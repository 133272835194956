import React, { useEffect, useState } from "react";
import "./style.scss";
import { Card, makeStyles, withStyles, 
		 CardActionArea, CardContent, 
		 Typography, CardActions, TextField } 
from "@material-ui/core";
import { useScreenSize } from "../../utils/media-query";

export const useStyles = makeStyles({
	desktop: {
		minWidth: "24%",
		width: "100%",
		height: "fit-content",
		margin: "1vh",
		flexGrow: "1",
	},

	tablet: {
		width: "100%",
		margin: "1vh",
		height: "fit-content",
		flexGrow: "1",
	},

	mobile: {
		width: "100%",
		margin: "1vh",
		height: "fit-content",
		flexGrow: "1",
	},

	media: {
		height: 140,
	},
});

export const OpcVariableCard = (props) => {
	const [opcVariable, setOpcVariable] = useState();
	const classes = useStyles();
	const { isXSmall, isLarge } = useScreenSize();

	function onValueChangedVariableInput(e) {
		const newOpcVariable = props.opcServer;

		if(((props.opcServer.CanonicalDataType === "VT_BOOL" || props.opcServer.CanonicalDataType === "System.Boolean") && 
			(e.target.value === "0" || e.target.value === "1")) || 
			((props.opcServer.CanonicalDataType !== "VT_BOOL" && props.opcServer.CanonicalDataType !== "System.Boolean") ||
			props.opcServer.Value.length > e.target.value.length)
		   ) 
		{
			newOpcVariable.Value = e.target.value;

			setOpcVariable(e.target.value);
			props.setOpcServer(newOpcVariable);
		} 
	}

	useEffect(() => {
		setOpcVariable(props.opcServer.Value);
	}, [props.opcServer]);

	function textColorQuality(){
		if(props.opcServer.Quality.indexOf("Good") > -1)
			return "rgb(7, 190, 7)";
		
		return "red"; 
	}

	return (
		<div className={"ContainerCard RemoveUnderline"}>
			<h4 className={"MarginHeader"}>VARIÁVEL</h4>
			<Card
				aria-hidden={true}
				className={ isLarge ? classes.desktop : isXSmall ? classes.mobile : classes.tablet }
			>
				<CardContent className="ContainerCardContent" style={{ width: "100%", height: "100%" }}>
					<div>
						<Typography variant="h6" color="textSecondary" component="p" className={"ContainerInputValue"}>
							<span className={"Name"}>Valor:</span>
								<span className={"Value"}>
									<TextField
										disabled={props.disabled}
										className="InputSemArredondamentos"
										hiddenLabel
										value={opcVariable}
										variant="filled"
										size="small"
										onChange={(e) => onValueChangedVariableInput(e)}
									/>
							</span>
						</Typography>
						<Typography variant="h6" color="textSecondary" component="p" className={"ContainerInputValue"}>
							<span className={"Name"}>Qualidade:</span>
							<span 
								className={"Value"}
								style={{color: textColorQuality()}}
							>
								{props.opcServer.Quality}
							</span> 
						</Typography>
						<Typography variant="h6" color="textSecondary" component="p" className={"ContainerInputValue"}>
							<span className={"Name"}>TimeStamp:</span>
							<span className={"Value"}>{props.opcServer.TimeStamp}</span> 
						</Typography>
					</div>
				</CardContent>
			</Card>
		</div>
	)
};