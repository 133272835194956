/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../components/common/style.scss";
import { Button } from "devextreme-react";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "./style.scss";
import { SelectBox } from "devextreme-react";
import { api } from "../../services/api";
import { useAuth } from "../../contexts/auth";
import { CheckBox } from "devextreme-react";
import { LogMessageCheckboxList } from "../../utils/logMessage"
import { formatMessage } from "devextreme/localization";
import { useLoading } from "../../contexts/loadingContext";
import { FavButton } from "../../components/fav-button/favButton";

export default function PermissionLaboratory() {
  const { loading } = useLoading();
  const { refreshToken, getRefreshTokenCode, getToken } = useAuth();
  const [load, setLoad] = useState(false);
  const [permission, setPermission] = useState([]);
  const [analysisPermission, setAnalysisPermission] = useState([]);
  const [analysisGroup, setAnalysisGroup] = useState([]);
  const [permissionChange, setPermissionChange] = useState();
  let permissionName = useRef();
  const [enabledButton, setEnabledButton] = useState(true);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const [textButton, setTextButton] = useState();
  const _list = useRef([]);
  const { unitCod } = useAuth();

  useEffect(() => {
    firstLoad();
  }, []);

  useEffect(() => {
    if (load){
      updateAnalysisPermission();
    }
  }, [load]);

  useEffect(() => {
    permissionChange > 0
      ? updateAnalysisPermission()
      : setAnalysisPermission([])
  }, [permissionChange]);

  useEffect(() => {
    if(analysisGroup.length >analysisPermission.length) {
      const newLstVariablePermissions = analysisGroup.map((program) => {return program.CodAnaliseGrupo});
      setAnalysisPermission(newLstVariablePermissions);
    }

    if(analysisPermission.length === analysisGroup.length)
      setAnalysisPermission([]);
  }, [selectedAll]);

  useEffect(() => {
    if(analysisGroup.length === analysisPermission.length) setTextButton("Desmarcar todos");
    if(analysisGroup.length > analysisPermission.length) setTextButton("Marcar todos");
  }, [analysisPermission, forceUpdate]);

  async function firstLoad(){
    if (unitCod !== undefined) {
      loadDataPermission();
      loadDataAnalysisGroup();
      refreshToken(getToken(), getRefreshTokenCode());
    }
  }

  async function updateAnalysisPermission() {
    if(unitCod !== undefined) {
      loadDataAnalysisGroup();
      loadDataAnalysisPermission();
    }
  }

  async function loadDataPermission() {
    await api
      .get("/permission", { params: { unitCod: unitCod } })
      .then((response) => {
        setPermission(response.data);
        setLoad(false);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true
      });
  }

  async function loadDataAnalysisGroup() {
    await api
      .get("/analysisGroup", { params: { unitCod: unitCod } })
      .then((response) => {
        setAnalysisGroup(response.data);
        setLoad(false);

      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function loadDataAnalysisPermission() {
    await api
      .get("/analysisPermission", {params: {codPermission: permissionChange}})
      .then((response) => {
        setAnalysisPermission(response.data.map((analysisGroup) => {return analysisGroup.CodAnaliseGrupo}));
        _list.current = response.data;
        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function loadInitialDataAnalysisPermission() {
    await api
        .get("/analysisPermission", {params: {codPermission: permissionChange}})
        .then((response) => {
          setAnalysisPermission(response.data.map((analysisGroup) => {return analysisGroup.CodAnaliseGrupo}));
          setLoad(false);
        })
        .catch((error) => {
          setLoad(true);

          return true;
        });
  }

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/analysisPermission", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(false);

        return true;
      });
  }

  async function deleteRow(data) {
    return await api
      .delete("/analysisPermission", { data: data})
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  function onSelectionChangedPermission (e) {
    setPermissionChange(e.selectedItem ? e.selectedItem.CodPermissao : 0)
    permissionName.current = (e.selectedItem ? e.selectedItem.NomPermissao : 0)
  }

  async function eventSaveClick(e) {
    const deleteAnalysisPermission = _list.current.filter((element) => {
      const found = analysisPermission.find(codAnaliseGrupo => codAnaliseGrupo === element.CodAnaliseGrupo)

      if(found === undefined) {
        element.NomLogFirst = permissionName.current
        element.NomLogSecond = analysisGroup.filter(x => x.CodAnaliseGrupo === element.CodAnaliseGrupo)[0].NomAnaliseGrupo;
        return element
      }
    })

    const insertAnalysisPermission = []

    analysisPermission.forEach((codAnaliseGrupo) => {
      const found = _list.current.find((element) => element.CodAnaliseGrupo === codAnaliseGrupo)

      if(found === undefined) {
        analysisGroup.forEach((analysis) => {
          if(analysis.CodAnaliseGrupo === codAnaliseGrupo) {
            const newAnalysisPermission = {
              CodPermissao: permissionChange,
              CodAnaliseGrupo: codAnaliseGrupo,
              NomLogFirst: permissionName.current,
              NomLogSecond: analysis.NomAnaliseGrupo
            };

            insertAnalysisPermission.push(newAnalysisPermission);
          }
        })
      }
    })

    deleteAnalysisPermission.forEach((item) => {
      let logMessage = LogMessageCheckboxList(formatMessage("DeletedLog"), item)
      const data = { unitCod: unitCod, data: item, logMessage: logMessage };

      deleteRow(data);
    })

    insertAnalysisPermission.forEach((item) => {
      let logMessage = LogMessageCheckboxList(formatMessage("InsertedLog"), item)
      insertRow(item, logMessage);
    })
    setEnabledButton(true)
  }

  function onValueChangedCheckBoxPermissions(e, codAnalysiGroup) {
    if(e.event) {
      let newLstAnalysisPermissions = analysisPermission;

      if(e.value) {
        newLstAnalysisPermissions.push(codAnalysiGroup);
        setAnalysisPermission(newLstAnalysisPermissions);
        setForceUpdate(!forceUpdate);
      } else {
        newLstAnalysisPermissions = analysisPermission.filter(code => code !== codAnalysiGroup);
        setAnalysisPermission(newLstAnalysisPermissions);
        setForceUpdate(!forceUpdate);
      };
      setEnabledButton(false)
    }
  };

  async function eventUndoClick() {
    loadInitialDataAnalysisPermission()
    setEnabledButton(true)
  }

  return (
    <React.Fragment>
      <div className="header-form">
        <div className="form-container">
          <div className={"margin-element"}>
              <SelectBox
                dataSource={permission}
                label={"Permissão"}
                searchEnabled={true}
                onSelectionChanged={onSelectionChangedPermission}
                displayExpr="NomPermissao"
                valueExpr="CodPermissao"
                width={200}
                stylingMode={"outlined"}
                labelMode={"floating"}
              />
          </div>
          <div className={"margin-element"}>
            <Button
              text={textButton}
              icon={!selectedAll ? "selectall" : "unselectall"}
              type="normal"
              className={"selectall-button"}
              useSubmitBehavior={false}
              stylingMode="contained"
              onClick={async (e) => {
                setSelectedAll(!selectedAll); setEnabledButton(false);
              }}
              // disabled={enabledButton}
            />
          </div>
          <div className={"margin-element"}>
            <Button
                text={"Desfazer"}
                icon={"undo"}
                type="normal"
                className={"selectall-button"}
                useSubmitBehavior={false}
                stylingMode="contained"
                onClick={ eventUndoClick}
                disabled={enabledButton}
            />
          </div>
          <div className={"margin-element"}>
            <Button
              className={"save-button"}
              id="searchFilterButton"
              icon="save"
              text="Salvar"
              type="default"
              useSubmitBehavior={false}
              stylingMode="contained"
              onClick={async (e) => {
                eventSaveClick();
              }}
              disabled={enabledButton}
              />
          </div>
        </div>
      </div>
      <div>
      <FavButton/>
      <div>
        {analysisGroup.map((analysiGroup) => {
          let valueCheckBox = false;

          analysisPermission.forEach((analysi) => {
            if(analysi === analysiGroup.CodAnaliseGrupo) valueCheckBox = true;
          });

          return(
                    <div style={{display: "inline-block", margin: "3% 1% 0% 2%", width: "200px"}} key={analysiGroup.CodAnaliseGrupo}>
                      <CheckBox
                        className={"checkbox-medium"}
                        value={valueCheckBox}
                        text={analysiGroup.NomAnaliseGrupo}
                        onValueChanged={(e) => onValueChangedCheckBoxPermissions(e, analysiGroup.CodAnaliseGrupo)}
                      />
                    </div>
                )
        })}
        </div>
      </div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
