import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import "./style.scss";
import Popup from 'devextreme-react/popup';
import { Button } from "devextreme-react";
import { useScreenSize } from '../../../utils/media-query';
import ScrollView from "devextreme-react/scroll-view";
import { api } from "../../../services/api";
import dayjs from "dayjs";

export default function PopupReanalysis(props) {
  const { isXSmall, isSmall} = useScreenSize();
  const [lstReanalysis, setLstReanalysis] = useState([]);
  let _lstDates = useRef([]);

  useEffect(() => {
    if(props.visibleReanalysisPopup) getAnalysisSample();
  }, [props.visibleReanalysisPopup])

  async function getAnalysisSample() {
    await api
      .get("/analysissample/getSampleAnalysis", {
        params: { 
          datAnalysis: dayjs(props.currencyValueDate).format("YYYY-MM-DDTHH:mm:ss"), 
          codAnalysis: props.codAnalysis,
          numLot: props.isLot ? props.numLot : null,
          unitCod: props.unitCod
        },
      })
      .then((response) => {
        response.data.forEach((element) => {
          if((_lstDates.current.findIndex(x => x === element.DatAnaliseAmostra)) === -1)
            _lstDates.current.push(element.DatAnaliseAmostra);
        });

        if(response.data.length === 0)
          _lstDates.current = [];

        setLstReanalysis(response.data.sort((a, b) => a.NumReanalise - b.NumReanalise));
      })
      .catch((error) => {
      })
  }

  return (
    <Popup
      width={isXSmall ? "100%" : isSmall ? "100%" : 600}
      height={isXSmall ? "100%" : isSmall ? "100%" : "50%"}
      fullScreen={isXSmall || isSmall ? true : false}
      visible={props.visibleReanalysisPopup}
      showTitle={true}
      title={"Reanálises"}
      closeOnOutsideClick={true}
      onHiding={(e) => props.setVisibleReanalysisPopup(false)}
      showCloseButton={true}
    >
      <ScrollView>
        <div>
          <div className="dx-field-label title">{props.nameAnalysis}</div>
            {_lstDates.current.map((element) => {
              return (
                <div key={element} className={"dx-card"}>
                  <h3 className="hour-samples">
                    {dayjs(element).format('LT')} 
                  </h3>
                  <table>
                    <tr>
                      <td>
                        <div className="dx-field-label" style={{width: "fit-content"}}>
                          {isXSmall ? "Numero" : "Número da Reanálise"}
                        </div>
                      </td>
                      <td>
                        <div className="margin-data dx-field-label">
                          {isXSmall ? "Valor" : "Valor Amostra"}
                        </div>
                      </td>
                      <td>
                        <div className="margin-data dx-field-label">
                          Observação
                        </div>
                      </td>
                    </tr>
                    {lstReanalysis.map((reanalysis) => {
                      if(reanalysis.DatAnaliseAmostra === element) {
                        return (
                          <tr key={reanalysis.NumReanalise}>
                            <td>
                              <div className="text-size">
                                {reanalysis.NumReanalise} 
                              </div>
                            </td>
                            <td>
                              <div className="margin-data text-size">
                                {reanalysis.TipVerificacao === 0 
                                ? (reanalysis.ValAmostra === 1 ? "Positivo" : "Negativo") 
                                : reanalysis.ValAmostra} 
                              </div>
                            </td>
                            <td style={{wordBreak: "break-word"}}>
                              <div className="margin-data text-size">
                                {reanalysis.DesObservacao} 
                              </div>
                            </td>
                          </tr>
                        )}
                    })} 
                  </table>
                </div>
              )
            })}
        </div>
        <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
          <Button
            className="form-btn"
            text="Fechar"
            width={120}
            useSubmitBehavior={false}
            type="default"
            stylingMode="text"
            onClick={(e) => props.setVisibleReanalysisPopup(false)}
          />
        </div>
      </ScrollView>        
    </Popup>    
  )
}