/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useLayoutEffect } from "react"
import dayjs from "dayjs"
import { CheckBox } from "devextreme-react"
import { NumberBox, Button as NumberBoxButton } from "devextreme-react/number-box"
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box"
import { useAuth } from "../../../contexts/auth"
import { useDataLoader } from "../../../contexts/dataLoaderContext"
import "../style.scss"
import { useScreenSize } from "../../../utils/media-query"
import VariableLegend from "../../../components/variable-legend/variable-legend"
import chevronDoubleDown from "../../../icons/chevronDoubleDown.svg"
import chevronDoubleUp from "../../../icons/chevronDoubleUp.svg"
import chevronSingleDown from "../../../icons/chevronSingleDown.svg"
import chevronSingleUp from "../../../icons/chevronSingleUp.svg"
import sucessBlackWhite from "../../../icons/sucessBlackWhite.svg"
import sucessGreen from "../../../icons/sucessGreen.svg"
import { AddItemsObjByVariable } from "./addItemsObjByVariable"
import { ObjSaveManualVariables } from "./objSaveManualVariables"
import { SpeedDialAction } from "devextreme-react/speed-dial-action"
import _ from "lodash";

// eslint-disable-next-line import/no-anonymous-default-export
export default function NumericInputVariable(props) {
  const { isSmall, isXSmall, isXLSmall, isLarge } = useScreenSize()
  let _refInputVariable = useRef([])
  let _refChbNotWorking = useRef([])
  let _refInputVariablePrevious = useRef([])
  let _paramsVariable = useRef({
    HiHi: "0",
    Hi: "0",
    Lo: "0",
    LoLo: "0",
    Ideal: "0",
    Unit: ""
  })
  let _lstPreviousValue = useRef([])
  let _lastAreaName = useRef("")
  let _processName = useRef(props.lstProcess.filter(x => x.CodProcesso === props.processCodeFilter)[0].NomProcesso)
  const { userCod } = useAuth()
  const { _lstInputVariableSave, setSaveHeaderTextBox, saveHeaderTextBox } = useDataLoader()

  useLayoutEffect(() => {
    async function loadHeaderTextBox() {
      //Reload quando salva
      await props.setSecondaryBt(false)
      props.loadVariableInputs(props.processCodeFilter)
    }

    if (saveHeaderTextBox) {
      loadHeaderTextBox()
      setSaveHeaderTextBox(false)
    }
    
  }, [saveHeaderTextBox])

  //#region icon / styles
  const iconHiHi = {
    icon: chevronDoubleUp,
    visible: true,
    hint: "Muito Alto"
  }

  const iconHi = {
    icon: chevronSingleUp,
    visible: true,
    hint: "Alto"
  }

  const iconIdeal = {
    icon: sucessGreen,
    visible: true,
    hint: "Valor Ideal!"
  }

  const iconOk = {
    icon: sucessBlackWhite,
    visible: true,
    hint: "Valor dentro do range"
  }

  const iconLo = {
    icon: chevronSingleDown,
    visible: true,
    hint: "Baixo"
  }

  const iconLoLo = {
    icon: chevronDoubleDown,
    visible: true,
    hint: "Muito Baixo"
  }

  const noIcon = {
    visible: false
  }

  const optionsValueIcon = {
    stylingMode: "text",
    focusStateEnabled: false,
    type: "default",
    visible: false,
    width: 36,
    height: 36
  }
  //#endregion

  //#region events
  const onValueChangedChbNotWorking = e => {
    _refInputVariable.current[e.element.id].instance.option("disabled", e.value)

    if (e.value) {
      _lstPreviousValue.current[e.element.id] = _refInputVariablePrevious.current[e.element.id].instance.option("value")
      _refInputVariable.current[e.element.id].instance.option("value", 0)
    } else {
      let previousValue = _lstPreviousValue.current[e.element.id]
      _refInputVariable.current[e.element.id].instance.option("value", previousValue)
    }
  }

  const onValueChanged = e => {
    let valueCheckNotWorking = _refChbNotWorking.current[e.element.id].instance.option("value")
    let dateInputVariable = _refInputVariable.current[e.element.id].instance.option("nextUpdate")
    let paramsVariable = _refInputVariable.current[e.element.id].instance.option("paramsVariable")
    let variableCode = _refInputVariable.current[e.element.id].instance.option("variableCode")

    const newObj = ObjSaveManualVariables(
      paramsVariable.current,
      dateInputVariable,
      e.value,
      !valueCheckNotWorking,
      userCod,
      variableCode,
      e.previousValue,
      paramsVariable.current.TypeVariable
    )

    AddItemsObjByVariable(newObj, _lstInputVariableSave.current)
  }

  const valueComparasion = (e, item) => {
    const numberBox = e.component.instance().getButton("checkValue")
    let dblValue = e.component.option("value")
    let booTipWorking = e.component.option("disabled")

    if (dblValue === null || dblValue === "" || booTipWorking) numberBox.option(noIcon)
    else if (dblValue > item.HiHi) numberBox.option(iconHiHi)
    else if (dblValue <= item.HiHi && dblValue > item.Hi) numberBox.option(iconHi)
    else if (dblValue === item.Ideal) numberBox.option(iconIdeal)
    else if (dblValue <= item.Hi && dblValue >= item.Lo) numberBox.option(iconOk)
    else if (dblValue <= item.Lo && dblValue >= item.LoLo) numberBox.option(iconLo)
    else if (dblValue < item.LoLo) numberBox.option(iconLoLo)
  }

  const scrollToElement = (item) => {//Scroll analises
		const element = document.getElementsByClassName(item)[0];
		if(element !== null) element.scrollIntoView({ behavior: "smooth", block: "start" });
	};
  //#endregion

  function setNameVar(parAreaName, parIndex, parLength) {
    //Quando for o último index zera a variavel
    parLength === parIndex ? (_lastAreaName.current = "") : (_lastAreaName.current = parAreaName)
  }

  function styledAreaName(parAreaName) {
    return (
      <div>
        <div className="title-process">Área</div>
        <div className="subtitle-area">{parAreaName}</div>
      </div>
    )
  }

  return (
    <div>
      <div className="scroll-to-top" />
      <div className="content-block" style={{ width: "90%", marginTop: 70 }}>
        <div
          style={{
            position: "fixed",
            visibility: isXSmall || isXLSmall ? "hidden" : "visible",
            left: isXLSmall ? "80%" : isSmall ? "80%" : "90%",
            width: "130px"
          }}
        >
          <VariableLegend paramsVariable={_paramsVariable.current} />
        </div>
        <h2 style={{marginTop: 0}}>
          <div className="title-process">Processo</div>
          <div className="subtitle-process">{`${_processName.current}`}</div>
        </h2>
        {props.lstVariables
          .filter(x => x !== null)
          .map((item, firstIndex) => (
            <div key={firstIndex} style={{marginTop: firstIndex === 0 ? "-20px" : "0px", marginLeft: 20}}>
              <div style={{ marginTop: firstIndex === 0 ? "30px" : "10px" }}>
                {/*É feito para não repetir o nome da Área em cada variavel, se for a mesma só escreve a Área na primeira variável*/}
                <h2>{_lastAreaName.current !== item[0].AreaName ? styledAreaName(item[0].AreaName) : ""}</h2>
                <div className="title-date">{dayjs(item[0].NextUpdate).format("L LT")}</div>
              </div>
              <div style={{ marginTop: "6px" }}>
                {setNameVar(item[0].AreaName, firstIndex, props.lstVariables.length - 1)}
                {_.orderBy(item, 'NumOrdem', "asc").map((item, index) => (
                  <div key={index} style={{ display: "inline-block", marginBottom: "10px", marginLeft: "20px" }}>
                    <div
                      title={`${item.VariableName} - ${item.VariableDesc}`}
                      id={`lbl${firstIndex}:${index}`}
                      style={{ display: "inline-block", width: isXLSmall ? 180 : isXSmall ? 240 : 300 }}
                      className="dx-field-label textbox-font-title"
                    >
                      {item.VariableName} - {item.VariableDesc}
                    </div>
                    <div style={{ display: "inline-block", marginRight: "10px" }}>
                      {item.TypeVariable === 1 ? ( //1 - Bit, 2 - Numerico, 3 - Alfanumerico
                        <CheckBox
                          height={42}
                          stylingMode="outlined"
                          className={"checkbox-large"}
                          tabIndex={-1}
                          style={{ marginTop: 4 }}
                          onValueChanged={onValueChanged}
                          onInitialized={e => {
                            _paramsVariable.current = {
                              HiHi: item.HiHi,
                              Hi: item.Hi,
                              Lo: item.Lo,
                              LoLo: item.LoLo,
                              Unit: item.Unit,
                              TypeVariable: item.TypeVariable
                            }

                            e.component.option("value", null)
                            e.component.option("nextUpdate", item.NextUpdate)
                            e.component.option("variableCode", item.VariableCode)
                            e.component.option("paramsVariable", _paramsVariable)
                          }}
                          onFocusIn={e => {
                            document.getElementById("legendHiHi").innerText = `${item.HiHi} ${item.Unit}`
                            document.getElementById("legendHi").innerText = `${item.Hi} ${item.Unit}`
                            document.getElementById("legendIdeal").innerText = `${item.Ideal} ${item.Unit}`
                            document.getElementById("legendLo").innerText = `${item.Lo} ${item.Unit}`
                            document.getElementById("legendLoLo").innerText = `${item.LoLo} ${item.Unit}`
                            document.getElementById(`lbl${e.element.id}`).style.fontWeight = "bold"
                          }}
                          onFocusOut={e => (document.getElementById(`lbl${e.element.id}`).style.fontWeight = "normal")}
                          defaultValue={""}
                          id={`${firstIndex}:${index}`}
                          ref={node => {
                            _refInputVariable.current[`${firstIndex}:${index}`] = node
                            _refInputVariablePrevious.current[`${firstIndex}:${index}`] = node
                          }}
                          // onOptionChanged={(e) => (e.name === "text" || e.name === "disabled" ? valueComparasion(e, item) : "")}
                        />
                      ) : item.TypeVariable === 2 ? ( //Numerico
                        <NumberBox
                          height={36}
                          width={isLarge ? 160 : 100}
                          stylingMode="outlined"
                          className={"textbox-font-editor"}
                          onValueChanged={onValueChanged}
                          onInitialized={e => {
                            _paramsVariable.current = {
                              HiHi: item.HiHi,
                              Hi: item.Hi,
                              Lo: item.Lo,
                              LoLo: item.LoLo,
                              Unit: item.Unit,
                              TypeVariable: item.TypeVariable
                            }

                            e.component.option("nextUpdate", item.NextUpdate)
                            e.component.option("variableCode", item.VariableCode)
                            e.component.option("paramsVariable", _paramsVariable)
                          }}
                          onFocusIn={e => {
                            document.getElementById("legendHiHi").innerText = `${item.HiHi} ${item.Unit}`
                            document.getElementById("legendHi").innerText = `${item.Hi} ${item.Unit}`
                            document.getElementById("legendIdeal").innerText = `${item.Ideal} ${item.Unit}`
                            document.getElementById("legendLo").innerText = `${item.Lo} ${item.Unit}`
                            document.getElementById("legendLoLo").innerText = `${item.LoLo} ${item.Unit}`
                            document.getElementById(`lbl${e.element.id}`).style.fontWeight = "bold"
                          }}
                          onFocusOut={e => (document.getElementById(`lbl${e.element.id}`).style.fontWeight = "normal")}
                          style={{ marginTop: 4 }}
                          defaultValue={""}
                          id={`${firstIndex}:${index}`}
                          ref={node => {
                            _refInputVariable.current[`${firstIndex}:${index}`] = node
                            _refInputVariablePrevious.current[`${firstIndex}:${index}`] = node
                          }}
                          onOptionChanged={e => (e.name === "text" || e.name === "disabled" ? valueComparasion(e, item) : "")}
                        >
                          <NumberBoxButton name="checkValue" location="after" options={optionsValueIcon} />
                        </NumberBox>
                      ) : (
                        <TextBox //AlfaNumerico
                          height={36}
                          width={isLarge ? 160 : 100}
                          stylingMode="outlined"
                          className={"textbox-font-editor"}
                          onValueChanged={onValueChanged}
                          onInitialized={e => {
                            _paramsVariable.current = {
                              HiHi: item.HiHi,
                              Hi: item.Hi,
                              Lo: item.Lo,
                              LoLo: item.LoLo,
                              Unit: item.Unit,
                              TypeVariable: item.TypeVariable
                            }

                            e.component.option("nextUpdate", item.NextUpdate)
                            e.component.option("variableCode", item.VariableCode)
                            e.component.option("paramsVariable", _paramsVariable)
                          }}
                          onFocusIn={e => {
                            document.getElementById("legendHiHi").innerText = `${item.HiHi} ${item.Unit}`
                            document.getElementById("legendHi").innerText = `${item.Hi} ${item.Unit}`
                            document.getElementById("legendIdeal").innerText = `${item.Ideal} ${item.Unit}`
                            document.getElementById("legendLo").innerText = `${item.Lo} ${item.Unit}`
                            document.getElementById("legendLoLo").innerText = `${item.LoLo} ${item.Unit}`
                            document.getElementById(`lbl${e.element.id}`).style.fontWeight = "bold"
                          }}
                          onFocusOut={e => (document.getElementById(`lbl${e.element.id}`).style.fontWeight = "normal")}
                          style={{ marginTop: 4 }}
                          defaultValue={""}
                          id={`${firstIndex}:${index}`}
                          ref={node => {
                            _refInputVariable.current[`${firstIndex}:${index}`] = node
                            _refInputVariablePrevious.current[`${firstIndex}:${index}`] = node
                          }}
                          onOptionChanged={e => (e.name === "text" || e.name === "disabled" ? valueComparasion(e, item) : "")}
                        >
                          <TextBoxButton name="checkValue" location="after" options={optionsValueIcon} />
                        </TextBox>
                      )}
                    </div>
                    <div style={{ display: "inline-block", width: "100px" }}>
                      <div id={"chbNotWorking"}>
                        <CheckBox
                          name="chbNotWorking"
                          className={"checkbox-large"}
                          stylingMode="outlined"
                          tabIndex={-1}
                          onValueChanged={e => onValueChangedChbNotWorking(e)}
                          id={`${firstIndex}:${index}`}
                          hint={"Marque se não foi possível fazer a leitura"}
                          ref={node => (_refChbNotWorking.current[`${firstIndex}:${index}`] = node)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        <SpeedDialAction icon="arrowup" index={1} onClick={() => scrollToElement('scroll-to-top')} />
      </div>
    </div>
  )
}
