/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "./style.scss";
import DataGrid, {
  Column,
  Pager,
  RequiredRule,
  Paging,
  FilterRow,
  Export,
  Selection,
  ColumnChooser,
  Grouping,
  GroupPanel,
  ColumnFixing,
  Lookup,
  CustomRule,
  Popup,
  Form,
  Editing,
  Position, Toolbar,
} from "devextreme-react/data-grid";
import DateBox from "devextreme-react/date-box";
import { Item } from "devextreme-react/form";
import { api } from "../../services/api";
import { useAuth } from "../../contexts/auth";
import { calculateDuration } from "../../utils/calculateDuration";
import dayjs from "dayjs";
import { LogMessageGrid } from "../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import { useLoading } from "../../contexts/loadingContext";
import { FavButton } from "../../components/fav-button/favButton";
import {useScreenSize} from "../../utils/media-query";
import {ReportsButton} from "../../components/reports-button/reportsButton";
import Button from "devextreme-react/button";

export default function AreaProcessStop() {
  const { loading } = useLoading();
  const { refreshToken, getRefreshTokenCode, getToken } = useAuth();
  const [load, setLoad] = useState(false);
  const [areaProcessStop, setAreaProcessStop] = useState([]);
  const [areaProcess, setAreaProcess] = useState([]);
  const [user, setUser] = useState([]);
  const [disabledDateBox, setDisabledDateBox] = useState(false);
  const { isLarge } = useScreenSize();
  const [typeReasonStop, setTypeReasonStop] = useState([]);
  const [lstEquipment, setLstEquipment] = useState([]);
  const _date = useRef();
  const _areaProcessStop = useRef();
  const _lstAllUser = useRef();
  const _areProcessSelected = useRef();

  //Autenticação requests
  const { unitCod, userCod } = useAuth();

  useEffect(() => {
    firstLoad();
  }, []);

  useEffect(() => {
      loadData();
  }, [load]);

  async function firstLoad() {
    if (unitCod !== undefined) {
      loadDataTypeReasonStop();
      loadDataAreaProcess();
      loadDataEquipment();
      await loadDataUsers();
      await loadData();
      refreshToken(getToken(), getRefreshTokenCode());
    }
  };

  async function loadData() {
    await api
      .get("/areaProcessStop", { params: { unitCod: unitCod, date: _date.current } })
      .then((response) => {
        setAreaProcessStop(response.data);
        return false;
      })
      .catch((error) => {
        return true;
      });
  };

  async function loadDataAreaProcess() {
    await api
      .get("/areaprocess", { params: { unitCod: unitCod } })
      .then((response) => {
        setAreaProcess(response.data.filter((item) => item.TipPermiteParada === true));
      })
      .catch((error) => {
        return true;
      });
  };

  async function loadDataUsers() {
    await api
      .get("/user", { params: { unitCod: unitCod } })
      .then((response) => {
        userCod === 0
          ? setUser(response.data)
          : setUser(response.data.filter((x) => x.CodUsuario !== 0));

        _lstAllUser.current = response.data;
      })
      .catch((error) => {
        return true;
      });
  };

  async function loadDataTypeReasonStop() {
    await api
      .get("/typeReasonStop", { params: { unitCod: unitCod } })
      .then((response) => {
        setTypeReasonStop(response.data);
        return false;
      })
      .catch((error) => {
        return true;
      });
  };

  async function loadDataEquipment() {
		await api
			.get("/equipment", { params: { unitCod: unitCod } })
			.then((response) => {
				setLstEquipment(response.data);
			})
			.catch((error) => {});
	}

  async function updateRow(data, parLogMessage) {
    return await api
      .put("/areaProcessStop", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(!load);
        return false;
      })
      .catch((error) => {
        return true;
      });
  };

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/areaProcessStop", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(!load);
        return false;
      })
      .catch((error) => {
        return true;
      });
  };

  async function deleteRow(data) {
    return await api
      .delete("/areaProcessStop", { data: data })
      .then((response) => {
        setLoad(!load);
        return false;
      })
      .catch((error) => {
        return true;
      });
  };

  function checkDates(params) {
    let check = true;

    if(params.data.DatFinalParada !== "Invalid Date") {
      check = dayjs(params.data.DatInicialParada).isBefore(dayjs(params.data.DatFinalParada));
    }

    if (params.data.DatPrazo && check) {
      check = dayjs(params.data.DatInicialParada).isBefore(dayjs(params.data.DatPrazo));
    }

    if (!params.data.DatFinalParada && !params.data.DatPrazo) {
      check = true;
    }

    return check;
  };

  function checkParams(params) {
    return (
      params.data.CodTipoParadaMotivo ||
      params.data.DesAcaoImediata ||
      params.data.DesAcaoResolutiva ||
      params.data.DatPrazo ||
      params.data.CodUsuario
    );
  };

  function dateBoxEditorRender(cell) {
      return (
          !!cell.item && cell.item.dataField === "DatInicialParada" ?
                <DateBox
                  type={"datetime"}
                  pickerType={isLarge ? "calendar" : "rollers"}
                  displayFormat={"dd/MM/yyyy HH:mm"}
                  defaultValue={dayjs(cell.value).format("YYYY-MM-DD HH:mm")}
                  onValueChanged={(e) => onValueChanged(e, cell)}
                />
               :
               <DateBox
                   type={"datetime"}
                   pickerType={isLarge ? "calendar" : "rollers"}
                   displayFormat={"dd/MM/yyyy HH:mm"}
                   defaultValue={cell.value ? dayjs(cell.value).format("YYYY-MM-DD HH:mm") : ""}
                   onOpened={(e) => e.component.option("value", dayjs().add(1, 'day').format("YYYY-MM-DD HH:mm"))}
                   onValueChanged={(e) => onValueChanged(e, cell)}
                   showClearButton={true}
               />
      );
  }

  function onValueChanged(e, cell) {
    cell.setValue(dayjs(e.value).format("YYYY-MM-DD HH:mm"));
  };

  function checkUniqueKey(params) {
    let check = true;

    areaProcessStop.forEach((unit) => {
      if (
        unit.CodAreaProcessoParada !== params.data.CodAreaProcessoParada &&
        unit.CodUnidadeEmpresa === params.data.CodUnidadeEmpresa &&
        unit.CodAreaProcesso === params.data.CodAreaProcesso &&
        unit.DatInicialParada === params.data.DatInicialParada
      )
        check = false;
    });

    return check;
  };

  const onEditorPreparing = (e) => {

      if (e.parentType === "dataRow" && (e.dataField === "CodTipoParadaMotivo" || e.dataField === "CodAreaProcesso"  || e.dataField === "CodUsuario")) {
        e.editorName = "dxSelectBox";
        e.editorOptions.searchEnabled = isLarge
      }
  }

  function setFinalDate(newData, value, currentRowData) {
		newData.DatFinalParada = value;

		if (value && !currentRowData.DatPrazo)
			newData.DatPrazo = value;

		let column = this;
		column.defaultSetCellValue(newData, value);
	}

  function setCellValueAreaProcess(newData, value, currentRowData) {
    _areProcessSelected.current = value
    newData.CodAreaProcesso = value;
    newData.CodEquipamento = null;

    let column = this;
		column.defaultSetCellValue(newData, value);
  }

  const lookupOptions = {
		onOpened: (e) => e.component.option("dataSource", _areProcessSelected.current 
      ? lstEquipment.filter(x => x.CodAreaProcesso === _areProcessSelected.current) 
      : lstEquipment)
	};

  return (
    <React.Fragment>
      <div className={"ContainerButtons"}>
				<FavButton/>
        <ReportsButton/>
			</div>
      <DataGrid
        className={"dx-cards"}
        dataSource={areaProcessStop}
        rowAlternationEnabled={true}
        showColumnLines={true}
        allowColumnReordering={true}
        showBorders={false}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        repaintChangesOnly={true}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: false }}
        onInitNewRow={(e) => {
          e.data.CodUnidadeEmpresa = unitCod;
          e.data.TipParadaAutomatica = false;
          e.data.TipOciosidade = false;
          e.data.DatInicialParada = dayjs().format("YYYY-MM-DD HH:mm");
          e.data.CodUsuario = userCod;
          setUser(user.filter(x => x.TipRegistroAtivo === true));
        }}
        onEditingStart={(e) => setUser(user.filter(x => x.TipRegistroAtivo === true || x.CodUsuario === e.data.CodUsuario))}
        onEditorPreparing={onEditorPreparing}
        onRowUpdating={async (e) => {
          const newObj = { ...e.oldData, ...e.newData };
          let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _areaProcessStop, newObj);
          if(newObj.DatFinalParada === "Invalid Date"){
            newObj.DatFinalParada = null;
          }
          e.cancel = await updateRow(newObj, strLogMessage);

          e.component.deselectAll();
        }}
        onRowInserting={(e) => {
          let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _areaProcessStop, e.data);
          if(e.data.DatFinalParada === "Invalid Date"){
            e.data.DatFinalParada = null;
          }
          e.cancel = insertRow(e.data, strLogMessage);

          e.component.deselectAll();
        }}
        onRowRemoving={(e) => {
          let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _areaProcessStop, e.data)
          const data = { unitCod: unitCod,  data: e.data, logMessage: strLogMessage }

          e.cancel = deleteRow(data);

          e.component.deselectAll();
        }}
        ref={_areaProcessStop}>
        <Editing refreshMode={"full"} mode="popup" allowUpdating={true} allowDeleting={true} allowAdding={false}>
          <Popup title="Área" fullScreen={true} showTitle={true}  width={700} height={600} onHidden={() => setUser(_lstAllUser.current)}>
            <Position my="center" at="center" of={window} />
          </Popup>
          <Form>
            <Item itemType="group" colCount={2} colSpan={2}>
              <Item dataField="CodAreaProcesso" colSpan={2}/>
              <Item dataField="DatInicialParada" />
              <Item dataField="DatFinalParada" />
              <Item dataField="CodEquipamento" editorType={"dxLookup"} editorOptions={lookupOptions}/>
              <Item dataField="CodTipoParadaMotivo"  />
              <Item dataField="DesAcaoImediata" />
              <Item dataField="DesAcaoResolutiva" />
              <Item dataField="DatPrazo" />
              <Item dataField="CodUsuario" />
              <Item dataField="TipOciosidade" />
            </Item>
          </Form>
        </Editing>
        <Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false} />
        <GroupPanel visible={false} />
        <Export enabled={true} fileName="Áreas de Processo Parada" allowExportSelectedData={true} />
        <ColumnChooser enabled={true} mode="select" />
        <ColumnFixing enabled={false} />
        <Selection mode="multiple" />
        <Paging defaultPageSize={20} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <Toolbar >
          <Item cssClass={'add-container'}>
            <Button className={'add-button'}  icon={"add"} onClick={() => (_areaProcessStop.current.instance.addRow())}/>
          </Item>
          <Item name="saveButton"/>
          <Item name="revertButton"/>
          <Item name="exportButton"/>
          <Item name="columnChooserButton"/>
        </Toolbar>
        <Column
          dataField={"CodAreaProcesso"}
          caption={"Área"}
          allowSorting={true}
          allowEditing={true}
          width={200}
          setCellValue={setCellValueAreaProcess}
        >
          <Lookup
            dataSource={areaProcess}
            displayExpr={"NomAreaProcesso"}
            valueExpr={"CodAreaProcesso"}
          />
          <CustomRule
            message={"Este campo deve ser único"}
            reevaluate={true} type={"custom"}
            validationCallback={checkUniqueKey}
          />
          <RequiredRule />
        </Column>
        <Column
          dataField={"DatInicialParada"}
          caption={"Data Inicial"}
          allowSorting={true}
          allowEditing={true}
          allowAdding={true}
          width={"auto"}
          dataType={"datetime"}
          format={"dd/MM/yyyy HH:mm"}
          editCellRender={dateBoxEditorRender}>
          <RequiredRule />
          <CustomRule
            message={"A data inicial deve ser menor que a data de prazo ou data final"}
            reevaluate={true}
            validationCallback={checkDates}
          />
          <CustomRule
            message={"Este campo deve ser único"}
            reevaluate={true} type={"custom"}
            validationCallback={checkUniqueKey}
          />
        </Column>
        <Column
          dataField={"DatFinalParada"}
          caption={"Data Final"}
          allowSorting={true}
          allowEditing={true}
          allowAdding={true}
          width={"auto"}
          dataType={"datetime"}
          format={"dd/MM/yyyy HH:mm"}
          editCellRender={dateBoxEditorRender}
          setCellValue={setFinalDate}
        >
          <CustomRule
            message={"A data final deve ser maior que a data inicial"}
            reevaluate={true}
            validationCallback={checkDates}
          />
        </Column>
        <Column
          calculateCellValue={calculateDuration}
          caption={"Periodo Parada"}
          allowSorting={true}
          allowEditing={false}
          width={"auto"}
        >
        </Column>
        <Column
          dataField={"CodEquipamento"}
          caption={"Equipamento"}
          allowSorting={true}
          allowEditing={true}
          allowSearch={false}
          width={"auto"}
        >
          <Lookup
            dataSource={lstEquipment}
            displayExpr={"TagEquipamento"}
            valueExpr={"CodEquipamento"}
          />
        </Column>
        <Column
          dataField={"CodTipoParadaMotivo"}
          caption={"Motivo da Parada"}
          allowSorting={true}
          allowEditing={true}
          allowSearch={false}
          width={"auto"}
        >
          <CustomRule
            message={"Motivo da Parada não pode ser nulo"}
            reevaluate={true}
            type={"custom"}
            validationCallback={(params) =>
              (params.data.CodTipoParadaMotivo === null || params.data.CodTipoParadaMotivo === undefined) && checkParams(params) ? false : true
            }
          />
          <Lookup
            dataSource={typeReasonStop}
            displayExpr={"NomTipoParadaMotivo"}
            valueExpr={"CodTipoParadaMotivo"}
          />
        </Column>
        <Column
          dataField={"DesAcaoImediata"}
          caption={"Ação Imediata / Observação"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}>
        </Column>
        <Column
          dataField={"DesAcaoResolutiva"}
          caption={"Ação Resolutiva"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}>
        </Column>
        <Column
          dataField={"DatPrazo"}
          caption={"Prazo"}
          allowSorting={true}
          allowEditing={true}
          allowAdding={true}
          width={"auto"}
          dataType={"date"}
          format={"dd/MM/yyyy HH:mm"}
          editCellRender={dateBoxEditorRender}>
          <RequiredRule />
          <CustomRule
            message={"A data prazo deve ser maior que a data inicial"}
            reevaluate={true}
            validationCallback={checkDates}
          />
          <CustomRule
            message={"Prazo não pode ser nulo"}
            reevaluate={true}
            type={"custom"}
            validationCallback={(params) =>
              (params.data.DatPrazo === null || params.data.DatPrazo === undefined) && checkParams(params) ? false : true
            }
          />
        </Column>
        <Column
          dataField={"CodUsuario"}
          caption={"Responsavel"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
        >
          <CustomRule
            message={"Responsável não pode ser nulo"}
            reevaluate={true}
            type={"custom"}
            validationCallback={(params) =>
              (params.data.CodUsuario === null || params.data.CodUsuario === undefined) && checkParams(params) ? false : true
            }
          />
          <Lookup
            dataSource={user}
            displayExpr={"NomUsuario"}
            valueExpr={"CodUsuario"}
          />
        </Column>
        <Column
          dataField={"TipParadaAutomatica"}
          caption={"Automática"}
          allowSorting={true}
          allowEditing={false}
          allowAdding={true}
          dataType={"boolean"}
          width={"auto"}>
        </Column>
        <Column
          dataField={"TipOciosidade"}
          caption={"Ociosidade"}
          allowSorting={true}
          allowEditing={true}
          dataType={"boolean"}
          width={"auto"}>
        </Column>
      </DataGrid>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
