/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "./style.scss";
import DataGrid, {
  Column,
  Pager,
  RequiredRule,
  Paging,
  FilterRow,
  Export,
  Selection,
  ColumnChooser,
  Grouping,
  GroupPanel,
  ColumnFixing,
  CustomRule,
  Lookup, Item, Toolbar
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { LogMessageGrid } from "../../../utils/logMessage"
import { formatMessage } from "devextreme/localization";
import { useLoading } from "../../../contexts/loadingContext";
import Button from "devextreme-react/button";

export default function TypeReasonStop(props) {
  const { loading } = useLoading();
  const [load, setLoad] = useState(false);
  const [typeReasonStop, setTypeReasonStop] = useState([]);
  const [typeStop, setTypeStop] = useState([]);
  const _typeStop = useRef();
  const columnMaxLengthTen = { maxLength: 10 };
  const columnMaxLengthThreeHundred ={ maxLength: 300};

  //Autenticação requests
  const { unitCod } = useAuth();

  useEffect(() => {
    firstLoad();
  }, [props.update]);

  useEffect(() => {
    if (load){
      loadData();
      //loadDataTypeStop();
    }
  }, [load]);

  async function firstLoad(){
    if (unitCod !== undefined) {
      loadData();
      loadDataTypeStop();
    }
  };

  async function loadData() {
    await api
      .get("/typeReasonStop", { params: { unitCod: unitCod } })
      .then((response) => {
        setTypeReasonStop(response.data);

        return false;
      })
      .catch((error) => {
        return true
      });
  };

  async function loadDataTypeStop() {
    await api
      .get("/typeStop", { params: { unitCod: unitCod } })
      .then((response) => {
        setTypeStop(response.data);
        return false;
      })
      .catch((error) => {
        return true
      });
  };

  async function updateRow(data, parLogMessage) {
    return await api
      .put("/typeReasonStop", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(!load);
        return false;
      })
      .catch((error) => {
        console.log(error)
        return true;
      });
  };

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/typeReasonStop", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(!load);

        return false;
      })
      .catch((error) => {
        console.log(error)
        return true;
      });
  };

  async function deleteRow(data) {
    return await api
      .delete("/typeReasonStop", {data: data})
      .then((response) => {
        setLoad(!load);

        return false;
      })
      .catch((error) => {
        console.log(error)
        return true;
      });
  };

  function checkUniqueKey(params) {
    let check = true;

    typeReasonStop.forEach((unit) => {
      if(unit.CodTipoParadaMotivo !== params.data.CodTipoParadaMotivo &&
        unit.NomTipoParadaMotivo === params.data.NomTipoParadaMotivo
        ) check = false;
    })

    return check;
  };

  function checkUniqueKeyT(params) {
    let check = true;

    typeReasonStop.forEach((unit) => {
      if(unit.CodTipoParadaMotivo !== params.data.CodTipoParadaMotivo &&
        unit.NomSiglaTipoParadaMotivo === params.data.NomSiglaTipoParadaMotivo
        ) check = false;
    })

    return check;
  };

  return (
    <React.Fragment>
      <DataGrid
        dataSource={typeReasonStop}
        rowAlternationEnabled={true}
        showColumnLines={true}
        allowColumnReordering={true}
        showBorders={false}
        columnAutoWidth={false}
        columnHidingEnabled={true}
        repaintChangesOnly={true}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: false }}
        onInitNewRow={(e) => {
          e.data.CodUnidadeEmpresa = unitCod;
          e.data.TipMostrarRelatorios = true;
        }}
        editing={{
          refreshMode: "full",
          mode:"batch",
          allowUpdating: true,
          allowDeleting: true,
          allowAdding: false,
        }}
        onRowUpdating={async (e) => {
          const newObj = { ...e.oldData, ...e.newData };
          let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _typeStop, newObj);

          e.cancel = updateRow(newObj, strLogMessage);

          e.component.deselectAll();
        }}
        onRowInserting={(e) => {
          let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _typeStop, e.data);

          e.cancel = insertRow(e.data, strLogMessage);

          e.component.deselectAll();
        }}
        onRowRemoving={(e) => {
          let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _typeStop, e.data)
          const data = { unitCod: unitCod,  data: e.data, logMessage: strLogMessage }

          e.cancel = deleteRow(data);

          e.component.deselectAll();
        }}
        ref={_typeStop}
      >
        <Grouping
          contextMenuEnabled={false}
          expandMode="rowClick"
          autoExpandAll={false}
        />
        <GroupPanel visible={false} />
        <Export
          enabled={true}
          fileName="Tipo de Motivos"
          allowExportSelectedData={true}
        />
        <ColumnChooser enabled={true} mode="select" />
        <ColumnFixing enabled={false} />
        <Selection mode="multiple" />
        <Paging defaultPageSize={20} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <Toolbar >
          <Item cssClass={'add-container'}>
            <Button className={'add-button'}  icon={"add"} onClick={() => (_typeStop.current.instance.addRow())}/>
          </Item>
          <Item name="saveButton"/>
          <Item name="revertButton"/>
          <Item name="exportButton"/>
          <Item name="columnChooserButton"/>
        </Toolbar>
        <Column
          dataField={"NomTipoParadaMotivo"}
          caption={"Descrição Motivo"}
          allowSorting={true}
          allowEditing={true}
          dataType={"string"}
          editorOptions={columnMaxLengthThreeHundred}
        >
          <CustomRule
            message={'Este campo deve ser único'}
            reevaluate={true}
            type={"custom"}
            validationCallback={checkUniqueKey}
          />
          <RequiredRule />
        </Column>
        <Column
          caption={"Tipo Parada"}
          dataField={"CodTipoParada"}
        >
          <Lookup
            dataSource={typeStop}
            displayExpr={"NomTipoParada"}
            valueExpr={"CodTipoParada"}
          />
        </Column>
        <Column
          dataField={"NomSiglaTipoParadaMotivo"}
          caption={"Sigla do Motivo"}
          allowSorting={true}
          allowEditing={true}
          dataType={"string"}
          editorOptions={columnMaxLengthTen}
        >
          <CustomRule
            message={'Este campo deve ser único'}
            reevaluate={true}
            type={"custom"}
            validationCallback={checkUniqueKeyT}
          />
          <RequiredRule />
        </Column>
        <Column
          dataField={"TipMostrarRelatorios"}
          caption={"Mostrar Relatório"}
          dataType={"boolean"}
          allowSorting={true}
          allowEditing={true}
        />
      </DataGrid>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  )
};
