/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useCallback, useState } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { navigation } from '../../app-navigation';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import { useAuth } from "../../contexts/auth";
import './side-navigation-menu.scss';

import * as events from 'devextreme/events';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady
  } = props;
  const { permissions, tipAdministrator } = useAuth();
  const { isLarge } = useScreenSize();
  const [items, setItems] = useState();

  //Usado para as permissões dos Programas
  useEffect(() => {
    tipAdministrator ? setItems(navigation) : permissionUsers()
    //_intNavigationLength.current = navigation.length - 1;
  }, [permissions]);

  /*const items = useMemo(
    () => navigation.map((item) => ({ ...item, expanded: isLarge })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []dxclick
  );*/

  const { navigationData: { currentPath } } = useNavigation();

  const treeViewRef = useRef();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'mouseover');
      events.off(prevElement, 'mouseout');
    }

    wrapperRef.current = element;
    events.on(element, 'mouseover', e => openMenu(e));
    events.on(element, 'mouseout', e => openMenu(e));
  }, [openMenu]);


  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;

    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      currentPath === '/configuration' || currentPath === '/about'
        ? treeView.selectItem('/home')
        : treeView.selectItem(currentPath)
      //treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  function permissionUsers() {
    const newItems = [];

    navigation.forEach((item) => {
      permissions.forEach((permission) => {
        item.items.forEach((value, index) => {
          value.id.forEach((id) => {
            if(id === permission){
              const checkItem = newItems.find(newItem => newItem.text === item.text);
      
              if(checkItem !== undefined && checkItem.text === item.text){
                newItems.forEach((newItem) => {
                  if(newItem.text === item.text) {
                    let enabledPush = true;
                    newItem.items.forEach((objItem) => {
                      if(objItem.text === value.text) enabledPush = false
                    })
                    if(enabledPush) newItem.items.push(value);
                  }
                });
              } else {
                const newObject = {
                  text: item.text,
                  icon: item.icon,
                  items: [value],
                };

                newItems.push(newObject);
              };
            };
          })
        });
      });
    });
    newItems.unshift(navigation[0]);
    newItems.push(navigation[navigation.length - 1]);
    setItems(newItems);
  }

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      {children}
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          onItemRendered={e => e.component.collapseAll()}
          onItemExpanded={(e) => {
            e.component.getNodes().forEach(node => {
              if(node.key !== e.node.key)
                e.component.collapseItem(node.key);
            });
          }}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={'100%'}
          height={isLarge ? "100%" : "80% "}
        />
      </div>
    </div>
  );
}
