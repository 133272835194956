/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import "../../"
import { Tabs } from "devextreme-react";
import Group from "./group";
import Analysis from "./analysis";
import { FavButton } from "../../../components/fav-button/favButton";

export default function AnalysisTab() {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <React.Fragment>
      <FavButton/>
      <Tabs 
				className={"tabs-panel"}
				items={["Grupo de Análise","Cadastro de Análise"]}
				onItemClick={e => setSelectedTab(e.itemIndex)}
				selectedIndex={selectedTab}
			/>
      {selectedTab === 0 ? <Group /> : ''}
			{selectedTab === 1 ? <Analysis /> : ''}
    </React.Fragment>
  );
}
