export const onEditorPreparingChangePassword = (e, setEvent, passwordChanged, setChangePassword) => {
    if (e.dataField === "ValSenhaUsuario" && e.parentType === "dataRow")  {
        setEvent(e);
        e.editorOptions = {
            stylingMode: "contained",
            text: passwordChanged ? '*********' : '',
            value: passwordChanged,
            hint: 'Alterar senha do Usuário',
            width: "128px",
            buttons: [ 
                'clear', 
                { 
                    name: 'passwordChange',
                    location: 'before', 
                    options: { 
                        type: "default",
                        text: "Alterar",
                        onClick: function(e) {
                            setChangePassword(true);
                        }
                    } 
                }
            ]
        }
    }
}