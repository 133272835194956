/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/form";
import Product from "./product";
import ProductSituation from "./productSituation";
import ProductGroup from "./productGroup";
import { FavButton } from "../../../components/fav-button/favButton";

export default function ProductTab() {

  const [update, setUpdate] = useState([]);

  /*useEffect(() => {
    async function firstLoad() {
      if (typeof data !== "undefined") {
        setUserTab(data.getUserTab);
        setUserPermissionTab(data.getUserPermissionTab);
      }
    }
    firstLoad();
  }, [loading]);*/

  const renderProductTab = () => {
    return <Product
            update={update}
            setUpdate={setUpdate}
           />;
  };

  const renderProductGroupTab = () => {
    return <ProductGroup
            update={update}
            setUpdate={setUpdate}
           />;
  };

  const renderProductSituationTab = () => {
    return <ProductSituation
            update={update}
            setUpdate={setUpdate}
           />;
  };

  return (
    <React.Fragment>
      <FavButton/>
      <TabPanel
        className={"tab-panel-margin"}
        animationEnabled={true}
        swipeEnabled={false}
      >
        <Item title="Grupo" render={renderProductGroupTab} />
        <Item title="Produto" render={renderProductTab} />
        <Item title="Situação" render={renderProductSituationTab} />
      </TabPanel>
    </React.Fragment>
  );
}
