/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "devextreme-react/button";
import { api } from "../../services/api";
import { useAuth } from "../../contexts/auth";
import React, { useEffect, useState } from "react";
import { LogMessageCheckboxList } from "../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import star_outline from '../../icons/star-outline.svg'
import star from '../../icons/star.svg'
import "./style.scss";

export const FavButton = () => {
	const { unitCod, userCod } = useAuth();
	const [load, setLoad] = useState(true);
	// const [favorites, setFavorites] = useState([]);
	const [favorite, setFavorite] = useState(null);
	let URL = createURL(window.location.href);

	useEffect(() => {
		if (load) loadDataFavorites();
	}, [load]);

	async function loadDataFavorites() {
		return await api
			.get("/favoriteuser/get", { params: { userId: userCod } })
			.then((response) => {
				setLoad(false);
				// setFavorites(response.data);
				setFavorite(findFavorite(response.data, URL, userCod));
			})
			.catch(() => {
				setLoad(true);
				return true;
			});
	}

	async function insertFavorite(data, parLogMessage) {
		return await api
			.post("/favoriteuser", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then((response) => {
				setLoad(true);
			})
			.catch(() => {
				setLoad(true);
				return true;
			});
	}

	async function deleteFavorite(data) {
		return await api
			.delete("/favoriteuser", { data: data })
			.then((response) => {
				setLoad(true);
			})
			.catch(() => {
				setLoad(true);
				return true;
			});
	}

	function findFavorite(array, route, user) {
		return array.find((element) => {
			return element.NomRota === route && element.CodUsuario === user;
		});
	}

	function createURL(fullURL) {
		let urlElements = fullURL.split("/");
		fullURL = `${urlElements[urlElements.length - 1]}`;
		return fullURL;
	}

	function favClick() {
		let data = {
			data: {
				CodUsuario: userCod,
				NomRota: URL,
			},
		};
		if (favorite) {
			let logMessage = LogMessageCheckboxList(formatMessage("DeletedLog"), data);
			data.logMessage = logMessage;
			data.unitCod = unitCod;
			deleteFavorite(data);
		} else {
			let logMessage = LogMessageCheckboxList(formatMessage("InsertedLog"), data);
			insertFavorite(data.data, logMessage);
		}
	}

	return (
		<div>
			<Button className={`FavButton ${JSON.parse(localStorage.getItem("@es-mes/darkMode")) ? "dark-mode" : ""}` } hint="Adicionar aos favoritos" icon={favorite ? star : star_outline} onClick={favClick} />
		</div>
	);
};
