import React, { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import { Button, TextArea } from "devextreme-react";
import { SelectBox } from "devextreme-react/select-box";
import { useDataLoader } from "../../contexts/dataLoaderContext";
import { AddItems } from "./AddItems";
import { ObjSampleSave } from "./objSampleSave";
import "./style.scss";
import { useScreenSize } from "../../utils/media-query";
import HourBox from "./HourBox";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
	const { isSmall, isXSmall, isXLSmall, isMedium, isLarge } = useScreenSize();
	const [clickButton, setClickButton] = useState(false);
	const { setVisiblePopUp, indexObservation, boolReanalysis, listToSaveSample, listToSaveDescSample, numLot, currencyDate, hourSample, periodUnit } = useDataLoader();
	let _currencyValueDate = useRef(props.isLot ? new Date(props.currencyValueDate) : new Date(props.sampleHour));

	useEffect(() => {
		if(_currencyValueDate.current === new Date(props.sampleHour) && !props.isLot) {
			_currencyValueDate.current.setDate(new Date(props.currencyValueDate).getDate());
			_currencyValueDate.current.setMonth(new Date(props.currencyValueDate).getMonth());
			_currencyValueDate.current.setFullYear(new Date(props.currencyValueDate).getFullYear());
		}
	}, [])

	useEffect(() => {
		_currencyValueDate.current.setDate(new Date(props.currencyValueDate).getDate());
		_currencyValueDate.current.setMonth(new Date(props.currencyValueDate).getMonth());
		_currencyValueDate.current.setFullYear(new Date(props.currencyValueDate).getFullYear());
	}, [props.currencyValueDate])

	useEffect(() => {
		if(numLot)
			onChange({event: "numLot"}, "dxTextBox")
	}, [numLot])

	//#region events
	const onChange = (e, component) => {
		if (e.event !== undefined) {
			if (props.isLot) {
				if (component === "observationSample") listToSaveDescSample.current["0:0"] = e.component.option("value");
				if (component === "selectBoxUser") listToSaveDescSample.current["0:1"] = e.component.option("value");

				//Altera o código usuario de todos os elementos a serem salvos
				listToSaveSample.current.forEach((element) => {
					if (e.event.type === "dxclick") element.CodUsuario = listToSaveDescSample.current["0:1"];
					element.IsReanalise = props._refButtonReAnalysis.current["0:0"].instance.option("value");

					if(element.CodAnalise !== 0) 
						element.DatAnaliseAmostra = _currencyValueDate.current;
				});
			} else {
				if (component === "selectBoxUser") {
					//SelectBox

					props._refUserSelectBox.current[`${props.indexAnalysis}:${props.index}`].instance.option("value", e.component.option("value"));
				}
			}
			
			if(!props.isLot) {
				listToSaveSample.current.forEach((element) => {
					if(element.index === `${props.indexAnalysis}:${props.index}`)
						_currencyValueDate.current = new Date(element.DatAnaliseAmostra)
				});
			}
			
			const newObj = ObjSampleSave(
				props.isLot ? props.currencyValueDate : e.component.option("date"),
				props.analysisTimeList[props.indexAnalysis],
				props.analysisGroupCode,
				props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value"),
				props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.getButton("checkValue").option("elementAttr.id") === "reproved-icon" ? 0 : 1,
				props._refBtnNotWorking.current[`${props.indexAnalysis}:${props.index}`].instance.option("value"),
				props.isLot ? numLot : null,
				props.userCod,
				props.isLot,
				true,
				props._refLabelHour,
				props._refUserSelectBox,
				props._refObservation,
				props._refButtonReAnalysis,
				listToSaveDescSample,
				`${props.indexAnalysis}:${props.index}`,
				false,
				(props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value") === props._lstPreviousValue.current[`${props.indexAnalysis}:${props.index}`])
				? "" : props._lstPreviousValue.current[`${props.indexAnalysis}:${props.index}`],
				props.sampleHour ? props.sampleHour : props.lstDescObservation.length > 0 ? dayjs(props.lstDescObservation[0].DatAnaliseAmostra).format('YYYY-MM-DD HH:mm') : _currencyValueDate.current,
				_currencyValueDate.current,
				props.tipPeriodUnit
			);
			
			AddItems(listToSaveSample, props.isLot, newObj, "", `${props.indexAnalysis}:${props.index}`);
		}
	};

	const onInitializedResponsible = (e) => {
		e.component.option("date", props.analysisTimeList[props.indexAnalysis][5][props.index]);
		e.component.option("value", props.userCod);

		//Carrega usuario do banco
		props.listAnalysisSample.forEach((element) => {
			if (props.isLot === true) {
				if (props.analysisTimeList[props.indexAnalysis][8].CodAnalise === element.CodAnalise)
					e.component.option("value", element.CodUsuario);
			} else {
				if (
					((dayjs(element.DatAnaliseAmostra).format("LT") === props._refLabelHour.current[`${props.indexAnalysis}:${props.index}`]) ||
					(dayjs(element.DatAnaliseAmostra).format("LT") === dayjs(_currencyValueDate.current).format("LT"))) &&
					props.analysisTimeList[props.indexAnalysis][8].CodAnalise === element.CodAnalise
				) 
					e.component.option("value", element.CodUsuario);
			}
		});

		//Desabilita campo
		if (props.isLot === false) {
			if (
				props._refInput.current[`${props.indexAnalysis}:${props.index}`] !== null &&
				props._refInput.current[`${props.indexAnalysis}:${props.index}`] !== undefined
			) {
				if (
					props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value") === null ||
					props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value") === ""
				)
					e.component.option("disabled", true);
			}
		} else {
			numLot === undefined || numLot === "" || numLot === 0 ? e.component.option("disabled", true) : e.component.option("disabled", false);
			listToSaveDescSample.current["0:1"] = e.component.option("value");
		}
	};

	const onInitializedReanalysis = (e) => {

		e.component.option("date", props.analysisTimeList[props.indexAnalysis][5][props.index]);

		if (props.isLot === false) {
			if (
				props._refInput.current[`${props.indexAnalysis}:${props.index}`] !== null &&
				props._refInput.current[`${props.indexAnalysis}:${props.index}`] !== undefined
			) {
				if (
					props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value") === null ||
					props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value") === ""
				)
					e.component.option("disabled", true);
			}
		} else {
			numLot === undefined || numLot === "" || numLot === 0 ? e.component.option("disabled", true) : e.component.option("disabled", props.actionOnlyViewer);
		}
		setClickButton(false);
	};

	const onInitializedGroupObservation = (e) => {

		e.component.option("date", props.analysisTimeList[props.indexAnalysis][5][props.index]);

		if (props.isLot === false) {
			if (
				props._refInput.current[`${props.indexAnalysis}:${props.index}`] !== null &&
				props._refInput.current[`${props.indexAnalysis}:${props.index}`] !== undefined
			) {
				if (
					props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value") === null ||
					props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value") === ""
				)
					e.component.option("disabled", true);
			}
		} else {
			numLot === undefined || numLot === "" || numLot === 0 ? e.component.option("disabled", true) : e.component.option("disabled", props.actionOnlyViewer);
		}
		
		//Se o camponente da hora é igual ao da amostra e o código do componente é igual ao da analise, para saber em qual componente inserir o valor do banco
		props.listAnalysisSample.forEach((element) => {
			if (
				((dayjs(element.DatAnaliseAmostra).format("LT") === props._refLabelHour.current[`${props.indexAnalysis}:${props.index}`])
				|| (dayjs(element.DatAnaliseAmostra).format("LT") === dayjs(_currencyValueDate.current).format("LT")))
				&& props.analysisTimeList[props.indexAnalysis][8].CodAnalise === element.CodAnalise
			) {
				let indexDescObservation = "";
				if (props.lstDescObservation.length > 0) {
					if(props.tipPeriodUnit !== 7 && props.tipPeriodUnit !== 4 && !props.isLot) {
						indexDescObservation = props.lstDescObservation.findIndex(
							(val) => val.TimeOfDay === props._refLabelHour.current[`${props.indexAnalysis}:${props.index}`].concat(":00")
						);
					} else {
						const date = new Date(props.sampleHour);
						const hours = date.getHours().toString().padStart(2, "0");
						const minutes = date.getMinutes().toString().padStart(2, "0");
						const strDate = `${hours}:${minutes}`.concat(":00");

						indexDescObservation = props.lstDescObservation.findIndex((val) => val.TimeOfDay === strDate);
					}
				}
				if (indexDescObservation !== -1 && indexDescObservation !== undefined && indexDescObservation !== "")
					e.component.instance().option("value", props.lstDescObservation[indexDescObservation].DesObservacao);
			}
		});
	};

	const onInitializedSampleObservation = (e) => {
		e.component.option("date", props.analysisTimeList[props.indexAnalysis][5][props.index]);

		if (props.isLot === false) {
			if (
				props._refInput.current[`${props.indexAnalysis}:${props.index}`] !== null &&
				props._refInput.current[`${props.indexAnalysis}:${props.index}`] !== undefined
			) {
				if (
					props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value") === null ||
					props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value") === ""
				)
					e.component.option("disabled", true);
			}

			//Se o camponente da hora é igual ao da amostra e o código do componente é igual ao da analise, para saber em qual componente inserir o valor do banco
			props.listAnalysisSample.forEach((element) => {
				if (
					((dayjs(element.DatAnaliseAmostra).format("LT") === props._refLabelHour.current[`${props.indexAnalysis}:${props.index}`])
					|| (dayjs(element.DatAnaliseAmostra).format("LT") === dayjs(_currencyValueDate.current).format("LT")))
					&& props.analysisTimeList[props.indexAnalysis][8].CodAnalise === element.CodAnalise
				) {
					e.component.instance().option("value", element.DesObservacao);
				}
			});
		} else {
			//se for LOTE
			if (numLot === undefined || numLot === "" || numLot === 0) {
				e.component.option("disabled", true);
				e.component.option("value", "");
			} else {
				e.component.option("disabled", props.actionOnlyViewer);
				e.component.option("value", "");
				listToSaveDescSample.current = [];

				if (props.lstDescObservation !== undefined) {
					if (props.lstDescObservation.length > 0) {
						e.component.option("value", props.lstDescObservation[0].DesObservacao);
						_currencyValueDate.current = new Date(props.lstDescObservation[0].DatAnaliseAmostra)
						listToSaveDescSample.current["0:0"] = e.component.option("value");

					}
				}
			}
		}
	};
	//#end region events

	return (
		<div>
			<div style={{ float: "left", 
				marginRight: isXSmall || isXLSmall ? 5 : 25, 
				width: props.isLot ? 200 : isLarge ? 150 : isMedium ? 80 : isSmall ? 80 : 80
			 }}>
				<div
					className="dx-field-label"
					style={{
						width: 100,
						display: props.headerLotRender && !isXSmall ? "inline" : props.isLot && !isXSmall ? "none" : props.index === 0 && !isXSmall ? "inline-block" : "none",
					}}>
					{isXSmall || isXLSmall ? "Respons." : "Responsável"}
				</div>
				<SelectBox
					dataSource={props.lstUserDataSource}
					valueExpr={"CodUsuario"}
					displayExpr={"NomUsuario"}
					onValueChanged={(e) => onChange(e, "selectBoxUser")}
					dropDownOptions={{
						width: 250,
					}}
					name="UserSelectBox"
					readOnly={props.actionOnlyViewer}
					defaultValue={props.userCod}
					showClearButton={false}
					onInitialized={(e) => onInitializedResponsible(e)}
					width={"100%"}
					height={42}
					stylingMode="outlined"
					visible={
						!props.isLot
							? props.analysisTimeList[props.indexAnalysis][5].some(
									(item) => dayjs(item).format("LT") === dayjs(props.sampleHour).format("LT")
							  ) && !isXSmall //Verifica se a hora é maior que a hora atual
							: props.headerLotRender && !isXSmall
					}
					id={!props.isLot ? `${props.indexAnalysis}:${props.index}` : "0:0"}
					ref={
						!props.isLot
							? (node) => {
									props._refUserSelectBox.current[`${props.indexAnalysis}:${props.index}`] = node;
							  }
							: (node) => {
									props._refUserSelectBox.current["0:0"] = node;
							  }
					}
				/>
			</div>

			<div style={{ marginRight: isXSmall  ? -25 : isXLSmall ? 5 : 25,
						width: 42, 
						float: "left" }}>
				<div
					className="dx-field-label"
					style={{
						width: isXSmall ? 50 : 80,
						display: props.headerLotRender ? "inline" : props.isLot ? "none" : props.index === 0 ? "inline-block" : "none",
					}}>
					{isXSmall || isXLSmall ? "Rean." : "Reanálise"}
				</div>
				<div id={clickButton ? "reanalysis-button" : "reanalysis-button-clicked"} >
					<Button
						name="ButtonReAnalysis"
						height={42}
						width={"100%"}
						disabled={props.actionOnlyViewer}
						stylingMode="outlined"
						//onValueChanged={onChange}
						//width={35}
						onClick={(e) => {
							let valueReanalysis = e.component.option("value");
							props._refButtonReAnalysis.current["0:0"].instance.option("value", !valueReanalysis);
							e.component.option("value", !valueReanalysis);
							onChange(e, "buttonReanalysis");
							setClickButton(!clickButton);
							boolReanalysis.current = !boolReanalysis.current;
						}}
						onInitialized={(e) => onInitializedReanalysis(e)}
						visible={
							!props.isLot
								? props.analysisTimeList[props.indexAnalysis][5].some(
										(item) => dayjs(item).format("LT") === dayjs(props.sampleHour).format("LT")
								  ) //Verifica se a hora é maior que a hora atual
								: props.headerLotRender
						}
						id={!props.isLot ? `${props.indexAnalysis}:${props.index}` : "0:0"}
						ref={
							!props.isLot
								? (node) => {
										props._refButtonReAnalysis.current[`${props.indexAnalysis}:${props.index}`] = node;
								  }
								: (node) => {
										props._refButtonReAnalysis.current["0:0"] = node;
								  }
						}
						icon={"rename"}
						//text= "Reanálise"
						hint={clickButton ? "O registro será salvo como Reanálise" : "Clique para o registro ser salvo como Reanálise"}></Button>
				</div>
			</div>

			<div
				style={{
					marginRight: isXSmall ? 0 : isXLSmall || isSmall ? 5 : 25,
					width: props.isLot ? 250 : isSmall || isXSmall || isXLSmall ? 110 : isMedium ? 160 : 450,
					float: "left",
				}}>
				<div
					className="dx-field-label"
					style={{
						width: 100,
						display: props.headerLotRender && !isXSmall ? "inline" : props.isLot && !isXSmall  ? "none" : props.index === 0 && !isXSmall  ? "inline-block" : "none",
					}}>
					Observação
				</div>
				<TextArea
					name="ObservationAnalysis"
					height={42}
					stylingMode="outlined"
					disabled={props.actionOnlyViewer}
					width={"100%"}
					onChange={(e) => onChange(e, "observationSample")}
					onInitialized={(e) => onInitializedSampleObservation(e)}
					visible={
						!props.isLot
							? props.analysisTimeList[props.indexAnalysis][5].some(
									(item) => dayjs(item).format("LT") === dayjs(props.sampleHour).format("LT")
							  ) && !isXSmall//Verifica se a hora é maior que a hora atual
							: props.headerLotRender && !isXSmall
					}
					id={!props.isLot ? `${props.indexAnalysis}:${props.index}` : "0:0"}
					ref={
						!props.isLot
							? (node) => {
									props._refObservation.current[`${props.indexAnalysis}:${props.index}`] = node;
							  }
							: (node) => {
									props._refObservation.current["0:0"] = node;
							  }
					}
				/>
			</div>
			{props.isLot  && props.tipPeriodUnit === 7 ? 
			<div style={{display: "inline-block", float: "left", marginRight: "25px"}}> 
				<div
					className="dx-field-label"
					style={{
						width: 100,
						display: "inline",
					}}>
					Hora de Análise
				</div>
				<div style={{width: 500}}>
					<HourBox 
						currencyValueDate={_currencyValueDate}
						onValueChanged={onChange}
					/>
				</div>
			</div>
				: ""
			}
		</div>
	);
};
