import React, { useState } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/form";
import { FavButton } from "../../../components/fav-button/favButton";
import Email from "./email";
import Configuration from "./configuration";

export default function EmailTab() {
  const [update, setUpdate] = useState(0);

  const renderEmailTab = () => {
    return <Email update={update} setUpdate={setUpdate} />;
  };

  const renderConfigurationTab = () => {
    return <Configuration update={update} />;
  };
  return (
    <React.Fragment>
      <FavButton />
      <TabPanel
        className={"tab-panel-margin"}
        animationEnabled={true}
        swipeEnabled={false}
      >
        <Item title="Email" render={renderEmailTab} />
        <Item title="Configurações" render={renderConfigurationTab} />
      </TabPanel>
    </React.Fragment>
  );
}
