import React, { useState } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/form";
import OpcGroup from "./opcGroup";
import OpcConnection from "./opcConnection";
import OpcConnectionGroup from "./opcConnectionGroup";
import ServerTypeOpc from "./serverTypeOPC";
import ServerOpc from "./serverOPC";
import { FavButton } from "../../../components/fav-button/favButton";
import TestOpc from "./testOPC";

export default function ConfigurationOpcTab() {
  const [update, setUpdate] = useState(0);

  const renderServerTypeOpc = () => {
    return (
      <ServerTypeOpc
        update={update}
        setUpdate={setUpdate}
      />
    )
  };

  const renderServerOpc = () => {
    return (
      <ServerOpc
        update={update}
        setUpdate={setUpdate}
      />
    )
  };

  const renderOpcGroup = () => {
    return (
      <OpcGroup
        setUpdate={setUpdate}
        update={update}
      />
    )
  };

  const renderOpcConnection = () => {
    return (
      <OpcConnection
        setUpdate={setUpdate}
        update={update}
      />
    )
  };

  const renderOpcConnectionGroup = () => {
    return (
      <OpcConnectionGroup
        setUpdate={setUpdate}
        update={update}
      />
    )
  };

  const renderTestOpc = () => {
    return (
      <TestOpc 
        update={update}
      />
    )
  };

  return (
    <React.Fragment>
      <FavButton/>
     {/*  <HelpButton
        registerGrid={pageSelected === 0 ? "serverTypeOpc" 
                        : pageSelected === 1 ? "serverOpc" 
                        : pageSelected === 2 ? "opcConnection" 
                        : pageSelected === 3 ? "opcGroup"
                        : pageSelected === 4 ? "opcConnectionGroup"
                        : "testOpc"
                     }
      /> */}
      <TabPanel
        className={"tab-panel-margin"}
        animationEnabled={true}
        swipeEnabled={false}
      >
        <Item
          title="Tipo Servidor"
          render={renderServerTypeOpc}
        />
        <Item
          title="Servidor OPC"
          render={renderServerOpc}
        />
        <Item
          title="Conexão OPC"
          render={renderOpcConnection}
        />
        <Item
          title="Grupo OPC"
          render={renderOpcGroup}
        />
        <Item
          title="Conexão Grupo OPC"
          render={renderOpcConnectionGroup}
        />
        <Item 
          title="Teste OPC" 
          render={renderTestOpc} 
        />
      </TabPanel>
    </React.Fragment>
  );
}
