import React from 'react';
import Button from 'devextreme-react/button';
import flagGray from "../../icons/flagGray.svg";
import flagRed from "../../icons/flagRed.svg";
import flagYellow from "../../icons/flagYellow.svg";
import "./style.scss";

export default function CardNotification(props) {
    function templateColorCard() {
        if(props.color === "Desvio") return "dx-card LineCardDeviations";
        if(props.color === "Tarefa") return "dx-card LineCardTasks";
        if(props.color === "Variáveis") return "dx-card LineCardVariables";
    };

    function iconSeverity() {
        if(props.severity === 0) return flagRed;
        if(props.severity === 1) return flagYellow;
        if(props.severity === 2 || props.severity === null) return flagGray;
    };

    function recoveryNotification(e) {
        const notification = {
            CodAlarme: props.alarmCode,
            CodVariavelLeitura: props.codReadingVariable,
            NomAlarme: props.title,
            DesMensagem: props.description,
            TipoAlarme: props.color,
            TipSeveridade: props.severity,
        };

        const lstNotifications = [notification];

        props.onClickRecognize(lstNotifications);
    };

    function messageTooltip() {
        if(props.severity === 0) return "Severidade: Alta";
        if(props.severity === 1) return "Severidade: Média";
        if(props.severity === 2 || props.severity === null) return "Severidade: Baixa";
    };

    return (
        <div className={"ContainerCard-notification"}>
            <div className={templateColorCard()}>
                <div className={"LineCard"}>
                    <div className={"SpaceLine"}>
                        <p className="TitleNotification">{props.title}</p>
                        <p className="DescriptionNotification">{props.description}</p>
                    </div>
                    <div className={"ContainerButtonsCard"}>
                        <Button
                            className={"BackgroundColorButtonFlags"}
                            hint={messageTooltip()}
                            icon={iconSeverity()}
                        />
                        <Button
                            hint={"Reconhecer a notificação"}
                            icon={"check"}
                            onClick={(e) => recoveryNotification(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};