/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { Button } from "devextreme-react";
import ButtonListSecondary from "./buttonListSecondary";

export default function ButtonList(props) {
	let _processCode = useRef()
	let _processName = useRef()
	
	//Usado para o botão de voltar
	useEffect(() => {
		if(!props.load){
			if(props.screen === "input"){
				props.setScreen("variable")
				props.setLoad(true)
				props.setSecondaryButtons(false);
				props.setSearchVariablesText("")
			}
			else if (props.screen === "variable"){
				props.setSecondaryButtons(false)
				props.setScreen("process")
				props.setLoad(true)
				props.setSearchProcessText("")
			}
		} 

		if(!props.secondaryButtons && props.screen === "variable") props.setSecondaryButtons(true)
	}, [props.load])

	return (
		<div className="content-block">
			{props.secondaryButtons ? 
				<ButtonListSecondary 
					processCode={_processCode.current} 
					processName={_processName.current}
					searchVariablesText={props.searchVariablesText}
					screen={"variable"} 
					setScreen={props.setScreen}
					setSecondaryButtons={props.setSecondaryButtons} 
					setLstVariables={props.setLstVariables}
					refHeaderSelectBox={props.refHeaderSelectBox}
					refHeaderDateBoxValue={props.refHeaderDateBoxValue}
					currencyValueDate={props.currencyValueDate}
					setCurrencyValueDate={props.setCurrencyValueDate}
					userCod={props.userCod}
				/>
			:
				<div>
					<h2>Selecionar processo</h2>
					{props.list.map((value, i) =>
						<Button
							key={i}
							className={"process-button"}
							text={props.screen === "process" ? value.NomProcesso : ""}
							stylingMode="outlined"
							searchEnabled={false}
							onClick={() => {
								_processCode.current = value.CodProcesso;
								_processName.current = value.NomProcesso;
								props.setSecondaryButtons(true);
								props.setScreen("variable")
								props.setLoad(true)
							}}
						/>
					)}
				</div>
			}
		</div>
	);
}
