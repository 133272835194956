/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import Scheduler from 'devextreme-react/scheduler'
import { LoadIndicator } from "devextreme-react/load-indicator";
import { SelectBox } from 'devextreme-react'
import dayjs from 'dayjs'
import 'devextreme/data/odata/store'
import { api } from '../../services/api'
import { useAuth } from '../../contexts/auth'
import { useScreenSize } from '../../utils/media-query'
import '../../components/common/style.scss'
import './style.scss'
import { formatMessage } from 'devextreme/localization'
import { FavButton } from '../../components/fav-button/favButton'
import { ReportsButton } from '../../components/reports-button/reportsButton'
import { ImageUploaderInline } from "../../components/image-uploader/imageUploaderInline";
import { useDataLoader } from '../../contexts/dataLoaderContext';
import { Template } from 'devextreme-react/core/template';
import '../../components/common/style.scss';
import '../../dx-styles.scss'

export default function CreateTask(props) {
  const { refreshToken, getRefreshTokenCode, getToken, unitCod, userCod } = useAuth()
  const { isXSmall, isLarge, isMedium } = useScreenSize()
  const { objectImgToDataBase } = useDataLoader();
  const [tasks, setTasks] = useState([])
  const [areaProcess, setAreaProcess] = useState([])
  const [executorPermission, setExecutorPermission] = useState([])
  const [monitorPermission, setMonitorPermission] = useState([])
  const [verificadorPermission, setVerificadorPermission] = useState([])
  const [filterTasks, setFilterTasks] = useState(false);
  const [user, setUser] = useState([])
  const [load, setLoad] = useState(false)
  const [loading, setLoading] = useState(true)
  const _dateInitial = useRef()
  const _dateEnd = useRef()
  const _dateEndPeriodicTask = useRef()
  const _executorUser = useRef()
  const _monitorUser = useRef()
  const _verifyUser = useRef()
  const _event = useRef()
  const _eventPermissions = useRef()
  const _allDay = useRef()
  const _tasks = useRef()
  const imgs = useRef([])
  const _datTaskClicked = useRef();
  const _areaProcessSelected = useRef();
  const views = ['day', 'week', 'workWeek', 'month']
  const currentDate = new Date()

  useEffect(() => {
    if (unitCod !== undefined) firstLoad()
  }, [])

  useEffect(() => {
    if (load) loadData()
  }, [load])

  useEffect(() => {
    if(_areaProcessSelected.current) {
      const newLstTasks = _tasks.current.filter((task) => task.CodAreaProcesso === _areaProcessSelected.current);
      setTasks(newLstTasks);
    } else {
      setTasks(_tasks.current);
    }
  }, [filterTasks])

  async function firstLoad() {
    loadData()
    loadDataAreaProcess()
    //loadDataPermissions();
    loadDataExecutorPermissions()
    loadDataMonitorPermissions()
    loadDataVerificadorPermissions()
    loadDataUsers()
    refreshToken(getToken(), getRefreshTokenCode())
  }

  //#region requests
  async function loadData() {
    await api
      .get('/tasks', { params: { unitCod: unitCod } })
      .then((response) => {
        //setTasks(newLstTasks);
        _tasks.current = response.data;
        setFilterTasks(!filterTasks);
        setLoading(false);

        setLoad(false)
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function loadDataAreaProcess() {
    await api
      .get('/areaprocess', { params: { unitCod: unitCod } })
      .then((response) => {
        setAreaProcess(response.data)

        setLoad(false)
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function loadDataExecutorPermissions() {
    await api
      .get('/permission/getExecutorPermission', { params: { unitCod: unitCod } })
      .then((response) => {
        setExecutorPermission(response.data)
        setLoad(false)

        return false
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function loadDataMonitorPermissions() {
    await api
      .get('/permission/getMonitorPermission', { params: { unitCod: unitCod } })
      .then((response) => {
        setMonitorPermission(response.data)
        setLoad(false)

        return false
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function loadDataVerificadorPermissions() {
    await api
      .get('/permission/getVerificadorPermission', { params: { unitCod: unitCod } })
      .then((response) => {
        setVerificadorPermission(response.data)
        setLoad(false)

        return false
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function loadDataUsers() {
    await api
      .get('/user', { params: { unitCod: unitCod } })
      .then((response) => {
        if (userCod === 0) {
          setUser(response.data)
        } else {
          setUser(response.data.filter((x) => x.CodUsuario !== 0))
        }

        setLoad(false)
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function getImages(primaryCod, secondaryCod) {
		await api
			.get("/image/get", { params: { unitCod, programCod: 3110, primaryCod, secondaryCod } })
			.then((response) => {
        imgs.current = [];
				imgs.current = response.data;
			})
			.catch(() => {
				return true;
			});
	}

  async function insertTask(data, parLogMessage) {
    return await api
      .post('/tasks', { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true)

        return false
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function deleteTask(data) {
    return await api
      .delete('/tasks', { data: data })
      .then((response) => {
        setLoad(true)

        return false
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function updateTask(data, parLogMessage) {
    return await api
      .put('/tasks/updateCreateTask', { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true)

        return false
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }
  //#endregion

  async function renderAppointmentFormOpening(e) {
    let numberTask = null;

    if(e.appointmentData.CodTarefa && e.appointmentData.TipPeriodica) 
      numberTask= searchNumberTask(e.appointmentData);

    appointmentFormOpening(e);
    await getImages(e.appointmentData.CodTarefa, numberTask);

    e.popup.repaint();
    e.form.repaint();

    const form = e.form
    invisibleItemsRecurrenceRule(form)
  }

  //#region appointmentFormOpening
  function appointmentFormOpening(e) {
    _dateEndPeriodicTask.current = undefined;
    e.popup.option('height', '95%')
    e.popup.option('minWidth', '500px')
    e.popup.option('showTitle', true)
    e.popup.option("showCloseButton", true)
    e.popup.option('title', 'Criar Tarefa')
    e.popup.option('onHiding', () => {
      window.scrollTo(0, 0)
    })
    
    const form = e.form
    let mainGroupItems = form.itemOption('mainGroup').items
    let recurrenceGroupItems = form.itemOption('recurrenceGroup').items
    let dateEndTaskPeriodic
    let recurrenceStringToArray
    
    _dateInitial.current = e.appointmentData.DatIniciaMonitoracao
    _dateEnd.current = e.appointmentData.DatFinalizaMonitoracao
    _executorUser.current = e.appointmentData.CodUsuarioExecutor
    _monitorUser.current = e.appointmentData.CodUsuarioMonitor
    _verifyUser.current = e.appointmentData.CodUsuarioVerificador
    
    if(e.appointmentData.DatFinalizaTarefa) {
      if (form.getEditor('recurrenceRule')) 
        form.getEditor('recurrenceRule')._repeatUntilDate.option('value', dayjs(e.appointmentData.DatFinalizaTarefa).subtract(1, 'day'));
    } else {
      if (form.getEditor('recurrenceRule')) 
        form.getEditor('recurrenceRule')._repeatUntilDate.option('value', dayjs());
    }
    
    if (e.appointmentData.recurrenceRule && e.appointmentData.recurrenceRule.length > 0) {
      recurrenceStringToArray = e.appointmentData.recurrenceRule.split(';')

      recurrenceStringToArray.forEach((element, index) => {
        const findUntilInArray = element.indexOf('UNTIL')

        if (findUntilInArray > -1) dateEndTaskPeriodic = recurrenceStringToArray[index].substr(6, 8)
      })
    }
    
    if (form.getEditor('allDay') !== undefined) _allDay.current = form.getEditor('allDay').option('value')

    const itemsRadioButton = document.getElementsByClassName('dx-item dx-radiobutton')
    const itemsRepeatOn = document.getElementsByClassName('dx-recurrence-repeat-on dx-field')
    if (itemsRadioButton.length > 0) itemsRadioButton[2].style.display = 'none'
    if (itemsRepeatOn.length > 0) itemsRepeatOn[0].style.display = 'none'
    
    function tomorrow() {
      var tomorrow = new Date(e.appointmentData.DatIniciaMonitoracao)

      return tomorrow.setHours(23, 59, 59)
    }

    recurrenceGroupItems[0].editorOptions = {
      readOnly: false,
      editorType: 'dxRecurrenceEditor',
      onValueChanged: function (e) {
        //Deixa invisível os campos que não precisamos do reccurrenceRule na troca de valores.
        form.getEditor('recurrenceRule')._recurrenceForm.option('items[0].editorOptions.items[0].visible', false)
        form.getEditor('recurrenceRule')._recurrenceForm.option('items[0].editorOptions.items[4].visible', false)
        form.getEditor('recurrenceRule')._recurrenceForm.option('items[3].items[2].visible', false)
        form.getEditor('recurrenceRule')._recurrenceForm.option('items[2].items[0].visible', false)
        form.getEditor('recurrenceRule')._recurrenceForm.option('items[4].items[0].editorOptions.items[2].visible', false)
        form.getEditor('recurrenceRule')._repeatCountEditor.option('visible', false)
        if (form.getEditor('recurrenceRule')._weekEditor) form.getEditor('recurrenceRule')._weekEditor.option('visible', false)

        //Lógica para subtrair ou manter a data no recurrenceRule
        
        let strActualValueDate = "";
        let strPreviousValueDate = "";

        if(e.value && e.previousValue) {
          const indexActualValueDate = e.value.indexOf('UNTIL');
          const indexPreviousValueDate = e.previousValue.indexOf('UNTIL');

          if(indexActualValueDate > -1) {
            strActualValueDate = e.value.substring(indexActualValueDate, indexActualValueDate+22); 
            strPreviousValueDate = e.previousValue.substring(indexPreviousValueDate, indexPreviousValueDate+22);
          }
        }
        
        if (e.value && strActualValueDate != strPreviousValueDate) {
          _dateEndPeriodicTask.current = undefined
          const findUntil = e.value.indexOf('UNTIL')
          const verifyFormat = e.value.indexOf('T03')

          if (findUntil > -1) {
            const recurrenceStringToArray = e.value.split(';')

            recurrenceStringToArray.forEach((element, index) => {
              const findUntilInArray = element.indexOf('UNTIL')

              if (findUntilInArray > -1) {
                const newDateEndTaskPeriodic = recurrenceStringToArray[index].substr(6, 8);

                verifyFormat === -1
                  ? (_dateEndPeriodicTask.current = dayjs(newDateEndTaskPeriodic).subtract(1, 'day'))
                  : (_dateEndPeriodicTask.current = dayjs(dateEndTaskPeriodic))
              }
            })
          }
        }
      },
      onInitialized: function (e) {
        if (!form.getEditor('TipAprovacaoMonitor').option('value')) {
          const invisibleItems = document.getElementsByClassName('dx-first-col dx-last-col dx-field-item dx-col-0 dx-field-item-optional dx-flex-layout dx-label-v-align')
          const labelVerify = document.getElementsByClassName('dx-field-item-label dx-field-item-label-location-top')

          invisibleFields(invisibleItems, labelVerify)
        }
      },
      onDisposing: function (e) {
        if (!form.option().formData.TipAprovacaoMonitor) {
          setTimeout(() => {
            const invisibleItems = document.getElementsByClassName('dx-first-col dx-last-col dx-field-item dx-col-0 dx-field-item-optional dx-flex-layout dx-label-v-align')
            const labelVerify = document.getElementsByClassName('dx-field-item-label dx-field-item-label-location-top')

            invisibleFields(invisibleItems, labelVerify)
          }, 300)
        }
      },
    }
    
    //Configuração dos campos do formulário de Criação de tarefas
    mainGroupItems[0].label = { text: 'Tarefa' }
    mainGroupItems[0].dataField = 'NomTarefa'
    mainGroupItems[2].items[1].dataField = 'TipPeriodica'
    mainGroupItems[0].validationRules = [{ type: 'required' }]
    mainGroupItems[4].validationRules = [{ type: 'required' }]
    mainGroupItems[4].editorOptions = {
      autoResizeEnabled: true
    }
    mainGroupItems[1].items[0].editorOptions = {
      calendarOptions: { firstDayOfWeek: undefined },
      type: 'datetime',
      width: '100%',
      adaptivityEnabled: true,
      onValueChanged: function (e) {
        _dateInitial.current = e.value
        _allDay.current = form.getEditor('allDay').option('value')
        const datTomorrow = new Date(e.value)
        datTomorrow.setHours(23, 59, 59)

        if (_allDay.current === true) {
          form.getEditor('DatFinalizaMonitoracao').option('value', datTomorrow)
          form.getEditor('DatFinalizaMonitoracao').option('max', datTomorrow)
          form.getEditor('DatFinalizaMonitoracao').option('min', datTomorrow)
        } else {
          form.getEditor('DatFinalizaMonitoracao').option('value', datTomorrow)
          form.getEditor('DatFinalizaMonitoracao').option('max', datTomorrow)
          form.getEditor('DatFinalizaMonitoracao').option('min', e.value)
        }

        form.validate()

        if (_event.current && _event.current.validator._validationRules[1].validationCallback !== undefined) 
          _event.current.validator._validationRules[1].validationCallback(_event.current)
      },
      refreshMode: 'full',
    }

    mainGroupItems[1].items[2].editorOptions = {
      calendarOptions: { firstDayOfWeek: undefined },
      type: 'datetime',
      width: '100%',
      adaptivityEnabled: true,
      value:
        form.getEditor('allDay') !== undefined && form.getEditor('allDay').option('value')
          ? form.getEditor('DatFinalizaMonitoracao').option('value', tomorrow())
          : form.getEditor('DatFinalizaMonitoracao').option('value', e.appointmentData.DatFinalizaMonitoracao),
      onValueChanged: function (e) {
        _dateEnd.current = e.value
        _allDay.current = form.getEditor('allDay').option('value')
        const datTomorrow = new Date(_dateInitial.current)
        datTomorrow.setHours(23, 59, 59)

        if (_allDay.current) {
          _dateEnd.current = datTomorrow
          form.getEditor('DatFinalizaMonitoracao').option('value', datTomorrow)
          form.getEditor('DatFinalizaMonitoracao').option('max', datTomorrow)
          form.getEditor('DatFinalizaMonitoracao').option('min', datTomorrow)
        } else {
          form.getEditor('DatFinalizaMonitoracao').option('max', datTomorrow)
          form.getEditor('DatFinalizaMonitoracao').option('min', new Date(_dateInitial.current))
        }

        form.validate()

        if (_event.current !== undefined && _event.current.validator._validationRules[1].validationCallback !== undefined)
          _event.current.validator._validationRules[1].validationCallback(_event.current)
      },
      onOpened: function (e) {
        const datTomorrow = new Date(_dateInitial.current)
        datTomorrow.setHours(23, 59, 59)

        if (form.getEditor('allDay').option('value')) {
          form.getEditor('DatFinalizaMonitoracao').option('max', datTomorrow)
          form.getEditor('DatFinalizaMonitoracao').option('min', datTomorrow)
        } else {
          form.getEditor('DatFinalizaMonitoracao').option('max', datTomorrow)
          form.getEditor('DatFinalizaMonitoracao').option('min', new Date(_dateInitial.current))
        }

        form.validate()
      },
      refreshMode: 'full',
    }

    mainGroupItems[1].items[0].validationRules = [{ type: 'required' }]
    mainGroupItems[1].items[1].validationRules = [{ type: 'required' }]
    mainGroupItems[3].visible = true
    
    if (mainGroupItems.length === 6)
      mainGroupItems.push(
        {
          colSpan: 2,
          label: { text: 'Área' },
          editorType: 'dxSelectBox',
          dataField: 'CodAreaProcesso',
          editorOptions: {
            items: areaProcess,
            displayExpr: 'NomAreaProcesso',
            valueExpr: 'CodAreaProcesso',
          },
          validationRules: [{ type: 'required' }],
        },
        {
          itemType: 'empty',
          colSpan: 2,
        },
        {
          itemType: 'empty',
          colSpan: 2,
        },
        {
          label: { text: 'Grupo Executor' },
        },
        {
          itemType: 'empty',
          colSpan: 2,
        },
        {
          colSpan: 2,
          label: { text: 'Especificar Executor' },
          editorType: 'dxCheckBox',
          dataField: 'TipUsuarioExecutor',
          value: false,
          editorOptions: {
            onValueChanged: function (args) {
              if (args.value && args.event !== undefined) {
                disabledCodPermissaoExecutor(form)

                form.getEditor('CodUsuarioExecutor').option('value', user.length > 0 ? user[0].CodUsuario : null)
              } else if (!args.value && args.event !== undefined) {
                disabledCodUsuarioExecutor(form)

                form.getEditor('CodPermissaoExecutor').option('value', executorPermission.length > 0 ? executorPermission[0].CodPermissao : null)
              }
            },
            iconSize: "36px",
          },
        },
        {
          colSpan: 2,
          label: { text: 'Usuário Executor' },
          editorType: 'dxSelectBox',
          dataField: 'CodUsuarioExecutor',
          editorOptions: {
            items: user,
            displayExpr: 'NomUsuario',
            valueExpr: 'CodUsuario',
            onValueChanged: function (e) {
              _executorUser.current = e.value

              if (_eventPermissions.current !== undefined && _eventPermissions.current.validator._validationRules[0].validationCallback !== undefined)
                _eventPermissions.current.validator._validationRules[0].validationCallback(_eventPermissions.current)
            },
            onInitialized: function (e) {
              if (!form.option().formData.TipUsuarioExecutor && (form.option().formData.CodUsuarioExecutor === null || form.option().formData.CodUsuarioExecutor === undefined))
                e.component.option('disabled', true)
            },
          },
          validationRules: [
            {
              type: 'custom',
              message: 'O(A) Usuário(a) Executor(a) deve ser distinto(a) do Usuário(a) Monitor(a) e do Usuário Verificador(a)',
              reevaluate: true,
              validationCallback: (e) => checkExecutorMonitorVerificador(e),
            },
          ],
        },
        {
          colSpan: 2,
          label: { text: 'Permissão Executor' },
          editorType: 'dxSelectBox',
          dataField: 'CodPermissaoExecutor',
          editorOptions: {
            items: executorPermission,
            displayExpr: 'NomPermissao',
            valueExpr: 'CodPermissao',
            onInitialized: function (e) {
              if (
                form.option().formData.TipUsuarioExecutor &&
                (form.option().formData.CodPermissaoExecutor === null || form.option().formData.CodPermissaoExecutor === undefined)
              ) {
                e.component.option('disabled', true)
              }
            },
          },
        },
        {
          itemType: 'empty',
          colSpan: 2,
        },
        {
          itemType: 'empty',
          colSpan: 2,
        },
        {
          label: { text: 'Grupo Monitor' },
        },
        {
          itemType: 'empty',
          colSpan: 2,
        },
        {
          colSpan: 2,
          label: { text: 'Exigir Aprovação do Monitor' },
          editorType: 'dxCheckBox',
          dataField: 'TipAprovacaoMonitor',
          value: true,
          editorOptions: {
            onValueChanged: function (args) {
              const invisibleItems = document.getElementsByClassName('dx-first-col dx-last-col dx-field-item dx-col-0 dx-field-item-optional dx-flex-layout dx-label-v-align')
              const labelVerify = document.getElementsByClassName('dx-field-item-label dx-field-item-label-location-top')

              if (args.value) {
                form.getEditor('CodUsuarioMonitor').option('disabled', false)
                form.getEditor('CodPermissaoMonitor').option('disabled', true)
                form.getEditor('TipUsuarioMonitor').option('disabled', false)
                form.getEditor('CodUsuarioVerificador').option('disabled', false)
                form.getEditor('CodPermissaoVerificador').option('disabled', true)
                form.getEditor('TipUsuarioVerificador').option('disabled', false)
                form.getEditor('TipAprovacaoVerificador').option('disabled', false)
                form.getEditor('CodUsuarioMonitor').option('value', user[0].CodUsuario)
                form.getEditor('CodPermissaoMonitor').option('value', null)
                form.getEditor('TipUsuarioMonitor').option('value', true)
                form.getEditor('CodUsuarioVerificador').option('value', user[0].CodUsuario)
                form.getEditor('CodPermissaoVerificador').option('value', null)
                form.getEditor('TipUsuarioVerificador').option('value', true)
                form.getEditor('TipAprovacaoVerificador').option('value', true)

                if (invisibleItems !== undefined && invisibleItems.length >= 11 && !isXSmall) {
                  invisibleItemsLoop(4, 10, 'block', invisibleItems)
                  labelVerify[14].style.display = 'block'
                }

                if (invisibleItems !== undefined && invisibleItems.length >= 14 && isXSmall) {
                  invisibleItemsLoop(6, 13, 'block', invisibleItems)
                }
              } else {
                disabledItems(form)

                invisibleFields(invisibleItems, labelVerify)
              }
            },
            iconSize: "36px",
          },
        },
        {
          colSpan: 2,
          label: { text: 'Especificar Monitor' },
          editorType: 'dxCheckBox',
          dataField: 'TipUsuarioMonitor',
          value: false,
          editorOptions: {
            onValueChanged: function (args) {
              const booTipAprovacaoMonitor = form.option().formData.TipAprovacaoMonitor
              if (args.value && booTipAprovacaoMonitor) {
                disabledCodPermissaoMonitor(form)

                form.getEditor('CodUsuarioMonitor').option('value', user.length > 0 ? user[0].CodUsuario : null)
              }

              if (!args.value && booTipAprovacaoMonitor) {
                disabledCodUsuarioMonitor(form)

                form.getEditor('CodPermissaoMonitor').option('value', monitorPermission.length > 0 ? monitorPermission[0].CodPermissao : null)
              }
            },
            onInitialized: function (e) {
              if (form.option().formData.TipAprovacaoMonitor === false) {
                e.component.option('disabled', true)

                const invisibleItems = document.getElementsByClassName('dx-first-col dx-last-col dx-field-item dx-col-0 dx-field-item-optional dx-flex-layout dx-label-v-align')
                const labelVerify = document.getElementsByClassName('dx-field-item-label dx-field-item-label-location-top')

                invisibleFields(invisibleItems, labelVerify)
              }
            },
            iconSize: "36px",
          },
        },
        {
          colSpan: 2,
          label: { text: 'Usuário Monitor' },
          editorType: 'dxSelectBox',
          dataField: 'CodUsuarioMonitor',
          editorOptions: {
            items: user,
            displayExpr: 'NomUsuario',
            valueExpr: 'CodUsuario',
            onValueChanged: function (e) {
              _monitorUser.current = e.value

              if (_eventPermissions.current !== undefined && _eventPermissions.current.validator._validationRules[0].validationCallback !== undefined)
                _eventPermissions.current.validator._validationRules[0].validationCallback(_eventPermissions.current)
            },
            onInitialized: function (e) {
              if (form.option().formData.TipAprovacaoMonitor === false) {
                e.component.option('disabled', true)

                const invisibleItems = document.getElementsByClassName('dx-first-col dx-last-col dx-field-item dx-col-0 dx-field-item-optional dx-flex-layout dx-label-v-align')
                const labelVerify = document.getElementsByClassName('dx-field-item-label dx-field-item-label-location-top')

                invisibleFields(invisibleItems, labelVerify)
              }

              if (form.option().formData.TipUsuarioMonitor && (form.option().formData.CodUsuarioMonitor === null || form.option().formData.CodUsuarioMonitor === undefined))
                e.component.option('disabled', true)
            },
          },
         validationRules: [
            {
              type: 'custom',
              message: 'O(A) Usuário(a) Monitor(a) deve ser distinto(a) do Usuário(a) Executor(a) e do Usuário Verificador(a)',
              reevaluate: true,
              validationCallback: (e) => checkMonitorExecutorVerificador(e),
            },
          ],
        },
        {
          colSpan: 2,
          label: { text: 'Permissão Monitor' },
          editorType: 'dxSelectBox',
          dataField: 'CodPermissaoMonitor',
          editorOptions: {
            items: monitorPermission,
            displayExpr: 'NomPermissao',
            valueExpr: 'CodPermissao',
            onInitialized: function (e) {
              if (form.option().formData.TipAprovacaoMonitor === false) {
                e.component.option('disabled', true)

                const invisibleItems = document.getElementsByClassName('dx-first-col dx-last-col dx-field-item dx-col-0 dx-field-item-optional dx-flex-layout dx-label-v-align')
                const labelVerify = document.getElementsByClassName('dx-field-item-label dx-field-item-label-location-top')

                invisibleFields(invisibleItems, labelVerify)
              }

              if (!form.option().formData.TipUsuarioMonitor && (form.option().formData.CodPermissaoMonitor === null || form.option().formData.CodPermissaoMonitor === undefined))
                e.component.option('disabled', true)
            },
          },
        },
        {
          itemType: 'empty',
          colSpan: 2,
        },
        {
          itemType: 'empty',
          colSpan: 2,
        },
        {
          label: { text: 'Grupo Verificador' },
        },
        {
          itemType: 'empty',
          colSpan: 2,
        },
        {
          colSpan: 2,
          label: { text: 'Exigir Aprovação do Verificador' },
          editorType: 'dxCheckBox',
          dataField: 'TipAprovacaoVerificador',
          value: true,
          editorOptions: {
            onValueChanged: function (args) {
              if (args.value) {
                form.getEditor('CodUsuarioVerificador').option('disabled', false)
                form.getEditor('CodPermissaoVerificador').option('disabled', true)
                form.getEditor('TipUsuarioVerificador').option('disabled', false)
                form.getEditor('CodUsuarioVerificador').option('value', user[0].CodUsuario)
                form.getEditor('CodPermissaoVerificador').option('value', null)
                form.getEditor('TipUsuarioVerificador').option('value', true)
              } else {
                form.getEditor('CodUsuarioVerificador').option('disabled', true)
                form.getEditor('CodPermissaoVerificador').option('disabled', true)
                form.getEditor('TipUsuarioVerificador').option('disabled', true)
                form.getEditor('CodPermissaoVerificador').option('value', null)
                form.getEditor('CodUsuarioVerificador').option('value', null)
                form.getEditor('TipUsuarioVerificador').option('value', false)
              }
            },
            iconSize: "36px",
          },
        },
        {
          colSpan: 2,
          label: { text: 'Especificar Verificador' },
          editorType: 'dxCheckBox',
          dataField: 'TipUsuarioVerificador',
          value: false,
          editorOptions: {
            onValueChanged: function (args) {
              const booTipAprovacaoVerificador = form.option().formData.TipAprovacaoVerificador
              if (args.value && booTipAprovacaoVerificador) {
                disabledCodPermissaoVerificador(form)

                form.getEditor('CodUsuarioVerificador').option('value', user.length > 0 ? user[0].CodUsuario : null)
              }

              if (!args.value && booTipAprovacaoVerificador) {
                disabledCodUsuarioVerificador(form)

                form.getEditor('CodPermissaoVerificador').option('value', verificadorPermission.length > 0 ? verificadorPermission[0].CodPermissao : null)
              }
            },
            onInitialized: function (e) {
              if (form.option().formData.TipAprovacaoMonitor === false) {
                e.component.option('disabled', true)

                const invisibleItems = document.getElementsByClassName('dx-first-col dx-last-col dx-field-item dx-col-0 dx-field-item-optional dx-flex-layout dx-label-v-align')
                const labelVerify = document.getElementsByClassName('dx-field-item-label dx-field-item-label-location-top')

                invisibleFields(invisibleItems, labelVerify)
              }

              if (form.option().formData.TipAprovacaoVerificador === false) e.component.option('disabled', true)
            },
            iconSize: "36px",
          },
        },
        {
          colSpan: 2,
          label: { text: 'Usuário Verificador' },
          editorType: 'dxSelectBox',
          dataField: 'CodUsuarioVerificador',
          editorOptions: {
            items: user,
            displayExpr: 'NomUsuario',
            valueExpr: 'CodUsuario',
            onValueChanged: function (e) {
              _verifyUser.current = e.value

              if (_eventPermissions.current !== undefined && _eventPermissions.current.validator._validationRules[0].validationCallback !== undefined)
                _eventPermissions.current.validator._validationRules[0].validationCallback(_eventPermissions.current.current)
            },
            onInitialized: function (e) {
              if (form.option().formData.TipAprovacaoMonitor === false) {
                e.component.option('disabled', true)

                const invisibleItems = document.getElementsByClassName('dx-first-col dx-last-col dx-field-item dx-col-0 dx-field-item-optional dx-flex-layout dx-label-v-align')
                const labelVerify = document.getElementsByClassName('dx-field-item-label dx-field-item-label-location-top')

                invisibleFields(invisibleItems, labelVerify)
              }

              if (
                !form.option().formData.TipAprovacaoVerificador &&
                (form.option().formData.CodUsuarioVerificador === null || form.option().formData.CodUsuarioVerificador === undefined)
              )
                e.component.option('disabled', true)
            },
          },
          validationRules: [
            {
              type: 'custom',
              message: 'O(A) Usuário(a) Verificador(a) deve ser distinto(a) do Usuário(a) Executor(a) e do Usuário Monitor(a)',
              reevaluate: true,
              validationCallback: (e) => checkVerificadorExecutorMonitor(e),
            },
          ],
        },
        {
          colSpan: 2,
          label: { text: 'Permissão Verificador' },
          editorType: 'dxSelectBox',
          dataField: 'CodPermissaoVerificador',
          editorOptions: {
            items: verificadorPermission,
            displayExpr: 'NomPermissao',
            valueExpr: 'CodPermissao',
            onInitialized: function (e) {
              if (form.option().formData.TipAprovacaoMonitor === false) {
                e.component.option('disabled', true)

                const invisibleItems = document.getElementsByClassName('dx-first-col dx-last-col dx-field-item dx-col-0 dx-field-item-optional dx-flex-layout dx-label-v-align')
                const labelVerify = document.getElementsByClassName('dx-field-item-label dx-field-item-label-location-top')

                invisibleFields(invisibleItems, labelVerify)
              }

              if (
                !form.option().formData.TipAprovacaoVerificador &&
                (form.option().formData.CodPermissaoVerificador === null || form.option().formData.CodPermissaoVerificador === undefined)
              )
                e.component.option('disabled', true)
            },
          },
        },
        {
          colSpan: 2,
          label: { text: 'Tarefa Ativa' },
          editorType: 'dxCheckBox',
          dataField: 'TipRegistroAtivo',
          editorOptions: {
            iconSize: "36px",
          }
        },
      )
      
    mainGroupItems[1].items[0].validationRules.push({
      type: 'custom',
      message: 'A Data de Início deve ser anterior a data final e a data final de repetição',
      reevaluate: true,
      validationCallback: (e) => checkDateInitial(e),
    })
    
    mainGroupItems[1].items[1].validationRules.push({
      type: 'custom',
      message: 'A Data Final deve ser posterior a Data de Ínicio e anterior a data final de repetição',
      reevaluate: true,
      validationCallback: (e) => checkDateEnd(e),
    })

    //
    form.itemOption('mainGroup', 'items', mainGroupItems)
    form.itemOption('recurrenceGroup', 'items', recurrenceGroupItems)
    if (form.getEditor('TipRegistroAtivo') !== undefined)
      form.getEditor('TipRegistroAtivo').option('value', e.appointmentData.TipRegistroAtivo === undefined ? true : e.appointmentData.TipRegistroAtivo)
    if (form.getEditor('TipUsuarioExecutor') !== undefined)
      form.getEditor('TipUsuarioExecutor').option('value', e.appointmentData.TipUsuarioExecutor === undefined ? false : e.appointmentData.TipUsuarioExecutor)
    if (form.getEditor('TipAprovacaoMonitor') !== undefined)
      form.getEditor('TipAprovacaoMonitor').option('value', e.appointmentData.TipAprovacaoMonitor === undefined ? false : e.appointmentData.TipAprovacaoMonitor)
    if (form.getEditor('TipUsuarioMonitor') !== undefined)
      form.getEditor('TipUsuarioMonitor').option('value', e.appointmentData.TipUsuarioMonitor === undefined ? true : e.appointmentData.TipUsuarioMonitor)
    if (form.getEditor('TipAprovacaoVerificador') !== undefined)
      form.getEditor('TipAprovacaoVerificador').option('value', e.appointmentData.TipAprovacaoVerificador === undefined ? true : e.appointmentData.TipAprovacaoVerificador)
    if (form.getEditor('TipUsuarioVerificador') !== undefined)
      form.getEditor('TipUsuarioVerificador').option('value', e.appointmentData.TipUsuarioVerificador === undefined ? true : e.appointmentData.TipUsuarioVerificador)

    //
    const invisibleItems = document.getElementsByClassName('dx-first-col dx-last-col dx-field-item dx-col-0 dx-field-item-optional dx-flex-layout dx-label-v-align')
    const labelVerify = document.getElementsByClassName('dx-field-item-label dx-field-item-label-location-top')
    
    if (form.getEditor('TipUsuarioExecutor') !== undefined) {
      if (e.appointmentData.TipUsuarioExecutor) {
        disabledCodPermissaoExecutor(form)

        form.getEditor('CodUsuarioExecutor').option('value', e.appointmentData.CodUsuarioExecutor)
      } else {
        disabledCodUsuarioExecutor(form)

        form
          .getEditor('CodPermissaoExecutor')
          .option(
            'value',
            e.appointmentData.CodPermissaoExecutor ? e.appointmentData.CodPermissaoExecutor : executorPermission.length > 0 ? executorPermission[0].CodPermissao : null
          )
      }
    }
    
    if (form.getEditor('TipAprovacaoMonitor').option('value')) {
      if (form.getEditor('TipAprovacaoVerificador') !== undefined && e.appointmentData.TipSituacao <= 1) form.getEditor('TipAprovacaoVerificador').option('disabled', false)

      if (form.getEditor('TipUsuarioMonitor') !== undefined) {
        const booTipAprovacaoMonitor = form.option().formData.TipAprovacaoMonitor

        if (e.appointmentData.TipUsuarioMonitor && booTipAprovacaoMonitor) {
          disabledCodPermissaoMonitor(form)

          form.getEditor('CodUsuarioMonitor').option('value', e.appointmentData.CodUsuarioMonitor)
        } else {
          disabledCodUsuarioMonitor(form)

          form.getEditor('CodPermissaoMonitor').option('value', e.appointmentData.CodPermissaoMonitor)
        }
      }

      if (form.getEditor('TipUsuarioVerificador') !== undefined) {
        const booTipAprovacaoVerificador = form.option().formData.TipAprovacaoVerificador

        if (e.appointmentData.TipUsuarioVerificador && booTipAprovacaoVerificador) {
          disabledCodPermissaoVerificador(form)

          form.getEditor('CodUsuarioVerificador').option('value', e.appointmentData.CodUsuarioVerificador)
        }

        if (!e.appointmentData.TipUsuarioVerificador && booTipAprovacaoVerificador) {
          disabledCodUsuarioVerificador(form)

          form.getEditor('CodPermissaoVerificador').option('value', e.appointmentData.CodPermissaoVerificador)
        }
      }

      if (invisibleItems !== undefined && invisibleItems.length >= 11 && !isXSmall) {
        invisibleItemsLoop(4, 10, 'block', invisibleItems)
        labelVerify[14].style.display = 'block'
      }

      if (invisibleItems !== undefined && invisibleItems.length >= 14 && isXSmall) invisibleItemsLoop(6, 13, 'block', invisibleItems)
    } else {
      disabledItems(form)

      invisibleFields(invisibleItems, labelVerify)
    }
    
    //Deixa invisível os campos que não precisamos do recurrenceRule quando uma tarefa já possuí valores
    invisibleItemsRecurrenceRule(form);

    if(e.appointmentData.TipSituacao !== undefined) {
      let insertedTemplate = mainGroupItems.find((element) => element.template === 'my-template');

      if(!insertedTemplate) 
        mainGroupItems.push(
          {
            colSpan: 2,
            template: 'my-template',
          }
        );
    }
    
    if(e.appointmentData.TipSituacao && e.appointmentData.TipSituacao > 1) {
      e.popup.option("toolbarItems[0].options.visible", false);
      e.popup.show();
    }
  }
  //#endregion

  function disabledCodPermissaoExecutor(form) {
    form.getEditor('CodUsuarioExecutor').option('disabled', false)
    form.getEditor('CodPermissaoExecutor').option('disabled', true)
    form.getEditor('CodPermissaoExecutor').option('value', null)
  }

  function disabledCodUsuarioExecutor(form) {
    form.getEditor('CodUsuarioExecutor').option('disabled', true)
    form.getEditor('CodPermissaoExecutor').option('disabled', false)
    form.getEditor('CodUsuarioExecutor').option('value', null)
  }

  function disabledCodPermissaoMonitor(form) {
    form.getEditor('CodUsuarioMonitor').option('disabled', false)
    form.getEditor('CodPermissaoMonitor').option('disabled', true)
    form.getEditor('CodPermissaoMonitor').option('value', null)
  }

  function disabledCodUsuarioMonitor(form) {
    form.getEditor('CodUsuarioMonitor').option('disabled', true)
    form.getEditor('CodPermissaoMonitor').option('disabled', false)
    form.getEditor('CodUsuarioMonitor').option('value', null)
  }

  function disabledCodPermissaoVerificador(form) {
    form.getEditor('CodUsuarioVerificador').option('disabled', false)
    form.getEditor('CodPermissaoVerificador').option('disabled', true)
    form.getEditor('CodPermissaoVerificador').option('value', null)
  }

  function disabledCodUsuarioVerificador(form) {
    form.getEditor('CodUsuarioVerificador').option('disabled', true)
    form.getEditor('CodPermissaoVerificador').option('disabled', false)
    form.getEditor('CodUsuarioVerificador').option('value', null)
  }

  function disabledItems(form) {
    form.getEditor('CodUsuarioMonitor').option('disabled', true)
    form.getEditor('CodPermissaoMonitor').option('disabled', true)
    form.getEditor('TipUsuarioMonitor').option('disabled', true)
    form.getEditor('CodUsuarioVerificador').option('disabled', true)
    form.getEditor('CodPermissaoVerificador').option('disabled', true)
    form.getEditor('TipUsuarioVerificador').option('disabled', true)
    form.getEditor('TipAprovacaoVerificador').option('disabled', true)
    form.getEditor('CodPermissaoMonitor').option('value', null)
    form.getEditor('CodUsuarioMonitor').option('value', null)
    form.getEditor('TipUsuarioMonitor').option('value', false)
    form.getEditor('CodUsuarioVerificador').option('value', null)
    form.getEditor('CodPermissaoVerificador').option('value', null)
    form.getEditor('TipUsuarioVerificador').option('value', false)
    form.getEditor('TipAprovacaoVerificador').option('value', false)
  }

  function invisibleFields(invisibleItems, labelVerify) {
    if (!isXSmall && invisibleItems !== undefined && invisibleItems.length >= 11) {
      invisibleItemsLoop(4, 10, 'none', invisibleItems)
      labelVerify[14].style.display = 'none'
    }

    if (isXSmall && invisibleItems !== undefined && invisibleItems.length >= 14) invisibleItemsLoop(6, 13, 'none', invisibleItems)
  }

  function invisibleItemsLoop(startInd, endInd, visible, invisibleItems) {
    for (let i = startInd; i <= endInd; i++) {
      invisibleItems[i].style.display = visible
    }
  }

  //#region functionsValidation
  function checkDateInitial(e) {
    _event.current = e
    let check = true

    if (_allDay.current === false) {
      check = dayjs(_dateInitial.current).isBefore(dayjs(_dateEnd.current), 'minute')

      if (_dateEndPeriodicTask.current !== undefined) check = dayjs(_dateInitial.current).isBefore(dayjs(_dateEndPeriodicTask.current), 'minute')
    }

    if (check) e.rule.isValid = true

    return check
  }

  function checkDateEnd(e) {
    _event.current = e
    let check = true

    if (_allDay.current === false) {
      check = dayjs(_dateInitial.current).isBefore(dayjs(_dateEnd.current), 'minute')

      if (_dateEndPeriodicTask.current !== undefined) check = dayjs(_dateEnd.current).isBefore(dayjs(_dateEndPeriodicTask.current), 'minute')
    }

    if (check) e.rule.isValid = true

    return check
  }

  function checkExecutorMonitorVerificador(e) {
    _eventPermissions.current = e
    let check = true

    if (
      _executorUser.current !== undefined &&
      _executorUser.current !== null &&
      (_executorUser.current === _monitorUser.current || _executorUser.current === _verifyUser.current)
    ) {
      check = false
    }

    return check
  }

  function checkMonitorExecutorVerificador(e) {
    _eventPermissions.current = e

    let check = true

    if (_monitorUser.current !== undefined && _monitorUser.current !== null && (_monitorUser.current === _executorUser.current || _monitorUser.current === _verifyUser.current)) {
      check = false
    }

    return check
  }

  function checkVerificadorExecutorMonitor(e) {
    _eventPermissions.current = e

    let check = true

    if (_verifyUser.current !== undefined && _verifyUser.current !== null && (_verifyUser.current === _executorUser.current || _verifyUser.current === _monitorUser.current)) {
      check = false
    }

    return check
  }
  //#endregion

  function addTask(e) {
    e.appointmentData.DatIniciaMonitoracao = dayjs(e.appointmentData.DatIniciaMonitoracao).format('YYYY-MM-DD[T]HH:mm:ss')
    e.appointmentData.DatFinalizaMonitoracao = dayjs(e.appointmentData.DatFinalizaMonitoracao).format('YYYY-MM-DD[T]HH:mm:ss')

    if (e.appointmentData.recurrenceRule !== undefined && e.appointmentData.recurrenceRule.length > 0)
      e.appointmentData.recurrenceRule = correctReccurenceRule(e.appointmentData.recurrenceRule)

    e.appointmentData.CodUnidadeEmpresa = unitCod

    if (e.appointmentData.TipUsuarioExecutor === undefined) e.appointmentData.TipUsuarioExecutor = false

    if (e.appointmentData.TipUsuarioVerificador === undefined) e.appointmentData.TipUsuarioVerificador = false

    if (e.appointmentData.TipUsuarioMonitor === undefined) e.appointmentData.TipUsuarioMonitor = false

    if (e.appointmentData.TipAprovacaoMonitor === undefined) e.appointmentData.TipAprovacaoMonitor = false

    if (e.appointmentData.TipAprovacaoVerificador === undefined) e.appointmentData.TipAprovacaoVerificador = false

    if (e.appointmentData.TipAprovacaoVerificador === undefined) e.appointmentData.TipAprovacaoVerificador = false

    if (e.appointmentData.TipRegistroAtivo === undefined) e.appointmentData.TipRegistroAtivo = false

    if(e.appointmentData.recurrenceRule === "") e.appointmentData.recurrenceRule = null;

    e.appointmentData.DatProximaAtualizacao = e.appointmentData.DatIniciaMonitoracao
    const strLogMessage = `${formatMessage('InsertedTask')} ${e.appointmentData.NomTarefa}`

    e.cancel = insertTask(e.appointmentData, strLogMessage)
  }

  function delTask(e) {
    delete e.appointmentData.endDate
    const strLogMessage = `${formatMessage('DeletedTask')} ${e.appointmentData.NomTarefa}`

    const data = { unitCod: unitCod, data: e.appointmentData, logMessage: strLogMessage }

    e.cancel = deleteTask(data)
  }

  function upTask(e) {
    delete e.newData.endDate

    if (e.newData.recurrenceRule !== undefined && e.newData.recurrenceRule !== null && e.newData.recurrenceRule.length > 0)
      e.newData.recurrenceRule = correctReccurenceRule(e.newData.recurrenceRule)

    if (e.newData.recurrenceRule === undefined && e.newData.TipPeriodica) e.newData.recurrenceRule = e.oldData.recurrenceRule

    const strLogMessage =
      e.newData.NomTarefa === e.oldData.NomTarefa
        ? `${formatMessage('UpdatedTask')} ${e.newData.NomTarefa}`
        : `${formatMessage('UpdatedTask')} ${e.oldData.NomTarefa} -> ${e.newData.NomTarefa}`

    e.cancel = updateTask(e.newData, strLogMessage)
  }

  function correctReccurenceRule(recurrenceRule) {
    const lstRecurrenceRule = recurrenceRule.split(';')
    let indexUntil

    lstRecurrenceRule.forEach((prop, index) => {
      const searched = prop.indexOf('UNTIL') > -1

      if (searched) indexUntil = index
    })

    if (_dateEndPeriodicTask.current !== undefined && indexUntil) {
      const findUntil = recurrenceRule.indexOf('UNTIL')
      const diff = lstRecurrenceRule.length - 1
      let dateCorrect

      indexUntil === diff
        ? (dateCorrect = _dateEndPeriodicTask.current.format('YYYYMMDD[T]HHmmss[Z]'))
        : (dateCorrect = _dateEndPeriodicTask.current.format('YYYYMMDD[T]HHmmss[Z];'))

      return recurrenceRule.replace(recurrenceRule.substring(findUntil + 6, findUntil + 23), dateCorrect)
    }

    if (!indexUntil) return recurrenceRule
  }

  function onSelectionChangedPermission(e) {
    e.selectedItem && e.selectedItem.CodAreaProcesso
      ? _areaProcessSelected.current = e.selectedItem.CodAreaProcesso
      : _areaProcessSelected.current = null;
    let newLstTasks

    e.selectedItem && e.selectedItem.CodAreaProcesso
      ? (newLstTasks = _tasks.current.filter((task) => task.CodAreaProcesso === e.selectedItem.CodAreaProcesso))
      : (newLstTasks = _tasks.current)

    setTasks(newLstTasks)
    setFilterTasks(!filterTasks);
  }

  const formItemRender = () => {
    return (
      <ImageUploaderInline
        multiple={true}
        numImgs={10}
        objImgFromDataBase={imgs.current}
        objImgToDataBase={objectImgToDataBase}
        datRegister={dayjs().format()}
        disabledButton={true}
        buttonType={true}
      />
    )
  }

  function searchNumberTask(appointmentData) {
    let numberTask = 1;
    let newDate = appointmentData.DatIniciaMonitoracao;
    let interval = 1;
    const lstRecurrenceRule = appointmentData.recurrenceRule.split(";");
    
    lstRecurrenceRule.forEach(element => {
      if(element.indexOf("INTERVAL=") !== -1)
        interval = parseInt(element.replace("INTERVAL=", ""));
    });

    lstRecurrenceRule.forEach((element) => {
      if(element === "FREQ=DAILY") {
        while(!dayjs(_datTaskClicked.current).isSame(dayjs(newDate))) {
          newDate = dayjs(newDate).add(1 * interval, "day");
          numberTask += 1;
        };
      };

      if(element === "FREQ=WEEKLY") {
        while(!dayjs(_datTaskClicked.current).isSame(dayjs(newDate))) {
          newDate = dayjs(newDate).add(1 * interval, "week");
          numberTask += 1;
        };
      };

      if(element === "FREQ=MONTHLY") {
        while(!dayjs(_datTaskClicked.current).isSame(dayjs(newDate))) {
          newDate = dayjs(newDate).add(1 * interval, "month");
          numberTask += 1;
        };
      };
    });

    return numberTask;
  }

  function invisibleItemsRecurrenceRule(form) {
    if (form.getEditor('recurrenceRule')) form.getEditor('recurrenceRule')._recurrenceForm.option('items[3].items[1].editorOptions.visible', false)
    if (form.getEditor('recurrenceRule')) form.getEditor('recurrenceRule')._recurrenceForm.option('items[3].items[2].visible', false)
    if (form.getEditor('recurrenceRule')) form.getEditor('recurrenceRule')._recurrenceForm.option('items[2].items[0].visible', false)
    if (form.getEditor('recurrenceRule')) form.getEditor('recurrenceRule')._recurrenceForm.option('items[4].items[0].editorOptions.items[2].visible', false)
    if (form.getEditor('recurrenceRule')) form.getEditor('recurrenceRule')._repeatCountEditor.option('visible', false)
    if (form.getEditor('recurrenceRule') && form.getEditor('recurrenceRule')._weekEditor) {
      if (form.getEditor('recurrenceRule')) form.getEditor('recurrenceRule')._weekEditor.option('visible', false)
    }
  }

  return (
    <React.Fragment>
      <div className={'ContainerButtons'}>
        <FavButton />
        <ReportsButton />
      </div>
      <SelectBox
        width={250}
        className={'dx-cards'}
        dataSource={areaProcess}
        label={'Área'}
        searchEnabled={true}
        onSelectionChanged={onSelectionChangedPermission}
        displayExpr="NomAreaProcesso"
        valueExpr="CodAreaProcesso"
        stylingMode={'outlined'}
        labelMode={'floating'}
      />
      <Scheduler
        textExpr={'NomTarefa'}
        descriptionExpr={'DesTarefa'}
        startDateExpr={'DatIniciaMonitoracao'}
        endDateExpr={'DatFinalizaMonitoracao'}
        recurrenceEditMode={'series'}
        className={'dx-cards'}
        adaptivityEnabled={isLarge || isMedium ? false : true}
        dataSource={tasks}
        views={views}
        defaultCurrentView="week"
        defaultCurrentDate={currentDate}
        onAppointmentFormOpening={(e) => renderAppointmentFormOpening(e)}
        onAppointmentClick={(e) => _datTaskClicked.current = e.targetedAppointmentData.TipPeriodica ? e.targetedAppointmentData.DatIniciaMonitoracao : e.targetedAppointmentData.displayStartDate}
        onAppointmentAdding={addTask}
        onAppointmentDeleting={delTask}
        onAppointmentUpdating={upTask}
        width={'auto'}
        cellDuration={30}
        maxAppointmentsPerCell={3}
      >
        <Template name={'my-template'} render={formItemRender} />
      </Scheduler>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  )
}
