/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useLayoutEffect, useEffect } from "react";
import dayjs from "dayjs";
import { SelectBox, Button as SelectBoxButton } from "devextreme-react/select-box";
import { useDataLoader } from "../../contexts/dataLoaderContext";
import "./style.scss";
import { AddItems } from "./AddItems";
import { ObjSampleSave } from "./objSampleSave";
import { useScreenSize } from "../../utils/media-query";
import { CheckBox } from "devextreme-react";
import HourBox from "./HourBox";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
	const { isSmall, isXSmall, isXLSmall, isMedium, isLarge } = useScreenSize();
	const { setSaveHeaderTextBox, listToSaveSample, listToSaveDescSample, numLot } = useDataLoader();
	let firstLoad = useRef(false);
	let _refInputPrevious = useRef([]);
	let _currencyValueDate = useRef(new Date(props.sampleHour));

	useLayoutEffect(() => {
		listToSaveSample.current = [];
		setSaveHeaderTextBox(false);
		firstLoad.current = true;

		if(_currencyValueDate.current === new Date(props.sampleHour)) {
			_currencyValueDate.current.setDate(new Date(props.currencyValueDate).getDate());
			_currencyValueDate.current.setMonth(new Date(props.currencyValueDate).getMonth());
			_currencyValueDate.current.setFullYear(new Date(props.currencyValueDate).getFullYear());
		}
	}, [])

	useEffect(() => {
		_currencyValueDate.current.setDate(new Date(props.currencyValueDate).getDate());
		_currencyValueDate.current.setMonth(new Date(props.currencyValueDate).getMonth());
		_currencyValueDate.current.setFullYear(new Date(props.currencyValueDate).getFullYear());
	}, [props.currencyValueDate])

	useEffect(() => {
		if(props.tipPeriodUnit === 4) searchCurrentDate()
	}, [])

	const positiveNegativeDS = [
		{ Id: 1, Value: "Positivo" },
		{ Id: 0, Value: "Negativo" },
	];

	const aprovedCheck = {
		icon: "check",
		hint: "Aprovado",
		elementAttr: { id: "aproved-icon" },
	};

	const reprovedCheck = {
		icon: "close",
		hint: "Reprovado",
		elementAttr: { id: "reproved-icon" },
	};

	const optionsValueIcon = {
		stylingMode: "text",
		focusStateEnabled: false,
		type: "default",
		visible: false,
		width: 0,
	};

	const verificationInput = (value, e) => {
		let comparasionString = "";
		let inputComponent = e.component.instance().getButton("checkValue") !== null ? e.component.instance().getButton("checkValue") : "";

		if(inputComponent !== ""){
			
			value === "" || value === null ? inputComponent.option("visible", false) : inputComponent.option("visible", true);

			let arrayIndex = e.element.id.split(":", 2);
			let tipVerification = props.analysisTimeList[arrayIndex[0]][1];

			switch (tipVerification) {
				case 0:
					comparasionString = value === 1;
					break;

				case 20:
					comparasionString = value === 0;
					break;

				default:
					break;
			}
			comparasionString ? inputComponent.option(aprovedCheck) : inputComponent.option(reprovedCheck);
		}

		return comparasionString;
	}

	const onValueChangedChbNotWorking = async (e) => {
		if (e.value)  {
			if(firstLoad.current && _refInputPrevious.current[`${props.indexAnalysis}:${props.index}`] !== undefined){
				
				props._lstPreviousValue.current[`${props.indexAnalysis}:${props.index}`] = 
				_refInputPrevious.current[`${props.indexAnalysis}:${props.index}`].instance.option("value") === undefined 
					? "" 
					: _refInputPrevious.current[`${props.indexAnalysis}:${props.index}`].instance.option("value")

				firstLoad.current = false
			}
			props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value", 0);
		} 
		else {
			let previousValue = props._lstPreviousValue.current[`${props.indexAnalysis}:${props.index}`];
			props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value", previousValue);
		}
	};

	const onValueChanged = async (e, parComponent) => {
		if (e.event !== undefined) {
			
			let value = e.component.option("value");

			//Guarda o PreviousValue e mesmo se clicar no checkbox "S/A", o PreviousValue não muda
			if(parComponent === "sbxPositiveNegative") {
				props._lstPreviousValue.current[e.element.id] = e.previousValue === undefined ? "" : e.previousValue
				firstLoad.current = false
			}

			if (
				props._refUserSelectBox.current[props.isLot ? "0:0" : e.element.id] !== null &&
				props._refUserSelectBox.current[props.isLot ? "0:0" : e.element.id] !== undefined &&
				props._refObservation.current[props.isLot 	? "0:0" : e.element.id] !== null &&
				props._refObservation.current[props.isLot 	? "0:0" : e.element.id] !== undefined
			) {
				let userSelectBox = props.headerLotRender
					? props._refUserSelectBox.current["0:0"].instance
					: props._refUserSelectBox.current[props.isLot ? "0:0" : e.element.id].instance;

				let observationTextArea = props.headerLotRender
					? props._refObservation.current["0:0"].instance
					: props._refObservation.current[props.isLot ? "0:0" : e.element.id].instance;

				/*let observationGroup = props.headerLotRender
					? props._refObservationGroup.current["0:0"].instance
					: props._refObservationGroup.current[props.isLot ? "0:0" : e.element.id].instance;*/

				let reanalysisButton = props._refButtonReAnalysis.current[props.isLot ? "0:0" : e.element.id].instance;

				let disabledAprovedFields = props.headerLotRender
					? numLot !== null && numLot !== undefined && numLot !== 0
					: props._refInput.current[`${props.indexAnalysis}:${props.index}`] !== null &&
					props._refInput.current[`${props.indexAnalysis}:${props.index}`] !== undefined
					? props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value") === null ||
					props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value") === ""
					: false;

				userSelectBox.option("disabled", disabledAprovedFields);
				observationTextArea.option("disabled", disabledAprovedFields);
				reanalysisButton.option("disabled", disabledAprovedFields);
			}

			let boolAprovedResult;
			if (parComponent !== "chbNotWorking" && parComponent !== "dxDateBox") 
				boolAprovedResult = verificationInput(value, e);

			if (props.isLot) {
				listToSaveSample.current.forEach((element) => { //Altera o código usuario de todos os elementos a serem salvos
					if (e.event.type === "dxclick") element.CodUsuario = listToSaveDescSample.current["0:1"]
				})

				if(props.lstDescObservation.length > 0) {
					let newDate = new Date(props.lstDescObservation[0].DatAnaliseAmostra);

					listToSaveSample.current.forEach(element => {
						if(element.CodAnalise == 0)
							newDate = new Date(element.DatAnaliseAmostra);
					})

					_currencyValueDate.current = newDate;
				}
					

				if(props.lstDescObservation.length === 0 && listToSaveSample.current.length > 0) {
					listToSaveSample.current.forEach(element => {
						if(element.CodAnalise === 0)
							_currencyValueDate.current = new Date(element.DatAnaliseAmostra);
					})
				}
			}

			/*if (
				props._refButtonReAnalysis.current[props.isLot ? "0:0" : e.element.id] !== undefined &&
				props._refButtonReAnalysis.current[props.isLot ? "0:0" : e.element.id] !== null &&
				props._refUserSelectBox.current[props.isLot ? "0:0" : e.element.id] !== undefined
			) {*/

			const newObj = ObjSampleSave(
					props.isLot ? props.currencyValueDate : e.component.option("date"),
					props.analysisTimeList[props.indexAnalysis],
					props.analysisGroupCode,
					props._refInput.current[`${props.indexAnalysis}:${props.index}`] !== null &&
						props._refInput.current[`${props.indexAnalysis}:${props.index}`] !== undefined
						? props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value")
						: 0,
					boolAprovedResult,
					props._refBtnNotWorking.current[`${props.indexAnalysis}:${props.index}`].instance.option("value"),
					props.isLot ? numLot : null,
					props.userCod,
					props.isLot,
					false,
					props._refLabelHour,
					props._refUserSelectBox,
					props._refObservation,
					props._refButtonReAnalysis,
					listToSaveDescSample,
					`${props.indexAnalysis}:${props.index}`,
					false,
					!(props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value") === props._lstPreviousValue.current[`${props.indexAnalysis}:${props.index}`])
					? props._lstPreviousValue.current[`${props.indexAnalysis}:${props.index}`] : "",
					props.sampleHour && !props.isLot ? props.sampleHour : _currencyValueDate.current,
					_currencyValueDate.current,
					props.tipPeriodUnit
			);
					
				await AddItems(listToSaveSample, props.isLot, newObj, "", `${props.indexAnalysis}:${props.index}`);
			//}
		}
	};

	const onInitializedChbNotWorking = (e) => {

		e.component.option("date", props.analysisTimeList[props.indexAnalysis][5][props.index]);

		//Se o camponente da hora é igual ao da amostra e o código do componente é igual ao da analise, para saber em qual componente inserir o valor do banco
		props.listAnalysisSample.forEach((element) => {
			if (props.isLot) {
				if (props.analysisTimeList[props.indexAnalysis][8].CodAnalise === element.CodAnalise)
					e.component.instance().option("value", !element.TipFuncionando);
			} else {
				if (
					(dayjs(element.DatAnaliseAmostra).format("LT") === props._refLabelHour.current[`${props.indexAnalysis}:${props.index}`] 
					|| (dayjs(element.DatAnaliseAmostra).format("LT") === dayjs(_currencyValueDate.current).format("LT")))
					&& props.analysisTimeList[props.indexAnalysis][8].CodAnalise === element.CodAnalise
				) {
					e.component.instance().option("value", !element.TipFuncionando); //Preenche checkbox tip_funcionando
					if (!element.TipFuncionando){
						//Desabilita campo valor amostra se tip_funcionando = 0
						props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("disabled", true);
					}
				}
			}
		});
	};

	function searchCurrentDate() {
		for(let i = 0; i < props.analysisTimeList.length; i++) {
			for(let j = 0; j < props.listAnalysisSample.length; j++) {
				if(props.listAnalysisSample[j].CodAnalise === props.analysisTimeList[i][8].CodAnalise) {
					props.setCurrentDate(new Date(props.listAnalysisSample[j].DatAnaliseAmostra));
					break;
				}
			}

			if(props.currentDate)
				break;
		}
	}

	return (
		<>
		{!props.isLot && (props.tipPeriodUnit === 7 || props.tipPeriodUnit === 4)
			? 	<HourBox 
					currencyValueDate={_currencyValueDate}
					sampleHour={props.sampleHour}
					onValueChanged={onValueChanged}
					currentDate={props.currentDate}
					setCurrentDate={props.setCurrentDate}
					tipPeriodUnit={props.tipPeriodUnit}
				/>
			: ""
		}
		<div style={{ minWidth: isLarge ? 235 : isMedium ? 235 : isSmall ? 185 : 150 }}>
			<section 
			style={{ float: "left", 
					marginRight: isXSmall || isXLSmall ? 5 : 25, 
					minWidth: isLarge ? 150 : isMedium ? 150 : isSmall || isXLSmall ? 100 : 100,
					maxWidth: isLarge ? 150 : isMedium ? 150 : isSmall || isXLSmall ? 100 : 100 }}>
				<div
					className="dx-field-label"
					style={{
						display: props.headerLotRender ? "inline" : props.isLot ? "none" : props.index === 0 ? "inline-block" : "none", width: "100%"
					}}>
					{isXSmall || isXLSmall ? "Valor" : "Valor Amostra"}
				</div>
				<div>
					<SelectBox
						dataSource={positiveNegativeDS}
						height={42}
						width={"100%"}
						style={{
							marginTop: props.isLot ? -27 : 0,
						}}
						stylingMode="outlined"
						valueExpr={"Id"}
						displayExpr={"Value"}
						onOptionChanged={(e) => {
							if (e.component.instance().option("readOnly")) {
								e.element.parentElement.style.backgroundColor = "rgb(244 67 54 / 19%)";
								e.element.parentElement.style.opacity = "0.6";
							}

							//if (e.name === "text" && e.component.instance().option("value") > 0) verificationInput(e.component.instance().option("value"), e)
						}}
						onInitialized={(e) => {
							e.component.option("date", props.analysisTimeList[props.indexAnalysis][5][props.index]);
						}}
						showClearButton={true}
						acceptCustomValue={false}
						deferRendering={false}
						id={`${props.indexAnalysis}:${props.index}`}
						onContentReady={(e) => {
							if (e.component.option("value") !== null) verificationInput(e.component.option("value"), e)
							//Se o camponente da hora é igual ao da amostra e o código do componente é igual ao da analise,
							//para saber em qual componente inserir o valor que vem do banco
							props.listAnalysisSample.forEach((element) => {
								if (props.isLot) {
									if (props.analysisTimeList[props.indexAnalysis][8].CodAnalise === element.CodAnalise){
										e.component.instance().option("value", element.ValAmostra);
										e.component.option("tipFuncionando", element.TipFuncionando);
										if(!element.TipFuncionando) e.component.option("disabled", true)
									}
								} else {
									if (
										(dayjs(element.DatAnaliseAmostra).format("LT") === props._refLabelHour.current[`${props.indexAnalysis}:${props.index}`]
										|| (dayjs(element.DatAnaliseAmostra).format("LT") === dayjs(_currencyValueDate.current).format("LT")))
										&& props.analysisTimeList[props.indexAnalysis][8].CodAnalise === element.CodAnalise
									) {
										e.component.instance().option("value", element.ValAmostra);
										e.component.option("tipFuncionando", element.TipFuncionando);
										if(!element.TipFuncionando) e.component.option("disabled", true)
									}
								}
							});

							//Desabilita conforme horas de registros de paradas
							props.analysisTimeList[props.indexAnalysis][6].forEach((element) => {
								if (!props.isLot) {
									if (dayjs(element).format("LT") === props._refLabelHour.current[`${props.indexAnalysis}:${props.index}`]) {
										e.component.instance().option("readOnly", true);
									}
								}
							});

							//Desabilita input se está com a ação de Apenas visualização
							if(props.actionOnlyViewer) e.component.option("disabled", props.actionOnlyViewer)
							
							e.component.option("codanalise", props.analysisTimeList[props.indexAnalysis][8].CodAnalise);
							
						}}
						onValueChanged={(e) => onValueChanged(e, "sbxPositiveNegative")}
						ref={(node) => {
							props._refInput.current[`${props.indexAnalysis}:${props.index}`] = node;
							_refInputPrevious.current[`${props.indexAnalysis}:${props.index}`] = node;
						}}
						//Verifica se a hora é maior que a hora atual, pois não pode inserir valores de amostras futuros
						visible={
							props.isLot === true || props.tipPeriodUnit === 7 || props.tipPeriodUnit === 4
								? true
								: props.analysisTimeList[props.indexAnalysis][5].some(
									(item) => dayjs(item).format("LT") === dayjs(_currencyValueDate.current).format("LT")
						  ) //Verifica se a hora é maior que a hora atual
						}>
						<SelectBoxButton name="clear" />
						<SelectBoxButton name="checkValue" location="after" options={optionsValueIcon} />
					</SelectBox>
				</div>
			</section>
			<section>
				<div
					className="dx-field-label"
					style={{
						height: "15px",
						width: isLarge || isMedium || isSmall ? 50 : 35,
						display: props.headerLotRender
							? "inline"
							: props.isLot
							? "none"
							: props.index === 0
							? "inline-block"
							: "none",
					}}>
					{isXSmall || isXLSmall ? "S/A" : "S/A"}
				</div>
				<div
					id={"chbNotWorking"}
					style={{
						height: "10px",
						marginTop: props.isLot ? "-18px" : "8px",
						float: "left",
					}}>
					<CheckBox
						name="chbNotWorking"
						stylingMode="outlined"
						className={"checkbox-large"}
						width={"100%"}
						onValueChanged={async (e) => {
							const refInput = props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance;

							onValueChangedChbNotWorking(e)

							//Altera o valor da amostra
							refInput.option("disabled", e.value ? true : false);
							refInput.option("tipFuncionando", e.value);

							onValueChanged(e, "chbNotWorking");
						}}
						onInitialized={(e) => onInitializedChbNotWorking(e)}
						visible={
							!props.isLot
								? props.analysisTimeList[props.indexAnalysis][5].some(
										(item) => dayjs(item).format("LT") === dayjs(props.sampleHour).format("LT")
								  ) //Verifica se a hora é maior que a hora atual
								: true
						}
						tabIndex={-1}
						id={!props.isLot ? `${props.indexAnalysis}:${props.index}` : "0:0"}
						hint={"Marque se não foi possível fazer a leitura"}
						ref={(node) => {
							props._refBtnNotWorking.current[`${props.indexAnalysis}:${props.index}`] = node;
						}}
					/>
				</div>
			</section>
		</div>
		</>
	);
};
