/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "../../../components/common/style.scss";
import "devextreme/data/odata/store";
import {LogMessageGrid} from "../../../utils/logMessage";
import {formatMessage} from "devextreme/localization";
import DataGrid, {
    Column,
    ColumnChooser, ColumnFixing, Editing,
    FilterRow,
    Form,
    Grouping,
    GroupPanel,
    Item, Pager, Paging,
    RequiredRule, Toolbar
} from "devextreme-react/data-grid";
import {api} from "../../../services/api";
import {useLoading} from "../../../contexts/loadingContext";
import {useAuth} from "../../../contexts/auth";
import {LoadIndicator} from "devextreme-react/load-indicator";
import Button from "devextreme-react/button";

export default function Email(props) {
    const { loading } = useLoading();
    const [load, setLoad] = useState(false);
    const [email, setEmail] = useState(false);
    const _email = useRef();

    const { unitCod, refreshToken, getRefreshTokenCode, getToken } = useAuth();

    useEffect(() => {
        firstLoad()
    }, [props.update]);

    useEffect(() => {
        if (load) {
            updateLoad();
        }
    }, [load]);

    async function updateLoad() {
        loadData();
    }

    async function firstLoad() {
        if (unitCod !== undefined) {
            loadData();
            await refreshToken(getToken(), getRefreshTokenCode());
        }
    }

    async function loadData() {
        await api
            .get("/email", { params: { unitCod: unitCod } })
            .then((response) => {
                setEmail(response.data);
                setLoad(false);
            })
            .catch(() => {
                setLoad(true);
                return true;
            });
    }

    async function updateRow(data, parLogMessage) {
        return await api
            .put("/email", { unitCod: unitCod, data: data, logMessage: parLogMessage })
            .then((response) => {
                setLoad(true);
                props.setUpdate(!props.update)
                return false;
            })
            .catch(() => {
                setLoad(true);
                return true;
            });
    }

    async function insertRow(data, parLogMessage) {
        return await api
            .post("/email", { unitCod: unitCod, data: data, logMessage: parLogMessage })
            .then((response) => {
                setLoad(true);
                props.setUpdate(!props.update)
                return false;
            })
            .catch(() => {
                setLoad(true);
                return true;
            });
    }

    async function deleteRow(data) {
        return await api
            .delete("/email", { data: data })
            .then(() => {
                setLoad(true);
                props.setUpdate(!props.update)
                return false;
            })
            .catch(() => {
                setLoad(true);

                return true;
            });
    }

    return (
        <React.Fragment>
            <DataGrid
                className={"dx-cards"}
                dataSource={email}
                rowAlternationEnabled={true}
                showColumnLines={true}
                allowColumnReordering={true}
                showBorders={false}
                columnHidingEnabled={true}
                repaintChangesOnly={true}
                onInitNewRow={(e) => {
                    e.data.CodUnidadeEmpresa = unitCod;
                    e.data.NomEmail = "";
                    e.data.DesUsuariosPara = "";
                    e.data.DesUsuariosCopiaOculta = "";
                }}
                headerFilter={{ visible: true }}
                filterPanel={{ visible: true }}
                filterRow={{ visible: false }}
                onRowUpdating={async (e) => {
                    const newObj = { ...e.oldData, ...e.newData };
                    let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _email, newObj);

                    e.cancel = updateRow(newObj, strLogMessage);

                    e.component.deselectAll();
                }}
                onRowInserting={async (e) => {
                    let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _email, e.data);

                    e.cancel = insertRow(e.data, strLogMessage);

                    e.component.deselectAll();
                }}
                onRowRemoving={(e) => {

                    let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _email, e.data);
                    const data = { unitCod: unitCod, data: e.data, logMessage: strLogMessage };

                    e.cancel = deleteRow(data);

                    e.component.deselectAll();

                }}
                ref={_email}>
                <Editing mode={"batch"} allowUpdating={true} allowDeleting={true} allowAdding={false}>
                    <Form>
                        <Item itemType="group" colCount={4} colSpan={2}>
                            <Item colSpan={2} dataField="NomEmail" />
                            <Item colSpan={2} dataField="DesUsuariosPara" />
                            <Item colSpan={2} dataField="DesUsuariosCopiaOculta" />
                        </Item>
                    </Form>
                </Editing>
                <Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false} />
                <GroupPanel visible={false} />
                <ColumnChooser enabled={true} mode="select" />
                <ColumnFixing enabled={false} />
                <Paging defaultPageSize={20} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />
                <Toolbar >
                    <Item cssClass={'add-container'}>
                        <Button className={'add-button'}  icon={"add"} onClick={() => (_email.current.instance.addRow())}/>
                    </Item>
                    <Item name="saveButton"/>
                    <Item name="revertButton"/>
                    <Item name="exportButton"/>
                    <Item name="columnChooserButton"/>
                </Toolbar>
                <Column
                    dataField={"NomEmail"}
                    caption={"Nome Email"}
                    allowSorting={true}
                    allowFiltering={true}
                    allowSearch={true}
                    allowGrouping={true}
                    allowEditing={true}>
                    <RequiredRule />
                </Column>
                <Column dataField={"DesUsuariosPara"} caption={"Destinatários - separar com ;"} allowSorting={true} width={"40%"}/>
                <Column
                    dataField={"DesUsuariosCopiaOculta"}
                    caption={"Destinatários Ocultos - separar com ;"}
                    allowSorting={true}
                    sortIndex={0}
                    width={"40%"}>
                </Column>
            </DataGrid>
            <div className="load-indicator">
                <LoadIndicator visible={loading} height={40} width={40} />
            </div>
        </React.Fragment>
    );
}
