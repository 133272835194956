/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "./style.scss";
import DataGrid, {
  Column,
  Pager,
  RequiredRule,
  StringLengthRule,
  Paging,
  FilterRow,
  Export,
  Selection,
  ColumnChooser,
  Grouping,
  Lookup,
  GroupPanel,
  ColumnFixing,
  CustomRule, Item, Toolbar
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { LogMessageGrid } from "../../../utils/logMessage"
import { formatMessage } from "devextreme/localization";
import { useLoading } from "../../../contexts/loadingContext";
import Button from "devextreme-react/button";

export default function State(props) {
  const { loading } = useLoading();
  const { getToken } = useAuth();
  const [load, setLoad] = useState(false);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const _state = useRef();

  //Autenticação requests
  const { unitCod } = useAuth();

  useEffect(() => {
    firstLoad();
  }, []);

  useEffect(() => {
    awaitRequests();
  }, [props.update]);

  useEffect(() => {
    if(load){
      awaitRequests()
    }
  }, [load]);

  async function firstLoad(){
    //if (await validateToken(token.current)){
      if(unitCod !== undefined) {
        loadData();
        //getCountries();
      }
    //}
  }

  async function awaitRequests() {
    if(unitCod !== undefined) {
      loadData()
      getCountries()
    }
  }

  async function loadData() {
    await api
      .get("/state", { params: { unitCod: unitCod } })
      .then((response) => {
        setStates(response.data);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function getCountries() {
    await api
      .get("/country")
      .then((response) => {
        setCountries(response.data);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function updateRow(data, parLogMessage) {
    return await api
      .put("/state", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true);
        props.setUpdate(!props.update)

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/state", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true);
        props.setUpdate(!props.update)

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function deleteRow(data) {
    return await api
      .delete("/state", { data: data })
      .then((response) => {
        setLoad(true);
        props.setUpdate(!props.update)

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  /*const onToolbarPreparing = (e) => {
      var dataGrid = e.component;

      e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
              elementAttr: {id: 'ToolbarAddNewButton'},
              hint: "Adicionar um registro",
              icon: "add",
              onClick: () => { dataGrid.addRow() }
          },
      });
  }*/

  function checkUniqueKey(params) {
    let check = true;

    states.forEach((unit) => {
      if(unit.CodEstado !== params.data.CodEstado &&
        unit.NomEstado === params.data.NomEstado
        ) check = false;
    })

    return check;
  }

  return (
    <React.Fragment>
      <div id="stateGrid">
        <DataGrid
          className={"dx-cards"}
          dataSource={states}
          rowAlternationEnabled={true}
          showColumnLines={true}
          allowColumnReordering={true}
          showBorders={false}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          repaintChangesOnly={false}
          onInitNewRow={(e) => {
            if(countries.length === 1) e.data.CodPais = countries[0].CodPais
          }}
          headerFilter={{ visible: true }}
          filterPanel={{ visible: true }}
          filterRow={{ visible: false }}
          editing={{
            refreshMode: "full",
            mode:"batch",
            allowUpdating: true,
            allowDeleting: true,
            allowAdding: false,
          }}
          onRowUpdating={async (e) => {
            const newObj = { ...e.oldData, ...e.newData };
            let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _state, newObj);

            e.cancel = updateRow(newObj, strLogMessage);

            e.component.deselectAll();
          }}
          onRowInserting={(e) => {
            let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _state, e.data);

            e.cancel = insertRow(e.data, strLogMessage);

            e.component.deselectAll();
          }}
          onRowRemoving={(e) => {
            let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _state, e.data)
            const data = { unitCod: unitCod,  data: e.data, logMessage: strLogMessage };

            e.cancel = deleteRow(data);

            e.component.deselectAll();
          }}
          ref={_state}
        >
          <Grouping
            contextMenuEnabled={false}
            expandMode="rowClick"
            autoExpandAll={false}
          />
          <GroupPanel visible={false} />
          <Export
            enabled={true}
            fileName="Estados"
            allowExportSelectedData={true}
          />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={false} />
          <Selection mode="multiple" />
          <Paging defaultPageSize={20} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <FilterRow visible={true} />
          <Toolbar >
            <Item cssClass={'add-container'}>
              <Button className={'add-button'}  icon={"add"} onClick={() => (_state.current.instance.addRow())}/>
            </Item>
            <Item name="saveButton"/>
            <Item name="revertButton"/>
            <Item name="exportButton"/>
            <Item name="columnChooserButton"/>
          </Toolbar>
          <Column
            dataField={"NomEstado"}
            caption={"Nome do Estado"}
            allowSorting={true}
            width={"auto"}
            allowEditing={true}
          >
            <CustomRule
              message={'Este campo deve ser único'}
              reevaluate={true}
              type={"custom"}
              validationCallback={checkUniqueKey}
            />
            <RequiredRule />
          </Column>
          <Column
            dataField={"DesSiglaEstado"}
            caption={"Sigla Estado"}
            allowSorting={true}
            width={"auto"}
            allowEditing={true}
          >
            <RequiredRule />
            <StringLengthRule
              max={2}
              message={"No máximo dois caracteres"}
            />
          </Column>
          <Column
            dataField={"CodPais"}
            caption={"Nome País"}
            allowSorting={true}
            width={"auto"}
          >
            <Lookup
              dataSource={countries}
              displayExpr={"NomPais"}
              valueExpr={"CodPais"}
            />
            <RequiredRule />
          </Column>
        </DataGrid>
      </div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
