import React, { useState } from "react";
import "./style.scss";
import { Card, makeStyles, 
		 CardActionArea, CardContent, 
		 Typography, CardActions } 
from "@material-ui/core";
import { useScreenSize } from "../../utils/media-query";

const useStyles = makeStyles({
	desktop: {
		minWidth: "24%",
		width: "100%",
		height: "fit-content",
		margin: "1vh",
		display: "flex",
		flexGrow: "1",
	},

	tablet: {
		width: "100%",
		margin: "1vh",
		height: "fit-content",
		flexGrow: "1",
	},

	mobile: {
		width: "100%",
		height: "fit-content",
		margin: "1vh",
		display: "flex",
		flexGrow: "1",
	},

	media: {
		height: 140,
	},
});

export const OpcServerCard = (props) => {
	const [load, setLoad] = useState(true);
	const classes = useStyles();
	const { isXSmall, isLarge } = useScreenSize();

	function textColorStatus(){
		if(props.opcServer.Status === "Operational" || 
		  (props.opcServer.Status.indexOf("The operation completed successfully.") > -1))
			return "rgb(7, 190, 7)";
		
		return "red"; 
	}

	return (
		<div className={"ContainerCard test"}>
			<h4 className={"MarginHeader"}>SERVIDOR OPC</h4>
			<Card
				aria-hidden={true}
				className={ isLarge ? classes.desktop : isXSmall ? classes.mobile : classes.tablet }
			>
				<CardContent className="ContainerCardContent" style={{ width: "100%", height: "100%" }}>
					<div>
						<Typography variant="h6" color="textSecondary" component="p" className={"ContainerInputValue"}>
							<span className={"Name"}>Status:</span> 
								<span
									className={"Value"} 
									style={{color: textColorStatus()}}
								>
									{props.opcServer.Status}
								</span>
						</Typography>
						<Typography variant="h6" color="textSecondary" component="p" className={"ContainerInputValue"}>
							<span className={"Name"}>Permissão Acesso:</span>
							<span className={"Value"}>{props.opcServer.AcessPermission}</span>
						</Typography>
						<Typography variant="h6" color="textSecondary" component="p" className={"ContainerInputValue"}>
							<span className={"Name"}>Canonical Data Type:</span>
							<span className={"Value"}>{props.opcServer.CanonicalDataType}</span>
						</Typography>
					</div>
				</CardContent>
			</Card>
		</div>
	)
};