/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import CardNotification from './cardNotification';
import Button from 'devextreme-react/button';
import { useScreenSize } from '../../utils/media-query';
import { api } from "../../services/api";
import { useAuth } from "../../contexts/auth";
import "./style.scss";

export default function PopupNotification(props) {
    const [load, setLoad] = useState(false);
    const [notificationsFiltreds, setNotificationsFiltreds] = useState([]);
    const [visibleTask, setVisibleTask] = useState(false);
    const [visibleVariable, setVisibleVariable] = useState(false);
    const [visibleDeviations, setVisibleDeviations] = useState(false);
    const [visibleAll, setVisibleAll] = useState(false);
    const [visibleReconhecerTodos, setReconhecerTodos] = useState(false);
    const [heightScreen, setHeightScreen] = useState();
    const [filter, setFilter] = useState("all");
    const { isXSmall, isSmall } = useScreenSize();
    const { unitCod } = useAuth();

    useEffect(() => {
        setHeightScreen(window.innerHeight);
    }, []);
    
    useEffect(() => {
        filterNotifications();
        filterNotificationsNavBar();
    }, [props.notifications, props.openPopupNotification, filter]);

    useEffect(() => {
        if(load) {
            props.loadDataNotifications();
            setLoad(false);
        }
    }, [load]);

    async function updateAlarms(data) {
        return await api
          .put("/notification", { unitCod: unitCod, data: data })
          .then((response) => {
            setLoad(true);
            
            return false;
          })
          .catch((error) => {
            setLoad(true);
            
            return true;
          });
    };

    function invisiblePopupNotification() {
        props.setOpenPopupNotification(false);
    };

    function onClickRecognizeAll(e) {
        updateAlarms(notificationsFiltreds);
    };

    function onClickRecognize (parNotification){
        updateAlarms(parNotification);
    };

    function filterNotifications() {
        if(filter !== "all" && props.notifications.length > 0) {
            const newLstNotifications = props.notifications.filter(notification => notification.TipoAlarme === filter);

            if(newLstNotifications.length <= 0) setFilter("all");

            if(newLstNotifications.length > 0) setNotificationsFiltreds(newLstNotifications);
        };
        
        if(filter === "all" && props.notifications.length > 0)
            setNotificationsFiltreds(props.notifications);
    };

    function filterNotificationsNavBar() {
        setVisibleDeviations(false);
        setVisibleTask(false);
        setVisibleVariable(false);
        setVisibleAll(false);
        setReconhecerTodos(false);

        if(props.notifications.length > 0) props.notifications.forEach((notification) => {
            if(notification.TipoAlarme === "Desvio") setVisibleDeviations(true);
            if(notification.TipoAlarme === "Tarefa") setVisibleTask(true);
            if(notification.TipoAlarme === "Variáveis") setVisibleVariable(true);
        });

        if(props.notifications.length > 0) {
            setVisibleAll(true);
            setReconhecerTodos(true);
        };
    };

    function filterByTask() {
        setFilter("Tarefa");
    };

    function filterByDetour() {
        setFilter("Desvio");
    };

    function filterByVariables() {
        setFilter("Variáveis");
    };

    function filterByAll() {
        setFilter("all");
    };

    function checkNotifications() {
        if(props.notifications.length <= 0)
            return <div>Você não possui notificações de tarefas e variáveis!</div>;
        
        if(notificationsFiltreds.length > 0) {
            let result = notificationsFiltreds.map((notification) => {
                return  <CardNotification key={notification.CodAlarme}
                            alarmCode={notification.CodAlarme}
                            title={notification.NomAlarme}
                            description={notification.DesMensagem}
                            color={notification.TipoAlarme}
                            severity={notification.TipSeveridade}
                            codReadingVariable={notification.CodVariavelLeitura}
                            onClickRecognize={onClickRecognize}
                        />
            });

            return result;
        };
    };

    function selectedTaskTab() {
        if(filter === 'Tarefa') return 'LineTask BackgroundColorButtonTab';

        return 'LineTask BackgroundColorButton';
    };

    function selectedDesvioTab() {
        if(filter === 'Desvio') return 'LineAnalogic BackgroundColorButtonTab';

        return 'LineAnalogic BackgroundColorButton';
    };

    function selectedVariaveisTab() {
        if(filter === 'Variáveis') return 'LineVariable BackgroundColorButtonTab';

        return 'LineVariable BackgroundColorButton';
    };

    function selectedAll() {
        if(filter === 'all') return 'LineAll BackgroundColorButtonTab';

        return 'LineAll BackgroundColorButton';
    };

    function setHeightPopup() {
        return `${heightScreen - 56}px`;
    };

    return (
        <Popup
            title={"Notificações"}
            width={isXSmall ? "100%" : isSmall ? "100%" : "550px"}
            height={isXSmall ? "100%" : isSmall ? "100%" : setHeightPopup()}
            visible={props.openPopupNotification}
            showTitle={true}
            closeOnOutsideClick={true}
            shading={false}
            onHiding={invisiblePopupNotification}
            showCloseButton={true}
            position={'right bottom'}
        >
            <ScrollView>
                <Button
                    className={selectedDesvioTab()} 
                    stylingMode={'text'}
                    text={"Desvios"}
                    onClick={filterByDetour}
                    hoverStateEnabled={false}
                    visible={visibleDeviations}
                    focusStateEnabled={false}
                />
                <Button
                    className={selectedTaskTab()} 
                    stylingMode={'text'}
                    text={"Atraso Tarefas"}
                    hoverStateEnabled={false}
                    onClick={filterByTask}
                    visible={visibleTask}
                    focusStateEnabled={false}
                />
                <Button
                    className={selectedVariaveisTab()} 
                    stylingMode={'text'}
                    text={"Atraso Variáveis"}
                    hoverStateEnabled={false}
                    onClick={filterByVariables}
                    visible={visibleVariable}
                    focusStateEnabled={false}
                />
                <Button
                    className={selectedAll()}
                    stylingMode={'text'}
                    text={"Tudo"}
                    hoverStateEnabled={false}
                    onClick={filterByAll}
                    visible={visibleAll}
                    focusStateEnabled={false}
                />
                <div className={"SpaceButtonReconhecerTodos"}>
                    <Button
                        stylingMode={'text'}
                        icon={"check"}
                        text={"Reconhecer todos"}
                        visible={visibleReconhecerTodos}
                        onClick={(e) => onClickRecognizeAll(e)}
                    />
                </div>
                <div>
                    {checkNotifications()}
                </div>
            </ScrollView>
        </Popup>
    );
};