import React, { useState } from "react";
import "devextreme/data/odata/store";
import "../../components/common/style.scss";
import "../../"
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/form";
import GroupProgram from "./groupProgram";
import Program from "./program";
import { FavButton } from "../../components/fav-button/favButton";

export default function ProgramTab() {
  const [update, setUpdate] = useState(false);

  const renderGroupProgramTab = () => {
    return (
      <GroupProgram
        update={update}
        setUpdate={setUpdate}
      />
    )
  }

  const renderProgramTab = () => {
    return (
      <Program
        update={update}
      />
    )
  }

  return (
    <React.Fragment>
      <FavButton/>
      <TabPanel
        className={"tab-panel-margin"}
        animationEnabled={true}
        swipeEnabled={false}
      >
        <Item title="Grupos" render={renderGroupProgramTab} />
        <Item title="Programas" render={renderProgramTab} />
      </TabPanel>
    </React.Fragment>
  )
}
