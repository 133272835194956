/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { Button } from "devextreme-react";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "../style.scss";
import { SelectBox } from "devextreme-react";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { CheckBox } from "devextreme-react";
import { LogMessageCheckboxList } from "../../../utils/logMessage"
import { formatMessage } from "devextreme/localization";
import { useLoading } from "../../../contexts/loadingContext";
import { useScreenSize } from "../../../utils/media-query";

export default function ActionPermission(props) {
  const { loading } = useLoading();
  const { isXSmall, isXLSmall, isSmall } = useScreenSize();
  const [load, setLoad] = useState(false);
  const [permission, setPermission] = useState([]);
  const [program, setProgram] = useState([]);
  const [action, setAction] = useState([]);
  const [actionPermission, setActionPermission] = useState([]);
  const [permissionChange, setPermissionChange] = useState(0);
  const [programChange, setProgramChange] = useState(0);
  const [enabledButton, setEnabledButton] = useState(true);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const [textButton, setTextButton] = useState();
  const _list = useRef([]);
  let permissionName = useRef();

  const { unitCod } = useAuth();

  useEffect(() => {
    firstLoad();
  }, [props.update]);

  useEffect(() => {
    if (load) updateActionPermission();
  }, [load]);

  useEffect(() => {
    if(permissionChange > 0 || programChange > 0) updates();

    checkSelectionChanged();
  }, [permissionChange, programChange])

  useEffect(() => {
    if(action.length > actionPermission.length) {
      const newLstActionPermissions = action.map((program) => {return program.CodAcao});
      setActionPermission(newLstActionPermissions);
    }

    if(actionPermission.length === action.length) {
      setActionPermission([]);
    }
  }, [selectedAll]);

  useEffect(() => {
    if(action.length === actionPermission.length) setTextButton("Desmarcar todos");
    if(action.length > actionPermission.length) setTextButton("Marcar todos");
  }, [actionPermission, forceUpdate]);

  async function updates() {
    await loadDataAction();
    loadDataActionPermission();
  }

  async function firstLoad() {
    if (unitCod !== undefined) {
      loadDataPermission();
      loadDataProgram();
      loadDataAction();
    }
  }

  async function updateActionPermission() {
    if(unitCod !== undefined) loadDataActionPermission();
  }

  async function loadDataPermission() {
    await api
      .get("/permission", { params: { unitCod: unitCod } })
      .then((response) => {
        setPermission(response.data);
        setLoad(false);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function loadDataProgram() {
    await api
      .get("/program")
      .then((response) => {
        const lstPrograms = response.data.filter((x) => x.TipLiberarParaCliente === true);

        lstPrograms.sort(function (a, b) {
            return (a.NomPrograma > b.NomPrograma) ? 1 : ((b.NomPrograma > a.NomPrograma) ? -1 : 0);
        });

        setProgram(lstPrograms);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function loadDataActionPermission() {
    await api
      .get("/actionPermission", { params: { codPermission: permissionChange, codProgram: programChange } })
      .then((response) => {
        setActionPermission(response.data.map((actionPermission) => {return actionPermission.CodAcao}));
        _list.current = response.data;

        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function loadDataAction() {
    await api
      .get("/action", { params: { codProgram: programChange } })
      .then((response) => {
        setAction(response.data);

        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/actionPermission", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(false);

        return true;
      });
  }

  async function deleteRow(data) {
    return await api
      .delete("/actionPermission", { unitCod: unitCod, data: data })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function onSelectionChangedPermission (e) {
    setPermissionChange(e.selectedItem ? e.selectedItem.CodPermissao : 0)
    permissionName.current = (e.selectedItem ? e.selectedItem.NomPermissao : 0)
  }

  async function onSelectionChangedGroupProgram (e) {
    setProgramChange(e.selectedItem ? e.selectedItem.CodPrograma : 0)
  }

  function checkSelectionChanged() {
    if(permissionChange > 0 || programChange > 0) {
      setEnabledButton(false);
    } else {
      setEnabledButton(true);
      setActionPermission([]);
    }
  }

  function onValueChangedCheckBoxPermissions(e, codAction) {
    if(e.event) {
      let newLstActionPermissions = actionPermission;

      if(e.value) {
        newLstActionPermissions.push(codAction);
        setActionPermission(newLstActionPermissions);
        setForceUpdate(!forceUpdate);
      } else {
        newLstActionPermissions = actionPermission.filter(code => code !== codAction);
        setActionPermission(newLstActionPermissions);
        setForceUpdate(!forceUpdate);
      };
    }
  };

  async function eventSaveClick(e) {
    const deleteActionsPermission = _list.current.filter((element) => {
      const found = actionPermission.find(codAcao => codAcao === element.CodAcao);

      if(found === undefined){
        element.NomLogFirst = permissionName.current
        element.NomLogSecond = action.filter(x => x.CodAcao === element.CodAcao)[0].NomAcao;

        return element
      }
    });

    const insertActionsPermission = [];

    actionPermission.forEach((codAcao) => {
      const found = _list.current.find((element) => element.CodAcao === codAcao);

      if(found === undefined) {
        action.forEach((action) => {
          if(action.CodAcao === codAcao) {
            const newActionPermission = {
              CodAcao: codAcao,
              CodPermissao: permissionChange,
              NomLogFirst: permissionName.current,
              NomLogSecond: action.NomAnaliseGrupo
            };

            insertActionsPermission.push(newActionPermission);
          }
        })
      }
    })

    deleteActionsPermission.forEach((item) => {
      let logMessage = LogMessageCheckboxList(formatMessage("DeletedLog"), item)
      const data = { unitCod: unitCod, data: item, logMessage: logMessage };

      deleteRow(data);
    })

    insertActionsPermission.forEach((item) => {
      let logMessage = LogMessageCheckboxList(formatMessage("InsertedLog"), item)
      insertRow(item, logMessage);
    })
  }

  return (
    <React.Fragment>
      <div className="header-form">
        <div className={"form-container"}>
          <div className={"margin-element"}>
              <SelectBox
                dataSource={permission}
                label={"Permissão"}
                searchEnabled={true}
                onSelectionChanged={onSelectionChangedPermission}
                displayExpr="NomPermissao"
                valueExpr="CodPermissao"
                width={250}
                stylingMode={"outlined"}
                labelMode={"floating"}
              />
          </div>
          <div className={isXLSmall ? "invisible-item" : isXSmall ? "invisible-item" : isSmall ? "invisble-item" : "margin-element"}>
              <SelectBox
                dataSource={program}
                label={"Programa"}
                searchEnabled={true}
                onSelectionChanged={onSelectionChangedGroupProgram}
                displayExpr="NomPrograma"
                valueExpr="CodPrograma"
                showClearButton={true}
                width={250}
                stylingMode={"outlined"}
                labelMode={"floating"}
              />
          </div>
          <div className={"margin-element"}>
            <Button
              text={textButton}
              icon={!selectedAll ? "selectall" : "unselectall"}
              type="normal"
              className={"selectall-button"}
              useSubmitBehavior={false}
              stylingMode="contained"
              onClick={async (e) => {
                setSelectedAll(!selectedAll);
              }}
              disabled={enabledButton}
            />
          </div>
          <div className={"margin-element"}>
            <Button
              className={"save-button"}
              id="searchFilterButton"
              icon="save"
              text="Salvar"
              type="default"
              useSubmitBehavior={false}
              stylingMode="contained"
              onClick={async (e) => {
                eventSaveClick()
              }}
              disabled={enabledButton}
            />
          </div>
        </div>
      </div>
      <div>
        <div>
          {action.map((actions) => {
            let valueCheckBox = false;

            actionPermission.forEach((act) => {
              if(act === actions.CodAcao) valueCheckBox = true;
            });

            return(
                      <div style={{display: "inline-block", margin: "3% 1% 0% 2%", width: "200px"}} key={actions.CodAcao}>
                        <CheckBox
                          className={"checkbox-medium"}
                          value={valueCheckBox}
                          text={actions.NomAcao}
                          onValueChanged={(e) => onValueChangedCheckBoxPermissions(e, actions.CodAcao)}
                        />
                      </div>
                  )
          })}
          </div>
        </div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
