import React, { createContext, useContext } from "react";

const LocalizationContext = createContext({});
const useLocalization = () => useContext(LocalizationContext);

function LocalizationProvider(props) {
  const LANGUAGE_KEY = "@es-mes/userLanguage";
  const changeLanguage = (language) => {
    localStorage.setItem(LANGUAGE_KEY, language);
    sessionStorage.setItem("@es-mes/blockLoopReload", true);
    document.location.reload();
  };
  const setInitialLanguage = (language) => {
    if (!localStorage.getItem(LANGUAGE_KEY))
      localStorage.setItem(LANGUAGE_KEY, language);
  };
  return (
    <LocalizationContext.Provider
      value={{
        changeLanguage,
        setInitialLanguage,
        LANGUAGE_KEY,
      }}
      {...props}
    />
  );
}

export { LocalizationProvider, useLocalization };
