import React from 'react';
import dayjs from 'dayjs';
import {useScreenSize} from "../../utils/media-query";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const { isXSmall } = useScreenSize()

    return(
        <div 
            className="dx-field-label" 
            id={`${props.indexAnalysis}:${props.index}:1`}
            ref={node => {
                if(node !== null)
                    props._refLabelHour.current[`${props.indexAnalysis}:${props.index}`] = node.innerText
            }}
            style={{width: isXSmall ? '22vw' : 42, marginTop: props.index === 0 ? "17px" : 0, marginBottom: 0, display: props.isLot ? "none" : "inline", paddingRight: 1}}
        >
            {dayjs(props.sampleHour).format('HH:mm')}
        </div>
    )
}