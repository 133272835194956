/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "./style.scss";
import DataGrid, {
	Column,
	Pager,
	RequiredRule,
	Paging,
	FilterRow,
	Export,
	Selection,
	ColumnChooser,
	Grouping,
	GroupPanel,
	ColumnFixing,
	Lookup,
	Editing,
	CustomRule,
	Popup,
	Form,
	Position,
	Item, Toolbar,
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import dayjs from "dayjs";
import { LogMessageGrid } from "../../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import notify from "devextreme/ui/notify";
import { useLoading } from "../../../contexts/loadingContext";
import { useScreenSize } from "../../../utils/media-query";

export default function ConfigurationManual(props) {
	const { loading } = useLoading();
	const [load, setLoad] = useState(false);
	const [readingVariable, setReadingVariable] = useState([]);
	const [readingVariableManual, setReadingVariableManual] = useState([]);
	const [type, setType] = useState([]);
	const _monitoration = useRef();
	const { isLarge } = useScreenSize();

	//Autenticação requests
	const { unitCod } = useAuth();

	useEffect(() => {
		firstLoad();
	}, []);

	useEffect(() => {
		if (load) {
			loadData();
		}
	}, [load]);

	async function firstLoad() {
		loadDataSourceReadingVariable();
		await loadDataSourceType();
		loadData();
	}

	async function loadData() {
		await api
			.get("/readingVariableManual", { params: { unitCod: unitCod } })
			.then((response) => {
				setReadingVariableManual(response.data);
				setLoad(false);

				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function loadDataSourceReadingVariable() {
		await api
			.get("/readingVariable", { params: { unitCod: unitCod } })
			.then((response) => {
				setReadingVariable(response.data);
				setLoad(false);

				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function loadDataSourceType() {
		await api
			.get("/manualVariables")
			.then((response) => {
				setType(response.data.filter((x) => x.TipUnidadePeriodo !== 1));
				setLoad(false);

				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function updateRow(data, parLogMessage) {
		return await api
			.put("/readingvariableManual", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then((response) => {
				setLoad(true);

				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}


	const dateBoxHourOptions = {
		displayFormat: "HH:mm",
		useMaskBehavior: true,
		type: "time"
	};

	function checkDates(params) {
		let check = true;

		check = dayjs(params.DatIniciaMonitoracao).isBefore(dayjs(params.DatFinalizaMonitoracao));
		return check;
	}

	function checkZeroValue(params) {
		let checkZero = true;
		checkZero = params.data.ValPeriodo > 0;

		return checkZero;
	}

	function checkZeroValueCondition(params) {
		let checkZero = true;
		if (params.data.TipHabilitaAlarmeAtualizacao) {
			checkZero = params.data.ValPeriodoAlarme > 0;
		}

		return checkZero;
	}

	return (
		<React.Fragment>
			<DataGrid
				className={"dx-cards"}
				activeStateEnabled={true}
				repaintChangesOnly={true}
				dataSource={readingVariableManual}
				rowAlternationEnabled={true}
				showColumnLines={true}
				allowColumnReordering={true}
				showBorders={false}
				columnAutoWidth={true}
				columnHidingEnabled={true}
				headerFilter={{ visible: true }}
				filterPanel={{ visible: true }}
				filterRow={{ visible: true }}

				onRowUpdating={async (e) => {
					let datInitialHour = dayjs(e.newData.DatIniciaMonitoracao).hour();
					let datInitialMinute = dayjs(e.newData.DatIniciaMonitoracao).minute();
					let datInitialSecond = dayjs(e.newData.DatIniciaMonitoracao).second();

					let datFinalHour = dayjs(e.newData.DatFinalizaMonitoracao).hour();
					let datFinalMinute = dayjs(e.newData.DatFinalizaMonitoracao).minute();
					let datFinalSecond = dayjs(e.newData.DatFinalizaMonitoracao).second();

					if (e.newData.DatIniciaMonitoracao)
						e.newData.DatIniciaMonitoracao = dayjs("1900-01-01T00:00:00")
							.add(datInitialHour, "hour")
							.add(datInitialMinute, "minutes")
							.add(datInitialSecond, "seconds")
							.format("YYYY-MM-DDTHH:mm:ss");

					if (e.newData.DatFinalizaMonitoracao)
						e.newData.DatFinalizaMonitoracao = dayjs("1900-01-01T00:00:00")
							.add(datFinalHour, "hour")
							.add(datFinalMinute, "minutes")
							.add(datFinalSecond, "seconds")
							.format("YYYY-MM-DDTHH:mm:ss");

					const newObj = { ...e.oldData, ...e.newData };

					if (!checkDates(newObj)) {
						notify(formatMessage("DateInvalid"), "error", 3000);
						e.cancel = true;
					} else {
						let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _monitoration, newObj);

						e.cancel = updateRow(newObj, strLogMessage);

						e.component.deselectAll();
					}
				}}
				ref={_monitoration}>
				<Editing refreshMode={"full"} mode={isLarge ? "batch" : "popup"} allowUpdating={true} allowAdding={false} allowDeleting={false}>
					<Popup title="Variáveis Manuais - Monitoração" showTitle={true} width={700} height={725} fullScreen={true}>
						<Position my="center" at="center" of={window} />
					</Popup>
					<Form>
						<Item itemType="group" colCount={4} colSpan={2}>
							<Item colSpan={2} dataField="CodVariavelLeitura" />
							<Item colSpan={2} dataField="ValPeriodo" />
							<Item colSpan={2} dataField="TipUnidadePeriodo" />
							<Item colSpan={2} dataField="DatIniciaMonitoracao"  />
							<Item colSpan={2} dataField="DatFinalizaMonitoracao"  />
							<Item colSpan={2} dataField="TipHabilitaAlarmeAtualizacao" />
							<Item colSpan={2} dataField="ValPeriodoAlarme" />
							<Item colSpan={2} dataField="TipUnidadePeriodoAlarme" />
						</Item>
					</Form>
				</Editing>
				<Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false} />
				<GroupPanel visible={false} />
				<Export enabled={true} fileName="Variáveis Manuais - Monitoração" allowExportSelectedData={true} />
				<ColumnChooser enabled={true} mode="select" />
				<ColumnFixing enabled={false} />
				<Selection mode="multiple" />
				<Paging defaultPageSize={20} />
				<Pager showPageSizeSelector={true} showInfo={true} />
				<FilterRow visible={true} />
				<Toolbar >
					<Item name="saveButton"/>
					<Item name="revertButton"/>
					<Item name="exportButton"/>
					<Item name="columnChooserButton"/>
				</Toolbar>
				<Column
					dataField={"CodVariavelLeitura"}
					caption={"Nome/Descrição Variável"}
					allowSorting={true}
					width={"auto"}
					allowEditing={false}
					allowUpdating={false}>
					<Lookup
						dataSource={readingVariable}
						displayExpr={(item) => item && item.NomVariavelLeitura + " - " + item.DesVariavelLeitura}
						valueExpr={"CodVariavelLeitura"}
					/>
					<RequiredRule />
				</Column>
				<Column dataField={"ValPeriodo"} caption={"Periodo"} allowSorting={true} allowEditing={true} width={"auto"} dataType={"number"}>
					<RequiredRule />
					<CustomRule message={"Valor deve ser maior que zero"} reevaluate={true} validationCallback={checkZeroValue} />
				</Column>
				<Column dataField={"TipUnidadePeriodo"} caption={"Unidade Período"} allowSorting={true} allowEditing={true} width={"auto"}>
					<Lookup dataSource={type} displayExpr={"NomTipo"} valueExpr={"TipUnidadePeriodo"} />
					<RequiredRule />
				</Column>
				<Column
					dataField={"DatIniciaMonitoracao"}
					caption={"Início Atualização"}
					allowSorting={true}
					allowEditing={true}
					width={"auto"}
					dataType={"datetime"}
					format={"shortTime"}
					editorOptions={dateBoxHourOptions}>
					<RequiredRule />
				</Column>
				<Column
					dataField={"DatFinalizaMonitoracao"}
					caption={"Final Atualização"}
					allowSorting={true}
					allowEditing={true}
					width={"auto"}
					dataType={"datetime"}
					format={"shortTime"}
					editorOptions={dateBoxHourOptions}>
					<RequiredRule />
				</Column>
				<Column
					dataField={"TipHabilitaAlarmeAtualizacao"}
					caption={"Alarme Atualização"}
					allowSorting={true}
					allowEditing={true}
					width={"auto"}
					dataType={"boolean"}></Column>
				<Column dataField={"ValPeriodoAlarme"} caption={"Período Alarme"} allowSorting={true} allowEditing={true} width={"auto"}>
					<RequiredRule />
					<CustomRule message={"Valor deve ser maior que zero"} reevaluate={true} validationCallback={checkZeroValueCondition} />
				</Column>
				<Column dataField={"TipUnidadePeriodoAlarme"} caption={"Unidade Período"} allowSorting={true} allowEditing={true} width={"auto"}>
					<Lookup dataSource={type} displayExpr={"NomTipo"} valueExpr={"TipUnidadePeriodo"} />
					<RequiredRule />
				</Column>
			</DataGrid>
			<div className="load-indicator">
				<LoadIndicator visible={loading} height={40} width={40} />
			</div>
		</React.Fragment>
	);
}
