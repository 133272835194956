import React, {  useState } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/form";
import MeasureValue from "./measure";
import MeasureValueConversionProduct from "./measureValueConversion";
import { FavButton } from "../../../components/fav-button/favButton";

export default function MeasureTab() {
  const [update, setUpdate] = useState(false);

  /*useEffect(() => {
    async function firstLoad() {
      if (typeof data !== "undefined") {
        setUserTab(data.getUserTab);
        setUserPermissionTab(data.getUserPermissionTab);
      }
    }
    firstLoad();
  }, [loading]);*/
  const renderMeasureTab = () => {
    return <MeasureValue
              setUpdate={setUpdate}
              update={update}
           />;
  };

  const renderMeasureValueConversionTab = () => {
    return <MeasureValueConversionProduct
              update={update}
            />;
  };

  return (
    <React.Fragment>
      <FavButton/>
      {/* <h2 className="content-block">Unidade de Medida</h2> */}
      <TabPanel
        className={"tab-panel-margin"}
        animationEnabled={true}
        swipeEnabled={false}
      >
        <Item title="Cadastro" render={renderMeasureTab} />
        <Item title="Conversão" render={renderMeasureValueConversionTab} />
      </TabPanel>
    </React.Fragment>
  );
}
