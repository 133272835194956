import dayjs from "dayjs";

export const convertDate1900 = (parDate) =>{
    let datHour = dayjs(parDate).hour();
    let datMinute = dayjs(parDate).minute();
    let datSecond = dayjs(parDate).second();

    return dayjs("1900-01-01T00:00:00")
        .add(datHour, "hour")
        .add(datMinute, "minutes")
        .add(datSecond, "seconds")
        .format("YYYY-MM-DDTHH:mm:ss");
}