/* eslint-disable react-hooks/exhaustive-deps */
import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import React, { useEffect } from "react";
import { HashRouter as Router } from "react-router-dom";
import "./dx-styles.scss";
import LoadPanel from "devextreme-react/load-panel";
import { NavigationProvider } from "./contexts/navigation";
import { AuthProvider, useAuth } from "./contexts/auth";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import NotAuthenticatedContent from "./NotAuthenticatedContent";
import { DataLoaderProvider } from "./contexts/dataLoaderContext";
import { locale, loadMessages } from "devextreme/localization";
import ptMessages from "devextreme/localization/messages/pt.json";
import enMessages from "devextreme/localization/messages/en.json";
import service from "./localization/dictionary";
import { codeToString } from "./localization/languagesEnum";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./utils/theme";
import WithAxios from "./utils/WithAxios";
import PopupUpdadateDB from "./components/popupUpdateDB/popupUpdateDB";

import {
  LocalizationProvider,
  useLocalization,
} from "./contexts/localizationContext";
import { LoadingProvider } from "./contexts/loadingContext";
import { NotificationProvider } from "./contexts/notificationContext";

function App() {
  const {
    loading,
    isAuthenticated,
    valid,
    initialConfig,
    checkLocalStorage
  } = useAuth();
  const { LANGUAGE_KEY, setInitialLanguage } = useLocalization();

  useEffect(() => {

    checkLocalStorage()

    loadMessages(
      service.getDictionaryWithCustomLocalizations(
        initialConfig.customLocalization
      )
    );
    //loadMessages(enMessages);
    //loadMessages(ptMessages);
    setInitialLanguage(
      initialConfig.defaultLanguage === undefined
        ? window.language
        : codeToString[initialConfig.defaultLanguage]
    );
    //locale(localStorage.getItem(LANGUAGE_KEY));
    dayjs.locale(localStorage.getItem(LANGUAGE_KEY));
    dayjs.extend(localizedFormat);
  }, []);

  useEffect(() => {
    locale(localStorage.getItem(LANGUAGE_KEY));
  }, [localStorage.getItem(LANGUAGE_KEY)]);

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (localStorage.getItem("@es-mes/authToken") !== null) {
    if (isAuthenticated() && valid) {
      return (
        <Router>
          <Content />
          {/* <Redirect to={"/home"} /> */}
        </Router>
      );
    }
  }

  return <NotAuthenticatedContent />;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const screenSizeClass = useScreenSizeClass();
  loadMessages(enMessages);
  loadMessages(ptMessages);
  locale(localStorage.getItem("@es-mes/language"));

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <DataLoaderProvider>
            <NotificationProvider>
              <LoadingProvider>
                <WithAxios>
                  <LocalizationProvider>
                    <NavigationProvider>
                      <div className={`app ${screenSizeClass}`}>
                        <App />
                        <PopupUpdadateDB />
                      </div>
                    </NavigationProvider>
                  </LocalizationProvider>
                </WithAxios>
              </LoadingProvider>
            </NotificationProvider>
          </DataLoaderProvider>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
}
