/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "../../../components/common/style.scss";
import "./style.scss";
import "devextreme/data/odata/store";
import { LoadIndicator } from "devextreme-react/load-indicator";
import DataGrid, {
    Column,
    Pager,
    RequiredRule,
    Paging,
    FilterRow,
    Export,
    ColumnChooser,
    Grouping,
    GroupPanel,
    ColumnFixing,
    Selection,
    Lookup, Item, Toolbar
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { LogMessageGrid } from "../../../utils/logMessage"
import { formatMessage } from "devextreme/localization";
import { useLoading } from "../../../contexts/loadingContext";
import Button from "devextreme-react/button";

export default function OpcConnectionGroup(props) {
  const { loading } = useLoading();
  const [load, setLoad] = useState(false);
  const [lstOpcConnectionGroup, setLstOpcConnectionGroup] = useState([]);
  const [lstOpcConnection, setLstOpcConnection] = useState([]);
  const [lstOpcGroup, setLstOpcGroup] = useState([]);
  const _lstOpcConnection = useRef([]);

   //Autenticação requests
   const { unitCod } = useAuth();

    useEffect(() => {
        firstLoad();
    }, [])

    useEffect(() => {
        loadData();
        loadDataOpcConnection();
        loadDataOpcGroup();
    }, [load]);

  async function firstLoad(){
    if(unitCod !== undefined) {
      loadData();
      loadDataOpcConnection();
      loadDataOpcGroup();
      //refreshToken(getToken(), getRefreshTokenCode());
    }
  };

  async function loadData() {
    await api
      .get("/OPCConnectionGroup", { params: { unitCod: 0 } })
      .then((response) => {
        setLstOpcConnectionGroup(response.data);
      })
      .catch((error) => {
        return true;
      });
  };

  async function loadDataOpcConnection() {
    await api
      .get("/OPCConnection", { params: { unitCod: 0 } })
      .then((response) => {
        setLstOpcConnection(response.data);
      })
      .catch((error) => {
        return true;
      });
  };

  async function loadDataOpcGroup() {
    await api
      .get("/OPCGroup", { params: { unitCod: unitCod } })
      .then((response) => {
        setLstOpcGroup(response.data);
      })
      .catch((error) => {
        return true;
      });
  };

  async function updateRow(data, parLogMessage) {
    return await api
      .put("/OPCConnectionGroup", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(!load);
        return false;
      })
      .catch((error) => {
        return true;
      });
  };

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/OPCConnectionGroup",  { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(!load);
        return false;
      })
      .catch((error) => {
        return true;
    });
  };

  async function deleteRow(data) {
    return await api
      .delete("/OPCConnectionGroup",  { unitCod: unitCod, data: data })
      .then((response) => {
        setLoad(!load);
        return false;
      })
      .catch((error) => {
        return true;
      });
  };

  function textDisplayConnection(e) {
    if(e.NomOpcConexao1 && e.NomOpcConexao2) return `${e.NomOpcConexao1} - ${e.NomOpcConexao2}`;

    if(e.NomOpcConexao1 && !e.NomOpcConexao2) return `${e.NomOpcConexao1}`;
  }

  return (
    <React.Fragment>
        <div id="opc-connection-group">
            <DataGrid
                className={"dx-cards"}
                dataSource={lstOpcConnectionGroup}
                rowAlternationEnabled={true}
                showColumnLines={true}
                allowColumnReordering={true}
                showBorders={false}
                columnAutoWidth={false}
                columnHidingEnabled={true}
                repaintChangesOnly={true}
                headerFilter={{ visible: true }}
                filterPanel={{ visible: true }}
                filterRow={{ visible: false }}
                editing={{
                  refreshMode: "full",
                  mode:"batch",
                  allowUpdating: true,
                  allowDeleting: true,
                  allowAdding: false,
                }}
                onInitNewRow={(e) => {
                  e.data.TipHabilitaConexao = true;
                }}
                onRowUpdating={async (e) => {
                  const newObj = { ...e.oldData, ...e.newData };
                  let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _lstOpcConnection, newObj);

                  e.cancel = updateRow(newObj, strLogMessage);

                  e.component.deselectAll();
                }}
                onRowInserting={(e) => {
                  let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _lstOpcConnection, e.data);

                  e.cancel = insertRow(e.data, strLogMessage);

                  e.component.deselectAll();
                }}
                onRowRemoving={(e) => {
                  let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _lstOpcConnection, e.data)
                  const data = { unitCod: unitCod,  data: e.data, logMessage: strLogMessage };

                  e.cancel = deleteRow(data);

                  e.component.deselectAll();
                }}
                ref={_lstOpcConnection}
            >
                <Grouping
                    contextMenuEnabled={false}
                    expandMode="rowClick"
                    autoExpandAll={false}
                />
                <GroupPanel visible={false} />
                <Export
                  enabled={true}
                  fileName="Conexão Grupo"
                  allowExportSelectedData={true}
                />
                <ColumnChooser enabled={true} mode="select" />
                <ColumnFixing enabled={false} />
                <Selection mode="multiple" />
                <Paging defaultPageSize={20} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />
                <Toolbar >
                    <Item cssClass={'add-container'}>
                        <Button className={'add-button'}  icon={"add"} onClick={() => (_lstOpcConnection.current.instance.addRow())}/>
                    </Item>
                    <Item name="saveButton"/>
                    <Item name="revertButton"/>
                    <Item name="exportButton"/>
                    <Item name="columnChooserButton"/>
                </Toolbar>
                <Column
                  dataField={"CodOpcConexaoGrupo"}
                  caption={"Código"}
                  allowSorting={true}
                  sortIndex={0}
                  width={80}
                  allowEditing={false}
                >
                </Column>
                <Column
                  dataField={"CodOpcConexao"}
                  caption={"Conexão"}
                  allowSorting={true}
                  sortIndex={0}
                >
                  <Lookup
                    dataSource={lstOpcConnection}
                    displayExpr={(e) => textDisplayConnection(e)}
                    valueExpr="CodOpcConexao"
                  />
                  <RequiredRule />
                </Column>
                <Column
                  dataField={"CodOpcGrupo"}
                  caption={"Grupo"}
                  allowSorting={true}
                  sortIndex={0}
                >
                  <Lookup
                    dataSource={lstOpcGroup}
                    displayExpr="NomOpcGrupo"
                    valueExpr="CodOpcGrupo"
                  />
                  <RequiredRule />
                </Column>
            </DataGrid>

        </div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
      <div>
      </div>
    </React.Fragment>
  );
}
