/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "../style.scss";
import { SelectBox } from "devextreme-react";
import { formatMessage } from "devextreme/localization";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { Button } from "devextreme-react";
import { CheckBox } from "devextreme-react";
import { LogMessageCheckboxList } from "../../../utils/logMessage";
import { useLoading } from "../../../contexts/loadingContext";

export default function ProductSituation(props) {
  const { loading } = useLoading();
  const [load, setLoad] = useState(true);
  const [productChange, setProductChange] = useState();
  const [dataSourceProducts, setDataSourceProducts] = useState([]);
  const [dataSourceSituation, setDataSourceSituation] = useState([]);
  const [listSituation, setListSituation] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const [textButton, setTextButton] = useState();
  const [enabledButton, setEnabledButton] = useState(true);
  const _list = useRef();
  //Autenticação requests
  const { unitCod } = useAuth();

  useEffect(() => {
    firstLoad();
  }, []);

  useEffect(() => {
    if (load){
      loadDataSourceProductSituation();
    }
  }, [load]);

  useEffect(() => {
    loadDataSourceProducts();
  }, [props.update]);

  useEffect(() => {
    loadDataSourceProductSituation();
    !productChange ? setEnabledButton(true) : setEnabledButton(false)
  }, [productChange])

  useEffect(() => {
    if(dataSourceSituation.length > listSituation.length) {
      const newLstListSituation = dataSourceSituation.map((program) => {return program.CodTipo});
      setListSituation(newLstListSituation);
    }

    if(listSituation.length === dataSourceSituation.length) {
      setListSituation([]);
    }
  }, [selectedAll]);

  useEffect(() => {
    if(dataSourceSituation.length === listSituation.length) setTextButton("Desmarcar todos");
    if(dataSourceSituation.length > listSituation.length) setTextButton("Marcar todos");
  }, [listSituation, forceUpdate]);

  async function firstLoad(){
    if(unitCod !== undefined) {
      loadDataSourceProducts();
      loadDataSourceSituation();
      loadDataSourceProductSituation();
    }
  }

  async function loadDataSourceProducts(){
    await api
    .get("/product/get", { params: { unitCod: unitCod, tipAtivo: true } })
    .then((response) => {
      setDataSourceProducts(response.data);
      setLoad(false);
    })
    .catch((error) => {
      setLoad(true);

      return true;
    });
  }

  async function loadDataSourceSituation(){
    await api
    .get("/type/get", { params: { typeId: 35 } })
    .then((response) => {
      setDataSourceSituation(response.data);
      setLoad(false);
    })
    .catch((error) => {
      setLoad(true);

      return true;
    });
  }

  async function loadDataSourceProductSituation() {
    let situationProduct = [];
    _list.current = [];
    await api
      .get("/productsituation/get", { params: { ProductSituationId: productChange } })
      .then(response => {
        response.data.forEach((situation, index) => {
          situationProduct[index] = situation.CodTipo;
          _list.current[index] = situation.CodTipo;
        });

        setListSituation(situationProduct);

        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  const onSelectionChanged = (e) => {
    setProductChange(e.selectedItem !== null ? e.selectedItem.CodProduto : undefined);
  }

  async function insertRow(data , logMessage) {
    return await api
      .post("/productsituation", {
        unitCod: unitCod,
        data: data,
        logMessage: logMessage
      })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function deleteRow(data) {
    return await api
      .delete("/productsituation", { data: data })
      .then((response) => {
          if (response !== undefined && response !== false){
            setLoad(true);

            return false;
        }

        if (response === undefined || response === false){//Se der erro ao excluir, volta o que está no banco de dados
          loadDataSourceProductSituation()

          return false;
      }
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  function eventSaveClick (e) {
    let actualSelectionSituation = listSituation;
    let listToDelete = [];
    let listToSave = [];

    _list.current.filter(function (element) {
      if(actualSelectionSituation.indexOf(element) === -1) {
        const selectionSituation = {
          CodProduto: productChange,
          CodTipo: element,
          NomLogFirst: dataSourceProducts.filter(x => x.CodProduto === productChange)[0].NomProduto,
          NomLogSecond: dataSourceSituation.filter(x => x.CodTipo === element)[0].NomTipo
        };

        listToDelete.push(selectionSituation);
      }
    });

    actualSelectionSituation.filter(function (element) {
      if(_list.current.indexOf(element) === -1) {
        const selectionSituation = {
          CodProduto: productChange,
          CodTipo: element,
          NomLogFirst: dataSourceProducts.filter(x => x.CodProduto === productChange)[0].NomProduto,
          NomLogSecond: dataSourceSituation.filter(x => x.CodTipo === element)[0].NomTipo
        }

        listToSave.push(selectionSituation);
      }
    });

    listToSave.forEach((item) => {
      let logMessage = LogMessageCheckboxList(formatMessage("InsertedLog"), item);
      insertRow(item, logMessage);
    })

    listToDelete.forEach((item) => {
      let logMessage = LogMessageCheckboxList(formatMessage("DeletedLog"), item);
      const data = { unitCod: unitCod, data: { CodTipo: item.CodTipo, CodProduto: productChange }, logMessage: logMessage };

      deleteRow(data);
    })

  }

  function onValueChangedCheckBoxPermissions(e, codPermission) {
    let newLstListSituation = listSituation;

    if(e.value) {
      newLstListSituation.push(codPermission);
      setListSituation(newLstListSituation);
      setForceUpdate(!forceUpdate);
    } else {
      newLstListSituation = listSituation.filter(code => code !== codPermission);
      setListSituation(newLstListSituation);
      setForceUpdate(!forceUpdate);
    };
  };

  return (
    <React.Fragment>
      <div className="header-form">
        <div className="form-container">
          <div className={"margin-element"}>
              <SelectBox
                dataSource={dataSourceProducts}
                label={"Produto"}
                searchEnabled={true}
                onSelectionChanged={onSelectionChanged}
                displayExpr="NomProduto"
                valueExpr="CodProduto"
                width={250}
                stylingMode={"outlined"}
                labelMode={"floating"}
              />
            </div>
            <div className={"margin-element"}>
              <Button
                text={textButton}
                icon={!selectedAll ? "selectall" : "unselectall"}
                type="normal"
                className={"selectall-button"}
                useSubmitBehavior={false}
                stylingMode="contained"
                onClick={async (e) => {
                  setSelectedAll(!selectedAll);
                }}
                disabled={enabledButton}
              />
            </div>
            <div className={"margin-element"}>
              <Button
                className={"save-button"}
                id="searchFilterButton"
                icon="save"
                text="Salvar"
                type="default"
                useSubmitBehavior={false}
                stylingMode="contained"
                onClick={async (e) => {
                  eventSaveClick()
                }}
                disabled={enabledButton}
              />
              </div>
            <div>
          </div>
        </div>
      </div>
      <div>
        <div>
          {dataSourceSituation.map((situation) => {
            let valueCheckBox = false;

            listSituation.forEach((sit) => {
              if(sit === situation.CodTipo) valueCheckBox = true;
            })

            return(
                      <div style={{display: "inline-block", margin: "3% 1% 0% 2%", width: "200px"}} key={situation.CodTipo}>
                        <CheckBox
                          className={"checkbox-medium"}
                          value={valueCheckBox}
                          text={situation.NomTipo}
                          onValueChanged={(e) => onValueChangedCheckBoxPermissions(e, situation.CodTipo)}
                        />
                      </div>
                  )
          })}
        </div>
      </div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
