/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { Button } from "devextreme-react";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "../style.scss";
import { SelectBox } from "devextreme-react";
import { formatMessage } from "devextreme/localization";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { CheckBox } from "devextreme-react";
import { LogMessageCheckboxList } from "../../../utils/logMessage";
import { useLoading } from "../../../contexts/loadingContext";

export default function AnalysisPermission(props) {
  const { loading } = useLoading();
  const [load, setLoad] = useState(false);
  const [permission, setPermission] = useState([]);
  const [process, setProcess] = useState([]);
  const [analysisPermission, setAnalysisPermission] = useState([]);
  const [analysisGroup, setAnalysisGroup] = useState([]);
  const [permissionChange, setPermissionChange] = useState();
  const [enabledButton, setEnabledButton] = useState(true);
  let permissionName = useRef();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const [textButton, setTextButton] = useState();
  const _list = useRef([]);
  const { unitCod } = useAuth();

  useEffect(() => {
    async function firstLoad(){
      loadDataPermission();
      await loadDataProcess();
      loadDataAnalysisGroup();
    }
    firstLoad()

  }, [props.update])

  useEffect(() => {
    if (load){
      loadDataAnalysisPermission()
    }
  }, [load]);

  useEffect(() => {
    if(permissionChange > 0) {
      setEnabledButton(false)
      loadDataAnalysisGroup();
      loadDataAnalysisPermission();
    } 
    else {
      setEnabledButton(true)
      setAnalysisPermission([])
    }
  }, [permissionChange]);

  useEffect(() => {
    if(analysisGroup.length >analysisPermission.length) {
      const newLstVariablePermissions = analysisGroup.map((program) => {return program.CodAnaliseGrupo});
      setAnalysisPermission(newLstVariablePermissions);
    }

    if(analysisPermission.length === analysisGroup.length) {
      setAnalysisPermission([]);
    }
  }, [selectedAll]);

  useEffect(() => {
    if(analysisGroup.length === analysisPermission.length) setTextButton("Desmarcar todos");
    if(analysisGroup.length > analysisPermission.length) setTextButton("Marcar todos");
  }, [analysisPermission, forceUpdate]);

  async function loadDataProcess() {
    await api
      .get("/process", { params: { unitCod } })
      .then((response) => {
        setProcess(response.data);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function loadDataPermission() {
    await api
      .get("/permission", { params: { unitCod: unitCod } })
      .then((response) => {
        setPermission(response.data);
        setLoad(false);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true
      });
  }

  async function loadDataAnalysisGroup() {
    await api
      .get("/analysisGroup", { params: { unitCod: unitCod } })
      .then((response) => {
        setAnalysisGroup(response.data);
        setLoad(false);

      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function loadDataAnalysisPermission() {
    await api
      .get("/analysisPermission", {params: {codPermission: permissionChange}})
      .then((response) => {
        setAnalysisPermission(response.data.map(e => e.CodAnaliseGrupo));
        _list.current = response.data;

        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/analysisPermission", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(false);

        return true;
      });
  }

  async function deleteRow(data) {
    return await api
      .delete("/analysisPermission", { data: data })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  function onSelectionChangedPermission (e) {
    setPermissionChange(e.selectedItem ? e.selectedItem.CodPermissao : 0)
    permissionName.current = (e.selectedItem ? e.selectedItem.NomPermissao : 0)
  }

  function onValueChangedCheckBoxPermissions(e, codAnalysisGroup) {
    if(e.event) {
      
      if(e.value) {
        let newLstAnalysisPermission = [...analysisPermission];
        newLstAnalysisPermission.push(codAnalysisGroup);
        setAnalysisPermission(newLstAnalysisPermission);
      }
      else setAnalysisPermission( analysisPermission.filter(e => e !== codAnalysisGroup) )

      setForceUpdate(!forceUpdate);
    }
  };

  async function eventSaveClick() {
    const deleteAnalysisPermission = _list.current.filter(e => {
      const found = analysisPermission.find(codAnaliseGrupo => codAnaliseGrupo === e.CodAnaliseGrupo)

      if(found !== undefined) return null;

      e.NomLogFirst = permissionName.current
      e.NomLogSecond = analysisGroup.filter(x => x.CodAnaliseGrupo === e.CodAnaliseGrupo)[0].NomAnaliseGrupo;

      return e
    })

    const insertAnalysisPermission = []

    analysisPermission.forEach((codAnaliseGrupo) => {
      const found = _list.current.find(e => e.CodAnaliseGrupo === codAnaliseGrupo)

      if(found === undefined) {
        analysisGroup.forEach((analysis) => {
          if(analysis.CodAnaliseGrupo === codAnaliseGrupo) {
            const newAnalysisPermission = {
              CodPermissao: permissionChange,
              CodAnaliseGrupo: codAnaliseGrupo,
              NomLogFirst: permissionName.current,
              NomLogSecond: analysis.NomAnaliseGrupo
            }

            insertAnalysisPermission.push(newAnalysisPermission)
          }
        })
      }
    })

    deleteAnalysisPermission.forEach((item) => {
      let logMessage = LogMessageCheckboxList(formatMessage("DeletedLog"), item)
      const data = { unitCod: unitCod, data: item, logMessage: logMessage };

      deleteRow(data);
    })

    insertAnalysisPermission.forEach((item) => {
      let logMessage = LogMessageCheckboxList(formatMessage("InsertedLog"), item)
      insertRow(item, logMessage);
    })
  }

  return (
    <React.Fragment>
      <div className="header-form">
        <div className={"form-container"}>
          <div className={"margin-element"}>
              <SelectBox
                dataSource={permission}
                label={"Permissão"}
                searchEnabled={true}
                onSelectionChanged={onSelectionChangedPermission}
                displayExpr="NomPermissao"
                valueExpr="CodPermissao"
                width={250}
                stylingMode={"outlined"}
                labelMode={"floating"}
              />
          </div>
          <div className={"margin-element"}>
            <Button
              text={textButton}
              icon={!selectedAll ? "selectall" : "unselectall"}
              type="normal"
              className={"selectall-button"}
              stylingMode="contained"
              onClick={() => setSelectedAll(state => !state)}
              disabled={enabledButton}
            />
          </div>
          <div className={"margin-element"}>
            <Button
              className={"save-button"}
              id="searchFilterButton"
              icon="save"
              text="Salvar"
              type="default"
              useSubmitBehavior={false}
              stylingMode="contained"
              onClick={eventSaveClick}
              disabled={enabledButton}
            />
          </div>
        </div>
      </div>
      <div style={{paddingTop: '30px'}}>
        {process.map(thisProcess => {
          const variablesProcess = analysisGroup.filter(e => e.CodProcesso === thisProcess.CodProcesso)

          return variablesProcess.length > 0 ?
            <div key={thisProcess.CodProcesso}>
              <h3 className="title-permission">{thisProcess.NomProcesso}</h3>

              {variablesProcess.map(analysiGroup => 
                <div style={{display: "inline-block", margin: "3% 1% 0% 2%", width: "200px"}} key={analysiGroup.CodAnaliseGrupo}>
                  <CheckBox
                    className={"checkbox-medium"}
                    value={analysisPermission.some(e => e === analysiGroup.CodAnaliseGrupo)}
                    text={analysiGroup.NomAnaliseGrupo}
                    onValueChanged={e => onValueChangedCheckBoxPermissions(e, analysiGroup.CodAnaliseGrupo)}
                  />
                </div>
              )}
            </div>
          : '';
        })}
        </div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
