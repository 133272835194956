import { withNavigationWatcher } from './contexts/navigation';
import {
  HomePage,
  Configuration,
  Receipt,
  UserTab,
  VariablesTab,
  Tips,
  CompanyAndUnit,
  ProductTab,
  ProductStock,
  MeasureTab,
  Transport,
  Aplication,
  ProgramTab,
  LocalityTab,
  PermissionTab,
  Sample,
  PermissionLaboratory,
  ReasonTypeTab,
  AreaProcessStop,
  ProcessStop,
  AnalysisTab,
  CreateTask,
  ExecuteTask,
  VariableInputByDate,
  VariableInputByVariable,
  Shifts,
  Email,
  Goals,
  ConfigurationOPCTab,
  QuickGuidesTab,
  QuickGuidesList,
  Profile, 
  Indicators,
  MonitorationBlockTab,
  DailyReportTab,
  DashboardConfigurationTab
} from './pages';
import SectorProcessAreaTab from "./pages/administration/sectorProcessArea/sectorProcessAreaTab";
import CriticalPointReadingVariable from './pages/dataAcquisition/criticalPointReadingVariable/criticalPointReadindVariable';
import Equipment from './pages/registration/equipment';

const routes = [
  {
    path: '/home',
    component: HomePage,
    id: [undefined]
  },
  {
    path: '/receipt',
    component: Receipt,
    id: [4310]
  },
  {
    path: '/transport',
    component: Transport,
    id: [4220]
  },
  {
    path: '/user',
    component: UserTab,
    id: [9110]
  },
  {
    path: '/permission',
    component: PermissionTab,
    id: [9130, 9140, 9150, 9160, 9170]
  },
  {
    path: '/tip',
    component: Tips,
    id: [8240]
  },
  /*{
    path: '/dataacess',
    component: DataAcess,
    id: [8250]
  },*/
  {
    path: '/companyandunit',
    component: CompanyAndUnit,
    id: [8210]
  },
  {
    path: '/variablesTab',
    component: VariablesTab,
    id: [8310]
  },
  {
    path: '/product',
    component: ProductTab,
    id: [8110]
  },
  {
    path: '/productstock',
    component: ProductStock,
    id: [8118]
  }, 
  {
    path: '/measure',
    component: MeasureTab,
    id: [8114]
  },
  {
    path: '/aplication',
    component: Aplication,
    id: [8230]
  },
  {
    path: '/users',
    component: ProgramTab,
    id: [9210]
  },
  {
    path: '/sample',
    component: Sample,
    id: [2110]
  },
  {
    path: '/locality',
    component: LocalityTab,
    id: [8120]
  },
  {
    path: '/configuration',
    component: Configuration,
    id: [undefined]
  },
  {
    path: '/permissionLaboratory',
    component: PermissionLaboratory,
    id: [2220]
  }, 
  {
    path: '/reasonType',
    component: ReasonTypeTab,
    id: [1134]
  },
  {
    path: '/areaProcessStop',
    component: AreaProcessStop,
    id: [1132]
  },
  {
    path: '/processStop',
    component: ProcessStop,
    id: [1130]
  },
  {
    path: '/variableInputByDate',
    component: VariableInputByDate,
    id: [1110]
  },
  {
    path: '/variableInputByVariable',
    component: VariableInputByVariable,
    id: [1120]
  },
  /*{
    path: '/automaticVariables',
    component: AutomaticVariablesTab,
    id: 1220
  },
  {
    path: '/manualVariables',
    component: ManualVariablesTab,
    id: 1210
  },*/
  {
    path: '/analysis',
    component: AnalysisTab,
    id: [2210]
  },
  /*{
    path: '/productionGroup',
    component: ProductionGroup,
    id: 4230
  },
  {
    path: '/directInput',
    component: DirectInputTab,
    id: 4230
  },*/
  {
    path: '/createTask',
    component: CreateTask,
    id: [3210]
  },
  {
    path: '/executeTask',
    component: ExecuteTask,
    id: [3110]
  },
  {
    path: '/configurationOPC',
    component: ConfigurationOPCTab,
    id: [9310]
  },
  {
    path: '/email',
    component: Email,
    id: [4330]
  },
  {
    path: '/shifts',
    component: Shifts,
    id: [9320]
  },
  {
    path: '/goals',
    component: Goals,
    id: [8420]
  },
  {
    path: '/monitorationBlock',
    component: MonitorationBlockTab,
    id: [8260]
  },
  {
    path: '/indicators',
    component: Indicators,
    id: [9340]
  },
  {
    path: '/quickguides',
    component: QuickGuidesTab,
    id: [5110],
  },
  {
    path: '/sectorprocessarea',
    component: SectorProcessAreaTab,
    id: [8220],
  },
  {
    path: '/quickguideslist',
    component: QuickGuidesList,
    id: [5120],
  },
  {
    path: '/profile',
    component: Profile,
    id: [undefined],
  },
  {
    path: '/dailyreport',
    component: DailyReportTab,
    id: [8121],
  },
  {
    path: '/equipment',
    component: Equipment,
    id: [8510],
  },
  {
    path: '/dashboardConfiguration',
    component: DashboardConfigurationTab,
    id: [8610],
  },
  {
    path: '/approveCriticalPoint',
    component: CriticalPointReadingVariable,
    id: [1140],
  }
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});