/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, createContext, useContext, useCallback } from "react"
import notify from "devextreme/ui/notify"
import { api } from "../services/api"
import usePersistedState from "../hooks/usePersistedState"
import dayjs from "dayjs"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
//import { createCookie } from "../utils/createCookie";

const AuthContext = createContext({})
const useAuth = () => useContext(AuthContext)
const defaultUser = {
  email: "sandra@example.com",
  avatarUrl: "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png",
}
export const TOKEN_KEY = "@es-mes/authToken"
const REFRESH_TOKEN_KEY = "@es-mes/refreshtokencode"
export const INITIAL_CONFIG_KEY = "@es-mes/initialConfig"
const USER_KEY = "@es-mes/user"
const UNIT_KEY = "@es-mes/unit"
let refreshing = false
export const login = (data) => {
  localStorage.setItem(TOKEN_KEY, data.token)
  localStorage.setItem(REFRESH_TOKEN_KEY, data.refreshTokenCode)
}

export const getToken = () => localStorage.getItem(TOKEN_KEY)
export const getRefreshTokenCode = () => localStorage.getItem(REFRESH_TOKEN_KEY)

export const logout = (props) => {
  logLogout()

  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem(REFRESH_TOKEN_KEY)
  localStorage.removeItem(INITIAL_CONFIG_KEY)
  localStorage.removeItem(USER_KEY)
  localStorage.removeItem(UNIT_KEY)
  localStorage.removeItem("@es-mes/units")
  localStorage.removeItem("@es-mes/permissions")
  localStorage.removeItem("@es-mes/userCod")
  localStorage.removeItem("@es-mes/tipAdministrator")
  localStorage.removeItem("@es-mes/userLanguage")
  localStorage.removeItem("@es-mes/programPermissions")
  localStorage.removeItem("@es-mes/addressReports")
  sessionStorage.removeItem("@es-mes/blockLoopReload")
  //logLogout();
}

export const checkLogout = () => {
  if (
    !localStorage.getItem("@es-mes/authToken") &&
    !localStorage.getItem("@es-mes/refreshtokencode") &&
    !localStorage.getItem("@es-mes/user") &&
    !localStorage.getItem("@es-mes/unit") &&
    !localStorage.getItem("@es-mes/units") &&
    !localStorage.getItem("@es-mes/permissions") &&
    !localStorage.getItem("@es-mes/userCod") &&
    !localStorage.getItem("@es-mes/tipAdministrator") &&
    !localStorage.getItem("@es-mes/userLanguage") &&
    !localStorage.getItem("@es-mes/addressReports") &&
    !localStorage.getItem("@es-mes/programPermissions")
  ) {
    return true
  } else {
    return false
  }
}

function unitCod() {
  return JSON.parse(localStorage.getItem("@es-mes/units")) && JSON.parse(localStorage.getItem("@es-mes/units")).length > 0
    ? JSON.parse(localStorage.getItem("@es-mes/units"))[0].CodUnidadeEmpresa
    : 0
}

async function logLogout() {
  await api
    .get("/auth/logout", { params: { unitCod: unitCod() } })
    .then((response) => {})
    .catch((error) => {})
}

function AuthProvider(props) {
  const [user, setUser] = useState()
  const [unit, setUnit] = useState()
  const [getUnit, setgetUnit] = useState(JSON.parse(localStorage.getItem("@es-mes/units")))
  const [filterUnitCod, setFilterUnitCod] = useState(getUnit && getUnit.length > 0 ? getUnit.filter((item) => item.TipPadrao) : [])
  const [userCod, setUserCod] = useState(JSON.parse(localStorage.getItem("@es-mes/userCod")))
  const [tipAdministrator, setTipAdministrator] = useState(JSON.parse(localStorage.getItem("@es-mes/tipAdministrator")))
  const [unitCod, setUnitCod] = useState(getUnit && getUnit.length > 0 ? filterUnitCod[0].CodUnidadeEmpresa : null)
  const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem("@es-mes/permissions")))
  const [units, setUnits] = useState(JSON.parse(localStorage.getItem("@es-mes/units")))
  const [updateLogo, setUpdateLogo] = useState(false)
  const [valid, SetValid] = useState(true)
  const [loading, setLoading] = useState(false)
  const [expirationDate, setExpirationDate] = useState()
  const [initialConfig, setInitialConfig] = usePersistedState(INITIAL_CONFIG_KEY, "")
  const [openPopupTip, setOpenPopupTip] = useState(false)
  const [visiblePopupUpdateDB, setVisiblePopupUpdateDB] = useState(false)
  const [tips, setTips] = useState([])
  const [showTips, setShowTips] = useState([])
  const [loginCycle, setLoginCycle] = useState(false)
  //const [notificationsTime, setNotificationsTime] = useState(localStorage.getItem("es-mes/notificationsTime"));
  dayjs.extend(isSameOrBefore)

  async function getUser() {
    const loggedUser = await api
      .get("/auth/loggedUser")
      .then((response) => {
        return {
          isOk: true,
          data: response.data,
        }
      })
      .catch((error) => {
        if (!window.document.URL.includes("/login")) notify("Sessão expirada, faça login novamente!", "error", 2400)
        if (localStorage.getItem(TOKEN_KEY) !== null) logout()

        return {
          isOk: false,
          message: "Erro ao carregar usuario logado",
        }
      })
    return {
      isOk: loggedUser.isOk,
      data: loggedUser.data,
      message: loggedUser.message,
    }
  }

  async function userSets(result) {
    let dataUser = result.data.user

    if (!loginCycle) {
      setUserCod(dataUser.CodUsuario)
      setUser(dataUser)
      setUnit(units !== null ? units[0].NomUnidadeEmpresa : dataUser !== undefined ? user?.units[0].NomUnidadeEmpresa : "")
      setUnitCod(units !== null ? units[0].CodUnidadeEmpresa : dataUser.units[0].CodUnidadeEmpresa)
      setLoginCycle(false)
    }
  }

  function checkLocalStorage() {
    if (
      (!localStorage.getItem("@es-mes/authToken") ||
        !localStorage.getItem("@es-mes/refreshtokencode") ||
        !localStorage.getItem("@es-mes/initialConfig") ||
        !localStorage.getItem("@es-mes/user") ||
        !localStorage.getItem("@es-mes/units") ||
        !localStorage.getItem("@es-mes/language") ||
        !localStorage.getItem("@es-mes/permissions") ||
        !localStorage.getItem("@es-mes/userCod") ||
        !localStorage.getItem("@es-mes/tipAdministrator") ||
        !localStorage.getItem("@es-mes/userLanguage") ||
        !localStorage.getItem("@es-mes/addressReports") ||
        !localStorage.getItem("@es-mes/programPermissions")) &&
      !window.document.URL.includes("/login")
    ) {
      logOut()
    }
  }

  useEffect(() => {
    ;(async function () {
      const result = window.document.URL.includes("/login") ? { isOk: false } : await getUser()

      if (!result.isOk) loadInitialConfig()
      else {
        if (sessionStorage.getItem("@es-mes/blockLoopReload") !== "true" && localStorage.getItem("@es-mes/userLanguage") !== localStorage.getItem("@es-mes/language")) {
          localStorage.setItem("@es-mes/language", localStorage.getItem("@es-mes/userLanguage"))

          document.location.reload()

          sessionStorage.setItem("@es-mes/blockLoopReload", true)
        }

        await userSets(result)
        if (window.document.URL.includes("/home")) await loadDataTips()

        loadDataExpirationDate(unitCod)
      }

      setLoading(false)
    })()
  }, [TOKEN_KEY, getToken()])

  const isAuthenticated = () => {
    return localStorage.getItem("@es-mes/authToken") !== null
  }
  const getFormattedCustomLocalization = (data) => {
    if (data === undefined || data === null) return
    var newData = {}
    data.forEach((lang) => {
      var keys = Object.keys(lang)
      newData[keys[0]] = {}
      lang[keys[0]].forEach((obj) => {
        newData[keys[0]][obj.key] = obj.value
      })
    })

    return newData
  }

  async function loadInitialConfig() {
    setLoading(true)
    await api
      .get("/config/initialConfig")
      .then((response) => {
        response.data.customLocalization = getFormattedCustomLocalization(response.data.customLocalization)
        setInitialConfig(response.data)

        //localStorage.setItem(LANGUAGE_KEY, response.data.defaultLanguage);
      })
      .catch((error) => {
        setInitialConfig({
          defaultDate: {
            initialDateFirstDay: dayjs().startOf("month").format(),
            initialDate: dayjs().subtract(1, "hour").format(),
            finalDate: dayjs().format(),
          },
        })
      })
    setLoading(false)
  }

  async function validateToken(token) {
    let validAuth = false

    await api
      .get("auth/validatetoken", {
        params: {
          token: token,
        },
      })
      .then((response) => {
        SetValid(response.data.isValid)

        validAuth = response.data.isValid
      })
      .catch((error) => {})

    async function refreshTokens() {
      validAuth
        ? setTimeout(() => {
            refreshToken(getToken(), getRefreshTokenCode())
          }, 3000)
        : notify("Sessão expirada, faça login novamente!", "error", 2400)
    }

    await refreshTokens()

    return validAuth
  }

  async function unitCodesSet(response) {
    setLoginCycle(true)
    setUnit(response.data.user.units[0].NomUnidadeEmpresa)
    setUnitCod(response.data.user.units[0].CodUnidadeEmpresa)
  }

  async function userCodesSet(response) {
    setUser(response.data.user)
    setUserCod(response.data.user.CodUsuario)
  }

  /*async function loginSet (response){
    login(response.data);
    notify("Autenticado com sucesso!", "success", 3200);
  }*/

  async function loadDataExpirationDate(unitCod) {
    await api
      .get("/aplication/getExpirationDate", { params: { unitCod: unitCod } })
      .then((response) => {
        setExpirationDate(response.data)
      })
      .catch((error) => {})
  }

  /*async function loadDataNotificationTime(unitCod) {
    await api
      .get("/aplication/getValTempoNotificacaoUsuario", { params: { unitCod: unitCod } })
      .then((response) => {
        localStorage.setItem("@es-mes/notificationTime", response.data);
        setNotificationsTime(response.data);
      })
      .catch((error) => {
      });
  };*/

  async function localStorageSet(response) {
    localStorage.setItem(USER_KEY, response.data.user.name)
    localStorage.setItem("@es-mes/permissions", JSON.stringify(response.data.user.roles))
    localStorage.setItem("@es-mes/userCod", JSON.stringify(response.data.user.CodUsuario))
    localStorage.setItem("@es-mes/tipAdministrator", JSON.stringify(response.data.user.TipAdministrador))
    localStorage.setItem("@es-mes/units", JSON.stringify(response.data.user.units))
    localStorage.setItem("@es-mes/userLanguage", response.data.user.idioma)
    localStorage.setItem(TOKEN_KEY, response.data.token)
    localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refreshTokenCode)
    localStorage.setItem("@es-mes/programPermissions", JSON.stringify(response.data.user.permissions))
    localStorage.setItem("@es-mes/addressReports", response.data.user.strAddressReports)
    setTipAdministrator(response.data.user.TipAdministrador)
    setPermissions(response.data.user.roles)
  }

  const logIn = useCallback(async (user, password) => {
    await api
      .post("auth/login", {}, { auth : {
        username: user,
        password: password
      }})
      .then(async (response) => {
        await unitCodesSet(response)
        await userCodesSet(response)
        await localStorageSet(response)
        if (localStorage.getItem("@es-mes/authToken")) notify("Autenticado com sucesso!", "success", 3200)
      })
      .catch((error) => {
        if (error.response) {
          logout()
          console.log("erro:", error.response.data)
          notify(`Erro ao efetuar login! \n ${error.response.data.message}`, "error", 3200)
        } else if (error.request) {
          notify(`Erro ao efetuar login! \n ${error.request}`, "error", 3200)
        } else {
        }
      })

    SetValid(true)
  }, [])

  const refreshToken = useCallback(async (token, refreshTokenCode) => {
    //if(unitCod) await loadDataExpirationDate()
    setTimeout(() => {
      const data = {
        DesLastToken: token,
        DesRefreshTokenCode: refreshTokenCode,
        permissionProgram: JSON.parse(localStorage.getItem("@es-mes/programPermissions")),
      }
      //if(unitCod) loadDataExpirationDate(unitCod);
      if (!refreshing) {
        api
          .post("auth/refreshtoken", data)
          .then((response) => {
            login(response.data)
            refreshing = true
          })
          .catch((error) => {
            console.log("erro no Refreshtoken", error.response)
            if (error.response) {
              notify("Sessão expirada, faça login novamente!", "error", 2400)
            } else if (error.request) {
              notify(`Sessão expirada, faça login novamente! \n ${error.request}`, "error", 2400)
            } else {
              notify("Sessão expirada, faça login novamente!", "error", 2400)
            }
          })
        refreshing = false
      }
    }, 3000)
  }, [])

  async function loadDataTips() {
    await api
      .get(`/tip/?unitCod=${unitCod}`)
      .then((response) => {
        setTips(response.data)
      })
      .catch((error) => {
        return true
      })
  }

  useEffect(() => {
    filterTips()
  }, [tips])

  useEffect(() => {
    if (showTips.length > 0) setOpenPopupTip(true)
  }, [showTips])

  function filterTips() {
    if (tips !== undefined) {
      const filtredTips = tips.filter((tip) => {
        if (dayjs().isSameOrBefore(dayjs(tip.DatFinalDica))) return true
      })

      setShowTips(filtredTips)
    }
  }

  const logOut = useCallback(() => {
    if (checkLogout() === false) {
      logout()
    } else {
      logLogout()
    }
    SetValid(false)
    //createCookie("cookieName", -1);
    //loadDefaultLanguage();
    //loadInitialConfig();
  }, [])

  useEffect(() => {
    const initialConfKey = localStorage.getItem(INITIAL_CONFIG_KEY)
    if ((initialConfKey === '""' && valid === false) || initialConfKey === null)
      //loadInitialConfig();
      setUser(defaultUser)
  }, [valid])

  return (
    <AuthContext.Provider
      value={{
        logIn,
        logOut,
        loading,
        isAuthenticated,
        validateToken,
        refreshToken,
        valid,
        user,
        unit,
        setUnit,
        unitCod,
        userCod,
        initialConfig,
        getUser,
        setUser,
        openPopupTip,
        setOpenPopupTip,
        getToken,
        getRefreshTokenCode,
        setUnitCod,
        setPermissions,
        permissions,
        setTipAdministrator,
        tipAdministrator,
        visiblePopupUpdateDB,
        setVisiblePopupUpdateDB,
        expirationDate,
        loadDataExpirationDate,
        //loadDataNotificationTime,
        showTips,
        checkLocalStorage,
        updateLogo,
        setUpdateLogo,
        //setNotificationsTime,
        //notificationsTime
      }}
      {...props}
    ></AuthContext.Provider>
  )
}

export { AuthProvider, useAuth }
