import React from "react";
import "devextreme/data/odata/store";
import "../../components/common/style.scss";
import "./style.scss";
import Popup from 'devextreme-react/popup';
import { useScreenSize } from '../../utils/media-query';
import { Button } from "devextreme-react";

export default function PopupRepprovedTask(props) {
  const { isXSmall} = useScreenSize();

  return (
    <Popup
      className={"pop-up"}
      width={isXSmall ? "80%" : 450}
      height={isXSmall ? 120 : 100}
      visible={props.visiblePopupRepprovedTask}
      showTitle={false}
      //closeOnOutsideClick={true}
      //onHiding={(e) => setVisiblePopUp(false)}
      //showCloseButton={true}
    >
    <div className="ContainerInfo">
      <p>Deseja realmente reprovar a tarefa?</p>
      <div className="ContainerButtons">
        <Button
          text="SIM"
          type="default"
          stylingMode="text"
          onClick={(e) => {
            props.setResponsePopupRepprovedTask(true)
            props.setVisiblePopupRepprovedTask(false)
            props.setVisiblePopupReopenTask(true)
          }}
        />
        <Button
          text="NÃO"
          type="default"
          stylingMode="text"
          onClick={(e) => {
            props.setResponsePopupRepprovedTask(false)
            props.setVisiblePopupRepprovedTask(false)
            props.popupExecuteTask.component.showAppointmentPopup(props.popupExecuteTask.appointmentData, false, props.popupExecuteTask.appointmentData)
          }}
        />
      </div>
    </div>
    </Popup>
  )
}