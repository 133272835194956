import React from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import "../style.scss";
import Popup from 'devextreme-react/popup';
import { useScreenSize } from '../../../utils/media-query';
import { Button } from "devextreme-react";

export default function PopupCheckSample(props) {
  const { isXSmall} = useScreenSize();

  return (
    <Popup
      width={isXSmall ? "80%" : 450}
      height={isXSmall ? 120 : 120}
      visible={props.visibleCheckSample}
      showTitle={false}
    >
    <div>
      <p>Você tem registro(s) preenchido(s) que não foram salvos, deseja sair assim mesmo? Se sair, perderá todos os registro(s).</p>
      <div className="ContainerButtons">
        <Button
          text="SIM"
          type="default"
          stylingMode="text"
          onClick={(e) => {
            props.setResponsePopupCheckSample(true);
            props.setVisibleCheckSample(false);
          }}
        />
        <Button
          text="NÃO"
          type="default"
          stylingMode="text"
          onClick={(e) => {
            props.setResponsePopupCheckSample(false);
            props.setVisibleCheckSample(false);
          }}
        />
      </div>
    </div>
    </Popup>
  )
}