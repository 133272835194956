/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useRef} from "react";
import "devextreme/data/odata/store";
import "../../components/common/style.scss";
import {LoadIndicator} from "devextreme-react/load-indicator";
import "./style.scss";
import DataGrid, {
    Column,
    Pager,
    RequiredRule,
    StringLengthRule,
    Paging,
    FilterRow,
    Export,
    Selection,
    ColumnChooser,
    Grouping,
    Lookup,
    GroupPanel,
    ColumnFixing,
    CustomRule,
    Editing,
    Form,
    Popup,
    Position,
    Item, Toolbar,
} from "devextreme-react/data-grid";
import {api} from "../../services/api";
import {useAuth} from "../../contexts/auth";
import {LogMessageGrid} from "../../utils/logMessage";
import {formatMessage} from "devextreme/localization";
import {useLoading} from "../../contexts/loadingContext";
import {useScreenSize} from "./../../utils/media-query";
import notify from "devextreme/ui/notify";
import Button from "devextreme-react/button";

export default function Program(props) {
    const {loading} = useLoading();
    const [load, setLoad] = useState(false);
    const {isLarge} = useScreenSize();
    const [program, setProgram] = useState([]);
    const [groupProgram, setGroupProgram] = useState([]);
    const _program = useRef();

    //Autenticação requests
    const {unitCod} = useAuth();

    useEffect(() => {
        awaitRequests();
    }, [props.update]);

    async function awaitRequests() {
        loadData();
        getGroupProgram();
    }

    useEffect(() => {
        if (load) {
            awaitRequests();
        }
    }, [load]);

    async function loadData() {
        await api
            .get("/program", {params: {unitCod: unitCod}})
            .then((response) => {
                setProgram(response.data);
                setLoad(false);
            })
            .catch((error) => {
                setLoad(true);
                console.log(error);

                return true;
            });
    }

    async function getGroupProgram() {
        await api
            .get("/groupProgram")
            .then((response) => {
                setGroupProgram(response.data);
                setLoad(false);
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function updateRow(data, parLogMessage) {
        return await api
            .put("/program", {unitCod: unitCod, data: data, logMessage: parLogMessage})
            .then((response) => {
                setLoad(true);

                return false;
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function insertRow(data, parLogMessage) {
        return await api
            .post("/program", {unitCod: unitCod, data: data, logMessage: parLogMessage})
            .then((response) => {
                setLoad(true);

                return false;
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function deleteRow(data) {
        return await api
            .delete("/program", {unitCod: unitCod, data: data})
            .then((response) => {
                setLoad(true);

                return false;
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    /*const onToolbarPreparing = (e) => {
      var dataGrid = e.component;

      e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
              elementAttr: {id: 'ToolbarAddNewButton'},
              hint: "Adicionar um registro",
              icon: "add",
              onClick: () => { dataGrid.addRow() }
          },
      });
  }*/

    function checkUniqueKey(params) {
        let check = true;

        program.forEach((unit) => {
            if (unit.CodPrograma !== params.data.CodPrograma && unit.NomPrograma === params.data.NomPrograma) check = false;
        });

        return check;
    }

    const checkGroup = (program) => {
        let group = groupProgram.find(e => e.CodProgramaGrupo === program.CodProgramaGrupo)
        return !(group.TipLiberarParaCliente === false && program.TipLiberarParaCliente === true);

    }

    return (
        <React.Fragment>
            <div id="programGrid">
                <DataGrid
                    className={"dx-cards"}
                    dataSource={program}
                    rowAlternationEnabled={true}
                    showColumnLines={false}
                    allowColumnReordering={true}
                    showBorders={false}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    repaintChangesOnly={false}
                    onInitNewRow={(e) => {
                        e.data.TipAlterarDados = true;
                        e.data.TipExcluirDados = true;
                        e.data.TipInserirDados = true;
                        e.data.TipLiberarParaCliente = true;
                    }}
                    headerFilter={{visible: true}}
                    filterPanel={{visible: true}}
                    filterRow={{visible: false}}
                    /* editing={{
            refreshMode: "full",
            mode:"batch",
            allowUpdating: true,
            allowDeleting: true,
            allowAdding: false,
          }} */
                    onRowUpdating={async (e) => {
                        const newObj = {...e.oldData, ...e.newData};
                        let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _program, newObj);

                        if (checkGroup(newObj)) {
                            e.cancel = updateRow(newObj, strLogMessage);
                        } else {
                            notify("Grupo não liberado", "error", 2500)
                            e.cancel = true;
                        }

                        e.component.deselectAll();
                    }}
                    onRowInserting={(e) => {
                        e.data.CodSistema = 4;
                        let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _program, e.data);

                        e.cancel = insertRow(e.data, strLogMessage);

                        e.component.deselectAll();
                    }}
                    onRowRemoving={(e) => {
                        let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _program, e.data);
                        const data = {unitCod: unitCod, data: e.data, logMessage: strLogMessage};

                        e.cancel = deleteRow(data);

                        e.component.deselectAll();
                    }}
                    ref={_program}>
                    <Editing mode={isLarge ? "batch" : "popup"} allowUpdating={true} allowDeleting={true}
                             allowAdding={false}>
                        <Popup
                            title="Cadastro de Programa"
                            showTitle={true}
                            width={700}
                            height={750}
                            fullScreen={true}>
                            <Position my="center" at="center" of={window}/>
                        </Popup>
                        <Form>
                            <Item itemType="group" colCount={4} colSpan={2}>
                                <Item colSpan={2} dataField="CodPrograma"/>
                                <Item colSpan={2} dataField="NomArquivo"/>
                                <Item colSpan={2} dataField="NomPrograma"/>
                                <Item colSpan={2} dataField="TipAlterarDados"/>
                                <Item colSpan={2} dataField="TipExcluirDados"/>
                                <Item colSpan={2} dataField="TipInserirDados"/>
                                <Item colSpan={1} dataField="TipLiberarParaCliente"/>
                                <Item colSpan={1} dataField="ValNivelPrograma"/>
                                <Item colSpan={1} dataField="ValTag"/>
                                <Item colSpan={1} dataField="CodProgramaGrupo"/>
                            </Item>
                        </Form>
                    </Editing>
                    <Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false}/>
                    <GroupPanel visible={false}/>
                    <Export enabled={true} fileName="Cadastro_Area" allowExportSelectedData={true}/>
                    <ColumnChooser enabled={true} mode="select"/>
                    <ColumnFixing enabled={false}/>
                    <Selection mode="multiple"/>
                    <Paging defaultPageSize={20}/>
                    <Pager showPageSizeSelector={true} showInfo={true}/>
                    <FilterRow visible={true}/>
                    <Toolbar>
                        <Item cssClass={'add-container'}>
                            <Button className={'add-button'} icon={"add"}
                                    onClick={() => (_program.current.instance.addRow())}/>
                        </Item>
                        <Item name="saveButton"/>
                        <Item name="revertButton"/>
                        <Item name="exportButton"/>
                        <Item name="columnChooserButton"/>
                    </Toolbar>
                    <Column dataField={"CodPrograma"} caption={"Código"} allowSorting={true} width={80}
                            allowEditing={true}>
                        <RequiredRule/>
                    </Column>
                    <Column dataField={"NomArquivo"} caption={"Nome Arquivo"} allowSorting={true} width={"auto"}>
                        <RequiredRule/>
                    </Column>
                    <Column dataField={"NomPrograma"} caption={"Nome Programa"} allowSorting={true} width={"auto"}>
                        <CustomRule message={"Este campo deve ser único"} reevaluate={true} type={"custom"}
                                    validationCallback={checkUniqueKey}/>
                        <RequiredRule/>
                    </Column>
                    <Column dataField={"TipAlterarDados"} caption={"Alterar"} dataType={"boolean"} allowSorting={true}
                            width={"auto"}></Column>
                    <Column dataField={"TipExcluirDados"} caption={"Excluir"} dataType={"boolean"} allowSorting={true}
                            width={"auto"}></Column>
                    <Column dataField={"TipInserirDados"} caption={"Inserir"} dataType={"boolean"} allowSorting={true}
                            width={"auto"}></Column>
                    <Column
                        dataField={"TipLiberarParaCliente"}
                        caption={"Liberar Cliente"}
                        dataType={"boolean"}
                        allowSorting={true}
                        width={"auto"}></Column>
                    <Column dataField={"ValNivelPrograma"} caption={"Nível"} dataType={"number"} allowSorting={true}
                            width={"auto"}>
                        <RequiredRule/>
                        <StringLengthRule message="Pode possuir no máximo três caracteres." max={3}/>
                    </Column>
                    <Column dataField={"ValTag"} caption={"Valor Tag"} dataType={"number"} allowSorting={true}
                            width={"auto"}>
                        <RequiredRule/>
                    </Column>
                    <Column dataField={"CodProgramaGrupo"} caption={"Grupo"} allowSorting={true} sortIndex={0}
                            width={"auto"}>
                        <Lookup dataSource={groupProgram} displayExpr={"NomProgramaGrupo"}
                                valueExpr={"CodProgramaGrupo"}/>
                    </Column>
                </DataGrid>
            </div>
            <div className="load-indicator">
                <LoadIndicator visible={loading} height={40} width={40}/>
            </div>
        </React.Fragment>
    );
}
