/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react"
import "devextreme/data/odata/store"
import "../../../components/common/style.scss"
import { Button } from "devextreme-react"
import { api } from "../../../services/api"
import { useAuth } from "../../../contexts/auth"
import NumericInputVariable from "./numericInputVariable"
import { useDataLoader } from "../../../contexts/dataLoaderContext"
import dayjs from "dayjs"
import { useScreenSize } from "../../../utils/media-query"
import _ from "lodash"

export default function ButtonListSecondary(props) {
  const [load, setLoad] = useState()
  const { unitCod } = useAuth()
  const [lstVariable, setLstVariable] = useState([])
  const { _lstInputVariableSave, setSaveHeaderTextBox, saveHeaderTextBox } = useDataLoader()
  const [firstLoad, setFirstLoad] = useState(false)
  const [reLoad, setReLoad] = useState()
  const { isMedium, isLarge, isXSmall, isSmall } = useScreenSize()
  let _lstVariable = useRef([])
  let _lstVariableInput = useRef([])
  let _variableCode = useRef()
  let _booBlockVariables = useRef([])

  //#region useEffect
  useEffect(() => {
    setFirstLoad(true)
    setSaveHeaderTextBox(false)
    getManualVariableList()
    props.setCurrencyValueDate(dayjs().valueOf())
  }, [])

  // useEffect(() => {
  //   function loadList() {
  //     // setLoad(false)
  //     _lstVariable.current = props.variableCodeFilter > 0 ? lstVariable.filter(x => x.CodVariavelLeitura === props.variableCodeFilter) : lstVariable
  //     // setLoad(true)
  //   }
  //   loadList()
  // }, [props.variableCodeFilter])

  useEffect(() => {
    setLstVariable(!props.searchVariablesText ? _lstVariable.current : _lstVariable.current.filter(lstVariables => lstVariables.NomVariavelLeitura.toUpperCase().indexOf(props.searchVariablesText.toUpperCase()) > -1))
  }, [props.searchVariablesText]);
  //#endregion

  //#region events
  async function loadVariableInputs(parVariableCode) {
    _lstInputVariableSave.current = []
    _variableCode.current = parVariableCode
    props.setScreen("input")
    await getVariableInputs(unitCod, parVariableCode, dayjs(props.refHeaderDateBoxValue.instance.option("value")).format("YYYY-MM-DD"))
  }
  //#endregion

  //#region Requests
  async function getManualVariableList() {
    await api
      .get("/readingvariable/getManualVariablesByProcess", { params: { unitCod: unitCod, codProcess: props.processCode } })
      .then(response => {
        try {
          _lstVariable.current = response.data
          setLstVariable(response.data)
          props.setLstVariables(response.data)
        } finally {
          setLoad(true)
        }
      })
      .catch(() => {
        return true
      })
  }

  async function getVariableInputs(parUnitCod, parReadingVariableCode, parDateBoxValue) {
    await api
      .get("/readingvariablemanual/GetVariableInputs", { params: { parUnitCod: parUnitCod, parReadingVariableCode: parReadingVariableCode, parDateBoxValue: parDateBoxValue } })
      .then(response => {
        try {
          //[0] = Dados fixos da tabela Leitura Manual
          //[1] = Dia e hora dos inputs (Date, Value, TipWorking)
          _lstVariableInput.current = response.data
          _booBlockVariables.current = response.data[0].BlockVariableInput

          let nextUpdate = dayjs(response.data[0].NextUpdate).format("YYYY-MM-DD")

          setReLoad(true)
          if (firstLoad && _booBlockVariables.current) {
            if (saveHeaderTextBox && !response.data[0].NextUpdateExists) props.setCurrencyValueDate(dayjs(nextUpdate).valueOf())
            if (response.data[0].SetRealNextUpdate) props.setCurrencyValueDate(dayjs(response.data[0].RealNextUpdate).valueOf())

            setSaveHeaderTextBox(true)
            setFirstLoad(false)
          }
        } finally {
          setLoad(false)
        }
      })
      .catch(() => {
        return true
      })
  }
  //#endregion

  return (
    <div>
      {load ? (
        <div>
          <h2>{`Processo: ${props.processName}`}</h2>
          <div className="dx-field-label sample-subtitle">Escolha a Variável</div>
          {_.orderBy(lstVariable, "NumOrdem").map((value, i) => (
            <Button
              key={i}
              className={"variable-button"}
              render={() => (
                <div style={{ width: "100%", margin: isXSmall ? 0 : 7, padding: 0 }}>
                  <p style={{ overflowy: "hidden", fontSize: isLarge ? 13 : isMedium ? 12 : 8}} className={"dx-button-text"}>
                  {`${value.NomVariavelLeitura} `} 
                  </p>
                  <p style={{ fontSize: isLarge ? 12 : isMedium ? 10 : 8 , display: isXSmall ? "inherit" : "inline-block", verticalAlign: "middle"}} >
                   {isXSmall ? `${value.DesVariavelLeitura}` : `  | ${value.DesVariavelLeitura}` } 
                  </p>
                </div>
              )}
              //text={props.screen === "variable" ? `${value.NomVariavelLeitura} - ${value.DesVariavelLeitura}` : ""}
              stylingMode="outlined"
              searchEnabled={false}
              onClick={() => loadVariableInputs(value.CodVariavelLeitura)}
            />
          ))}
        </div>
      ) : reLoad ? (
        <NumericInputVariable
          lstVariable={_lstVariableInput.current}
          processName = {props.processName}
          VariableCode={_variableCode.current}
          refHeaderSelectBox={props.refHeaderSelectBox}
          refHeaderDateBoxValue={props.refHeaderDateBoxValue}
          setReLoad={setReLoad}
          getVariableInputs={getVariableInputs}
          loadVariableInputs={loadVariableInputs}
          currencyValueDate={props.currencyValueDate}
          booBlockVariables={_booBlockVariables.current}
        />
      ) : (
        ""
      )}
    </div>
  )
}
