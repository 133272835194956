import React, { useState } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/form";
import Country from "./country";
import State from "./state";
import City from "./city";
import { useLoading } from "../../../contexts/loadingContext";
import { FavButton } from "../../../components/fav-button/favButton";

export default function LocalityTab() {
  const [update, setUpdate] = useState(false);

  const renderCountries = () => {
    return  <Country
              update={update}
              setUpdate={setUpdate}
            />;
  };

  const renderState = () => {
    return  <State
              update={update}
              setUpdate={setUpdate}
            />;
  };

  const renderCity = () => {
    return  <City
              update={update}
              setUpdate={setUpdate}
            />;
  };

  return (
    <React.Fragment>
      <FavButton/>
      {/* <h2 className="content-block">Localidade</h2> */}
      <TabPanel
        className={"tab-panel-margin"}
        swipeEnabled={false}
      >
        <Item title="País" render={renderCountries} />
        <Item title="Estados" render={renderState} />
        <Item title="Cidades" render={renderCity} />
      </TabPanel>
    </React.Fragment>
  );
}
