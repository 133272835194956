import React from "react";
import "devextreme/data/odata/store";
import "../common/style.scss";
import "./style.scss";
import chevronDoubleDown from "../../icons/chevronDoubleDown.svg";
import chevronDoubleUp from "../../icons/chevronDoubleUp.svg";
import chevronSingleDown from "../../icons/chevronSingleDown.svg";
import chevronSingleUp from "../../icons/chevronSingleUp.svg";
import sucessGreen from "../../icons/sucessGreen.svg"

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {

	return (
		<div style={{float: "right", marginTop: '15vh'}}>
			<div className={"fl-ml"}>
				<div className={"img-legend double"}>
					<img alt="HiHi" src={chevronDoubleUp}/>
				</div>
				<div className={"variable-legend"}>
					<div className={"title-legend"}>Muito Alto</div>
					<label id={"legendHiHi"} className={"dx-field-label value-legend"}> {props.paramsVariable.HiHi}</label>
				</div>
			</div>

			<div className={"fl-ml"}>
				<div className={"img-legend simple"}>
					<img alt="Hi" src={chevronSingleUp}/>
				</div>
				<div className={"variable-legend"}>
					<div className={"title-legend"}>Alto</div>
					<label id={"legendHi"} className={"dx-field-label value-legend"}>{props.paramsVariable.Hi}</label>
				</div>
			</div>

			<div className={"fl-ml"}>
				<div className={"img-legend ideal"}>
					<img alt="Ideal" src={sucessGreen}/>
				</div>
				<div className={"variable-legend"}>
					<div className={"title-legend"}>Ideal</div>
					<label id={"legendIdeal"} className={"dx-field-label value-legend"}>{props.paramsVariable.Ideal}</label>
				</div>
			</div>

			<div className={"fl-ml"}>
				<div className={"img-legend simple"}>
					<img alt="Lo" src={chevronSingleDown}/>
				</div>
				<div className={"variable-legend"}>
					<div className={"title-legend"}>Baixo</div>
					<label id={"legendLo"} className={"dx-field-label value-legend"}>{props.paramsVariable.Lo}</label>
				</div>
			</div>

			<div className={"fl-ml"}>
				<div className={"img-legend double"}>
					<img alt="Ideal" src={chevronDoubleDown}/>
				</div>
				<div className={"variable-legend"}>
					<div className={"title-legend"}>Muito Baixo</div>
					<label id={"legendLoLo"} className={"dx-field-label value-legend"}>{props.paramsVariable.LoLo}</label>
				</div>
			</div>
		</div>
	);
}
