/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "./style.scss";
import DataGrid, {
  Column,
  Pager,
  RequiredRule,
  Paging,
  FilterRow,
  Export,
  Selection,
  ColumnChooser,
  Grouping,
  GroupPanel,
  ColumnFixing,
  Lookup,
  CustomRule,
  Popup,
  Form,
  Editing,
  Position,
  RangeRule, 
  Toolbar,
  Button
} from "devextreme-react/data-grid";
import { Item } from 'devextreme-react/form';
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { useScreenSize } from '../../../utils/media-query';
import { LogMessageGrid } from "../../../utils/logMessage"
import { Tooltip } from 'devextreme-react/tooltip';
import { formatMessage } from "devextreme/localization";
import { useLoading } from "../../../contexts/loadingContext";
import Buttons from "devextreme-react/button";

export default function VariableRegister() {
  const { loading } = useLoading();
  const { refreshToken, getRefreshTokenCode, getToken } = useAuth();
  const [load, setLoad] = useState(false);
  const [areaProcess, setAreaProcess] = useState([]);
  const [readingVariable, setReadingVariable] = useState([]);
  const [disabledDeleteGrid, setDisabledDeleteGrid] = useState(true);
  const [disabledAddDelButton, setDisabledAddDelButton] = useState(false);
  const severity = [{NomSeveridade: "Alto", TipSeveridade: 0}, {NomSeveridade: "Médio", TipSeveridade: 1}, {NomSeveridade: "Baixo", TipSeveridade: 2}]
  const _configuration = useRef();
  const duplicate = useRef({});
  const { isLarge } = useScreenSize();

  //Autenticação requests
  const { unitCod, userCod } = useAuth();
  const numberBoxOptions = { showSpinButtons: true, min: 0};
  const TipVariavelLeituraSelect = [
    {'TipVariavelLeitura': 1, 'Nome':'Bit'},
    {'TipVariavelLeitura': 2, 'Nome':'Numérico'},
    {'TipVariavelLeitura': 3, 'Nome':'AlfaNumérico'},
    {'TipVariavelLeitura': 4, 'Nome':'Data'}
  ];

  useEffect(() => {
    async function firstLoad(){
      const data = await loadData();
      loadDataAction(831);
      await loadDataAreaProcess();
      setReadingVariable(data);
      refreshToken(getToken(), getRefreshTokenCode());
    }
    firstLoad();
   }, [])

  useEffect(() => {
    async function load(){
      setReadingVariable(await loadData());
    }
    if (load) load();
  }, [load]);

  //#region Requests
  async function loadData() {
    return await api
      .get("/readingVariable", { params: { unitCod: unitCod } })
      .then((response) => {
        setLoad(false);
        return response.data;
      })
      .catch(() => {
        setLoad(true);
      });
  }

  async function loadDataAction(actionCod) {
    await api
      .get("/actionPermission/getActionsByUser", { params: { userCod: userCod, actionCod: actionCod } })
      .then((response) => {
        if(response.data) {
          setDisabledDeleteGrid(false);
          setDisabledAddDelButton(true);
        }
        setLoad(false);
        return false;
      })
      .catch(() => {
        setLoad(true);

        return true;
      });
  }

  async function loadDataAreaProcess() {
    await api
      .get("/areaprocess", { params: { unitCod: unitCod } })
      .then((response) => {
        setAreaProcess(response.data);
        setLoad(false);
      })
      .catch(() => {
        setLoad(true);

        return true;
      });
  }

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/readingVariable",  { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then(() => {
        setLoad(true);

        return false;
      })
      .catch(() => {
        setLoad(false);

        return true;
      });
  }

  async function updateRow(data, parLogMessage) {
    return await api
      .put("/readingVariable",  { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then(() => {
        setLoad(true);

        return false;
      })
      .catch(() => {
        setLoad(true);

        return true;
      });
  }

  async function deleteRow(data) {
    return await api
      .delete("/readingVariable", { data: data })
      .then(() => {
        setLoad(true);

        return false;
      })
      .catch(() => {
        setLoad(false);

        return true;
      });
  }
  //#endregion

  //#region functions

  function checkVeryLowValue(params) {
    let check = true;

    if(params.data.TipRangeInvertido === false && params.data.ValMuitoBaixo > 0 && params.data.ValBaixo > 0)
      params.data.ValMuitoBaixo > params.data.ValBaixo ? check = false : check = true;

    return check;
  }

  function checkVeryLowValueInvertedRange(params) {
    let check = true;

    if(params.data.TipRangeInvertido === true && params.data.ValMuitoBaixo > 0 && params.data.ValBaixo > 0)
      params.data.ValBaixo > params.data.ValMuitoBaixo ? check = false : check = true;

    return check;
  }

  function checkIdealValue(params) {
    let check = true;

    if((params.data.TipRangeInvertido === false) && (params.data.ValIdeal > 0) &&
      (params.data.ValIdeal > params.data.ValMuitoAlto ||
      params.data.ValIdeal > params.data.ValAlto ||
      params.data.ValIdeal < params.data.ValBaixo ||
      params.data.ValIdeal < params.data.ValMuitoBaixo)
    ) {
      check = false;
    }

    return check;
  }

  function checkIdealValueInvertedRange(params) {
    let check = true;
    let valMuitoBaixo = params.data.ValMuitoBaixo * -1;
    let valBaixo = params.data.ValBaixo * -1;
    let valIdeal = params.data.ValIdeal * -1;
    let valAlto = params.data.ValAlto * -1;
    let valMuitoAlto = params.data.ValMuitoAlto * -1;

    if((params.data.TipRangeInvertido === true) && (valIdeal < 0) &&
      (valIdeal > valMuitoAlto ||
      valIdeal > valAlto ||
      valIdeal < valBaixo ||
      valIdeal < valMuitoBaixo)
    ) {
      check = false;
    }

    return check;
  }

  function checkHighValue(params) {
    let check = true;

    if((params.data.TipRangeInvertido === false) && (params.data.ValAlto > 0) &&
      (params.data.ValAlto > params.data.ValMuitoAlto ||
      params.data.ValAlto < params.data.ValMuitoBaixo ||
      params.data.ValAlto < params.data.ValBaixo)
    ) {
      check = false;
    }

    return check;
  }

  function checkHighValueInvertedRange(params) {
    let check = true;
    let valMuitoBaixo = params.data.ValMuitoBaixo * -1;
    let valBaixo = params.data.ValBaixo * -1;
    let valAlto = params.data.ValAlto * -1;
    let valMuitoAlto = params.data.ValMuitoAlto * -1;

    if((params.data.TipRangeInvertido === true) && (valAlto < 0) &&
      (valAlto > valMuitoAlto ||
      valAlto < valMuitoBaixo ||
      valAlto < valBaixo)
    ) {
      check = false;
    }

    return check;
  }

  function checkVeryHighValue(params) {
    let check = true;

    if((params.data.TipRangeInvertido === false) && (params.data.ValMuitoAlto > 0) &&
      (params.data.ValMuitoAlto < params.data.ValAlto ||
      params.data.ValMuitoAlto < params.data.ValIdeal ||
      params.data.ValMuitoAlto < params.data.ValBaixo ||
      params.data.ValMuitoAlto < params.data.ValMuitoBaixo)
    ) {
      check = false;
    }

    return check;
  }

  function checkVeryHighValueInvertedRange(params) {
    let check = true;
    let valMuitoBaixo = params.data.ValMuitoBaixo * -1;
    let valBaixo = params.data.ValBaixo * -1;
    let valIdeal = params.data.ValIdeal * -1;
    let valAlto = params.data.ValAlto * -1;
    let valMuitoAlto = params.data.ValMuitoAlto * -1;

    if((params.data.TipRangeInvertido === true) && (valMuitoAlto < 0) &&
      (valMuitoAlto < valAlto ||
      valMuitoAlto < valIdeal ||
      valMuitoAlto < valBaixo ||
      valMuitoAlto < valMuitoBaixo)
    ) {
      check = false;
    }

    return check;
  }

  function setCellValue(newData, value, currentRowData) {
    newData.TipHabilitaAlarme = value;
    newData.TipReconheceAutomatico = value;
    newData.TipDescartaAlarme = value;
    newData.TipSeveridade = 1;
    newData.TipAlarmeDigital = false;
    newData.ValDigitalReferencia = false;

    if(newData.TipHabilitaAlarme === false) {
      newData.TipReconheceAutomatico = 0;
      newData.TipDescartaAlarme = 0;
      newData.TipSeveridade = 2;
      newData.TipAlarmeDigital = 0;
      newData.ValDigitalReferencia = 0;
    }

    if(newData.TipHabilitaAlarme === true && newData.TipDescartaAlarme === true && currentRowData.ValPeriodoDescarte === 0) {
      newData.ValPeriodoDescarte = 24;
    } else {
      newData.ValPeriodoDescarte = 0;
    }

    let column = this;
    column.defaultSetCellValue(newData, value);
  }

  function setCellValueAlarmDiscard(newData, value, currentRowData) {
    newData.TipDescartaAlarme = value;
    newData.ValPeriodoDescarte = (currentRowData.TipHabilitaAlarme && newData.TipDescartaAlarme && currentRowData.ValPeriodoDescarte === 0) ? 24 : 0;

    let column = this;
    column.defaultSetCellValue(newData, value);
  }

  function setCellValueDigitalAlarm(newData, value, currentRowData) {
    newData.TipAlarmeDigital = value;
    newData.ValDigitalReferencia = (currentRowData.TipHabilitaAlarme && newData.TipAlarmeDigital) ? true : false;

    let column = this;
    column.defaultSetCellValue(newData, value);
  }

  function setCellValueTipVariavelLeitura(newData, value, currentRowData) {
    newData.TipVariavelLeitura = value;

    if(value !== 2)
    {
      newData.ValMuitoBaixo = 0;
      newData.ValBaixo = 0;
      newData.ValAlto = 0;
      newData.ValMuitoAlto = 0;
      newData.ValIdeal = 0;
      newData.NumCasasDecimais = 0;
    }

    let column = this;
    column.defaultSetCellValue(newData, value);
  }

  function onEditorPreparing(e) {
    if(e.parentType !== "dataRow") return;
    
    if (e.dataField === "NomVariavelLeitura"){
      e.editorOptions.disabled = disabledAddDelButton;
    }

    if (e.dataField === "DesVariavelLeitura"){
      e.editorOptions.disabled = disabledAddDelButton;
    }

    if (e.dataField === "CodAreaProcesso"){
      e.editorOptions.disabled = disabledAddDelButton;
    }

    if (e.dataField === "ValEscala"){
      e.editorOptions.disabled = disabledAddDelButton;
    }

    if (e.dataField === "NumCasasDecimais"){
      e.editorOptions.disabled = disabledAddDelButton;
    }

    if (e.dataField === "TipReconheceAutomatico"){
      e.editorOptions.value = false
      e.editorOptions.disabled = disabledAddDelButton || !e.row.data.TipHabilitaAlarme;
    }

    if (e.dataField === "TipDescartaAlarme"){
      if (e.editorOptions.value === undefined) e.editorOptions.value = false
      e.editorOptions.disabled = disabledAddDelButton || !e.row.data.TipHabilitaAlarme;
    }

    if(e.dataField === "TipRegistroAtivo") {
      if (e.value === undefined) e.editorOptions.value = true
      e.editorOptions.disabled = disabledAddDelButton;
    }

    if (e.dataField === "TipHabilitaAlarme") {
      if (e.editorOptions.value === undefined) e.editorOptions.value = false
      e.editorOptions.disabled = disabledAddDelButton;
    }

    if (e.dataField === "TipRangeInvertido") {
      e.editorOptions.value = false;
      e.editorOptions.disabled = disabledAddDelButton;
    }

    if (e.dataField === "TipSeveridade") {
      e.editorOptions.disabled = disabledAddDelButton || !e.row.data.TipHabilitaAlarme;
    }

    if (e.dataField === "TipAlarmeDigital")  {
      if (e.editorOptions.value === undefined) e.editorOptions.value = false
      e.editorOptions.disabled = disabledAddDelButton || !e.row.data.TipHabilitaAlarme;
    }

    if (e.dataField === "ValPeriodoDescarte") {
      e.editorOptions.disabled = disabledAddDelButton || !e.row.data.TipHabilitaAlarme;

      if(e.row.data.TipHabilitaAlarme === true && e.row.data.TipDescartaAlarme === true && !disabledAddDelButton)
      e.editorOptions.disabled = !e.row.data.TipHabilitaAlarme;

      if(e.row.data.TipHabilitaAlarme === false && !disabledAddDelButton)
        e.editorOptions.disabled = !e.row.data.TipHabilitaAlarme;

      if(e.row.data.TipDescartaAlarme === false && !disabledAddDelButton)
        e.editorOptions.disabled = !e.row.data.TipDescartaAlarme;
    }

    if (e.dataField === "TipVariavelAutomatica") {
      if (e.editorOptions.value === undefined) e.editorOptions.value = false
      e.editorOptions.disabled = disabledAddDelButton;
    }

    if(e.dataField === "ValDigitalReferencia" && !disabledAddDelButton){
      if(e.editorOptions.value === undefined) e.editorOptions.value = false
      e.editorOptions.disabled = disabledAddDelButton || !e.row.data.TipHabilitaAlarme;

      if(e.row.data.TipHabilitaAlarme && e.row.data.TipAlarmeDigital)
        e.editorOptions.disabled = !e.row.data.TipHabilitaAlarme;

      if(!e.row.data.TipAlarmeDigital)
        e.editorOptions.disabled = !e.row.data.TipAlarmeDigital;
    }

    if((e.dataField === "ValMuitoBaixo" || 
        e.dataField === "ValBaixo" ||
        e.dataField === "ValAlto" ||
        e.dataField === "ValMuitoAlto" ||
        e.dataField === "ValIdeal" ||
        e.dataField === "NumCasasDecimais") &&  
        e.row.data.TipVariavelLeitura !== 2) 
    {
      e.editorOptions.value = 0
      e.editorOptions.disabled = true
    }
  }

  function checkUniqueKeyNameReadingVariable(params) {
    return !readingVariable.some(unit => 
      (unit.CodVariavelLeitura !== params.data.CodVariavelLeitura && unit.NomVariavelLeitura === params.data.NomVariavelLeitura)
    )
  }

  function cloneIconClick(e) {
		let clonedItem = {
			...e.row.data,
			CodUnidadeEmpresa: unitCod
		};
		delete clonedItem["CodVariavelLeitura"]

		duplicate.current = clonedItem;
		_configuration.current.instance.addRow()
		e.event.preventDefault();
	}
  //#endregion

  return (
    <React.Fragment>
      <DataGrid
        className={"dx-cards"}
        dataSource={readingVariable}
        rowAlternationEnabled={true}
        showColumnLines={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        showBorders={false}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        onInitNewRow={(e) => {
          if(Object.keys(duplicate.current).length === 0){
            e.data.CodUnidadeEmpresa = unitCod
            e.data.ValPeriodoDescarte = 24
            e.data.TipSeveridade = 2
            e.data.NumOrdem = 1
          } else {
            e.data = duplicate.current;
            duplicate.current = {};
          }
        }}
        onEditorPreparing={onEditorPreparing}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        onRowUpdating={async (e) => {
          const newObj = { ...e.oldData, ...e.newData };
          let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _configuration, newObj);

          e.cancel = updateRow(newObj, strLogMessage);

          e.component.deselectAll();
        }}
        onRowInserting={(e) => {
          let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _configuration, e.data);

          e.cancel = insertRow(e.data, strLogMessage);

          e.component.deselectAll();
        }}
        onRowRemoving={(e) => {
          let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _configuration, e.data)
          const data = { unitCod: unitCod,  data: e.data, logMessage: strLogMessage }

          e.cancel = deleteRow(data);

          e.component.deselectAll();
        }}
        ref={_configuration}
      >
        <Editing
          refreshMode={"full"}
          mode={isLarge ? "batch" : "popup"}
          allowUpdating={true}
          allowDeleting={disabledDeleteGrid}
          allowAdding={false}
        >
            <Popup
              title="Cadastro Variáveis"
              showTitle={true}
              width={700} height={725}
              fullScreen={isLarge ? false : true}
            >
              <Position my="center" at="center" of={window} />
            </Popup>
              <Form >
                <Item itemType="group" colCount={4} colSpan={2}>
                  <Item colSpan={1} dataField="NomVariavelLeitura"/>
                  <Item colSpan={2} dataField="DesVariavelLeitura"/>
                  <Item colSpan={1} dataField="TipVariavelLeitura"/>
                  <Item colSpan={1} dataField="ValMuitoBaixo"/>
                  <Item colSpan={1} dataField="ValBaixo"/>
                  <Item colSpan={1} dataField="ValAlto"/>
                  <Item colSpan={1} dataField="ValMuitoAlto"/>
                  <Item colSpan={1} dataField="ValIdeal"/>
                  <Item colSpan={1} dataField="DesUnidade"/>
                  <Item colSpan={1} dataField="ValEscala"/>
                  <Item colSpan={1} dataField="NumCasasDecimais"/>
                  <Item colSpan={1} dataField="CodAreaProcesso"/>
                  <Item colSpan={1} dataField="TipVariavelAutomatica"/>
                  <Item colSpan={1} dataField="TipRangeInvertido"/>
                  <Item colSpan={1} dataField="TipRegistroAtivo"/>
                  <Item colSpan={1} dataField="TipHabilitaAlarme"/>
                  <Item colSpan={2} dataField="TipReconheceAutomatico"/>
                  <Item colSpan={2} dataField="TipDescartaAlarme"/>
                  <Item colSpan={2} dataField="ValPeriodoDescarte" editorType={"dxNumberBox"} editorOptions={numberBoxOptions}/>
                  <Item colSpan={2} dataField="TipSeveridade"/>
                  <Item colSpan={1} dataField="TipAlarmeDigital"/>
                  <Item colSpan={1} dataField="ValDigitalReferencia" cssClass={"ValDigitalReferencia"} />
                  <Item colSpan={1} dataField="NumOrdem"/>
                </Item>
              </Form>
        </Editing>
        <Grouping
          contextMenuEnabled={false}
          expandMode="rowClick"
          autoExpandAll={false}
        />
        <GroupPanel visible={false} />
        <Export
          enabled={true}
          fileName="Configuração"
          allowExportSelectedData={true}
        />
        <ColumnChooser enabled={true} mode="select" />
        <ColumnFixing enabled={false} />
        <Selection mode="multiple" />
        <Paging defaultPageSize={20} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <Toolbar >
          <Item cssClass={'add-container'}>
            <Buttons className={'add-button'}  icon={"add"} onClick={() => (_configuration.current.instance.addRow())}/>
          </Item>
          <Item name="saveButton"/>
          <Item name="revertButton"/>
          <Item name="exportButton"/>
          <Item name="columnChooserButton"/>
        </Toolbar>
        <Column
          dataField={"NomVariavelLeitura"}
          caption={"Tag Variável"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
          e
        >
          <CustomRule
            message={'Este campo deve ser único'}
            reevaluate={true}
            type={"custom"}
            validationCallback={checkUniqueKeyNameReadingVariable}
          />
          <RequiredRule />
        </Column>
        <Column
          dataField={"DesVariavelLeitura"}
          caption={"Descrição"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField={"ValMuitoBaixo"}
          caption={"Valor Muito Baixo"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
        >
          <CustomRule
            message={'O valor muito baixo tem que ser menor que o valor baixo'}
            reevaluate={true}
            type={"custom"}
            validationCallback={checkVeryLowValue}
          />
          <CustomRule
            message={'O valor muito baixo tem que ser maior que o valor baixo'}
            reevaluate={true}
            type={"custom"}
            validationCallback={checkVeryLowValueInvertedRange}
          />
          <RequiredRule />
        </Column>
        <Column
          dataField={"ValBaixo"}
          caption={"Valor Baixo"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
        >
          <CustomRule
            message={'O valor baixo tem que ser maior que o valor muito baixo'}
            reevaluate={true}
            type={"custom"}
            validationCallback={checkVeryLowValue}
          />
          <CustomRule
            message={'O valor baixo tem que ser menor que o valor muito baixo'}
            reevaluate={true}
            type={"custom"}
            validationCallback={checkVeryLowValueInvertedRange}
          />
          <RequiredRule />
        </Column>
        <Column
          dataField={"ValIdeal"}
          caption={"Valor Ideal"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
        >
          <CustomRule
            message={'O valor ideal deve ser menor que o valor muito alto e alto e maior que o valor muito baixo e baixo.'}
            reevaluate={true}
            validationCallback={checkIdealValue}
          />
          <CustomRule
            message={'O valor ideal deve ser maior que o valor muito alto e alto e menor que o valor muito baixo e baixo.'}
            reevaluate={true}
            validationCallback={checkIdealValueInvertedRange}
          />
          <RequiredRule />
        </Column>
        <Column
          dataField={"ValAlto"}
          caption={"Valor Alto"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
        >
          <CustomRule
            message={'O valor alto deve ser menor que o valor muito alto e maior que o valor ideal, valor baixo e valor muito baixo.'}
            reevaluate={true}
            validationCallback={checkHighValue}
          />
          <CustomRule
            message={'O valor alto deve ser maior que o valor muito alto e menor que o valor ideal, valor baixo e valor muito baixo.'}
            reevaluate={true}
            validationCallback={checkHighValueInvertedRange}
          />
          <RequiredRule />
        </Column>
        <Column
          dataField={"ValMuitoAlto"}
          caption={"Valor Muito Alto"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
        >
          <CustomRule
            message={'O valor muito alto deve ser maior que todos os valores'}
            reevaluate={true}
            validationCallback={checkVeryHighValue}
          />
          <CustomRule
            message={'O valor muito alto deve ser menor que todos os valores'}
            reevaluate={true}
            validationCallback={checkVeryHighValueInvertedRange}
          />
          <RequiredRule />
        </Column>
        <Column
          dataField={"DesUnidade"}
          caption={"Unidade"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField={"ValEscala"}
          caption={"Escala"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField={"NumCasasDecimais"}
          caption={"Casas Dec."}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField={"TipRangeInvertido"}
          caption={"Range Invertido"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
          dataType={"boolean"}
        >
        </Column>
        <Column
          dataField={"CodAreaProcesso"}
          caption={"Área"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
        >
          <RequiredRule />
          <Lookup
            dataSource={areaProcess}
            displayExpr={"NomAreaProcesso"}
            valueExpr={"CodAreaProcesso"}
          />
        </Column>
        <Column
          dataField={"TipVariavelLeitura"}
          caption={"Tipo de Dado"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
          setCellValue={setCellValueTipVariavelLeitura}
        >
          <RequiredRule />
          <Lookup
            dataSource={TipVariavelLeituraSelect}
            displayExpr={"Nome"}
            valueExpr={"TipVariavelLeitura"}
            on
          />
        </Column>
        <Column
          dataField={"TipRegistroAtivo"}
          caption={"Ativo"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
          dataType={"boolean"}
        >
        </Column>
        <Column
          dataField={"TipHabilitaAlarme"}
          caption={"Habilita Alarme"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
          dataType={"boolean"}
          setCellValue={setCellValue}
        >
        </Column>
        <Column
          dataField={"TipReconheceAutomatico"}
          caption={"Reconhecimento Automático"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
          dataType={"boolean"}
        >
        </Column>
        <Column
          dataField={"TipDescartaAlarme"}
          caption={"Descarta Alarme"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
          dataType={"boolean"}
          setCellValue={setCellValueAlarmDiscard}
        >
        </Column>
        <Column
          dataField={"ValPeriodoDescarte"}
          caption={"Descarte (Meses)"}
          allowSorting={true}
          allowEditing={true}
          dataType={"number"}
          width={"auto"}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField={"TipSeveridade"}
          caption={"Severidade"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
        >
          <Lookup
            dataSource={severity}
            displayExpr="NomSeveridade"
            valueExpr="TipSeveridade"
          />
          <RequiredRule/>
        </Column>
        <Column
          dataField={"TipAlarmeDigital"}
          caption={"Alarme Digital"}
          allowSorting={true}
          allowEditing={true}
          dataType={"boolean"}
          width={"auto"}
          setCellValue={setCellValueDigitalAlarm}
        >
        </Column>
        <Column
          dataField={"ValDigitalReferencia"}
          caption={"Referencia"}
          allowSorting={true}
          allowEditing={true}
          dataType={"boolean"}
          width={"auto"}
        >
        </Column>
        <Column
          dataField={"TipVariavelAutomatica"}
          caption={"Variável Automática?"}
          allowSorting={true}
          allowEditing={true}
          dataType={"boolean"}
          width={"auto"}
        >
        </Column>
        <Column
          dataField={"NumOrdem"}
          caption={"Ordem"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
        >
          <RequiredRule />
        </Column>
        <Column 
          type="buttons"
          allowSorting={true}
          width={90}
          format={"shortTime"}
        >
          <Button hint="Duplicar" icon="copy" onClick={cloneIconClick} />
          <Button name="edit" />
          <Button name="delete" />
        </Column>
        <Column
          dataField={"TipPontoCritico"}
          caption={"Ponto Crítico"}
          allowSorting={true}
          allowEditing={true}
          dataType={"boolean"}
          width={"auto"}
        />
      </DataGrid>
      <Tooltip
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position={"bottom"}
            target={".ValDigitalReferencia"}
          >
            Checkbox deve ser marcado se gera alarme digital quando o bit for True
          </Tooltip>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  )
};
