/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import { useAuth } from "../contexts/auth";
import { api } from '../services/api';
import { useLoading } from '../contexts/loadingContext';

const WithAxios = ({ children }) => {
    const {  getToken } = useAuth();
    const { loading, setLoading } = useLoading()

    useMemo(() => {
        api.interceptors.response.use(async function (response) {
            
            if(response.config.url !== "notification" && response.config.url !== "auth/refreshtoken") setLoading(false);
            
            return response;
        }, async function (error) {
            setLoading(false);

            return Promise.reject(error);
        })
    }, [])
    
    api.interceptors.request.use(async (config) => {
        config.timeout = 90000;
        config.timeoutErrorMessage = "Timeout client";
        const token = getToken();
        if(config.url !== "/notification" && config.method === "get") 
            setLoading(true);

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    });

    return children;
}

export default WithAxios