/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "./style.scss";
import DataGrid, {
	Column,
	Pager,
	RequiredRule,
	Paging,
	FilterRow,
	Export,
	Selection,
	ColumnChooser,
	Grouping,
	GroupPanel,
	ColumnFixing,
	CustomRule,
	Lookup, Item, Toolbar,
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { LogMessageGrid } from "../../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import { useLoading } from "../../../contexts/loadingContext";
import Button from "devextreme-react/button";

export default function TypeStop(props) {
	const { loading } = useLoading();
	const { refreshToken, getRefreshTokenCode, getToken } = useAuth();
	const [load, setLoad] = useState(false);
	const [typeStop, setTypeStop] = useState([]);
	const [company, setCompany] = useState([]);
	const _typeStop = useRef();
	const countHour = [
		{
			Id: 1,
			Desc: "Não contabilizar/Programado para não operar",
		},
		{
			Id: 2,
			Desc: "Parada operacional",
		},
		{
			Id: 3,
			Desc: "Parada não operacional",
		},
	];
	const columnMaxLengthTen = { maxLength: 10 };
	const columnMaxLengthOneHundred = { maxLength: 100 };

	//Autenticação requests
	const { unitCod } = useAuth();

	useEffect(() => {
		firstLoad();
	}, []);

	useEffect(() => {
		if (load) {
			loadData();
			loadDataUserCompanyUnit();
		}
	}, [load]);

	async function firstLoad() {
		if (unitCod !== undefined) {
			loadData();
			loadDataUserCompanyUnit();
			refreshToken(getToken(), getRefreshTokenCode());
		}
	}

	async function loadData() {
		await api
			.get("/typeStop", { params: { unitCod: unitCod } })
			.then((response) => {
				setTypeStop(response.data);
				setLoad(false);

				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function loadDataUserCompanyUnit() {
		await api
			.get("/companyUnit", { params: { unitCod: 0 } })
			.then((response) => {
				setCompany(response.data);
				setLoad(false);
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function updateRow(data, parLogMessage) {
		return await api
			.put("/typeStop", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then((response) => {
				setLoad(false);
				props.setUpdate(!props.update)

				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function insertRow(data, parLogMessage) {
		return await api
			.post("/typeStop", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then((response) => {
				setLoad(true);
				props.setUpdate(!props.update)

				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function deleteRow(data) {
		return await api
			.delete("/typeStop", { data: data })
			.then((response) => {
				setLoad(true);
				props.setUpdate(!props.update);

				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	function checkUniqueKey(params) {
		let check = true;

		typeStop.forEach((unit) => {
			if (unit.CodTipoParada !== params.data.CodTipoParada && unit.NomTipoParada === params.data.NomTipoParada) check = false;
		});

		return check;
	}

	function checkUniqueKeySigla(params) {
		let check = true;

		typeStop.forEach((unit) => {
			if (unit.CodTipoParada !== params.data.CodTipoParada && unit.NomSiglaTipoParada === params.data.NomSiglaTipoParada) check = false;
		});

		return check;
	}

	return (
		<React.Fragment>
			<DataGrid
				dataSource={typeStop}
				rowAlternationEnabled={true}
				showColumnLines={true}
				allowColumnReordering={true}
				showBorders={false}
				columnAutoWidth={false}
				columnHidingEnabled={true}
				repaintChangesOnly={true}
				headerFilter={{ visible: true }}
				filterPanel={{ visible: true }}
				filterRow={{ visible: false }}
				onInitNewRow={(e) => {
					e.data.CodUnidadeEmpresa = unitCod;
					e.data.TipContabilizacaoHora = 1;
				}}
				editing={{
					refreshMode: "full",
					mode: "batch",
					allowUpdating: true,
					allowDeleting: true,
					allowAdding: false,
				}}
				onRowUpdating={async (e) => {
					const newObj = { ...e.oldData, ...e.newData };
          			let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _typeStop, newObj);

          			e.cancel = updateRow(newObj, strLogMessage);

					e.component.deselectAll();
				}}
				onRowInserting={(e) => {
          			let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _typeStop, e.data);

          			e.cancel = insertRow(e.data, strLogMessage);

					e.component.deselectAll();
				}}
				onRowRemoving={(e) => {
          			let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _typeStop, e.data)
          			const data = { unitCod: unitCod,  data: e.data, logMessage: strLogMessage }

					e.cancel = deleteRow(data);

					e.component.deselectAll();
				}}
				ref={_typeStop}>
				<Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false} />
				<GroupPanel visible={false} />
				<Export enabled={true} fileName="Permissões" allowExportSelectedData={true} />
				<ColumnChooser enabled={true} mode="select" />
				<ColumnFixing enabled={false} />
				<Selection mode="multiple" />
				<Paging defaultPageSize={20} />
				<Pager showPageSizeSelector={true} showInfo={true} />
				<FilterRow visible={true} />
				<Toolbar >
					<Item cssClass={'add-container'}>
						<Button className={'add-button'}  icon={"add"} onClick={() => (_typeStop.current.instance.addRow())}/>
					</Item>
					<Item name="saveButton"/>
					<Item name="revertButton"/>
					<Item name="exportButton"/>
					<Item name="columnChooserButton"/>
				</Toolbar>
				<Column
					dataField={"NomTipoParada"}
					caption={"Tipo Parada"}
					allowSorting={true}
					allowEditing={true}
					dataType={"string"}
					width={"30%"}
					editorOptions={columnMaxLengthOneHundred}>
					<CustomRule message={"Este campo deve ser único"} reevaluate={true} type={"custom"} validationCallback={checkUniqueKey} />
					<RequiredRule />
				</Column>
				<Column
					dataField={"NomSiglaTipoParada"}
					caption={"Sigla do Tipo"}
					allowSorting={true}
					allowEditing={true}
					dataType={"string"}
					width={"auto"}
					editorOptions={columnMaxLengthTen}>
					<CustomRule message={"Este campo deve ser único"} reevaluate={true} type={"custom"} validationCallback={checkUniqueKeySigla} />
				</Column>
				<Column dataField={"TipContabilizacaoHora"} caption={"Contabilizar Hora"} allowSorting={true} allowEditing={true} width={"30%"}>
					<Lookup dataSource={countHour} displayExpr="Desc" valueExpr="Id" />
					<RequiredRule />
				</Column>
			</DataGrid>
			<div className="load-indicator">
				<LoadIndicator visible={loading} height={40} width={40} />
			</div>
		</React.Fragment>
	);
}
