import React from "react";
import "./home.scss";
import PopupTip from '../administration/popupTip';
import { FavCard } from "../../components/fav-card/favCard";
// eslint-disable-next-line import/no-anonymous-default-export
export default function () {

  return(
  <React.Fragment>
{/*     <img
      className={"home-img"}
      width={isXSmall || isXLSmall ? "250" : isSmall ? "400" : isMedium ? "650" : isLarge ? "800" : "800"}
      height={isXSmall || isXLSmall ? "40" : isSmall ? "60" : isMedium ? "85" : isLarge ? "113" : "113"}
      src={logo}
      alt=""
      style={{zIndex: -1, position: "relative"}}
    /> */}
    <FavCard/>
    <PopupTip />
  </React.Fragment>)
};
