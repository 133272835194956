/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {useAuth} from "../../contexts/auth";
import {api} from "../../services/api";
import {Button} from "devextreme-react/button";
import {TextField} from "@material-ui/core";
import {useDataLoader} from "../../contexts/dataLoaderContext";
import "./style.scss";
import {ChangePasswordForm} from "../../components";
import { ImageUploaderInline } from "../../components/image-uploader/imageUploaderInline";
import dayjs from "dayjs";


// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
    const {userCod, unitCod} = useAuth();
    const [load, setLoad] = useState(false);
    const [imgs, setImgs] = useState({});
    const [codImg, setCodImg] = useState(null);
    // -------------------------------------------------------------
    // testes image uploader local
    // const [file, setFile] = useState([]);
    // const [fileNames, setFileNames] = useState("");
    // const [nameArray, setNameArray] = useState([]);
    // const [openImgUploaderLocal, setOpenImgUploaderLocal] = useState(false);
    // -------------------------------------------------------------

    // -------------------------------------------------------------
    // testes QR generator e Image Viewer
    // const [openImgViewer, setOpenImgViewer] = useState(false);
    // const [qr, openQr] = useState(false);
    // const teste = {
    //     "Nome": "nome de teste",
    //     "Data": new Date()
    // }
    // -------------------------------------------------------------

    const [openImgUploader, setOpenImgUploader] = useState(false);
    const [name, setName] = useState(localStorage.getItem("@es-mes/user"));
    let [user, setUser] = useState("");
    let _user = useRef({});
    const { setEnabledActualPassword, setChangePassword, changePassword, objectImgToDataBase } = useDataLoader();

    useEffect(() => {
        getUser(userCod);
        getUserImage(9110, userCod);
        // getUserImageLocal("teste1.jpg|teste2.jpg");
    }, []);

    async function getUser(data) {
        return await api
            .get("user/getUser", { params: { id: data } })
            .then((response) => {
                _user.current = response.data[0];
                setUser(response.data[0]);
                setName(response[0].data.NomUsuario);
                setLoad(!load);
                return false;
            })
            .catch(() => {
                return false;
            });
    }

    // -------------------------------------------------------------
    // testes image uploader local
    // async function getUserImageLocal(data) {
    //     return await api
    //         .get("file/get/task", {params: {filename: data}})
    //         .then((response) => {
    //             // if (response.data[0]) {
    //             console.log(response.data)
    //                 // setImgs([response.data[0]])
    //                 setFile(response.data)
    //                 // setObs(response.data[0].DesObservacao)
    //                 // setCodImg(response.data[0].CodImagem)
    //             // }
    //             setLoad(!load);
    //             return false;
    //         })
    //         .catch(() => {
    //             return false;
    //         });
    // }
    // -------------------------------------------------------------

    async function getUserImage(programCode, primaryCode) {
        return await api
            .get("image/get", {
                params: {
                    unitCod: unitCod,
                    programCod: programCode,
                    primaryCod: primaryCode
                }
            })
            .then((response) => {
                if (response.data[0]) {
                    setImgs(response.data)
                    setCodImg(response.data[0].CodImagem)
                }
                setLoad(!load);
                return false;
            })
            .catch(() => {
                return false;
            });
    }

    async function updateUser(data, imgData, parLogMessage) {
        return await api
            .put("user", {
                unitCod: unitCod,
                data: data,
                logMessage: parLogMessage,
                imgData: imgData,
            })
            .then((response) => {
                setLoad(!load);
                return false;
            })
            .catch((e) => {
                console.log("error",e)
                return false;
            });
    }

    async function updateImage(data, parLogMessage) {
        return await api
            .put("image", {
                unitCod: unitCod,
                data: data,
                logMessage: parLogMessage,
            })
            .then((response) => {
                setLoad(!load);
                return false;
            })
            .catch(() => {
                return false;
            });
    }

    // -------------------------------------------------------------
    // testes image uploader local
    // async function updateImageFileTask(files, nameArray, names, obs) {
    //     console.log({nameArray}, {names})
    //     const config = {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     }
    //     let formData = new FormData();
    //     files.forEach((file, index) => {
    //         formData.append('files', file)
    //         formData.append('nameArray', nameArray[index])
    //     })
    //
    //     formData.append('names', names)
    //     formData.append('obs', obs)
    //     formData.append('programCode', 9110)
    //     formData.append('companyCode', unitCod)
    //     formData.append('primaryCode', userCod)
    //     formData.append('storeDate', new dayjs().format())
    //
    //     await api
    //         .post("file/upload/task", formData, config)
    //         .then(() => {})
    //         .catch(() => {
    //             return false;
    //         });
    // }
    // -------------------------------------------------------------

    const handleSave = async () => {
        const logMessage = "Modificou usuário";
        await getUser(userCod);
        if (name.length > 0) {
            _user.current.NomUsuario = name;
            await updateUser(_user.current, objectImgToDataBase.current, logMessage);
        }

        localStorage.setItem("@es-mes/user", name);

        setTimeout(() => {
            window.location.reload();
        }, 3000);
    };

    const handleChangePassword = () => {
        setEnabledActualPassword(true);
        setChangePassword(true);
    };

    const handleOpenImageUploader = () => {
        setOpenImgUploader(true)
    };
    // -------------------------------------------------------------
    // testes image uploader local
    // const handleSaveImage = async () => {
    //     if (file) {
    //         await updateImageFileTask(file, nameArray, fileNames, obs, userCod)
    //     }
    // };
    //
    // const handleOpenImageUploaderLocal = () => {
    //     setOpenImgUploaderLocal(true)
    // };
    // -------------------------------------------------------------

    // -------------------------------------------------------------
    // testes QR generator e Image Viewer
    // const handleGenerateQr = () => {
    //     openQr(true)
    // };

    // const handleOpenImageViewer = () => {
    //     setOpenImgViewer(true)
    // };
    // -------------------------------------------------------------
    return (
        <React.Fragment>
            <div>
                <form style={{display: "flex", marginLeft: "2vw"}}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <h2><b>Editar Perfil:</b></h2>
                        <TextField
                            variant="standard"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            label={"Nome"}
                            InputLabelProps={{shrink: true}}
                            style={{width: "14em", height: "3em", margin: " 1vh 0 2vh 1vh"}}
                        />
                        <div className={"edit-button"}>
                            <div className={"change-pwd"}>
                                <Button
                                    onClick={handleChangePassword}
                                    text={"Alterar senha"}
                                    // width={"14em"}
                                    height={"5em"}
                                />
                            </div>
                            <div className={"change-pwd"} style={{minHeight: "5em", display: "flex", alignItems: "center"}}>
                                <ImageUploaderInline
                                    multiple={true}
                                    numImgs={10}
                                    objImgFromDataBase={[]}
                                    objImgToDataBase={objectImgToDataBase}
                                    datRegister={dayjs().format()}
                                    disabledButton={false}
                                    invisibleObservation={true}
                                    buttonType={false}
                                />
                            </div>
                            {/*// -------------------------------------------------------------*/}
                            {/*// testes QR generator e Image Viewer*/}
                            {/*<div className={"change-pwd"}>*/}
                            {/*    <Button*/}
                            {/*        onClick={handleGenerateQr}*/}
                            {/*        text={"Gerar QR"}*/}
                            {/*        height={"5em"}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<div className={"change-pwd"}>*/}
                            {/*    <Button*/}
                            {/*        onClick={handleOpenImageViewer}*/}
                            {/*        text={"Visualizador de imagens"}*/}
                            {/*        height={"5em"}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*// -------------------------------------------------------------*/}

                            {/*// -------------------------------------------------------------*/}
                            {/*// testes image uploader local*/}
                            {/*<div className={"change-pwd"}>*/}
                            {/*    <Button*/}
                            {/*        onClick={handleOpenImageUploaderLocal}*/}
                            {/*        text={"Alterar Foto Local"}*/}
                            {/*        // width={"14em"}*/}
                            {/*        height={"5em"}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*// -------------------------------------------------------------*/}

                        </div>
                        <div className={"save"} style={{marginTop: "24px"}}>
                            <Button
                                onClick={handleSave}
                                icon={"save"}
                                text={"Salvar"}
                                width={"14em"}
                                height={"3em"}
                                disabled={!name.length > 0 && !imgs.ImgBase64}
                            />
                        </div>
                    </div>
                </form>
                {changePassword && <ChangePasswordForm userCod={userCod}/>}
                {/*// -------------------------------------------------------------*/}
                {/*// testes image uploader local*/}
                {/*{openImgUploaderLocal &&  <LocalImageUploader multiple={true}*/}
                {/*                                              file={file} setFile={setFile}*/}
                {/*                                              names={fileNames} setNames={setFileNames}*/}
                {/*                                              obs={obs} setObs={setObs}*/}
                {/*                                              nameArray={nameArray} setNameArray={setNameArray}*/}
                {/*                                              open={openImgUploaderLocal} setOpen={setOpenImgUploaderLocal}*/}
                {/*                                              primaryCode={userCod}/>}*/}
                {/*// -------------------------------------------------------------*/}

                {/*// -------------------------------------------------------------*/}
                {/*// testes QR generator e Image Viewer*/}
                {/*{qr && <QRCode value={JSON.stringify(teste)}/>}*/}
                {/*{openImgViewer && <ImageViewer open={openImgViewer} setOpen={setOpenImgViewer} programCode={9110} primaryCode={userCod}/>}*/}
                {/*// -------------------------------------------------------------*/}

            </div>
        </React.Fragment>
    );
}
