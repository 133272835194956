const codeToString = {
    1: "pt",
    2: "en",
    3: "es",
  };
  const stringToCode = {
    pt: 1,
    en: 2,
    es: 3,
  };
  export { codeToString, stringToCode };
  