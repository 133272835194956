/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "../../../components/common/style.scss";
import "devextreme/data/odata/store";
import {LogMessageGrid} from "../../../utils/logMessage";
import {formatMessage} from "devextreme/localization";
import DataGrid, {
    Column,
    ColumnChooser, ColumnFixing, Editing,
    FilterRow,
    Form,
    Grouping,
    GroupPanel,
    Item, Pager, Paging,
    RequiredRule, Toolbar,
    Lookup,
    Export,
    CustomRule,
    Selection
} from "devextreme-react/data-grid";
import {api} from "../../../services/api";
import {useLoading} from "../../../contexts/loadingContext";
import {useAuth} from "../../../contexts/auth";
import {LoadIndicator} from "devextreme-react/load-indicator";
import Button from "devextreme-react/button";

const lstItemType = [{Code: 0, Name:"Grupo de Análise"}, 
                     {Code: 1, Name:"Variável Leitura"}];

export default function AditionalConfiguration(props) {
    const { unitCod, refreshToken, getRefreshTokenCode, getToken } = useAuth();
    const { loading } = useLoading();
    const [load, setLoad] = useState(false);
    const [dailyReportBundlerItem, setDailyReportBundlerItem] = useState(false);
    const [analysisGroup, setAnalysisGroup] = useState([]);
    const [analysis, setAnalysis] = useState([]);
    const [readingVariable, setReadingVariable] = useState([]);
    const _dailyReportBundlerItem = useRef();
    
    useEffect(() => {
        firstLoad()
    }, [props.update]);

    useEffect(() => {
        if (load)
            updateLoad();
    }, [load]);

    async function updateLoad() {
        loadData();
    }

    async function firstLoad() {
        if (unitCod !== undefined) {
            loadData();
            loadDataAnalysisGroup();
            loadDataAnalysis();
            loadReadingVariableData();
            await refreshToken(getToken(), getRefreshTokenCode());
        }
    }

    async function loadData() {
        await api
            .get("/dailyReportBundlerAditionalItem", { params: { unitCod: unitCod } })
            .then((response) => {
                setDailyReportBundlerItem(response.data);
                setLoad(false);
            })
            .catch(() => {
                setLoad(true);
                return true;
            });
    }

    async function loadDataAnalysisGroup() {
		await api
			.get("/analysisGroup", { params: { unitCod: unitCod } })
			.then((response) => {
                const lstAnalysisGroup = response.data.filter((analysis) => analysis.TipRegistroAtivo === true);
				setAnalysisGroup(lstAnalysisGroup);
				setLoad(false);
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

    async function loadDataAnalysis() {
		await api
			.get("/dailyReportBundlerAditionalItem/getAnalysis", { params: { unitCod: unitCod } })
			.then((response) => {
				setAnalysis(response.data);
				setLoad(false);
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

    async function loadReadingVariableData() {
        await api
            .get("/ReadingVariable", { params: { unitCod: unitCod } })
            .then((response) => {
                setReadingVariable(response.data);
            })
            .catch(() => {
                return true;
            });
    }

    async function updateRow(data, parLogMessage) {
        return await api
            .put("/dailyReportBundlerAditionalItem", { unitCod: unitCod, data: data, logMessage: parLogMessage })
            .then((response) => {
                setLoad(true);
                props.setUpdate(!props.update)
                return false;
            })
            .catch(() => {
                setLoad(true);
                return true;
            });
    }

    async function insertRow(data, parLogMessage) {
        return await api
            .post("/dailyReportBundlerAditionalItem", { unitCod: unitCod, data: data, logMessage: parLogMessage })
            .then((response) => {
                setLoad(true);
                props.setUpdate(!props.update)
                return false;
            })
            .catch(() => {
                setLoad(true);
                return true;
            });
    }

    async function deleteRow(data) {
        return await api
            .delete("/dailyReportBundlerAditionalItem", { data: data })
            .then(() => {
                setLoad(true);
                props.setUpdate(!props.update)
                return false;
            })
            .catch(() => {
                setLoad(true);

                return true;
            });
    }

    function onEditorPreparing(e) {
        if(e.parentType !== "dataRow") return;

        if((e.dataField === "CodAnaliseGrupo" || e.dataField === "CodAnaliseAdicional") && e.row.data.TipItem !== 0)
            changeColumnOption(e);

        if((e.dataField === "CodVariavelLeitura" || e.dataField === "CodVariavelLeituraAdicional") && e.row.data.TipItem !== 1)
            changeColumnOption(e);
    }

    function changeColumnOption(e) {
        e.editorOptions.value = null;
        e.editorOptions.disabled = true;
    }

    
  function setCellValueTipItem(newData, value) {
    newData.TipItem = null;
    newData.CodAnaliseGrupo = null;
    newData.CodAnaliseAdicional = null;
    newData.CodVariavelLeitura = null;
    newData.CodVariavelLeituraAdicional = null;

    let column = this;
    column.defaultSetCellValue(newData, value);
  }

  function setCellValueCodVariavelLeitura(newData, value, currentRowData) {
    if(value === currentRowData.CodVariavelLeituraAdicional) 
        newData.CodVariavelLeituraAdicional = null;
    
    let column = this;
    column.defaultSetCellValue(newData, value);
  }

  function setCellValueCodAnaliseGrupo(newData, value, currentRowData) {
    const analysi = analysis.filter(x => x.CodAnaliseGrupo === value && x.CodAnalise === currentRowData.CodAnaliseAdicional);
    
    if(analysi.length > 0)
        newData.CodAnaliseAdicional = null;

    let column = this;
    column.defaultSetCellValue(newData, value);
  }

    return (
        <React.Fragment>
            <DataGrid
                className={"dx-cards"}
                dataSource={dailyReportBundlerItem}
                rowAlternationEnabled={true}
                showColumnLines={true}
                allowColumnReordering={true}
                showBorders={false}
                columnHidingEnabled={true}
                repaintChangesOnly={true}
                headerFilter={{ visible: true }}
                filterPanel={{ visible: true }}
                filterRow={{ visible: true }}
                onRowUpdating={async (e) => {
                    const newObj = { ...e.oldData, ...e.newData };
                    let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _dailyReportBundlerItem, newObj);

                    e.cancel = await updateRow(newObj, strLogMessage);

                    e.component.deselectAll();
                }}
                onRowInserting={async (e) => {
                    let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _dailyReportBundlerItem, e.data);

                    e.cancel = await insertRow(e.data, strLogMessage);

                    e.component.deselectAll();
                }}
                onRowRemoving={async (e) => {
                    let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _dailyReportBundlerItem, e.data);
                    const data = { unitCod: unitCod, data: e.data, logMessage: strLogMessage };

                    e.cancel = await deleteRow(data);

                    e.component.deselectAll();

                }}
                onEditorPreparing={onEditorPreparing}
                onFocusedCellChanged={(e) => {
                    if(e.column.dataField === "CodVariavelLeituraAdicional")
                        e.column.lookup.dataSource = readingVariable.filter(x => x.CodVariavelLeitura !== e.row.data.CodVariavelLeitura);

                    if(e.column.dataField === "CodAnaliseAdicional")
                        e.column.lookup.dataSource = analysis.filter(x => x.CodAnaliseGrupo !== e.row.data.CodAnaliseGrupo);
                }}
                ref={_dailyReportBundlerItem}>
                <Export
                    enabled={true}
                    fileName="Configuração"
                    allowExportSelectedData={true}
                />
                <Editing mode={"batch"} allowUpdating={true} allowDeleting={true} allowAdding={false}>
                    <Form>
                        <Item itemType="group" colCount={4} colSpan={2}>
                            <Item colSpan={2} dataField="TipItem" />
                            <Item colSpan={2} dataField="CodAnaliseGrupo" />
                            <Item colSpan={2} dataField="CodVariavelLeitura" />
                            <Item colSpan={2} dataField="CodAnaliseAdicional" />
                            <Item colSpan={2} dataField="CodVariavelLeituraAdicional" />
                        </Item>
                    </Form>
                </Editing>
                <Selection mode="multiple" />
                <Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false} />
                <GroupPanel visible={false} />
                <ColumnChooser enabled={true} mode="select" />
                <ColumnFixing enabled={false} />
                <Paging defaultPageSize={20} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />
                <Toolbar >
                    <Item cssClass={'add-container'}>
                        <Button className={'add-button'}  icon={"add"} onClick={() => (_dailyReportBundlerItem.current.instance.addRow())}/>
                    </Item>
                    <Item name="saveButton"/>
                    <Item name="revertButton"/>
                    <Item name="exportButton"/>
                    <Item name="columnChooserButton"/>
                </Toolbar>
                <Column 
                    dataField={"TipItem"} 
                    caption={"Tipo"} 
                    allowSorting={true}
                    allowFiltering={true}
                    setCellValue={setCellValueTipItem}  
                >
                    <Lookup 
                        dataSource={lstItemType} 
                        displayExpr="Name" 
                        valueExpr="Code" 
                    />
                    <RequiredRule />
                </Column>
                <Column 
                    dataField={"CodAnaliseGrupo"} 
                    caption={"Grupo de Análise"} 
                    allowSorting={true}
                    allowFiltering={true}
                    setCellValue={setCellValueCodAnaliseGrupo}
                >
                    <Lookup 
                        dataSource={analysisGroup} 
                        displayExpr="NomAnaliseGrupo"  
                        valueExpr="CodAnaliseGrupo"
                    />
                </Column>
                <Column 
                    dataField={"CodAnaliseAdicional"} 
                    caption={"Análise Adicional"} 
                    allowSorting={true}
                    allowFiltering={true}
                >
                    <Lookup 
                        dataSource={analysis} 
                        displayExpr="NomAnalise"  
                        valueExpr="CodAnalise"
                    />
                </Column>
                <Column 
                    dataField={"CodVariavelLeitura"} 
                    caption={"Variável Leitura"} 
                    allowSorting={true}
                    allowFiltering={true}
                    setCellValue={setCellValueCodVariavelLeitura}
                >
                    <Lookup 
                        dataSource={readingVariable} 
                        displayExpr="NomVariavelLeitura"
                        valueExpr="CodVariavelLeitura"  
                    />
                </Column>
                <Column 
                    dataField={"CodVariavelLeituraAdicional"} 
                    caption={"Variável Leitura Adicional"} 
                    allowSorting={true}
                    allowFiltering={true}
                >
                    <Lookup 
                        dataSource={readingVariable} 
                        displayExpr="NomVariavelLeitura"
                        valueExpr="CodVariavelLeitura"  
                    />
                </Column>
            </DataGrid>
            <div className="load-indicator">
                <LoadIndicator visible={loading} height={40} width={40} />
            </div>
        </React.Fragment>
    );
}