/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createContext, useContext, useEffect } from "react";
import { api } from "../services/api";
import { useAuth } from "./auth";
import { TOKEN_KEY, getToken } from "./auth";

const NotificationContext = createContext({});
const useNotification = () => useContext(NotificationContext);

function NotificationProvider(props) {
  const [notificationsTime, setNotificationsTime] = useState(localStorage.getItem("es-mes/notificationsTime"));
  const { unitCod } = useAuth();

  useEffect(() => {
    if(getToken() !== null)
      loadDataNotificationTime(unitCod);
  }, [TOKEN_KEY, getToken()]);

  async function loadDataNotificationTime(unitCod) {
    await api
      .get("/aplication/getValTempoNotificacaoUsuario", { params: { unitCod: unitCod } })
      .then((response) => {
        localStorage.setItem("@es-mes/notificationTime", response.data);
        setNotificationsTime(response.data);
      })
      .catch((error) => {
      });
  };

  return (
    <NotificationContext.Provider
      value={{
        notificationsTime,
        setNotificationsTime,
        loadDataNotificationTime
      }}
      {...props}
    />
  );
}

export { NotificationProvider, useNotification };
