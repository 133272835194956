import React, { useState } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/form";
import { FavButton } from "../../../components/fav-button/favButton";
import Bundler from "./bundler";
import Configuration from "./configuration";
import AditionalConfiguration from "./aditionalConfiguration";

export default function DailyReportTab() {
  const [update, setUpdate] = useState(0);

  const renderBundler = () => {
    return <Bundler update={update} setUpdate={setUpdate} />;
  };

  const renderConfiguration = () => {
    return <Configuration update={update} />;
  };

  const renderAditionalConfiguration = () => {
    return <AditionalConfiguration update={update} />;
  };
  
  return (
    <React.Fragment>
      <FavButton />
      <TabPanel
        className={"tab-panel-margin"}
        animationEnabled={true}
        swipeEnabled={false}
      >
        <Item 
          title="Agrupador" 
          render={renderBundler} 
        />
        <Item 
          title="Configuração" 
          render={renderConfiguration} 
        />
        <Item 
          title="Configuração Adicional" 
          render={renderAditionalConfiguration} 
        />
      </TabPanel>
    </React.Fragment>
  );
}
