/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import HeaderDateBox from "../../../components/header-datebox/header-datebox";
import ButtonList from "./buttonList";
import dayjs from "dayjs";
import { useLoading } from "../../../contexts/loadingContext";
import { FavButton } from "../../../components/fav-button/favButton";
import { ReportsButton } from "../../../components/reports-button/reportsButton";
import _ from 'lodash';

export default function VariableInputByDate() {
	const { loading } = useLoading();
	const [load, setLoad] = useState();
	const { refreshToken, getRefreshTokenCode, getToken, unitCod, userCod } = useAuth();
	const [screen, setScreen] = useState();
	const [processCodeFilter, setProcessCodeFilter] = useState(0);
	const [currencyValueDate, setCurrencyValueDate] = useState(dayjs());
	const [lstProcess, setLstProcess] = useState()
	const [searchProcessText, setSearchProcessText] = useState()
	const [searchVariablesText, setSearchVariablesText] = useState()
	let _refHeaderDateBoxValue = useRef();
	let _lstProcess = useRef([]);

	useEffect(() => {
		getLoadProcess();
		setScreen("process");
	}, []);

	//Faz o filtro do botão Pesquisar
	useEffect(() => {
		if(searchProcessText !== undefined)
			setLstProcess(_lstProcess.current.filter(lstProcess => lstProcess.NomProcesso.toUpperCase().indexOf(searchProcessText.toUpperCase()) > -1))
	}, [searchProcessText])

	//#region Requests
	async function getLoadProcess() {
		await api
			.get("/readingVariable/getprocess", { params: { unitCod: unitCod, userId: userCod  } })
			.then((response) => {
				_lstProcess.current = _.orderBy(response.data, "NomProcesso")
				setLoad(true);
			})
			.catch(() => {
				return true;
			});
	}
	//#endregion

	return (
		<React.Fragment>
			<HeaderDateBox
				screen={screen}
				setScreen={setScreen}
				lstProcess={_lstProcess.current}
				setSearchProcessText={setSearchProcessText}
				setSearchVariablesText={setSearchVariablesText}
				setCurrencyValueDate={setCurrencyValueDate}
				currencyValueDate={currencyValueDate}
				setLoad={setLoad}
				load={load}
				setProcessCodeFilter={setProcessCodeFilter}
				processCodeFilter={processCodeFilter}
				refHeaderDateBoxValue={_refHeaderDateBoxValue}
				byDate={true}
				sampleScreen={false}
			/>
			<div className={"ContainerButtons"}>
				<FavButton/>
				<ReportsButton/>
			</div>
			<div>
				{_lstProcess.current.length > 0 ? 
					<ButtonList
						list={!lstProcess || lstProcess.length === 0 ? _lstProcess.current : lstProcess}
						lstProcess={_lstProcess.current}
						screen={screen}
						load={load}
						setSearchProcessText={setSearchProcessText}
						processCodeFilter={processCodeFilter}
						setProcessCodeFilter={setProcessCodeFilter}
						setLoad={setLoad}
						setScreen={setScreen}
					/>
				: ""}
			</div>
			<div className="load-indicator">
				<LoadIndicator visible={loading} height={40} width={40} />
			</div>
		</React.Fragment>
	);
}
