/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { TextBox, SelectBox, Button } from "devextreme-react";
import "devextreme/data/odata/store";
import "../../../components/sampleInputs/style.scss";
import { useDataLoader } from "../../../contexts/dataLoaderContext";
import { useAuth } from "../../../contexts/auth";
import LabelHour from "../../../components/sampleInputs/LabelHour";
import HourBox from "../../../components/sampleInputs/HourBox";
import PositiveNegativeInput from "../../../components/sampleInputs/PositiveNegativeInput";
import AprovedFields from "../../../components/sampleInputs/AprovedFields";
import NumericInput from "../../../components/sampleInputs/NumericInput";
import { useScreenSize } from "../../../utils/media-query";
import { SpeedDialAction } from "devextreme-react/speed-dial-action";
import {api} from "../../../services/api";
import dayjs from "dayjs";
import PopupListVisualizer from "../../../components/popupListVisualizer/popupListVisualizer";
import _ from "lodash";
import { ImageUploaderInline } from "../../../components/image-uploader/imageUploaderInline";
import PopupReanalysis from "../popupReanalysis/PopupReanalysis";

export const SampleInputs = (props) => {

	//const [load, setLoad] = useState()
	const { saveHeaderTextBox, setChangedDateHeader, changedDateHeader, numLot, setNumLot, objectImgToDataBase } = useDataLoader()
	const { isXSmall, isLarge, isMedium } = useScreenSize()
	const { unitCod, userCod } = useAuth()
	const [objectGetImgUploader, setObjectGetImgUploader] = useState({});
	const [visible, setVisible] = useState(false);
	const [visibleReanalysisPopup, setVisibleReanalysisPopup] = useState(false);
	const [currentDate, setCurrentDate] = useState();
	let _refLabelHour = useRef([])
	let _refInput = useRef([])
	let _refUserSelectBox = useRef([])
	let _refObservation = useRef([])
	let _refBtnNotWorking = useRef([])
	let _refButtonReAnalysis = useRef([])
	let subTitle = ""
	let _refAnchor = useRef([])
	let _lstPreviousValue = useRef([]);
	let _lstVisualizer = useRef([]);
	let _firstLoad = useRef(true);
	let _listAnalysisToImage = useRef(true)
	let _tipPeriodUnit = useRef();
	let _codAnalysis = useRef();
	let _isLot = useRef();
	let _nameAnalysis = useRef();
	const _itemRef = useRef([]);

	const scrollToItem = (index) => {
		_itemRef.current[index].scrollIntoView({ behavior: "smooth" });
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {

		if ((saveHeaderTextBox || changedDateHeader) && !_firstLoad.current) {
			//Reload quando salva
			props.setReLoad(false)
			props.loadVariableInputs(props.analysisGroupCode)
			setChangedDateHeader(false)
		}

		//Se for primeira renderização
		if(_firstLoad.current) {
			_firstLoad.current = false;
			
			//Retira o loading
			props.setLoading(false)

			//Usado para carregar os grupos no botão "Selecionar Outro Grupo"
			_lstVisualizer.current = resultLstVisualizer();

			//Para ir para o topo a primeira vez que renderiza
			onClick();
			
			//Carrega as imagens nos grupos de verificação por Lote e por período
			if((props.analysisTimeList[0][9] && !!numLot)) getImages(props.analysisGroupCode, null, numLot, null, unitCod)//Código Analise Amostra e número de lote (numTerciario), quando a análise é por lote
			if(!props.analysisTimeList[0][9]) getImages(props.analysisGroupCode, null, null, dayjs(props.currencyValueDate).format(), unitCod)//Código Analise Amostra quando não é lote
			
			//Carrega as analises no selectbox do componente de imagem
			_listAnalysisToImage.current = props.analysisTimeList.map((item) => ({Id: `${item[8].CodAnalise}`, Value: item[0]}))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saveHeaderTextBox, props.currencyValueDate])

	//#region Requests
	async function getImages(parPrimaryCod, parSecondaryCod, parTerciaryCod, parDatRegister, parUnitCod) {
		await api
			.get("/image/get", { params: { unitCod: parUnitCod, programCod: 2110, primaryCod: parPrimaryCod, secondaryCod: parSecondaryCod, terciaryCod: parTerciaryCod, datRegister: parDatRegister } })
			.then((response) => {
				setObjectGetImgUploader(response.data.length > 0 ? response.data : {})
			})
			.catch(() => {
				return true;
			});
	}
	//#endregion

	//#region Functions
	const scrollToElement = (item) => {//Scroll analises
		const element = document.getElementById(item);
		if(element !== null) element.scrollIntoView({ behavior: "smooth", block: "start" });
	};

	const onClick = () => {
		//scroll to Top
		const element = document.getElementById("top");
		if(element !== null) {
			element.scrollIntoView(false, { behavior: "smooth", block: "end" });
			window.scrollBy(0, -20);
		}
	};

	const inputAttr = {
		id: "txtLot",
		class: "inputStyle dx-field",
	};

	async function onValueChanged(e) {
		props.setReLoad(false);
		await setNumLot(e.value);
		await props.loadVariableInputs(props.analysisGroupCode);
	}

	function redirectToGroupAnalysis(groupAnalysisCod, groupAnalysName) {
		let analysisGroupCode = groupAnalysisCod
		props.strNameAnalysisGroup.current = groupAnalysName
		props.setReLoad(false)
		props.loadVariableInputs(analysisGroupCode)
		setVisible(false);
	}

	function resultLstVisualizer() {
		const lstVisualizer = [];
		props.lstAllGroupAnalysis.forEach((x) => {
			if(props.processCode == x.CodProcesso) {
				let groupAnalysis = { Name: "", Code: ""};

				groupAnalysis.Name = x.NomAnaliseGrupo;  
				groupAnalysis.Code = x.CodAnaliseGrupo;

				lstVisualizer.push(groupAnalysis);
			}
		})

		return lstVisualizer;
	}
	//#endregion
	
	return (
		<div 
			className="content-block"
		>
			{/* ====================Início Cabeçalho de Grupo de Análise por LOTE========================*/}
			{/* ====================Coloca o campo RESPONSÁVEL, REANÁLISE E OBSERVAÇÃO APENAS NO CABEÇALHO, QUANDO NÃO FOR LOTE É COLOCADO EM CADA LINHA===================*/}
			{props.analysisTimeList.forEach(element => {
				if(element[8].TipUnidadePeriodo === 7)
					_tipPeriodUnit.current = 7;	
				if(element[8].TipUnidadePeriodo === 4)
					_tipPeriodUnit.current = 4;			
			})}
			{props.analysisTimeList[0][9] ? ( //Se for LOTE
				<div className={'top'}>
					<div style={{ float: "left" }}>
						<div className="dx-field-label"  id="top" style={{ width: 180, fontWeight: 'bolder' }}>
							Digitar Número do Lote
						</div>
						<TextBox
							id={"txtLot"}
							inputAttr={inputAttr}
							height={35}
							defaultValue={numLot}
							onValueChanged={onValueChanged}
							style={{ marginRight: 30, display: "inline-block" }}
							stylingMode="underlined"
							width={194}
							onContentReady={(e) => {
								if(!e.component.option("value"))
									e.component.focus();
							}}
						/>
					</div>
					<div>
						<div className="dx-field-label">Lotes do Dia</div>
						<SelectBox
							dataSource={props.listLots}
							valueExpr={"NumLot"}
							displayExpr={"DescLot"}
							style={{ marginRight: 30, display: "inline-block" }}
							name="UserSelectBox"
							onValueChanged={onValueChanged}
							defaultValue={numLot}
							showClearButton={false}
							height={35}
							stylingMode="outlined"
							width={160}
						/>
					</div>

					<div style={{ float: "left", width: props.analysisTimeList[0][9] ? "100%" : isXSmall ? "90%" : "100%", marginTop: 18 }}>
						<AprovedFields
							lstUserDataSource={props.lstUserDataSource}
							userCod={userCod}
							currencyValueDate={props.currencyValueDate}
							_refLabelHour={_refLabelHour}
							_refInput={_refInput}
							_refUserSelectBox={_refUserSelectBox}
							_refObservation={_refObservation}
							_refButtonReAnalysis={_refButtonReAnalysis}
							_refBtnNotWorking={_refBtnNotWorking}
							analysisGroupCode={props.analysisGroupCode}
							indexAnalysis={0}
							index={0}
							analysisTimeList={props.analysisTimeList}
							listAnalysisSample={props.listAnalysisSample}
							isLot={true}
							headerLotRender={true}
							_lstPreviousValue={_lstPreviousValue}
							lstDescObservation={props.lstDescObservation}
							actionOnlyViewer={props.actionOnlyViewer}
							tipPeriodUnit={_tipPeriodUnit.current}
						/>
					</div>
					
					<div style={{ display: "inline-block", marginTop: 30 }}>
						<ImageUploaderInline
							multiple={true}
							numImgs={10}
							objImgFromDataBase={objectGetImgUploader}
							objImgToDataBase={objectImgToDataBase}
							datRegister={dayjs(props.currencyValueDate).format()}
							disabledButton={props.actionOnlyViewer}
							buttonType={false}
							listSelectBox={_listAnalysisToImage.current}
						/>
					</div>
				</div>
			)
			: ""}
			{/* ====================Fim Cabeçalho de Grupo de Análise por LOTE========================*/}

			{/* ====================Início Renderização das Análises========================*/}
			<div 
				id="top" 
				className={!props.analysisTimeList[0][9] ? 'top container-flex' : 'container-flex'}
			>
				<h2 style={{width: !isLarge ? '500px' : 'auto'}}>
					<div className="title-group-analysis">Grupo de Análise</div>
					<b>{props.strNameAnalysisGroup.current}</b>
				</h2>
				<div
					onClick={() => {
						setVisible(!visible);
					}} 
				>
					<div className="container-image-uploader" >
						<label 
							className={"button-group analysis-popup-navigation"}
						>
							{isLarge ? 'Selecionar Outro Grupo' : 'Outro Grupo'}
						</label>
					</div>
				</div> 
				<span className={`container-navigation-analysis ${isMedium ? 'height-container-navigation-analysis-medium' : 'height-container-navigation-analysis'}`}>
					<div>
						<div className="dx-field-label" style={{width: "100%", display: isMedium || isLarge ? "" : 'none'}}>S/A: Sem análise</div>
						<div className="dx-field-label" style={{width: "100%", display: isMedium || isLarge ? "" : 'none'}}>Análise:</div>
						{isLarge || isMedium 
						? 	props.analysisTimeList.map((value, indexAnalysis ) => {
								return value.map((valueItem, index) => {
									if (index === 0) {
										return (
											<div 
												className="text-navigation-analysis"
												key={`analysis-${indexAnalysis}`}
												onClick={() => scrollToItem(indexAnalysis)}
											>
												{isMedium ? props.analysisTimeList[indexAnalysis][0].substr(0, 20) : props.analysisTimeList[indexAnalysis][0]}
											</div>
										)
									}
								})
							})
						: ''}
					</div>
				</span>
			</div>
			{visible
				? <PopupListVisualizer
					visible={visible} 
					lstPage={_.orderBy(_lstVisualizer.current, "Name")}
					redirectToPage={redirectToGroupAnalysis}
					strPageSelected={props.strNameAnalysisGroup.current}
				   />
				: ""
			}
			{!props.analysisTimeList[0][9] ? ( //Se não for LOTE
				<div className={"button-image"}>
					<ImageUploaderInline
						multiple={true}
						numImgs={10}
						objImgFromDataBase={objectGetImgUploader}
						objImgToDataBase={objectImgToDataBase}
						datRegister={dayjs(props.currencyValueDate).format()}
						disabledButton={props.actionOnlyViewer}
						buttonType={false}
						listSelectBox={_listAnalysisToImage.current}
					/>
				</div> 
			)
			: ""}

			{/* ===============Percorre cada análise do Grupo de Análise========================*/}
			{props.analysisTimeList.map((value, indexAnalysis ) =>
				// eslint-disable-next-line array-callback-return
				value.map((valueItem, index) => {
					//index 0=>Nome Analise  1=>Tipo Verificacao  2=>Unidade Analise  3=>Valor Minimo  4=>Valor Maximo  5=>Horas < Hora Atual  6=>Horas da Análise
					//Título da Amostra
					if (index === 0) {
						_refAnchor.current[indexAnalysis] = valueItem;
						return (
							<section id={`${valueItem} ${props.analysisTimeList[indexAnalysis][0]}`} 
								ref={(ref) => (_itemRef.current[indexAnalysis] = ref)}
							>
								<div className="container-title-and-reanylisis-button">
									<div className="dx-field-label sample-title" >{valueItem}</div>
									<label 
										className="dx-field-label"
										onClick={(e) => {
											_codAnalysis.current = props.analysisTimeList[indexAnalysis][8].CodAnalise;
											_isLot.current = props.analysisTimeList[indexAnalysis][9];
											_nameAnalysis.current = props.analysisTimeList[indexAnalysis][0];
											setVisibleReanalysisPopup(!visibleReanalysisPopup);
										}}
										style={{cursor: "pointer", visibility: props.listAnalysisSample.findIndex(x => x.CodAnalise === props.analysisTimeList[indexAnalysis][8].CodAnalise 
																							   && x.NumReanalise > 0 
																							   && (props.analysisTimeList[indexAnalysis][9] 
																								? x.NumLote === numLot 
																								: x.NumLote === null)) > -1 
																								? 'visible' 
																								: 'hidden'}}
									>
										Ver Reanálises
									</label>
								</div>

								<div className={"btnScrollBackground"} style={{ marginLeft: 400, height: 0, visibility: "hidden" /*props.analysisTimeList[indexAnalysis][9] ? "hidden" : "visible"*/}}>
									<Button
										visible={indexAnalysis - 1 > -1}
										type={"default"}
										className={"btnScroll"}
										hint={"Análise anterior"}
										onClick={() => {
											scrollToElement(_refAnchor.current[indexAnalysis - 1])
										}}
										icon="arrowup">
									</Button>
									<Button
										visible={indexAnalysis + 1 < props.analysisTimeList.length}
										type={"default"}
										className={"btnScroll"}
										hint={"Próxima análise"}
										onClick={() => {
											scrollToElement(_refAnchor.current[indexAnalysis + 1])

										}}
										icon="arrowdown">
									</Button>
								</div>
							</section>
						);
					}

					//Subtítulo da Amostra
					if (index === 1) {
						if (valueItem === 1)
							subTitle = `Intervalo: ${props.analysisTimeList[indexAnalysis][3]} a ${props.analysisTimeList[indexAnalysis][4]} ${props.analysisTimeList[indexAnalysis][2]}`;
						if (valueItem === 2)
							subTitle = `Mínimo: ${props.analysisTimeList[indexAnalysis][3]} ${props.analysisTimeList[indexAnalysis][2]}`;
						if (valueItem === 3)
							subTitle = `Máximo: ${props.analysisTimeList[indexAnalysis][4]} ${props.analysisTimeList[indexAnalysis][2]}`;
						if (valueItem === 20) subTitle = `Negativo (${props.analysisTimeList[indexAnalysis][2]})`;
						if (valueItem === 0) subTitle = `Positivo (${props.analysisTimeList[indexAnalysis][2]})`;
						if (valueItem === 4) subTitle = `Unidade: ${props.analysisTimeList[indexAnalysis][2]}`;
						return (
							<section style={{width: isXSmall ? '50vw' : '100vw'}}>
								<div
									className="dx-field-label sample-subtitle"
									style={{
										maxWidth: 360,
										marginBottom: props.analysisTimeList[indexAnalysis][9] === true ? 0 : 10,
										float: props.analysisTimeList[indexAnalysis][9] === true ? "left" : "",
									}}>
									{subTitle}
								</div>	
								<div
									style={{
										display:
											props.analysisTimeList[indexAnalysis][6].length > 0 && props.analysisTimeList[indexAnalysis][9] === false
												? "block"
												: "none",
										color: "rgb(244 67 54 / 80%)",
										marginBottom: "20px",
									}}>
									Existe registro de paradas. Não possível alterar algumas amostras.
								</div>
							</section>
						);
					}

					//Lista das horas e inputs
					if (index === 7) {
						//Positivo/Negativo ou Negativo
						if (props.analysisTimeList[indexAnalysis][1] === 0 || props.analysisTimeList[indexAnalysis][1] === 20) {
							return valueItem.map((sampleHour, index) => {
								return (
									<div className="dx-field margin-hierarchy">
										{((numLot !== undefined && numLot !== "" && props.analysisTimeList[indexAnalysis][9])) || (!props.analysisTimeList[indexAnalysis][9] && props.analysisTimeList[indexAnalysis][8].TipUnidadePeriodo !== 7 && props.analysisTimeList[indexAnalysis][8].TipUnidadePeriodo !== 4)?
											<LabelHour
												indexAnalysis={indexAnalysis}
												index={index}
												_refLabelHour={_refLabelHour}
												sampleHour={sampleHour}
												isLot={props.analysisTimeList[indexAnalysis][9]}
												numLot={numLot}
											/>
											: ""}
										{(numLot !== undefined && numLot !== "" && props.analysisTimeList[indexAnalysis][9]) || !props.analysisTimeList[indexAnalysis][9] ?
											<PositiveNegativeInput
												indexAnalysis={indexAnalysis}
												index={index}
												currencyValueDate={props.currencyValueDate}
												analysisGroupCode={props.analysisGroupCode}
												_refInput={_refInput}
												_refLabelHour={_refLabelHour}
												_refUserSelectBox={_refUserSelectBox}
												_refObservation={_refObservation}
												_refButtonReAnalysis={_refButtonReAnalysis}
												_refBtnNotWorking={_refBtnNotWorking}
												analysisTimeList={props.analysisTimeList}
												listAnalysisSample={props.listAnalysisSample}
												sampleHour={sampleHour}
												userCod={userCod}
												isLot={props.analysisTimeList[indexAnalysis][9]}
												headerLotRender={false}
												listGroupAnalysis={props.listGroupAnalysis}
												lstDescObservation={props.lstDescObservation}
												numLot={numLot}
												_lstPreviousValue={_lstPreviousValue}
												actionOnlyViewer={props.actionOnlyViewer}
												tipPeriodUnit={props.analysisTimeList[indexAnalysis][8].TipUnidadePeriodo}
												codAnalysis={props.analysisTimeList[indexAnalysis][8].CodAnalise}
												currentDate={currentDate}
												setCurrentDate={setCurrentDate}
											/>
										: ""}
										{(!props.analysisTimeList[indexAnalysis][9] ? //Se for lote não precisa renderizar novamente
											<AprovedFields
												lstUserDataSource={props.lstUserDataSource}
												indexAnalysis={indexAnalysis}
												index={index}
												currencyValueDate={props.currencyValueDate}
												analysisGroupCode={props.analysisGroupCode}
												userCod={userCod}
												_refLabelHour={_refLabelHour}
												_refInput={_refInput}
												_refUserSelectBox={_refUserSelectBox}
												_refObservation={_refObservation}
												_refBtnNotWorking={_refBtnNotWorking}
												_refButtonReAnalysis={_refButtonReAnalysis}
												analysisTimeList={props.analysisTimeList}
												listAnalysisSample={props.listAnalysisSample}
												sampleHour={sampleHour}
												isLot={props.analysisTimeList[indexAnalysis][9]}
												headerLotRender={false}
												lstDescObservation={props.lstDescObservation}
												_lstPreviousValue={_lstPreviousValue}
												actionOnlyViewer={props.actionOnlyViewer}
												tipPeriodUnit={props.analysisTimeList[indexAnalysis][8].TipUnidadePeriodo}
												codAnalysis={props.analysisTimeList[indexAnalysis][8].CodAnalise}
											/>
											: "")}
									</div>
								);
							});
						} //Inputs Numéricos
						else
							return valueItem.map((sampleHour, index) => {
								return (
									<div className="dx-field margin-hierarchy">
										{(numLot !== undefined && numLot !== "" && props.analysisTimeList[indexAnalysis][9]) || (!props.analysisTimeList[indexAnalysis][9] && props.analysisTimeList[indexAnalysis][8].TipUnidadePeriodo !== 7 && props.analysisTimeList[indexAnalysis][8].TipUnidadePeriodo !== 4) ?
											<LabelHour
												indexAnalysis={indexAnalysis}
												index={index}
												_refLabelHour={_refLabelHour}
												sampleHour={sampleHour}
												isLot={props.analysisTimeList[indexAnalysis][9]}
												numLot={numLot}
											/>
										: ""}
										{(numLot !== undefined && numLot !== "" && props.analysisTimeList[indexAnalysis][9]) || !props.analysisTimeList[indexAnalysis][9] ?
											<NumericInput
												indexAnalysis={indexAnalysis}
												index={index}
												currencyValueDate={props.currencyValueDate}
												analysisGroupCode={props.analysisGroupCode}
												_refInput={_refInput}
												_refLabelHour={_refLabelHour}
												_refUserSelectBox={_refUserSelectBox}
												_refObservation={_refObservation}
												_refBtnNotWorking={_refBtnNotWorking}
												_refButtonReAnalysis={_refButtonReAnalysis}
												analysisTimeList={props.analysisTimeList}
												listAnalysisSample={props.listAnalysisSample}
												numLot={numLot}
												userCod={userCod}
												sampleHour={sampleHour}
												isLot={props.analysisTimeList[indexAnalysis][9]}
												headerLotRender={false}
												lstDescObservation={props.lstDescObservation}
												setReLoad={props.setReLoad}
												loadVariableInputs={props.loadVariableInputs}
												_lstPreviousValue={_lstPreviousValue}
												actionOnlyViewer={props.actionOnlyViewer}
												tipPeriodUnit={props.analysisTimeList[indexAnalysis][9] && (_tipPeriodUnit.current === 7 || _tipPeriodUnit.current === 4) ? _tipPeriodUnit.current : props.analysisTimeList[indexAnalysis][8].TipUnidadePeriodo}
												codAnalysis={props.analysisTimeList[indexAnalysis][8].CodAnalise}
												currentDate={currentDate}
												setCurrentDate={setCurrentDate}
											/>
										: ""}
										{(!props.analysisTimeList[indexAnalysis][9] ? //Se for lote não precisa renderizar novamente
											<AprovedFields
												lstUserDataSource={props.lstUserDataSource}
												indexAnalysis={indexAnalysis}
												index={index}
												analysisGroupCode={props.analysisGroupCode}
												currencyValueDate={props.currencyValueDate}
												userCod={userCod}
												_refLabelHour={_refLabelHour}
												_refInput={_refInput}
												_refUserSelectBox={_refUserSelectBox}
												_refObservation={_refObservation}
												_refBtnNotWorking={_refBtnNotWorking}
												_refButtonReAnalysis={_refButtonReAnalysis}
												analysisTimeList={props.analysisTimeList}
												listAnalysisSample={props.listAnalysisSample}
												sampleHour={sampleHour}
												isLot={props.analysisTimeList[indexAnalysis][9]}
												headerLotRender={false}
												lstDescObservation={props.lstDescObservation}
												_lstPreviousValue={_lstPreviousValue}
												actionOnlyViewer={props.actionOnlyViewer}
												tipPeriodUnit={props.analysisTimeList[indexAnalysis][8].TipUnidadePeriodo}
												codAnalysis={props.analysisTimeList[indexAnalysis][8].CodAnalise}
											/>
										: "")}
									</div>
								);
							});
					}
				})
			)}
			{/* ====================Fim Renderização das Análises========================*/}

			<PopupReanalysis 
				visibleReanalysisPopup={visibleReanalysisPopup}
				setVisibleReanalysisPopup={setVisibleReanalysisPopup}
				codAnalysis={_codAnalysis.current}
				currencyValueDate={props.currencyValueDate}
				unitCod={unitCod}
				isLot={_isLot.current}
				numLot={numLot}
				nameAnalysis={_nameAnalysis.current}
			/>
			<SpeedDialAction
				icon="arrowup"
				//label="Add row"
				index={1}
				onClick={onClick}
			/>
		</div>
	);
};
