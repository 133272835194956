/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "../../components/common/style.scss";
import "devextreme/data/odata/store";
import { LogMessageGrid } from "../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Editing,
  FilterRow,
  Form,
  Grouping,
  GroupPanel,
  Popup,
  Position,
  Lookup,
  Pager,
  Paging,
  RequiredRule, Item, Toolbar,
} from "devextreme-react/data-grid";
import { api } from "../../services/api";
import { useLoading } from "../../contexts/loadingContext";
import { useAuth } from "../../contexts/auth";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { SimpleItem } from "devextreme-react/form";
import Button from "devextreme-react/button";

export default function Subtopic(props) {
  const { loading } = useLoading();
  const [load, setLoad] = useState(false);
  const [subtopic, setSubtopic] = useState([]);
  const [topic, setTopic] = useState([]);
  const _subtopic = useRef();

  const { unitCod, refreshToken, getRefreshTokenCode, getToken, tipAdministrator } = useAuth();

  const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
  const fontValues = [
    "Arial",
    "Georgia",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ];
  const fontFormatOptions = { placeholder: "Fonte", width: 100 };
  const sizeFormatOptions = { placeholder: "Tamanho", width: 100 };

  useEffect(() => {
    refreshToken(getToken(), getRefreshTokenCode());
  }, []);

  useEffect(() => {
    loadData();
    loadTopics();
  }, [props.update]);

  useEffect(() => {
    loadData();
  }, [load]);

  async function loadData() {
    await api
      .get("quickguidessubtopic", { params: { unitCod: unitCod } })
      .then((response) => {
        let temp = [];
        topic.forEach((topic) => {
          response.data.forEach((subtopic) => {
            if(topic.CodGuiaRapidoTopico === subtopic.CodGuiaRapidoTopico) {
              temp.push(subtopic)
            }
          })
        })
        setSubtopic(temp);
      })
      .catch(() => {
        return true;
      });
  }

  async function loadTopics() {
    await api
      .get("quickguidestopic", { params: { unitCod: unitCod } })
      .then((response) => {
        if(tipAdministrator) {
          setTopic(response.data);
        } else {
          let temp = []
          response.data.forEach((topic) => {
            if(topic.TipAdministrador === tipAdministrator){
              temp.push(topic)
            }
          })
          setTopic(temp)
        }
        setLoad(!load)
      })
      .catch(() => {
        return true;
      });
  }

  async function updateRow(data, parLogMessage) {
    return await api
      .put("quickguidessubtopic", {
        unitCod: unitCod,
        data: data,
        logMessage: parLogMessage,
      })
      .then((response) => {
        setLoad(!load);
        return false;
      })
      .catch(() => {
        return true;
      });
  }

  async function insertRow(data, parLogMessage) {
    return await api
      .post("quickguidessubtopic", {
        unitCod: unitCod,
        data: data,
        logMessage: parLogMessage,
      })
      .then((response) => {
        setLoad(!load);
        return false;
      })
      .catch(() => {
        return true;
      });
  }

  async function deleteRow(data) {
    return await api
      .delete("quickguidessubtopic", { data: data })
      .then(() => {
        setLoad(!load);
        return false;
      })
      .catch(() => {
        return true;
      });
  }

  const editorOptions = {
    multiline: true,
    mediaResizing: { enabled: true },
    height: 500,
    toolbar: {
      items: [
        {
          name: "font",
          acceptedValues: fontValues,
          options: fontFormatOptions,
        },
        {
          name: "size",
          acceptedValues: sizeValues,
          options: sizeFormatOptions,
        },
        { name: "undo", options: { hint: "Desfazer" }},
        { name: "redo", options: { hint: "Refazer" }},
        { name: "separator" },
        { name: "bold", options: { hint: "Negrito" }},
        { name: "italic", options: { hint: "Itálico" } },
        { name: "strike", options: { hint: "Tachado" } },
        { name: "underline", options: { hint: "Sublinhado" } },
        { name: "color", options: { hint: "Cor da fonte" } },
        { name: "separator" },
        { name: "alignLeft", options: { hint: "Alinhar texto à esquerda" } },
        { name: "alignCenter", options: { hint: "Centralizar" } },
        { name: "alignRight", options: { hint: "Alinhar texto à direita" } },
        { name: "alignJustify", options: { hint: "Justificar" } },
        { name: "separator" },
        { name: "link", options: { hint: "Hiperlink" } },
        { name: "image", options: { hint: "Inserir imagem" } },
        { name: "separator" },
        { name: "insertTable", options: { hint: "Inserir tabela" } },
        { name: "deleteTable", options: { hint: "Deletar tabela" } },
        { name: "insertRowAbove", options: { hint: "Inserir linha acima" } },
        { name: "insertRowBelow", options: { hint: "Inserir linha abaixo" } },
        { name: "deleteRow", options: { hint: "Deletar linha" } },
        { name: "insertColumnLeft", options: { hint: "Inserir coluna à esquerda" } },
        { name: "insertColumnRight", options: { hint: "Inserir coluna à direita" }},
        { name: "deleteColumn", options: { hint: "Deletar coluna" }}
      ],
    },
  };
  return (
    <React.Fragment>
      <DataGrid
        className={"dx-cards"}
        dataSource={subtopic}
        rowAlternationEnabled={true}
        showColumnLines={true}
        allowColumnReordering={true}
        showBorders={false}
        columnHidingEnabled={true}
        repaintChangesOnly={true}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: false }}
        onRowUpdating={async (e) => {
          const newObj = { ...e.oldData, ...e.newData };
          let strLogMessage = LogMessageGrid(
            formatMessage("UpdatedLog"),
            _subtopic,
            newObj
          );

          e.cancel = updateRow(newObj, strLogMessage);

          e.component.deselectAll();
        }}
        onRowInserting={async (e) => {
          let strLogMessage = LogMessageGrid(
            formatMessage("InsertedLog"),
            _subtopic,
            e.data
          );

          e.cancel = insertRow(e.data, strLogMessage);

          e.component.deselectAll();
        }}
        onRowRemoving={(e) => {
          let strLogMessage = LogMessageGrid(
            formatMessage("DeletedLog"),
            _subtopic,
            e.data
          );
          const data = {
            unitCod: unitCod,
            data: e.data,
            logMessage: strLogMessage,
          };

          e.cancel = deleteRow(data);

          e.component.deselectAll();
        }}
        ref={_subtopic}
      >
        <Editing mode="popup" allowUpdating={true} allowDeleting={true} allowAdding={false}>
          <Popup fullScreen={true}>
            <Position my="center" at="center" of={window} />
          </Popup>
          <Form colCount={5}>
            <SimpleItem colSpan={2} dataField="DesTitulo" />
            <SimpleItem colSpan={2} dataField="CodGuiaRapidoTopico" />
            <SimpleItem colSpan={1} dataField="NumOrdem" />
            <SimpleItem
              colSpan={5}
              dataField="DesTexto"
              editorType="dxHtmlEditor"
              name={""}
              editorOptions={editorOptions}
            />
          </Form>
        </Editing>
        <Grouping
          contextMenuEnabled={false}
          expandMode="rowClick"
          autoExpandAll={false}
        />
        <GroupPanel visible={false} />
        <ColumnChooser enabled={true} mode="select" />
        <ColumnFixing enabled={false} />
        <Paging defaultPageSize={20} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <Toolbar >
          <Item cssClass={'add-container'}>
            <Button className={'add-button'}  icon={"add"} onClick={() => (_subtopic.current.instance.addRow())}/>
          </Item>
          <Item name="saveButton"/>
          <Item name="revertButton"/>
          <Item name="exportButton"/>
          <Item name="columnChooserButton"/>
        </Toolbar>
        <Column
          dataField={"CodGuiaRapidoTopico"}
          caption={"Tópico"}
          allowSorting={true}
          allowFiltering={true}
          allowSearch={true}
          allowGrouping={true}
          allowEditing={true}
        >
          <Lookup
            dataSource={topic}
            displayExpr={(topic) => `${topic.NomTopico}`}
            valueExpr="CodGuiaRapidoTopico"
          />
          <RequiredRule />
        </Column>
        <Column
          dataField={"DesTitulo"}
          caption={"Título"}
          allowSorting={true}
          allowFiltering={true}
          allowSearch={true}
          allowGrouping={true}
          allowEditing={true}
        >
          <RequiredRule />
        </Column>
        <Column
            dataField={"NumOrdem"}
            caption={"Ordem"}
            allowSorting={true}
            allowFiltering={true}
            allowSearch={true}
            allowGrouping={true}
            allowEditing={true}
        >
          <RequiredRule />
        </Column>
        <Column
            dataField={"DesTexto"}
            caption={"Texto"}
            visible={false}
            allowSorting={true}
            allowFiltering={true}
            allowSearch={true}
            allowGrouping={true}
            allowEditing={true}
        >
          <RequiredRule />
        </Column>
      </DataGrid>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
