/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react"
import dayjs from "dayjs"
import { CheckBox } from "devextreme-react"
import { NumberBox, Button as NumberBoxButton } from "devextreme-react/number-box"
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box"
import { useAuth } from "../../../contexts/auth"
import { useDataLoader } from "../../../contexts/dataLoaderContext"
import "./../style.scss"
import { useScreenSize } from "../../../utils/media-query"
import VariableLegend from "../../../components/variable-legend/variable-legend"
import chevronDoubleDown from "../../../icons/chevronDoubleDown.svg"
import chevronDoubleUp from "../../../icons/chevronDoubleUp.svg"
import chevronSingleDown from "../../../icons/chevronSingleDown.svg"
import chevronSingleUp from "../../../icons/chevronSingleUp.svg"
import sucessBlackWhite from "../../../icons/sucessBlackWhite.svg"
import sucessGreen from "../../../icons/sucessGreen.svg"
import { AddItemsObjByVariable } from "./addItemsObjByVariable"
import { ObjSaveManualVariables } from "./objSaveManualVariables"
import { SpeedDialAction } from "devextreme-react/speed-dial-action"

// eslint-disable-next-line import/no-anonymous-default-export
export default function NumericInputVariable(props) {
  const { isSmall, isXSmall, isXLSmall, isMedium, isLarge } = useScreenSize()
  let _refInputVariable = useRef([])
  let _refInputVariablePrevious = useRef([])
  let _refChbNotWorking = useRef([])
  let _lstPreviousValue = useRef([])
  const { userCod } = useAuth()
  const { _lstInputVariableSave, setSaveHeaderTextBox, saveHeaderTextBox, setChangedDateHeader, changedDateHeader } = useDataLoader()
  const paramsVariable = props.lstVariable[0]
  const intCountVariableLst = props.lstVariable[1].length - 1

  useEffect(() => {
    if (saveHeaderTextBox || changedDateHeader) {
      //Reload quando salva
      props.setReLoad(false)
      props.loadVariableInputs(props.VariableCode)
      setChangedDateHeader(false)
    }
    if (saveHeaderTextBox) setSaveHeaderTextBox(false)
  }, [saveHeaderTextBox, props.currencyValueDate])

  //#region icon / styles
  const iconHiHi = {
    icon: chevronDoubleUp,
    visible: true,
    hint: "Muito Alto"
  }

  const iconHi = {
    icon: chevronSingleUp,
    visible: true,
    hint: "Alto"
  }

  const iconIdeal = {
    icon: sucessGreen,
    visible: true,
    hint: "Valor Ideal!"
  }

  const iconOk = {
    icon: sucessBlackWhite,
    visible: true,
    hint: "Valor dentro do range"
  }

  const iconLo = {
    icon: chevronSingleDown,
    visible: true,
    hint: "Baixo"
  }

  const iconLoLo = {
    icon: chevronDoubleDown,
    visible: true,
    hint: "Muito Baixo"
  }

  const noIcon = {
    visible: false
  }

  const optionsValueIcon = {
    stylingMode: "text",
    focusStateEnabled: false,
    type: "default",
    visible: false,
    width: 36,
    height: 36
  }

  const enabledInput = {
    style: "background-color: white; opacity: 1; height: 42px; margin-top: 4px"
  }

  const disabledInput = {
    style: "background-color: #eee; opacity: 0.4; height: 42px; margin-top: 4px"
  }

  const notWorkingInput = {
    style: "background-color: #white; opacity: 0.4; height: 42px; margin-top: 4px"
  }
  //#endregion

  //#region events
  const onValueChangedChbNotWorking = e => {
    _refInputVariable.current[e.element.id].instance.option("disabled", e.value)

    if (e.value) {
      _lstPreviousValue.current[e.element.id] = _refInputVariablePrevious.current[e.element.id].instance.option("value")
      _refInputVariable.current[e.element.id].instance.option("value", 0)
    } else {
      let previousValue = _lstPreviousValue.current[e.element.id]
      _refInputVariable.current[e.element.id].instance.option("value", previousValue)
    }
  }

  const onValueChanged = e => {
    let valueCheckNotWorking = _refChbNotWorking.current[e.element.id].instance.option("value")
    let dateInputVariable = _refInputVariable.current[e.element.id].instance.option("date")
    let dateBoxValue = dayjs(props.refHeaderDateBoxValue.instance.option("value")).format("YYYY-MM-DD")
    let dateAuxDay = dayjs(dateInputVariable).format("YYYY-MM-DD")

    if (dateAuxDay === "1900-01-01") {
      //*Se no Aplicacão o "Mostrar Variavel" for false
      dateInputVariable = `${dateBoxValue}T${dayjs(dateInputVariable).format("LT")}`
    }

    //Se parametro bloqueado = sim na aplicação e se não é o último input, para não quebrar
    if (props.booBlockVariables) {
      if (intCountVariableLst > e.element.id) {
        let intEnabledNextInput = Number(e.element.id) + 1

        if (!_refChbNotWorking.current[intEnabledNextInput].instance.option("value")) {
          _refInputVariable.current[intEnabledNextInput].instance.option("disabled", false)
          _refInputVariable.current[intEnabledNextInput].instance.option("elementAttr", enabledInput)
        }
      }
    }

    //Desabilita input conforme Checkbox (Parado?)
    _refInputVariable.current[e.element.id].instance.option("disabled", valueCheckNotWorking)
    _refInputVariable.current[e.element.id].instance.option("elementAttr", valueCheckNotWorking ? notWorkingInput : enabledInput)

    if (paramsVariable.TypeVariable !== 1) valueComparasion(e, valueCheckNotWorking)

    const newObj = ObjSaveManualVariables(
      props.lstVariable[0],
      props.lstVariable[1],
      dateInputVariable,
      e.value,
      !valueCheckNotWorking,
      userCod,
      props.VariableCode,
      e.previousValue,
      paramsVariable.TypeVariable
    )

    AddItemsObjByVariable(newObj, _lstInputVariableSave.current)
  }

  const valueComparasion = (e, parValueCheckNotWorking) => {
    const numberBox = e.component.instance().getButton("checkValue")
    let dblValue = e.component === null ? null : e.component.option("value")

    if (numberBox !== null) {
      //Campos acima da data atual ficam invisiveis
      if (dblValue === null || dblValue === "" || parValueCheckNotWorking) numberBox.option(noIcon)
      else if (dblValue > paramsVariable.HiHi) numberBox.option(iconHiHi)
      else if (dblValue <= paramsVariable.HiHi && dblValue > paramsVariable.Hi) numberBox.option(iconHi)
      else if (dblValue === paramsVariable.Ideal) numberBox.option(iconIdeal)
      else if (dblValue <= paramsVariable.Hi && dblValue >= paramsVariable.Lo) numberBox.option(iconOk)
      else if (dblValue <= paramsVariable.Lo && dblValue >= paramsVariable.LoLo) numberBox.option(iconLo)
      else if (dblValue < paramsVariable.LoLo) numberBox.option(iconLoLo)
    }
  }

  const scrollToElement = (item) => {//Scroll analises
		const element = document.getElementsByClassName(item)[0];
		if(element !== null) element.scrollIntoView({ behavior: "smooth", block: "start" });
	};
  //#endregion

  return (
    <div>
      <div className="scroll-to-top" />
      <div className="content-block">
        <section>
          <h2 style={{marginTop: 60, marginBottom: -10}}>
            <div style={{ fontSize: "14px", color: "gray", marginLeft: "0px", marginBottom: "-10px" }}>Processo</div>
            <div style={{ fontSize: "22px"}}>{`${props.processName}`}</div>
          </h2>
          {/* Nome e descrição das variáveis */}
          <div id={"top"} style={{ float: "left", marginLeft: isXSmall || isXLSmall ? 0 : 20  }}>
            <h2 style={{ fontWeight: "bold", marginBottom: -10 }}>{`${props.lstVariable[0].Name} (${props.lstVariable[0].Unit})`}</h2>
            <div style={{ fontSize: "14px", color: "gray", marginLeft: "0px", marginBottom: 20 }}>{props.lstVariable[0].Desc}</div>
          </div>
          <div
            style={{
              position: "absolute",
              visibility: isXSmall || isXLSmall ? "hidden" : "visible",
              left: isXLSmall || isSmall ? "80%" : isXSmall ? "67%" : "90%",
              width: "130px"
            }}
          >
            <VariableLegend paramsVariable={props.lstVariable[0]} />
          </div>
        </section>
        {props.lstVariable[1].map((items, index) => (
          <section key={index}>
            <div style={{ width: "100%", float: "left" }}>
              <section style={{ float: "left", width: 50, marginLeft: isXSmall || isXLSmall ? 0 : 20 }}>
                <div style={{ width: "100%" }} className="dx-field-label textbox-font-title label-hour">
                  {dayjs(items.Date).format("HH:mm")}
                </div>
              </section>
              <section style={{ float: "left", width: isLarge || isMedium ? 160 : 120, marginLeft: isXSmall || isXLSmall ? 0 : 20 }}>
                <div style={{ width: "100%", visibility: index > 0 ? "hidden" : "visible" }} className="dx-field-label textbox-font-title">
                  Valor Amostra
                </div>
                <div>
                  {/*Dependendendo do tip_variavel_leitura da tabela Variavel.Leitura, muda o componente de input*/}
                  {paramsVariable.TypeVariable === 1 ? ( //1=Bit, 2=Numerico, 3-AlfaNumerico, 4-Data
                    <CheckBox
                      height={42}
                      stylingMode="outlined"
                      className={"checkbox-large"}
                      tabIndex={-1}
                      style={{ marginTop: 4 }}
                      id={index}
                      onInitialized={e => {
                        e.component.option("value", items.Value === 1 ? true : items.Value === 0 ? false : null)
                        e.component.option("date", items.Date)
                        e.component.option("disabled", items.TipWorking === null ? false : !items.TipWorking)
                        //Bloqueia inputs maiores que a próxima atualização
                        if (items.Date > props.lstVariable[0].NextUpdate && props.lstVariable[0].BlockVariableInput) {
                          e.component.option("disabled", true)
                          e.component.option("value", null)
                          e.component.option("elementAttr", disabledInput)
                        }
                      }}
                      onValueChanged={onValueChanged}
                      visible={
                        //Se a data for maior que a data atual não aparece o input
                        dayjs(items.Date).set("hour", dayjs(items.Date).hour()).set("minute", dayjs(items.Date).minute()).format() < dayjs().format()
                      }
                      ref={node => {
                        _refInputVariable.current[index] = node
                        _refInputVariablePrevious.current[index] = node
                      }}
                    />
                  ) : paramsVariable.TypeVariable === 2 ? ( //1=Bit, 2=Numerico, 3-AlfaNumerico, 4-Data
                    <NumberBox
                      height={42}

                      stylingMode="outlined"
                      className={"textbox-font-editor"}
                      style={{ marginTop: 4 }}
                      defaultValue={""}
                      //format={{ type: "fixedPoint", precision:2 }}
                      id={index}
                      onInitialized={e => {
                        e.component.option("value", items.Value)
                        e.component.option("date", items.Date)
                        e.component.option("disabled", items.TipWorking === null ? false : !items.TipWorking)
                        //Bloqueia inputs maiores que a próxima atualização
                        if (items.Date > props.lstVariable[0].NextUpdate && props.lstVariable[0].BlockVariableInput) {
                          e.component.option("disabled", true)
                          e.component.option("value", null)
                          e.component.option("elementAttr", disabledInput)
                        }
                      }}
                      onValueChanged={onValueChanged}
                      visible={
                        //Se a data for maior que a data atual não aparece o input
                        dayjs(items.Date).set("hour", dayjs(items.Date).hour()).set("minute", dayjs(items.Date).minute()).format() < dayjs().format()
                      }
                      ref={node => {
                        _refInputVariable.current[index] = node
                        _refInputVariablePrevious.current[index] = node
                      }}
                      onOptionChanged={e => {
                        if (e.name === "text") valueComparasion(e, items.TipWorking === null ? false : !items.TipWorking)
                        /*if (e.component.instance().option("readOnly")) {
                        e.element.parentElement.style.backgroundColor = "rgb(244 67 54 / 19%)";
                        e.element.parentElement.style.opacity = "0.6";
                      }*/
                      }}
                    >
                      <NumberBoxButton name="checkValue" location="after" options={optionsValueIcon} />
                    </NumberBox>
                  ) : (
                    <TextBox
                      height={42}
                      stylingMode="outlined"
                      className={"textbox-font-editor"}
                      style={{ marginTop: 4 }}
                      defaultValue={""}
                      id={index}
                      onInitialized={e => {
                        e.component.option("value", items.Value)
                        e.component.option("date", items.Date)
                        e.component.option("disabled", items.TipWorking === null ? false : !items.TipWorking)
                        //Bloqueia inputs maiores que a próxima atualização
                        if (items.Date > props.lstVariable[0].NextUpdate && props.lstVariable[0].BlockVariableInput) {
                          e.component.option("disabled", true)
                          e.component.option("value", null)
                          e.component.option("elementAttr", disabledInput)
                        }
                      }}
                      onValueChanged={onValueChanged}
                      visible={
                        //Se a data for maior que a data atual não aparece o input
                        dayjs(items.Date).set("hour", dayjs(items.Date).hour()).set("minute", dayjs(items.Date).minute()).format() < dayjs().format()
                      }
                      ref={node => {
                        _refInputVariable.current[index] = node
                        _refInputVariablePrevious.current[index] = node
                      }}
                      onOptionChanged={e => {
                        if (e.name === "text") valueComparasion(e, items.TipWorking === null ? false : !items.TipWorking)
                        /*if (e.component.instance().option("readOnly")) {
                      e.element.parentElement.style.backgroundColor = "rgb(244 67 54 / 19%)";
                      e.element.parentElement.style.opacity = "0.6";
                    }*/
                      }}
                    >
                      <TextBoxButton name="checkValue" location="after" options={optionsValueIcon} />
                    </TextBox>
                  )}
                </div>
              </section>
              <section style={{ float: "left", width: isXSmall ? 100 : 200 }}>
                <div
                  className="dx-field-label textbox-font-title"
                  style={{
                    marginLeft: isLarge ? 26 : 16,
                    visibility: index > 0 ? "hidden" : "visible",
                    width: 100
                  }}
                >
                  Parado?
                </div>
                <div
                  id={"chbNotWorking"}
                  style={{
                    marginTop: 12,
                    float: "left",
                    marginLeft: isSmall || isXSmall ? "22px" : isXLSmall ? "18px" : isMedium ? "23px" : "32px"
                  }}
                >
                  <CheckBox
                    name="chbNotWorking"
                    stylingMode="outlined"
                    className={"checkbox-large"}
                    tabIndex={-1}
                    onInitialized={e => {
                      e.component.option("value", items.TipWorking === null ? false : !items.TipWorking)
                    }}
                    visible={
                      //Se a data for maior que a data atual não aparece o input
                      dayjs(items.Date).set("hour", dayjs(items.Date).hour()).set("minute", dayjs(items.Date).minute()).format() < dayjs().format()
                    }
                    onValueChanged={e => onValueChangedChbNotWorking(e)}
                    id={index}
                    hint={"Marque se não foi possível fazer a leitura"}
                    ref={node => {
                      _refChbNotWorking.current[index] = node
                    }}
                  />
                </div>
              </section>
            </div>
          </section>
        ))}
        <SpeedDialAction icon="arrowup" index={1} onClick={() => scrollToElement('scroll-to-top')} />
      </div>
    </div>
  )
}
