/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Toolbar, { Item } from 'devextreme-react/toolbar'
import Button from 'devextreme-react/button'
import UserPanel from '../user-panel/user-panel'
import PopupNotification from '../../pages/notifications/popupNotification'
import './header.scss'
import { useNavigation } from '../../contexts/navigation'
import bell from '../../icons/grayBell.svg'
import bellRed from '../../icons/bellRed.svg'
import { api } from '../../services/api'
import { useAuth } from '../../contexts/auth'
import alert from '../../icons/alert.svg'
import dayjs from 'dayjs'
import { useNotification } from '../../contexts/notificationContext'
import { useScreenSize } from '../../utils/media-query'
import { useDarkreader } from 'react-darkreader';
import { pageName } from '../../utils/pageName';

export default function Header({ menuToggleEnabled, title, toggleMenu }) {
  const { isXSmall } = useScreenSize()
  const [openPopupNotification, setOpenPopupNotification] = useState(false)
  const [openPopupMenu, setOpenPopupMenu] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [namPage, setNamPage] = useState('')
  const [blockedRequest, setBlockedRequest] = useState(false)
  const { navigationData } = useNavigation()
  const { unitCod, userCod, expirationDate, logOut } = useAuth()
  const { notificationsTime } = useNotification()
  const [isDark, { toggle }] = useDarkreader(JSON.parse(localStorage.getItem("@es-mes/darkMode"))); // MODO ESCURO / DARK MODE

  useEffect(() => {
    loadDataNotifications()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const iconBell = document.getElementsByClassName('dx-icon')
      const buttonBell = document.getElementsByClassName('dx-button-content')
      
      if (iconBell.length >= 2) {
        iconBell[1].style.width = '30px'
        iconBell[1].style.height = '30px'
      }
      if (buttonBell.length >= 2) {
        buttonBell[1].style.margin = ''
        buttonBell[1].style.display = ''
        buttonBell[1].style.flexDirection = ''
        buttonBell[1].style.justifyContent = ''
        buttonBell[1].style.alignItems = ''

        if(notifications.length > 0) {
          buttonBell[1].style.display = 'flex'
          buttonBell[1].style.flexDirection = 'column'
          buttonBell[1].style.justifyContent = 'center'
          buttonBell[1].style.alignItems = 'center'
        }

        buttonBell[1].children[0].style.display = 'flex'
        buttonBell[1].children[0].style.order = '2'
        buttonBell[1].children[0].style.zIndex = '1'
        
        if (buttonBell[1].children.length >= 2 && notifications.length > 0) {
          buttonBell[1].style.margin = '4px 0px'
          buttonBell[1].children[1].style.display = 'flex'
          buttonBell[1].children[1].style.justifyContent = 'center'
          buttonBell[1].children[1].style.alignItems = 'center'
          buttonBell[1].children[1].style.zIndex = '2'
          buttonBell[1].children[1].style.backgroundColor = 'red'
          buttonBell[1].children[1].style.borderRadius = '100%'
          buttonBell[1].children[1].style.width = '20px'
          buttonBell[1].children[1].style.height = '20px'
          buttonBell[1].children[1].style.padding = '1px'
          buttonBell[1].children[1].style.fontSize = '12px'
          buttonBell[1].children[1].style.color = 'white'
          buttonBell[1].children[1].style.margin = '-14px'
          buttonBell[1].children[1].style.border = '2px solid white'
        } 
      }
    }, 25)

    if (!blockedRequest && notificationsTime > 0) {
      setBlockedRequest(true)
      getNotificationsTime()
    }
  }, [notifications])

  function getNotificationsTime() {
    setTimeout(() => {
      loadDataNotifications()
      setBlockedRequest(false)
    }, notificationsTime * 60 * 1000)
  }

  async function loadDataNotifications() {
    await api
      .get('/notification', { params: { userCod: userCod, unitCod: unitCod } })
      .then((response) => {
        setNotifications(response.data)
      })
      .catch((error) => {
        return true
      })
  }

  const darkModeSwitch = async () => {
    localStorage.setItem("@es-mes/darkMode", !JSON.parse(localStorage.getItem("@es-mes/darkMode")));
    await toggle();
    window.location.reload();
  }

  useEffect(() => {
    if(navigationData.currentPath)
      setNamPage(pageName(navigationData.currentPath));

    loadDataNotifications();
  }, [navigationData.currentPath])

  function topenPopupNotification(e) {
    setOpenPopupNotification(true)
  }

  function openMenu() {
    setOpenPopupMenu(true)
  }

  function popupNotification() {
    if (openPopupNotification === true) {
      return (
        <PopupNotification
          notifications={notifications}
          loadDataNotifications={loadDataNotifications}
          openPopupNotification={openPopupNotification}
          setOpenPopupNotification={setOpenPopupNotification}
        />
      )
    }
  }

  function textExpirationDate() {
    const diffInDays = expirationDate ? dayjs(expirationDate).diff(dayjs(), 'day') : 31
    if (diffInDays < 0 && localStorage.getItem('@es-mes/tipAdministrator') === 'false') {
      logOut()
    }

    /*if(diffInDays < 0 && localStorage.getItem("@es-mes/tipAdministrator") === "true") {
          return  <div className={"ContainerMessageExpirationDate"}>
                    <img src={alert} width={"30"}></img>
                    <p className={"MessageExpirationDate"}>O sistema expirou.</p>
                  </div>
        };*/

    if (diffInDays <= 30) {
      return (
        <div className={'ContainerMessageExpirationDate'}>
          <img alt="Expiração do Sistema" src={alert} width={'30'}></img>
          <p className={'MessageExpirationDate'}>O sistema irá expirar em {diffInDays} dias. Entre em contato com a Nexxus.</p>
        </div>
      )
    }
  }

  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item visible={menuToggleEnabled} location={'before'} widget={'dxButton'} cssClass={'menu-button'}>
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item location={'before'} cssClass={isXSmall ? 'header-title-mobile' : 'header-title'} text={`${title} | ${namPage}`} visible={!!title} />
        <Item location={'center'} render={textExpirationDate}></Item>
        <Item location={'after'} locateInMenu={'auto'}>
          <Button
            stylingMode={'text'}
            text={notifications !== undefined && notifications.length > 0 ? notifications.length : ''}
            icon={notifications !== undefined && notifications.length > 0 ? bellRed : bell}
            onClick={(e) => topenPopupNotification(e)}
            height={'47px'}
            style={{ backgroundColor: 'white', display: 'flex', alignItems: "center" }}
            hint={'Notificações de tarefas e variáveis'}
          />
          {popupNotification()}
        </Item>
        <Item location={'after'} locateInMenu={'auto'}>
          <Button className={'user-button '} width={'100%'} height={'100%'} stylingMode={'text'} onClick={openMenu}>
            <UserPanel menuMode={'context'} />
            <UserPanel 
              isDark={isDark} 
              darkModeSwitch={darkModeSwitch} 
              menuMode={'list'} 
              setOpen={setOpenPopupMenu}
              open={openPopupMenu} 
            />
          </Button>
        </Item>
      </Toolbar>
    </header>
  )
}
