/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "../../../components/common/style.scss";
import "./style.scss";
import "devextreme/data/odata/store";
import { LoadIndicator } from "devextreme-react/load-indicator";
import DataGrid, {
	Column,
	Pager,
	RequiredRule,
	Paging,
	FilterRow,
	Export,
	ColumnChooser,
	Grouping,
	GroupPanel,
	ColumnFixing,
	Selection,
	CustomRule, Item, Toolbar,
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import FileUploader from "devextreme-react/file-uploader";
import { useAuth } from "../../../contexts/auth";
import { LogMessageGrid } from "../../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import notify from "devextreme/ui/notify";
import { useLoading } from "../../../contexts/loadingContext";
import Button from "devextreme-react/button";

export default function Company(props) {
	const { loading } = useLoading();
	const { refreshToken, getRefreshTokenCode, getToken, setUpdateLogo, updateLogo } = useAuth();
	const [load, setLoad] = useState(false);
	const [company, setCompany] = useState([]);
	const _companyAndUnit = useRef();
	const _imgPreview = useRef();
	const _formData = useRef({});
	const _visibleAddButton = useRef(false);
	const { unitCod } = useAuth();

	const uploadHeaders = {
		Accept: "*/*",
		"Content-Type": "text/plain; charset=utf-8",
		"X-Requested-With": "XMLHttpRequest",
	};

	useEffect(() => {
		async function firstLoad() {
			getFile("logo-cliente.png")
			loadData();
			refreshToken(getToken(), getRefreshTokenCode());
		}
		firstLoad();
	}, [props.update]);

	useEffect(() => {
		if (load) {
			loadData();
		}
	}, [load]);

	async function loadData() {
		await api
			.get("/company/?unitCod=0")
			.then((response) => {
				if(response.data.length === 0)
					_visibleAddButton.current = true;

				setCompany(response.data);
				setLoad(false);
			})
			.catch(() => {
				setLoad(true);
				return true;
			});
	}

	async function updateRow(data, parLogMessage) {
		return await api
			.put("/company", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then(() => {
				setLoad(true);
				props.setUpdate(!props.update);
				return false;
			})
			.catch(() => {
				setLoad(true);
				return true;
			});
	}

	async function insertRow(data, parLogMessage) {
		return await api
			.post("/company", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then(() => {
				setLoad(true);
				props.setUpdate(!props.update);

				return false;
			})
			.catch(() => {
				setLoad(true);

				return true;
			});
	}

	async function deleteRow(data) {
		return await api
			.delete("/company", { data: data })
			.then(() => {
				setLoad(true);
				props.setUpdate(!props.update);

				return false;
			})
			.catch(() => {
				setLoad(true);
				return true;
			});
	}

	async function uploadFile(data) {
		return await api.post("/file", data)
			.then(() => {
				setUpdateLogo(!updateLogo);
				return false;
			})
			.catch(() => {
				return true;
			});
	}

	async function getFile(data) {
		await api
			.get("/file", { params: { filename: data } })
			.then((response) => {
				_imgPreview.current = response.data
				setLoad(false);
			})
			.catch(() => {
				setLoad(true);
				return true;
			});
	}

	function cellRender() {
		return <img src={_imgPreview.current} alt="Imagem" width="50" />;
	}

	function editImage(newData) {
		return <FileUploader
			multiple={false}
			accept={"*"}
			uploadMode={"instantly"}
			uploadHeaders={uploadHeaders}
			uploadMethod={"POST"}
			uploadFailedMessage={""}
			uploadUrl={`${api}/file/upload`}
			onValueChanged={e => saveFile(e)}
			allowedFileExtensions={[".png"]}
		/>
	}

	async function saveFile(e) {
		_companyAndUnit.current.instance.endUpdate();

		let formData = new FormData();

		formData.append("files", e.value[0]);
		formData.append("nome", "bernardo");
		formData.append("nameArray", ["logo-cliente.png"]);

		_formData.current = formData;
		encodeImageFileAsBase64(e.value[0]);

		_companyAndUnit.current.instance.beginUpdate();
		_companyAndUnit.current.instance.cellValue(0, "ImgEmpresa", "a");
	}

	function encodeImageFileAsBase64(element) {
		var reader = new FileReader();

		reader.onloadend = function() {
		  _imgPreview.current = reader.result
		}

		reader.readAsDataURL(element);
	}

	function checkUniqueKey(params) {
		return !company.some(unit => (unit.CodEmpresa !== params.data.CodEmpresa && unit.NomEmpresa === params.data.NomEmpresa))
	}

	return (
		<React.Fragment>
			<div id="companyAndUnit">	
				<DataGrid
					className={"dx-cards"}
					dataSource={company}
					rowAlternationEnabled={true}
					showColumnLines={true}
					allowColumnReordering={true}
					showBorders={false}
					columnAutoWidth={false}
					columnHidingEnabled={true}
					repaintChangesOnly={true}
					onInitNewRow={(e) => {}}
					headerFilter={{ visible: true }}
					filterPanel={{ visible: true }}
					filterRow={{ visible: false }}
					editing={{
						refreshMode: "full",
						mode: "batch",
						allowUpdating: true,
						allowDeleting: true,
						allowAdding: false,
					}}
					onFocusedCellChanged={() => _companyAndUnit.current.instance.endUpdate()}
					onEditCanceling={() => _companyAndUnit.current.instance.endUpdate()}
					onRowUpdating={async e => {
						let newObj = { ...e.oldData, ...e.newData };
						newObj.ImgEmpresa = "";
						let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _companyAndUnit, newObj);
						
						e.cancel = updateRow(newObj, strLogMessage);
						await uploadFile(_formData.current);
						e.component.deselectAll();

						_companyAndUnit.current.instance.endUpdate();
					}}
					onRowInserting={async e => {
						const newObj = {
							ImgEmpresa: "",
							NomEmpresa: e.data.NomEmpresa,
						};
						let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _companyAndUnit, newObj);

						e.cancel = insertRow(newObj, strLogMessage);
						await uploadFile(_formData.current);
						e.component.deselectAll();

						_companyAndUnit.current.instance.endUpdate();
					}}
					onRowRemoving={e => {
						if (e.data.CodEmpresa === 1) {
							notify("Não é possível excluir a empresa com código 01", "error", 2500);
							e.cancel = true;
						} else {
							let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _companyAndUnit, e.data);
							const data = { unitCod: unitCod, data: e.data, logMessage: strLogMessage };

							e.cancel = deleteRow(data);
							e.component.deselectAll();
						}

						_companyAndUnit.current.instance.endUpdate();
					}}
					ref={_companyAndUnit}>
					<Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false} />
					<GroupPanel visible={false} />
					<Export enabled={true} fileName="Empresa" allowExportSelectedData={true} />
					<ColumnChooser enabled={true} mode="select" />
					<ColumnFixing enabled={false} />
					<Selection mode="multiple" />
					<Paging defaultPageSize={20} />
					<Pager showPageSizeSelector={true} showInfo={true} />
					<FilterRow visible={true} />
					<Toolbar >
						<Item 
							cssClass={'add-container'} 
							visible={_visibleAddButton.current}
						>
							<Button 
								className={'add-button'}  
								icon={"add"} 
								onClick={() => (_companyAndUnit.current.instance.addRow())}
							/>
						</Item>
						<Item name="saveButton"/>
						<Item name="revertButton"/>
						<Item name="exportButton"/>
						<Item name="columnChooserButton"/>
					</Toolbar>
					<Column caption={"Código"} allowSorting={true} sortIndex={0} dataField={"CodEmpresa"} allowEditing={false} width={80}></Column>
					<Column dataField={"NomEmpresa"} caption={"Empresa"} allowSorting={true} sortIndex={0}>
						<CustomRule message={"Este campo deve ser único"} reevaluate={true} type={"custom"} validationCallback={checkUniqueKey} />
						<RequiredRule />
					</Column>
					<Column
						dataField={"ImgEmpresa"}
						caption={"Logo (Tipo: '.png' e tamanho ideal: 150 x 40px)"}
						allowSorting={false}
						sortIndex={0}
						visible={true}
						allowEditing={true}
						cellRender={cellRender}
						editCellRender={editImage}>
					</Column>
				</DataGrid>
			</div>
			<div className="load-indicator">
				<LoadIndicator visible={loading} height={40} width={40} />
			</div>
			<div></div>
		</React.Fragment>
	);
}
