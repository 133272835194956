import React, { useEffect } from 'react';
import { DateBox } from 'devextreme-react';
import { useScreenSize } from '../../utils/media-query';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const { isXSmall } = useScreenSize();

    useEffect(() => {
        if(props.tipPeriodUnit === 4 && !props.currentDate)
            props.setCurrentDate(props.currencyValueDate.current);
    }, [])

    useEffect(() => {
        if(props.tipPeriodUnit === 4 && props.currentDate)
            props.currencyValueDate.current = props.currentDate;
    }, [props.currentDate])

    return(
        <div className='container-hour-box'>
            <DateBox
                type={"time"}
                displayFormat={"HH:mm"}
                interval={30}
                width={isXSmall ? 120 : 70}
                stylingMode={"filled"}
                useMaskBehavior={true}
                onValueChanged={(e) => {
                    props.currencyValueDate.current = e.value;

			        if(props.tipPeriodUnit === 4)
                        props.setCurrentDate(e.value);

                    props.onValueChanged(e, "dxDateBox");
                }}
                value={props.tipPeriodUnit === 4 ? props.currentDate : props.currencyValueDate.current}
            />
        </div>
    )
}