/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "./style.scss";
import DataGrid, {
  Column,
  Pager,
  RequiredRule,
  Paging,
  FilterRow,
  Export,
  Selection,
  ColumnChooser,
  Grouping,
  Lookup,
  GroupPanel,
  ColumnFixing,
  CustomRule
} from "devextreme-react/data-grid";
import { formatMessage } from "devextreme/localization";
import { api } from "../../services/api";
import { useAuth } from "../../contexts/auth";
import { AddButtonGrid } from "../../components/add-new-button/addButtonGrid";
import { LogMessageGrid } from "../../utils/logMessage";
import { useLoading } from "../../contexts/loadingContext";

export default function Receipts() {
  const { loading } = useLoading();
  const { refreshToken, getRefreshTokenCode, getToken } = useAuth();
  const [load, setLoad] = useState(false);
  const [receipt, setReceipt] = useState([]);
  const dataType = [
    { Id: 0, Value: "Individual" },
    { Id: 1, Value: "Diário" }
  ];
  const _receipt = useRef();

  //Autenticação requests
  const { unitCod } = useAuth();


  useEffect(() => {
    async function firstLoad(){
      if (unitCod !== undefined) {
        setLoad(true);
        refreshToken(getToken(), getRefreshTokenCode());
      }
    }
    firstLoad();
  }, []);

  useEffect(() => {
    if(load){
      async function loadData() {
        await api
          .get("/receipt", { params: { unitCod: unitCod } })
          .then((response) => {
            setReceipt(response.data);
            setLoad(false);
          })
          .catch((error) => {
            setLoad(true);
    
            return true;
          });
      }

    loadData();
    }
  }, [load]);

  async function updateRow(data, parLogMessage) {
    return await api
      .put("/receipt", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);
      
        return true;
      });
  }

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/receipt", { unitCod: unitCod, data: data, logMessage: parLogMessage})
      .then((response) => {
        setLoad(true);
        return false;
      })
      .catch((error) => {
        setLoad(true);
        

        return true;
      });
  }

  async function deleteRow(data) {
    return await api
      .delete("/receipt", { data: data })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  const onEditorPreparing = (e) => {

      if (e.dataField === "NomRecebimento" && e.parentType === "dataRow")
        e.editorOptions.maxLength = 100

      if (e.dataField === "TipRecebimento" && e.parentType === "dataRow") {

        e.editorOptions.searchEnabled = false;
        e.editorOptions.disabled = e.row.data.TipAutomatico;
      }

  }

  /*const onToolbarPreparing = (e) => {
      var dataGrid = e.component;

      e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
              elementAttr: {id: 'ToolbarAddNewButton'},
              hint: "Adicionar um registro",
              icon: "add",
              onClick: () => { dataGrid.addRow() }
          },
      });
  }*/

  function setCellValue (newData, value, currentRowData){
    newData.TipAutomatico = value;
    value ? newData.TipRecebimento = null : newData.TipRecebimento = 1;
    this.defaultSetCellValue(newData, value, currentRowData);
  }

  function checkUniqueKey(params) {
    let check = true;

    receipt.forEach((unit) => {
      if(unit.CodRecebimento !== params.data.CodRecebimento && 
        unit.NomRecebimento === params.data.NomRecebimento
        ) check = false;
    })

    return check;
  }

  return (
    <React.Fragment>
      {/* <h2 className="content-block">Cadastro de Recebimentos</h2> */}
      <div id="receiptsGrid">
        <DataGrid
          className={"dx-cards"}
          dataSource={receipt}
          rowAlternationEnabled={true}
          showColumnLines={true}
          allowColumnReordering={true}
          showBorders={false}
          columnAutoWidth={false}
          columnHidingEnabled={true}
          repaintChangesOnly={true}
          onEditorPreparing={onEditorPreparing}
          onInitNewRow={(e) => { 
            e.data.CodUnidadeEmpresa = unitCod
            e.data.TipAutomatico = false;
            e.data.TipRecebimento = 1;
          }}
          headerFilter={{ visible: true }}
          filterPanel={{ visible: true }}
          filterRow={{ visible: false }}
          onToolbarPreparing={(e) => AddButtonGrid(e)}
          editing={{
            refreshMode: "full",
            mode:"batch",
            allowUpdating: true,
            allowDeleting: true,
            allowAdding: false,           
          }}
          onRowUpdating={async (e) => {
            const newObj = { ...e.oldData, ...e.newData };
            let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _receipt, newObj);

            e.cancel = updateRow(newObj, strLogMessage);

            e.component.deselectAll();
          }}
          onRowInserting={(e) => {
            let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _receipt, e.data);

            e.cancel = insertRow(e.data, strLogMessage);

            e.component.deselectAll();
          }}
          onRowRemoving={(e) => {
            let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _receipt, e.data)
            const data = { unitCod: unitCod,  data: e.data, logMessage: strLogMessage }

            e.cancel = deleteRow(data);

            e.component.deselectAll();
          }}
          ref={_receipt}
        >
          <Grouping
            contextMenuEnabled={false}
            expandMode="rowClick"
            autoExpandAll={false}
          />
          <GroupPanel visible={false} /> 
          <Export
            enabled={true}
            fileName="Recebimentos"
            allowExportSelectedData={true}
          />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={false} />
          <Selection mode="multiple" />
          <Paging defaultPageSize={20} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <FilterRow visible={true} />
          <Column
            dataField={"CodRecebimentoIntegracao"}
            caption={"Código Integração"}
            allowSorting={true}
            sortIndex={0}
            width={80}
          >
          </Column>  
          <Column
            dataField={"NomRecebimento"}
            caption={"Nome"}
            allowSorting={true}
            width="50%"
          >
            <CustomRule
              message={'Este campo deve ser único'}
              reevaluate={true}
              type={"custom"}
              validationCallback={checkUniqueKey}
            />
            <RequiredRule />
          </Column>  
          <Column
            dataField={"TipAutomatico"}
            caption={"Automático?"}
            dataType={"boolean"}
            setCellValue={setCellValue}
            allowSorting={true}
            hidingPriority={2}
            width="20%"
           >
          </Column>  
          <Column
            caption={"Tipo"}
            dataField={"TipRecebimento"}
          >
            <Lookup
              dataSource={dataType}
              displayExpr={"Value"}
              valueExpr={"Id"}
              hidingPriority={1}
            />
          </Column>
        </DataGrid>
      </div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
