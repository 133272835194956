/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import "../style.scss";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Export,
  Selection,
  ColumnChooser,
  Grouping,
  GroupPanel,
  ColumnFixing,
  Lookup, Item, Toolbar
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { SelectBox } from "devextreme-react";
import { LogMessageGrid } from "../../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import { loadDataUsers } from "../../../utils/functionRequests";

export default function AcessUnits(props) {
  const [load, setLoad] = useState(false);
  const [userChange, setUserChange] = useState(-1);
  const [user, setUser] = useState([]);
  const [dataSourceCompanyUnit, setDataSourceCompanyUnit] = useState([]);
  const [acessUnits, setAcessUnits] = useState([]);
  const { unitCod, userCod } = useAuth();
  let _dataGrid = useRef();

  useEffect(() => {
    setLoad(true);
  }, [props.update]);

  useEffect(() => {
    if (load){
      loadDataUsers(userCod, setUser, setLoad);
      loadDataCompanyUnit();
      loadDataAcessUnits();
    }
  }, [load]);

  useEffect(() => {
    loadDataAcessUnits();
  }, [userChange])

  async function loadDataAcessUnits() {
    await api
      .get("/acessUnits", { params: { userCod: userChange } })
      .then((response) => {
        setAcessUnits(response.data);

        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  };

  async function loadDataCompanyUnit() {
    await api
      .get("/companyUnit", { params: { unitCod: 0 } })
      .then((response) => {
        setDataSourceCompanyUnit(response.data);

        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function updateRow(data, parLogMessage) {
    return await api
      .put("/userUnitCompany", {unitCod: unitCod, data: data, logMessage: parLogMessage})
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  };

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/userUnitCompany", { unitCod: unitCod, data: data, logMessage: parLogMessage } )
      .then((response) => {
        setLoad(true);
        props.setUserRegistred(null);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  };

  function onSelectionChangedUser (e) {
    e.selectedItem !== null ? setUserChange(e.selectedItem.CodUsuario) : setUserChange(-1);
  };

  const onEditorPreparing = (e) => {
    if(e.dataField === "TipPadrao" && e.parentType === "dataRow") {
      if(e.row !== undefined && e.row.data.TipAcesso === false) e.editorOptions.disabled = true;
      if(e.row !== undefined && e.row.data.TipAcesso === true) e.editorOptions.disabled = false;
    };

    if(e.dataField === "TipLeitura" && e.parentType === "dataRow") {
      if(e.row !== undefined && e.row.data.TipAcesso === false) e.editorOptions.disabled = true;
      if(e.row !== undefined && e.row.data.TipAcesso === true) e.editorOptions.disabled = false;
    };
  }

  function setCellValue (newData, value){
    newData.TipAcesso = value;
    newData.TipPadrao = false;
    newData.TipLeitura = false;
    this.defaultSetCellValue(newData, value);
  };

  function onInitializedUser(e) {
    if(props.userRegistred !== null && props.userRegistred !== undefined) {
      e.component.option("value", props.userRegistred);
      e.component.on("selectionChanged", onSelectionChangedUser);
    }
  }

  const GridComp = () => {
    return (
      <DataGrid
        className={"form-container"}
        dataSource={acessUnits}
        rowAlternationEnabled={true}
        showColumnLines={true}
        allowColumnReordering={true}
        keyExpr={"CodUnidadeEmpresa"}
        showBorders={false}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        repaintChangesOnly={false}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: false }}
        onEditorPreparing={onEditorPreparing}
        onInitNewRow={e => e.data.TipLeitura = false}
        editing={{
          refreshMode: "full",
          mode:"batch",
          allowUpdating: true,
          allowDeleting: false,
          allowAdding: false,
        }}
        onRowUpdating={async (e) => {
          const newObj = { ...e.oldData, ...e.newData };

          if(newObj.CodUsuarioUnidadeEmpresa === null) {
            newObj.CodUsuario = userChange;

            let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _dataGrid, newObj)
            e.cancel = insertRow(newObj, strLogMessage);

            e.component.deselectAll();
          } else {
            let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _dataGrid, newObj)
            e.cancel = updateRow(newObj, strLogMessage);

            e.component.deselectAll();
          }
        }}
        ref={_dataGrid}
      >
        <Grouping
          contextMenuEnabled={false}
          expandMode="rowClick"
          autoExpandAll={false}
        />
        <GroupPanel visible={false} />
        <Export
          enabled={true}
          fileName="Usuário Unidade Empresa"
          allowExportSelectedData={true}
        />
        <ColumnChooser enabled={true} mode="select" />
        <ColumnFixing enabled={false} />
        <Selection mode="multiple" />
        <Paging defaultPageSize={20} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <Toolbar>
          <Item name="saveButton"/>
          <Item name="revertButton"/>
          <Item name="exportButton"/>
          <Item name="columnChooserButton"/>
        </Toolbar>
        <Column
          dataField={"CodUnidadeEmpresa"}
          caption={"Unidade Empresa"}
          allowSorting={true}
        >
          <Lookup
            dataSource={dataSourceCompanyUnit}
            displayExpr="NomUnidadeEmpresa"
            valueExpr="CodUnidadeEmpresa"
          />
        </Column>
        <Column
          dataField={"TipAcesso"}
          caption={"Acesso Unidade"}
          allowSorting={true}
          dataType={"boolean"}
          setCellValue={setCellValue}
        />
        <Column
          dataField={"TipPadrao"}
          caption={"Unidade Padrão"}
          allowSorting={true}
        />
        {/* <Column
          dataField={"TipLeitura"}
          caption={"Somente Leitura"}
          allowSorting={true}
        /> */}
      </DataGrid>
    )};

  return (
    <React.Fragment>
      <div className={"form-container"}>
        <div className={"margin-element-grid"}>
            <SelectBox
              dataSource={user}
              label={"Usuário"}
              searchEnabled={true}
              onSelectionChanged={onSelectionChangedUser}
              onContentReady={onInitializedUser}
              displayExpr="NomUsuario"
              valueExpr="CodUsuario"
              width={250}
              stylingMode={"outlined"}
              labelMode={"floating"}
            />
          </div>
        </div>
        {useMemo(() => GridComp(), [user, acessUnits])}
    </React.Fragment>
  );
}
