import React, {useState, createContext, useContext, useRef} from "react";

const DataLoaderContext = createContext({});
const useDataLoader = () => useContext(DataLoaderContext);

function DataLoaderProvider(props) {
    const [loading, setLoading] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState("");
    const [groupProductChanged, setGroupProductChanged] = useState(false);
    const [measureChanged, setMeasureChanged] = useState(false);
    const [productChanged, setProductChanged] = useState(false);
    const listToSaveSample = useRef([]);
    const [visiblePopUp, setVisiblePopUp] = useState(false);
    const [responsePopUp, setResponsePopUp] = useState(false);
    const listToSaveDescSample = useRef([]);
    let indexObservation = useRef();
    let hourSample = useRef();
    let currencyDate = useRef();
    let periodUnit = useRef();
    const boolReanalysis = useRef(false);
    const [numLot, setNumLot] = useState();
    const _lstInputVariableSave = useRef([]);
    const [saveHeaderTextBox, setSaveHeaderTextBox] = useState();
    const [changedDateHeader, setChangedDateHeader] = useState();
    const [enabledActualPassword, setEnabledActualPassword] = useState(false);
    const objectImgToDataBase = useRef({});
    const _lstTimeListAnalysis = useRef([]);

    return (
        <DataLoaderContext.Provider
            value={{
                loading,
                setLoading,
                setGroupProductChanged,
                groupProductChanged,
                productChanged,
                setProductChanged,
                setMeasureChanged,
                measureChanged,
                changePassword,
                setChangePassword,
                passwordChanged,
                setPasswordChanged,
                setNumLot,
                numLot,
                listToSaveSample,
                indexObservation,
                boolReanalysis,
                listToSaveDescSample,
                _lstInputVariableSave,
                setSaveHeaderTextBox,
                saveHeaderTextBox,
                setChangedDateHeader,
                changedDateHeader,
                enabledActualPassword,
                setEnabledActualPassword,
                setVisiblePopUp,
                visiblePopUp,
                setResponsePopUp,
                responsePopUp,
                objectImgToDataBase,
                hourSample,
                currencyDate,
                periodUnit,
                _lstTimeListAnalysis
            }}
            {...props}
        />
    );
}

export {DataLoaderProvider, useDataLoader};
