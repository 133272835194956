/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import {LoadIndicator} from "devextreme-react/load-indicator";
import DataGrid, {
    Column,
    Pager,
    RequiredRule,
    Paging,
    FilterRow,
    Export,
    Selection,
    ColumnChooser,
    Grouping,
    GroupPanel,
    ColumnFixing,
    CustomRule, Item, Toolbar
} from "devextreme-react/data-grid";
import {api} from "../../../services/api";
import {useAuth} from "../../../contexts/auth";
import {LogMessageGrid} from "../../../utils/logMessage";
import {formatMessage} from "devextreme/localization";
import {useLoading} from "../../../contexts/loadingContext";
import Button from "devextreme-react/button";

export default function GroupDashboard() {
    const { loading } = useLoading();
    const { refreshToken, getRefreshTokenCode, getToken } = useAuth();
    const [load, setLoad] = useState(false);
    const [groupDashboard, setGroupDashboard] = useState([]);
    const [system, setSystem] = useState([]);
    const _groupDashboard = useRef()

    //Autenticação requests
    const { unitCod } = useAuth();

    useEffect(() => {
        async function firstLoad() {
            setLoad(true);
        }

        firstLoad();
        refreshToken(getToken(), getRefreshTokenCode());
    }, []);

    useEffect(() => {
        if (load) {
            awaitRequests()
        }

        async function awaitRequests() {
            loadData();
        }
    }, [load]);

    async function loadData() {
        await api
            .get("/groupDashboard", { params: { unitCod: unitCod } })
            .then((response) => {
                setGroupDashboard(response.data);
                setLoad(false);
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function updateRow(data, parLogMessage) {
        return await api
            .put("/groupDashboard", {unitCod: unitCod, data: data, logMessage: parLogMessage})
            .then((response) => {
                setLoad(true);

                return false;
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function insertRow(data, parLogMessage) {
        return await api
            .post("/groupDashboard", {unitCod: unitCod, data: data, logMessage: parLogMessage})
            .then((response) => {
                setLoad(true);

                return false;
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function deleteRow(data) {
        return await api
            .delete("/groupDashboard", {unitCod: unitCod, data: data})
            .then((response) => {
                setLoad(true);

                return false;
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    function checkUniqueKey(params) {
        let check = true;

        groupDashboard.forEach((unit) => {
            if (unit.CodDashboardGrupo !== params.data.CodDashboardGrupo &&
                unit.NomGrupo === params.data.NomGrupo
            ) check = false;
        })

        return check;
    }

    return (
        <React.Fragment>
            <div id="receiptsGrid">
                <DataGrid
                    className={"dx-cards"}
                    dataSource={groupDashboard}
                    rowAlternationEnabled={true}
                    showColumnLines={false}
                    allowColumnReordering={true}
                    showBorders={false}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    repaintChangesOnly={false}
                    onInitNewRow={(e) => {
                        e.data.CodUnidadeEmpresa = unitCod;
                    }}
                    headerFilter={{visible: true}}
                    filterPanel={{visible: true}}
                    filterRow={{visible: false}}
                    editing={{
                        refreshMode: "full",
                        mode: "batch",
                        allowUpdating: true,
                        allowDeleting: true,
                        allowAdding: false,
                    }}
                    onRowUpdating={async (e) => {
                        const newObj = {...e.oldData, ...e.newData};

                        let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _groupDashboard, newObj);
                        e.cancel = updateRow(newObj, strLogMessage);

                        e.component.deselectAll();
                    }}
                    onRowInserting={(e) => {
                        let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _groupDashboard, e.data);
                        e.cancel = insertRow(e.data, strLogMessage);

                        e.component.deselectAll();
                    }}
                    onRowRemoving={async (e) => {
                        let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _groupDashboard, e.data)
                        const data = {unitCod: unitCod, data: e.data, logMessage: strLogMessage};

                        e.cancel = deleteRow(data);

                        e.component.deselectAll();
                    }}
                    ref={_groupDashboard}
                >
                    <Grouping
                        contextMenuEnabled={false}
                        expandMode="rowClick"
                        autoExpandAll={false}
                    />
                    <GroupPanel visible={false}/>
                    <Export
                        enabled={true}
                        fileName="Grupo_Dashboard"
                        allowExportSelectedData={true}
                    />
                    <ColumnChooser enabled={true} mode="select"/>
                    <ColumnFixing enabled={false}/>
                    <Selection mode="multiple"/>
                    <Paging defaultPageSize={20}/>
                    <Pager showPageSizeSelector={true} showInfo={true}/>
                    <FilterRow visible={true}/>
                    <Toolbar>
                        <Item cssClass={'add-container'}>
                            <Button className={'add-button'} icon={"add"}
                                    onClick={() => (_groupDashboard.current.instance.addRow())}/>
                        </Item>
                        <Item name="saveButton"/>
                        <Item name="revertButton"/>
                        <Item name="exportButton"/>
                        <Item name="columnChooserButton"/>
                    </Toolbar>
                    <Column
                        dataField={"NomGrupo"}
                        caption={"Nome Grupo"}
                        allowSorting={true}
                        width={"auto"}
                    >
                        <CustomRule
                            message={'Este campo deve ser único'}
                            reevaluate={true}
                            type={"custom"}
                            validationCallback={checkUniqueKey}
                        />
                        <RequiredRule/>
                    </Column>
                </DataGrid>
            </div>
            <div className="load-indicator">
                <LoadIndicator visible={loading} height={40} width={40}/>
            </div>
        </React.Fragment>
    );
}
