/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "../style.scss";
import DataGrid, {
  Column,
  Pager,
  RequiredRule,
  Paging,
  FilterRow,
  Export,
  Selection,
  ColumnChooser,
  Grouping,
  Lookup,
  GroupPanel,
  ColumnFixing,
  Item, 
  Toolbar,
  CustomRule,
} from "devextreme-react/data-grid";
import { formatMessage } from "devextreme/localization";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { LogMessageGrid } from "../../../utils/logMessage";
import dayjs from "dayjs";
import { CheckBox, DateBox } from "devextreme-react";
import "./style.scss";

export default function CriticalPointReadingVariable() {
  const [load, setLoad] = useState(true);
  const [criticalPointReadingVariable, setCriticalPointReadingVariable] = useState([]);
  const [user, setUser] = useState([]);
  const [checkShowLastMonth, setCheckShowLastMonth] = useState(false);
  const [date, setDate] = useState(dayjs().subtract(1, 'month'));
  const _criticalPointReadingVariable = useRef();
  const status = [
    {
      TipAprovado: 0,
      Status: formatMessage("Disapproved"),
    }, 
    {
      TipAprovado: 1,
      Status: formatMessage("Approved")
    }
  ];

  //Autenticação requests
  const { unitCod, userCod } = useAuth();

  useEffect(() => {
    firstLoad();
  }, []);

  async function firstLoad() {
    if (unitCod !== undefined)
      loadDataUser();
  };

  useEffect(() => {
    if (load)
      loadData();
  }, [load]);
  
  async function loadData() {
    const initialDate = dayjs(new Date(date)).format('YYYY-MM-DDTHH:mm:ss');
    await api
      .get("/criticalPointReadingVariable", { params: { unitCod: unitCod, initialDate: initialDate, checkShowLastMonth: checkShowLastMonth } })
      .then((response) => {
        setCriticalPointReadingVariable(response.data);

        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);
      });
  };

  async function loadDataUser() {
    await api
      .get("/user", { params: { unitCod: unitCod } })
      .then((response) => {
        setUser(response.data.filter((x) => x.TipRegistroAtivo === true));
      })
      .catch((error) => {
      });
  };

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/criticalPointReadingVariable", {
        unitCod: unitCod,
        data: data,
        logMessage: parLogMessage,
      })
      .then((response) => {
        setLoad(true);
      })
      .catch((error) => {
        setLoad(true);
      });
  };

  async function updateRow(data, parLogMessage) {
    return await api
      .put("/criticalPointReadingVariable", {
        unitCod: unitCod,
        data: data,
        logMessage: parLogMessage,
      })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  };

  const onEditorPreparing = (e) => {
    if (e.dataField === "DesObservacao" && e.parentType === "dataRow") {
      e.editorOptions.disabled = e.row.data.TipAprovado;

      e.editorOptions.value = null;
    };
  };

  function setCellValue(newData, value, currentRowData) {
    newData.TipAprovado = value;

    if(value === 1)
      newData.DesObservacao = null;

    let column = this;
    column.defaultSetCellValue(newData, value);
  };

  function customRule(e) {
    return e.data.TipAprovado === 1 || (e.data.TipAprovado === 0 && e.value);
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="containerDateCheckBox">
          <DateBox
            label={"Mostrar a partir de:"}
            width={250}
            height={45}
            showClearButton={false}
            type="date"
            value={date}
            onValueChanged={e => {
              setDate(e.value);
              setLoad(true);
            }}
            stylingMode={"outlined"}
            labelMode={"floating"}
            className="date-box"
          /> 
          <CheckBox 
            defaultValue={false} 
            text={'Mostrar verificados'} 
            className={'ContainerCheckBox'} 
            onValueChanged={(e) => {
              setCheckShowLastMonth(e.value);
              setLoad(true);
            }}
          /> 
        </div>
      <div>
        <DataGrid
          className={"dx-cards"}
          dataSource={criticalPointReadingVariable}
          rowAlternationEnabled={true}
          showColumnLines={true}
          allowColumnReordering={true}
          showBorders={false}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          repaintChangesOnly={false}
          headerFilter={{ visible: true }}
          filterPanel={{ visible: true }}
          filterRow={{ visible: false }}
          editing={{
            refreshMode: "full",
            mode:"batch",
            allowUpdating: true,
          }}
          onRowUpdating={async (e) => {
            let newObj = { ...e.oldData, ...e.newData };

            newObj = {
              CodVariavelLeituraPontoCritico: newObj.CodVariavelLeituraPontoCritico ? newObj.CodVariavelLeituraPontoCritico : undefined,
              DatVariavelLeitura: newObj.DatVariavelLeitura,
              CodVariavelLeitura: newObj.CodVariavelLeitura,
              ValVariavelLeitura: newObj.ValorRegistrado,
              TipAprovado: newObj.TipAprovado,
              DesObservacao: newObj.DesObservacao,
              CodUsuario: userCod,
            };

            let strLogMessage = newObj.CodVariavelLeituraPontoCritico  
              ? LogMessageGrid(
                formatMessage("UpdatedLog"),
                _criticalPointReadingVariable,
                newObj
              ) 
              : LogMessageGrid(
                formatMessage("InsertedLog"),
                _criticalPointReadingVariable,
                newObj
              );

            e.cancel = newObj.CodVariavelLeituraPontoCritico 
              ? updateRow(newObj, strLogMessage) 
              : insertRow(newObj, strLogMessage);

            e.component.deselectAll();
          }}
          ref={_criticalPointReadingVariable}
          onEditorPreparing={(e) => onEditorPreparing(e)}
        >
          <Grouping
            contextMenuEnabled={false}
            expandMode="rowClick"
            autoExpandAll={false}
          />
          <GroupPanel visible={false} />
          <Export
            enabled={true}
            fileName="Aprovar Pontos Criticos"
            allowExportSelectedData={true}
          />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={false} />
          <Selection mode="multiple" />
          <Paging defaultPageSize={20} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <FilterRow visible={true} />
          <Toolbar >
            <Item name="saveButton"/>
            <Item name="revertButton"/>
            <Item name="exportButton"/>
            <Item name="columnChooserButton"/>
          </Toolbar>
          <Column
            dataField={"DatVariavelLeitura"}
            caption={"Data"}
            hidingPriority={7}
            allowSorting={true}
            type={'datetime'}
            customizeText={(e) => { return dayjs(e.valueText).format('L LTS')}}
            allowEditing={false}
          />
          <Column
            dataField={"Descricao"}
            caption={"Descrição"}
            hidingPriority={6}
            allowSorting={true}
            allowEditing={false}
          />
          <Column
            dataField={"Parametros"}
            caption={"Parâmetros"}
            hidingPriority={6}
            allowSorting={true}
            allowEditing={false}
          />
          <Column
            dataField={"ValorRegistrado"}
            caption={"Valor Registrado"}
            hidingPriority={6}
            allowSorting={true}
            allowEditing={false}
            cellRender={e => <div style={{color: e.data.TipVermelho === 1 ? "red" : ""}}>{e.data.ValorRegistrado}</div>}
          />
          <Column
            dataField={"TipAprovado"}
            caption={"Aprovar/Reprovar"}
            hidingPriority={3}
            allowSorting={true}
            allowSearch={false}
            filterValue={null}
            setCellValue={setCellValue}
          >
            <Lookup
              dataSource={status}
              displayExpr="Status"
              valueExpr="TipAprovado"
            />
            <RequiredRule />
          </Column>
          <Column
            dataField={"DesObservacao"}
            caption={"Observação | NC"}
            hidingPriority={6}
            allowSorting={true}
          >
            <CustomRule 
              validationCallback={(e) => customRule(e)}
              message={formatMessage("Required")}
            />
          </Column>
          <Column
            dataField={"CodUsuario"}
            caption={"Verificador"}
            hidingPriority={3}
            allowSorting={true}
            allowEditing={false}
          >
            <Lookup
              dataSource={user}
              displayExpr="NomUsuario"
              valueExpr="CodUsuario"
            />
          </Column>
          <Column
            dataField={"DatVerificacao"}
            caption={"Data Verificação"}
            hidingPriority={7}
            allowSorting={true}
            allowEditing={false}
            type={'datetime'}
            customizeText={(e) => { 
              if(e.valueText.length> 0)
                return dayjs(e.valueText).format('L LTS');
              
              return e.valueText;
            }}
          />
        </DataGrid>
      </div>
      </div>
      <div className="load-indicator">
        <LoadIndicator visible={load} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
