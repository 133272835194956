/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useMemo } from "react";
import "../../../components/common/style.scss";
import "./style.scss";
import "devextreme/data/odata/store";
import { LoadIndicator } from "devextreme-react/load-indicator";
import DataGrid, {
	Column,
	Pager,
	RequiredRule,
	Paging,
	FilterRow,
	Export,
	ColumnChooser,
	Grouping,
	GroupPanel,
	ColumnFixing,
	Selection,
	CustomRule,
	Lookup,
	Editing,
	Form,
	Popup,
	Position,
	Item, Toolbar,
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useDataLoader } from "../../../contexts/dataLoaderContext";
import { useAuth } from "../../../contexts/auth";
import { LogMessageGrid } from "../../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import { useLoading } from "../../../contexts/loadingContext";
import { useScreenSize } from "../../../utils/media-query";
import ChangePassword from "../../../components/change-password-form/change-password-form";
import { onEditorPreparingChangePassword } from "../../../utils/onEditorPreparingChangePassword";
import Button from "devextreme-react/button";

export default function ServerOpc(props) {
	const { loading } = useLoading();
	const { refreshToken, getRefreshTokenCode, getToken } = useAuth();
	const { setChangePassword, changePassword, passwordChanged } = useDataLoader();
	const { isLarge } = useScreenSize();
	const [load, setLoad] = useState(false);
	const [lstServerOpc, setLstServerOpc] = useState([]);
	const [lstServerTypeOpc, setLstServerTypeOpc] = useState([]);
	const [event, setEvent] = useState([]);
	const _lstServerOpc = useRef([]);
	const columnMaxLengthThirty = { maxLength: 30 };

	//Autenticação requests
	const { unitCod } = useAuth();

	useEffect(() => {
		firstLoad();
	}, [props.update]);

	useEffect(() => {
		loadData();
		loadDataServerTypeOpc();
	}, [load]);

	async function firstLoad() {
		if (unitCod !== undefined) {
			loadData();
			loadDataServerTypeOpc();
			refreshToken(getToken(), getRefreshTokenCode());
		}
	}

	async function loadData() {
		await api
			.get("/OPCServer", { params: { unitCod: 0 } })
			.then((response) => {
				setLstServerOpc(response.data);
			})
			.catch((error) => {
				return true;
			});
	}

	async function loadDataServerTypeOpc() {
		await api
			.get("/OPCServerType", { params: { unitCod: 0 } })
			.then((response) => {
				setLstServerTypeOpc(response.data);
			})
			.catch((error) => {
				return true;
			});
	}

	async function updateRow(data, parLogMessage) {
		return await api
			.put("/OPCServer", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then((response) => {
				props.setUpdate(!props.update);
				setLoad(!load);
				return false;
			})
			.catch((error) => {
				return true;
			});
	}

	async function insertRow(data, parLogMessage) {
		return await api
			.post("/OPCServer", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then((response) => {
				props.setUpdate(!props.update);
				setLoad(!load);
				return false;
			})
			.catch((error) => {
				return true;
			});
	}

	async function deleteRow(data) {
		return await api
			.delete("/OPCServer", { data: data })
			.then((response) => {
				props.setUpdate(!props.update);
				setLoad(!load);
				return false;
			})
			.catch((error) => {
				return true;
			});
	}

	function checkUniqueKey(params) {
		let check = true;

		lstServerOpc.forEach((opcConnection) => {
			if (opcConnection.CodOpcServidor !== params.data.CodOpcServidor && opcConnection.NomOpcServidor === params.data.NomOpcServidor)
				check = false;
		});

		return check;
	}

	const renderServerOPCGrid = () => {
		return (
			<DataGrid
				className={"dx-cards"}
				dataSource={lstServerOpc}
				rowAlternationEnabled={true}
				showColumnLines={true}
				allowColumnReordering={true}
				showBorders={false}
				columnAutoWidth={false}
				columnHidingEnabled={true}
				repaintChangesOnly={true}
				headerFilter={{ visible: true }}
				filterPanel={{ visible: true }}
				filterRow={{ visible: false }}
				onInitNewRow={(e) => {
					e.data.ValTaxaAtualizacao = 1000;
				}}
				onEditorPreparing={(e) => onEditorPreparingChangePassword(e, setEvent, passwordChanged, setChangePassword)}
				onRowUpdating={async (e) => {
					const newObj = { ...e.oldData, ...e.newData };

					//let keyObj = e.key
					//let index = _lstServerOpc.current.instance.getRowIndexByKey(key)
					let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _lstServerOpc, newObj);
					e.cancel = updateRow(newObj, strLogMessage);

					e.component.deselectAll();
				}}
				onRowInserting={(e) => {
					let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _lstServerOpc, e.data);

					e.cancel = insertRow(e.data, strLogMessage);

					e.component.deselectAll();
				}}
				onRowRemoving={(e) => {
					let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _lstServerOpc, e.data);
					const data = { unitCod: unitCod, data: e.data, logMessage: strLogMessage };

					e.cancel = deleteRow(data);

					e.component.deselectAll();
				}}
				ref={_lstServerOpc}
				//onContentReady={(e) => e.component.}
			>
				<Editing mode={isLarge ? "batch" : "popup"} allowUpdating={true} allowDeleting={true} allowAdding={false}>
					<Popup
						title="Cadastro de Servidor OPC"
						showTitle={true}
						width={700}
						height={750}
						fullScreen={true}
					>
						<Position my="center" at="center" of={window} />
					</Popup>
					<Form>
						<Item itemType="group" colCount={4} colSpan={2}>
							<Item colSpan={2} dataField="NomOpcServidor" />
							<Item colSpan={2} dataField="CodOpcServidorTipo" />
							<Item colSpan={2} dataField="NomOpcDominio" />
							<Item colSpan={2} dataField="NomOpcComputador" />
							<Item colSpan={2} dataField="NomLoginUsuario" />
							<Item colSpan={2} dataField="ValSenhaUsuario" />
							<Item colSpan={1} dataField="ValTaxaAtualizacao" />
						</Item>
					</Form>
				</Editing>
				<Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false} />
				<GroupPanel visible={false} />
				<Export enabled={true} fileName="Conexão" allowExportSelectedData={true} />
				<ColumnChooser enabled={true} mode="select" />
				<ColumnFixing enabled={false} />
				<Selection mode="multiple" />
				<Paging defaultPageSize={20} />
				<Pager showPageSizeSelector={true} showInfo={true} />
				<FilterRow visible={true} />
				<Toolbar >
					<Item cssClass={'add-container'}>
						<Button className={'add-button'}  icon={"add"} onClick={() => (_lstServerOpc.current.instance.addRow())}/>
					</Item>
					<Item name="saveButton"/>
					<Item name="revertButton"/>
					<Item name="exportButton"/>
					<Item name="columnChooserButton"/>
				</Toolbar>
				<Column
					dataField={"NomOpcServidor"}
					caption={"Servidor OPC"}
					allowSorting={true}
					sortIndex={0}
				>
					<CustomRule
						message={"Este campo deve ser único"}
						reevaluate={true}
						type={"custom"}
						validationCallback={checkUniqueKey}
					/>
					<RequiredRule />
				</Column>
				<Column
					dataField={"CodOpcServidorTipo"}
					caption={"Servidor Tipo"}
					minWidth={200}
					allowSorting={true}
					sortIndex={0}
				>
					<Lookup
						dataSource={lstServerTypeOpc}
						displayExpr="NomOpcServidorTipo"
						valueExpr="CodOpcServidorTipo"
					/>
					<RequiredRule />
				</Column>
				<Column
					dataField={"NomOpcDominio"}
					caption={"Domínio OPC"}
					allowSorting={true}
					sortIndex={0}
					editorOptions={columnMaxLengthThirty}
				/>
				<Column
					dataField={"NomOpcComputador"}
					caption={"Computador OPC"}
					allowSorting={true}
					sortIndex={0}
					editorOptions={columnMaxLengthThirty}
				/>
				<Column
					dataField={"NomLoginUsuario"}
					caption={"Login Usuário"}
					allowSorting={true}
					sortIndex={0}
					editorOptions={columnMaxLengthThirty}
				/>
				<Column
					dataField={"ValSenhaUsuario"}
					caption={"Senha"}
					customizeText={() => "*********"}
					editorOptions={{mode: 'password'}}
					allowFiltering={false}
					allowGrouping={false}
					allowSearch={false}
					allowSorting={false}
					allowEditing={true}
				/>
				<Column
					dataField={"ValTaxaAtualizacao"}
					caption={"Taxa Atualização"}
					dataType={"string"}
					allowSorting={true}
					sortIndex={0}
				/>
			</DataGrid>
		)
	}

	return (
		<React.Fragment>
			<div id="opc-connection">
				{useMemo(() => renderServerOPCGrid(), [lstServerOpc])}
			</div>
			{changePassword && <ChangePassword event={event}/>}
			<div className="load-indicator">
				<LoadIndicator visible={loading} height={40} width={40} />
			</div>
			<div></div>
		</React.Fragment>
	);
}
