import { formatMessage } from "devextreme/localization";

export function pageName(address) {
  console.log(address)
    let namePage;

    switch (address) {
        case '/home':
          namePage = formatMessage('Home')
          break
        case '/receipt':
          namePage = formatMessage('ReceiptRegister')
          break
        case '/transport':
          namePage = formatMessage('ReceiptTransport')
          break
        case '/user':
          namePage = formatMessage('Users')
          break
        case '/permission':
          namePage = formatMessage('Permission')
          break
        case '/tip':
          namePage = formatMessage('Tip')
          break
        case '/shifts':
          namePage = formatMessage('Shift')
          break
        case '/dataacess':
          namePage = formatMessage('DataAcess')
          break
        case '/process':
          namePage = formatMessage('Process')
          break
        case '/areaprocess':
          namePage = formatMessage('AreaProcess')
          break
        case '/companyandunit':
          namePage = formatMessage('CompanyAndUnit')
          break
        case '/product':
          namePage = formatMessage('Product')
          break
        case '/productstock':
          namePage = formatMessage('Stock')
          break
        case '/measure':
          namePage = formatMessage('Measure')
          break
        case '/sector':
          namePage = formatMessage('Sector')
          break
        case '/aplication':
          namePage = formatMessage('Aplication')
          break
        case '/users':
          namePage = formatMessage('Programs')
          break
        case '/about':
          namePage = formatMessage('About')
          break
        case '/locality':
          namePage = formatMessage('Locality')
          break
        case '/configuration':
          namePage = formatMessage('Configuration')
          break
        case '/permissionLaboratory':
          namePage = formatMessage('PermissionLaboratory')
          break
        case '/reasonType':
          namePage = formatMessage('RegisterReasonType')
          break
        case '/areaProcessStop':
          namePage = formatMessage('StopsByArea')
          break
        case '/processStop':
          namePage = formatMessage('StopsByProcess')
          break
        case '/automaticVariables':
          namePage = formatMessage('AutomaticVariables')
          break
        case '/manualVariables':
          namePage = formatMessage('ManualVariables')
          break
        case '/analysis':
          namePage = formatMessage('RegisterAnalysis')
          break
        case '/productionGroup':
          namePage = formatMessage('Group')
          break
        case '/directInput':
          namePage = formatMessage('InputDirect')
          break
        case '/createTask':
          namePage = formatMessage('CreateTask')
          break
        case '/executeTask':
          namePage = formatMessage('ExecuteTask')
          break
        case '/variableInputByVariable':
          namePage = formatMessage('VariableInputByVariable')
          break
        case '/variableInputByDate':
          namePage = formatMessage('VariableInputByDate')
          break
        case '/sample':
          namePage = formatMessage('Sample')
          break
        case '/variablesTab':
          namePage = formatMessage('RegisterVariable')
          break
        case '/configurationOPC':
          namePage = formatMessage('ConfigurationOPC')
          break
        case '/goals':
          namePage = formatMessage('MonitorationBlockGoals')
          break
        case '/monitorationBlock':
          namePage = formatMessage('MonitorationBlock')
          break
        case '/quickguides':
          namePage = formatMessage('QuickGuides')
          break
        case '/quickguideslist':
          namePage = formatMessage('QuickGuides')
          break
        case '/sectorprocessarea':
          namePage = formatMessage('SectorProcessArea')
          break
        case '/profile':
          namePage = formatMessage('Profile')
          break
        case '/indicators':
          namePage = formatMessage('Indicators')
          break
        case '/dailyreport':
          namePage = formatMessage('DailyReport')
          break
        case '/equipment':
          namePage = formatMessage('Equipment')
          break
        case '/dashboardConfiguration':
          namePage = formatMessage('DashboardConfiguration')
            break
        case '/approveCriticalPoint':
          namePage = formatMessage('ApproveCriticalPoint')
          break
        case '/email':
          namePage = formatMessage('Email')
          break
        default:
          namePage = formatMessage('Home');
    }

    return namePage;
}

