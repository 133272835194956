/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback, useEffect } from "react";
import { api } from "../../services/api";
import Form, {
  Label,
  ButtonItem,
  ButtonOptions,
  SimpleItem,
  RequiredRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import { useAuth, INITIAL_CONFIG_KEY } from "../../contexts/auth";
import "./login-form.scss";
import packageJsonInfo from '../../../package.json';
import logo from "../../images/ES-MES.png"
import logoNexxus from "../../images/logo-nexxus.png"
import {useScreenSize} from "../../utils/media-query";

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const { logIn, setVisiblePopupUpdateDB, initialConfig } = useAuth();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [users, setUsers] = useState();
  const formData = useRef({});
  const versionScafold = packageJsonInfo.versionBDScaffold;
  const version = packageJsonInfo.version;
  const {isLarge} = useScreenSize()

  useEffect(() => {
    async function loadUsers() {
      await api
        .get("/auth/users")
        .then((response) => {
          setUsers(response.data);
        })
        .catch((error) => {
          String(error).substring(0, 14) === "Error: Network"
          ? console.log("networkError")
          : console.log("user-login", error.response);
        });
    }

      loadUsers();
  }, []);

  useEffect(() => {
    initialConfig && versionScafold !== initialConfig.versionDB && initialConfig.versionDB !== "0.0.0.0" && initialConfig.versionDB !== "0000" 
      ? setVisiblePopupUpdateDB(true) 
      : setVisiblePopupUpdateDB(false);
 
    disable(initialConfig);
  }, [initialConfig]);

  useEffect(() => {
    disable(localStorage.getItem(INITIAL_CONFIG_KEY));
  }, [localStorage.getItem(INITIAL_CONFIG_KEY)]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { user, password } = formData.current;
      setLoading(true);

      await logIn(user, password);
      setLoading(false);
      setDisabled(false);
    },
    [logIn]
  );

  const userEditorOptions = {
    stylingMode: "filled",
    placeholder: "Usuário",
    searchEnabled: isLarge,
    showClearButton:false,
    items: users,
  };

  const passwordEditorOptions = {
    stylingMode: "filled",
    placeholder: "Senha",
    mode: "password",
    elementAttr: { class: "password-margin" },

  };

  function disable(parInitialConfig) {
    const initialConfigInLocalStorage = !parInitialConfig || parInitialConfig === `""` ? true : false;
    setDisabled(initialConfigInLocalStorage);
  };

  return (
    <React.Fragment>
      <img
        className="logo-login"
        src={logo}
        alt=""
        width="80"
        height="81"
      />
      <div className={"login-header"}>
        <div>Faça login em sua conta</div>
      </div>

      <form className={"login-form"} onSubmit={onSubmit}>
        <Form formData={formData.current} disabled={loading}>
          <SimpleItem
            dataField={"user"}
            editorType={"dxSelectBox"}
            editorOptions={userEditorOptions}
          >
            <RequiredRule message="É necessário escolher o usuário" />
            <Label visible={false} />
          </SimpleItem>
          <SimpleItem
            dataField={"password"}
            editorType={"dxTextBox"}
            editorOptions={passwordEditorOptions}
          >
            <RequiredRule message="É necessário preencher a senha" />
            <Label visible={false} />
          </SimpleItem>
          <ButtonItem>
            <ButtonOptions
              width={"100%"}
              height={40}
              type={"default"}
              useSubmitBehavior={true}
              disabled={loading ? loading : disabled}
            >
              <span className="dx-button-text">
                {loading || disabled? (
                  <LoadIndicator
                    width={"24px"}
                    height={"24px"}
                    visible={true}
                  />
                ) : (
                  "Entrar"
                )}
              </span>
            </ButtonOptions>
          </ButtonItem>
        </Form>
      </form>
      <img
          className="logo-nexxus"
          src={logoNexxus}
          alt=""
          width="70%"
          height="70%"
          title={`v${version}\nv${versionScafold}`}
      />
    </React.Fragment>
  );
}
