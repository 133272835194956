/* eslint-disable react-hooks/exhaustive-deps */
import {Button} from "devextreme-react/button";
import { SelectBox } from "devextreme-react";
import React, {useEffect, useState} from "react";
import "./style.scss";
import Resizer from "react-image-file-resizer";
import {useScreenSize} from "../../utils/media-query";
import {TextField} from "@material-ui/core";
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import photo from "../../icons/photos.svg"

/*
    -----------------------------------------------------------------------------------------------------------------------------------------
    Caso seja mais de uma imagem (props.multiple) vai retornar um array com 2 posiçoes, a primeira com imagens e a segunda com as observações
    E quando for mais uma imagem, utilizar props.numImgs pra determinar a quantidade permitida
    Quando for multiple = true, para abrir o componente já com fotos, deve ser enviado o props.objImgFromDataBase como objeto, com
    as propriedades:
    - ImgBase64
    - DesObservacao

    O retorno é o objeto objImgToDataBase:
    objImgToDataBase.ImgBase64
    objImgToDataBase.DesObservacao
    objImgToDataBase.DatGravacao = props.datRegister

    props.objImgFromDataBase Precisa ser uma referencia

    props.disabledButton é utilizado para desabilitar e deixar o botão de Inserir Imagem 
    props.invisbleObservation é utilizado para deixar o campo de observação invísivel
    props.buttonType é utilizado para renderizar um botão ou um texto para inserir a imagem.

    props.listSelectBox tem que ser um objeto com Id e Value, e o que for escolhido será salvo no num_secundario na tabela Imagem (quando for amostras)
    -----------------------------------------------------------------------------------------------------------------------------------------
*/
export const ImageUploaderInline = (props) => {
    dayjs.extend(utc)
    ImageUploaderInline.defaultProps = {
        datRegister: dayjs().utc(true).format()
    }
    const { isLarge } = useScreenSize();
    const [imgs, setImgs] = useState([])
    const [imgsObservation, setImgsObservation] = useState([])
    const [imgsSecondaryCode, setImgsSecondaryCode] = useState([])
    const [visiblePreview, setVisiblePreview] = useState(false)

    useEffect(() => {
        if(props.multiple){
            if(!!props.objImgFromDataBase[0]) {
                props.objImgFromDataBase[0].ImgBase64.indexOf('|') > -1
                    ? setImgs(props.objImgFromDataBase[0].ImgBase64.split('|'))
                    : setImgs([props.objImgFromDataBase[0].ImgBase64])

                if(!!props.objImgFromDataBase[0].DesObservacao){
                    props.objImgFromDataBase[0].DesObservacao.indexOf('|') > -1
                        ? setImgsObservation(props.objImgFromDataBase[0].DesObservacao.split('|'))
                        : setImgsObservation([props.objImgFromDataBase[0].DesObservacao])
                }
                if(!!props.objImgFromDataBase[0].NumSecundario){
                    props.objImgFromDataBase[0].NumSecundario.indexOf('|') > -1
                        ? setImgsSecondaryCode(props.objImgFromDataBase[0].NumSecundario.split('|'))
                        : setImgsSecondaryCode([props.objImgFromDataBase[0].NumSecundario])
                }
            }
        } else {
            if(props.objImgFromDataBase[0]) {
                setImgs(props.objImgFromDataBase[0])
            }
        }
    },[props.objImgFromDataBase])

    useEffect(() => {
        handleOk()
    },[imgs, imgsObservation, imgsSecondaryCode])

    const handleRemoveItem = (e) => {
        const index = imgs.indexOf(e)
        if(props.multiple){
            setImgsSecondaryCode(imgsSecondaryCode.filter(item => item !== imgsSecondaryCode[index]))
            setImgsObservation(imgsObservation.filter(item => item !== imgsObservation[index]))
            setImgs(imgs.filter(item => item !== e))
        }
        else setImgs("")
    };

    const handleOk = () => {
        if (props.multiple) {
            props.objImgToDataBase.current = {
                ImgBase64: (imgs.length > 1) ? imgs.join("|") : imgs[0],
                DesObservacao: (imgs.length > 1) ? imgsObservation.join("|") : imgsObservation[0],
                SecondaryCode: (imgs.length > 1) ? imgsSecondaryCode.join("|") : imgsSecondaryCode[0],
                TerciaryCode: (imgs.length > 1) ? imgsSecondaryCode.join("|") : imgsSecondaryCode[0],
                DatGravacao: props.datRegister
            }
        }
        else {
            props.objImgFromDataBase.ImgBase64 = imgs
        }
    };

    const handleChange = (value, index) => {
        let newObs = [...imgsObservation]
        newObs[index] = value
        setImgsObservation(newObs)
    };

    const handleSelectBoxChange = (value, index) => {
        let newSecondaryCode = [...imgsSecondaryCode]
        newSecondaryCode[index] = value
        setImgsSecondaryCode(newSecondaryCode)
    };

    const handleImageChange = async (event) => {
        Object.values(event.target.files).forEach(file => {
          Resizer.imageFileResizer(
            file,480,480,"WEBP",60,0,
            uri => setImgs(currentImgs => [...currentImgs, uri])
          )
        })
        setVisiblePreview(true)
    }

    return (
        <div className="container-image-uploader" >
            <label 
                htmlFor="file-upload" 
                className={`${props.buttonType ? "insert-image-input" : "insert-image-input-insert"} ${props.disabledButton ? "invisible-button" : ""}`}
            >
                Inserir Imagem
                <input
                    multiple={props.multiple}
                    type="file"
                    id="file-upload"
                    accept="image/*" 
                    onChange={handleImageChange}
                    disabled={props.disabledButton}
                />
            </label>
            <Button
                icon={visiblePreview ? "chevrondown" : "chevronright"}
                onClick={() => { setVisiblePreview(!visiblePreview) }}
            >
            </Button>
            <label style={{fontSize: 10}} onClick={() => { setVisiblePreview(!visiblePreview) }}>{visiblePreview ? 'Esconder' : 'Visualizar'}</label>
            
            <div className="image-container" style={{ border: imgs.length > 0 ? "1px solid #ccc" : "", padding: imgs.length > 0 ? 4 : 0 , display: visiblePreview ? "flex" : "none"}}>
                {props.multiple ?
                    imgs.map((imgPrev, index) => 
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} key={index}>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <div className={"image-container-user-square"}>
                                    <img className={"user-image"} src={imgPrev} alt={""}/>
                                </div>
                                <Button
                                    visible={!props.disabledButton} 
                                    icon="close" 
                                    className={"remove-image-square"}
                                    onClick={() => handleRemoveItem(imgPrev)}
                                />
                            </div>
                            <p style={{fontSize: '10px'}}>Tamanho: {(imgPrev.length/1000).toFixed(0)}KB</p>
                            {!props.invisibleObservation ? 
                                <div>
                                    <TextField
                                    style={{width: "200px", margin: "1vh 10px"}}
                                    InputLabelProps={{shrink: true}}
                                    variant="outlined"
                                    inputProps={{ maxLength: 50 }}
                                    value={imgsObservation[index]}
                                    multiline
                                    rows={3}
                                    onChange={(e) => handleChange(e.target.value, index)}
                                    label={"Observação:"}
                                    />
                                    <SelectBox
                                        dataSource={props.listSelectBox}
                                        style={{margin: "0vh 10px"}}
                                        visible={!!props.listSelectBox}
                                        valueExpr="Id"
                                        displayExpr="Value"
                                        displayValue=""
                                        value={imgsSecondaryCode[index]}
                                        showClearButton={true}
                                        width={"90%"}
                                        height={"3em"}
                                        //disabled={props.multiple ? (props.imgs.length === 0 || props.imgs.length > props.numImgs) : !props.imgs.length > 0}
                                        onValueChanged={(e) => {handleSelectBoxChange(e.value, index)}}
                                    />
                                </div>
                            : ""}
                        </div>
                    )
                    :
                    imgs.length > 0 ?
                        <div style={{display: "flex"}}>
                            <div className={"image-container-user"}>
                                <img className={"user-image"} src={imgs} alt={""}/>
                            </div>
                            <Button
                                visible={!props.disabledButton}
                                icon="close"
                                className={isLarge ? "remove-image" : "remove-image-mobile"}
                                onClick={() => handleRemoveItem(imgs)}
                            />
                        </div>
                : ""}
            </div>
        </div>
    );
};
