/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "../style.scss";
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Selection,
    Grouping,
    GroupPanel,
    ColumnFixing,
    RequiredRule,
    Item, Toolbar,
} from "devextreme-react/data-grid";
import { formatMessage } from "devextreme/localization";
import notify from "devextreme/ui/notify";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { useLoading } from "../../../contexts/loadingContext";
import dayjs from "dayjs";
import {LogMessageGrid} from "../../../utils/logMessage";
import {FavButton} from "../../../components/fav-button/favButton";
import {convertDate1900} from "../../../utils/convertDate1900";
import Button from "devextreme-react/button";

export default function Shifts(props) {
    const { loading } = useLoading();
    const { refreshToken, getRefreshTokenCode, getToken } = useAuth();
    const [load, setLoad] = useState(false);
    const [shifts, setShifts] = useState([]);
    const [company, setCompany] = useState([]);
    const _shifts = useRef();

    //Autenticação requests
    const { unitCod, userCod } = useAuth();

    useEffect(() => {
        firstLoad();
    }, []);

    useEffect(() => {
        //if (load){
            updateLoad()
        //}
    }, [load]);

    async function firstLoad(){
        if (unitCod !== undefined) {
            loadData();
            loadDataUserCompanyUnit();
            refreshToken(getToken(), getRefreshTokenCode());
        }
    }

    async function updateLoad() {
        loadData();
        //loadDataUserCompanyUnit();
    }

    async function loadData() {
        await api
            .get("/shifts", { params: { unitCod: unitCod } })
            .then((response) => {
                setShifts(response.data);
                setLoad(true);

                return false;
            })
            .catch((error) => {
                notify(formatMessage("UpdateError"), "error", 3000);
                setLoad(true);

                return true
            });
    }

    async function loadDataUserCompanyUnit() {
        await api
            .get("/companyUnit", { params: { unitCod: 0 } })
            .then((response) => {
                setCompany(response.data);
                setLoad(false);
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function updateRow(data, parLogMessage) {
        return await api
            .put("/shifts", { unitCod: unitCod, data: data, logMessage: parLogMessage })
            .then((response) => {
                setLoad(false);

                return false;
            })
            .catch((error) => {
                setLoad(!load);

                return true;
            });
    };

    async function insertRow(data, parLogMessage) {
        return await api
            .post("/shifts", { unitCod: unitCod, data: data, logMessage: parLogMessage })
            .then((response) => {
                setLoad(true);

                return false;
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    };

    async function deleteRow(data) {
        return await api
            .delete("/shifts", { data: data })
            .then((response) => {
                setLoad(true);

                return false;
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    };

    function checkInsertShift(checkbox, e){
        // turno que vai ser inserido está marcado como turno inicial?
        if(checkbox){
            shifts.forEach((item, index) => {
                // verifica se algum dos turnos na tabela está marcado como inicial
                // se estiver, desmarca e atualiza
                if(item.TipTurnoInicial === true) {
                    let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _shifts, item);
                    let newItem = {...item}
                    newItem.TipTurnoInicial = false
                    shifts[index] = newItem
                    updateRow(newItem, strLogMessage)
                }
            })
        }
    }

    async function checkUpdateShift(checkbox, shift) {
        // turno que vai ser atualizado está marcado como turno inicial?
        if (checkbox) {
            shifts.forEach((item, index) => {
                // verifica se algum dos turnos na tabela está marcado como inicial
                // se estiver, desmarca e atualiza
                if (item.TipTurnoInicial === true && item.CodTurno !== shift.CodTurno) {
                    let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _shifts, item);
                    item.TipTurnoInicial = false
                    updateRow(item, strLogMessage)
                }
            })
        }
        else {
            // se não está, algum tem que estar
            // se nenhum está, marca o turno 0
            let initialShift;
            shifts.forEach((item, index) => {
                if (item.TipTurnoInicial === true) initialShift = item
            })
            if(!initialShift) {
                let newInitialShift = {...shifts[0]}
                let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _shifts, newInitialShift);
                newInitialShift.TipTurnoInicial = true;
                await updateRow(newInitialShift, strLogMessage)
            }
        }
    }

    async function checkDeleteShift(checkbox, shift) {
        // turno inicial
        let initialShift;
        if (checkbox) {
            if (shifts) {
                shifts.forEach((item) => {
                    if (item.CodTurno === shift.CodTurno) {
                        initialShift = item;
                    }
                })
            }
            if (initialShift) {

                shifts[0].TipTurnoInicial = true
                let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _shifts, shifts[0]);
                await updateRow(shifts[0], strLogMessage)
            }
        }
    }

    const dateBoxHourOptions = {
        displayFormat: "HH:mm",
        useMaskBehavior: true,
        type: "time"
    };

    return (
        <React.Fragment>
			<div className={"ContainerButtons"}>
				<FavButton/>
			</div>
            <DataGrid
                className={"dx-cards"}
                dataSource={shifts}
                rowAlternationEnabled={true}
                showColumnLines={true}
                allowColumnReordering={true}
                showBorders={false}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                repaintChangesOnly={true}
                headerFilter={{ visible: true }}
                filterPanel={{ visible: true }}
                filterRow={{ visible: false }}
                onInitNewRow={(e) => {
                    e.data.CodUnidadeEmpresa = unitCod;
                    e.data.NomTurno = "";
                    e.data.DatHoraInicial = dayjs().format();
                    e.data.DatHoraFinal = dayjs().format();
                    e.data.TipTurnoInicial = false;
                    e.data.CodUsuario = userCod;
                }}
                editing={{
                    refreshMode: "full",
                    mode:"batch",
                    allowUpdating: true,
                    allowDeleting: true,
                    allowAdding: false,
                }}
                onRowUpdating={async (e) => {
                    const newObj = { ...e.oldData, ...e.newData };
                    newObj.DatHoraInicial = convertDate1900(newObj.DatHoraInicial)
                    newObj.DatHoraFinal = convertDate1900(newObj.DatHoraFinal)
                    let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _shifts, newObj);
                    await checkUpdateShift(e.newData.TipTurnoInicial, newObj)
                    e.cancel = await updateRow(newObj, strLogMessage);

                    e.component.deselectAll();
                }}
                onRowInserting={(e) => {
                    let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _shifts, e.data);
                    e.data.DatHoraInicial = convertDate1900(e.data.DatHoraInicial)
                    e.data.DatHoraFinal = convertDate1900(e.data.DatHoraFinal )

                    checkInsertShift(e.data.TipTurnoInicial)
                    e.cancel = insertRow(e.data, strLogMessage);

                    e.component.deselectAll();
                }}
                onRowRemoving={(e) => {
                    let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _shifts, e.data)
                    const data = { unitCod: unitCod,  data: e.data, logMessage: strLogMessage }
                    checkDeleteShift(e.data.TipTurnoInicial, e.data)
                    e.cancel = deleteRow(data);

                    e.component.deselectAll();
                }}
                ref={_shifts}
            >
                <Grouping
                    contextMenuEnabled={false}
                    expandMode="rowClick"
                    autoExpandAll={false}
                />
                <GroupPanel visible={false} />
                <ColumnFixing enabled={false} />
                <Selection mode="multiple" />
                <Paging defaultPageSize={20} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />
                <Toolbar >
                    <Item cssClass={'add-container'}>
                        <Button className={'add-button'}  icon={"add"} onClick={() => (_shifts.current.instance.addRow())}/>
                    </Item>
                    <Item name="saveButton"/>
                    <Item name="revertButton"/>
                    <Item name="exportButton"/>
                    <Item name="columnChooserButton"/>
                </Toolbar>
                <Column
                    dataField={"NomTurno"}
                    caption={"Nome do Turno"}
                    allowSorting={true}
                    allowEditing={true}
                    width={"auto"}
                >
                    <RequiredRule />
                </Column>
                <Column
                    dataField={"DatHoraInicial"}
                    caption={"Hora Inicial"}
                    allowSorting={true}
                    allowEditing={true}
                    allowAdding={true}
                    width={"auto"}
                    editorType={"dxDateBox"}
                    dataType={"datetime"}
                    format={"HH:mm"}
                    editorOptions={dateBoxHourOptions}
                    // editCellRender={hourBoxEditorRender}
                >
                    <RequiredRule />
                </Column>
                <Column
                    dataField={"DatHoraFinal"}
                    caption={"Hora Final"}
                    allowSorting={true}
                    allowEditing={true}
                    allowAdding={true}
                    width={"auto"}
                    editorType={"dxDateBox"}
                    dataType={"datetime"}
                    format={"HH:mm"}
                    editorOptions={dateBoxHourOptions}
                >
                    <RequiredRule />
                </Column>
                <Column
                    dataField={"TipTurnoInicial"}
                    caption={"Turno Inicial"}
                    allowSorting={true}
                    allowEditing={true}
                    dataType={"boolean"}
                    width={"5%"}
                >
                </Column>
            </DataGrid>
            <div className="load-indicator">
                <LoadIndicator visible={loading} height={40} width={40} />
            </div>
        </React.Fragment>
    )

};
