/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./style.scss";
import { Card, makeStyles, CardActionArea, CardContent, Typography, CardActions } from "@material-ui/core";
import { useAuth } from "../../contexts/auth";
import { api } from "../../services/api";
import { navigation } from "../../app-navigation";
import logo from "../../icons/star.svg";
import {useScreenSize} from "../../utils/media-query";

const useStyles = makeStyles({
	desktop: {
		minWidth: "24%",
		width: "auto",
		height: "20%",
		margin: "1vh",
	},
	tablet: {
		width: "80%",
		height: "20%",
		margin: "1vh",
	},
	mobile: {
		width: "100vw",
		height: "20%",
		margin: "1vh",
	},
	media: {
		height: 140,
	},
});
export const FavCard = () => {
	const { userCod } = useAuth();
	const [favorites, setFavorites] = useState([]);
	const [load, setLoad] = useState(true);
	const classes = useStyles();
	const { isXSmall, isLarge } = useScreenSize();
	useEffect(() => {
		if (load) loadDataFavorites();
	}, [load]);

	async function loadDataFavorites() {
		return await api
			.get("/favoriteuser/get", { params: { userId: userCod } })
			.then((response) => {
				setLoad(false);
				setFavorites(response.data);
			})
			.catch(() => {
				setLoad(true);
				return true;
			});
	}

	function findNavFavorite(array, route) {
		let fav_found;
		array.forEach((element) => {
			if (element.items) {
				element.items.forEach((item) => {
					if (item.path === `/${route}`) {
						fav_found = item;
						fav_found.menuName = element.text;
					}
				});
			} else {
				if (element.path === `/${route}`) {
					fav_found = element;
				}
			}
		});
		return fav_found;
	}

	function newURL(favPath) {
		let fullURL = window.location.href;
		let lastIndex = window.location.href.lastIndexOf("/");
		return fullURL.substring(0, lastIndex) + favPath;
	}
	return (
		<React.Fragment>
			<div className="form-container">
			{favorites.map((favorite, index) => {
				let fav_found = findNavFavorite(navigation, favorite.NomRota);
				if (!fav_found) return null;

				let newUrl = newURL(fav_found.path);
				if(fav_found.text.length > 25){
					fav_found.text = fav_found.text.substring(0,23) + "...";
				}

				return (
					<Card
						key={index}
						aria-hidden={true}
						className={ isLarge ? classes.desktop : isXSmall ? classes.mobile : classes.tablet }
						onClick={() => window.location.href = newUrl}
					>
						<CardActionArea>
							<CardActions style={{ width: "100%", height: "100%" }}>
								<CardContent>
									<div style={{ display: "inline-block", marginTop: "-1vh", marginLeft: "2vh", marginRight: "2vh" }}>
										<Typography variant="h5" component="h2">
											<img src={logo} className={JSON.parse(localStorage.getItem("@es-mes/darkMode")) ? "dark-mode" : ""} alt="favoritos" width={isLarge ? "40px" : isXSmall ? "20px" : "30px"} height={isLarge ? "40px" : isXSmall ? "20px" : "30px"}  />
										</Typography>
									</div>
									<div style={{ display: "inline-block", marginRight: '2vh'}}>
										<Typography variant="h5" component={isLarge ? "h3" : "h5"} style={{marginTop: '-6vh'}}>
											<strong> {fav_found.text} </strong>
										</Typography>
										<Typography variant="body2" color="textSecondary" component="p">
											{fav_found.menuName}
										</Typography>
									</div>
								</CardContent>
							</CardActions>
						</CardActionArea>
					</Card>
				);
			})}
			</div>
		</React.Fragment>
	);
};
