/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react";
import "devextreme/data/odata/store";
import "./style.scss";
import {FavButton} from "../../../components/fav-button/favButton";
import {useAuth} from "../../../contexts/auth";
import {useLoading} from "../../../contexts/loadingContext";
import {LoadIndicator} from "devextreme-react/load-indicator";
import {api} from "../../../services/api";
import {LogMessageGrid} from "../../../utils/logMessage";
import {formatMessage} from "devextreme/localization";
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, CustomRule,
    Editing, FilterRow,
    Form,
    Grouping,
    GroupPanel,
    Item, Pager,
    Paging, RequiredRule, Toolbar
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";

export default function Indicators() {
    const _indicators = useRef();
    const { loading } = useLoading();
    const [load, setLoad] = useState(false);
    const [indicators, setIndicators] = useState(false);
    const { unitCod, refreshToken, getRefreshTokenCode, getToken } = useAuth();

    useEffect(() => {
        refreshToken(getToken(), getRefreshTokenCode());
    }, []);

    useEffect(() => {
        loadData();
    }, [load]);

    async function loadData() {
        await api
            .get("indicators", { params: { unitCod: unitCod } })
            .then((response) => {
                setIndicators(response.data);
            })
            .catch(() => {
                return true;
            });
    }

    async function updateRow(data, parLogMessage) {
        return await api
            .put("indicators", {
                unitCod: unitCod,
                data: data,
                logMessage: parLogMessage,
            })
            .then((response) => {
                setLoad(!load)
                return false;
            })
            .catch(() => {
                return true;
            });
    }

    async function insertRow(data, parLogMessage) {
        return await api
            .post("indicators", {
                unitCod: unitCod,
                data: data,
                logMessage: parLogMessage,
            })
            .then((response) => {
                setLoad(!load)
                return false;
            })
            .catch(() => {
                return true;
            });
    }

    async function deleteRow(data) {
        return await api
            .delete("indicators", { data: data })
            .then(() => {
                setLoad(!load)
                return false;
            })
            .catch(() => {
                return true;
            });
    }

    function checkUniqueKey(params) {
        let check = true;

        indicators.forEach((indicator) => {
            if (indicator.CodIndicador !== params.data.CodIndicador && params.column.dataField === "DesEndereco") {
                if (indicator.DesEndereco === params.value) check = false;
            }

            if (indicator.CodIndicador !== params.data.CodIndicador && params.column.dataField === "NomIndicador") {
                if (indicator.NomIndicador === params.value) check = false;
            }
        });

        return check;
    }

    return (
        <React.Fragment>
            <FavButton />
            <DataGrid
                className={"dx-cards"}
                dataSource={indicators}
                rowAlternationEnabled={true}
                showColumnLines={true}
                allowColumnReordering={true}
                showBorders={false}
                columnHidingEnabled={true}
                repaintChangesOnly={true}
                onInitNewRow={(e) => {
                    e.data.NomIndicador = "";
                    e.data.DesEndereco = "";
                    e.data.NumOrdem = "";
                    e.data.TipLinkExterno = false;
                }}
                headerFilter={{ visible: true }}
                filterPanel={{ visible: true }}
                filterRow={{ visible: false }}
                onRowUpdating={async (e) => {
                    const newObj = { ...e.oldData, ...e.newData };
                    let strLogMessage = LogMessageGrid(
                        formatMessage("UpdatedLog"),
                        _indicators,
                        newObj
                    );

                    e.cancel = updateRow(newObj, strLogMessage);

                    e.component.deselectAll();
                }}
                onRowInserting={async (e) => {
                    let strLogMessage = LogMessageGrid(
                        formatMessage("InsertedLog"),
                        _indicators,
                        e.data
                    );

                    e.cancel = insertRow(e.data, strLogMessage);

                    e.component.deselectAll();
                }}
                onRowRemoving={(e) => {
                    let strLogMessage = LogMessageGrid(
                        formatMessage("DeletedLog"),
                        _indicators,
                        e.data
                    );
                    const data = {
                        unitCod: unitCod,
                        data: e.data,
                        logMessage: strLogMessage,
                    };

                    e.cancel = deleteRow(data);

                    e.component.deselectAll();
                }}
                ref={_indicators}
            >
                <Editing
                    mode={"batch"}
                    allowUpdating={true}
                    allowDeleting={true}
                    allowAdding={false}
                >
                    <Form>
                        <Item itemType="group" colCount={6} colSpan={2}>
                            <Item colSpan={2} dataField="NomIndicador" />
                            <Item colSpan={2} dataField="DesEndereco" />
                            <Item colSpan={2} dataField="NumOrdem" />
                        </Item>
                    </Form>
                </Editing>
                <Grouping
                    contextMenuEnabled={false}
                    expandMode="rowClick"
                    autoExpandAll={false}
                />
                <GroupPanel visible={false} />
                <ColumnChooser enabled={true} mode="select" />
                <ColumnFixing enabled={false} />
                <Paging defaultPageSize={20} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />
                <Toolbar >
                    <Item cssClass={'add-container'}>
                        <Button className={'add-button'}  icon={"add"} onClick={() => (_indicators.current.instance.addRow())}/>
                    </Item>
                    <Item name="saveButton"/>
                    <Item name="revertButton"/>
                    <Item name="exportButton"/>
                    <Item name="columnChooserButton"/>
                </Toolbar>
                <Column
                    dataField={"NomIndicador"}
                    caption={"Indicador"}
                    allowSorting={true}
                    allowFiltering={true}
                    allowSearch={true}
                    allowGrouping={true}
                    allowEditing={true}
                >
                    <CustomRule
                        message={"Já existe registros cadastrados com o mesmo valor"}
                        reevaluate={true}
                        type={"custom"}
                        validationCallback={checkUniqueKey}
                    />
                    <RequiredRule />
                </Column>
                <Column
                    dataField={"DesEndereco"}
                    caption={"Endereço"}
                    allowSorting={true}
                    allowFiltering={true}
                    allowSearch={true}
                    allowGrouping={true}
                    allowEditing={true}
                >
                    <CustomRule
                        message={"Já existe registros cadastrados com o mesmo valor"}
                        reevaluate={true}
                        type={"custom"}
                        validationCallback={checkUniqueKey}
                    />
                    <RequiredRule />
                </Column>
                <Column
                    dataField={"NumOrdem"}
                    caption={"Ordem"}
                    allowSorting={true}
                    allowFiltering={true}
                    allowSearch={true}
                    allowGrouping={true}
                    allowEditing={true}
                >
                    <RequiredRule />
                </Column>
                <Column
                    dataField={"TipLinkExterno"}
                    caption={"Link Externo"}
                    dataType={"boolean"}
                    allowSorting={true}
                    allowFiltering={true}
                    allowSearch={true}
                    allowGrouping={true}
                    allowEditing={true}
                >
                    
                </Column>
            </DataGrid>
            <div className="load-indicator">
                <LoadIndicator visible={loading} height={40} width={40} />
            </div>
        </React.Fragment>
    );
}
