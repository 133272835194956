import React, { useState, useEffect, useRef } from 'react';
import "../sampleInputs/style.scss";
import "../../pages/quickGuides/style.scss"
import { useDataLoader } from '../../contexts/dataLoaderContext';
import PopupCheckSample from '../../pages/laboratory/sampleInputs/popUpCheckSamples';

export default function PopupListVisualizer(props) {
    const [lstSearchedPages, setLstSearchedPages] = useState([]);
    const [visiblePopupCheckSample, setVisibleCheckSample] = useState(false);
    const [responsePopupCheckSample, setResponsePopupCheckSample] = useState(false);
    const { listToSaveSample } = useDataLoader();
    const _value = useRef({});

    useEffect(() => {
        setLstSearchedPages(props.lstPage);
    }, []);

    useEffect(() => {
        if(responsePopupCheckSample) {
            props.redirectToPage(_value.current.Code, _value.current.Name);
            _value.current = {};
            listToSaveSample.current = [];
        };

        setVisibleCheckSample(false);
        setResponsePopupCheckSample(false);
    }, [responsePopupCheckSample]);

    return (
        <div>
            <div style={{width: "50%"}}>
                {lstSearchedPages.map((value, i) => {
                    return (
                        <span
                            key={i}
                            style={{cursor: 'pointer'}}
                        >
                            {i <= (lstSearchedPages.length -1) ? ` | ` : ''}
                            <span
                                className='text-menu-analysis'
                                id={props.strPageSelected === value.Name ? "pageSelected" : ""}
                                onClick={() => {
                                    if(listToSaveSample.current.length === 0) {
                                        props.redirectToPage(value.Code, value.Name);
                                    } else {
                                        setVisibleCheckSample(true);
                                        _value.current = value;
                                    };
                                }}
                            >
                                {value.Name} 
                            </ span>
                        </span>
                    )
                })}
            </div>
            <PopupCheckSample 
                visibleCheckSample={visiblePopupCheckSample}
                setVisibleCheckSample={setVisibleCheckSample}
                setResponsePopupCheckSample={setResponsePopupCheckSample}
            />
        </div>
    );
}