/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "./style.scss";
import DataGrid, {
  Column,
  Pager,
  RequiredRule,
  CustomRule,
  Paging,
  FilterRow,
  Export,
  Selection,
  ColumnChooser,
  Grouping,
  GroupPanel,
  ColumnFixing,
  Lookup,
  Form,
  Editing,
  Popup,
  Position,
  Item, Toolbar,
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { LogMessageGrid } from "../../../utils/logMessage"
import { formatMessage } from "devextreme/localization";
import { useLoading } from "../../../contexts/loadingContext";
import { useScreenSize } from "../../../utils/media-query";

export default function ConfigurationAutomatic(props) {
  const { loading } = useLoading();
  const [load, setLoad] = useState(false);
  const { isLarge } = useScreenSize();
  const [readingVariableOpc, setReadingVariableOpc] = useState([]);
  const [groupConnectionDS, setGroupConnectionDS] = useState([]);
  const [readingVariable, setReadingVariable] = useState([]);
  const [type, setType] = useState([]);
  const _monitoration = useRef();
  const dataSourceVariableType = [
    { Id: 0, Value: "Processo" },
    { Id: 1, Value: "Parada" },
  ];

  //Autenticação requests
  const { unitCod } = useAuth();

  useEffect(() => {
    firstLoad();
   }, []);

  useEffect(() => {
    if (load) {
      loadData();
    }
  }, [load]);

  //#region Requests
  async function firstLoad(){
    loadDataSourceReadingVariable();
    getGroupConnections();
    await loadDataSourceType();
    loadData();
  }

  async function loadData() {
    await api
      .get("/readingvariableOPC", { params: { unitCod: unitCod } })
      .then((response) => {
        setReadingVariableOpc(response.data);
        setLoad(false);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function loadDataSourceType() {
    await api
      .get("/automaticVariables/type")
      .then((response) => {
        setType(response.data);
        setLoad(false);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function getGroupConnections() {
    await api
      .get("/automaticVariables")
      .then((response) => {
        setGroupConnectionDS(response.data);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function loadDataSourceReadingVariable() {
    await api
      .get("/readingVariable/getAutomaticVariables", { params: { unitCod: unitCod } })
      .then((response) => {
        setReadingVariable(response.data);
        setLoad(false);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/readingvariableOPC", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(false);

        return true;
      });
  }

  async function updateRow(data, parLogMessage) {
    return await api
      .put("/readingvariableOPC", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(false);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }
  //#endregion

  function checkZeroValue(params) {
    return params.data.ValPeriodo > 0;
  }

  function checkUniqueKey(params) {
    return !readingVariableOpc.some(unit => ( 
      unit.CodVariavelLeitura !== params.data.CodVariavelLeitura && 
      unit.DesStringConexao === params.data.DesStringConexao && 
      unit.CodOpcConexaoGrupo === params.data.CodOpcConexaoGrupo
    ))
  }

  return (
    <React.Fragment>
      <DataGrid
        className={"dx-cards"}
        dataSource={readingVariableOpc}
        rowAlternationEnabled={true}
        showColumnLines={true}
        allowColumnReordering={true}
        showBorders={false}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        repaintChangesOnly={true}
        allowColumnResizing={true}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        /* editing={{
          refreshMode: "full",
          mode:"batch",
          allowUpdating: true,
          allowDeleting: false,
          allowAdding: false,
        }} */
        onInitNewRow={(e) => {
          e.data.TipUnidadePeriodo = 3
        }}
        onRowUpdating={async (e) => {
          const newObj = { ...e.oldData, ...e.newData };
          let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _monitoration, newObj);

          e.cancel = updateRow(newObj, strLogMessage);

          e.component.deselectAll();
        }}
        onRowInserting={async (e) => {
          let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _monitoration, e.data);

          e.cancel = insertRow(e.data, strLogMessage);

          e.component.deselectAll();
        }}
        ref={_monitoration}
      >
        <Editing mode={isLarge ? "batch" : "popup"} allowUpdating={true} allowDeleting={false} allowAdding={false}>
						<Popup
							title="Cadastro de Unidade"
							showTitle={true}
							width={700}
							height={750}
							fullScreen={true}>
							<Position my="center" at="center" of={window} />
						</Popup>
						<Form>
							<Item itemType="group" colCount={4} colSpan={2}>
								<Item colSpan={2} dataField="CodVariavelLeitura" />
								<Item colSpan={2} dataField="ValPeriodo" />
								<Item colSpan={2} dataField="TipUnidadePeriodo" />
								<Item colSpan={2} dataField="ValBandaMorta" />
								<Item colSpan={2} dataField="DesStringConexao" />
								<Item colSpan={2} dataField="TipMonitoracaoPeriodico" />
								<Item colSpan={1} dataField="CodOpcConexaoGrupo" />
								<Item colSpan={1} dataField="TipVariavelLeituraOpc" />
							</Item>
						</Form>
					</Editing>
        <Grouping
          contextMenuEnabled={false}
          expandMode="rowClick"
          autoExpandAll={false}
        />
        <GroupPanel visible={false} />
        <Export
          enabled={true}
          fileName="Processos Parada"
          allowExportSelectedData={true}
        />
        <ColumnChooser enabled={true} mode="select" />
        <ColumnFixing enabled={false} />
        <Selection mode="multiple" />
        <Paging defaultPageSize={20} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <Toolbar >
          <Item name="saveButton"/>
          <Item name="revertButton"/>
          <Item name="exportButton"/>
          <Item name="columnChooserButton"/>
        </Toolbar>
        <Column
          dataField={"CodVariavelLeitura"}
          caption={"Nome/Descrição Variável"}
          allowSorting={true}
          allowEditing={false}
          width={"auto"}
        >
          <Lookup
            dataSource={readingVariable}
            displayExpr={(item) => item && item.NomVariavelLeitura + ' - ' + item.DesVariavelLeitura}
            valueExpr={"CodVariavelLeitura"}
          />
          <RequiredRule />
        </Column>
        <Column
          dataField={"DesStringConexao"}
          caption={"String Conexão"}
          allowSorting={true}
          allowEditing={true}
          width={250}
        >
          <CustomRule
            message={'Já existe uma variável com a mesma String de conexão e Grupo Conexão'}
            reevaluate={true}
            type={"custom"}
            validationCallback={checkUniqueKey}
          />
        </Column>
        <Column
          dataField={"CodOpcConexaoGrupo"}
          caption={"Grupo Conexão"}
          allowSorting={true}
          allowEditing={true}
          width={300}
        >
          <CustomRule
            message={'Já existe uma variável com a mesma String de conexão e Grupo Conexão'}
            reevaluate={true}
            type={"custom"}
            validationCallback={checkUniqueKey}
          />
          <Lookup
            dataSource={groupConnectionDS}
            displayExpr={"NomOpcConexaoGrupo"}
            valueExpr={"CodOpcConexaoGrupo"}
          />
        </Column>
        <Column
          dataField={"TipVariavelLeituraOpc"}
          caption={"Tipo Variável"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
        >
          <Lookup
            dataSource={dataSourceVariableType}
            displayExpr={"Value"}
            valueExpr={"Id"}
          />
        </Column>
        <Column
          dataField={"TipMonitoracaoPeriodico"}
          caption={"Monitoração por Período? (Por evento - desmarcar)"}
          allowSorting={true}
          allowEditing={true}
          dataType={"boolean"}
          width={"auto"}
        >
        </Column>
        <Column
          dataField={"ValPeriodo"}
          caption={"Período"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
          dataType={"number"}
        >
          <RequiredRule />
          <CustomRule
            message={'Valor deve ser maior que zero'}
            reevaluate={true}
            validationCallback={checkZeroValue}
          />
        </Column>
        <Column
          dataField={"TipUnidadePeriodo"}
          caption={"Unidade Período"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
        >
          <Lookup
            dataSource={type}
            displayExpr={"NomTipo"}
            valueExpr={"TipUnidadePeriodo"}
          />
        </Column>
        <Column
          dataField={"ValBandaMorta"}
          caption={"Banda Morta"}
          allowSorting={true}
          allowEditing={true}
          width={"auto"}
          dataType={"number"}
        >
          <RequiredRule />
        </Column>

      </DataGrid>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  )
};
