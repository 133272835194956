/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useRef} from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import {LoadIndicator} from "devextreme-react/load-indicator";
import "../style.scss";
import DataGrid, {
    Column,
    Pager,
    RequiredRule,
    Paging,
    Export,
    Selection,
    ColumnChooser,
    Grouping,
    Lookup,
    GroupPanel,
    ColumnFixing,
    CustomRule,
    Editing,
    Form,
    Popup,
    Position,
    Item, Toolbar,
} from "devextreme-react/data-grid";
import { formatMessage} from "devextreme/localization";
import {api} from "../../../services/api";
import {useAuth} from "../../../contexts/auth";
import {LogMessageGrid} from "../../../utils/logMessage";
import {useLoading} from "../../../contexts/loadingContext";
import {loadDataSectors} from "../../../utils/functionRequests";
import {useScreenSize} from "../../../utils/media-query";

import Button from "devextreme-react/button";

export default function Process(props) {
    const {loading} = useLoading();
    const [load, setLoad] = useState(false);
    const {isLarge} = useScreenSize();
    const [process, setProcess] = useState([]);
    const [dataSourceSector, setDataSourceSector] = useState([]);
    const [dataSourceReadVariable, setDataSourceReadVariable] = useState([]);
    const _process = useRef();
    const {unitCod} = useAuth();

    useEffect(() => {
        async function loadSectors() {
            loadDataSectors(unitCod, setDataSourceSector, setLoad);
            await loadDataSourceReadVariable();
            loadData();
        }
        loadSectors();
    }, [props.update]);

    useEffect(() => {
        if(load) loadData();
    }, [load]);

    async function loadDataSourceReadVariable() {
        await api
            .get("/readingvariableOPC/getVariableOPC", {params: {unitCod: unitCod, tipVariableOPC: 1}})
            .then((response) => {
                setDataSourceReadVariable(response.data);
                setLoad(false);
            })
            .catch(() => {
                setLoad(true);
                return true;
            });
    }

    async function loadData() {
        await api
            .get("/process", {params: {unitCod: unitCod}})
            .then((response) => {
                setProcess(response.data);
            })
            .catch(() => {
                return true;
            });
    }

    async function updateRow(data, parLogMessage) {
        return await api
            .put("/process", {unitCod: unitCod, data: data, logMessage: parLogMessage})
            .then(() => {
                props.setUpdate(!props.update)
                return false;
            })
            .catch(() => {
                return true;
            });
    }

    async function insertRow(data, parLogMessage) {
        return await api
            .post("/process", {unitCod: unitCod, data: data, logMessage: parLogMessage})
            .then(() => {
                props.setUpdate(!props.update)
                return false;
            })
            .catch(() => {
                return true;
            });
    }

    async function deleteRow(data) {
        return await api
            .delete("/process", {data: data})
            .then(() => {
                props.setUpdate(!props.update)
                return false;
            })
            .catch(() => {
                return true;
            });
    }

    const onEditorPreparing = (e) => {
        if (e.dataField === "CodVariavelLeitura" && e.parentType === "dataRow") {
            e.editorOptions.disabled = !e.row.data.TipFuncionamentoAutomatico;
            e.editorOptions.value = null;
        }

        if (e.dataField === "TipFuncionandoAutomatico" && e.parentType === "dataRow") e.editorOptions.disabled = !e.row.data.TipPermiteParada;
    };

    function setCellValue(newData, value) {
        newData.TipPermiteParada = value;
        newData.TipFuncionamentoAutomatico = false;
        this.defaultSetCellValue(newData, value);
    }

    function setCellValueTipFuncionandoAutomatico(newData, value) {
        newData.TipFuncionamentoAutomatico = value;
        this.defaultSetCellValue(newData, value);
    }

    function checkUniqueKey(params) {
        let check = true;

        process.forEach((unit) => {
            if (
                unit.CodProcesso !== params.data.CodProcesso &&
                unit.NomProcesso === params.data.NomProcesso &&
                unit.CodSetor === params.data.CodSetor
            )
                check = false;
        });

        return check;
    }

    function checkUniqueKeyIntegration(params) {
        let check = true;

        process.forEach((unit) => {
            if (
                unit.CodProcesso !== params.data.CodProcesso &&
                unit.CodProcessoIntegracao === params.data.CodProcessoIntegracao &&
                params.data.CodProcessoIntegracao !== null
            )
                check = false;
        });

        return check;
    }

    return (
        <React.Fragment>
            <div id="processGrid">
                <DataGrid
                    className={"dx-cards"}
                    dataSource={process}
                    rowAlternationEnabled={true}
                    showColumnLines={true}
                    allowColumnReordering={true}
                    showBorders={false}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    repaintChangesOnly={false}
                    onEditorPreparing={onEditorPreparing}
                    onInitNewRow={(e) => {
                        e.data.CodUnidadeEmpresa = unitCod;
                        e.data.TipPermiteParada = true;
                        e.data.TipFuncionando = true;
                        e.data.TipFuncionamentoAutomatico = false;
                    }}
                    headerFilter={{visible: true}}
                    filterPanel={{visible: false}}
                    filterRow={{visible: false}}
                    onRowUpdating={async (e) => {
                        const newObj = {...e.oldData, ...e.newData};
                        let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _process, newObj);

                        e.cancel = updateRow(newObj, strLogMessage);

                        e.component.deselectAll();
                    }}
                    onRowInserting={(e) => {
                        let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _process, e.data);

                        e.cancel = insertRow(e.data, strLogMessage);

                        e.component.deselectAll();
                    }}
                    onRowRemoving={(e) => {
                        let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _process, e.data);
                        const data = {unitCod: unitCod, data: e.data, logMessage: strLogMessage};

                        e.cancel = deleteRow(data);

                        e.component.deselectAll();
                    }}
                    ref={_process}>
                    <Editing mode={isLarge ? "batch" : "popup"} allowUpdating={true} allowDeleting={true} allowAdding={false}>
                        <Popup
                            title="Cadastro de Processo"
                            showTitle={true}
                            width={700}
                            height={750}
                            fullScreen={true}>
                            <Position my="center" at="center" of={window}/>
                        </Popup>
                        <Form>
                            <Item itemType="group" colCount={4} colSpan={2}>
                                <Item colSpan={2} dataField="NomProcesso"/>
                                <Item colSpan={2} dataField="TipPermiteParada"/>
                                <Item colSpan={2} dataField="TipFuncionamentoAutomatico"/>
                                <Item colSpan={2} dataField="CodSetor"/>
                                <Item colSpan={2} dataField="CodVariavelLeitura"/>
                                <Item colSpan={2} dataField="CodProcessoIntegracao"/>
                                <Item colSpan={1} dataField="TipFuncionando"/>
                            </Item>
                        </Form>
                    </Editing>
                    <Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false}/>
                    <GroupPanel visible={false}/>
                    <Export enabled={true} fileName="Cadastro_Processos" allowExportSelectedData={true}/>
                    <ColumnChooser enabled={true} mode="select"/>
                    <ColumnFixing enabled={false}/>
                    <Selection mode="multiple"/>
                    <Paging defaultPageSize={20}/>
                    <Pager showPageSizeSelector={true} showInfo={true}/>
                    <Toolbar>
                        <Item cssClass={'add-container'}>
                            <Button className={'add-button'} icon={"add"}
                                    onClick={() => (_process.current.instance.addRow())}/>
                        </Item>
                        <Item name="saveButton"/>
                        <Item name="revertButton"/>
                        <Item name="exportButton"/>
                        <Item name="columnChooserButton"/>
                    </Toolbar>
                    <Column dataField={"NomProcesso"} caption={"Nome Processo"} hidingPriority={6} allowSorting={true}>
                        <CustomRule
                            message={"A combinação entre o Nome do Processo e Setor deve ser única"}
                            reevaluate={true}
                            type={"custom"}
                            validationCallback={checkUniqueKey}
                        />
                        <RequiredRule/>
                    </Column>
                    <Column
                        dataField={"TipPermiteParada"}
                        caption={"Permite Parada?"}
                        dataType={"boolean"}
                        setCellValue={setCellValue}
                        allowSorting={true}
                        hidingPriority={5}
                        width={"auto"}></Column>
                    <Column
                        dataField={"TipFuncionamentoAutomatico"}
                        caption={"Parada Automática"}
                        dataType={"boolean"}
                        cssClass={"word-wrap"}
                        setCellValue={setCellValueTipFuncionandoAutomatico}
                        allowSorting={true}
                        hidingPriority={4}
                        width={"auto"}/>
                    <Column dataField={"CodSetor"} caption={"Setor"} hidingPriority={3} allowSorting={true} sortIndex={0}>
                        <Lookup dataSource={dataSourceSector} displayExpr="NomSetor" valueExpr="CodSetor"/>
                        <RequiredRule/>
                    </Column>
                    <Column
                        dataField={"CodVariavelLeitura"}
                        caption={"Variável Leitura"}
                        hidingPriority={2}
                        allowSorting={true}
                        sortIndex={0}
                    >
                        <Lookup
                            dataSource={dataSourceReadVariable}
                            allowClearing={true}
                            displayExpr="NomVariavelLeitura"
                            valueExpr="CodVariavelLeitura"
                        />
                    </Column>
                    <Column 
                        dataField={"CodProcessoIntegracao"}
                        caption={"Código Integração"}
                        hidingPriority={1}
                        allowSorting={true}
                        dataType={"number"}
                    >
                        <CustomRule
                            message={"Este campo deve ser único"}
                            reevaluate={true}
                            type={"custom"}
                            validationCallback={checkUniqueKeyIntegration}
                        />
                    </Column>
                    <Column
                        dataField={"TipFuncionando"}
                        caption={"Ativo"}
                        dataType={"boolean"}
                        width={"auto"}
                        //setCellValue={setCellValue}
                        allowSorting={true}
                        hidingPriority={0}></Column>
                </DataGrid>
            </div>
            <div className="load-indicator">
                <LoadIndicator visible={loading} height={40} width={40}/>
            </div>
        </React.Fragment>
    );
}
