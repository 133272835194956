/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { ButtonList } from "./buttonList";
import { LoadIndicator } from "devextreme-react/load-indicator";
//import "./style.scss";
import { api } from "../../../services/api";
import { useDataLoader } from "../../../contexts/dataLoaderContext";
import dayjs from "dayjs";
import { useAuth } from "../../../contexts/auth";
import HeaderDateBox from "../../../components/header-datebox/header-datebox";
import { FavButton } from "../../../components/fav-button/favButton";
import { ReportsButton } from "../../../components/reports-button/reportsButton";

export default function Sample() {
	const [load, setLoad] = useState()
	const [loading, setLoading] = useState(false)
	const [lstVariables, setLstVariables] = useState([])
	let _lstGroupAnalysis = useRef([])
	const [currencyValueDate, setCurrencyValueDate] = useState(dayjs())
	let _refHeaderDateBoxValue = useRef()
	let _refHeaderSelectBox = useRef()
	let _lstProcess = useRef([])
	const [searchProcessText, setSearchProcessText] = useState()
	const [lstProcess, setLstProcess] = useState()
	const [processCodeFilter, setProcessCodeFilter] = useState(0)
	const [variableCodeFilter, setVariableCodeFilter] = useState("")
	const [secondaryButtons, setSecondaryButtons] = useState();
	const [searchAnalysisGroupText, setSearchAnalysisGroupText] = useState()
	const [actionOnlyViewer, setActionOnlyViewer] = useState(false)
	
	const {
		listToSaveSample,
		numLot
	} = useDataLoader()
	const { unitCod, userCod } = useAuth()
	const [screen, setScreen] = useState()
	//const [listDescObservation, setListDescObservation] = useState([]);

	//Faz o filtro do botão Pesquisar
	useEffect(() => {
		setLstProcess(_lstProcess.current.filter(lstProcess => lstProcess.NomProcesso.toUpperCase().indexOf(searchProcessText.toUpperCase()) > -1))
	}, [searchProcessText])

	useEffect(() => {
		async function firstLoad() {
			if (!load) {
				await setFirstFunctions();
				await setsFirstLoad();
				listToSaveSample.current.length = 0;
				//refreshToken(getToken(), getRefreshTokenCode());
			}
		}
		firstLoad();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currencyValueDate, numLot]);

	async function setFirstFunctions() {
		getGroupAnalysis()
		loadDataAction(211)
		await getLoadProcess()
	}

	async function setsFirstLoad() {
		if(!secondaryButtons){
			setScreen("process")
		} 
	}

	//#region requests
	async function getGroupAnalysis() {
		await api
			.get("/sample/groupanalysis", { params: { unitCod: unitCod, userId: userCod } })
			.then((response) => {
				_lstGroupAnalysis.current = response.data;
				setLstVariables(response.data)
			})
			.catch(() => {
				return true;
			});
	}

	async function getLoadProcess() {
		await api
			.get("/sample/load", { params: { unitCod: unitCod, userId: userCod } })
			.then((response) => {
				_lstProcess.current = response.data;
			})
			.finally(() => {
				setLoad(false)
			})
			.catch(() => {
				return true;
			});
	}

	async function loadDataAction(actionCod) {
		await api
		  .get("/actionPermission/getActionsByUser", { params: { userCod: userCod, actionCod: actionCod } })
		  .then((response) => {
			if(response.data) {
				setActionOnlyViewer(true);
			}
		  })
		  .catch(() => {
			return true;
		  });
	  }
	

	//#endregion

	return (
		<React.Fragment>
			<HeaderDateBox
				screen={screen}
				setCurrencyValueDate={setCurrencyValueDate}
				currencyValueDate={currencyValueDate}
				setLoad={setLoad}
				load={load}
				setLoading={setLoading}
				setSearchProcessText={setSearchProcessText}
				setSearchAnalysisGroupText = {setSearchAnalysisGroupText}
				setProcessCodeFilter={setProcessCodeFilter}
				setVariableCodeFilter={setVariableCodeFilter}
				refHeaderSelectBox={_refHeaderSelectBox}
				refHeaderDateBoxValue={_refHeaderDateBoxValue}
				secondaryButtons={secondaryButtons}
				byDate={false}
				sampleScreen={true}
			/>
			<div className={"ContainerButtons"}>
				<FavButton/>
				<ReportsButton />
			</div>
			<div>
				<ButtonList
					list={!lstProcess || lstProcess.length === 0 ? _lstProcess.current : lstProcess}
					screen={screen}
					setScreen={setScreen}
					variableCodeFilter={variableCodeFilter}
					setLstVariables={setLstVariables}
					searchAnalysisGroupText={searchAnalysisGroupText}
					setSearchProcessText={setSearchProcessText}
					setSearchAnalysisGroupText = {setSearchAnalysisGroupText}
					lstAllGroupAnalysis={lstVariables}
					setVariableCodeFilter={setVariableCodeFilter}
					lstGroupAnalysis={_lstGroupAnalysis.current}
					setLoad={setLoad}
					load={load}
					refHeaderSelectBox={_refHeaderSelectBox.current}
					refHeaderDateBoxValue={_refHeaderDateBoxValue.current}
					currencyValueDate={currencyValueDate}
					setCurrencyValueDate={setCurrencyValueDate}
					userCod={userCod}
					setLoading={setLoading}
					setSecondaryButtons={setSecondaryButtons}
					secondaryButtons={secondaryButtons}
					actionOnlyViewer={actionOnlyViewer}
				/>
			</div>
			<div className="load-indicator">
				<LoadIndicator visible={loading} height={40} width={40} />
			</div>
		</React.Fragment>
	);
}
