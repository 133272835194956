/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "../../../components/common/style.scss";
import "devextreme/data/odata/store";
import {LogMessageGrid} from "../../../utils/logMessage";
import {formatMessage} from "devextreme/localization";
import DataGrid, {
    Column,
    ColumnChooser, ColumnFixing, Editing,
    FilterRow,
    Form,
    Grouping,
    GroupPanel,
    Item, Pager, Paging,
    RequiredRule, Toolbar,
    Lookup,
    Export,
    CustomRule,
    Selection
} from "devextreme-react/data-grid";
import {api} from "../../../services/api";
import {useLoading} from "../../../contexts/loadingContext";
import {useAuth} from "../../../contexts/auth";
import {LoadIndicator} from "devextreme-react/load-indicator";
import Button from "devextreme-react/button";

const lstItemType = [{Code: 0, Name:"Grupo de Análise"}, 
                     {Code: 1, Name:"Variável Leitura"}];

const lstVerificationType = [{Code: 0, Name:"Último Valor do dia"}, 
                             {Code: 1, Name:"Média"},
                             {Code: 2, Name:"Soma"},
                             {Code: 3, Name:"Maior Valor"},
                             {Code: 4, Name:"Menor Valor"},
                             {Code: 5, Name:"Todos os valores"}];

export default function Configuration(props) {
    const { unitCod, refreshToken, getRefreshTokenCode, getToken } = useAuth();
    const { loading } = useLoading();
    const [load, setLoad] = useState(false);
    const [dailyReportBundler, setDailyReportBundler] = useState(false);
    const [dailyReportBundlerItem, setDailyReportBundlerItem] = useState(false);
    const [analysisGroup, setAnalysisGroup] = useState([]);
    const [readingVariable, setReadingVariable] = useState([]);
    const _dailyReportBundlerItem = useRef();
    
    useEffect(() => {
        firstLoad()
    }, [props.update]);

    useEffect(() => {
        if (load)
            updateLoad();
    }, [load]);

    async function updateLoad() {
        loadData();
    }

    async function firstLoad() {
        if (unitCod !== undefined) {
            loadData();
            loadDataDailyReportBundler();
            loadDataAnalysisGroup();
            loadReadingVariableData();
            await refreshToken(getToken(), getRefreshTokenCode());
        }
    }

    async function loadData() {
        await api
            .get("/dailyReportBundlerItem", { params: { unitCod: unitCod } })
            .then((response) => {
                setDailyReportBundlerItem(response.data);
                setLoad(false);
            })
            .catch(() => {
                setLoad(true);
                return true;
            });
    }

    async function loadDataDailyReportBundler() {
        await api
            .get("/dailyReportBundler", { params: { unitCod: unitCod } })
            .then((response) => {
                setDailyReportBundler(response.data);
                setLoad(false);
            })
            .catch(() => {
                setLoad(true);
                return true;
            });
    }

    async function loadDataAnalysisGroup() {
		await api
			.get("/analysisGroup", { params: { unitCod: unitCod } })
			.then((response) => {
                const lstAnalysisGroup = response.data.filter((analysis) => analysis.TipRegistroAtivo === true);
				setAnalysisGroup(lstAnalysisGroup);
				setLoad(false);
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

    async function loadReadingVariableData() {
        await api
            .get("/ReadingVariable", { params: { unitCod: unitCod } })
            .then((response) => {
                setReadingVariable(response.data);
            })
            .catch(() => {
                return true;
            });
    }

    async function updateRow(data, parLogMessage) {
        return await api
            .put("/dailyReportBundlerItem", { unitCod: unitCod, data: data, logMessage: parLogMessage })
            .then((response) => {
                setLoad(true);
                props.setUpdate(!props.update)
                return false;
            })
            .catch(() => {
                setLoad(true);
                return true;
            });
    }

    async function insertRow(data, parLogMessage) {
        return await api
            .post("/dailyReportBundlerItem", { unitCod: unitCod, data: data, logMessage: parLogMessage })
            .then((response) => {
                setLoad(true);
                props.setUpdate(!props.update)
                return false;
            })
            .catch(() => {
                setLoad(true);
                return true;
            });
    }

    async function deleteRow(data) {
        return await api
            .delete("/dailyReportBundlerItem", { data: data })
            .then(() => {
                setLoad(true);
                props.setUpdate(!props.update)
                return false;
            })
            .catch(() => {
                setLoad(true);

                return true;
            });
    }

    function onEditorPreparing(e) {
        if(e.parentType !== "dataRow") return;

        if((e.dataField === "CodAnaliseGrupo" || 
            e.dataField === "CodAnaliseGrupoSecundario" || 
            e.dataField === "CodAnaliseGrupoAgrupador") && 
            e.row.data.TipItem !== 0)
            changeColumnOption(e);

        if(e.dataField === "CodVariavelLeitura" && e.row.data.TipItem !== 1)
            changeColumnOption(e);
    }

    function changeColumnOption(e) {
        e.editorOptions.value = null;
        e.editorOptions.disabled = true;
    }

    
  function setCellValueTipItem(newData, value) {
    newData.TipItem = null;
    newData.CodAnaliseGrupo = null;
    newData.CodAnaliseGrupoSecundario = null;
    newData.CodVariavelLeitura = null;
    newData.CodAnaliseGrupoAgrupador = null;

    let column = this;
    column.defaultSetCellValue(newData, value);
  }

  function setCellValueCodAnaliseGrupo(newData, value, currentRowData) {
    if(value === currentRowData.CodAnaliseGrupoSecundario) 
        newData.CodAnaliseGrupoSecundario = null;

    if(value === currentRowData.CodAnaliseGrupoAgrupador)
        newData.CodAnaliseGrupoAgrupador = null;
    
    let column = this;
    column.defaultSetCellValue(newData, value);
  }

    return (
        <React.Fragment>
            <DataGrid
                className={"dx-cards"}
                dataSource={dailyReportBundlerItem}
                rowAlternationEnabled={true}
                showColumnLines={true}
                allowColumnReordering={true}
                showBorders={false}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                repaintChangesOnly={true}
                headerFilter={{ visible: true }}
                filterPanel={{ visible: true }}
                filterRow={{ visible: true }}
                onRowUpdating={async (e) => {
                    const newObj = { ...e.oldData, ...e.newData };
                    let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _dailyReportBundlerItem, newObj);

                    e.cancel = await updateRow(newObj, strLogMessage);

                    e.component.deselectAll();
                }}
                onRowInserting={async (e) => {
                    let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _dailyReportBundlerItem, e.data);

                    e.cancel = await insertRow(e.data, strLogMessage);

                    e.component.deselectAll();
                }}
                onRowRemoving={async (e) => {
                    let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _dailyReportBundlerItem, e.data);
                    const data = { unitCod: unitCod, data: e.data, logMessage: strLogMessage };

                    e.cancel = await deleteRow(data);

                    e.component.deselectAll();

                }}
                onEditorPreparing={onEditorPreparing}
                onFocusedCellChanged={(e) => {
                    if(e.column.dataField === "CodAnaliseGrupoSecundario" || e.column.dataField === "CodAnaliseGrupoAgrupador")
                        e.column.lookup.dataSource = analysisGroup.filter(x => x.CodAnaliseGrupo !== e.row.data.CodAnaliseGrupo);
                }}
                ref={_dailyReportBundlerItem}>
                <Export
                    enabled={true}
                    fileName="Configuração"
                    allowExportSelectedData={true}
                />
                <Editing mode={"batch"} allowUpdating={true} allowDeleting={true} allowAdding={false}>
                    <Form>
                        <Item itemType="group" colCount={4} colSpan={2}>
                            <Item colSpan={2} dataField="CodRelatorioDiarioAgrupador" />
                            <Item colSpan={2} dataField="TipItem" />
                            <Item colSpan={2} dataField="CodAnaliseGrupo" />
                            <Item colSpan={2} dataField="CodAnaliseGrupoSecundario" />
                            <Item colSpan={2} dataField="CodAnaliseGrupoAgrupador" />
                            <Item colSpan={2} dataField="CodVariavelLeitura" />
                            <Item colSpan={2} dataField="TipItemVerificacao" />
                            <Item colSpan={2} dataField="NomGrupoAlternativo" />
                        </Item>
                    </Form>
                </Editing>
                <Selection mode="multiple" />
                <Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false} />
                <GroupPanel visible={false} />
                <ColumnChooser enabled={true} mode="select" />
                <ColumnFixing enabled={false} />
                <Paging defaultPageSize={20} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />
                <Toolbar >
                    <Item cssClass={'add-container'}>
                        <Button className={'add-button'}  icon={"add"} onClick={() => (_dailyReportBundlerItem.current.instance.addRow())}/>
                    </Item>
                    <Item name="saveButton"/>
                    <Item name="revertButton"/>
                    <Item name="exportButton"/>
                    <Item name="columnChooserButton"/>
                </Toolbar>
                <Column
                    dataField={"CodRelatorioDiarioAgrupador"}
                    caption={"Agrupador"}
                    allowSorting={true}
                    allowFiltering={true}
                    allowSearch={true}
                    allowGrouping={true}
                    allowEditing={true}
                >
                    <Lookup 
                        dataSource={dailyReportBundler} 
                        displayExpr="NomAgrupador" 
                        valueExpr="CodRelatorioDiarioAgrupador" 
                    />
                    <RequiredRule />
                </Column>
                <Column 
                    dataField={"TipItem"} 
                    caption={"Tipo"} 
                    allowSorting={true}
                    allowFiltering={true}
                    setCellValue={setCellValueTipItem} 
                >
                    <Lookup 
                        dataSource={lstItemType} 
                        displayExpr="Name" 
                        valueExpr="Code" 
                    />
                    <RequiredRule />
                </Column>
                <Column 
                    dataField={"CodAnaliseGrupo"} 
                    caption={"Grupo de Análise"} 
                    allowSorting={true}
                    allowFiltering={true}
                    setCellValue={setCellValueCodAnaliseGrupo}
                >
                    <Lookup 
                        dataSource={analysisGroup} 
                        displayExpr="NomAnaliseGrupo"  
                        valueExpr="CodAnaliseGrupo"
                    />
                </Column>
                <Column 
                    dataField={"CodAnaliseGrupoSecundario"} 
                    caption={"Grupo de Análise Secundário"} 
                    allowSorting={true}
                    allowFiltering={true}
                >
                    <Lookup 
                        dataSource={analysisGroup} 
                        displayExpr="NomAnaliseGrupo"  
                        valueExpr="CodAnaliseGrupo"
                    />
                </Column>
                <Column
                    dataType={"number"}
                    dataField={"CodAnaliseGrupoAgrupador"}
                    caption={"Grupo Análise Agrupador"}
                    allowSorting={true}
                    allowFiltering={true}
                >
                    <Lookup 
                        dataSource={analysisGroup} 
                        displayExpr="NomAnaliseGrupo"  
                        valueExpr="CodAnaliseGrupo"
                    />
                </Column>
                <Column 
                    dataField={"CodVariavelLeitura"} 
                    caption={"Variável Leitura"} 
                    allowSorting={true}
                    allowFiltering={true}
                >
                    <Lookup 
                        dataSource={readingVariable} 
                        displayExpr="NomVariavelLeitura"
                        valueExpr="CodVariavelLeitura"  
                    />
                </Column>
                <Column 
                    dataField={"TipItemVerificacao"} 
                    caption={"Tipo Verificação"} 
                    allowSorting={true} 
                    allowFiltering={true}
                >
                    <Lookup 
                        dataSource={lstVerificationType} 
                        displayExpr="Name" 
                        valueExpr="Code" 
                    />
                    <RequiredRule />
                </Column>
                <Column
                    dataField={"NomGrupoAlternativo"}
                    caption={"Nome Grupo Alternativo"}
                    allowSorting={true}
                    allowFiltering={true}
                    allowSearch={true}
                    allowGrouping={true}
                    allowEditing={true}
                ></Column>
                <Column
                    dataType={"number"}
                    dataField={"NumOrdem"}
                    caption={"Ordem"}
                    allowSorting={true}
                    allowFiltering={true}
                >
                    <RequiredRule />
                </Column>
            </DataGrid>
            <div className="load-indicator">
                <LoadIndicator visible={loading} height={40} width={40} />
            </div>
        </React.Fragment>
    );
}