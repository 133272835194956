/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../../components/common/style.scss";
import "devextreme/data/odata/store";
import { LogMessageGrid } from "../../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Editing,
  FilterRow,
  Form,
  Grouping,
  GroupPanel,
  Item,
  Lookup,
  CustomRule,
  Pager,
  Paging,
  RequiredRule, Toolbar,
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useLoading } from "../../../contexts/loadingContext";
import { useAuth } from "../../../contexts/auth";
import { LoadIndicator } from "devextreme-react/load-indicator";
import Button from "devextreme-react/button";

export default function MonitorationBlockConfiguration(props) {
    const { loading } = useLoading();
    const [load, setLoad] = useState(false);
    const [monitorationBlock, setMonitorationBlock] = useState([]);
    const [measureValue, setMeasureValue] = useState([]);
    const [monitorationBlockConfiguration, setMonitorationBlockConfiguration] = useState([]);
    const [monitorationBlockValueColumnsName, setMonitorationBlockValueColumnsName] = useState([]);
    const _filterData = useRef([]);
    const _monitorationBlockConfiguration = useRef();
    const { unitCod } = useAuth();

    const tipValorSelect = [
        {TipValor: 1, Nome: "Bit"},
        {TipValor: 2, Nome: "Numérico"},
        {TipValor: 3, Nome: "AlfaNumérico"},
        {TipValor: 4, Nome: "Data"}
    ]

    //#region UseEffect
    useEffect(() => {
        async function requestDatabase(){
            await loadMeasureValueData()
            await loadMonitorationBlockData()
            await loadMonitorationBlockColumnsNames();
            loadData()
        }
        requestDatabase()
    }, [])
    
    useEffect(() => {
        if (load) {
            loadData()
            setLoad(false)
        }
    }, [load])
    //#endregion

    useEffect(() => {
        loadMonitorationBlockData();
    }, [props.update])

    //#region Requests
    async function loadData() {
        await api
        .get("/monitorationBlockConfiguration", { params: { unitCod: unitCod } })
        .then((response) => {
            setMonitorationBlockConfiguration(response.data);
            const oldFilter = [...response.data].filter(col => col.TipFiltroData)
            _filterData.current = oldFilter
        })
        .catch(() => {
            return true;
        });
    }

    async function loadMonitorationBlockData() {
        await api
            .get("/MonitorationBlock", { params: { unitCod: unitCod } })
            .then((response) => {
                setMonitorationBlock(response.data);
            })
            .catch(() => {
                return true;
            });
    }

    async function loadMonitorationBlockColumnsNames() {
        await api
            .get("/monitorationBlockValue/getColumnsNames")
            .then((response) => {
                setMonitorationBlockValueColumnsName(response.data);
            })
            .catch(() => {
                return true;
            });
    }

    async function loadMeasureValueData() {
        await api
            .get("/measureValue", { params: { unitCod: unitCod } })
            .then((response) => {
                setMeasureValue(response.data);
            })
            .catch(() => {
                return true;
            });
    }

    async function updateRow(data, parLogMessage) {
        return await api
        .put("/monitorationBlockConfiguration", {
            unitCod: unitCod,
            data: data,
            logMessage: parLogMessage
        })
        .then(() => {
            setLoad(!load);
            props.setUpdate(!props.update);
        })
        .catch(() => {
            return true;
        });
    }

    async function insertRow(data, parLogMessage) {
        return await api
        .post("/monitorationBlockConfiguration", {
            unitCod: unitCod,
            data: data,
            logMessage: parLogMessage
        })
        .then(() => {
            setLoad(!load);
            props.setUpdate(!props.update);
            return false;
        })
        .catch(() => {
            return true;
        });
    }

    async function deleteRow(data) {
        return await api
        .delete("/monitorationBlockConfiguration", { data: data })
        .then(() => {
            setLoad(!load);
            props.setUpdate(!props.update);
            return false;
        })
        .catch(() => {
            return true;
        });
    }
    //#endregion

    function checkUniqueKey(params) {
        return !monitorationBlockConfiguration.some(unit => (
            unit.CodBlocoMonitoracao === params.data.CodBlocoMonitoracao &&
            unit.NumOrdem === params.data.NumOrdem &&
            unit.CodBlocoMonitoracaoConfiguracao !== params.data.CodBlocoMonitoracaoConfiguracao
        ))
    }

    function checkFilterData(params) {
        const oldFilter = _filterData.current.find(col => col.CodBlocoMonitoracao === params.data.CodBlocoMonitoracao)
        if(!oldFilter && params.data.TipFiltroData){
            _filterData.current.push(params.data)
            return true
        }
        
        if(oldFilter?.CodBlocoMonitoracaoConfiguracao === params.data.CodBlocoMonitoracaoConfiguracao){
            if(params.data.TipFiltroData) return true
            _filterData.current = _filterData.current.filter(e => e !== oldFilter)
            return true
        }
        if(!params.data.TipFiltroData) return true
        return false
    }

    function checkDescription(params) {
        const searched = monitorationBlockConfiguration.find(x => x.CodBlocoMonitoracao === params.data.CodBlocoMonitoracao &&
                                                                  x.CodBlocoMonitoracaoConfiguracao != params.data.CodBlocoMonitoracaoConfiguracao &&          
                                                                  x.DesCampoBlocoMonitoracaoValor == params.data.DesCampoBlocoMonitoracaoValor);

        if(searched === undefined)
            return true;

        return false;
    }

    return (
        <React.Fragment>
        <DataGrid
            className={"dx-cards"}
            dataSource={monitorationBlockConfiguration}
            rowAlternationEnabled={true}
            showColumnLines={true}
            allowColumnReordering={true}
            showBorders={false}
            columnHidingEnabled={true}
            columnAutoWidth={true}
            repaintChangesOnly={true}
            onInitNewRow={(e) => {
                e.data.TipFiltroData = false;
                e.data.TipFiltroDados = false;
            }}
            headerFilter={{ visible: true }}
            filterPanel={{ visible: true }}
            filterRow={{ visible: false }}
            onRowUpdating={async (e) => {
                const newObj = { ...e.oldData, ...e.newData };
                let strLogMessage = LogMessageGrid(
                    formatMessage("UpdatedLog"),
                    _monitorationBlockConfiguration,
                    newObj
                );

                e.cancel = updateRow(newObj, strLogMessage);
                e.component.deselectAll();
            }}
            onRowInserting={async (e) => {
                let strLogMessage = LogMessageGrid(
                    formatMessage("InsertedLog"),
                    _monitorationBlockConfiguration,
                    e.data
                );

                e.cancel = insertRow(e.data, strLogMessage);
                e.component.deselectAll();
            }}
            onRowRemoving={(e) => {
                let strLogMessage = LogMessageGrid(
                    formatMessage("DeletedLog"),
                    _monitorationBlockConfiguration,
                    e.data
                );

                const data = {
                    unitCod: unitCod,
                    data: e.data,
                    logMessage: strLogMessage,
                };
                e.cancel = deleteRow(data);
                e.component.deselectAll();
            }}
            ref={_monitorationBlockConfiguration}
        >
            <Editing
                mode={"batch"}
                allowUpdating={true}
                allowDeleting={true}
                allowAdding={false}
            >
                <Form>
                    <Item itemType="group" colCount={4} colSpan={2}>
                    <Item colSpan={2} dataField="NomBalancaFluxo" />
                    <Item colSpan={2} dataField="CodAreaProcesso" />
                    </Item>
                </Form>
            </Editing>
            <Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false}/>
            <GroupPanel visible={false} />
            <ColumnChooser enabled={true} mode="select" />
            <ColumnFixing enabled={false} />
            <Paging defaultPageSize={20} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />
            <Toolbar>
                <Item cssClass={'add-container'}>
                    <Button className={'add-button'} icon={"add"} onClick={() => (_monitorationBlockConfiguration.current.instance.addRow())}/>
                </Item>
                <Item name="saveButton"/>
                <Item name="revertButton"/>
                <Item name="exportButton"/>
                <Item name="columnChooserButton"/>
            </Toolbar>
            <Column
                dataField={"CodBlocoMonitoracao"}
                caption={"Bloco de Monitoração"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
                <RequiredRule />
                <CustomRule
                    message={'A junção desses campos deve ser única'}
                    reevaluate={true}
                    type={"custom"}
                    validationCallback={checkUniqueKey}
                />
                <Lookup
                    dataSource={monitorationBlock}
                    displayExpr={"NomBlocoMonitoracao"}
                    valueExpr="CodBlocoMonitoracao"
                />
            </Column>
            <Column
                dataField={"NomBlocoMonitoracaoConfiguracao"}
                caption={"Nome"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
                <RequiredRule />
            </Column>
            <Column
                dataField={"NumOrdem"}
                caption={"Ordem"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
                <RequiredRule />
                <CustomRule
                    message={'A junção desses campos deve ser única'}
                    reevaluate={true}
                    type={"custom"}
                    validationCallback={checkUniqueKey}
                />
            </Column>
            <Column
                dataField={"CodUnidadeValor"}
                caption={"Unidade"}
                dataType={"number"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
                <RequiredRule/>
                <Lookup
                    dataSource={measureValue}
                    displayExpr={"NomSiglaUnidadeValor"}
                    valueExpr="CodUnidadeValor"
                />
            </Column>
            <Column
                dataField={"TipValor"}
                caption={"Tipo do Dado"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
                <RequiredRule/>
                <Lookup
                    dataSource={tipValorSelect}
                    displayExpr={"Nome"}
                    valueExpr="TipValor"
                />
            </Column>
            <Column
                dataField={"NumCasasDecimais"}
                caption={"Casas Decimais"}
                dataType={"number"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
            </Column>
            <Column
                dataField={"DesCampoBlocoMonitoracaoValor"}
                caption={"Descrição"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
                editorOptions={{ maxLength: 100 }}
            >
                <Lookup 
                    dataSource={monitorationBlockValueColumnsName}
                />
                <RequiredRule />
                <CustomRule 
                    message={'Este Bloco de Monitoração já possui este campo de Descrição.'}
                    reevaluate={true}
                    type={"custom"}
                    validationCallback={checkDescription}
                />
            </Column>
            <Column
                dataField={"TipFiltroData"}
                caption={"Filtro Data"}
                allowSorting={true}
                datatype={"boolean"}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
                
            >
                <CustomRule
                    message={'Somente um Filtro de Data para cada Bloco de Monitoração'}
                    reevaluate={true}
                    type={"custom"}
                    validationCallback={checkFilterData}
                />
            </Column>
            <Column
                dataField={"TipFiltroDados"}
                caption={"Filtro Dados"}
                allowSorting={true}
                datatype={"boolean"}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
            </Column>
        </DataGrid>
        <div className="load-indicator">
            <LoadIndicator visible={loading} height={40} width={40} />
        </div>
        </React.Fragment>
    );
};
