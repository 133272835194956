import dayjs from "dayjs";

export const calculateDuration = (rowData) => {
    const date1 = dayjs(rowData.DatFinalParada).unix();
    const date2 = dayjs(rowData.DatInicialParada).unix();

    if(date1) {
      const diff = date1 - date2;
      const day = Math.trunc(diff/86400);

      const diff2 = diff - (86400*day);
      const hour = Math.trunc(diff2/3600);

      const diff3 = diff2 - (3600*hour)
      const minute = Math.trunc(diff3/60);

      const diff4 = diff3 - (60*minute);
      const second = Math.trunc(diff4);

      return `${day}d ${hour}:${minute}:${second}`;
    }

    return ""
}