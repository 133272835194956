/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useLayoutEffect } from "react";
import dayjs from "dayjs";
import { CheckBox } from "devextreme-react";
import { NumberBox} from "devextreme-react";
import {  Button as TextBoxButton } from "devextreme-react/text-box";
import { useDataLoader } from "../../contexts/dataLoaderContext";
import "./style.scss";
import "../../dx-styles.scss"
import { AddItems } from "./AddItems";
import { ObjSampleSave } from "./objSampleSave";
import { useScreenSize } from "../../utils/media-query";
import HourBox from "./HourBox";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
	const { isSmall, isXSmall, isXLSmall, isMedium, isLarge } = useScreenSize();
	const { setSaveHeaderTextBox, listToSaveSample, listToSaveDescSample, numLot } = useDataLoader();
	let _refInputPrevious = useRef([]);
	let firstLoad = useRef(false);
	let _currencyValueDate = useRef(new Date(props.sampleHour));

	useLayoutEffect(() => {
		listToSaveSample.current = []
		setSaveHeaderTextBox(false)
		firstLoad.current = true

		if(_currencyValueDate.current === new Date(props.sampleHour)) {
			_currencyValueDate.current.setDate(new Date(props.currencyValueDate).getDate());
			_currencyValueDate.current.setMonth(new Date(props.currencyValueDate).getMonth());
			_currencyValueDate.current.setFullYear(new Date(props.currencyValueDate).getFullYear());
		}
	}, [])

	useEffect(() => {
		_currencyValueDate.current.setDate(new Date(props.currencyValueDate).getDate());
		_currencyValueDate.current.setMonth(new Date(props.currencyValueDate).getMonth());
		_currencyValueDate.current.setFullYear(new Date(props.currencyValueDate).getFullYear());
	}, [props.currencyValueDate])

	useEffect(() => {
		if(props.tipPeriodUnit === 4) searchCurrentDate()
	}, [])

	const aprovedCheck = {
		icon: "check",
		hint: "Aprovado",
		elementAttr: { id: "aproved-icon" },
	}

	const reprovedCheck = {
		icon: "close",
		hint: "Reprovado",
		elementAttr: { id: "reproved-icon" },
	}

	const optionsValueIcon = {
		stylingMode: "text",
		focusStateEnabled: false,
		type: "default",
		visible: false,
		width: 0
	};

	const verificationInput = (value, e) => {
		let arrayIndex = e.element.id.split(":", 2);
		let tipVerification = props.analysisTimeList[arrayIndex[0]][1];
		let minimumValue = props.analysisTimeList[arrayIndex[0]][3];
		let maximumValue = props.analysisTimeList[arrayIndex[0]][4];
		let comparasionString = "";

		let inputComponent = e.component.getButton("checkValue") !== null ? e.component.getButton("checkValue") : "";

		if(inputComponent !== "" && inputComponent !== undefined){
			value === "" || value === null ? inputComponent.option("visible", false) : inputComponent.option("visible", true);
			// eslint-disable-next-line default-case
			switch (tipVerification) {
				case 1: //Interval
					comparasionString = value >= minimumValue && value <= maximumValue;
					break;

				case 2: //Minimum
					comparasionString = value >= minimumValue;
					break;

				case 3: //Maximum
					comparasionString = value <= maximumValue;
					break;
			}

			if(tipVerification !== 4)//Se for não aplicável, não insere icone, pois não existe aprovado ou reprovado
				comparasionString ? inputComponent.option(aprovedCheck) : inputComponent.option(reprovedCheck);
		}

		return tipVerification === 4 ? true : comparasionString; //Se for não aplicável, retorna true
	};

	const onValueChangedChbNotWorking = async (e) => {
		if (e.value)  {
			if(firstLoad.current){
				props._lstPreviousValue.current[e.element.id] = _refInputPrevious.current[e.element.id].instance.option("value")
				firstLoad.current = false
			}
			props._refInput.current[e.element.id].instance.option("value", 0);
		} 
		else {
			let previousValue = props._lstPreviousValue.current[e.element.id];
			props._refInput.current[e.element.id].instance.option("value", previousValue);
		}
	};

	const onValueChanged = async (e, parComponent) => {
		if (e.event !== undefined) {

			let value = e.component.option("value");
			
			//Guarda o PreviousValue e mesmo se clicar no checkbox "S/A", o PreviousValue não muda
			if(parComponent === "tbxNumeric") {
				props._lstPreviousValue.current[e.element.id] = e.previousValue
				firstLoad.current = false
			}

			if (
				props._refUserSelectBox.current[props.isLot ? "0:0" : e.element.id] !== null &&
				props._refUserSelectBox.current[props.isLot ? "0:0" : e.element.id] !== undefined &&
				props._refObservation.current[props.isLot ? "0:0" : e.element.id] !== null &&
				props._refObservation.current[props.isLot ? "0:0" : e.element.id] !== undefined
			) {
				let userSelectBox = props._refUserSelectBox.current[props.isLot || props.headerLotRender ? "0:0" : e.element.id].instance;
				let observationTextArea = props._refObservation.current[props.isLot || props.headerLotRender ? "0:0" : e.element.id].instance;
	
				let reanalysisButton = props._refButtonReAnalysis.current[props.isLot ? "0:0" : e.element.id].instance;
	
				let disabledAprovedFields = props.headerLotRender
					? numLot !== null && numLot !== undefined && numLot !== 0 && props._refInput.current["0:0"] !== undefined
					: !props.isLot
					? props._refInput.current[e.element.id].instance.option("value") === null ||
					  props._refInput.current[e.element.id].instance.option("value") === ""
					: props._refInput.current["0:0"] !== undefined && props._refInput.current["0:0"] !== null
					? props._refInput.current["0:0"].instance.option("value") === null || props._refInput.current["0:0"].instance.option("value") === ""
					: false;
	
				userSelectBox.option("disabled", disabledAprovedFields);
				observationTextArea.option("disabled", disabledAprovedFields);
				reanalysisButton.option("disabled", disabledAprovedFields);
			}
	
			let boolAprovedResult;
			if (parComponent !== "chbNotWorking" && parComponent !== "dxDateBox")
				boolAprovedResult = verificationInput(value, e)

			if (props.isLot) {
				listToSaveSample.current.forEach((element) => { //Altera o código usuario de todos os elementos a serem salvos
					if (e.event.type === "dxclick") element.CodUsuario = listToSaveDescSample.current["0:1"]
				})
				if(props.lstDescObservation.length > 0) {
					let newDate = new Date(props.lstDescObservation[0].DatAnaliseAmostra);

					listToSaveSample.current.forEach(element => {
						if(element.CodAnalise == 0)
							newDate = new Date(element.DatAnaliseAmostra);
					})

					_currencyValueDate.current = newDate;
				}
					

				if(props.lstDescObservation.length === 0 && listToSaveSample.current.length > 0) {
					listToSaveSample.current.forEach(element => {
						if(element.CodAnalise === 0)
							_currencyValueDate.current = new Date(element.DatAnaliseAmostra);
					})
				}
			}

			const newObj = ObjSampleSave(
				props.isLot ? props.currencyValueDate : e.component.option("date"),
				props.analysisTimeList[props.indexAnalysis],
				props.analysisGroupCode,
				props._refInput.current[`${props.indexAnalysis}:${props.index}`] !== undefined && 
				props._refInput.current[`${props.indexAnalysis}:${props.index}`] !== null
					? props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value")
					: "",
				boolAprovedResult,
				props._refBtnNotWorking.current[`${props.indexAnalysis}:${props.index}`].instance.option("value"), //tip_funcionando
				props.isLot ? numLot : null,
				props.userCod,
				props.isLot,
				false,
				props._refLabelHour,
				props._refUserSelectBox,
				props._refObservation,
				props._refButtonReAnalysis,
				listToSaveDescSample,
				`${props.indexAnalysis}:${props.index}`,
				false,
				(props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("value") === props._lstPreviousValue.current[`${props.indexAnalysis}:${props.index}`])
				? "" : props._lstPreviousValue.current[`${props.indexAnalysis}:${props.index}`],
				props.sampleHour ? props.sampleHour : _currencyValueDate.current,
				_currencyValueDate.current,
				props.tipPeriodUnit
			);

			await AddItems(listToSaveSample, props.isLot, newObj, "", `${props.indexAnalysis}:${props.index}`);
		}
		
	};

	const onInitializedChbNotWorking = (e) => {
		props.isLot ? e.component.option("date", props.currencyValueDate) : e.component.option("date", props.analysisTimeList[props.indexAnalysis][5][props.index])
	
		//Se o camponente da hora é igual ao da amostra e o código do componente é igual ao da analise, para saber em qual componente inserir o valor do banco
		props.listAnalysisSample.forEach((element) => {
			if (props.isLot) {
				if (props.analysisTimeList[props.indexAnalysis][8].CodAnalise === element.CodAnalise)
					e.component.option("value", !element.TipFuncionando);

				if(numLot === "" || numLot === undefined) e.component.option("value", false)

			} else {
				if (
					(dayjs(element.DatAnaliseAmostra).format("LT") === props._refLabelHour.current[`${props.indexAnalysis}:${props.index}`]
					|| (dayjs(element.DatAnaliseAmostra).format("LT") === dayjs(_currencyValueDate.current).format("LT")))
					&& props.analysisTimeList[props.indexAnalysis][8].CodAnalise === element.CodAnalise
				) {
					e.component.option("value", !element.TipFuncionando); //Preenche checkbox tip_funcionando

					if (!element.TipFuncionando)
						//Desabilita campo valor amostra se tip_funcionando = 0
						props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance.option("disabled", true);
				}
			}
		});
	};

	function searchCurrentDate() {
		for(let i = 0; i < props.analysisTimeList.length; i++) {
			for(let j = 0; j < props.listAnalysisSample.length; j++) {
				if(props.listAnalysisSample[j].CodAnalise === props.analysisTimeList[i][8].CodAnalise) {
					props.setCurrentDate(new Date(props.listAnalysisSample[j].DatAnaliseAmostra));
					break;
				}
			}

			if(props.currentDate)
				break;
		}
	}

	return (
		<>
		{!props.isLot && (props.tipPeriodUnit === 7 || props.tipPeriodUnit === 4)
			? 	<HourBox 
					currencyValueDate={_currencyValueDate}
					sampleHour = {props.sampleHour}
					onValueChanged={onValueChanged}
					currentDate={props.currentDate}
					setCurrentDate={props.setCurrentDate}
					tipPeriodUnit={props.tipPeriodUnit}
				/>
				: ""
		}
		<div style={{ minWidth: isLarge || isMedium  ? 235 : isSmall ? 185 : 150}}>
			<section style={{ float: "left",
			 					marginRight: isXSmall || isXLSmall ? 5 : 25,
								 minWidth: isLarge || isMedium ? 150 : isSmall || isXLSmall ? 100 : 100,
								 maxWidth: isLarge || isMedium ? 150 : isSmall || isXLSmall? 100 : 100 }}>
				<div
					className="dx-field-label"
					style={{
						display: props.headerLotRender ? "inline" : props.isLot ? "none" : props.index === 0 ? "inline-block" : "none", width: "100%"
					}}>
					{isXSmall || isXLSmall ? "Valor" : "Valor Amostra"}
				</div>
				<div>
					<NumberBox
						height={42}
						stylingMode="outlined"
						className={"textbox-font-editor"}
						width={"100%"}
						defaultValue={null}
						onInitialized={(e) => {
							props.isLot 
							? e.component.option("date", props.currencyValueDate) 
							: e.component.option("date", props.analysisTimeList[props.indexAnalysis][5][props.index])
						}}
						style={{ marginTop: props.isLot ? -27 : 0}}
						id={`${props.indexAnalysis}:${props.index}`}
						ref={(node) => {
							props._refInput.current[`${props.indexAnalysis}:${props.index}`] = node;
							_refInputPrevious.current[`${props.indexAnalysis}:${props.index}`] = node;
						}}
						onOptionChanged={(e) => {
							if (e.component.instance().option("readOnly")) {
								e.element.parentElement.style.backgroundColor = "rgb(244 67 54 / 19%)";
								e.element.parentElement.style.opacity = "0.6";
							}
							
							verificationInput(e.component.option("value"), e);
						}}
						onContentReady={(e) => {
							//Se o camponente da hora é igual ao da amostra e o código do componente é igual ao da analise para saber em qual componente inserir o valor do banco
							props.listAnalysisSample.forEach((element) => {
								if (props.isLot) {
									if (props.analysisTimeList[props.indexAnalysis][8].CodAnalise === element.CodAnalise) {
										e.component.option("value", element.ValAmostra);
										e.component.option("tipFuncionando", element.TipFuncionando);
										if(!element.TipFuncionando) e.component.option("disabled", true)
										if(numLot === "" || numLot === undefined) e.component.option("value", null)
									}
								} else {
									if (
										((dayjs(element.DatAnaliseAmostra).format("LT") === props._refLabelHour.current[`${props.indexAnalysis}:${props.index}`])
										|| (dayjs(element.DatAnaliseAmostra).format("LT") === dayjs(_currencyValueDate.current).format("LT")))
										&& props.analysisTimeList[props.indexAnalysis][8].CodAnalise === element.CodAnalise
									) {
										e.component.option("value", element.ValAmostra);
										e.component.option("tipFuncionando", element.TipFuncionando);
										if(!element.TipFuncionando) e.component.option("disabled", true)
									}
								}
							});

							//Desabilita conforme horas de registros de paradas
							props.analysisTimeList[props.indexAnalysis][6].forEach((element) => {
								if (!props.isLot) {
									if (dayjs(element).format("LT") === props._refLabelHour.current[`${props.indexAnalysis}:${props.index}`]) {
										e.component.instance().option("readOnly", true);
									}
								}
							});

							e.component.option("codanalise", props.analysisTimeList[props.indexAnalysis][8].CodAnalise);
							
							verificationInput(e.component.option("value"), e);

							//Desabilita input se está com a ação de Apenas visualização
							if(props.actionOnlyViewer) e.component.option("disabled", props.actionOnlyViewer)

						}}
						onValueChanged={(e) => onValueChanged(e, "tbxNumeric")}
						visible={
							props.isLot === true || props.tipPeriodUnit === 7 || props.tipPeriodUnit === 4
								? true
								: props.analysisTimeList[props.indexAnalysis][5].some(
										(item) => dayjs(item).format("LT") === dayjs(_currencyValueDate.current).format("LT")
								  ) //Verifica se a hora é maior que a hora atual
						}>
						<TextBoxButton name="checkValue" location="after" options={optionsValueIcon} />
					</NumberBox>
				</div>
			</section>
			<section>
				<div
					className="dx-field-label"
					style={{
						height: 15,
						width: isLarge || isMedium || isSmall ? 50 : 40,
						display: props.headerLotRender
							? "inline"
							: props.isLot
							? "none"
							: props.index === 0
							? "inline-block"
							: "none",
					}}>
					S/A
				</div>
				<div
					id={"chbNotWorking"}
					style={{
						height: 10,
						marginTop: props.isLot ? -18 : 8,
						float: "left",
					}}>
					<CheckBox
						name="chbNotWorking"
						stylingMode="outlined"
						className={"checkbox-large"}
						disabled={props.actionOnlyViewer}
						width={"100%"}
						onValueChanged={async (e) => {
							const refInput = props._refInput.current[`${props.indexAnalysis}:${props.index}`].instance;

							 onValueChangedChbNotWorking(e);

							//Altera o valor da amostra
							refInput.option("disabled", e.value ? true : false);
							refInput.option("tipFuncionando", e.value);

							 onValueChanged(e, "chbNotWorking")

						}}
						onInitialized={(e) => onInitializedChbNotWorking(e)}
						visible={
							
							!props.isLot
								? props.analysisTimeList[props.indexAnalysis][5].some(
										(item) => dayjs(item).format("LT") === dayjs(props.sampleHour).format("LT")
								  ) //Verifica se a hora é maior que a hora atual
								: true
						}
						tabIndex={-1}
						id={`${props.indexAnalysis}:${props.index}`}
						hint={"Marque se não foi possível fazer a leitura"}
						ref={(node) => {
							props._refBtnNotWorking.current[`${props.indexAnalysis}:${props.index}`] = node;
						}}
					/>
				</div>
			</section>
		</div>
		</>
	);
};
