/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback } from 'react';
import { Popup } from "devextreme-react/popup";
import { useDataLoader } from "../../contexts/dataLoaderContext";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useAuth } from '../../contexts/auth';
import { api } from '../../services/api';

export default function ChangePassword (props) {
  const { setChangePassword, changePassword, setPasswordChanged,
          enabledActualPassword, setEnabledActualPassword } = useDataLoader();
  const { unitCod, userCod } = useAuth();
  const [loading, setLoading] = useState(false);
  const [actualPassword, setActualPassword] = useState();
  const formData = useRef({});
  const _validRules = useRef(false);

  const onClick = useCallback(async (e) => {
    if(confirmPasswordOnClick()){
      if(!enabledActualPassword) {
        props.event.setValue(formData.current.password);
        setPasswordChanged(formData.current.password);
        setChangePassword(false);
      } else {
        setPasswordChanged(formData.current.password);
        setActualPassword(formData.current.actualPassword);
        reqchangePassword(formData.current.actualPassword, formData.current.password);
      }
    }
    // history.push("/profile");
  }, []);

  async function reqchangePassword(actualPassword, password) {
    const data = {
      userCod: props.userCod, 
      unitCod: unitCod, 
      actualPassword: actualPassword, 
      newPassword: password,
      logMessage: "Modificou a senha"
    };

    await api
      .put("/user/changePassword", data)
      .then((response) => {
        setChangePassword(false);
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const confirmPasswordOnClick = useCallback(
    () => formData.current.confirmedPassword !== undefined && formData.current.confirmedPassword !== undefined
        ? formData.current.confirmedPassword === formData.current.password
        : false,
    []
  );

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

  const validateForm = (e) => {
    const result = e.component.validate();
    _validRules.current = result.isValid;
  };

  return (
    <Popup
      className="pop-up"
      visible={changePassword}
      onHiding={() => setChangePassword(false)}
      dragEnabled={true}
      resizeEnabled={true}
      closeOnOutsideClick={true}
      onInitialized={(e) => {
        //Usado para o cadastro de usuario
        if(!!props.dataGrid) props.dataGrid.current.instance.beginUpdate();
      }}
      showTitle={true}
      width={"fit-content"}
      height={"fit-content"}
      onHidden={() => {
        setEnabledActualPassword(false);
        formData.current = {};
        
        //Usado para o cadastro de usuario
        if(!!props.dataGrid) props.dataGrid.current.instance.endUpdate();
        // history.push("/profile");
      }}
    >
      <form >
        <Form 
          formData={formData.current} 
          disabled={loading}
          onContentReady={(e) => validateForm(e)}
          onFieldDataChanged={(e) => validateForm(e)}
        >
          <Item 
            visible={enabledActualPassword} 
            disabled={!enabledActualPassword}
            editorType={'dxTextBox'}
            dataField={"actualPassword"}
            editorOptions={actualPasswordOp}
          >
            <RequiredRule message={"Senha atual é obrigatória"}/>
            <Label visible={false} />
          </Item>  
          <Item
            dataField={'password'}
            editorType={'dxTextBox'}
            editorOptions={passwordEditorOptions}
          >
            <RequiredRule message="Senha é obrigatório!" />
            <Label visible={false} />
          </Item>
          <Item
            dataField={'confirmedPassword'}
            editorType={'dxTextBox'}
            editorOptions={confirmedPasswordEditorOptions}
          >
            <RequiredRule message="Confirmar Senha é obrigatório" />
            <CustomRule
              message={'Senhas não conferem'}
              validationCallback={confirmPassword}
            />
            <Label visible={false} />
          </Item>
          <ButtonItem>
            <ButtonOptions
              width={'100%'}
              type={'default'}
              useSubmitBehavior={false}
              onClick={(e) => {
                if(_validRules.current) onClick(e);
              }}
            >
              <span className="dx-button-text">
                {
                  loading
                    ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                    : 'Confirmar'
                }
              </span>
            </ButtonOptions>
          </ButtonItem>
        </Form>
      </form>
    </Popup>
  );
}

const actualPasswordOp = { stylingMode: 'filled', placeholder: 'Senha Atual', mode: 'password' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Senha', mode: 'password' };
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Confirmar Senha', mode: 'password' };