import dayjs from "dayjs";

export default function formatDateTime() {

  switch (dayjs.locale())
  {
    case "pt":
    case "pt-br":
    case "es":
      return "dd/MM/yyyy HH:mm:ss"
    case "en":
      return "MM/dd/yyyy HH:mm:ss"
    default: 
      return "dd/MM/yyyy HH:mm:ss"
  }
}