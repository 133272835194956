/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "./style.scss";
import "devextreme/data/odata/store";
import "../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import Form, {
  SimpleItem,
  GroupItem,
  EmptyItem,
  ButtonItem,
}
from 'devextreme-react/form';
import { api } from "../../services/api";
import { useAuth } from "../../contexts/auth";
import formatDateTime from "../../localization/formatDateTime";
import { Tooltip } from 'devextreme-react/tooltip';
import { languages} from '../../utils/languages';
import { useLoading } from "../../contexts/loadingContext";
import { useNotification } from "../../contexts/notificationContext";
import { FavButton } from "../../components/fav-button/favButton";
import { loadDataAddressReports } from "../../utils/functionRequests";
import dayjs from "dayjs";

export default function Aplication() {
  const { loading } = useLoading();
  const { refreshToken, getRefreshTokenCode, getToken } = useAuth();
  const { loadDataNotificationTime } = useNotification();
  const [load, setLoad] = useState(false);
  const [dataSourceTypeGoal, setDataSourceTypeGoal] = useState([]);
  const [dataSourceAplication, setDataSourceAplication] = useState();
  const [dataSourceSubmit, setDataSourceSubmit] = useState({});
  const _form = useRef();

  const dataSourceHourTypeOptions = [
    { Id: 1, Value: "Início" },
    { Id: 0, Value: "Final" },
  ];

  //Autenticação requests
  const { unitCod } = useAuth();

  useEffect(() => {
    async function firstLoad(){
      loadDataSourceTypeGoal();
      loadData();
      refreshToken(getToken(), getRefreshTokenCode());
    }
    firstLoad();
  }, []);

  useEffect(() => {
    if(load) {
      loadData();
      loadDataNotificationTime();
    }
  }, [load]);

  //#region Requests
  async function loadData() {
      await api
      .get("/aplication", { params: { unitCod: unitCod } })
      .then((response) => {
        setDataSourceAplication(response.data[0]);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  };

  async function loadDataSourceTypeGoal() {
      await api
      .get("/type/get", { params: { typeId: 85 } })
      .then((response) => {
        setDataSourceTypeGoal(response.data);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  };

  async function findId() {
      return await api
      .get("/aplication/get", { params: { Id: unitCod } })
        .then((response) => {
          return (response.data.length > 0) ? true : false;
        })
        .catch((error) => {
          return false;
        });
  }

  async function updateRow(data) {
    return await api
      .put("/aplication", { unitCod: unitCod, data: data })
      .then((response) => {
        loadDataAddressReports(unitCod);
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  };

  async function insertRow(data) {
    return await api
      .post("/aplication", { unitCod: unitCod, data: data })
      .then((response) => {
        loadDataAddressReports(unitCod);
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
    });
  };
  //#endregion

  const numberBoxOptions =
  {
    showSpinButtons: true,
    min: 0
  };

  const dateBoxHourOptions = {
    displayFormat: "HH:mm",
    useMaskBehavior: true,
    type: "time"
  };

  const lastEmailOptions = {
    displayFormat: formatDateTime(),
    useMaskBehavior: true,
    type: "datetime",
    disabled: false
  };

  const defaultLanguageOptions = {
    dataSource: languages,//dataSourceLanguageOptions,
    displayExpr: "NomLinguagemUsuario",
    valueExpr: "ValIdiomaUsuario"
  }

  const initialDayOptions =
  {
    dataSource: dataSourceHourTypeOptions,
    displayExpr: "Value",
    valueExpr: "Id",
    onInitialized: (e) => convertValueToSelectBox(e)
  };

  const typeGoalOptions =
  {
    dataSource: dataSourceTypeGoal,
    displayExpr: "NomTipo",
    valueExpr: "CodTipo"
  };

  const buttonOptions = {
    type: "default",
    icon: "save",
    height: "3em",
    width: "14em",
    text: "Salvar",
    useSubmitBehavior: true,
    onClick: (e) => handleSubmit(e)
  };

  const codUnitOptions = {
    value: unitCod,
    readOnly: true
  };

  const initialFactoryStopped = {
    value: initialFactoryStop()
  };

  function initialFactoryStop(e) {
    if(dataSourceAplication === undefined && dataSourceSubmit === undefined) return true;

    if(dataSourceSubmit !== undefined && dataSourceSubmit.TipAlteraAmostraFabricaParada !== undefined) return dataSourceSubmit.TipAlteraAmostraFabricaParada;

    if(dataSourceAplication !== undefined) return dataSourceAplication.TipAlteraAmostraFabricaParada;
  };

  const onFieldDataChanged = async (e) => {
    await setDataSourceSubmit(e.component.option("formData"));
  };

  async function handleSubmit(e) {
    const result = await findId();

    dataSourceSubmit.DatInicioFinalDia = convertDate1900(dataSourceSubmit.DatInicioFinalDia)
    result ? 
      updateRow(dataSourceSubmit) :
      insertRow(dataSourceSubmit);
  }

  function convertValueToSelectBox(e) {
    if(e.component.option("value") === true) e.component.option("value", 1)
    if(e.component.option("value") === false) e.component.option("value", 0)
  }

  const convertDate1900 = (parDate) =>{
		let datHour = dayjs(parDate).hour();
		let datMinute = dayjs(parDate).minute();
		let datSecond = dayjs(parDate).second();

		return dayjs("1900-01-01T00:00:00")
				.add(datHour, "hour")
				.add(datMinute, "minutes")
				.add(datSecond, "seconds")
				.format("YYYY-MM-DDTHH:mm:ss");
	}

  return (
    <React.Fragment>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
        <FavButton/>
      <div>
        <div className="form-container" style={{margin:"1% 1% 1% 1.5%"}}>
            <Form
              id="form"
              colCount={1}
              onFieldDataChanged={onFieldDataChanged}
              stylingMode = "outlined"
              labelMode={"floating"}
              alignItemLabelsInAllGroups={false}
              formData={dataSourceAplication}
              ref={_form}
            >
              <GroupItem caption="Sistema" alignItemLabels={true}>
                <GroupItem colCount={13}>
                  <SimpleItem
                    dataField="CodUnidadeEmpresa"
                    editorType={"dxNumberBox"}
                    editorOptions={codUnitOptions}
                    visible={true}
                    colSpan={1}
                    label={{text:"Código Unidade"}}
                    labelMode={"floating"}
                    stylingMode={"outlined"}
                  />
                  <SimpleItem
                      dataField="DesEnderecoPrograma"
                      colSpan={4}
                      label={{text: "Endereço Programa MES"}}
                      isRequired={true}
                  />
                    <SimpleItem
                      dataField="DesEnderecoRelatorio"
                      colSpan={4}
                      label={{text: "Endereço Relatório MES"}}
                      isRequired={true}
                    />
                    <SimpleItem
                      dataField="DesEnderecoLogoCliente"
                      colSpan={4}
                      label={{text: "Endereço Logo Cliente"}}
                    />
                  </GroupItem>
                  <EmptyItem />
                  <GroupItem colCount={8}>
                    <SimpleItem
                        dataField="NomComputadorServidor"
                        label={{text: "Servidor Principal"}}
                        colSpan={1}
                        isRequired={true}
                    />
                    <SimpleItem
                        dataField="NomComputadorServidorReserva"
                        colSpan={1}
                        label={{text: "Servidor Reserva"}}
                    />
                    <SimpleItem
                      dataField="DatExpiracao"
                      editorType="dxDateBox"
                      colSpan={1}
                      label={{text: "Data Expiração Sistema"}}
                      isRequired={true}
                      cssClass={"DataExpiracao"}
                    />
                    <SimpleItem
                      dataField="NumMaximoUsuarios"
                      label={{text: "Máximo de Usuários"}}
                      isRequired={true}
                      cssClass={"MaximoUsuarios"}
                      />
                    <SimpleItem
                      dataField="ValPeriodoDescarteLog"
                      editorType={"dxNumberBox"}
                      showSpinButtons={true}
                      editorOptions={numberBoxOptions}
                      label={{text: "Descarte Logs"}}
                      helpText="Meses"
                      isRequired={true}
                      cssClass={"DescarteLogs"}
                    />
                    <SimpleItem
                      dataField="ValTempoExpiraAutenticacao"
                      editorType={"dxNumberBox"}
                      showSpinButtons={true}
                      editorOptions={numberBoxOptions}
                      label={{text: "Timeout da Aplicação"}}
                      helpText={"Minutos"}
                      isRequired={true}
                    />
                    <SimpleItem
                      dataField="ValTempoNotificacaoUsuario"
                      editorType={"dxNumberBox"}
                      showSpinButtons={true}
                      editorOptions={numberBoxOptions}
                      showColon={true}
                      label={{text: "Tempo Notificações"}}
                      helpText={"Minutos"}
                      isRequired={true}
                      cssClass={"ValTempoNotificacaoUsuario"}
                    />
                    <SimpleItem
                      dataField="TipLinguagemPadrao"
                      editorType={"dxSelectBox"}
                      editorOptions={defaultLanguageOptions}
                      label={{text: "Idioma Padrão"}}
                      isRequired={true}
                    />
                  </GroupItem>
                  <GroupItem colCount={4}>
                    <SimpleItem
                      dataField="ValVersaoMaior"
                      editorOptions={numberBoxOptions}
                      label={{text: "Maior"}}
                      helpText={"Versão do Programa"}
                      isRequired={true}/>
                    <SimpleItem
                      dataField="ValVersaoMenor"
                      editorOptions={numberBoxOptions}
                      label={{text: "Menor"}}
                      isRequired={true}
                    />
                    <SimpleItem
                      dataField="ValVersaoCompilacao"
                      editorOptions={numberBoxOptions}
                      label={{text: "Compilação"}}
                      isRequired={true}
                    />
                    <SimpleItem
                      dataField="ValVersaoRevisao"
                      editorOptions={numberBoxOptions}
                      label={{text: "Revisão"}}
                      isRequired={true}
                    />
                    <SimpleItem
                      dataField="TipAlteraAmostraFabricaParada"
                      editorType={"dxCheckBox"}
                      editorOptions={initialFactoryStopped}
                      label={{text: "Alterar Amostra com Fábrica Parada"}}
                      cssClass={"TipAlteraAmostraFabricaParada"}
                    />

                  </GroupItem>
              </GroupItem>
              <EmptyItem />
              <GroupItem caption="Empresa">
                <GroupItem colCount={7}>
                  <SimpleItem
                    dataField="DatInicioFinalDia"
                    editorType={"dxDateBox"}
                    dataType={"datetime"}
                    displayFormat={"HH:mm"}
                    editorOptions={dateBoxHourOptions}
                    label={{text: "Hora Início/Final do dia"}}
                    isRequired={true}
                    cssClass={"DataInicioFinalDia"}
                  />
                  <SimpleItem
                    dataField="TipInicioDia"
                    editorType={"dxSelectBox"}
                    editorOptions={initialDayOptions}
                    label={{text: "Tipo"}}
                  />
                  <SimpleItem
                    dataField="TipMostrarInicioFinalDiaVariavel"
                    editorType={"dxCheckBox"}
                    label={{text: "Mostrar Variável"}}
                    cssClass={"TipMostrarInicioFinalDiaVariavel"}
                  />
                  <SimpleItem
                    dataField="TipMostrarInicioFinalDiaAmostra"
                    editorType={"dxCheckBox"}
                    label={{text: "Mostrar Amostra"}}
                    cssClass={"TipMostrarInicioFinalDiaAmostra"}
                  />
                  <SimpleItem
                    dataField="TipMostrarInicioFinalDiaEquipamentoLeitura"
                    editorType={"dxCheckBox"}
                    label={{text: "Mostrar Equipamento"}}
                    cssClass={"TipMostrarInicioFinalDiaEquipamentoLeitura"}
                  />
                  <SimpleItem
                    dataField="TipMostrarInicioFinalDiaInsumoLeitura"
                    editorType={"dxCheckBox"}
                    tex
                    label={{text: "Mostrar Insumo"}}
                    cssClass={"TipMostrarInicioFinalDiaInsumoLeitura"}
                  />
                  <SimpleItem
                    dataField="TipMostrarInicioFinalDiaProducao"
                    editorType={"dxCheckBox"}
                    label={{text: "Mostrar Produção"}}
                    cssClass={"TipMostrarInicioFinalDiaProducao"}
                  />
                </GroupItem>
                  <EmptyItem />
              </GroupItem>
              <GroupItem caption="Relatório Gerencial ES-MES" colCount={8}>
                <SimpleItem
                  dataField="TipBaseCalculoRendimento"
                  label={{text: "Base Cálculo Rendimento"}}
                  cssClass={"TipBaseCalculoRendimento"}
                />
                <SimpleItem
                  dataField="TipBaseCalculoConsumo"
                  label={{text: "Base Cálculo Consumo"}}
                  cssClass={"TipBaseCalculoConsumo"}
                />
              </GroupItem>
              <EmptyItem />
              <GroupItem caption="ES-FWS">
              <EmptyItem />
                <GroupItem caption="Gerais" colCount={5}>
                  <SimpleItem
                    dataField="TipMeta"
                    label={{text: "Meta"}}
                    editorType={"dxSelectBox"}
                    editorOptions={typeGoalOptions}
                  />
                </GroupItem>
                <GroupItem caption="Email" colCount={5}>
                  <SimpleItem
                    dataField="DesServidorSmtp"
                    label={{text: "Servidor SMTP"}}
                  />
                  <SimpleItem
                    dataField="DesEmailEnvio"
                    label={{text: "Endereço de Envio"}}
                  />
                  <SimpleItem
                    dataField="DesUsuarioEnvio"
                    label={{text: "Usuário de Envio"}}
                  />
                  <SimpleItem
                    dataField="DesSenhaEnvio"
                    label={{text: "Senha"}}
                  />
                  <SimpleItem
                    dataField="NumPortaEnvio"
                    label={{text: "Porta Saída"}}
                  />
                  <SimpleItem
                    dataField="DatUltimoEnvioEmail"
                    editorOptions={lastEmailOptions}
                    editorType={"dxDateBox"}
                    dataType={"datetime"}
                    displayFormat={formatDateTime()}
                  />
                </GroupItem>
              </GroupItem>

              {!isNaN(dataSourceSubmit.CodAplicacao) ?
                <ButtonItem
                  horizontalAlignment="left"
                  buttonOptions={buttonOptions}
                /> 
              : ''}
            </Form>
          <Tooltip
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position={"bottom"}
            target={".DescarteLogs"}
          >
            Tempo de armazenamento de logs no banco de dados.
          </Tooltip>
          <Tooltip
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position={"bottom"}
            target={".MaximoUsuarios"}
          >
            Máximo Usuários.
          </Tooltip>
          <Tooltip
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position={"bottom"}
            target={".DataExpiracao"}
          >
            Data da expiração do sistema.
          </Tooltip>
          <Tooltip
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position={"bottom"}
            target={".TipAlteraAmostraFabricaParada"}
          >
            Habilita a alteração das amostras no programa de Amostras, da aba Laboratório, mesmo com registro de parada em aberto.
          </Tooltip>
          <Tooltip
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position={"bottom"}
            target={".DataInicioFinalDia"}
          >
            Horário de início ou final de dia da empresa.
          </Tooltip>
          <Tooltip
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position={"bottom"}
            target={".TipMostrarInicioFinalDiaVariavel"}
          >
            Mostra na tela de variáveis o dia conforme hora de início ou final
          </Tooltip>
          <Tooltip
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position={"bottom"}
            target={".TipMostrarInicioFinalDiaAmostra"}
          >
            Mostra na tela de amostras o dia conforme hora de início ou final
          </Tooltip>
          <Tooltip
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position={"bottom"}
            target={".TipMostrarInicioFinalDiaEquipamentoLeitura"}
          >
            Mostra na tela de equipamentos de leitura o dia conforme hora de início ou final
          </Tooltip>
          <Tooltip
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position={"bottom"}
            target={".TipMostrarInicioFinalDiaInsumoLeitura"}
          >
              Mostra na tela de insumos o dia conforme hora de início ou final
          </Tooltip>
          <Tooltip
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position={"bottom"}
            target={".TipMostrarInicioFinalDiaProducao"}
          >
            Mostra na tela de produção o dia conforme hora de início ou final
          </Tooltip>
          <Tooltip
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position={"bottom"}
            target={".ValTempoNotificacaoUsuario"}
          >
            Intervalo de tempo para notificar o usuário de desvios e atrasos.
          </Tooltip>
          <Tooltip
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position={"bottom"}
            target={".TipBaseCalculoRendimento"}
          >
            Utiliza o valor da base de cálculo para calcular percentual de rendimento
          </Tooltip>
          <Tooltip
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position={"bottom"}
            target={".TipBaseCalculoConsumo"}
            >
              Utiliza o valor da base de cálculo para calcular percentual de consumo
          </Tooltip>
          </div>
        </div>
    </React.Fragment>
  );
}
