/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { Button } from "devextreme-react";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "../style.scss";
import { SelectBox } from "devextreme-react";
import { formatMessage } from "devextreme/localization";
import notify from "devextreme/ui/notify";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { CheckBox } from "devextreme-react";
import { LogMessageCheckboxList } from "../../../utils/logMessage";
import { useLoading } from "../../../contexts/loadingContext";
import { useScreenSize } from "../../../utils/media-query";

export default function VariablesPermission(props) {
  const { loading } = useLoading();
  const { isXSmall, isXLSmall, isSmall } = useScreenSize();
  const [load, setLoad] = useState(false);
  const [permission, setPermission] = useState([]);
  const [process, setProcess] = useState([]);
  const [variables, setVariables] = useState([]);
  const [variablePermission, setVariablePermission] = useState([]);
  const [permissionChange, setPermissionChange] = useState(0);
  const [processChange, setProcessChange] = useState(0);
  const [enabledButton, setEnabledButton] = useState(true);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const [textButton, setTextButton] = useState();
  const _list = useRef([]);
  let permissionName = useRef();

  const { unitCod } = useAuth();

  useEffect(() => {
    async function firstLoad(){
      loadDataPermission();
      await loadDataProcess();
      loadDataReadingVariable();
    }
    firstLoad();

  }, [props.update]);

  useEffect(() => {
    if (load){
      loadDataReadingVariablePermission()
    }
  }, [load]);

  useEffect(() => {
    if(permissionChange > 0 || processChange > 0) {
      updates();
    }

    checkSelectionChanged();
  }, [permissionChange, processChange])

  useEffect(() => {
    if(variables.length > variablePermission.length) {
      const newLstVariablePermissions = variables.map(program => program.CodVariavelLeitura);
      setVariablePermission(newLstVariablePermissions);
    }

    if(variablePermission.length === variables.length)
      setVariablePermission([]);
  }, [selectedAll]);

  useEffect(() => {
    if(variables.length === variablePermission.length) setTextButton("Desmarcar todos");
    if(variables.length > variablePermission.length) setTextButton("Marcar todos");
  }, [variablePermission, forceUpdate])

  async function updates() {
    await loadDataReadingVariable()
    loadDataReadingVariablePermission()
  }

  async function loadDataPermission() {
    await api
      .get("/permission", { params: { unitCod } })
      .then((response) => {
        setPermission(response.data);
        setLoad(false);

        return false;
      })
      .catch((error) => {
        notify(formatMessage("UpdateError"), "error", 3000);
        setLoad(true);

        return true
      })
  }

  async function loadDataProcess() {
    await api
      .get("/process", { params: { unitCod } })
      .then((response) => {
        setProcess(response.data);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function loadDataReadingVariablePermission() {
    await api
      .get("/readingVariablesPermission", {params: {codPermission: permissionChange, codProcess: processChange}})
      .then((response) => {
        setVariablePermission(response.data.filter((x) => x.CodVariavelLeituraNavigation.TipVariavelAutomatica === false).map( val => val.CodVariavelLeitura ))
        _list.current = response.data.filter((x) => x.CodVariavelLeituraNavigation.TipVariavelAutomatica === false);

        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function loadDataReadingVariable() {
    await api
      .get("/readingVariable/getReadingVariablesByProcess", { params: { unitCod, codProcess: processChange } })
      .then((response) => {
        setVariables(response.data.filter((x) => x.TipVariavelAutomatica === false));

        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function insertRow(data, logMessage) {
    return await api
      .post("/readingVariablesPermission", { unitCod, data, logMessage })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function deleteRow(data) {
    return await api
      .delete("/readingVariablesPermission", { data })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  function onSelectionChangedPermission(e) {
    setPermissionChange(e.selectedItem ? e.selectedItem.CodPermissao : 0)
    permissionName.current = (e.selectedItem ? e.selectedItem.NomPermissao : 0)
  }

  function onSelectionChangedProcess(e) {
    setProcessChange(e.selectedItem ? e.selectedItem.CodProcesso : 0)
  }

  function onValueChangedCheckBoxPermissions(e, codReadingVariable) {
    if(e.event) {
      let newLstVariablesPermission = variablePermission;

      if(e.value) {
        newLstVariablesPermission.push(codReadingVariable);
        setVariablePermission(newLstVariablesPermission);
        setForceUpdate(!forceUpdate);
      } else {
        newLstVariablesPermission = variablePermission.filter(code => code !== codReadingVariable);
        setVariablePermission(newLstVariablesPermission);
        setForceUpdate(!forceUpdate);
      };
    }
  };

  function checkSelectionChanged() {
    permissionChange > 0 || processChange > 0 ? setEnabledButton(false) : setEnabledButton(true);
  }

  async function eventSaveClick() {
    const deleteReadingVariablesPermission = _list.current.filter((element) => {
      const found = variablePermission.find(e => e === element.CodVariavelLeitura)

      if(found !== undefined) return null;

      element.NomLogFirst = permissionName.current
      element.NomLogSecond = variables.filter(x => x.CodVariavelLeitura === element.CodVariavelLeitura)[0].NomVariavelLeitura;
      return element
    })

    const insertReadingVariablePermission = []

    variablePermission.forEach((codVariavelLeitura) => {
      const found = _list.current.find((element) => element.CodVariavelLeitura === codVariavelLeitura)

      if(found !== undefined) return;
      
      variables.forEach((vrb) => {
        if(vrb.CodVariavelLeitura === codVariavelLeitura) {
          const newReadingVariablePermission = {
            CodPermissao: permissionChange,
            CodVariavelLeitura: codVariavelLeitura,
            NomLogFirst: permissionName.current,
            NomLogSecond: vrb.NomVariavelLeitura
          }

          insertReadingVariablePermission.push(newReadingVariablePermission)
        }
      })
    })

    deleteReadingVariablesPermission.forEach((data) => {
      let logMessage = LogMessageCheckboxList(formatMessage("DeletedLog"), data)
      const value = { unitCod, data, logMessage }

      deleteRow(value);
    })

    insertReadingVariablePermission.forEach((item) => {
      let logMessage = LogMessageCheckboxList(formatMessage("InsertedLog"), item)
      insertRow(item, logMessage);
    })
  }

  return (
    <React.Fragment>
      <div className="header-form">
        <div className={"form-container"}>
          <div className={"margin-element"}>
              <SelectBox
                dataSource={permission}
                placeholder={"Permissão"}
                searchEnabled={true}
                onSelectionChanged={onSelectionChangedPermission}
                displayExpr="NomPermissao"
                valueExpr="CodPermissao"
                width={250}
                stylingMode={"outlined"}
                labelMode={"floating"}
              />
          </div>
          <div className={isXLSmall ? "invisible-item" : isXSmall ? "invisible-item" : isSmall ? "invisble-item" : "margin-element"}>
              <SelectBox
                dataSource={process}
                label={"Processo"}
                searchEnabled={true}
                onSelectionChanged={onSelectionChangedProcess}
                displayExpr="NomProcesso"
                valueExpr="CodProcesso"
                showClearButton={true}
                width={250}
                stylingMode={"outlined"}
                labelMode={"floating"}
              />
          </div>
          <div className={"margin-element"}>
            <Button
              text={textButton}
              icon={!selectedAll ? "selectall" : "unselectall"}
              type="normal"
              className={"selectall-button"}
              useSubmitBehavior={false}
              stylingMode="contained"
              onClick={() => setSelectedAll( state => !state )}
              disabled={enabledButton}
            />
          </div>
          <div className={"margin-element"}>
            <Button
              className={"save-button"}
              id="searchFilterButton"
              icon="save"
              text="Salvar"
              type="default"
              useSubmitBehavior={false}
              stylingMode="contained"
              onClick={eventSaveClick}
              disabled={enabledButton}
            />
          </div>
        </div>
      </div>
      <div style={{paddingTop: '30px'}}>
        {process.map(thisProcess => {
          const variablesProcess = variables.filter(e => e.CodProcesso === thisProcess.CodProcesso)

          return variablesProcess.length > 0 ?
            <div key={thisProcess.CodProcesso}>
              <h3 className="title-permission">{thisProcess.NomProcesso}</h3>
              
              {variablesProcess.map(val => 
                <div style={{display: "inline-block", margin: "3% 1% 0% 2%", width: "200px"}} key={val.CodVariavelLeitura}>
                  <CheckBox
                    className={"checkbox-medium"}
                    value={variablePermission.some(e => e === val.CodVariavelLeitura)}
                    text={`${val.NomVariavelLeitura} - ${val.DesVariavelLeitura}`}
                    onValueChanged={e => onValueChangedCheckBoxPermissions(e, val.CodVariavelLeitura)}
                  />
                </div>
              )}
            </div>
          : '';
        })}
      </div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
