/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "devextreme/data/odata/store";
import { Tabs } from "devextreme-react";
import { FavButton } from "../../../components/fav-button/favButton";
import GroupDashboard from "./groupDashboard";
import ItemDashboard from "./itemDashboard";

export default function DashboardConfigurationTab() {
	const [selectedTab, setSelectedTab] = useState(0);

	return (
		<React.Fragment>
			<FavButton/>
			<Tabs 
				className={"tabs-panel"}
				items={["Cadastro Grupo","Cadastro Item"]}
				onItemClick={e => setSelectedTab(e.itemIndex)}
				selectedIndex={selectedTab}
			/>
			{selectedTab === 0 ? <GroupDashboard /> : ''}
			{selectedTab === 1 ? <ItemDashboard /> : ''}
			
		</React.Fragment>
	);
}
