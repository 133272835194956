/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef  } from "react";
import "devextreme/data/odata/store";
import { api } from "../../../services/api";
import notify from "devextreme/ui/notify";
import { formatMessage } from "devextreme/localization";
import { useAuth } from "../../../contexts/auth";
import { FavButton } from "../../../components/fav-button/favButton";
import { Button, NumberBox, SelectBox } from "devextreme-react";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import isoWeek from "dayjs/plugin/isoWeek";
import { LogMessageCheckboxList } from "../../../utils/logMessage";
import "./style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { useLoading } from "../../../contexts/loadingContext";
import DateBox from "devextreme-react/date-box";

export default function Goals(props) {
  dayjs.extend(localeData);
  dayjs.extend(isoWeek);
  const { loading } = useLoading();
  const [goalType, setGoalType] = useState(null);
  const [goalsToInsert, setGoalsToInsert] = useState([]);
  const [dailyGoals, setDailyGoals] = useState([]);
  const [monthlyGoals, setMonthlyGoals] = useState([]);
  const [load, setLoad] = useState(false);
  const [shifts, setShifts] = useState([]);
  const [monitorationBlock, setMonitorationBlock] = useState([]);
  const [selectedMonitorationBlock, setSelectedMonitorationBlock] = useState(0);
  const [disabledButton, setDisabledButton] = useState(true);
  const [year, setYear] = useState(new Date());
  const [day, setDay] = useState(new Date());
  const weekDays = dayjs.weekdaysShort();
  const months = dayjs.monthsShort();
  const startWeek = useRef(null)
  const endWeek = useRef(null)
  //Autenticação requests
  const { unitCod } = useAuth();

  //#region UseEffects
  useEffect(() => {
    if (goalType === 0) {
      startWeek.current = dayjs(day).startOf("week").subtract(1, "d").format("YYYY-MM-DD")
      endWeek.current = dayjs(day).endOf("week").subtract(1, "d").format("YYYY-MM-DD")

      if (startWeek.current && endWeek.current) {
        loadDailyGoals();
        setLoad(false)
      }
    } else {
      if (year) {
        loadMonthlyGoals();
        setLoad(false)
      }
    }
  }, [selectedMonitorationBlock, day, year]);

  useEffect(() => {
    loadAplicationData();
    loadShiftsData();
    loadMonitorationBlockData();
  }, [])

  //#endregion

  //#region Requests
  async function loadDailyGoals() {
    await api
      .get("/dailyGoalMonitorationBlock/get", {
        params: {
          unitCod: unitCod,
          Id: selectedMonitorationBlock,
          startWeek: startWeek.current,
          endWeek: endWeek.current,
        },
      })
      .then(res => {
        setDailyGoals(res.data);
      })
      .catch(e => {
        setLoad(false);
        return true;
      })
      .finally(e => {
          setLoad(true)
      });
  }

  async function loadMonthlyGoals() {
    await api
      .get("/monthlyGoalMonitorationBlock/get", {
        params: {
          unitCod: unitCod,
          Id: selectedMonitorationBlock,
          Year: year.getFullYear(),
        }
      })
      .then((response) => {
        setMonthlyGoals(response.data);
      })
      .catch(e => {
        setLoad(false);
        return true;
      })
      .finally(e => {
        setLoad(true)
      })
  }

  async function insertUpdateDailyGoals(data, parLogMessage) {
    await api
      .post("/dailyGoalMonitorationBlock", {
        unitCod: unitCod,
        data: data,
        logMessage: parLogMessage,
      })
      .then(res => {
        setLoad(true);
        return false;
      })
      .catch(e => {
        setLoad(false);
        return true;
      });
  }

  async function insertUpdateMonthlyGoals(data, parLogMessage) {
    await api
      .post("/monthlyGoalMonitorationBlock", {
        unitCod: unitCod,
        data: data,
        logMessage: parLogMessage,
      })
      .then(res => {
        setLoad(true);
        return false;
      })
      .catch(e=> {
        setLoad(false);
        return true;
      });
  }

  async function loadAplicationData() {
    await api
      .get("/aplication", { params: { unitCod: unitCod } })
      .then(res => {
        setGoalType(res.data[0].TipMeta);
      })
      .catch(e => {
        setLoad(false);
        return true;
      });
  }

  async function loadShiftsData() {
    await api
      .get("/shifts", { params: { unitCod: unitCod } })
      .then(res => {
        setShifts(res.data);
      })
      .catch(e => {
        notify(formatMessage("ShiftError"), "error", 3000);
        setLoad(false);
      });
  }

  async function loadMonitorationBlockData() {
    await api
      .get("/MonitorationBlock", { params: { unitCod: unitCod } })
      .then(res => {
        setMonitorationBlock(res.data);
      })
      .catch(e => {
        setLoad(false);
      });
  }
  //#endregion

  //#region Events

  async function onValueChangedNumberBox(e, shift, date) {
    if (e.event === undefined) return;
    setDisabledButton(false);

    let newGoal;
    if (goalType === 0) {
      newGoal = {
        CodBlocoMonitoracao: selectedMonitorationBlock,
        TipDiaSemana: dayjs(date).day() + 1,
        CodTurno: shift.CodTurno,
        DatMeta: date,
        ValPesoMeta: e.component.option("value"),
        NomLogFirst: monitorationBlock.find(e => e.CodBlocoMonitorcao === selectedMonitorationBlock.CodBlocoMonitoracao).NomBlocoMonitoracao,
        NomLogSecond: "Meta: "+e.component.option("value")+" no dia: "+date
      };
      setGoalsToInsert(goalsList => [...goalsList, newGoal]);
    } else {
      date = dayjs(date).year(year.getFullYear()).format("YYYY-MM-DD");
      newGoal = {
        CodBlocoMonitoracao: selectedMonitorationBlock,
        TipMes: dayjs(date).month() + 1,
        DatMeta: date,
        ValPesoMeta: e.component.option("value"),
        NomLogFirst: monitorationBlock.find(e => e.CodBlocoMonitorcao === selectedMonitorationBlock.CodBlocoMonitoracao).NomBlocoMonitoracao,
        NomLogSecond: "Meta: "+e.component.option("value")+" no mês: "+date
      };
      setGoalsToInsert(goalsList => [...goalsList, newGoal]);
    }
  }

  async function eventSaveClick() {
    setDisabledButton(true);

    for (const goal of goalsToInsert) {
      let logMessage = LogMessageCheckboxList(formatMessage("InsertedLog"), goal);
      
      if(goalType === 0) await insertUpdateDailyGoals(goal, logMessage);
      else await insertUpdateMonthlyGoals(goal, logMessage);
    }
    setGoalsToInsert([]);
  }
//#endregion

  return (
    <React.Fragment>
      <div className="header-form">
        <div className={"form-container"}>
          <div className={"margin-element"}>
            <SelectBox
              dataSource={monitorationBlock}
              label={"Bloco de Monitoração"}
              searchEnabled={true}
              onSelectionChanged={e => setSelectedMonitorationBlock(e.selectedItem.CodBlocoMonitoracao)}
              displayExpr="NomBlocoMonitoracao"
              valueExpr="CodBlocoMonitoracao"
              width={250}
              height={45}
              stylingMode={"outlined"}
              labelMode={"floating"}
            />
          </div>
          {goalType === 0 && (
            <div className={"margin-element"}>
              <DateBox
                label={"Semana do Dia"}
                width={250}
                height={45}
                showClearButton={false}
                type="date"
                value={day}
                onValueChanged={e => setDay(e.value)}
                stylingMode={"outlined"}
                labelMode={"floating"}
              />
            </div>
          )}
          {goalType === 1 && (
            <div className={"margin-element"}>
              <DateBox
                label={"Ano"}
                width={250}
                height={45}
                showClearButton={false}
                type="date"
                displayFormat={"yyyy"}
                value={year}
                calendarOptions={{zoomLevel: "decade", maxZoomLevel: "decade"}}
                stylingMode={"outlined"}
                labelMode={"floating"}
                onValueChanged={e => setYear(e.value)}
              />
            </div>
          )}
          <div className={"margin-element"}>
            <Button
              className={"save-button"}
              id="searchFilterButton"
              icon="save"
              text="Salvar"
              type="default"
              useSubmitBehavior={false}
              stylingMode="contained"
              onClick={eventSaveClick}
              disabled={disabledButton}
            />
          </div>
        </div>
      </div>
      <FavButton />
      {selectedMonitorationBlock ? (
        goalType === 0 && load ? (
          <div className={"container-data"}>
            <h2 style={{marginLeft: "2vw", width: "100%"}}><b>Semana: </b>{dayjs(startWeek.current).format("L")} - {dayjs(endWeek.current).format("L")}</h2>
            {shifts.map(shift => {
              let startOfWeek = dayjs(day)
                .startOf("week")
                .subtract(1, "d")
                .format("YYYY-MM-DD");

              return (
                <div
                  style={{
                    flexGrow: "2",
                    marginLeft: "2vw",
                    marginRight: "5vw",
                    marginBottom: "2vw"
                  }}
                >
                  <h2 style={{fontSize:26}}>{`${shift.NomTurno} (${dayjs(shift.DatHoraInicial).format("LT")} - ${dayjs(shift.DatHoraFinal).format("LT")})`}</h2>
                  <div className="container-data" style={{marginLeft: "1vw"}}>
                    {weekDays.map((day, dayIndex) => {
                      const date = dayjs(startOfWeek).add(dayIndex, "d").format("YYYY-MM-DD");

                      return (
                        <div style={{marginRight: "3%"}}>
                          <h3>{day}</h3>
                          <NumberBox
                            id={`${shift.CodTurno}:${dayIndex}`}
                            format="#,##0.00"
                            stylingMode="outlined"
                            onValueChanged={e => onValueChangedNumberBox(e, shift, date)}
                            onContentReady={e => dailyGoals.forEach(goal => {
                              if(goal.CodTurno === shift.CodTurno && goal.TipDiaSemana === dayIndex + 1){
                                e.component.option("value", parseFloat(goal.ValPesoMeta));
                              }
                            })}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        ) : goalType === 1  && load ? (
          <div className={"container-data"}>
            {months.map((month, monthIndex) => {
              let date = dayjs()
                .month(0)
                .add(monthIndex, "month")
                .format("YYYY-MM-DD");

              return (
                <div key={month} style={{
                    marginLeft: "2vw",
                    marginRight: "3vw",
                    marginBottom: "1vw",
                  }}
                >
                  <div style={{marginLeft: "1vw"}}>
                    <h3>{month}</h3>
                    <NumberBox
                      format="#,##0.00"
                      onValueChanged={e => onValueChangedNumberBox(e, 0, date)}
                      stylingMode="outlined"
                      onContentReady={e => monthlyGoals.forEach(goal => {
                        if(goal.TipMes === monthIndex + 1) {
                          e.component.option("value", parseFloat(goal.ValPesoMeta))
                        }
                      })}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ) : null
      ) : null}
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
