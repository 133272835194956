/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { Button } from "devextreme-react";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { SampleInputs } from "./sampleInputs"
import { useDataLoader } from "../../../contexts/dataLoaderContext"
import dayjs from "dayjs";

export default function ButtonListSecondary(props) {
	const [load, setLoad] = useState()
	const { unitCod, userCod } = useAuth()
	const { _lstInputVariableSave,  setSaveHeaderTextBox, numLot, setNumLot, _lstTimeListAnalysis } = useDataLoader()
	const [reLoad, setReLoad] = useState()
	const [lstGroupAnalysis, setLstGroupAnalysis] = useState()
	const [listLots, setListLots] = useState();
	let _strNameAnalysisGroup = useRef();
	let numLotFinal = useRef()
	//let _listLots = useRef([])
	let _analysisTimeList = useRef([])
	let _listAnalysisSample = useRef([])
	let _lstVariableInput = useRef([])
	let _booBlockVariables = useRef([])
	let _intAnalysisGroupCode = useRef()
	let _isLot = useRef()
	let _lstDescObservation = useRef([])
	let _lstUserDataSource = useRef([])

	//#region useEffect
		useLayoutEffect(() => {
			setSaveHeaderTextBox(false)
			getActiveAnalysisTime();
			getAnalysisLots(_intAnalysisGroupCode.current)
			getUsers();
			// props.refHeaderSelectBox.instance.option("value", null);
			props.setCurrencyValueDate(dayjs().valueOf())
		}, []);

		useLayoutEffect(() => {
			setLstGroupAnalysis(!props.searchAnalysisGroupText ? props.lstGroupAnalysis : props.lstGroupAnalysis.filter(lstGroupAnalysis => lstGroupAnalysis.NomAnaliseGrupo.toUpperCase().indexOf(props.searchAnalysisGroupText.toUpperCase()) > -1))
			setLoad(true)
		}, [props.searchAnalysisGroupText]);

		useLayoutEffect(() => {
			numLotFinal.current = numLot;
		}, [numLot]);
	//#endregion

	//#region events
	async function loadVariableInputs(parAnalysisGroupCode) {
		getDescObservationGroup(parAnalysisGroupCode)
		getUsers()
		props.setLoading(true)
		_lstInputVariableSave.current = []
		_intAnalysisGroupCode.current = parAnalysisGroupCode;
		await getActiveAnalysisTime(parAnalysisGroupCode)
		await getAnalysisSample(parAnalysisGroupCode)
		await getAnalysisLots(parAnalysisGroupCode)
		await props.setScreen("input")
	}
	//#endregion

	//#region Requests
	
	//[0] Nome da Análise
	//[1] Tipo da Verificação (Intervalo, Maximo, Minimo...)
	//[2] Range da aplicação
	//[3] Valor Máximo
	//[4] Valor Mínimo
	//[5] Horas possíveis para edição, dependendendo da hora atual
	//[6] Horas possíveis para edição, dependendendo se tem registro de fábrica parada
	//[7] Range de horas da análise
	//[8] Objeto {CodAnalise, CodAnaliseGrupo, ValIdeal, ValPositivo}
	//[9] Se é amostragem por lote (booleano)
	async function getActiveAnalysisTime(parAnalysisGroupId) {
		if (parAnalysisGroupId > 0) {
			await api
				.get("sample/sampleinputs", {
					params: {
						unitCod: unitCod,
						GroupAnalysisId: parAnalysisGroupId,
						DateBoxValue: dayjs(props.refHeaderDateBoxValue.instance.option("value")).format("YYYY-MM-DDTHH:mm:ss"),
					},
				})
				.then((response) => {
					_isLot.current = response.data[0][9];
					_analysisTimeList.current = response.data;
					_lstTimeListAnalysis.current = response.data;
					_intAnalysisGroupCode.current = parAnalysisGroupId;
				})
				.catch(() => {
					return true;
				});
		}
	}

	async function getUsers() {
		await api
			.get("/user", { params: { unitCod: unitCod } })
			.then((response) => {
				userCod === 0 ? _lstUserDataSource.current = response.data : _lstUserDataSource.current = response.data.filter((x) => x.CodUsuario !== 0);
			})
			.catch((error) => {
				console.log(error);

				return true;
			});
	}

	async function getAnalysisSample(parAnalysisGroupId) {
			//if ((_isLot.current === true && numLot !== "" && numLot !== undefined) || _isLot.current === false) {
			await api
				.get("/analysissample/get", {
					params: { 
					datAnalysis: dayjs(props.refHeaderDateBoxValue.instance.option("value")).format("YYYY-MM-DDTHH:mm:ss"), 
					codAnalysis: parAnalysisGroupId,
					numLot: _isLot.current ? numLotFinal.current : null,
					unitCod: unitCod
				 },
				})
				.then((response) => {
					if(response.data[0] !== undefined){
						_listAnalysisSample.current = response.data;
						//setNumLot(_isLot.current ? response.data[0] !== undefined ? response.data[0].NumLote : "" : "");
						setLoad(false)
						setReLoad(true)
					}
					else{
						let data = [];
						//setNumLot("");
						_listAnalysisSample.current = data;
					}
					setLoad(false)
					setReLoad(true)
				})
				.catch((error) => {
					console.log(error);

					return false;
				})
			//}
	}

	async function getAnalysisLots(parAnalysisGroupId) {
		if (_isLot.current === true) {
			await api
				.get("/sample/lots", {
					params: { dateBoxValue: dayjs(props.refHeaderDateBoxValue.instance.option("value")).format("YYYY-MM-DDTHH:mm:ss"), groupAnalysisId: parAnalysisGroupId },
				})
				.then((response) => {
					//_listLots.current = response.data
					setListLots(response.data);
				})
				.catch((error) => {
					console.log(error);

					return false;
				});
		}
	}

	async function getDescObservationGroup(parAnalysisGroupId) {
		await api
			.get("/sample/descObservationGroup", {
				params: {
					dateBoxValue: dayjs(props.refHeaderDateBoxValue.instance.option("value")).format("YYYY-MM-DDTHH:mm:ss"),
					groupAnalysisId: parAnalysisGroupId,
					numLot: _isLot.current ? numLotFinal.current : null,
					unitCod: unitCod
				},
			})
			.then((response) => {
				_lstDescObservation.current = response.data;
				//setListDescObservation(response.data);
			})
			.catch((error) => {
				console.log(error);
				return false;
			});
	}
	//#endregion

	return (
		<div>
			{load ?
				<div>
					<h2>Processo: {props.processName}</h2>
					<div className="dx-field-label sample-subtitle">Escolha o Grupo de Análise</div>
					{lstGroupAnalysis.map((value, i) => 
						<Button
							key={i}
							className={"process-button"}
							text={props.screen === "variable" ? value.NomAnaliseGrupo : "" }
							stylingMode="outlined"
							searchEnabled={false}
							onClick={() => {
								let analysisGroupCode = value.CodAnaliseGrupo
								setNumLot("")
								_strNameAnalysisGroup.current = value.NomAnaliseGrupo
								loadVariableInputs(analysisGroupCode)
							}}
						/>
				  	)}
				</div>
			: reLoad ?
				<SampleInputs 
					lstVariable={_lstVariableInput.current}
					lstDescObservation = {_lstDescObservation.current}
					lstGroupAnalysis={lstGroupAnalysis}
					lstAllGroupAnalysis={props.lstAllGroupAnalysis}
					analysisGroupCode={_intAnalysisGroupCode.current}
					refHeaderDateBoxValue={props.refHeaderDateBoxValue}
					setReLoad={setReLoad}
					loadVariableInputs={loadVariableInputs}
					currencyValueDate={props.currencyValueDate}
					booBlockVariables={_booBlockVariables.current}
					analysisTimeList = {_analysisTimeList.current}
					listAnalysisSample={_listAnalysisSample.current}
					lstUserDataSource={_lstUserDataSource.current}
					listLots={listLots}
					setLoading={props.setLoading}
					strNameAnalysisGroup={_strNameAnalysisGroup}
					actionOnlyViewer={props.actionOnlyViewer}
					processCode={props.processCode}
				/>
			: ""}
		</div>
	);
}
