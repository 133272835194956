import "devextreme/data/odata/store";
import "../../components/common/style.scss";
import "./style.scss";

export const AddButtonGrid = (e) => {
  var dataGrid = e.component;
  let disabledAddButton;

  e.toolbarOptions.items.unshift({
    location: "after",
    widget: "dxButton",
    disabled: disabledAddButton,
    options: {
    elementAttr: {id: 'ToolbarAddNewButton'},
    hint: "Adicionar um registro",
    icon: "add",
    onClick: () => {dataGrid.addRow()}
  },
  });
};