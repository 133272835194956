/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import ChangePassword from "../../../components/change-password-form/change-password-form";
import "../style.scss";
import DataGrid, {
  Column,
  Pager,
  RequiredRule,
  Paging,
  FilterRow,
  Export,
  Selection,
  ColumnChooser,
  Grouping,
  GroupPanel,
  ColumnFixing,
  CustomRule,
  Lookup, Item, Toolbar
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useDataLoader } from "../../../contexts/dataLoaderContext";
import { useAuth } from "../../../contexts/auth";
import { EmailRule } from "devextreme-react/form";
import { languages } from '../../../utils/languages';
import { LogMessageGrid } from "../../../utils/logMessage"
import { formatMessage } from "devextreme/localization";
import { loadDataUsers } from "../../../utils/functionRequests";
import notify from "devextreme/ui/notify";
import { onEditorPreparingChangePassword } from "../../../utils/onEditorPreparingChangePassword";
import Button from "devextreme-react/button";

export default function User(props) {
  const { setEnabledActualPassword, setChangePassword, changePassword, passwordChanged } = useDataLoader();
  const [load, setLoad] = useState(false);
  const [user, setUser] = useState([]);
  const { refreshToken, getRefreshTokenCode, getToken } = useAuth();
  const [event, setEvent] = useState([]);
  const [defaulLanguageUnit, setDefaultLanguageUnit] = useState();
  const [userCodKey, setUserCodKey] = useState();
  //
  const _user = useRef([]);

  //Autenticação requests
  const { unitCod, userCod } = useAuth();

  useEffect(() => {
      async function firstLoad() {
          setLoad(true);
      }
      firstLoad();
      refreshToken(getToken(), getRefreshTokenCode());
  }, []);

  useEffect(() => {
    if (load){
      loadDataUsers(userCod, setUser, setLoad);
      loadDataAplication();
    }
  }, [load]);

  async function updateRow(data, parLogMessage) {
    return await api
      .put("/user", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true);
        props.setUpdate(!props.update)
        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  };

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/user", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true);
        props.setUpdate(!props.update)
        props.setUserRegistred(response.data.CodUsuario);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  };

  async function deleteRow(data) {
    return await api
      .delete("/user", { data: data })
      .then((response) => {
        setLoad(true);
        props.setUpdate(!props.update)
        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  };

  async function loadDataAplication() {
    await api
      .get("/aplication/getTipLinguagemPadrao", { params: { unitCod: unitCod } })
      .then((response) => {
        setDefaultLanguageUnit(response.data);

        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  };

  function checkUniqueKeyNameUser(params) {
    let check = true;

    user.forEach((unit) => {
      if(unit.CodUsuario !== params.data.CodUsuario &&
        unit.NomUsuario === params.data.NomUsuario
        ) check = false;
    })

    return check;
  };

  function checkUniqueKeyNameLoginUser(params) {
    let check = true;

    user.forEach((unit) => {
      if(unit.CodUsuario !== params.data.CodUsuario &&
        unit.NomLoginUsuario === params.data.NomLoginUsuario
        ) check = false;
    })

    return check;
  };

  const GridComp = () => {
    return (
      <DataGrid
        className={"dx-cards"}
        dataSource={user}
        rowAlternationEnabled={true}
        showColumnLines={true}
        allowColumnReordering={true}
        showBorders={false}
        columnAutoWidth={true}
        onEditCanceled={(e) =>{
          _user.current.instance.endUpdate();
        }}
        onSaved={() => _user.current.instance.endUpdate()}
        columnHidingEnabled={true}
        repaintChangesOnly={true}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        onEditorPreparing={(e) => onEditorPreparingChangePassword(e, setEvent, passwordChanged, setChangePassword)}
        onEditingStart={(e) => {
          if(!!e.key){
            let rowData = _user.current.instance.getRowIndexByKey(e.key)
            if(_user.current.instance.option('dataSource')[rowData]){
              setEnabledActualPassword(true);
              setUserCodKey(e.key.CodUsuario)
            }
          }

          if(!e.key){
            setEnabledActualPassword(false);
          }
 
        }}
        onInitNewRow={(e) => {
          setEnabledActualPassword(false);
          e.data.TipRegistroAtivo = true;
          e.data.TipAdministrador = false;
          e.data.ValIdiomaUsuario = 0;
          e.data.CodUnidadeEmpresa = unitCod;
          e.data.ValIdiomaUsuario = defaulLanguageUnit;
          e.data.DesMatriculaUsuario = "0000";
        }}
        editing={{
          refreshMode: "full",
          mode:"batch",
          allowUpdating: true,
          allowDeleting: true,
          allowAdding: false,
        }}
        onRowUpdating={async (e) => {
          const newObj = { ...e.oldData, ...e.newData };
          newObj.NomLoginUsuario = newObj.NomLoginUsuario.toUpperCase();
          let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _user, newObj);

          e.cancel = updateRow(newObj, strLogMessage);

          e.component.deselectAll();
        }}
        onRowInserting={(e) => {
          e.data.NomLoginUsuario = e.data.NomLoginUsuario.toUpperCase();
          let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _user, e.data);

          e.cancel = insertRow(e.data, strLogMessage);

          e.component.deselectAll();
        }}
        onRowRemoving={(e) => {
          if (e.data.CodUsuario === 0)  {
            notify("Não é possível excluir esse usuário", "error", 3000)
            e.cancel = true;
          }
          else {
            let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _user, e.data)
            const data = { unitCod: unitCod,  data: e.data, logMessage: strLogMessage }

            e.cancel = deleteRow(data);

            e.component.deselectAll();
          }
        }}
        ref={_user}
      >
        <Grouping
          contextMenuEnabled={false}
          expandMode="rowClick"
          autoExpandAll={false}
        />
        <GroupPanel visible={false} />
        <Export
          enabled={true}
          fileName="Usuário"
          allowExportSelectedData={true}
        />
        <ColumnChooser enabled={true} mode="select" />
        <ColumnFixing enabled={false} />
        <Selection mode="multiple" />
        <Paging defaultPageSize={20} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <Toolbar >
          <Item cssClass={'add-container'}>
            <Button className={'add-button'}  icon={"add"} onClick={() => (_user.current.instance.addRow())}/>
          </Item>
          <Item name="saveButton"/>
          <Item name="revertButton"/>
          <Item name="exportButton"/>
          <Item name="columnChooserButton"/>
        </Toolbar>
        <Column
          dataField={"NomUsuario"}
          caption={"Nome"}
          allowSorting={true}
          width={"auto"}
        >
          <CustomRule
            message={'Este campo deve ser único'}
            reevaluate={true}
            type={"custom"}
            validationCallback={checkUniqueKeyNameUser}
          />
          <RequiredRule />
        </Column>
        <Column
          dataField={"NomLoginUsuario"}
          caption={"Login"}
          allowSorting={true}
          dataType={"string"}
          cssClass={"uppercase"}
          width={"auto"}
        >
          <CustomRule
            message={'Este campo deve ser único'}
            reevaluate={true}
            type={"custom"}
            validationCallback={checkUniqueKeyNameLoginUser}
          />
          <RequiredRule />
        </Column>
        <Column
          dataField={"DesMatriculaUsuario"}
          caption={"Matrícula"}
          allowSorting={true}
          width={"auto"}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField={"DesEmailUsuario"}
          caption={"Email"}
          allowSorting={true}
          width={"auto"}
        >
          <EmailRule message={"Email inválido"} type={"email"}/>
        </Column>
        <Column
          dataField={"ValIdiomaUsuario"}
          caption={"Idioma"}
          allowSorting={true}
          visible={true}
          width={"auto"}
        >
          <Lookup
            dataSource={languages}
            displayExpr="NomLinguagemUsuario"
            valueExpr="ValIdiomaUsuario"
          />
          <RequiredRule />
        </Column>
        <Column
          dataField={"TipRegistroAtivo"}
          caption={"Ativo"}
          allowSorting={true}
          width={"auto"}
        >
        </Column>
        <Column
          dataField={"ValSenhaUsuario"}
          caption={"Senha"}
          customizeText={() => "*********"}
          editorOptions={{mode: 'password'}}
          allowFiltering={false}
          allowGrouping={false}
          allowSearch={false}
          allowSorting={false}
          allowEditing={true}
          width={"auto"}
        >
          <RequiredRule />
        </Column>
      </DataGrid>
    )};

  return (
    <React.Fragment>
      <div>
        {useMemo(() => GridComp(), [user])}
        {changePassword && <ChangePassword userCod={userCodKey} event={event} dataGrid={_user}/>}
      </div>
    </React.Fragment>
  );
}
