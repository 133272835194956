const dictionary = {
  en: {
    Home: "Home",
    About: "About",
    Reports: "Reports",
    Date: "Date",
    Report: "Report",
    InsertedLog: "Inserted register ",
    UpdatedLog: "Updated register",
    DeletedLog: "Removed register",
    InsertedSuccess: "Successfully inserted",
    UpdatedSuccess: "Successfully updated",
    DeletedSuccess: "Successfully removed",
    DateValid: "Date valid",
    InsertError: "Insert Error",
    InsertErrorDuplicated: "Already registered",
    InsertErrorLoginExisting: "Login already existing in any unit of the company",
    UpdateError: "Update Error",
    UpdateErrorDuplicated: "Already registered",
    DateInvalid: "Date invalid",
    DeleteError: "Delete Error",
    DeleteForeignError: "Cannot delete as the item has already been used in another program",
    SelectOption: "Select an option!",
    PasswordIncorrect: "User and/or password incorrect",
    ActualPasswordIncorrect: "The current password entered is incorrect",
    NoRegisteredUnit: "No registered unit",
    DateExpiration: "Your system validation has expired. Please contact a Nexxus.",
    InsertedSample: "Inserted analysis sample ",
    UpdatedSample: "Updated analysis sample ",
    DeletedSample: "Removed analysis sample ",
    InsertedVariable: "Inserted variable ",
    UpdatedVariable: "Updated variable ",
    DeletedVariable: "Removed variable ",
    InsertedTask: "Inserted task:",
    UpdatedTask: "Updated task:",
    DeletedTask: "Deleted task:",
    ExecutedTask: "Executed task: ",
    MonitoredTask: "Monitored task: ",
    VerifiedTask: "Verified task: ",
    ShiftError: "Check shift's start and finish times",
    ApproveCriticalPoint: "Approve Critical Point",
    Approved: "Approved",
    Disapproved: "Disapproved",
    Required: "The field is mandatory",
    ReceiptRegister: "Receipt Register",
    ReceiptTransport: "Receipt Transport",
    Users: "Users",
    Permission: "Permission",
    Tip: "Tip",
    Shift: "Shift",
    DataAcess: "Data Acess",
    Process: "Process",
    AreaProcess: "Area Process",
    CompanyAndUnit: "Company and Unit",
    Product: "Product",
    Stock: "Stock",
    Measure: "Measure",
    Sector: "Sector",
    Aplication: "Aplication",
    Programs: "Programs",
    Locality: "Locality",
    Configuration: "Configuration",
    PermissionLaboratory: "Permission Laboratory",
    RegisterReasonType: "Register Reason Type",
    StopsByArea: "Stops By Area",
    StopsByProcess: "Stops By Process",
    AutomaticVariables: "Automatic Variables",
    ManualVariables: "Manual Variables",
    RegisterAnalysis: "Register Analysis",
    Group: "Group",
    InputDirect: "Input Direct",
    CreateTask: "Create Task",
    ExecuteTask: "Execute Task",
    VariableInputByVariable: "Variable Input By Variable",
    VariableInputByDate: "Variable Input By Date",
    Sample: "Sample",
    RegisterVariable: "Register Variable",
    ConfigurationOPC: "Configuration OPC",
    MonitorationBlockGoals: "Monitoration Block Goals",
    MonitorationBlock: "Monitoration Block",
    QuickGuides: "Quick Guides",
    SectorProcessArea: "Sector Process Area",
    Profile: "Profile",
    Indicators: "Indicator",
    DailyReport: "Daily Report",
    Equipment: "Equipment",
    DashboardConfiguration: "Dashboard Configuration",
    ApproveCriticalPoint: "Approve Criticial Point",
    Email: "Email",
  },
  pt: {
    Home: "Início",
    About: "Sobre",
    Reports: "Relatórios",
    Date: "Data",
    Report: "Relatório",
    InsertedLog: "Inseriu registro",
    UpdatedLog: "Atualizou registro",
    DeletedLog: "Excluiu registro",
    InsertedSuccess: "Inserido com sucesso",
    UpdatedSuccess: "Atualizado com sucesso",
    DateValid: "Data válida",
    DeletedSuccess: "Removido com sucesso",
    DateInvalid: "A data final deve ser posterior a data inicial",
    InsertError: "Erro ao inserir",
    InsertErrorDuplicated: "Erro ao inserir. Existem campos que precisam ser únicos.",
    InsertErrorLoginExisting: "Login já existente em alguma unidade da empresa.",
    UpdateError: "Erro ao atualizar",
    UpdateErrorDuplicated: "Erro ao editar. Existem campos que precisam ser únicos.",
    DeleteError: "Erro ao remover",
    DeleteForeignError: "Não é possível excluir, pois o item já foi utilizado em outro programa",
    SelectOption: "É obrigatório selecionar uma opção!",
    PasswordIncorrect: "Usuário e/ou senha informada estão incorretos.",
    ActualPasswordIncorrect: "A senha atual digitada está incorreta",
    NoRegisteredUnit: "Usuário sem unidade cadastrada.",
    DateExpiration: "A validade do seu sistema expirou. Favor entrar em contato com a Nexxus.",
    InsertedSample: "Inseriu amostra da análise ",
    UpdatedSample: "Atualizou amostra da análise ",
    DeletedSample: "Removeu amostra da análise ",
    InsertedVariable: "Inseriu variável ",
    UpdatedVariable: "Atualizou variável ",
    DeletedVariable: "Removeu variável ",
    InsertedTask: "Inseriu tarefa:",
    UpdatedTask: "Atualizou tarefa:",
    DeletedTask: "Removeu tarefa:",
    ExecutedTask: "Executou tarefa: ",
    MonitoredTask: "Monitorou tarefa: ",
    VerifiedTask: "Verificou tarefa: ",
    ShiftError: "Verifique o horário de início e fim do turno",
    ApproveCriticalPoint: "Aprovar Pontos Críticos",
    Approved: "Aprovado",
    Disapproved: "Reprovado",
    Required: "O campo é de preenchimento obrigatório",
    ReceiptRegister: "Cadastro de Recebimentos",
    ReceiptTransport: "Cadastro de Transportes",
    Users: "Usuários",
    Permission: "Permissões",
    Tip: "Dicas",
    Shift: "Turnos",
    DataAcess: "Acesso de dados",
    Process: "Processos da Empresa",
    AreaProcess: "Áreas da Empresa",
    CompanyAndUnit: "Empresa e Unidade",
    Product: "Produto",
    Stock: "Estoque",
    Measure: "Unidade de Medida",
    Sector: "Setores da Empresa",
    Aplication: "Aplicação",
    Programs: "Programas",
    Locality: "Localidade",
    Configuration: "Trocar Unidade / Configuração",
    PermissionLaboratory: "Permissão Análises",
    RegisterReasonType: "Cadastro Tipo/Motivos",
    StopsByArea: "Paradas por Área",
    StopsByProcess: "Paradas por Processo",
    AutomaticVariables: "Variáveis Automáticas",
    ManualVariables: "Variáveis Manuais",
    RegisterAnalysis: "Cadastro de Análises",
    Group: "Grupo",
    InputDirect: "Entrada Direta",
    CreateTask: "Criar Tarefa",
    ExecuteTask: "Executar Tarefa",
    VariableInputByVariable: "Entrada Variáveis por Variáveis",
    VariableInputByDate: "Entrada Variáveis por Data",
    Sample: "Amostras",
    RegisterVariable: "Cadastro Variáveis",
    ConfigurationOPC: "Configuração OPC",
    MonitorationBlockGoals: "Bloco de Monitoração Meta",
    MonitorationBlock: "Bloco de Monitoração",
    QuickGuides: "Guias Rápidos",
    SectorProcessArea: "Setor, Processo e Área",
    Profile: "Perfil",
    Indicators: "Indicadores",
    DailyReport: "Relatório Diário",
    Equipment: "Equipamento",
    DashboardConfiguration: "Dashboard Configuração",
    ApproveCriticalPoint: "Aprovar Pontos Críticos",
    Email: "Email"
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDictionary() {
    return dictionary;
  },
  getDictionaryWithCustomLocalizations(customLocalization) {
    if (customLocalization) {
      var keys = Object.keys(dictionary);
      let merged = {};
      keys.forEach((element) => {
        merged[element] = Object.assign(
          dictionary[element],
          customLocalization[element]
        );
      });
    }
    return dictionary;
  },
};
  