/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { Button } from "devextreme-react";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "../style.scss";
import { SelectBox } from "devextreme-react";
import { formatMessage } from "devextreme/localization";
import notify from "devextreme/ui/notify";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { CheckBox } from "devextreme-react";
import { LogMessageCheckboxList } from "../../../utils/logMessage";
import { useLoading } from "../../../contexts/loadingContext";
import { useScreenSize } from "../../../utils/media-query";

export default function ProgramsPermission(props) {
  const { loading } = useLoading();
  const { isXSmall, isXLSmall, isSmall } = useScreenSize();
  const [load, setLoad] = useState(false);
  const [permission, setPermission] = useState([]);
  const [groupProgram, setGroupProgram] = useState([]);
  const [program, setProgram] = useState([]);
  const [programPermission, setProgramPermission] = useState([]);
  const [permissionChange, setPermissionChange] = useState(0);
  const [groupProgramChange, setGroupProgramChange] = useState(0);
  const [enabledButton, setEnabledButton] = useState(true);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const [textButton, setTextButton] = useState();
  const _list = useRef([]);
  let permissionName = useRef();

  const { unitCod } = useAuth();

  useEffect(() => {
    async function firstLoad(){
      loadDataPermission();
      await loadDataGroupProgram();
      loadDataProgram();
    };
    firstLoad();

  }, [props.update]);

  useEffect(() => {
    if (load) 
      loadDataProgramPermission();
      
  }, [load]);

  useEffect(() => {
    if(permissionChange > 0 || groupProgramChange > 0) {
      loadDataProgram();
    }
    if(permissionChange > 0) loadDataProgramPermission();

    setEnabledButton( !(permissionChange > 0 || groupProgramChange > 0) );
  }, [permissionChange, groupProgramChange])

  useEffect(() => {
    if(program.length > programPermission.length) {
      const newLstProgramPermissions = program.map(e => e.CodPrograma);
      setProgramPermission(newLstProgramPermissions);
    }

    if(programPermission.length === program.length) {
      setProgramPermission([]);
    }
  }, [selectedAll]);

  useEffect(() => {
    if(program.length === programPermission.length) setTextButton("Desmarcar todos");
    if(program.length > programPermission.length) setTextButton("Marcar todos");
  }, [programPermission, forceUpdate])

  async function loadDataPermission() {
    await api
      .get("/permission", { params: { unitCod } })
      .then((response) => {
        setPermission(response.data);
        setLoad(false);

        return false;
      })
      .catch((error) => {
        notify(formatMessage("UpdateError"), "error", 3000);
        setLoad(true);

        return true;
      });
  };

  async function loadDataGroupProgram() {
    await api
      .get("/groupProgram")
      .then((response) => {
        setGroupProgram(response.data.filter(x => x.TipLiberarParaCliente));
        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  };

  async function loadDataProgramPermission() {
    await api
      .get("/programPermission", {params: {codPermission: permissionChange, codGroup: groupProgramChange}})
      .then((response) => {
        setProgramPermission(response.data.map(e => e.CodPrograma));
        _list.current = response.data;

        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  };

  async function loadDataProgram() {
    await api
      .get("/groupingProgram", { params: { codGroup: groupProgramChange } })
      .then((response) => {
        setProgram(response.data.filter(x => x.TipLiberarParaCliente).sort((a, b) => 
          (a.NomPrograma > b.NomPrograma) ? 1 : ((b.NomPrograma > a.NomPrograma) ? -1 : 0)))
        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  };

  async function insertRow(data, logMessage) {
    return await api
      .post("/programPermission", { unitCod, data, logMessage })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(false);

        return true;
      });
  };

  async function deleteRow(data) {
    return await api
      .delete("/programPermission", { data })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  };

  async function onSelectionChangedPermission (e) {
    setPermissionChange(e.selectedItem ? e.selectedItem.CodPermissao : 0);
    permissionName.current = (e.selectedItem ? e.selectedItem.NomPermissao : 0)
  };

  async function onSelectionChange(e) {
    if(e.event.currentTarget.value !== "") return;

    setPermissionChange(0);
    permissionName.current = 0;
    setProgramPermission([]);
  };

  function onValueChangedCheckBoxPermissions(e, codProgram) {
    if(e.event) {
      let newLstProgramPermission = programPermission;

      if(e.value) {
        newLstProgramPermission.push(codProgram);
        setProgramPermission(newLstProgramPermission);
      } 
      else setProgramPermission(programPermission.filter(e => e !== codProgram));

      setForceUpdate(!forceUpdate);
    }
  };

  async function eventSaveClick() {
    const deleteProgramPermission = _list.current.filter((element) => {
      const found = programPermission.find(e => e === element.CodPrograma);

      if(found === undefined){
        element.NomLogFirst = permissionName.current
        element.NomLogSecond = program.filter(x => x.CodPrograma === element.CodPrograma)[0].NomPrograma;
        return element;
      }
    });

    const insertProgramPermission = [];

    programPermission.forEach((codPrograma) => {
      const found = _list.current.find(e => e.CodPrograma === codPrograma);

      if(found !== undefined) return null;

      program.forEach((prog) => {
        if(prog.CodPrograma === codPrograma) {
          const newProgramPermission = {
            CodPrograma: codPrograma,
            CodPermissao: permissionChange,
            TipInserirDados: prog.TipInserirDados,
            TipAlterarDados: prog.TipAlterarDados,
            TipExcluirDados: prog.TipExcluirDados,
            NomLogFirst: permissionName.current,
            NomLogSecond: prog.NomPrograma
          };

          insertProgramPermission.push(newProgramPermission);
        }
      })
      
    })

    deleteProgramPermission.forEach(data => {
      let logMessage = LogMessageCheckboxList(formatMessage("DeletedLog"), data)
      const val = { unitCod, data, logMessage };
      deleteRow(val);
    });

    insertProgramPermission.forEach((item) => {
      let logMessage = LogMessageCheckboxList(formatMessage("InsertedLog"), item)
      insertRow(item, logMessage);
    });
  };

  return (
    <React.Fragment>
      <div className="header-form">
        <div className="form-container">
          <div className={"margin-element"}>
              <SelectBox
                dataSource={permission}
                label={"Permissão"}
                searchEnabled={true}
                onSelectionChanged={onSelectionChangedPermission}
                onChange={onSelectionChange}
                displayExpr="NomPermissao"
                valueExpr="CodPermissao"
                width={250}
                stylingMode={"outlined"}
                labelMode={"floating"}
              />
          </div>
          <div className={isXLSmall ? "invisible-item" : isXSmall ? "invisible-item" : isSmall ? "invisble-item" : "margin-element"}>
              <SelectBox
                dataSource={groupProgram}
                label={"Grupo"}
                searchEnabled={true}
                onSelectionChanged={e => setGroupProgramChange(e.selectedItem ? e.selectedItem.CodProgramaGrupo : 0)}
                displayExpr="NomProgramaGrupo"
                valueExpr="CodProgramaGrupo"
                showClearButton={true}
                width={250}
                stylingMode={"outlined"}
                labelMode={"floating"}
              />
          </div>
          <div className={"margin-element"}>
            <Button
              text={textButton}
              icon={!selectedAll ? "selectall" : "unselectall"}
              type="normal"
              className={"selectall-button"}
              useSubmitBehavior={false}
              stylingMode="contained"
              onClick={() => setSelectedAll(state => !state)}
              disabled={enabledButton}
            />
          </div>
          <div className={"margin-element"}>
            <Button
              className={"save-button"}
              id="searchFilterButton"
              icon="save"
              text="Salvar"
              type="default"
              useSubmitBehavior={false}
              stylingMode="contained"
              onClick={eventSaveClick}
              disabled={enabledButton}
            />
          </div>
        </div>
      </div>
      <div style={{paddingTop: '30px'}}>
        {groupProgram.map(group => {
          const programGroup = program.filter(e => e.CodProgramaGrupo === group.CodProgramaGrupo)

          return programGroup.length > 0 ?
            <div key={group.CodProgramaGrupo}>
              <h3 className="title-permission">{group.NomProgramaGrupo}</h3>
              
              {programGroup.map(progr =>
                <div style={{display: "inline-block", margin: "3% 1% 0% 2%", width: "200px"}} key={progr.CodPrograma}>
                  <CheckBox
                    className={"checkbox-medium"}
                    value={programPermission.some(e => e === progr.CodPrograma)}
                    text={progr.NomPrograma}
                    onValueChanged={e => onValueChangedCheckBoxPermissions(e, progr.CodPrograma)}
                  />
                </div>
              )}
            </div>
          : '';
        })}
      </div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
