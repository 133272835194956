/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "devextreme/data/odata/store";
import "../../components/common/style.scss";
import { FavButton } from "../../components/fav-button/favButton";
import { api } from "../../services/api";
import { useLoading } from "../../contexts/loadingContext";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { useAuth } from "../../contexts/auth";
import "./style.scss";
import { Button } from "devextreme-react";
import HtmlEditor from "devextreme-react/html-editor";
import {SearchBar} from './searchBar';
import { useScreenSize } from "../../utils/media-query";

export default function QuickGuidesList() {
  const { loading } = useLoading();
  const { isLarge } = useScreenSize()
  const [load, setLoad] = useState(false);
  const _subtopic = useRef([]);
  const _initial_subtopic = useRef([]);
  const [topic, setTopic] = useState([]);
  const [openedGuide, setOpenedGuide] = useState(false);
  const [subtopicId, setSubtopicId] = useState(null);
  const [topicId, setTopicId] = useState(null);
  const [topicList, setTopicList] = useState(false);
  const [topicCod, setTopicCod] = useState();
  const [expandAll, setExpandAll] = useState(false);
  const searchString = useRef(null);
  const { unitCod, refreshToken, getRefreshTokenCode, getToken, tipAdministrator } = useAuth();

  useEffect(() => {
    refreshToken(getToken(), getRefreshTokenCode());
  }, []);

  useEffect(() => {
    loadTopics();
    loadSubtopics();
  }, []);

  async function loadTopics() {
    await api
      .get("quickguidestopic", { params: { unitCod: unitCod } })
      .then((response) => {
        setTopic(response.data.sort((a,b) => a.NumOrdem > b.NumOrdem ? 1 : -1));
      })
      .catch(() => {
        return true;
      });
  }

  async function loadSubtopics() {
    await api
      .get("quickguidessubtopic", { params: { unitCod: unitCod } })
      .then((response) => {
        _subtopic.current = response.data.sort((a,b) => a.NumOrdem > b.NumOrdem ? 1 : -1)
        _initial_subtopic.current = response.data.sort((a,b) => a.NumOrdem > b.NumOrdem ? 1 : -1)
        setLoad(!load)
      })
      .catch(() => {
        return true;
      });
  }

  const handleClick = (subtopic, topic) => {
    setOpenedGuide(true);
    setSubtopicId(subtopic.CodGuiaRapidoSubtopico);
    setTopicId(topic.CodGuiaRapidoTopico);
  };

  const InitalComponent = () => (
    <div>
      <div style={{ margin: "3vh" }}>
        { tipAdministrator ?
              topic.map((topic) => {
                return (
                    <div>
                      <h4 style={{textAlign: 'left'}} onClick={() => {
                        setTopicCod(topic.CodGuiaRapidoTopico !== topicCod ? topic.CodGuiaRapidoTopico : null)
                        setTopicList(topic.CodGuiaRapidoTopico !== topicCod ? true : false)
                      }} className={"topic"}>
                        <Button
                          icon={topicList && topicCod === topic.CodGuiaRapidoTopico || expandAll ? "chevrondown" : "chevronright"}
                          onClick={(e) => {
                            setTopicCod(topic.CodGuiaRapidoTopico !== topicCod ? topic.CodGuiaRapidoTopico : null)
                            setTopicList(topic.CodGuiaRapidoTopico !== topicCod ? true : false)
                          }}
                        />
                        <strong>{topic.NomTopico}</strong>
                      </h4>
                      {
                        // eslint-disable-next-line array-callback-return
                        topicList && topicCod === topic.CodGuiaRapidoTopico || expandAll
                          ? _subtopic.current.map((subtopic) => {
                            if (subtopic.CodGuiaRapidoTopico === topic.CodGuiaRapidoTopico) {
                              return (
                                  <div className={"subtopic"}>
                                    <h4
                                        onClick={() => handleClick(subtopic, topic)}
                                    >
                                      {subtopic.DesTitulo}
                                    </h4>
                                  </div>
                              );
                            }
                          }) 
                          : ""
                      }
                    </div>
                )
              })
              :
              topic.map((topic) => {
                if(topic.TipAdministrador === tipAdministrator){
                  return (
                      <div key={topic.CodGuiaRapidoTopico}>
                        <h4 style={{textAlign: 'left'}} className={"topic"}>
                          <Button
                            icon={topicList && topicCod === topic.CodGuiaRapidoTopico || expandAll ? "chevrondown" : "chevronright"}
                            onClick={(e) => {
                              if(topic.CodGuiaRapidoTopico !== topicCod) {
                                setTopicCod(topic.CodGuiaRapidoTopico);
                                setTopicList(true);
                              } else {
                                setTopicCod(null);
                                setTopicList(false);
                              }
                            }}
                          />
                          <strong>{topic.NomTopico}</strong>
                        </h4>
                        {
                          // eslint-disable-next-line array-callback-return
                          topicList && topicCod === topic.CodGuiaRapidoTopico || expandAll 
                            ? _subtopic.current.map((subtopic) => {
                              if (subtopic.CodGuiaRapidoTopico === topic.CodGuiaRapidoTopico) {
                                return (
                                    <div className={"subtopic"}>
                                      <h3
                                        onClick={() => handleClick(subtopic, topic)}
                                      >
                                        {subtopic.DesTitulo}
                                      </h3>
                                    </div>
                                );
                              }
                            })
                            : ""
                        }
                      </div>
                  );
                }
              })
        }
      </div>
    </div>
  );

  const OpenedGuideComponent = () => {
    const [subtopics, setSubtopics] = useState(
        _subtopic.current.filter((e) => e.CodGuiaRapidoTopico === topicId)
    );

    let [selectedSubtopicIndex, setSelectedSubtopicIndex] = useState(
        subtopics.findIndex((e) => e.CodGuiaRapidoSubtopico === subtopicId)
    );

    const handleNextTopic = () => {
      setSelectedSubtopicIndex(selectedSubtopicIndex++);
      setSubtopicId(
          subtopics[selectedSubtopicIndex].CodGuiaRapidoSubtopico
      );
    };

    const handlePreviousTopic = () => {
      setSelectedSubtopicIndex(selectedSubtopicIndex--);
      setSubtopicId(
          subtopics[selectedSubtopicIndex].CodGuiaRapidoSubtopico
      );
    };

    if(selectedSubtopicIndex > -1 && openedGuide) {
      
      return (
          <div>
            <div className={"html-editor-container"}>
              <h2 style={{ margin: "auto", width: "80vw"}}>
                <strong>{subtopics[selectedSubtopicIndex].DesTitulo}</strong>
              </h2>
              <HtmlEditor
                  className={"html-editor"}
                  stylingMode={"underlined"}
                  onContentReady={(e) =>
                      e.component.option(
                        "value",
                        subtopics[selectedSubtopicIndex].DesTexto
                      )
                  }
                  readOnly={true}
              />
              <div className={"change-topic-button"}>
                <Button
                    className={"margin"}
                    type="default"
                    onClick={handlePreviousTopic}
                    text={subtopics[selectedSubtopicIndex - 1] !== undefined ? subtopics[selectedSubtopicIndex - 1].DesTitulo : ""}
                    disabled={selectedSubtopicIndex === 0 }
                    icon={"arrowleft"}
                />
                <Button
                    className={"margin"}
                    style={{ margin: "auto" }}
                    text={subtopics[selectedSubtopicIndex + 1] !== undefined ? subtopics[selectedSubtopicIndex + 1].DesTitulo : ""}
                    type="default"
                    onClick={handleNextTopic}
                    disabled={selectedSubtopicIndex === subtopics.length - 1 }
                    icon={"arrowright"}
                />
              </div>
            </div>
          </div>
      );
    }
  };

  const handleReturn = () => {
    setOpenedGuide(false);
    setSubtopicId(null);
  };

  return (
    <React.Fragment>
      <FavButton />
       {!openedGuide && (
          <div style={{marginTop: "40px"}}>
            <div className={"search-bar-container "}>
              <div className={'search-title'}>
                <strong>Como posso ajudar?</strong>
              </div>
            </div>
            <SearchBar 
              _subtopic={_subtopic} 
              _initial_subtopic={_initial_subtopic} 
              openedGuide={openedGuide} 
              setOpenedGuide={setOpenedGuide} 
              setSubtopicId={setSubtopicId} 
              searchString={searchString} 
              load={load} 
              setLoad={setLoad} 
              loadSubtopics={loadSubtopics}
              expandAll={expandAll}
              setExpandAll={setExpandAll}
            />
            <InitalComponent />
          </div>
        )}
        {openedGuide && (
          <div style={{marginTop: "1vw"}}>
            <Button
              height={"50px"}
              width={"50px"}
              className={isLarge ? "search-return" : "search-return-medium" }
              icon={"arrowleft"}
              type="default"
              onClick={handleReturn}
              style={{borderRadius: "100%", marginLeft: "1vw"}}
              hint={"Voltar"}
            />
            <OpenedGuideComponent />
          </div>
        )}
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
