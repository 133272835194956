import dayjs from "dayjs";

export function ObjSampleSave(
	parDate,
	parAnalysisTimeList,
	parSampleCodAnalysisGroup,
	parValAmostra,
	parTipAproved,
	parTipWorking,
	parNumLot,
	parCodUser,
	parIsLot,
	parIsAprovedFields,
	parRefLabelHour,
	parRefUserSelectBox,
	parRefObservation,
	parRefButtonReAnalysis,
	parListToSaveDescSample,
	parIndex,
	parIsObsGroup,
	parPreviousValue,
	parPreviousDate,
	parDateAnalysis,
	parPeriodUnit
) {
	let refButtonReAnalysis = parIsLot
		? parRefButtonReAnalysis.current["0:0"].instance.option("value") !== undefined
			? parRefButtonReAnalysis.current["0:0"].instance.option("value")
			: false
		: false;
	let boolReanalysisValue = false;

	if (parRefButtonReAnalysis.current[parIndex] !== undefined) {
		if (parRefButtonReAnalysis.current[parIndex].instance.option("value") !== undefined)
			boolReanalysisValue = parRefButtonReAnalysis.current[parIndex].instance.option("value");
		else boolReanalysisValue = false;
	} else boolReanalysisValue = false;
	
	const newObject = {
		CodAnalise: parIsAprovedFields ? (parIsLot ? 0 : parAnalysisTimeList[8].CodAnalise) : parAnalysisTimeList[8].CodAnalise,
		CodAnaliseGrupo: parSampleCodAnalysisGroup,
		DatAnaliseAmostra: dayjs(parPeriodUnit === 7 || parPeriodUnit === 4 ? parDateAnalysis : parDate)
			.format("YYYY-MM-DD ")
			.concat(" ", parPeriodUnit === 7 || parPeriodUnit === 4 ? 
                    `${parDateAnalysis.getHours().toString().padStart(2, "0")}:${parDateAnalysis.getMinutes().toString().padStart(2, "0")}` 
                    : parIsLot ? "00:00" : parRefLabelHour.current[parIndex]),
		//DatAnaliseAmostra: dayjs(parAnalysisTimeList[5].find(item => dayjs(item).format('LT') === dayjs(parSampleHour).format('LT'))).format('YYYY-MM-DD HH:mm'),
		TipVerificacao: parAnalysisTimeList[1],
		ValMinimo: parAnalysisTimeList[3],
		ValMaximo: parAnalysisTimeList[4],
		ValPositivo: parAnalysisTimeList[8].ValPositivo,
		ValAmostra: parValAmostra === undefined ? "" : parValAmostra,
		TipAprovado: !parTipWorking ? parTipAproved : 1,
		TipFuncionando: !parTipWorking,
		NumLote: parNumLot,
		CodUsuario: parIsLot
			? parListToSaveDescSample.current["0:1"]
			: parRefUserSelectBox.current[parIndex] !== undefined
			? parRefUserSelectBox.current[parIndex].instance.option("value")
			: parCodUser,
		ValIdeal: parAnalysisTimeList[8].ValIdeal,
		DesObservacao: parIsLot
			? null
			: parRefObservation.current[parIndex] !== undefined
			? parRefObservation.current[parIndex].instance.option("value")
			: null,
		DescGroup: "",
		IsReanalise: parIsLot ? refButtonReAnalysis : boolReanalysisValue,
		index: parIndex,
		IsObsGroup: parIsObsGroup,
		PreviousValue: parPreviousValue === undefined ? "" : parPreviousValue,
		PreviousDate: parPreviousDate,
		TipUnidadePeriodo: parPeriodUnit
	};

	if(!parIsObsGroup && parIsLot) 
		newObject.DescGroup = parListToSaveDescSample.current["0:0"];

	return newObject;
}
