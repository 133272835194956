/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react"
import "../../../components/common/style.scss"
import "./style.scss"
import "devextreme/data/odata/store"
import { LoadIndicator } from "devextreme-react/load-indicator"
import DataGrid, {
  Column,
  Pager,
  RequiredRule,
  Paging,
  FilterRow,
  Export,
  ColumnChooser,
  Grouping,
  GroupPanel,
  ColumnFixing,
  Selection,
  Lookup,
  CustomRule,
  Editing,
  Form,
  Popup,
  Position,
  Item,
  Toolbar
} from "devextreme-react/data-grid"
import { api } from "../../../services/api"
import { useAuth } from "../../../contexts/auth"
import { SelectBox } from "devextreme-react"
import DataSource from "devextreme/data/data_source"
import { LogMessageGrid } from "../../../utils/logMessage"
import { formatMessage } from "devextreme/localization"
import notify from "devextreme/ui/notify"
import { useLoading } from "../../../contexts/loadingContext"
import { useScreenSize } from "../../../utils/media-query"
import Button from "devextreme-react/button"

export default function Unit(props) {
  const { loading } = useLoading()
  const [load, setLoad] = useState(false)
  const { isLarge } = useScreenSize()
  const [company, setCompany] = useState([])
  const [dataSourceCompanyUnit, setDataSourceCompanyUnit] = useState([])
  const [cities, setCities] = useState([])
  const [rules] = useState({ X: /[02-9]/ })
  const _unit = useRef()
  let editNumCnpj = useRef("")
  let editNomUnidadeEmpresa = useRef("")
  //Autenticação requests
  const { unitCod } = useAuth()

  useEffect(() => {
    firstLoad()
  }, [props.update])

  useEffect(() => {
    if (load) {
      updateLoad()
    }
  }, [load])

  async function updateLoad() {
    if (unitCod !== undefined) {
      loadData()
    }
  }

  async function firstLoad() {
    loadDataCompany()
    await loadDataCities()
    loadData()
  }

  async function loadData() {
    await api
      .get("/companyUnit", { params: { unitCod: 0 } })
      .then(response => {
        setDataSourceCompanyUnit(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  async function loadDataCities() {
    await api
      .get("/city")
      .then(response => {
        setCities(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  async function updateRow(data, parLogMessage) {
    return await api
      .put("/companyUnit", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then(response => {
        setLoad(!load)

        return false
      })
      .catch(() => {
        return true
      })
  }

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/companyUnit", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then(response => {
        setLoad(!load)

        return false
      })
      .catch(() => {
        return true
      })
  }

  async function deleteRow(data) {
    return await api
      .delete("/companyUnit", { data: data })
      .then(() => {
        setLoad(!load)

        return false
      })
      .catch(() => {
        return true
      })
  }

  async function loadDataCompany() {
    await api
      .get("/company/?unitCod=0")
      .then(response => {
        setCompany(response.data)
        setLoad(!load)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const selectBoxCitiesData = new DataSource({
    store: cities,
    paginate: true,
    pageSize: 10
  })

  function selectBoxCitiesEditorRender(cell) {
    return (
      <SelectBox
        dataSource={selectBoxCitiesData}
        displayExpr={"NomCidade"}
        valueExpr={"CodCidade"}
        defaultValue={cell.value}
        deferRendering={false}
        onValueChanged={e => onValueChanged(e, cell)}
        searchEnabled={true}
      />
    )
  }

  function onValueChanged(e, cell) {
    cell.setValue(e.value)
  }

  function onEditorPreparing(e) {
    if (e.parentType === "dataRow" && e.dataField === "NumTelefone") {
      e.editorName = "dxTextBox"
      e.editorOptions.mask = "(00) 00000-0000"
      e.editorOptions.maskRules = rules
      e.editorOptions.maskInvalidMessage = "O telefone deve possuir o seguinte formato (00) 00000-0000"
    }

    if (e.parentType === "dataRow" && e.dataField === "NumCnpj") {
      e.editorName = "dxTextBox"
      e.editorOptions.mask = "00.000.000/0000-00"
      e.editorOptions.maskRules = rules
      e.editorOptions.maskInvalidMessage = "O CNPJ deve possuir o seguinte formato 00.000.000/0000-00"
    }

    if (e.parentType === "dataRow" && e.dataField === "NumCep") {
      e.editorName = "dxTextBox"
      e.editorOptions.mask = "00000-000"
      e.editorOptions.maskRules = rules
      e.editorOptions.maskInvalidMessage = "O CEP deve possuir o seguinte formato 00000-000"
    }
  }

  function checkPhoneNumber(params) {
    let check = true

    if (params.data.NumTelefone && params.data.NumTelefone !== "") {
      params.data.NumTelefone.length === 11 ? (check = true) : (check = false)
    }

    return check
  }

  // function checkCnpj(params) {
  // 	let check = true;
  //
  // 	if (params.data.NumCnpj && params.data.NumCnpj !== "") {
  // 		params.data.NumCnpj.length === 14 ? (check = true) : (check = false);
  // 	}
  //
  // 	return check;
  // }

  function checkCep(params) {
    let check = true

    if (params.data.NumCep && params.data.NumCep !== "") {
      params.data.NumCep.length === 8 ? (check = true) : (check = false)
    }

    return check
  }

  function formatCep(options) {
    if (options.valueText.length > 0) {
      const codPartOne = options.valueText.substr(0, 5)
      const codPartTwo = options.valueText.substr(4, 3)

      return `${codPartOne}-${codPartTwo}`
    }

    return ""
  }

  function formatCnpj(options) {
    if (options.valueText.length > 0) {
      const codPartOne = options.valueText.substr(0, 2)
      const codPartTwo = options.valueText.substr(2, 3)
      const codPartThree = options.valueText.substr(5, 3)
      const codPartFour = options.valueText.substr(8, 4)
      const codPartFive = options.valueText.substr(12, 2)

      return `${codPartOne}.${codPartTwo}.${codPartThree}/${codPartFour}-${codPartFive}`
    }

    return ""
  }

  function formatPhoneNumber(options) {
    if (options.valueText.length > 0) {
      const codDDD = options.valueText.substr(0, 2)
      const codPartOne = options.valueText.substr(2, 5)
      const codPartTwo = options.valueText.substr(7, 4)

      return `(${codDDD}) ${codPartOne}-${codPartTwo}`
    }

    return ""
  }

  function checkComposedUniqueKey(params) {
    //Nome + CNPJ são uniqueKey no banco
    let check = true
    let numCNPJ

    dataSourceCompanyUnit.forEach(unit => {
      numCNPJ = unit.NumCnpj
      if (!unit.NumCnpj) numCNPJ = ""

      if (unit.CodUnidadeEmpresa !== params.data.CodUnidadeEmpresa && unit.NomUnidadeEmpresa === editNomUnidadeEmpresa.current && numCNPJ === editNumCnpj.current) check = false
    })

    return check
  }

  function checkUniqueKey(params) {
    let check = true

    dataSourceCompanyUnit.forEach(unit => {
      if (unit.CodUnidadeEmpresa !== params.data.CodUnidadeEmpresa && params.column.dataField === "NumCnpj") {
        if (unit.NumCnpj === params.value) check = false
      }

      if (unit.CodUnidadeEmpresa !== params.data.CodUnidadeEmpresa && params.column.dataField === "NomUnidadeEmpresa") {
        if (unit.NomUnidadeEmpresa === params.value) check = false
      }
    })

    return check
  }

  function setCellValueCNPJ(newData, value, currentRowData) {
    newData.NumCnpj = value
    editNumCnpj.current = value
  }

  function setCellValueNomUnidadeEmpresa(newData, value, currentRowData) {
    newData.NomUnidadeEmpresa = value
    editNomUnidadeEmpresa.current = value
  }

  return (
    <React.Fragment>
      <div id='companyAndUnit'>
        <DataGrid
          className={"dx-cards"}
          dataSource={dataSourceCompanyUnit}
          rowAlternationEnabled={true}
          showColumnLines={true}
          allowColumnReordering={true}
          showBorders={false}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          repaintChangesOnly={true}
          onInitNewRow={e => {
            company.length === 1 ? (e.data.CodEmpresa = company[0].CodEmpresa) : (e.data.CodEmpresa = null)
            editNomUnidadeEmpresa.current = ""
            editNumCnpj.current = ""
            e.data.TipLocal = false
          }}
          headerFilter={{ visible: true }}
          filterPanel={{ visible: true }}
          filterRow={{ visible: false }}
          /*editing={{
                  refreshMode: "full",
                  mode:"batch",
                  allowUpdating: true,
                  allowDeleting: true,
                  allowAdding: false,           
                }}*/
          onRowUpdating={async e => {
            const newObj = { ...e.oldData, ...e.newData }
            let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _unit, newObj)

            e.cancel = updateRow(newObj, strLogMessage)

            e.component.deselectAll()
          }}
          onRowInserting={async e => {
            let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _unit, e.data)

            e.cancel = insertRow(e.data, strLogMessage)

            e.component.deselectAll()
          }}
          onRowRemoving={e => {
            if (e.data.CodUnidadeEmpresa === 1) {
              notify("Não é possível excluir a unidade com código 01", "error", 2500)
              e.cancel = true
            } else {
              let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _unit, e.data)
              const data = { unitCod: unitCod, data: e.data, logMessage: strLogMessage }

              e.cancel = deleteRow(data)

              e.component.deselectAll()
            }
          }}
          onEditorPreparing={onEditorPreparing}
          ref={_unit}
        >
          <Editing mode={isLarge ? "batch" : "popup"} allowUpdating={true} allowDeleting={true} allowAdding={false}>
            <Popup title='Cadastro de Unidade' showTitle={true} width={700} height={750} fullScreen={true}>
              <Position my='center' at='center' of={window} />
            </Popup>
            <Form>
              <Item itemType='group' colCount={4} colSpan={2}>
                <Item colSpan={2} dataField='CodEmpresa' />
                <Item colSpan={2} dataField='NomUnidadeEmpresa' />
                <Item colSpan={2} dataField='DesEndereco' />
                <Item colSpan={2} dataField='NumEndereco' />
                <Item colSpan={2} dataField='DesLocalidade' />
                <Item colSpan={2} dataField='CodCidade' />
                <Item colSpan={1} dataField='NumCep' />
                <Item colSpan={1} dataField='NumTelefone' />
                <Item colSpan={1} dataField='NumCnpj' />
              </Item>
            </Form>
          </Editing>
          <Grouping contextMenuEnabled={false} expandMode='rowClick' autoExpandAll={false} />
          <GroupPanel visible={false} />
          <Export enabled={true} fileName='Unidades Industriais' allowExportSelectedData={true} />
          <ColumnChooser enabled={true} mode='select' />
          <ColumnFixing enabled={false} />
          <Selection mode='multiple' />
          <Paging defaultPageSize={20} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <FilterRow visible={true} />
          <Toolbar>
            <Item cssClass={"add-container"}>
              <Button className={"add-button"} icon={"add"} onClick={() => _unit.current.instance.addRow()} />
            </Item>
            <Item name='saveButton' />
            <Item name='revertButton' />
            <Item name='exportButton' />
            <Item name='columnChooserButton' />
          </Toolbar>
          <Column dataField={"CodEmpresa"} caption={"Empresa"} allowSorting={true} allowFiltering={true} allowSearch={true} allowGrouping={true} allowEditing={true}>
            <RequiredRule />
            <Lookup dataSource={company} displayExpr={"NomEmpresa"} valueExpr={"CodEmpresa"} searchEnabled={true} />
          </Column>
          <Column dataField={"TipLocal"} caption={"Local"} dataType={"boolean"} allowSorting={true} width={"auto"}></Column>
          <Column dataField={"NomUnidadeEmpresa"} caption={"Nome"} allowSorting={true} sortIndex={0} width={"auto"} setCellValue={setCellValueNomUnidadeEmpresa}>
            <CustomRule
              message={"Os campos em vermelho devem ser diferentes dos registros já cadastrados"}
              reevaluate={true}
              type={"custom"}
              validationCallback={checkComposedUniqueKey}
            />
            <CustomRule message={"Já existe registros cadastrados com o mesmo valor"} reevaluate={true} type={"custom"} validationCallback={checkUniqueKey} />
            <RequiredRule />
          </Column>
          <Column dataField={"DesEndereco"} caption={"Endereço"} allowSorting={true} sortIndex={0} width={"auto"}></Column>
          <Column dataField={"NumEndereco"} dataType={"number"} caption={"Número"} allowSorting={true} sortIndex={0} width={"auto"}></Column>
          <Column dataField={"DesLocalidade"} caption={"Complemento"} allowSorting={true} sortIndex={0} width={"auto"}></Column>
          <Column
            dataField={"CodCidade"}
            caption={"Cidade"}
            allowSorting={true}
            allowFiltering={true}
            allowSearch={true}
            allowGrouping={true}
            allowEditing={true}
            width={180}
            editCellRender={selectBoxCitiesEditorRender}
          >
            <Lookup dataSource={cities} displayExpr={"NomCidade"} valueExpr={"CodCidade"} searchEnabled={true} />
          </Column>
          <Column dataField={"NumCep"} caption={"CEP"} allowSorting={true} sortIndex={0} width={90} format={"0.##"} customizeText={formatCep}>
            <CustomRule message={"O número do Cep está incompleto"} validationCallback={checkCep} />
          </Column>
          <Column
            dataField={"NumTelefone"}
            caption={"Telefone"}
            allowSorting={true}
            sortIndex={0}
            width={120}
            allowEditing={true}
            format={"0.##"}
            customizeText={formatPhoneNumber}
          >
            <CustomRule message={"O número de telefone está incompleto"} validationCallback={checkPhoneNumber} />
          </Column>
          <Column dataField={"NumIe"} caption={"Inscrição Estadual"} allowSorting={true} sortIndex={0} width={"auto"}></Column>
          <Column
            dataField={"NumCnpj"}
            caption={"CNPJ"}
            allowSorting={true}
            sortIndex={0}
            width={"auto"}
            format={"0.##"}
            customizeText={formatCnpj}
            setCellValue={setCellValueCNPJ}
          >
            <CustomRule
              message={"Os campos em vermelho devem ser diferentes dos registros já cadastrados"}
              reevaluate={true}
              type={"custom"}
              validationCallback={checkComposedUniqueKey}
            />
            <CustomRule message={"Já existe registros cadastrados com o mesmo valor"} reevaluate={true} type={"custom"} validationCallback={checkUniqueKey} />
            <RequiredRule />
          </Column>
        </DataGrid>
      </div>
      <div></div>
      <div className='load-indicator'>
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
      <div></div>
    </React.Fragment>
  )
}
