import React, { useState } from "react";
import "devextreme/data/odata/store";
import "../../components/common/style.scss";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/form";
import { FavButton } from "../../components/fav-button/favButton";
import Topic from "./topic";
import Subtopic from "./subtopic";
// import Configuration from "./configuration";

export default function QuickGuidesTab() {
  const [update, setUpdate] = useState(false);
  const renderTopicTab = () => {
    return <Topic update={update} setUpdate={setUpdate} />;
  };

  const renderSubtopicTab = () => {
    return <Subtopic update={update} />;
  };

  return (
    <React.Fragment>
      <FavButton />
      <TabPanel
        className={"tab-panel-margin"}
        animationEnabled={true}
        swipeEnabled={false}
      >
        <Item title="Tópico" render={renderTopicTab} />
        <Item title="Subtópico" render={renderSubtopicTab} />
      </TabPanel>
    </React.Fragment>
  );
}
