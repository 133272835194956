import {Button} from "devextreme-react";
import {Button as TextBoxButton, TextBox} from "devextreme-react/text-box";
import {theme} from "../../utils/theme";
import React from "react";
import "./style.scss"
import { useScreenSize } from "../../utils/media-query";

export const SearchBar = (props) => {
    const { isLarge } = useScreenSize()
    const optionsValueClear = {
        stylingMode: "text",
        type: "default",
        visible: true,
        icon: "search",
        onClick: () => {
            handleClearFilters();
        },
    };

    const handleReturn = () => {
        props.setOpenedGuide(false);
        props.setSubtopicId(null);
    };

    const handleClearFilters = async () => {
        await props.loadSubtopics();
        if(props._subtopic.current.length > 0) await props.setLoad(!props.load)

    };

    const handleSearch = async () => {
        if (props.searchString.current.length > 0)
        {
            props._subtopic.current = props._initial_subtopic.current.filter(
                (e) => e.DesTitulo.toLowerCase().indexOf(props.searchString.current.toLowerCase()) > -1
            );
        }else {
            await handleClearFilters();
        }

        if (props.openedGuide) props.setOpenedGuide(false)
        await props.setLoad(!props.load)
    };


    return (
       <div>
           <div className={"search-bar-container"}>
                <div className={isLarge ? "search-bar" : "search-bar-medium"}>
                    <TextBox
                        label={'Pesquisar...'}
                        showClearButton={true}
                        onValueChanged={(e) => {
                            e.component.option("value") === "" ? props.searchString.current = "" : props.searchString.current = e.component.option("value");
                            
                            handleSearch(e);

                            !e.value || e.value === ""
                                ? props.setExpandAll(false)
                                : props.setExpandAll(true);
                        }}
                        stylingMode={"outlined"}
                        valueChangeEvent={"keyup"}
                    >
                    <TextBoxButton
                        name="clear"
                        location="after"
                    />
                    </TextBox>
                </div>
            </div>
       </div>
    );
}


