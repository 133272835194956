/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/form";
import { FavButton } from "../../../components/fav-button/favButton";
import { useAuth } from "../../../contexts/auth";
import Sector from "./sector";
import AreaProcess from "./areaProcess";
import Process from "./process";

export default function SectorProcessAreaTab() {
    const [update, setUpdate] = useState(false);
    const {refreshToken, getRefreshTokenCode, getToken} = useAuth();

    useEffect(() => {
        refreshToken(getToken(), getRefreshTokenCode());
      }, [])

    const renderSectorTab = () => {
        return <Sector update={update} setUpdate={setUpdate} />
    };

    const renderProcessTab = () => {
        return <Process update={update} setUpdate={setUpdate}/>
    };

    const renderAreaTab = () => {
        return <AreaProcess update={update} setUpdate={setUpdate}/>
    };
    
    return (
        <React.Fragment>
            <FavButton />
            <TabPanel
                className={"tab-panel-margin"}
                animationEnabled={true}
                swipeEnabled={false}
            >
                <Item title="Setor" render={renderSectorTab} />
                <Item title="Processo" render={renderProcessTab} />
                <Item title="Área" render={renderAreaTab} />
            </TabPanel>
        </React.Fragment>
    );
}
