/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "../../components/common/style.scss";
import "devextreme/data/odata/store";
import { LogMessageGrid } from "../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Editing,
  FilterRow,
  Form,
  Grouping,
  GroupPanel,
  Item,
  Pager,
  Paging,
  RequiredRule, Toolbar,
} from "devextreme-react/data-grid";
import { api } from "../../services/api";
import { useLoading } from "../../contexts/loadingContext";
import { useAuth } from "../../contexts/auth";
import { LoadIndicator } from "devextreme-react/load-indicator";
import Button from "devextreme-react/button";

export default function Topic(props) {
  const { loading } = useLoading();
  const [topic, setTopic] = useState([]);
  const _topic = useRef();

  const { unitCod, refreshToken, getRefreshTokenCode, getToken, tipAdministrator } = useAuth();

  useEffect(() => {
    refreshToken(getToken(), getRefreshTokenCode());
  }, []);

  useEffect(() => {
    loadData();
  }, [props.update]);

  async function loadData() {
    await api
      .get("quickguidestopic", { params: { unitCod: unitCod } })
      .then((response) => {
        if(tipAdministrator) {
          setTopic(response.data);
        } else {
          let temp = []
          response.data.forEach((topic) => {
            if(topic.TipAdministrador === tipAdministrator){
              temp.push(topic)
            }
          })
          setTopic(temp)
        }
      })
      .catch(() => {

        return true;
      });
  }

  async function updateRow(data, parLogMessage) {
    return await api
      .put("quickguidestopic", {
        unitCod: unitCod,
        data: data,
        logMessage: parLogMessage,
      })
      .then((response) => {
        props.setUpdate(!props.update);
        return false;
      })
      .catch(() => {
        return true;
      });
  }

  async function insertRow(data, parLogMessage) {
    return await api
      .post("quickguidestopic", {
        unitCod: unitCod,
        data: data,
        logMessage: parLogMessage,
      })
      .then((response) => {
        props.setUpdate(!props.update);
        return false;
      })
      .catch(() => {
        return true;
      });
  }

  async function deleteRow(data) {
    return await api
      .delete("quickguidestopic", { data: data })
      .then(() => {
        props.setUpdate(!props.update);
        return false;
      })
      .catch(() => {
        return true;
      });
  }

  return (
    <React.Fragment>
      <DataGrid
        className={"dx-cards"}
        dataSource={topic}
        rowAlternationEnabled={true}
        showColumnLines={true}
        allowColumnReordering={true}
        showBorders={false}
        columnHidingEnabled={true}
        repaintChangesOnly={true}
        onInitNewRow={(e) => {
          e.data.NomTopico = "";
        }}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: false }}
        onRowUpdating={async (e) => {
          const newObj = { ...e.oldData, ...e.newData };
          let strLogMessage = LogMessageGrid(
            formatMessage("UpdatedLog"),
            _topic,
            newObj
          );

          e.cancel = updateRow(newObj, strLogMessage);

          e.component.deselectAll();
        }}
        onRowInserting={async (e) => {
          let strLogMessage = LogMessageGrid(
            formatMessage("InsertedLog"),
            _topic,
            e.data
          );

          e.cancel = insertRow(e.data, strLogMessage);

          e.component.deselectAll();
        }}
        onRowRemoving={(e) => {
          let strLogMessage = LogMessageGrid(
            formatMessage("DeletedLog"),
            _topic,
            e.data
          );
          const data = {
            unitCod: unitCod,
            data: e.data,
            logMessage: strLogMessage,
          };

          e.cancel = deleteRow(data);

          e.component.deselectAll();
        }}
        ref={_topic}
      >
        <Editing
          mode={"batch"}
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={false}
        >
          <Form>
            <Item itemType="group" colCount={4} colSpan={2}>
              <Item colSpan={2} dataField="NomTopico" />
              <Item colSpan={2} dataField="NumTopico" />
            </Item>
          </Form>
        </Editing>
        <Grouping
          contextMenuEnabled={false}
          expandMode="rowClick"
          autoExpandAll={false}
        />
        <GroupPanel visible={false} />
        <ColumnChooser enabled={true} mode="select" />
        <ColumnFixing enabled={false} />
        <Paging defaultPageSize={20} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <Toolbar >
          <Item cssClass={'add-container'}>
            <Button className={'add-button'}  icon={"add"} onClick={() => (_topic.current.instance.addRow())}/>
          </Item>
          <Item name="saveButton"/>
          <Item name="revertButton"/>
          <Item name="exportButton"/>
          <Item name="columnChooserButton"/>
        </Toolbar>
        <Column
          dataField={"NomTopico"}
          caption={"Tópico"}
          allowSorting={true}
          allowFiltering={true}
          allowSearch={true}
          allowGrouping={true}
          allowEditing={true}
        >
          <RequiredRule />
        </Column>
        <Column
            dataField={"NumOrdem"}
            caption={"Ordem"}
            allowSorting={true}
            allowFiltering={true}
            allowSearch={true}
            allowGrouping={true}
            allowEditing={true}
        >
          <RequiredRule />
        </Column>
      </DataGrid>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
