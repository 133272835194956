/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import Form, { Item, ButtonItem, ButtonOptions, GroupItem } from "devextreme-react/form";
import {  useHistory } from 'react-router-dom';
import notify from "devextreme/ui/notify";
import { useAuth } from "../../contexts/auth";
import { useLocalization } from "../../contexts/localizationContext";
import LoadIndicator from "devextreme-react/load-indicator";
import { SelectBox } from "devextreme-react";
import { loadDataProgramPermission, loadDataAddressReports } from "../../utils/functionRequests";

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const { user, getUser, setUser, setUnitCod, setUnit, unitCod, userCod } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState();
  const [unitChanged, setUnitChanged] = useState()
  const { changeLanguage } = useLocalization();
  const [units, setUnits] = useState(JSON.parse(localStorage.getItem("@es-mes/units")))
  const formData = useRef({});
  const _userSelectBox = useRef();
  const _enabledGetPermissions = useRef(false);
  // const emailEditorOptions = {
  //     stylingMode: 'filled',
  //      dataSource: test,
  //     placeholder: "Unidade",
  //     displayExpr: "NomUnidadeEmpresa",
  //     valueExpr:"CodUnidadeEmpresa",
  //     defaultValue: test[0].CodUnidadeEmpresa,

  // };

  useEffect(() => {
    if(units === null) {
      async function userGet() {
        let result = await getUser();
        setUser(result.data.user);
      };

      userGet();
    }
  }, []);
  
 const onClick = (e) => {
   _enabledGetPermissions.current = true;
  let intUnitChanged = 0
  let intSelectBoxUserValue = _userSelectBox.current.instance.option("value");

  intUnitChanged = unitChanged !== undefined ? unitChanged : intSelectBoxUserValue

  if(intUnitChanged === undefined){
    notify("Não é possível salvar sem unidade", "error", 3000);
  }
  else{
    const newUnits = [];
    let newDefaultUnit;

    user.units.forEach((unit) => {
      if(unit.CodUnidadeEmpresa === intUnitChanged) {
        newDefaultUnit = {
          CodUnidadeEmpresa: unit.CodUnidadeEmpresa,
          NomUnidadeEmpresa: unit.NomUnidadeEmpresa,
          TipPadrao: true,
        };
  
        newUnits.unshift(newDefaultUnit);
      } else {
        const newUnit = {
          CodUnidadeEmpresa: unit.CodUnidadeEmpresa,
          NomUnidadeEmpresa: unit.NomUnidadeEmpresa,
          TipPadrao: false
        }

        newUnits.push(newUnit);
      }
    });
  
    setTimeout(() => {
      localStorage.setItem("@es-mes/units", JSON.stringify(newUnits));
      setUnitCod(newDefaultUnit.CodUnidadeEmpresa);
      setUnit(newDefaultUnit.NomUnidadeEmpresa);
      if( language !== localStorage.getItem("@es-mes/userLanguage") && 
          (unitCod === unitChanged) || unitChanged === undefined) changeLanguage(language);
    }, 2000);
  }
 }

  useEffect(() => {
    getPermissions();
  }, [unitCod])

  async function getPermissions() {
    if(unitChanged) await loadDataAddressReports(unitCod);

    if(localStorage.getItem("@es-mes/tipAdministrator") !== "true" && _enabledGetPermissions.current) {
      await loadDataProgramPermission(unitCod, userCod);
      language !== localStorage.getItem("@es-mes/userLanguage") ? changeLanguage(language) : document.location.reload();
    }

    if(localStorage.getItem("@es-mes/tipAdministrator") === "true" && _enabledGetPermissions.current) changeLanguage(language);
  }

  return (
    <React.Fragment>
      {/* <h2 className="content-block">Configuração</h2> */}
      <div style={{ width: "95%", marginTop: "3%", marginLeft: "2.7%" }}>
        <form
        //onSubmit={onSubmit}
        >
          <Form
            colCount={2}
            className="form"
            formData={formData.current}
            disabled={loading}
          >
            <Item colSpan={1}>
              <div className="dx-field-label">Unidade</div>
              <SelectBox
                dataSource={user?.units}
                displayExpr="NomUnidadeEmpresa"
                valueExpr="CodUnidadeEmpresa"
                ref={_userSelectBox}
                defaultValue={units.length > 0 ? units[0].CodUnidadeEmpresa : user?.units[0].CodUnidadeEmpresa}/* {user?.units[0].CodUnidadeEmpresa} */
                onSelectionChanged={(e) => {
                  setUnitChanged(e.selectedItem.CodUnidadeEmpresa)
                }}
              ></SelectBox>
            </Item>
            <Item colSpan={1}>
              <div className="dx-field-label">Linguagem</div>
              <SelectBox
                dataSource={[
                  {
                    Nome: "Português",
                    Sigla: "pt",
                  },
                  {
                    Nome: "Inglês",
                    Sigla: "en",
                  },
                ]}
                displayExpr="Nome"
                valueExpr="Sigla"
                defaultValue={localStorage.getItem("@es-mes/userLanguage")}
                onSelectionChanged={(e) => {
                  setLanguage(e.selectedItem.Sigla);
                }}
              ></SelectBox>
            </Item>
            <GroupItem colSpan={2} colCount={5}>
            <Item type="empty" colSpan={3}></Item>
            <ButtonItem horizontalAlignment="left" colSpan={1}>
              <ButtonOptions
                width={"100%"}
                height={"4vh"}
                type={"default"}
                //useSubmitBehavior={true}
                onClick={(e) => {
                  onClick(e);
                  //updateRow();
                }}
              >
                <span className="dx-button-text">
                  {loading ? (
                    <LoadIndicator
                      width={"24px"}
                      height={"24px"}
                      visible={true}
                    />
                  ) : (
                    "Salvar"
                  )}
                </span>
              </ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="right" colSpan={1}>
              <ButtonOptions
                width={"100%"}
                height={"4vh"}
                type={"default"}
                onClick={(e) => {
                  history.push('/home');
                }}
              >
                <span className="dx-button-text">
                  {loading ? (
                    <LoadIndicator
                      width={"24px"}
                      height={"24px"}
                      visible={true}
                    />
                  ) : (
                    "Fechar"
                  )}
                </span>
              </ButtonOptions>
            </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </React.Fragment>
  );
}