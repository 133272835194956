/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useRef} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import appInfo from "./app-info";
import routes from "./app-routes";
import { SideNavOuterToolbar as SideNavBarLayout } from "./layouts";
import { Footer } from "./components";
import { useAuth } from "./contexts/auth";
import {api} from "./services/api";

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const { permissions, tipAdministrator, updateLogo } = useAuth();
    const [img, setImg] = useState("");

    useEffect(() => {
        getFile("logo-cliente.png");
    }, [updateLogo])

    async function getFile(data) {
        await api
            .get("/file", {params: {filename: data}})
            .then((response) => {
              setImg(response.data);
            })
            .catch((error) => {
            });
    }
  return (
    <SideNavBarLayout title={appInfo.title}>
      <Switch>
        {/* {routes.map(({ path, component, id }) => (
          !tipAdministrator && permissions.length >= 0 ? permissions.map((numb) => {
            if(id === numb || id === undefined) return <Route exact key={path} path={path} component={component} />
          }) : <Route exact key={path} path={path} component={component} />
        ))} */}
        {routes.map((route) => {
          let router;
          router = route.id.map((id) => {
            if(!tipAdministrator && permissions) {
              if(permissions.length > 0) {
                let renderPage;

                permissions.map((numb) => {
                  if(id === numb || id === undefined) {
                    renderPage = <Route exact key={route.path} path={route.path} component={route.component} />
                  }
                })
                
                return renderPage;
              } else  {
                return <Route exact key={route.path} path={route.path} component={route.component} />
              }
            } else {
              return <Route exact key={route.path} path={route.path} component={route.component} />
            }
          })

          return router;
        })}
        <Redirect to={"/home"} />
      </Switch>
      <Footer>
        <img
          style={{objectFit:"contain", width:"150px", height:"50px"}}
          alt=""
          id="logo"
          src={img}
        />
      </Footer>
    </SideNavBarLayout>
  );
}

