/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react"
import "devextreme/data/odata/store"
import "../../../components/common/style.scss"
import { Button } from "devextreme-react"
import "../style.scss"
import { SelectBox } from "devextreme-react"
import { CheckBox } from "devextreme-react"
import { api } from "../../../services/api"
import { useAuth } from "../../../contexts/auth"
import { LogMessageCheckboxList } from "../../../utils/logMessage"
import { formatMessage } from "devextreme/localization"
import { loadDataProgramPermission } from "../../../utils/functionRequests"

export default function UserPermission(props) {
  const { unitCod,  userCod } = useAuth()
  const [load, setLoad] = useState(true)
  const [userChange, setUserChange] = useState(null)
  const [dataSourcePermission, setDataSourcePermission] = useState([])
  const [dataSourceUser, setDataSourceUser] = useState([])
  const [enabledButton, setEnabledButton] = useState(true)
  const [permissionCodes, setPermissionCodes] = useState([])
  const [forceUpdate, setForceUpdate] = useState(false)
  const _list = useRef([])
  let _userName = useRef([])

  useEffect(() => {
    loadDataUser();
  }, [props.update])

  useEffect(() => {
    if (load){
      loadDataPermission();
      loadDataUser();
      if(userChange !== null)loadUserPermission();
    }
  }, [load]);

  useEffect(() => {
    if(userChange) loadUserPermission();

    // userChange !== null && userChange !== undefined ? setEnabledButton(false) : setEnabledButton(true);
  }, [userChange]);

  async function loadDataUser() {
    await api
      .get("/user", { params: { unitCod: unitCod } })
      .then((response) => {
        setDataSourceUser(response.data.filter((x) => x.TipRegistroAtivo === true && x.CodUsuario !== 0));
        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function loadDataPermission() {
    await api
      .get("/permission", {params: { unitCod: unitCod} })
      .then((response) => {
        setDataSourcePermission(response.data);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function loadUserPermission() {
    let permissionCodes = [];

    await api
      .get("/userpermission/get", { params: { UserId: userChange } })
      .then(response => {
        _list.current = [];
        response.data.forEach((permission, index) => {
          permissionCodes[index] = permission.CodPermissao;
          _list.current[index] = permission.CodPermissao;
        });

        setPermissionCodes(permissionCodes);

        if(userChange === userCod) loadDataProgramPermission(unitCod, userCod);

        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function loadInitialUserPermission() {
    let permissionCodes = [];

    await api
        .get("/userpermission/get", { params: { UserId: userChange } })
        .then(response => {
          response.data.forEach((permission, index) => {
            permissionCodes[index] = permission.CodPermissao;
          });
          setPermissionCodes(permissionCodes);
          if(userChange === userCod) loadDataProgramPermission(unitCod, userCod);
          setLoad(false);
        })
        .catch((error) => {
          setLoad(true);

          return true;
        });
  }

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/userPermission", { unitCod: unitCod, data: { CodPermissao: data, CodUsuario: userChange }, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function deleteRow(data) {
    return await api
      .delete("/userPermission", { data: data })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  function onSelectionChangedUser (e) {
    e.selectedItem !== null ? setUserChange(e.selectedItem.CodUsuario) : setUserChange(null);
    _userName.current = (e.selectedItem ? e.selectedItem.NomUsuario : 0)
  }

  async function eventSaveClick (e) {
    let actualSelectionPermission = permissionCodes;
    let listToDelete = [];
    let listToSave = [];

    _list.current.filter(function (element) {
      if(actualSelectionPermission.indexOf(element) === -1) {
        let strNomPermission;
        strNomPermission = dataSourcePermission.filter(x => x.CodPermissao === element)[0].NomPermissao;

        const data = {
          NomLogFirst: strNomPermission,
          NomLogSecond: dataSourceUser.filter(x => x.CodUsuario === userChange)[0].NomUsuario,
          CodPermissao: element
        };

        listToDelete.push(data);
      }
    })

     actualSelectionPermission.filter(function (element) {
      if(_list.current.indexOf(element) === -1) {
        let strNomPermission;
        strNomPermission = dataSourcePermission.filter(x => x.CodPermissao === element)[0].NomPermissao;

        const data = {
          NomLogFirst: strNomPermission,
          NomLogSecond: dataSourceUser.filter(x => x.CodUsuario === userChange)[0].NomUsuario,
          CodPermissao: element
        };

        listToSave.push(data);
      }
    });

    listToSave.forEach((item) => {
      let logMessage = LogMessageCheckboxList(formatMessage("InsertedLog"), item)
      insertRow(item.CodPermissao, logMessage);
    });

    listToDelete.forEach((item) => {
      let logMessage = LogMessageCheckboxList(formatMessage("DeletedLog"), item)
      deleteRow({unitCod: unitCod, data: {CodPermissao: item.CodPermissao, CodUsuario: userChange} , logMessage: logMessage});
    });
    setEnabledButton(true)
  };

  async function eventUndoClick() {
    loadInitialUserPermission();
    setEnabledButton(true)
  }

  function onValueChangedCheckBoxPermissions(e, codPermission) {

    //Entra apenas quando for clicado o checkbox
    if(!!e.event){
     
      let newLstPermissionCodes = permissionCodes;

      if(e.value) {
        newLstPermissionCodes.push(codPermission);
        setPermissionCodes(newLstPermissionCodes);
        setForceUpdate(!forceUpdate);
      } else {
        newLstPermissionCodes = permissionCodes.filter(code => code !== codPermission);
        setPermissionCodes(newLstPermissionCodes);
        setForceUpdate(!forceUpdate);
      };
      setEnabledButton(false)
    }

  };

  return (
    <React.Fragment>
      <div className="header-form">
        <div className={"form-container"}>
        <div className={"margin-element"}>
            <SelectBox
              dataSource={dataSourceUser}
              label={"Usuário"}
              width={250}
              searchEnabled={true}
              onSelectionChanged={onSelectionChangedUser}
              displayExpr="NomUsuario"
              valueExpr="CodUsuario"
              stylingMode={"outlined"}
              labelMode={"floating"}
            />
          </div>
          <div className={"margin-element"}>
            <Button
                text={"Desfazer"}
                icon={"undo"}
                type="normal"
                className={"selectall-button"}
                useSubmitBehavior={false}
                stylingMode="contained"
                onClick={eventUndoClick}
                disabled={enabledButton}
            />
          </div>
          <div className={"margin-element"}>
            <Button
              className={"save-button"}
              id="searchFilterButton"
              icon="save"
              text="Salvar"
              type="default"
              useSubmitBehavior={false}
              stylingMode="contained"
              onClick={async (e) => {
                eventSaveClick()
              }}
              disabled={enabledButton}
            />
          </div>
        </div>
      </div>
      <div>
        <div>
        {dataSourcePermission.map((permission) => {
          let valueCheckBox = false;

          permissionCodes.forEach((perm) => {
            if(perm === permission.CodPermissao) valueCheckBox = true;
          });

          return(
                    <div style={{display: "inline-block", margin: "3% 1% 0% 2%", width: "200px"}} key={permission.CodPermissao}>
                      <CheckBox
                        className={"checkbox-medium"}
                        value={valueCheckBox}
                        text={permission.NomPermissao}
                        onValueChanged={(e) => onValueChangedCheckBoxPermissions(e, permission.CodPermissao)}
                      />
                    </div>
                )
        })}
        </div>
      </div>
    </React.Fragment>
  );
}
