/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "./style.scss";
import "devextreme/data/odata/store";
import "../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { formatMessage } from "devextreme/localization";
import DataGrid, {
  Column,
  Pager,
  RequiredRule,
  Paging,
  FilterRow,
  Export,
  Selection,
  ColumnChooser,
  Grouping,
  GroupPanel,
  ColumnFixing,
} from "devextreme-react/data-grid";
import Form, { 
  SimpleItem, 
  GroupItem,
  ButtonItem,
} 
from 'devextreme-react/form';
import notify from "devextreme/ui/notify";
import { api } from "../../services/api";
import { useAuth } from "../../contexts/auth";
import dayjs from "dayjs";
import { useLoading } from "../../contexts/loadingContext";

export default function ProductStock() {
  const { loading } = useLoading();
  const [load, setLoad] = useState(false);
  const [dataSourceStockCorrection, setDataSourceStockCorrection] = useState();
  const [dataSourceProducts, setDataSourceProducts] = useState([]);
  const { refreshToken, getRefreshTokenCode, getToken } = useAuth();
  //const [radioGroupValue, setRadioGroupValue] = useState(0);
  const [productOriginValue, setProductOriginValue] = useState(0);
  const [productDestinationValue, setProductDestinationValue] = useState(0);
  const [dataSourceSubmit, setDataSourceSubmit] = useState();
  const _form = useRef();
  const radioGroupValue = useRef(0);

  //Autenticação requests
  const { unitCod } = useAuth();

  const dataSourceType = [
    { Id: 1, Value: "Quebra" },
    { Id: 2, Value: "Entrada" },
    { Id: 3, Value: "Transferência" }
  ];

  useEffect(() => {
    if(unitCod !== undefined) {
      refreshToken(getToken(), getRefreshTokenCode());
      loadData();
      loadProducts();
    }
  }, []);

  useEffect(() => {
    if(load) loadData();
  }, [load]);

  async function loadData() {
    await api
      .get("/productstockcorrection", { params: { unitCod: unitCod } })
      .then((response) => {
        setDataSourceStockCorrection(response.data);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function loadProducts() {
    await api
      .get("/product", { params: { unitCod: unitCod } })
      .then((response) => {
        setDataSourceProducts(response.data);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }
  async function insertRow(data) {
    return await api
      .post("/productstockcorrection", { unitCod: unitCod, data: data })
      .then((response) => {
        setLoad(true);
        _form.current.instance.resetValues();
        _form.current.instance.getEditor("DesObservacao").option("value", null)
        return true;
      })
      .catch((error) => {
        setLoad(false);
        
        return true;
    });
  }
  
  const productsOptions = 
  { 
    dataSource: dataSourceProducts.filter((x) => x.TipControleEstoque === 1 && x.TipRegistroAtivo === 1  && x.CodProduto !== productDestinationValue),
    name: "productOrigin",
    displayExpr: "NomProduto", 
    valueExpr: "CodProduto",
    showClearButton: true,
    onValueChanged: (e) => {
      setProductOriginValue(e.value);
    }
  }

  const productsDestinationOptions = 
  { 
    dataSource: dataSourceProducts.filter((x) => x.TipControleEstoque === 1 && x.TipRegistroAtivo === 1 && x.CodProduto !== productOriginValue),
    name: "productDestination",
    displayExpr: "NomProduto", 
    valueExpr: "CodProduto",
    showClearButton: true,
    disabled: radioGroupValue.current != 3,
    onOptionChanged: (e) => {
      //zera o produto destino quando desabilitado
      if (e.name === "disabled"){
        if (e.value === true){
          _form.current.instance.getEditor("CodProdutoTransferencia").option("value", null)
        }
      }

    },
    onValueChanged: (e) => {
      setProductDestinationValue(e.value);
    }
  }

  const radioGroupOptions ={
    dataSource: dataSourceType,
    displayExpr: "Value",
    valueExpr: "Id",
    layout: "horizontal",
    onValueChanged: (e) => {
      radioGroupValue.current = e.value;

      (radioGroupValue.current === 3)
      ? _form.current.instance.getEditor("CodProdutoTransferencia").option("disabled", false)
      : _form.current.instance.getEditor("CodProdutoTransferencia").option("disabled", true)
    }
  }

  const dateBoxOptions = {
    max: dayjs()
  }

  const textAreaOptions = {
    stylingMode: "outlined",
    height: 75
  }

  const buttonOptions = {
    type: "default",
    text: "Salvar",
    useSubmitBehavior: true
  }

  const onFieldDataChanged = (e) => {
    setDataSourceSubmit(e.component.option("formData"));
  }

  async function handleSubmit(e){
    e.preventDefault();
    (radioGroupValue.current !== 0 && radioGroupValue.current != null)
      ? insertRow(dataSourceSubmit)
      : notify(formatMessage("SelectOption"), 'error', 2500);
  }

  const calculateDisplayValue = (newData) => {
    if(dataSourceProducts.length > 0) return dataSourceProducts.filter((x) => x.CodProduto === newData.CodProduto)[0].NomProduto
  }

  const calculateDisplayValueTransferencia = (newData) => {
    if(newData.CodProdutoTransferencia != null && dataSourceProducts.length > 0) {
      return dataSourceProducts.filter((x) => x.CodProduto === newData.CodProdutoTransferencia)[0].NomProduto
    }
  }

  return (
    <React.Fragment>
      {/* <h2 className="content-block">Estoque</h2> */}
      <div>
        <div className="form-container" style={{margin:"1% 1% 1% 1.5%"}}>
            <form onSubmit={handleSubmit}>
              <Form
                //className={"dx-cards"}
                id="form"
                colCount={1}
                onFieldDataChanged={onFieldDataChanged}
                alignItemLabelsInAllGroups={false}
                ref={_form}
              >
              <GroupItem colCount={7}>
              <SimpleItem 
                  dataField="TipCorrecao" 
                  editorType="dxRadioGroup" 
                  editorOptions={radioGroupOptions}
                  label={{text: "Tipo Correção"}}
                  colSpan={7}
                />
                <SimpleItem 
                  dataField="DatCorrecao" 
                  editorType="dxDateBox" 
                  editorOptions={dateBoxOptions}
                  label={{text: "Data Correção"}}
                  isRequired={true}
                />
                <SimpleItem 
                  dataField="CodProduto" 
                  label={{text: "Produto"}} 
                  editorType={"dxSelectBox"}
                  editorOptions={productsOptions}
                  colSpan={2} 
                  isRequired={true}
                />
                <SimpleItem 
                  dataField="CodProdutoTransferencia" 
                  label={{text: "Produto Destino"}} 
                  editorType={"dxSelectBox"}
                  editorOptions={productsDestinationOptions}
                  colSpan={2} 
                  isRequired={true}
                />
                <SimpleItem 
                  dataField="ValEstoqueCorrecao" 
                  label={{text: "Valor Estoque"}}  
                  colSpan={2} 
                  editorType={"dxNumberBox"}
                  isRequired={true}
                />
                <SimpleItem 
                  colSpan={7}
                  dataField="DesObservacao" 
                  editorType={"dxTextArea"}
                  label={{text: "Observação"}} 
                  editorOptions={textAreaOptions}
                />
                </GroupItem>
              <ButtonItem 
                horizontalAlignment="left"
                buttonOptions={buttonOptions}
              />
              </Form>
            </form>
            <div>
              <DataGrid
                className={"dx-cards"}
                dataSource={dataSourceStockCorrection}
                rowAlternationEnabled={true}
                allowColumnReordering={true}
                showBorders={false}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                headerFilter={{ visible: true }}
                filterPanel={{ visible: true }}
                filterRow={{ visible: false }}
              >
                <Grouping
                  contextMenuEnabled={false}
                  expandMode="rowClick"
                  autoExpandAll={false}
                />
                <GroupPanel visible={false} /> 
                <Export
                  enabled={true}
                  fileName="correcao_estoque"
                  allowExportSelectedData={true}
                />
                <ColumnChooser enabled={true} mode="select" />
                <ColumnFixing enabled={false} />
                <Selection mode="multiple" />
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />
                <Column
                  dataField={"DatCorrecao"}
                  caption={"Data Correção"}
                  allowSorting={true}
                  dataType={"date"}
                  width={"auto"}
                  sortOrder={"desc"}
                  customizeText={(e) => { 
                    if (e.valueText != ""){
                      return dayjs(e.valueText).format('L') 
                    }
                  }}
                >
                </Column>  
                <Column
                  dataField={"CodProduto"}
                  calculateDisplayValue={calculateDisplayValue}
                  caption={"Produto"}
                  allowSorting={true}
                  width={"auto"}
                >
                  <RequiredRule />
                </Column>  
                <Column
                  dataField={"CodProdutoTransferencia"}
                  calculateDisplayValue={calculateDisplayValueTransferencia}
                  caption={"Produto Destino"}
                  allowSorting={true}
                  width={"auto"}
                />
                <Column
                  dataField={"ValEstoqueCorrecao"}
                  caption={"Valor Correção Estoque"}
                  allowSorting={true}
                  width={"auto"}
                />
                <Column
                  dataField={"DesObservacao"}
                  cssClass={"word-wrap"}
                  caption={"Observação"}
                  allowSorting={true}
                  width={"auto"}
                />
              </DataGrid>
            </div>
          </div>
        </div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}