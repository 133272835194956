import React, { useEffect, useState, useRef } from "react";
import "../../../components/common/style.scss";
import "./style.scss";
import "devextreme/data/odata/store";
import { LoadIndicator } from "devextreme-react/load-indicator";
import DataGrid, {
	Column,
	Pager,
	Paging,
	FilterRow,
	Selection,
	Grouping
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { useLoading } from "../../../contexts/loadingContext";
import OpcTest from "../../../components/opcTest/opcTest";

export default function TestOpc(props) {
	const { loading } = useLoading();
	const { unitCod } = useAuth();
	const [load, setLoad] = useState(false);
	const [lstServerOpc, setLstServerOpc] = useState([]);
	const [codOPCConnection, setCodOPCConnection] = useState();
	const [connectionString, setConnectionString] = useState();
	const [rowSelectionChanged, setRowSelectionChanged] = useState(false);
	const _lstServerOpc = useRef([]);

	useEffect(() => {
		firstLoad();
	}, [props.rowSelectionChanged]);

	useEffect(() => {
		if (load) 
			loadData();
	}, [load]);

	async function firstLoad() {
		if (unitCod !== undefined)
			loadData();
	};

	async function loadData() {
		await api
			.get("/testOPC", { params: { unitCod: unitCod } })
			.then((response) => {
				setLstServerOpc(response.data);

				setLoad(false);
			})
			.catch((error) => {
				setLoad(true);
			});
	};

	return (
		<React.Fragment>
			<OpcTest 
				codOPCConnection={codOPCConnection}
				connectionString={connectionString}
				rowSelectionChanged={rowSelectionChanged}
			/>
			<div id="opc-connection">
				<DataGrid
					className={"dx-cards"}
					dataSource={lstServerOpc}
					rowAlternationEnabled={false}
					showColumnLines={true}
					allowColumnReordering={true}
					showBorders={false}
					columnAutoWidth={false}
					columnHidingEnabled={true}
					repaintChangesOnly={true}
					headerFilter={{ visible: true }}
					filterPanel={{ visible: true }}
					filterRow={{ visible: false }}
					ref={_lstServerOpc}
					onSelectionChanged={(e) => {
						setConnectionString(e.currentSelectedRowKeys[0].StringConexão);
						setCodOPCConnection(e.currentSelectedRowKeys[0].CodOPCServidor);
						setRowSelectionChanged(!rowSelectionChanged);
					}}
				>
					<Grouping
						contextMenuEnabled={true}
						expandMode="rowClick"
						autoExpandAll={true}
          			/>
					<Selection mode="single" />
					<Paging defaultPageSize={20} />
					<Pager showPageSizeSelector={true} showInfo={true} />
					<FilterRow visible={true} />
					<Column
						dataField={"NomOPCServidor"} 
						caption={"Servidor"} 
						allowSorting={true} 
						sortIndex={0}
						groupIndex={0}
					/>
					<Column
						dataField={"CodVariavelLeitura"} 
						caption={"Código"} 
						allowSorting={true} 
						sortIndex={0}
					/>
					<Column 
						dataField={"NomVariavelLeitura"} 
						caption={"Tag"} 
						minWidth={200} 
						allowSorting={true} 
						sortIndex={0}
					/>
					<Column
						dataField={"StringConexão"}
						caption={"String Conexão"}
						allowSorting={true}
						sortIndex={0}
					/>
					<Column
						dataField={"NomeGrupo"}
						caption={"Grupo"}
						allowSorting={true}
						sortIndex={0} 
					/>
					<Column 
						dataField={"Unidade"}
						caption={"Unidade"}
						allowSorting={true}
						sortIndex={0}
					/>
					<Column
						dataField={"CasasDecimais"}
						caption={"C. Dec."}
						allowSorting={true}
						sortIndex={0}
					/>
					<Column
						dataField={"ValorIdeal"}
						caption={"Valor Ideal"}
						allowSorting={true}
						sortIndex={0} 
					/>
					<Column 
						dataField={"Monitoracao"}
						caption={"Monitoração"}
						allowSorting={true}
						sortIndex={0} 
					/>
					<Column
						dataField={"Ativo"}
						caption={"Ativo"}
						allowSorting={true}
						sortIndex={0} 
					/>
				</DataGrid>
			</div>
			<div className="load-indicator">
				<LoadIndicator 
					visible={loading} 
					height={40} 
					width={40} 
				/>
			</div>
		</React.Fragment>
	);
};