/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "../style.scss";
import DataGrid, {
  Column,
  Pager,
  RequiredRule,
  Paging,
  FilterRow,
  Export,
  Selection,
  ColumnChooser,
  Grouping,
  Lookup,
  GroupPanel,
  ColumnFixing,
  CustomRule,
  Editing,
  Form,
  Popup,
  Position,
  Item, Toolbar,
} from "devextreme-react/data-grid";
import { formatMessage } from "devextreme/localization";
import notify from "devextreme/ui/notify";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { LogMessageGrid } from "../../../utils/logMessage";
import { useLoading } from "../../../contexts/loadingContext";
import { useScreenSize } from "../../../utils/media-query";
import Button from "devextreme-react/button";

export default function Product(props) {
  const { loading } = useLoading();
  const [load, setLoad] = useState(false);
  const { isLarge } = useScreenSize();
  const [dataSourceProduct, setDataSourceProduct] = useState([]);
  const [dataSourceProductGroup, setDataSourceProductGroup] = useState([]);
  const [dataSourceMeasureValue, setDataSourceMeasureValue] = useState([]);
  const [dataSourceType, setDataSourceType] = useState([]);
  const _product = useRef();

  //Autenticação requests
  const { unitCod } = useAuth();

  useEffect(() => {
    firstLoad();
  }, [props.update]);

  async function firstLoad() {
    if (unitCod !== undefined) {
      loadDataSourceProductGroup();
      loadData();
    }
    //}
  }

  useEffect(() => {
    if (load) {
      loadData();
    }
  }, [load]);

  //Carrega se o cadastro de grupo foi alterado
  useEffect(() => {
    loadDataSourceProductGroup();
    loadDataSourceType();
    loadDataSourceMeasureValue();
    loadData();
  }, []);

  async function loadDataSourceProductGroup() {
    await api
      .get("/productGroup", { params: { unitCod: unitCod } })
      .then((response) => {
        setDataSourceProductGroup(response.data);
        setLoad(false);
      })
      .catch((error) => {
        notify(formatMessage("UpdateError"), "error", 3000);
        setLoad(true);

        return true;
      });
  }

  async function loadDataSourceMeasureValue() {
    await api
      .get("/measureValue", { params: { unitCod: unitCod, tipVariableOPC: 1 } })
      .then((response) => {
        setDataSourceMeasureValue(response.data);
        setLoad(false);
      })
      .catch((error) => {
        notify(formatMessage("UpdateError"), "error", 3000);
        setLoad(true);

        return true;
      });
  }

  async function loadDataSourceType() {
    await api
      .get("/type/get", { params: { typeId: 20 } })
      .then((response) => {
        setDataSourceType(response.data);
        setLoad(false);
      })
      .catch((error) => {
        notify(formatMessage("UpdateError"), "error", 3000);
        setLoad(true);

        return true;
      });
  }

  async function loadData() {
    await api
      .get("/product", { params: { unitCod: unitCod } })
      .then((response) => {
        setDataSourceProduct(response.data);
        setLoad(false);
      })
      .catch((error) => {
        notify(formatMessage("UpdateError"), "error", 3000);
        setLoad(true);

        return true;
      });
  }

  async function updateRow(data, parLogMessage) {
    return await api
      .put("/product", {
        unitCod: unitCod,
        data: data,
        logMessage: parLogMessage,
      })
      .then((response) => {
        setLoad(true);
        props.setUpdate(!props.update);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/product", {
        unitCod: unitCod,
        data: data,
        logMessage: parLogMessage,
      })
      .then((response) => {
        setLoad(true);
        props.setUpdate(!props.update);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  async function deleteRow(data) {
    return await api
      .delete("/product", { data: data })
      .then((response) => {
        setLoad(true);
        props.setUpdate(!props.update);

        return false;
      })
      .catch((error) => {
        setLoad(true);

        return true;
      });
  }

  const onEditorPreparing = (e) => {
    /*if (e.dataField == "CodVariavelLeitura" && e.parentType == "dataRow")  {
            e.editorOptions.disabled = !e.row.data.TipFuncionamentoAutomatico;
            e.editorOptions.value = null;
          }*/

    if (e.dataField === "CodProdutoIntegracao" && e.parentType === "dataRow")
      e.editorOptions.maxLength = 50;

    if (e.dataField === "NomProduto" && e.parentType === "dataRow")
      e.editorOptions.maxLength = 50;
  };

  /*const onToolbarPreparing = (e) => {
        var dataGrid = e.component;

        e.toolbarOptions.items.unshift({
            location: "after",
            widget: "dxButton",
            options: {
                elementAttr: {id: 'ToolbarAddNewButton'},
                hint: "Adicionar um registro",
                icon: "add",
                onClick: () => { dataGrid.addRow() }
            },
        });
    }*/

  /*function setCellValue (newData, value){
      newData.TipPermiteParada = value;
      newData.TipFuncionamentoAutomatico = false;
      this.defaultSetCellValue(newData, value);
    }

    function setCellValueTipFuncionandoAutomatico (newData, value){
      newData.TipFuncionamentoAutomatico = value;
      this.defaultSetCellValue(newData, value);
    }*/

  function checkUniqueKey(params) {
    let check = true;

    dataSourceProduct.forEach((unit) => {
      if (
        unit.CodProduto !== params.data.CodProduto &&
        unit.NomProduto === params.data.NomProduto
      )
        check = false;
    });

    return check;
  }

  return (
    <React.Fragment>
      <div id="processGrid">
        <DataGrid
          className={"dx-cards"}
          dataSource={dataSourceProduct}
          rowAlternationEnabled={true}
          showColumnLines={true}
          allowColumnReordering={true}
          showBorders={false}
          //columnAutoWidth={true}
          columnHidingEnabled={true}
          repaintChangesOnly={false}
          onEditorPreparing={onEditorPreparing}
          onInitNewRow={(e) => {
            e.data.CodUnidadeEmpresa = unitCod;
            e.data.TipControleEstoque = false;
            e.data.ValMinEstoque = 0;
            e.data.TipRegistroAtivo = true;
          }}
          headerFilter={{ visible: true }}
          filterPanel={{ visible: true }}
          filterRow={{ visible: false }}
          /* editing={{
                      refreshMode: "full",
                      mode:"batch",
                      allowUpdating: true,
                      allowDeleting: true,
                      allowAdding: false,
                    }} */
          onRowUpdating={async (e) => {
            const newObj = { ...e.oldData, ...e.newData };
            let strLogMessage = LogMessageGrid(
              formatMessage("UpdatedLog"),
              _product,
              newObj
            );

            e.cancel = updateRow(newObj, strLogMessage);

            e.component.deselectAll();
          }}
          onRowInserting={(e) => {
            let strLogMessage = LogMessageGrid(
              formatMessage("InsertedLog"),
              _product,
              e.data
            );

            e.cancel = insertRow(e.data, strLogMessage);

            e.component.deselectAll();
          }}
          onRowRemoving={(e) => {
            let strLogMessage = LogMessageGrid(
              formatMessage("DeletedLog"),
              _product,
              e.data
            );
            const data = {
              unitCod: unitCod,
              data: e.data,
              logMessage: strLogMessage,
            };

            e.cancel = deleteRow(data);

            e.component.deselectAll();
          }}
          ref={_product}
        >
          <Editing
            mode={isLarge ? "batch" : "popup"}
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={false}
          >
            <Popup
              title="Cadastro de Produto"
              showTitle={true}
              width={700}
              height={750}
              fullScreen={true}
            >
              <Position my="center" at="center" of={window} />
            </Popup>
            <Form>
              <Item itemType="group" colCount={4} colSpan={2}>
                <Item colSpan={2} dataField="CodProdutoIntegracao" />
                <Item colSpan={2} dataField="NomProduto" />
                <Item colSpan={2} dataField="TipProduto" />
                <Item colSpan={2} dataField="CodProdutoGrupo" />
                <Item colSpan={2} dataField="ValMinEstoque" />
                <Item
                  colSpan={2}
                  dataField="CodUnidadeValorPadraoRecebimento"
                />
                <Item colSpan={1} dataField="CodUnidadeValorPadraoExpedicao" />
                <Item colSpan={1} dataField="TipControleEstoque" />
                <Item colSpan={1} dataField="TipRegistroAtivo" />
              </Item>
            </Form>
          </Editing>
          <Grouping
            contextMenuEnabled={false}
            expandMode="rowClick"
            autoExpandAll={false}
          />
          <GroupPanel visible={false} />
          <Export
            enabled={true}
            fileName="produtos"
            allowExportSelectedData={true}
          />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={false} />
          <Selection mode="multiple" />
          <Paging defaultPageSize={20} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <FilterRow visible={true} />
          <Toolbar >
            <Item cssClass={'add-container'}>
              <Button className={'add-button'}  icon={"add"} onClick={() => (_product.current.instance.addRow())}/>
            </Item>
            <Item name="saveButton"/>
            <Item name="revertButton"/>
            <Item name="exportButton"/>
            <Item name="columnChooserButton"/>
          </Toolbar>
          <Column
            dataField={"CodProdutoIntegracao"}
            caption={"Código"}
            hidingPriority={7}
            allowSorting={true}
          >
            <RequiredRule />
          </Column>
          <Column
            dataField={"NomProduto"}
            caption={"Nome Produto"}
            hidingPriority={6}
            allowSorting={true}
          >
            <CustomRule
              message={"Este campo deve ser único"}
              reevaluate={true}
              type={"custom"}
              validationCallback={checkUniqueKey}
            />
            <RequiredRule />
          </Column>
          <Column
            dataField={"TipProduto"}
            caption={"Tipo do Produto"}
            hidingPriority={3}
            allowSorting={true}
            allowSearch={false}
            sortIndex={0}
          >
            <Lookup
              dataSource={dataSourceType}
              displayExpr="NomTipo"
              valueExpr="CodTipo"
            />
            <RequiredRule />
          </Column>
          <Column
            dataField={"CodProdutoGrupo"}
            caption={"Grupo do Produto"}
            hidingPriority={3}
            allowSorting={true}
            sortIndex={0}
            allowSearch={false}
          >
            <Lookup
              dataSource={dataSourceProductGroup}
              displayExpr="NomProdutoGrupo"
              valueExpr="CodProdutoGrupo"
            />
            <RequiredRule />
          </Column>
          <Column
            dataField={"ValMinEstoque"}
            caption={"Estoque Mínimo"}
            dataType={"number"}
            //setCellValue={setCellValueTipFuncionandoAutomatico}
            allowSorting={true}
            hidingPriority={5}
          />
          <Column
            dataField={"CodUnidadeValorPadraoRecebimento"}
            caption={"Unidade Padrão Recebimento"}
            hidingPriority={3}
            allowSorting={true}
            sortIndex={0}
          >
            <Lookup
              dataSource={dataSourceMeasureValue}
              displayExpr="NomUnidadeValor"
              valueExpr="CodUnidadeValor"
            />
          </Column>
          <Column
            dataField={"CodUnidadeValorPadraoExpedicao"}
            caption={"Unidade Padrão Expedição"}
            hidingPriority={3}
            allowSorting={true}
            sortIndex={0}
          >
            <Lookup
              dataSource={dataSourceMeasureValue}
              displayExpr="NomUnidadeValor"
              valueExpr="CodUnidadeValor"
            />
          </Column>
          <Column
            dataField={"TipControleEstoque"}
            caption={"Estoque"}
            dataType={"boolean"}
            //setCellValue={setCellValue}
            allowSorting={true}
            hidingPriority={1}
          />
          <Column
            dataField={"TipRegistroAtivo"}
            caption={"Ativo"}
            dataType={"boolean"}
            //setCellValue={setCellValue}
            allowSorting={true}
            hidingPriority={1}
          />
        </DataGrid>
      </div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
