import React, { useEffect, useState } from "react";
import { api } from "../../services/api";
import { OpcServerCard } from "../opcServer-card/opcServerCard";
import { OpcVariableCard } from "../opcvariable-card/opcVariableCard";
import { Button } from "devextreme-react";
import "../../pages/administration/configurationOPC/style.scss";

export default function OpcTest(props) {
    const [opcServer, setOpcServer] = useState({ Status: "", 
                                                 AcessPermission: "", 
                                                 CanonicalDataType: "",  
                                                 Value: "", 
                                                 TimeStamp: "", 
                                                 Quality: "",
                                                 EnableButton: false });

    useEffect(() => {
        if(props.codOPCConnection) 
			loadDataServerOPC();
    }, [props.rowSelectionChanged]);

	async function loadDataServerOPC() {
		await api
			.get("/testOPC/getServerOPC", { params: { codOPCConnection: props.codOPCConnection, connectionString: props.connectionString } })
			.then((response) => {
				setOpcServer(response.data);
			})
			.catch((error) => {});
	};

    async function writeValueVariable() {
		await api
			.get("/testOPC/writeValueVariable", { params: { variableValue: opcServer.Value} })
			.then((response) => {
				setOpcServer(response.data);
			})
			.catch((error) => {});
	};

    async function readPropertiesVariable() {
		await api
			.get("/testOPC/readPropertiesVariable")
			.then((response) => {
				setOpcServer(response.data);
			})
			.catch((error) => {});
	};

    return (
        <React.Fragment>
            <div className={"ContainerCardsTestOPC"}>
                <OpcServerCard
                    opcServer={opcServer} 
                />
                <OpcVariableCard
                    opcServer={opcServer}
                    setOpcServer={setOpcServer}
                    disabled={!opcServer.EnableButton ? true : false}
                />
                <div className={"ContainerButtonsTestOPC"}>
                    <h4 className={"MarginHeader"}></h4>
                    <Button
                        disabled={!opcServer.EnableButton ? true : false}
                        text="Ler"
                        width={120}
                        useSubmitBehavior={false}
                        type="default"
                        onClick={() => readPropertiesVariable()}
                    />
                    <Button
                        disabled={!opcServer.EnableButton ? true : false}
                        text="Escrever"
                        width={120}
                        useSubmitBehavior={false}
                        type="default"
                        onClick={() => writeValueVariable()}
                    />
                </div>
            </div>
        </React.Fragment>
    )
};