export async function AddItems(listToSaveSample, isLot, newObj, listDescObservation, parIndex) {
	let index = -1;
	let indexDesc = -1;

	if(newObj.TipUnidadePeriodo === 4)
		listToSaveSample.current.forEach(sample => sample.DatAnaliseAmostra = newObj.DatAnaliseAmostra)

	index = listToSaveSample.current.findIndex(
		(val) => (val.index === parIndex && val.CodAnalise === newObj.CodAnalise)
	);
	let descGroup = "";
	
	if (index > -1) {
		descGroup = listToSaveSample.current[index].DescGroup;

		listToSaveSample.current.splice(index, 1); //Se já existe remove do array para não duplicar no BD
	
		if (descGroup !== "") {
			if (newObj.DescGroup !== "" || !newObj.IsObsGroup) {
				if (newObj.DescGroup === "") newObj.DescGroup = descGroup;
			}
		}
	}
	if (!newObj.DescGroup) newObj.DescGroup = "";

	listToSaveSample.current.push(newObj);

	//Lista de observação geral por horário
	if (!listDescObservation !== true) {
		indexDesc = listDescObservation.findIndex((val) => val.TimeOfDay === newObj.DatAnaliseAmostra.substring(11, 19).concat(":00") || val.TimeOfDay === newObj.PreviousDate.substring(11, 19).concat(":00"));

		if (indexDesc > -1) {
			listDescObservation.splice(indexDesc, 1); //Se já existe remove do array para não duplicar no BD
		}
		
		const newObjDesc = {
			TimeOfDay: newObj.DatAnaliseAmostra.substring(11, 19).concat(":00").replace(" ", ''),
			DesObservacao: newObj.DescGroup,
		};

		if (listDescObservation !== "") {
			listDescObservation.push(newObjDesc);
		}
	}
}
