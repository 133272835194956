import { createTheme } from '@material-ui/core/styles'

export const theme = createTheme({
  palette: {
    chart: {
      green: "#6cde45",
      lightGreen: "#56ca85",
      yellow: "#ffd700",
      yellowLight: "#ffe663",
      red: "#ff3424",
      pink: "#cb6bb2",
      blue: "#1e90ff",
    },
    text: {
      title: "rgba(100, 100, 100, 0.87)",
      subtitle: "rgb(179, 179, 179)",
      background: "rgba(221,221,221,0.76)",
    }
  },
});
