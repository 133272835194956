import React from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import "./style.scss";
import Popup from 'devextreme-react/popup';
import { useScreenSize } from '../../../utils/media-query';
import { Button } from "devextreme-react";

export default function PopupConfirmModifyAnalysis(props) {
    const { isXSmall} = useScreenSize();

    return (
      <Popup
        className={"pop-up"}
        width={isXSmall ? "80%" : 450}
        height={120}
        visible={props.visiblePopupConfirmModifyAnalysis}
        showTitle={false}
      >
      <div className="ContainerInfo">
        <p> Quer realmente confirmar a alteração? Todas as análises do grupo serão alteradas para verificação por {props.newObj && props.newObj.TipLote ? "lote" : "período"}</p>
        <div className="ContainerButtons">
          <Button
            text="SIM"
            type="default"
            stylingMode="text"
            onClick={(e) => {
                props.updatedConfirmed();
                props.setVisiblePopupConfirmModifyAnalysis(false);
            }}
          />
          <Button
            text="NÃO"
            type="default"
            stylingMode="text"
            onClick={(e) => {
                props.setVisiblePopupConfirmModifyAnalysis(false);
                props.setLoad(true);
            }}
          />
        </div>
      </div>
      </Popup>
    )
}