/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import DataGrid, {
    Column,
    Pager,
    RequiredRule,
    Paging,
    FilterRow,
    Export,
    Selection,
    ColumnChooser,
    Grouping,
    Lookup,
    GroupPanel,
    ColumnFixing,
    Item, 
    Toolbar
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { LogMessageGrid } from "../../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import { useLoading } from "../../../contexts/loadingContext";
import Button from "devextreme-react/button";

const tipItem = [{Cod: 0, Nom: "Análise"}, 
                 {Cod: 1, Nom: "Variável Leitura"}];

const tipGraph = [{Cod: 0, Nom: "Linha"}, 
                    {Cod: 1, Nom: "Relógio"}];

export default function ItemDashboard() {
    const { loading } = useLoading();
    const { refreshToken, getRefreshTokenCode, getToken } = useAuth();
    const [load, setLoad] = useState(false);
    const [groupDashboard, setGroupDashboard] = useState([]);
    const [itemDashboard, setItemDashboard] = useState([]);
    const [readingVariable, setReadingVariable] = useState([]);
    const [analysis, setAnalysis] = useState([]);
    const _itemDashboard = useRef();

    //Autenticação requests
    const { unitCod } = useAuth();

    useEffect(() => {
        async function firstLoad() {
            setLoad(true);
        }

        firstLoad();
        refreshToken(getToken(), getRefreshTokenCode());
    }, []);

    useEffect(() => {
        if (load) {
            awaitRequests()
        }

        async function awaitRequests() {
            loadData();
            loadDataGroupDashboard();
            loadDataReadingVariable();
            loadDataAnalysis();
        }
    }, [load]);

    async function loadData() {
        await api
            .get("/itemDashboard", { params: { unitCod: unitCod } })
            .then((response) => {
                setItemDashboard(response.data);

                setLoad(false);
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function loadDataGroupDashboard() {
        await api
            .get("/groupDashboard", { params: { unitCod: unitCod } })
            .then((response) => {
                setGroupDashboard(response.data);

                setLoad(false);
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function loadDataReadingVariable() {
        await api
            .get("/readingVariable", { params: { unitCod: unitCod } })
            .then((response) => {
                setReadingVariable(response.data);

                setLoad(false);
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function loadDataAnalysis() {
        await api
        .get("/analysisAndGroupAnalysis", { params: { unitCod: unitCod } })
        .then((response) => {
            console.log(response.data)
            setAnalysis(response.data);

            setLoad(false);
        })
        .catch((error) => {
            setLoad(true);

            return true;
        });
    }

    async function updateRow(data, parLogMessage) {
        return await api
            .put("/itemDashboard", {unitCod: unitCod, data: data, logMessage: parLogMessage})
            .then((response) => {
                setLoad(true);

                return false;
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function insertRow(data, parLogMessage) {
        return await api
            .post("/itemDashboard", {unitCod: unitCod, data: data, logMessage: parLogMessage})
            .then((response) => {
                setLoad(true);

                return false;
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function deleteRow(data) {
        return await api
            .delete("/itemDashboard", {unitCod: unitCod, data: data})
            .then((response) => {
                setLoad(true);

                return false;
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    const onEditorPreparing = (e) => {
        if (e.dataField === "CodVariavelLeitura" && e.parentType === "dataRow") {
            e.editorOptions.disabled = e.row.data.TipItem === 0 ? true : false;
            e.editorOptions.value = null;
        }

        if (e.dataField === "CodAnalise" && e.parentType === "dataRow") {
            e.editorOptions.disabled =  e.row.data.TipItem === 1 ? true : false;
            e.editorOptions.value = null;
        }

        if  (e.dataField === "NumAgrupador" && e.parentType === "dataRow")
            e.editorOptions.disabled = e.row.data.TipGrafico === 1 ? true : false;
    };

    function setCellValueTipItem(newData, value) {
        newData.TipItem = value;
        
        if(value === 0) 
            newData.CodVariavelLeitura = null;
        
        if(value === 1)
            newData.CodAnalise = null;

        this.defaultSetCellValue(newData, value);
    }

    function setCellValueTipGraph(newData, value) {
        newData.TipGrafico = value;

        if(value === 1)
            newData.NumAgrupador = null;

        this.defaultSetCellValue(newData, value);
    }

    return (
        <React.Fragment>
            <div id="receiptsGrid">
                <DataGrid
                    className={"dx-cards"}
                    dataSource={itemDashboard}
                    rowAlternationEnabled={true}
                    showColumnLines={false}
                    allowColumnReordering={true}
                    showBorders={false}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    repaintChangesOnly={false}
                    onEditorPreparing={onEditorPreparing}
                    onInitNewRow={(e) => {
                        e.data.NumOrdem = 1;
                    }}
                    headerFilter={{visible: true}}
                    filterPanel={{visible: true}}
                    filterRow={{visible: false}}
                    editing={{
                        refreshMode: "full",
                        mode: "batch",
                        allowUpdating: true,
                        allowDeleting: true,
                        allowAdding: false,
                    }}
                    onRowUpdating={async (e) => {
                        const newObj = {...e.oldData, ...e.newData};

                        let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _itemDashboard, newObj);
                        e.cancel = updateRow(newObj, strLogMessage);

                        e.component.deselectAll();
                    }}
                    onRowInserting={(e) => {
                        let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _itemDashboard, e.data);
                        e.cancel = insertRow(e.data, strLogMessage);

                        e.component.deselectAll();
                    }}
                    onRowRemoving={async (e) => {
                        let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _itemDashboard, e.data)
                        const data = {unitCod: unitCod, data: e.data, logMessage: strLogMessage};

                        e.cancel = deleteRow(data);

                        e.component.deselectAll();
                    }}
                    ref={_itemDashboard}
                >
                    <Grouping
                        contextMenuEnabled={false}
                        expandMode="rowClick"
                        autoExpandAll={false}
                    />
                    <GroupPanel visible={false}/>
                    <Export
                        enabled={true}
                        fileName="Item_Dashboard"
                        allowExportSelectedData={true}
                    />
                    <ColumnChooser enabled={true} mode="select"/>
                    <ColumnFixing enabled={false}/>
                    <Selection mode="multiple"/>
                    <Paging defaultPageSize={20}/>
                    <Pager showPageSizeSelector={true} showInfo={true}/>
                    <FilterRow visible={true}/>
                    <Toolbar>
                        <Item cssClass={'add-container'}>
                            <Button className={'add-button'} icon={"add"}
                                    onClick={() => (_itemDashboard.current.instance.addRow())}/>
                        </Item>
                        <Item name="saveButton"/>
                        <Item name="revertButton"/>
                        <Item name="exportButton"/>
                        <Item name="columnChooserButton"/>
                    </Toolbar>
                    <Column
                        dataField={"CodDashboardGrupo"}
                        caption={"Dashboard Grupo"}
                        allowSorting={true}
                        sortIndex={0}
                    >
                        <Lookup
                            dataSource={groupDashboard}
                            displayExpr="NomGrupo"
                            valueExpr="CodDashboardGrupo"
                        />
                        <RequiredRule/>
                    </Column>
                    <Column
                        dataField={"TipItem"}
                        caption={"Tipo Item"}
                        allowSorting={true}
                        sortIndex={0}
                        setCellValue={setCellValueTipItem}
                    >
                        <Lookup
                            dataSource={tipItem}
                            displayExpr="Nom"
                            valueExpr="Cod"
                        />
                        <RequiredRule/>
                    </Column>
                    <Column
                        dataField={"CodVariavelLeitura"}
                        caption={"Variável"}
                        allowSorting={true}
                        sortIndex={0}
                    >
                        <Lookup
                            dataSource={readingVariable}
                            displayExpr="NomVariavelLeitura"
                            valueExpr="CodVariavelLeitura"
                        />
                    </Column>
                    <Column
                        dataField={"CodAnalise"}
                        caption={"Análise"}
                        allowSorting={true}
                        sortIndex={0}
                    >
                        <Lookup
                            dataSource={analysis}
                            displayExpr="NomAnalise"
                            valueExpr="CodAnalise"
                        />
                    </Column>
                    <Column
                        dataField={"NumAgrupador"}
                        caption={"Número Agrupador"}
                        dataType={"number"}
                        allowSorting={true}
                    >
                    </Column>
                    <Column
                        dataField={"TipGrafico"}
                        caption={"Tipo Gráfico"}
                        allowSorting={true}
                        sortIndex={0}
                        setCellValue={setCellValueTipGraph}
                    >
                        <Lookup
                            dataSource={tipGraph}
                            displayExpr="Nom"
                            valueExpr="Cod"
                        />
                        <RequiredRule/>
                    </Column>
                    <Column
                        dataField={"ValTamanhoGrafico"}
                        caption={"Tamanho Gráfico (px)"}
                        dataType={"number"}
                        allowSorting={true}
                        sortIndex={0}
                    >
                        <RequiredRule/>
                    </Column>
                    <Column
                        dataField={"NumOrdem"}
                        caption={"Ordem"}
                        dataType={"number"}
                        allowSorting={true}
                    >
                        <RequiredRule/>
                    </Column>
                </DataGrid>
            </div>
            <div className="load-indicator">
                <LoadIndicator visible={loading} height={40} width={40}/>
            </div>
        </React.Fragment>
    );
}
