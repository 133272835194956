/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useRef} from "react";
import "devextreme/data/odata/store";
import "../../components/common/style.scss";
import {LoadIndicator} from "devextreme-react/load-indicator";
import "./style.scss";
import DataGrid, {
    Column,
    Pager,
    RequiredRule,
    StringLengthRule,
    Paging,
    FilterRow,
    Export,
    Selection,
    ColumnChooser,
    Grouping,
    Lookup,
    GroupPanel,
    ColumnFixing,
    CustomRule, Item, Toolbar
} from "devextreme-react/data-grid";
import {api} from "../../services/api";
import {useAuth} from "../../contexts/auth";
import {LogMessageGrid} from "../../utils/logMessage";
import {formatMessage} from "devextreme/localization";
import {useLoading} from "../../contexts/loadingContext";
import Button from "devextreme-react/button";

export default function GroupProgram(props) {
    const {loading} = useLoading();
    const {refreshToken, getRefreshTokenCode, getToken} = useAuth();
    const [load, setLoad] = useState(false);
    const [groupProgram, setGroupProgram] = useState([]);
    const [system, setSystem] = useState([]);
    const _groupProgram = useRef()

    //Autenticação requests
    const {unitCod} = useAuth();

    useEffect(() => {
        async function firstLoad() {
            setLoad(true);
        }

        firstLoad();
        refreshToken(getToken(), getRefreshTokenCode());
    }, []);

    useEffect(() => {
        if (load) {
            awaitRequests()
        }

        async function awaitRequests() {
            loadData();
            loadSystem();
        }
    }, [load]);

    async function loadData() {
        await api
            .get("/groupProgram")
            .then((response) => {
                setGroupProgram(response.data);
                setLoad(false);
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function loadSystem() {
        await api
            .get("/system")
            .then((response) => {
                setSystem(response.data);

                setLoad(false);
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function updateRow(data, parLogMessage) {
        return await api
            .put("/groupProgram", {unitCod: unitCod, data: data, logMessage: parLogMessage})
            .then((response) => {
                setLoad(true);
                props.setUpdate(!props.update)

                return false;
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function insertRow(data, parLogMessage) {
        return await api
            .post("/groupProgram", {unitCod: unitCod, data: data, logMessage: parLogMessage})
            .then((response) => {
                setLoad(true);
                props.setUpdate(!props.update);

                return false;
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function deleteRow(data) {
        return await api
            .delete("/groupProgram", {unitCod: unitCod, data: data})
            .then((response) => {
                setLoad(true);
                props.setUpdate(!props.update);

                return false;
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    function checkUniqueKey(params) {
        let check = true;

        groupProgram.forEach((unit) => {
            if (unit.CodProgramaGrupo !== params.data.CodProgramaGrupo &&
                unit.NomProgramaGrupo === params.data.NomProgramaGrupo
            ) check = false;
        })

        return check;
    }

    return (
        <React.Fragment>
            <div id="receiptsGrid">
                <DataGrid
                    className={"dx-cards"}
                    dataSource={groupProgram}
                    rowAlternationEnabled={true}
                    showColumnLines={false}
                    allowColumnReordering={true}
                    showBorders={false}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    repaintChangesOnly={false}
                    onInitNewRow={(e) => {
                        e.data.TipLiberarParaCliente = true;
                    }}
                    headerFilter={{visible: true}}
                    filterPanel={{visible: true}}
                    filterRow={{visible: false}}
                    editing={{
                        refreshMode: "full",
                        mode: "batch",
                        allowUpdating: true,
                        allowDeleting: true,
                        allowAdding: false,
                    }}
                    onRowUpdating={async (e) => {
                        const newObj = {...e.oldData, ...e.newData};

                        let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _groupProgram, newObj);
                        e.cancel = updateRow(newObj, strLogMessage);

                        e.component.deselectAll();
                    }}
                    onRowInserting={(e) => {
                        let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _groupProgram, e.data);
                        e.cancel = insertRow(e.data, strLogMessage);

                        e.component.deselectAll();
                    }}
                    onRowRemoving={async (e) => {
                        let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _groupProgram, e.data)
                        const data = {unitCod: unitCod, data: e.data, logMessage: strLogMessage};

                        e.cancel = deleteRow(data);

                        e.component.deselectAll();
                    }}
                    ref={_groupProgram}
                >
                    <Grouping
                        contextMenuEnabled={false}
                        expandMode="rowClick"
                        autoExpandAll={false}
                    />
                    <GroupPanel visible={false}/>
                    <Export
                        enabled={true}
                        fileName="Cadastro_Area"
                        allowExportSelectedData={true}
                    />
                    <ColumnChooser enabled={true} mode="select"/>
                    <ColumnFixing enabled={false}/>
                    <Selection mode="multiple"/>
                    <Paging defaultPageSize={20}/>
                    <Pager showPageSizeSelector={true} showInfo={true}/>
                    <FilterRow visible={true}/>
                    <Toolbar>
                        <Item cssClass={'add-container'}>
                            <Button className={'add-button'} icon={"add"}
                                    onClick={() => (_groupProgram.current.instance.addRow())}/>
                        </Item>
                        <Item name="saveButton"/>
                        <Item name="revertButton"/>
                        <Item name="exportButton"/>
                        <Item name="columnChooserButton"/>
                    </Toolbar>
                    <Column
                        dataField={"CodProgramaGrupo"}
                        caption={"Código"}
                        allowSorting={true}
                        width={"auto"}
                        allowEditing={false}
                    >
                    </Column>
                    <Column
                        dataField={"NomProgramaGrupo"}
                        caption={"Nome Grupo"}
                        allowSorting={true}
                        width={"auto"}
                    >
                        <CustomRule
                            message={'Este campo deve ser único'}
                            reevaluate={true}
                            type={"custom"}
                            validationCallback={checkUniqueKey}
                        />
                        <RequiredRule/>
                    </Column>
                    <Column
                        dataField={"TipLiberarParaCliente"}
                        caption={"Liberado"}
                        dataType={"boolean"}
                        allowSorting={true}
                    >
                    </Column>
                    <Column
                        dataField={"ValNivelProgramaGrupo"}
                        caption={"Nível Programa"}
                        dataType={"number"}
                        allowSorting={true}
                    >
                        <RequiredRule/>
                        <StringLengthRule
                            message="Pode possuir no máximo três caracteres."
                            max={3}
                        />
                    </Column>
                    <Column
                        dataField={"ValTag"}
                        caption={"Valor Tag"}
                        dataType={"number"}
                        allowSorting={true}
                    >
                        <RequiredRule/>
                    </Column>
                    <Column
                        dataField={"CodSistema"}
                        caption={"Sistema"}
                        allowSorting={true}
                        sortIndex={0}
                    >
                        <Lookup
                            dataSource={system}
                            displayExpr="NomSistema"
                            valueExpr="CodSistema"
                        />
                        <RequiredRule/>
                    </Column>
                </DataGrid>
            </div>
            <div className="load-indicator">
                <LoadIndicator visible={loading} height={40} width={40}/>
            </div>
        </React.Fragment>
    );
}
