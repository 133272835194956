/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect, useRef } from 'react'
import List from 'devextreme-react/list'
import { useAuth } from '../../contexts/auth'
import './user-panel.scss'
import { useHistory } from 'react-router'
import { api } from '../../services/api'
import { Popup } from 'devextreme-react/popup'
import { useScreenSize } from '../../utils/media-query'
import { Switch } from 'react-darkreader';
import logoNexxus from '../../images/logo-nexxus.png'
import { theme } from '../../utils/theme'

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  const { isSmall } = useScreenSize()
  const { unit, logOut } = useAuth()
  const history = useHistory()
  const userName = localStorage.getItem('@es-mes/user')
  // const userCod = localStorage.getItem("@es-mes/userCod")
  const { userCod, unitCod } = useAuth()
  const [load, setLoad] = useState(false)
  const _imgPreview = useRef()
  const [imgUser, setImgUser] = useState([])

  const menuItems = useMemo(() => [
      {
        text: 'Relatórios MES',
        icon: 'arrowright',
        onClick: () => {
          props.setOpen(false)
          window.open(`${localStorage.getItem('@es-mes/addressReports')}`)
        },
      },
      {
        text: 'Trocar Unidade / Configuração',
        icon: 'preferences',
        onClick: () => {
          props.setOpen(false)
          history.push('/configuration')
        },
      },
      {
        text: 'Guias Rápidos',
        icon: 'coffee',
        onClick: () => {
          props.setOpen(false)
          history.push('/quickguideslist')
        },
      },
      {
        text: 'Editar Perfil',
        icon: 'user',
        onClick: () => {
          props.setOpen(false)
          history.push('/profile')
        },
      },
      {
        text: 'Sair',
        icon: 'runner',
        onClick: logOut,
      },
    ],
    [logOut, history]
  )

  const contactItems = [
    {
      text: '+55 (48) 3411 5047',
      icon: 'tel',
      disabled: true,
    },
    {
      text: 'nexxus@nexxus-es.com.br',
      icon: 'email',
      disabled: true,
    },
  ]

  useEffect(() => {
    getFile('logo-usuario.png')
    getUserImage(9110, userCod)
  }, [])

  async function getFile(data) {
    await api
      .get('/file', { params: { filename: data } })
      .then((response) => {
        _imgPreview.current = response.data
        setLoad(!load)
      })
      .catch((error) => {})
  }

  async function getUserImage(programCode, primaryCode) {
    return await api
      .get('image/get', {
        params: {
          unitCod: unitCod,
          programCod: programCode,
          primaryCod: primaryCode,
        },
      })
      .then((response) => {
        if (response.data[0] === undefined || response.data[0].ImgBase64.length === 0) {
          setImgUser(_imgPreview.current)
        } else {
          setImgUser(response.data[0].ImgBase64)
        }
        setLoad(!load)
      })
      .catch(() => {
        return false
      })
  }

  return (
    <div className={'user-panel'}>
      {props.menuMode === 'context' && (
        <div className={'user-info'}>
          <div className={'image-container-user'}>
            <img className={'user-image'} src={!!imgUser ? imgUser : _imgPreview.current} alt="Imagem" />
          </div>
          <div className={'profile-text'}>
            <div className={'user-name'}>
              {userName !== null ? (userName.split(' ')[0].length >= 23 ? userName.split(' ')[0].substring(0, 21).concat('.') : userName.split(' ')[0]) : ''}
            </div>
            <div className={'user-description'}>{unit}</div>
          </div>
        </div>
      )}

      {props.menuMode === 'list' && (
        <Popup
          // width={isXSmall ? '100%' : isSmall ? '100%' : '450px'}
          // height={isXSmall ? '100%' : isSmall ? '100%' : setHeightPopup()}
          width={isSmall ? '100%' : '400px'}
          height={'100%'}
          visible={props.open}
          showTitle={true}
          closeOnOutsideClick={true}
          shading={false}
          onHiding={() => props.setOpen(false)}
          showCloseButton={true}
          dragEnabled={false}
          position={'right bottom'}
        >
          <div className={'user-info-popup'}>
            <div className={'image-container-user-popup'}>
              <img className={'user-image-popup'} src={imgUser} alt="Imagem" />
            </div>
            <div className={'profile-text-popup'}>
              <div className={'user-name-popup'}>
                {userName !== null ? (userName.split(' ')[0].length >= 23 ? userName.split(' ')[0].substring(0, 21).concat('.') : userName.split(' ')[0]) : ''}
              </div>
            </div>
            <List className={'dx-toolbar-menu-action'} items={menuItems} />
            <div className={'image-container-logo-popup'}>
              <img className={'logo-image-popup'} src={logoNexxus} alt="Imagem" />
            </div>
            <div>
              <b style={{ margin: '5px', color: theme.palette.text.title}}>Alterar para modo {props.isDark ? "claro": "escuro"}</b>
              <Switch
                height={20}
                checked={JSON.parse(localStorage.getItem("@es-mes/darkMode"))} 
                onChange={props.darkModeSwitch}
              />
            </div>
            <div className={'contact-list'}>
              <List items={contactItems} style={{ marginLeft: '25%' }} />
            </div>
          </div>
        </Popup>
      )}
    </div>
  )
}
