/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "../../../components/common/style.scss";
import "../style.scss";
import "devextreme/data/odata/store";
import { LoadIndicator } from "devextreme-react/load-indicator";
import DataGrid, {
    Column,
    Pager,
    RequiredRule,
    Paging,
    FilterRow,
    Export,
    ColumnChooser,
    Grouping,
    GroupPanel,
    ColumnFixing,
    Selection,
    CustomRule, Item, Toolbar
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { LogMessageGrid } from "../../../utils/logMessage"
import { formatMessage } from "devextreme/localization";
import { useLoading } from "../../../contexts/loadingContext";
import Button from "devextreme-react/button";

export default function Sector(props) {
  const { loading } = useLoading();
  const [sectors, setSectors] = useState([]);
  const _sector = useRef();
  const { unitCod } = useAuth();

    useEffect(() => {
        loadData();
    }, [props.update]);

  async function loadData() {
    await api
      .get(`/sector/?unitCod=${unitCod}`)
      .then((response) => {
        setSectors(response.data);
      })
      .catch(() => {
        return true;
      });
  }

  async function updateRow(data, parLogMessage) {
    return await api
      .put("/sector", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then(() => {
        props.setUpdate(!props.update);
        return false;
      })
      .catch(() => {
        return true;
      });
  }

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/sector", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then(() => {
        props.setUpdate(!props.update)
        return false;
      })
      .catch(() => {
        return true;
    });
  }

  async function deleteRow(data) {
    return await api
      .delete("/sector", { data: data })
      .then(() => {
        props.setUpdate(!props.update)
        return false;
      })
      .catch(() => {
        return true;
      });
  }

  function checkUniqueKey(params) {
    let check = true;

    sectors.forEach((unit) => {
      if(unit.CodSetor !== params.data.CodSetor &&
        unit.NomSetor === params.data.NomSetor
        ) check = false;
    })

    return check;
  }

  function checkUniqueKeyIntegration(params) {
    let check = true;
    sectors.forEach((unit) => {
      if((unit.CodSetor !== params.data.CodSetor &&
        unit.CodSetorIntegracao === params.data.CodSetorIntegracao) && params.data.CodSetorIntegracao !== null
        ) check = false;
    })

    return check;
  }

  return (
    <React.Fragment >
        <div id="registerSector">
            <DataGrid
                className={"dx-cards"}
                dataSource={sectors}
                rowAlternationEnabled={true}
                showColumnLines={true}
                allowColumnReordering={true}
                showBorders={false}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                repaintChangesOnly={true}
                headerFilter={{ visible: true }}
                filterPanel={{ visible: true }}
                filterRow={{ visible: false }}
                editing={{
                  refreshMode: "full",
                  mode:"batch",
                  allowUpdating: true,
                  allowDeleting: true,
                  allowAdding: false,
                }}
                onRowUpdating={async (e) => {
                  const newObj = { ...e.oldData, ...e.newData };
                  let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _sector, newObj);

                  e.cancel = updateRow(newObj, strLogMessage);

                  e.component.deselectAll();
                }}
                onRowInserting={(e) => {
                  const newObj = {
                    NomSetor: e.data.NomSetor,
                    CodSetorIntegracao: e.data.CodSetorIntegracao,
                    CodUnidadeEmpresa: unitCod
                  };
                  let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _sector, newObj);

                  e.cancel = insertRow(newObj, strLogMessage);

                  e.component.deselectAll();
                }}
                onRowRemoving={(e) => {
                  let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _sector, e.data)
                  const data = { unitCod: unitCod,  data: e.data, logMessage: strLogMessage };

                  e.cancel = deleteRow(data);

                  e.component.deselectAll();
                }}
                ref={_sector}
            >
                <Grouping
                    contextMenuEnabled={false}
                    expandMode="rowClick"
                    autoExpandAll={false}
                />
                <GroupPanel visible={false} />
                <Export
                  enabled={true}
                  fileName="Setor Área e Processo"
                  allowExportSelectedData={true}
                />
                <ColumnChooser enabled={true} mode="select" />
                <ColumnFixing enabled={false} />
                <Selection mode="multiple" />
                <Paging defaultPageSize={20} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={false} />
                <Toolbar >
                    <Item cssClass={'add-container'}>
                        <Button className={'add-button'}  icon={"add"} onClick={() => (_sector.current.instance.addRow())}/>
                    </Item>
                    <Item name="saveButton"/>
                    <Item name="revertButton"/>
                    <Item name="exportButton"/>
                    <Item name="columnChooserButton"/>
                </Toolbar>
                <Column
                    dataField={"NomSetor"}
                    caption={"Setor"}
                    allowEditing={true}
                    allowSorting={true}
                    sortIndex={0}
                >
                  <CustomRule
                    message={'Este campo deve ser único'}
                    reevaluate={true}
                    type={"custom"}
                    validationCallback={checkUniqueKey}
                  />
                  <RequiredRule />
                </Column>
                <Column
                  dataField={"CodSetorIntegracao"}
                  caption={"Código de Integração"}
                  dataType={"number"}
                  allowEditing={true}
                  allowSorting={true}
                  sortIndex={0}
                  width={200}
                >
                  <CustomRule
                    message={'Este campo deve ser único'}
                    reevaluate={true}
                    type={"custom"}
                    validationCallback={checkUniqueKeyIntegration}
                  />
                </Column>
            </DataGrid>

        </div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
      <div>
      </div>
    </React.Fragment>
  );
}
