import axios from "axios"
import { logout, checkLogout } from "../contexts/auth"
import notify from "devextreme/ui/notify"
import { formatMessage } from "devextreme/localization"

//Pega o IP que está na barra de endereço, para não chumbar o IP no appsettings
let result = window.document.URL.split("://", 2)
let resultUrlIp = result[1].toString().split("/", 2)
let resultUrl = resultUrlIp[0].toString().split(":", 2)
let baseUrl = `${window.api_protocol}://${resultUrl[0]}:${window.api_port}/api/v1.0/`

export const api = axios.create({
  baseURL: baseUrl,
})

api.interceptors.response.use(
  function (response) {
    const { status, config } = response

    if (status === 200 && config.url !== "auth/refreshtoken" && config.url !== "auth/validatetoken") {
      if (config.method === "post") notify(formatMessage("InsertedSuccess"), "success", 2000)

      if (config.method === "put") notify(formatMessage("UpdatedSuccess"), "success", 2000)

      if (config.method === "delete") notify(formatMessage("UpdatedSuccess"), "success", 2000)
    }

    return response
  },
  function (error) {
    const { status, data, config } = error.response

    if (status === 401) {
      if (config.method === "post" || config.method === "put" || config.method === "delete") {
        if (checkLogout() === false && !window.document.URL.includes("/login")) {
          logout()
          document.location.reload()
        }
      } else {
        if (!window.document.URL.includes("/login")) notify("Sessão expirada, faça login novamente!", "error", 2400)
      }

      if (config.method === "post") {
        const checkError = error.response.data.message

        if (checkError.includes("Usuário e/ou senha informada estão incorretos.")) notify(formatMessage("PasswordIncorrect"), "error", 2000)

        if (checkError.includes("Usuário sem unidade cadastrada.")) notify(formatMessage("NoRegisteredUnit"), "error", 2000)

        if (checkError.includes("A validade do seu sistema expirou. Favor entrar em contato com a Nexxus.")) notify(formatMessage("DateExpiration"), "error", 2000)
      }

      return false
    }

    if (error.message === "Network Error" && !error.response) notify("Não foi possível conectar com o banco de dados! Entre em contato com o suporte.", "error", 4000)
    if (status === 400 && config.method === "post") {
      const checkError = error.response.data.innerError
      const dataError = error.response.data?.dataError[3]
      notify(formatMessage("InsertError"), "error", 2000)

      if (!window.document.URL.includes("/login")) {
      }

      if (dataError === "2627")
        //UNIQUE KEY && PRIMARY error
        notify(formatMessage("InsertErrorDuplicated"), "error", 2000)

      if (checkError.includes("Erro na criação de turno")) notify(formatMessage("ShiftError"), "error", 2000)

      if (checkError.includes("Violação da restrição UNIQUE KEY")) notify(formatMessage("InsertErrorDuplicated"), "error", 2000)

      if (checkError.includes("Violação da restrição PRIMARY KEY")) notify(formatMessage("InsertErrorDuplicated"), "error", 2000)

      if (checkError.includes("'UN_Usuario_nom_login_usuario'")) notify(formatMessage("InsertErrorLoginExisting"), "error", 2000)
    }

    if (status === 400 && config.method === "put") {

      const checkError = error.response.data.innerError
      const dataError = !error.response.data.dataError ? error.response.data : error.response.data?.dataError[3]
      notify(formatMessage("UpdateError"), "error", 2000)

      if (dataError === "2627") notify(formatMessage("UpdateErrorDuplicated"), "error", 2000)

      if (checkError.includes("Erro na edição do turno")) notify(formatMessage("ShiftError"), "error", 2000)

      if (checkError.includes("Violação da restrição UNIQUE KEY")) notify(formatMessage("UpdateErrorDuplicated"), "error", 2000)

      if (checkError.includes("Violação da restrição PRIMARY KEY")) notify(formatMessage("UpdateErrorDuplicated"), "error", 2000)

      if (checkError.includes("Violação da restrição UNIQUE KEY 'UN_Usuario_nom_login_usuario'")) notify(formatMessage("InsertErrorLoginExisting"), "error", 2000)

      if (checkError.includes("A senha atual digitada não confere com a existente")) notify(formatMessage("ActualPasswordIncorrect"), "error", 2000)
    }

    if (status === 400 && config.method === "delete") {
      notify("Erro ao excluir", "error", 3000)

      if (data.dataError[3] === "547") notify(formatMessage("DeleteForeignError"), "error", 6000)

      if (String(data.innerError).substring(0, 44) === "A instrução DELETE conflitou com a restrição") notify(formatMessage("DeleteForeignError"), "error", 6000)
    }

    if (status === 403) notify("Sem permissão para a ação solicitada", "error", 4000)

    if (status === 404) notify("Não foi encontrado a solicitação", "error", 4000)

    return Promise.reject(error)
  }
)
