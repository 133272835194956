/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "./style.scss";
import DataGrid, {
	Column,
	Pager,
	RequiredRule,
	Paging,
	FilterRow,
	Export,
	Selection,
	ColumnChooser,
	Grouping,
	Lookup,
	GroupPanel,
	ColumnFixing,
	CustomRule,
	Popup,
	Form,
	Editing,
	Position,
	Item, Toolbar,
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { LogMessageGrid } from "../../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import { useLoading } from "../../../contexts/loadingContext";
import { useScreenSize } from "../../../utils/media-query";
import Button from "devextreme-react/button";
import PopupConfirmModifyAnalysis from "../popupConfirmModifyAnalysis/PopupConfirmModifyAnalysis";

export default function Group() {
	const { loading } = useLoading();
	const { refreshToken, getRefreshTokenCode, getToken } = useAuth();
	const [load, setLoad] = useState(false);
	const { isLarge } = useScreenSize();
	const [analysisGroup, setAnalysisGroup] = useState([]);
	const [analysis, setAnalysis] = useState([]);
	const [areaProcess, setAreaProcess] = useState([]);
	const [visiblePopupConfirmModifyAnalysis, setVisiblePopupConfirmModifyAnalysis] = useState(false);
	const _e = useRef([]);
	const _newObj = useRef([]);
	const _group = useRef();
	const [process, setProcess] = useState([]);

	//Autenticação requests
	const { unitCod } = useAuth();

	useEffect(() => {
		firstLoad();
		refreshToken(getToken(), getRefreshTokenCode());
	}, []);

	useEffect(() => {
		if (load) {
			awaitRequests();
		}
	}, [load]);

	async function firstLoad() {
		setLoad(true);
	}

	async function awaitRequests() {
		loadDataAnalysis();
		loadDataProcess();
		await loadAreaProcess();
		await loadData();
	}

	async function loadData() {
		await api
			.get("/analysisGroup", { params: { unitCod: unitCod } })
			.then((response) => {
				setAnalysisGroup(response.data);
				setLoad(false);
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function loadAreaProcess() {
		await api
			.get("/areaProcess", { params: { unitCod: unitCod } })
			.then((response) => {
				setAreaProcess(response.data);

				setLoad(false);
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

  	async function loadDataProcess() {
		if(unitCod !== undefined) {
			await api
			.get("/process", { params: { unitCod: unitCod } })
			.then((response) => {
				setProcess(response.data);
				setLoad(false);
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
		}
	}

	async function loadDataAnalysis() {
		await api
			.get("/analysis", { params: { unitCod: unitCod } })
			.then((response) => {
				setAnalysis(response.data);
				setLoad(false);
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function updateRow(data, parLogMessage) {
		return await api
			.put("/analysisGroup", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then((response) => {
				setLoad(true);

				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function insertRow(data, parLogMessage) {
		return await api
			.post("/analysisGroup", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then((response) => {
				setLoad(true);

				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function deleteRow(data) {
		return await api
			.delete("/analysisGroup", { data: data })
			.then((response) => {
				setLoad(true);

				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	function checkUniqueKey(params) {
		let check = true;

		analysisGroup.forEach((unit) => {
			if (unit.CodAnaliseGrupo !== params.data.CodAnaliseGrupo && unit.NomAnaliseGrupo === params.data.NomAnaliseGrupo) check = false;
		});

		return check;
	}

	function areaProcessAndProcess(parAreaProcess) {
		let strAreaProcessAndProcess;

		process.forEach((process) => {
		  if(parAreaProcess.CodProcesso === process.CodProcesso)
			strAreaProcessAndProcess = `${parAreaProcess.NomAreaProcesso} - ${process.NomProcesso}`;
		})
		return strAreaProcessAndProcess;
	}

	function updatedConfirmed() {

		_e.current.forEach((e, index) => {
			let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _group, _newObj.current[index]);
	
			e.cancel = updateRow(_newObj.current[index], strLogMessage);
			e.component.deselectAll();
		})
		_e.current = [];
		_newObj.current = [];
	}

	return (
		<React.Fragment>
			<div>
				<PopupConfirmModifyAnalysis 
					visiblePopupConfirmModifyAnalysis={visiblePopupConfirmModifyAnalysis}
					setVisiblePopupConfirmModifyAnalysis={setVisiblePopupConfirmModifyAnalysis}
					updatedConfirmed={updatedConfirmed}
					setLoad={setLoad}
				/>
				<DataGrid
					className={"dx-cards"}
					dataSource={analysisGroup}
					rowAlternationEnabled={true}
					showColumnLines={true}
					allowColumnReordering={true}
					showBorders={false}
					columnAutoWidth={true}
					columnHidingEnabled={true}
					repaintChangesOnly={false}
					onInitNewRow={(e) => {
						e.data.CodUnidadeEmpresa = unitCod;
						e.data.TipRegistroAtivo = true;
						e.data.TipLote = false;
						e.data.TipEditaFabricaParada = false;
					}}
					headerFilter={{ visible: true }}
					filterPanel={{ visible: true }}
					filterRow={{ visible: true }}
					onRowUpdating={(e) => {
						const newObj = { ...e.oldData, ...e.newData };
			
						const anaylisisPerLotDivergent = analysis.find(x => x.CodAnaliseGrupo === newObj.CodAnaliseGrupo && 
																			x.TipLote !== newObj.TipLote);
																		
						_e.current.push(e);
						_newObj.current.push(newObj);

						(anaylisisPerLotDivergent)
							? setVisiblePopupConfirmModifyAnalysis(true)
							: updatedConfirmed();
					}}
					onRowInserting={(e) => {
						let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _group, e.data);

						e.cancel = insertRow(e.data, strLogMessage);

						e.component.deselectAll();
					}}
					onRowRemoving={async (e) => {
						let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _group, e.data);
						const data = { unitCod: unitCod, data: e.data, logMessage: strLogMessage };

						e.cancel = deleteRow(data);

						e.component.deselectAll();
					}}
					ref={_group}>
					<Editing 
						mode={isLarge ? "batch" : "popup"} 
						allowUpdating={true} 
						allowDeleting={true} 
						allowAdding={false}
					>
						<Popup
							title="Cadastro de Grupo de Análise"
							showTitle={true}
							width={700}
							height={750}
							fullScreen={true}>
							<Position my="center" at="center" of={window} />
						</Popup>
						<Form>
							<Item itemType="group" colCount={4} colSpan={2}>
								<Item colSpan={2} dataField="NomAnaliseGrupo" />
								<Item colSpan={2} dataField="CodAreaProcesso" />
								<Item colSpan={2} dataField="TipLote" />
								<Item colSpan={2} dataField="NumOrdem" />
								<Item colSpan={2} dataField="TipEditaFabricaParada" />
								<Item colSpan={2} dataField="TipRegistroAtivo" />
							</Item>
						</Form>
					</Editing>
					<Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false} />
					<GroupPanel visible={false} />
					<Export enabled={true} fileName="Grupo" allowExportSelectedData={true} />
					<ColumnChooser enabled={true} mode="select" />
					<ColumnFixing enabled={false} />
					<Selection mode="multiple" />
					<Paging defaultPageSize={20} />
					<Pager showPageSizeSelector={true} showInfo={true} />
					<FilterRow visible={true} />
					<Toolbar >
						<Item cssClass={'add-container'}>
							<Button className={'add-button'}  icon={"add"} onClick={() => (_group.current.instance.addRow())}/>
						</Item>
						<Item name="saveButton"/>
						<Item name="revertButton"/>
						<Item name="exportButton"/>
						<Item name="columnChooserButton"/>
					</Toolbar>
					<Column dataField={"NomAnaliseGrupo"} caption={"Grupo"} allowSorting={true} minWidth={300}>
						<CustomRule message={"Este campo deve ser único"} reevaluate={true} type={"custom"} validationCallback={checkUniqueKey} />
						<RequiredRule />
					</Column>
					<Column
					dataField={"CodAreaProcesso"}
					caption={"Área - Processo"}
					allowSorting={true}
					minWidth={300}>
						<Lookup
						dataSource={areaProcess}
						displayExpr={(areaProcess) => areaProcessAndProcess(areaProcess)}
						valueExpr="CodAreaProcesso"
						/>
						<RequiredRule />
					</Column>
					<Column dataField={"TipLote"} caption={"Por Lote"} dataType={"boolean"} allowSorting={true}></Column>
					<Column dataField={"NumOrdem"} caption={"Ordem"} dataType={"number"} allowSorting={true}>
						<RequiredRule />
					</Column>
					<Column dataField={"TipEditaFabricaParada"} caption={"Editar Fábrica Parada"} dataType={"boolean"} allowSorting={true}></Column>
					<Column dataField={"TipRegistroAtivo"} caption={"Ativo"} dataType={"boolean"} allowSorting={true}></Column>
				</DataGrid>
			</div>
			<div className="load-indicator">
				<LoadIndicator visible={loading} height={40} width={40} />
			</div>
		</React.Fragment>
	);
}
