/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react"
import "devextreme/data/odata/store"
import "../../../components/common/style.scss"
import { LoadIndicator } from "devextreme-react/load-indicator"
//import "./style.scss";
import { api } from "../../../services/api"
import { useAuth } from "../../../contexts/auth"
import HeaderDateBox from "../../../components/header-datebox/header-datebox"
import ButtonList from "./buttonList"
import dayjs from "dayjs"
import { useLoading } from "../../../contexts/loadingContext"
import { FavButton } from "../../../components/fav-button/favButton"
import { ReportsButton } from "../../../components/reports-button/reportsButton"
import _ from "lodash"

export default function VariableInputByVariable() {
  const { loading } = useLoading()
  const [load, setLoad] = useState()
  const { refreshToken, getRefreshTokenCode, getToken, unitCod, userCod } = useAuth()
  const [screen, setScreen] = useState()
  const [secondaryButtons, setSecondaryButtons] = useState()
  const [lstVariables, setLstVariables] = useState([])
  const [lstProcess, setLstProcess] = useState()
  const [searchProcessText, setSearchProcessText] = useState()
  const [searchVariablesText, setSearchVariablesText] = useState()
  let _refHeaderDateBoxValue = useRef()
  let _lstProcess = useRef([])
  const [currencyValueDate, setCurrencyValueDate] = useState(dayjs())

  useEffect(() => {
    getLoadProcess()
    setScreen("process")
    //refreshToken(getToken(), getRefreshTokenCode());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  	//Faz o filtro do botão Pesquisar
	useEffect(() => {
		setLstProcess(_lstProcess.current.filter(lstProcess => lstProcess.NomProcesso.toUpperCase().indexOf(searchProcessText.toUpperCase()) > -1))
	}, [searchProcessText])

  //#region Requests
  async function getLoadProcess() {
    await api
      .get("/readingVariable/getprocess", { params: { unitCod: unitCod, userId: userCod } })
      .then((response) => {
        _lstProcess.current = _.orderBy(response.data, "NomProcesso")
      })
      .finally(() => {
        setLoad(true)
      })
      .catch(() => {
        return true
      })
  }
  //#endregion

  return (
    <React.Fragment>
      <HeaderDateBox
        screen={screen}
        setSearchProcessText={setSearchProcessText}
        setSearchVariablesText={setSearchVariablesText}
        setCurrencyValueDate={setCurrencyValueDate}
        currencyValueDate={currencyValueDate}
        load={load}
        setLoad={setLoad}
        refHeaderDateBoxValue={_refHeaderDateBoxValue}
        byDate={false}
        secondaryButtons={secondaryButtons}
        sampleScreen={false}
      />
      <div className={"ContainerButtons"}>
        <FavButton />
        <ReportsButton />
      </div>
      <div>
        {_lstProcess.current.length > 0 ? (
          <ButtonList
            list={!lstProcess || lstProcess.length === 0 ? _lstProcess.current : lstProcess}
            screen={screen}
            setScreen={setScreen}
            setLstVariables={setLstVariables}
            setLoad={setLoad}
            load={load}
            setSearchProcessText={setSearchProcessText}
            setSearchVariablesText={setSearchVariablesText}
            searchVariablesText={searchVariablesText}
            refHeaderDateBoxValue={_refHeaderDateBoxValue.current}
            currencyValueDate={currencyValueDate}
            setCurrencyValueDate={setCurrencyValueDate}
            secondaryButtons={secondaryButtons}
            setSecondaryButtons={setSecondaryButtons}
          />
        ) : (
          ""
        )}
      </div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  )
}
