/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../../components/common/style.scss";
import "devextreme/data/odata/store";
import { LogMessageGrid } from "../../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Editing,
  FilterRow,
  Form,
  Grouping,
  GroupPanel,
  Item,
  Pager,
  Paging,
  RequiredRule, Toolbar,
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useLoading } from "../../../contexts/loadingContext";
import { useAuth } from "../../../contexts/auth";
import { LoadIndicator } from "devextreme-react/load-indicator";
import Button from "devextreme-react/button";

export default function MonitorationBlockGroup(props) {
    const { loading } = useLoading();
    const [load, setLoad] = useState(false);
    const [monitorationBlockGroup, setMonitorationBlockGroup] = useState([]);
    const _monitorationBlockGroup = useRef();
    const { unitCod } = useAuth();

    useEffect(() => {
        loadMonitorationBlockGroupData();
        props.setUpdate(!props.update)
    }, [load]);


    async function loadMonitorationBlockGroupData() {
        await api
            .get("/monitorationBlockGroup", { params: { unitCod: unitCod } })
            .then((response) => {
                setMonitorationBlockGroup(response.data);
            })
            .catch(() => {
                return true;
            });
      }

    async function updateRow(data, parLogMessage) {
        return await api
            .put("/monitorationBlockGroup", {
                unitCod: unitCod,
                data: data,
                logMessage: parLogMessage,
            })
            .then(() => {
                setLoad(!load);
            })
            .catch(() => {
                return true;
            });
    }

    async function insertRow(data, parLogMessage) {
        return await api
            .post("/monitorationBlockGroup", {
                unitCod: unitCod,
                data: data,
                logMessage: parLogMessage,
            })
            .then(() => {
                setLoad(!load);
                return false;
            })
            .catch(() => {
                return true;
            });
    }

    async function deleteRow(data) {
        return await api
            .delete("/monitorationBlockGroup", { data: data })
            .then(() => {
                setLoad(!load);
                return false;
            })
            .catch(() => {
                return true;
            });
    }

    return (
        <React.Fragment>
        <DataGrid
            className={"dx-cards"}
            dataSource={monitorationBlockGroup}
            rowAlternationEnabled={true}
            showColumnLines={true}
            allowColumnReordering={true}
            showBorders={false}
            columnHidingEnabled={true}
            columnAutoWidth={true}
            repaintChangesOnly={true}
            onInitNewRow={e => e.data.CodUnidadeEmpresa = unitCod}
            headerFilter={{ visible: true }}
            filterPanel={{ visible: true }}
            filterRow={{ visible: false }}
            onRowUpdating={e => {
                const newObj = { ...e.oldData, ...e.newData };
                let strLogMessage = LogMessageGrid(
                    formatMessage("UpdatedLog"),
                    _monitorationBlockGroup,
                    newObj
                );

                e.cancel = updateRow(newObj, strLogMessage);
                e.component.deselectAll();
            }}
            onRowInserting={e => {
                let strLogMessage = LogMessageGrid(
                    formatMessage("InsertedLog"),
                    _monitorationBlockGroup,
                    e.data
                );

                e.cancel = insertRow(e.data, strLogMessage);
                e.component.deselectAll();
            }}
            onRowRemoving={e => {
                let strLogMessage = LogMessageGrid(
                    formatMessage("DeletedLog"),
                    _monitorationBlockGroup,
                    e.data
                );

                const data = {
                    unitCod: unitCod,
                    data: e.data,
                    logMessage: strLogMessage,
                };
                
                e.cancel = deleteRow(data);
                e.component.deselectAll();
            }}
            ref={_monitorationBlockGroup}
        >
            <Editing
                mode={"batch"}
                allowUpdating={true}
                allowDeleting={true}
                allowAdding={false}
            >
                <Form>
                    <Item itemType="group" colCount={4} colSpan={2}>
                    <Item colSpan={2} dataField="NomBalancaFluxo" />
                    <Item colSpan={2} dataField="CodAreaProcesso" />
                    </Item>
                </Form>
            </Editing>
            <Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false}/>
            <GroupPanel visible={false} />
            <ColumnChooser enabled={true} mode="select" />
            <ColumnFixing enabled={false} />
            <Paging defaultPageSize={20} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />
            <Toolbar>
                <Item cssClass={'add-container'}>
                    <Button className={'add-button'} icon={"add"} onClick={() => _monitorationBlockGroup.current.instance.addRow()}/>
                </Item>
                <Item name="saveButton"/>
                <Item name="revertButton"/>
                <Item name="exportButton"/>
                <Item name="columnChooserButton"/>
            </Toolbar>
            <Column
                dataField={"NomBlocoMonitoracaoGrupo"}
                caption={"Grupos"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
                <RequiredRule />
            </Column>
        </DataGrid>
        <div className="load-indicator">
            <LoadIndicator visible={loading} height={40} width={40} />
        </div>
        </React.Fragment>
    );
};
