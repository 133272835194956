/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "../../../components/common/style.scss";
import "devextreme/data/odata/store";
import {LogMessageGrid} from "../../../utils/logMessage";
import {formatMessage} from "devextreme/localization";
import DataGrid, {
    Column,
    ColumnChooser, ColumnFixing, Editing,
    FilterRow,
    Form,
    Grouping,
    GroupPanel,
    Item, Pager, Paging,
    RequiredRule, Toolbar,
    Lookup,
    Export,
    CustomRule,
    Selection
} from "devextreme-react/data-grid";
import {api} from "../../../services/api";
import {useLoading} from "../../../contexts/loadingContext";
import {useAuth} from "../../../contexts/auth";
import {LoadIndicator} from "devextreme-react/load-indicator";
import Button from "devextreme-react/button";

export default function Bundler(props) {
    const { unitCod, refreshToken, getRefreshTokenCode, getToken } = useAuth();
    const { loading } = useLoading();
    const [load, setLoad] = useState(false);
    const [dailyReportBundler, setDailyReportBundler] = useState(false);
    const [process, setProcess] = useState();
    const _dailyReportBundler = useRef();
    
    useEffect(() => {
        firstLoad()
    }, [props.update]);

    useEffect(() => {
        if (load)
            updateLoad();
    }, [load]);

    async function updateLoad() {
        loadData();
    }

    async function firstLoad() {
        if (unitCod !== undefined) {
            loadData();
            loadProcess();
            await refreshToken(getToken(), getRefreshTokenCode());
        }
    }

    async function loadData() {
        await api
            .get("/dailyReportBundler", { params: { unitCod: unitCod } })
            .then((response) => {
                setDailyReportBundler(response.data);
                setLoad(false);
            })
            .catch(() => {
                setLoad(true);
                return true;
            });
    }

    async function loadProcess() {
        await api
            .get("/process", {params: {unitCod: unitCod}})
            .then((response) => {
                setProcess(response.data);
                setLoad(false);
            })
            .catch(() => {
                setLoad(true);

                return true;
            });
    }

    async function updateRow(data, parLogMessage) {
        return await api
            .put("/dailyReportBundler", { unitCod: unitCod, data: data, logMessage: parLogMessage })
            .then((response) => {
                setLoad(true);
                props.setUpdate(!props.update)
                return false;
            })
            .catch(() => {
                setLoad(true);
                return true;
            });
    }

    async function insertRow(data, parLogMessage) {
        return await api
            .post("/dailyReportBundler", { unitCod: unitCod, data: data, logMessage: parLogMessage })
            .then((response) => {
                setLoad(true);
                props.setUpdate(!props.update)
                return false;
            })
            .catch(() => {
                setLoad(true);
                return true;
            });
    }

    async function deleteRow(data) {
        return await api
            .delete("/dailyReportBundler", { data: data })
            .then(() => {
                setLoad(true);
                props.setUpdate(!props.update)
                return false;
            })
            .catch(() => {
                setLoad(true);

                return true;
            });
    }

    function checkUniqueKey(params) {
        let check = true;
    
        dailyReportBundler.forEach((unit) => {
          if(unit.CodRelatorioDiarioAgrupador !== params.data.CodRelatorioDiarioAgrupador
             && unit.CodProcesso === params.data.CodProcesso
             && unit.NomAgrupador === params.data.NomAgrupador) 
                check = false;
        })
    
        return check;
    }

    return (
        <React.Fragment>
            <DataGrid
                className={"dx-cards"}
                dataSource={dailyReportBundler}
                rowAlternationEnabled={true}
                showColumnLines={true}
                allowColumnReordering={true}
                showBorders={false}
                columnHidingEnabled={true}
                repaintChangesOnly={true}
                onInitNewRow={(e) => {
                    e.data.CodUnidadeEmpresa = unitCod;
                    e.data.TipRegistroAtivo = true;
                }}
                headerFilter={{ visible: true }}
                filterPanel={{ visible: true }}
                filterRow={{ visible: true }}
                onRowUpdating={async (e) => {
                    const newObj = { ...e.oldData, ...e.newData };
                    let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _dailyReportBundler, newObj);

                    e.cancel = await updateRow(newObj, strLogMessage);

                    e.component.deselectAll();
                }}
                onRowInserting={async (e) => {
                    let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _dailyReportBundler, e.data);

                    e.cancel = await insertRow(e.data, strLogMessage);

                    e.component.deselectAll();
                }}
                onRowRemoving={async (e) => {
                    let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _dailyReportBundler, e.data);
                    const data = { unitCod: unitCod, data: e.data, logMessage: strLogMessage };

                    e.cancel = await deleteRow(data);

                    e.component.deselectAll();

                }}
                ref={_dailyReportBundler}>
                <Export
                    enabled={true}
                    fileName="Agrupador"
                    allowExportSelectedData={true}
                />
                <Editing mode={"batch"} allowUpdating={true} allowDeleting={true} allowAdding={false}>
                    <Form>
                        <Item itemType="group" colCount={4} colSpan={2}>
                            <Item colSpan={2} dataField="NomAgrupador" />
                            <Item colSpan={2} dataField="CodProcesso" />
                            <Item colSpan={2} dataField="NumOrdem" />
                            <Item colSpan={2} dataField="TipRegistroAtivo" />
                        </Item>
                    </Form>
                </Editing>
                <Selection mode="multiple" />
                <Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false} />
                <GroupPanel visible={false} />
                <ColumnChooser enabled={true} mode="select" />
                <ColumnFixing enabled={false} />
                <Paging defaultPageSize={20} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />
                <Toolbar >
                    <Item cssClass={'add-container'}>
                        <Button className={'add-button'}  icon={"add"} onClick={() => (_dailyReportBundler.current.instance.addRow())}/>
                    </Item>
                    <Item name="saveButton"/>
                    <Item name="revertButton"/>
                    <Item name="exportButton"/>
                    <Item name="columnChooserButton"/>
                </Toolbar>
                <Column
                    dataField={"NomAgrupador"}
                    caption={"Nome Agrupador"}
                    allowSorting={true}
                    allowFiltering={true}
                    allowSearch={true}
                    allowGrouping={true}
                    allowEditing={true}>
                    <RequiredRule />
                    <CustomRule
                        message={'Este campo deve ser único'}
                        reevaluate={true}
                        type={"custom"}
                        validationCallback={checkUniqueKey}
                    />
                </Column>
                <Column 
                    dataField={"CodProcesso"} 
                    caption={"Processo"} 
                    allowSorting={true}
                    allowFiltering={true} 
                >
                    <CustomRule
                        message={'Este campo deve ser único'}
                        reevaluate={true}
                        type={"custom"}
                        validationCallback={checkUniqueKey}
                    />
                    <Lookup 
                        dataSource={process} 
                        displayExpr="NomProcesso" 
                        valueExpr="CodProcesso" 
                    />
                    <RequiredRule />
                </Column>
                <Column
                    dataType={"number"}
                    dataField={"NumOrdem"}
                    caption={"Ordem"}
                    allowSorting={true}
                    allowFiltering={true}
                    sortIndex={0}
                >
                    <RequiredRule />
                </Column>
                <Column 
                    dataType={"boolean"}
                    dataField={"TipRegistroAtivo"}
                    caption={"Ativo"}
                    allowFiltering={true}
                    allowSorting={true}
                    sortIndex={0}
                />
            </DataGrid>
            <div className="load-indicator">
                <LoadIndicator visible={loading} height={40} width={40} />
            </div>
        </React.Fragment>
    );
}
