/* eslint-disable react-hooks/exhaustive-deps */
import Drawer from 'devextreme-react/drawer'
import ScrollView from 'devextreme-react/scroll-view'
import React, { useState, useCallback, useRef } from 'react'
import { useHistory } from 'react-router'
import { Header, SideNavigationMenu, Footer } from '../../components'
import './side-nav-outer-toolbar.scss'
import { useScreenSize } from '../../utils/media-query'
import { Template } from 'devextreme-react/core/template'
import { useMenuPatch } from '../../utils/patches'
import { useAuth } from '../../contexts/auth'

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ title, children }) {
  const scrollViewRef = useRef()
  const history = useHistory()
  const { isXSmall, isLarge } = useScreenSize()
  const [patchCssClass, onMenuReady] = useMenuPatch()
  const [menuStatus, setMenuStatus] = useState(MenuStatus.Closed)
  const { checkLocalStorage } = useAuth()

  const toggleMenu = useCallback(({ event }) => {
    setMenuStatus((prevMenuStatus) => (prevMenuStatus === MenuStatus.Closed? MenuStatus.Opened : MenuStatus.Closed))
    event.stopPropagation()
  }, [])

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus(state => state === MenuStatus.Opened ? state : state === MenuStatus.Closed ? MenuStatus.TemporaryOpened : MenuStatus.Closed)
  }, [])

  const onOutsideClick = useCallback(() => {
    setMenuStatus((prevMenuStatus) => prevMenuStatus !== MenuStatus.Closed && !isLarge ? MenuStatus.Closed : prevMenuStatus)
  }, [isLarge])

  const onNavigationChanged = useCallback(
    ({ itemData: { path }, event, node }) => {
      if (menuStatus === MenuStatus.Closed || !path || node.selected) {
        event.preventDefault()
        return
      }

      checkLocalStorage()

      history.push(path)
      if (scrollViewRef.current !== null) scrollViewRef.current.instance.scrollTo(0)

      if (!isLarge) {
        setMenuStatus(MenuStatus.Closed)
        event.stopPropagation()
      }
    },
    [history, menuStatus, isLarge]
  )

  return (
    <div className={'side-nav-outer-toolbar'}>
      <Header className={'layout-header'} menuToggleEnabled toggleMenu={toggleMenu} title={title} />
      <Drawer
        className={['drawer', patchCssClass].join(' ')}
        position={'before'}
        closeOnOutsideClick={!isLarge ? onOutsideClick : false}
        openedStateMode={isLarge ? 'shrink' : 'overlap'}
        revealMode={isXSmall ? 'slide' : 'expand'}
        minSize={isXSmall ? 0 : 60}
        maxSize={250}
        shading={isLarge ? false : true}
        opened={menuStatus === MenuStatus.Closed ? false : true}
        template={'menu'}
      >
        <div className={'container'}>
          <ScrollView ref={scrollViewRef} className={'layout-body with-footer'}>
            <div className={'content'}>
              {React.Children.map(children, (item) => {
                return item.type !== Footer && item
              })}
            </div>
            <div className={'content-block'}>
              {React.Children.map(children, (item) => {
                return item.type === Footer && item
              })}
            </div>
          </ScrollView>
        </div>
        <Template name={'menu'}>
          <SideNavigationMenu
            compactMode={menuStatus === MenuStatus.Closed}
            selectedItemChanged={onNavigationChanged}
            openMenu={temporaryOpenMenu}
            onMenuReady={onMenuReady}
          />
        </Template>
      </Drawer>
    </div>
  )
}

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3,
}
