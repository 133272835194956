/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "../../../components/common/style.scss";
import "./style.scss";
import "devextreme/data/odata/store";
import { LoadIndicator } from "devextreme-react/load-indicator";
import DataGrid, {
	Column,
	Pager,
	RequiredRule,
	Paging,
	FilterRow,
	Export,
	ColumnChooser,
	Grouping,
	GroupPanel,
	ColumnFixing,
	Selection,
	CustomRule,
	Lookup,
	Editing,
	Form,
	Popup,
	Position,
	Item, Toolbar,
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { LogMessageGrid } from "../../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import { useLoading } from "../../../contexts/loadingContext";
import { useScreenSize } from "../../../utils/media-query";
import Button from "devextreme-react/button";

export default function OpcConnection(props) {
	const { loading } = useLoading();
	const { refreshToken, getRefreshTokenCode, getToken } = useAuth();
	const [load, setLoad] = useState(false);
	const { isLarge } = useScreenSize();
	const [lstOpcConnection, setLstOpcConnection] = useState([]);
	const [lstOpcServer, setLstOpcServer] = useState([]);
	const _lstOpcConnection = useRef([]);

	//Autenticação requests
	const { unitCod } = useAuth();

	useEffect(() => {
		firstLoad();
	}, [props.update]);

	useEffect(() => {
		if (load) loadData();
	}, [load]);

	async function firstLoad() {
		if (unitCod !== undefined) {
			loadData();
			loadDataOpcServer();
			refreshToken(getToken(), getRefreshTokenCode());
		}
	}

	async function loadData() {
		await api
			.get("/OPCConnection", { params: { unitCod: 0 } })
			.then((response) => {
				setLstOpcConnection(response.data);
				setLoad(false);
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function loadDataOpcServer() {
		await api
			.get("/OPCServer", { params: { unitCod: 0 } })
			.then((response) => {
				setLstOpcServer(response.data);
				setLoad(false);
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function updateRow(data, parLogMessage) {
		return await api
			.put("/OPCConnection", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then((response) => {
				setLoad(true);
				props.setUpdate(!props.update);
				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function insertRow(data, parLogMessage) {
		return await api
			.post("/OPCConnection", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then((response) => {
				setLoad(true);
				props.setUpdate(!props.update);
				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function deleteRow(data) {
		return await api
			.delete("/OPCConnection", { data: data })
			.then((response) => {
				setLoad(true);
				props.setUpdate(!props.update);
				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	function checkUniqueKey(params) {
		let check = true;

		lstOpcConnection.forEach((opcConnection) => {
			if (
				opcConnection.CodOpcConexao !== params.data.CodOpcConexao &&
				opcConnection.NomOpcConexao1 === params.data.NomOpcConexao1 &&
				opcConnection.NomOpcConexao2 === params.NomOpcConexao2
			)
				check = false;
		});

		return check;
	}

	return (
		<React.Fragment>
			<div id="opc-connection">
				<DataGrid
					className={"dx-cards"}
					dataSource={lstOpcConnection}
					rowAlternationEnabled={true}
					showColumnLines={true}
					allowColumnReordering={true}
					showBorders={false}
					columnAutoWidth={true}
					columnHidingEnabled={true}
					repaintChangesOnly={true}
					headerFilter={{ visible: true }}
					filterPanel={{ visible: true }}
					filterRow={{ visible: false }}
					/* editing={{
                  refreshMode: "full",
                  mode:"batch",
                  allowUpdating: true,
                  allowDeleting: true,
                  allowAdding: false,
                }} */
					onInitNewRow={(e) => {
						e.data.TipHabilitaConexao = true;
					}}
					onRowUpdating={async (e) => {
						const newObj = { ...e.oldData, ...e.newData };
						let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _lstOpcConnection, newObj);

						e.cancel = updateRow(newObj, strLogMessage);

						e.component.deselectAll();
					}}
					onRowInserting={(e) => {
						let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _lstOpcConnection, e.data);

						e.cancel = insertRow(e.data, strLogMessage);

						e.component.deselectAll();
					}}
					onRowRemoving={(e) => {
						let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _lstOpcConnection, e.data);
						const data = { unitCod: unitCod, data: e.data, logMessage: strLogMessage };

						e.cancel = deleteRow(data);

						e.component.deselectAll();
					}}
					ref={_lstOpcConnection}>
					<Editing mode={isLarge ? "batch" : "popup"} allowUpdating={true} allowDeleting={true} allowAdding={false}>
						<Popup title="Cadastro de Conexão OPC" showTitle={true} width={700} height={750} fullScreen={true}>
							<Position my="center" at="center" of={window} />
						</Popup>
						<Form>
							<Item itemType="group" colCount={4} colSpan={2}>
								<Item colSpan={2} dataField="NomOpcConexao1" />
								<Item colSpan={2} dataField="NomComputadorServidor1" />
								<Item colSpan={2} dataField="NomOpcConexao2" />
								<Item colSpan={2} dataField="NomComputadorServidor2" />
								<Item colSpan={2} dataField="CodOpcServidor" />
								<Item colSpan={2} dataField="TipHabilitaConexao" />
								<Item colSpan={1} dataField="DesStringConexaoWatchdog" />
							</Item>
						</Form>
					</Editing>
					<Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false} />
					<GroupPanel visible={false} />
					<Export enabled={true} fileName="Conexão" allowExportSelectedData={true} />
					<ColumnChooser enabled={true} mode="select" />
					<ColumnFixing enabled={false} />
					<Selection mode="multiple" />
					<Paging defaultPageSize={20} />
					<Pager showPageSizeSelector={true} showInfo={true} />
					<FilterRow visible={true} />
					<Toolbar >
						<Item cssClass={'add-container'}>
							<Button className={'add-button'}  icon={"add"} onClick={() => (_lstOpcConnection.current.instance.addRow())}/>
						</Item>
						<Item name="saveButton"/>
						<Item name="revertButton"/>
						<Item name="exportButton"/>
						<Item name="columnChooserButton"/>
					</Toolbar>
					<Column dataField={"NomOpcConexao1"} caption={"Nome Conexão 1"} allowSorting={true} hidingPriority={7} >
						<CustomRule message={"Este campo deve ser único"} reevaluate={true} type={"custom"} validationCallback={checkUniqueKey} />
						<RequiredRule />
					</Column>
					<Column dataField={"NomComputadorServidor1"} caption={"Nome PC Servidor 1"} allowSorting={true} hidingPriority={6}>
						<RequiredRule />
					</Column>
					<Column dataField={"NomOpcConexao2"} caption={"Nome Conexão 2"} allowSorting={true} hidingPriority={5} >
						<CustomRule message={"Este campo deve ser único"} reevaluate={true} type={"custom"} validationCallback={checkUniqueKey} />
					</Column>
					<Column dataField={"NomComputadorServidor2"} caption={"Nome PC Servidor 2"} hidingPriority={4} sortIndex={0} ></Column>
					<Column dataField={"CodOpcServidor"} caption={"Servidor"} width={200} allowSorting={true} hidingPriority={2}>
						<Lookup dataSource={lstOpcServer} displayExpr="NomOpcServidor" valueExpr="CodOpcServidor" />
						<RequiredRule />
					</Column>
					<Column
						dataField={"TipHabilitaConexao"}
						caption={"Habilitar Conexão"}
						dataType={"boolean"}
						allowSorting={true}
						hidingPriority={3}
						width={"auto"}></Column>
					<Column
						dataField={"DesStringConexaoWatchdog"}
						caption={"String Conexão Watchdog"}
						allowSorting={true}
						width={"auto"}
						hidingPriority={1}></Column>
				</DataGrid>
			</div>
			<div className="load-indicator">
				<LoadIndicator visible={loading} height={40} width={40} />
			</div>
			<div></div>
		</React.Fragment>
	);
}
