import { api } from "../services/api";

export async function loadDataProgramPermission(unitCod, userCod) {
    await api
      .get("/permissionUser", { params: { unitCod: unitCod, userCod: userCod } })
      .then((response) => {
        localStorage.setItem("@es-mes/permissions", JSON.stringify(response.data));
      })
      .catch((error) => {

        return true;
      });
};

export async function loadDataUsers(userCod, setUser, setLoad) {
  await api
    .get("/user/get", { params: { id: userCod } })
    .then((response) => {
      if (userCod === 0)  {
        setUser(response.data);
      } else {
        setUser(response.data.filter((x) => x.CodUsuario !== 0));
      }
      
      setLoad(false);
    })
    .catch((error) => {
      setLoad(true);

      return true;
    });
};

export async function loadDataSectors(unitCod, setLstSectors, setLoad) {
  await api
    .get("/sector", { params: { unitCod: unitCod } })
    .then((response) => {
      setLstSectors(response.data);
      setLoad(false);
    })
    .catch((error) => {
      setLoad(true);

      return true;
    });
};

export async function loadDataAddressReports(unitCod) {
  await api
    .get("/aplication/getAddressReports", { params: { unitCod: unitCod } })
    .then((response) => {
      localStorage.setItem("@es-mes/addressReports", response.data);
    })
    .catch((error) => {

    });
}