/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../../components/common/style.scss";
import "devextreme/data/odata/store";
import { LogMessageGrid } from "../../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Editing,
  FilterRow,
  Form,
  Grouping,
  GroupPanel,
  Item,
  Lookup,
  Pager,
  Paging,
  RequiredRule, Toolbar,
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useLoading } from "../../../contexts/loadingContext";
import { useAuth } from "../../../contexts/auth";
import { LoadIndicator } from "devextreme-react/load-indicator";
import Button from "devextreme-react/button";

export default function MonitorationBlock(props) {
    const { loading } = useLoading();
    const [load, setLoad] = useState(false);
    const [monitorationBlock, setMonitorationBlock] = useState([]);
    const [monitorationBlockOpc, setMonitorationBlockOpc] = useState([]);
    const [monitorationBlockgroup, setMonitorationBlockgroup] = useState([]);
    const [area, setArea] = useState([]);
    const _monitorationBlock = useRef();
    const { unitCod } = useAuth();

    useEffect(() => {
        async function requestOpc() {
            loadOpcGroupData()
            await loadDataOpc()
        }
        requestOpc()
    }, [props.update])

    useEffect(() => {
        async function requestsFirstLoad() {
            await loadAreaData()
            loadData()
        }
        requestsFirstLoad()
    }, [])
    
    useEffect(() => {
        if (load) {
            loadData()
            setLoad(false)
        }
    }, [load])

    useEffect(() => {
        loadDataOpc();
    }, [props.setUpdate])

    async function loadData() {
        await api
        .get("/monitorationBlock", { params: { unitCod: unitCod } })
        .then((response) => {
            setMonitorationBlock(response.data);
        })
        .catch(() => {
            return true;
        });
    }

    async function loadDataOpc() {
        await api
            .get("/MonitorationBlockOpc", { params: { unitCod: unitCod } })
            .then((response) => {
                setMonitorationBlockOpc(response.data);
            })
            .catch(() => {
                return true;
            });
    }

    async function loadOpcGroupData() {
        await api
            .get("/monitorationBlockGroup", { params: { unitCod: unitCod } })
            .then((response) => {
                setMonitorationBlockgroup(response.data);
            })
            .catch(() => {
                return true;
            });
    }

    async function loadAreaData() {
        await api
            .get("/areaProcess", { params: { unitCod: unitCod } })
            .then((response) => {
                setArea(response.data);
            })
            .catch(() => {
                return true;
            });
    }

    async function updateRow(data, parLogMessage) {
        return await api
        .put("/monitorationBlock", {
            unitCod: unitCod,
            data: data,
            logMessage: parLogMessage
        })
        .then(() => {
            setLoad(!load);
            props.setUpdate(!props.update);
        })
        .catch(() => {
            return true;
        });
    }

    async function insertRow(data, parLogMessage) {
        return await api
        .post("/monitorationBlock", {
            unitCod: unitCod,
            data: data,
            logMessage: parLogMessage
        })
        .then(() => {
            setLoad(!load);
            props.setUpdate(!props.update);
            return false;
        })
        .catch(() => {
            return true;
        });
    }

    async function deleteRow(data) {
        return await api
        .delete("/monitorationBlock", { data: data })
        .then(() => {
            setLoad(!load);
            props.setUpdate(!props.update);
            return false;
        })
        .catch(() => {
            return true;
        });
    }

    return (
        <React.Fragment>
        <DataGrid
            className={"dx-cards"}
            dataSource={monitorationBlock}
            rowAlternationEnabled={true}
            showColumnLines={true}
            allowColumnReordering={true}
            showBorders={false}
            columnHidingEnabled={true}
            columnAutoWidth={true}
            repaintChangesOnly={true}
            onInitNewRow={(e) => {
                e.data.CodUnidadeEmpresa = unitCod;
                e.data.TipRegistroAtivo = true;
            }}
            headerFilter={{ visible: true }}
            filterPanel={{ visible: true }}
            filterRow={{ visible: false }}
            onRowUpdating={async (e) => {
                const newObj = { ...e.oldData, ...e.newData };
                let strLogMessage = LogMessageGrid(
                    formatMessage("UpdatedLog"),
                    _monitorationBlock,
                    newObj
                );

                e.cancel = updateRow(newObj, strLogMessage);
                e.component.deselectAll();
            }}
            onRowInserting={async (e) => {
                let strLogMessage = LogMessageGrid(
                    formatMessage("InsertedLog"),
                    _monitorationBlock,
                    e.data
                );

                e.cancel = insertRow(e.data, strLogMessage);
                e.component.deselectAll();
            }}
            onRowRemoving={(e) => {
                let strLogMessage = LogMessageGrid(
                    formatMessage("DeletedLog"),
                    _monitorationBlock,
                    e.data
                );

                const data = {
                    unitCod: unitCod,
                    data: e.data,
                    logMessage: strLogMessage,
                };
                e.cancel = deleteRow(data);
                e.component.deselectAll();
            }}
            ref={_monitorationBlock}
        >
            <Editing
                mode={"batch"}
                allowUpdating={true}
                allowDeleting={true}
                allowAdding={false}
            >
                <Form>
                    <Item itemType="group" colCount={4} colSpan={2}>
                    <Item colSpan={2} dataField="NomBalancaFluxo" />
                    <Item colSpan={2} dataField="CodAreaProcesso" />
                    </Item>
                </Form>
            </Editing>
            <Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false}/>
            <GroupPanel visible={false} />
            <ColumnChooser enabled={true} mode="select" />
            <ColumnFixing enabled={false} />
            <Paging defaultPageSize={20} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />
            <Toolbar>
                <Item cssClass={'add-container'}>
                    <Button className={'add-button'} icon={"add"} onClick={() => (_monitorationBlock.current.instance.addRow())}/>
                </Item>
                <Item name="saveButton"/>
                <Item name="revertButton"/>
                <Item name="exportButton"/>
                <Item name="columnChooserButton"/>
            </Toolbar>
            <Column
                dataField={"NomBlocoMonitoracao"}
                caption={"Nome"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
                <RequiredRule />
            </Column>
            <Column
                dataField={"CodAreaProcesso"}
                caption={"Área"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
                <RequiredRule />
                <Lookup
                    dataSource={area}
                    displayExpr={area => `${area.NomAreaProcesso}`}
                    valueExpr="CodAreaProcesso"
                />
            </Column>
            <Column
                dataField={"CodBlocoMonitoracaoOpc"}
                caption={"String Conexão OPC"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
                <RequiredRule />
                <Lookup
                    dataSource={monitorationBlockOpc}
                    displayExpr={monitorationBlockOpc => `${monitorationBlockOpc.DesStringConexao}`}
                    valueExpr="CodBlocoMonitoracaoOpc"
                />
            </Column>
            <Column
                dataField={"CodBlocoMonitoracaoGrupo"}
                caption={"Grupo"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
                <RequiredRule />
                <Lookup
                    dataSource={monitorationBlockgroup}
                    displayExpr={val => `${val.NomBlocoMonitoracaoGrupo}`}
                    valueExpr="CodBlocoMonitoracaoGrupo"
                />
            </Column>
            <Column
                dataField={"TipRegistroAtivo"}
                caption={"Ativo"}
                allowSorting={true}
                datatype={"boolean"}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
            </Column>
        </DataGrid>
        <div className="load-indicator">
            <LoadIndicator visible={loading} height={40} width={40} />
        </div>
        </React.Fragment>
    );
};
