import React, { useEffect, useState } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/form";
import MonitorationBlock from "./monitorationBlock";
import MonitorationBlockOpc from "./monitorationBlockOpc";
import MonitorationBlockGroup from "./monitorationBlockGroup";
import MonitorationBlockVariable from "./monitorationBlockVariable";
import MonitorationBlockConfiguration from "./monitorationBlockConfiguration";
import { FavButton } from "../../../components/fav-button/favButton";
import { useAuth } from "../../../contexts/auth";

export default function MonitorationBlockTab(props) {
  const [update, setUpdate] = useState(false)
  const {refreshToken, getRefreshTokenCode, getToken} = useAuth();

  useEffect(() => {
    refreshToken(getToken(), getRefreshTokenCode());
  }, [])

  const renderMonitorationBlock = () => {
    return (
      <MonitorationBlock setUpdate={setUpdate} update={update} />
    )
  };

  const renderMonitorationBlockOpc = () => {
    return (
      <MonitorationBlockOpc setUpdate={setUpdate} update={update} />
    )
  };

  const renderMonitorationBlockGroup = () => {
    return (
      <MonitorationBlockGroup setUpdate={setUpdate} update={update} />
    )
  };

  const renderMonitorationBlockVariable = () => {
    return (
      <MonitorationBlockVariable setUpdate={setUpdate} update={update} />
    )
  };

  const renderMonitorationBlockConfiguration = () => {
    return (
      <MonitorationBlockConfiguration setUpdate={setUpdate} update={update} />
    )
  };

  return (
    <React.Fragment>
      <FavButton/>
      <TabPanel
        className={"tab-panel-margin"}
        animationEnabled={true}
        swipeEnabled={false}
      >
        <Item title="Grupos" render={renderMonitorationBlockGroup} />
        <Item title="OPC" render={renderMonitorationBlockOpc} />
        <Item title="Bloco de Monitoração" render={renderMonitorationBlock} />
        <Item title="Configuração" render={renderMonitorationBlockConfiguration} />
        <Item title="Variável" render={renderMonitorationBlockVariable} />
      </TabPanel>
    </React.Fragment>
  );
};
