/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "./style.scss";
import DataGrid, {
	Column,
	Pager,
	RequiredRule,
	Paging,
	FilterRow,
	Export,
	Selection,
	ColumnChooser,
	Grouping,
	Lookup,
	GroupPanel,
	ColumnFixing,
	CustomRule,
	Editing,
	Form,
	Popup,
	Position,
	Button,
	Toolbar
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import { Item } from "devextreme-react/form";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import dayjs from "dayjs";
import ValidationSummary from "devextreme-react/validation-summary";
import { LogMessageGrid } from "../../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import { useScreenSize } from "../../../utils/media-query";
import { useLoading } from "../../../contexts/loadingContext";
import {convertDate1900} from "../../../utils/convertDate1900";
import Buttons from "devextreme-react/button";
import PopupConfirmModifyGroupAnalysis from "../popupConfirmModifyAnalysis/PopupConfirmModifyGroupAnalysis";

export default function Analysis() {
	const { loading } = useLoading();
	const { unitCod} = useAuth();
	const [load, setLoad] = useState(false);
	const [analysisGroup, setAnalysisGroup] = useState([]);
	const [product, setProduct] = useState([]);
	const [analysis, setAnalysis] = useState([]);
	const [productSituation, setProductSituation] = useState([]);
	const [visiblePopupConfirmModifyAnalysis, setVisiblePopupConfirmModifyAnalysis] = useState(false);
	const { isLarge } = useScreenSize();
	const verification = [
		{ TipVerificacao: 0, NomVerificacao: "Positivo/Negativo" },
		{ TipVerificacao: 1, NomVerificacao: "Intervalo" },
		{ TipVerificacao: 2, NomVerificacao: "Mínimo" },
		{ TipVerificacao: 3, NomVerificacao: "Máximo" },
		{ TipVerificacao: 4, NomVerificacao: "Não Aplicável" },
	];
	const unitPeriod = [
		{ TipUnidadePeriodo: 2, NomUnidadePeriodo: "Minuto" },
		{ TipUnidadePeriodo: 3, NomUnidadePeriodo: "Hora" },
		{ TipUnidadePeriodo: 7, NomUnidadePeriodo: "Especificar na Amostra"},
		{ TipUnidadePeriodo: 4, NomUnidadePeriodo: "Diário" }
	];
	const _analysis = useRef();
	const duplicate = useRef({});
	const columnMaxLengthTen = { maxLength: 10 };
	const _tipLote = useRef(false);
	const _groupChanges = useRef([]);
	const _e = useRef([]);
	const _analysisChange = useRef([]);

	var utc = require("dayjs/plugin/utc");
	dayjs.extend(utc);

	useEffect(() => {
		firstLoad();
	}, []);

	useEffect(() => {
		if (load) {
			awaitRequests();
		}
	}, [load]);

	async function firstLoad() {
	  	await firstRequests()
		await awaitRequests()
	}

	async function firstRequests(){
		loadDataProduct();
		loadDataProductSituation();
		await loadDataAnalysisGroup();
	}

	async function awaitRequests() {
		await loadData();
	}

	function messageLot(data) {
    	let checkLot = true;

		analysisGroup.filter((x) => x.CodAnaliseGrupo === data.CodAnaliseGrupo).forEach((item) => {
			checkLot = item.TipLote === data.TipLote ? true : false
			//e.row.data.TipLote = item.TipLote
		})

		return checkLot;
	}

	async function loadData() {
		await api
			.get("/analysis", { params: { unitCod: unitCod } })
			.then((response) => {
				setAnalysis(response.data);
				setLoad(false);
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function loadDataAnalysisGroup() {
		await api
			.get("/analysisGroup", { params: { unitCod: unitCod } })
			.then((response) => {
				setAnalysisGroup(response.data);
				//setLoad(false);
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function loadDataProduct() {
		await api
			.get("/product/getproductName", { params: { unitCod: unitCod } })
			.then((response) => {
				setProduct(response.data);
				//setLoad(false);
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function loadDataProductSituation() {
		await api
			.get("/registrationAnalysis", { params: { codProduct: 1 } })
			.then((response) => {
				setProductSituation(response.data);
				//setLoad(false);
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function updateRow(data, parLogMessage) {
		return await api
			.put("/analysis", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then((response) => {
				setLoad(true);

				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function insertRow(data, parLogMessage) {
		return await api
			.post("/analysis", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then((response) => {
				setLoad(true);

				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function deleteRow(data) {
		return await api
			.delete("/analysis", { data: data })
			.then((response) => {
				setLoad(true);

				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	function checkUniqueKey(params) {
		return !analysis.some(unit => (
			unit.CodAnaliseGrupo === params.data.CodAnaliseGrupo &&
			unit.CodAnalise !== params.data.CodAnalise &&
			unit.NomAnalise === params.data.NomAnalise &&
			unit.CodProduto === params.data.CodProduto &&
			unit.CodAnaliseProduto === params.data.CodAnaliseProduto &&
			unit.CodTipo === params.data.CodTipo
		))
	}

	function cloneIconClick(e) {
		let clonedItem = {
			...e.row.data,
			CodUnidadeEmpresa: unitCod
		};
		delete clonedItem["CodAnalise"]

		duplicate.current = clonedItem;
		_analysis.current.instance.addRow()
		e.event.preventDefault();
	}

	function checkValuePerioid(params){
		if(params.data.TipLote)
			return true;

		if((!params.data.TipLote && (params.value || params.data.TipUnidadePeriodo === 7)) || params.data.TipUnidadePeriodo === 4)
			return true;
		
		if(!params.data.TipLote && !params.value && params.data.TipUnidadePeriodo !== 7)
			return false;
	}

	function checkDates(params) {
		let check = true;

		if(!params.TipLote && params.TipUnidadePeriodo !== 7 && params.TipUnidadePeriodo !== 4)
			check = dayjs(params.DatIniciaAnalise).isBefore(dayjs(params.DatFinalizaAnalise));

		return check;
	}

	function checkValMinMax(params) {
		let check = true;

		if (params.data.TipVerificacao === 1)
			//Intervalo
			check =
				params.data.ValMinimo < params.data.ValMaximo && params.data.ValMinimo !== null && params.data.ValMaximo !== null ? true : false;

		if (params.data.TipVerificacao === 2) {
			//Minimo
			check = params.data.ValMinimo !== null ? true : false;
		}

		if (params.data.TipVerificacao === 3)
			//Maximo
			check = params.data.ValMaximo !== null ? true : false;

		return check;
	}

	function checkGroupUnit(params) {
		const analysisFilter = [...analysis].filter(unit => (
			unit.CodAnaliseGrupo === params.data.CodAnaliseGrupo
			&& unit.CodAnalise !== params.data.CodAnalise
			&& unit.TipUnidadePeriodo !== params.value
			&& (
				(unit.TipUnidadePeriodo === 7 || unit.TipUnidadePeriodo === 4)
				||
				(params.value === 7 || params.value === 4)
			)
		))

		const group = analysisGroup.find(e => e.CodAnaliseGrupo === params.data.CodAnaliseGrupo);
		if(analysisFilter.length > 0){
			if(_groupChanges.current.includes(group?.NomAnaliseGrupo))
				return true
			
			_groupChanges.current.push(group?.NomAnaliseGrupo);
		}
		else 
			_groupChanges.current = _groupChanges.current.filter(e => e !== group?.NomAnaliseGrupo)

		return true
	}

	function setProductValue(rowData, value) {
		rowData.CodTipo = null;
		this.defaultSetCellValue(rowData, value);
	}

	function getFilteredSituations(options) {
		return {
			store: productSituation,
			filter: options.data ? ["CodProduto", "=", options.data.CodProduto] : null,
		};
	}

	function setVerificationValue(newData, value, currentRowData) {
		newData.TipVerificacao = value;

		if (newData.TipVerificacao === 0) {
			newData.ValMinimo = null;
			newData.ValIdeal = 0.0;
			newData.ValMaximo = null;
			newData.ValPositivo = true;
		}

		if (newData.TipVerificacao === 1) {
			newData.ValMinimo = undefined;
			newData.ValIdeal = undefined;
			newData.ValMaximo = undefined;
			newData.ValPositivo = null;
		}

		if (newData.TipVerificacao === 2) {
			newData.ValMinimo = undefined;
			newData.ValIdeal = undefined;
			newData.ValMaximo = null;
			newData.ValPositivo = null;
		}

		if (newData.TipVerificacao === 3) {
			newData.ValMinimo = null;
			newData.ValIdeal = undefined;
			newData.ValMaximo = undefined;
			newData.ValPositivo = null;
		}

		if (newData.TipVerificacao === 4) {
			newData.ValMinimo = null;
			newData.ValIdeal = 0.0;
			newData.ValMaximo = null;
			newData.ValPositivo = null;
		}

		let column = this;
		column.defaultSetCellValue(newData, value);
	}

	function setLoteValue(newData, value, currentRowData) {
		newData.TipLote = value;

		if (newData.TipLote === false && currentRowData.TipUnidadePeriodo !== 7) {
			newData.ValPeriodo = 1;
			newData.TipUnidadePeriodo = 3;
			newData.DatIniciaAnalise = dayjs().startOf("day");
			newData.DatFinalizaAnalise = dayjs().endOf("day");
		} else {
			!newData.TipLote && currentRowData.TipUnidadePeriodo === 7 
				? newData.ValPeriodo = 1
				: newData.ValPeriodo = null;

			if(currentRowData.TipUnidadePeriodo !== 7) 
				newData.TipUnidadePeriodo = null;

			newData.DatIniciaAnalise = null;
			newData.DatFinalizaAnalise = null;
		}

		let column = this;
		column.defaultSetCellValue(newData, value);
	}

	function setPeriodUnit(newData, value, currentRowData) {
		if(value === 7) {
			if(currentRowData.TipLote) 
				newData.ValPeriodo = null;
				
			newData.ValPeriodo = 1	
			newData.DatIniciaAnalise = null;
			newData.DatFinalizaAnalise = null;
		}

		if(value === 4){
			newData.ValPeriodo = null;
			newData.DatIniciaAnalise = null;
			newData.DatFinalizaAnalise = null;
		}

		if(value !== 7 && value !== 4) {
			if(currentRowData.TipLote === false) {
				newData.ValPeriodo = 1;
				newData.TipUnidadePeriodo = 3;
				newData.DatIniciaAnalise = dayjs().startOf("day");
				newData.DatFinalizaAnalise = dayjs().endOf("day");
			} else {
				newData.ValPeriodo = null;
				newData.TipUnidadePeriodo = null;
				newData.DatIniciaAnalise = null;
				newData.DatFinalizaAnalise = null;
			}
		}

		let column = this;
		column.defaultSetCellValue(newData, value);
	}

	const onEditorPreparing = (e) => {
		if (e.dataField === "ValMinimo" && e.parentType === "dataRow")
			e.editorOptions.disabled = e.row.data.TipVerificacao === 1 || e.row.data.TipVerificacao === 2 ? false : true;

		if (e.dataField === "ValIdeal" && e.parentType === "dataRow")
			e.editorOptions.disabled =
				e.row.data.TipVerificacao === 1 || e.row.data.TipVerificacao === 2 || e.row.data.TipVerificacao === 3 ? false : true;

		if (e.dataField === "ValMaximo" && e.parentType === "dataRow")
			e.editorOptions.disabled = e.row.data.TipVerificacao === 1 || e.row.data.TipVerificacao === 3 ? false : true;

		if (e.dataField === "ValPositivo" && e.parentType === "dataRow")
			e.editorOptions.disabled = e.row.data.TipVerificacao === 0 ? false : true;

		if (e.dataField === "ValPeriodo" && e.parentType === "dataRow")
			e.editorOptions.disabled = e.row.data.TipLote;

		if (e.dataField === "ValPeriodo" && e.parentType === "dataRow" && e.row.data.TipUnidadePeriodo === 4)
			e.editorOptions.disabled = true;

		//if (e.dataField === "TipUnidadePeriodo" && e.parentType === "dataRow") e.editorOptions.disabled = e.row.data.TipLote;

		if (e.dataField === "DatIniciaAnalise" && e.parentType === "dataRow") {
			e.editorOptions.disabled = (!e.row.data.TipLote && e.row.data.TipUnidadePeriodo === 7) || e.row.data.TipUnidadePeriodo === 4 ? true : e.row.data.TipLote;
			e.editorOptions.min = dayjs().startOf("day");
			e.editorOptions.max = dayjs().endOf("day");
		}

		if (e.dataField === "DatFinalizaAnalise" && e.parentType === "dataRow") {
			e.editorOptions.disabled = (!e.row.data.TipLote && e.row.data.TipUnidadePeriodo === 7) || e.row.data.TipUnidadePeriodo === 4 ? true : e.row.data.TipLote;
			e.editorOptions.min = dayjs().startOf("day");
			e.editorOptions.max = dayjs().endOf("day");
		}

	};

	const checkBoxOptions = {
		validationMessageMode: "always",
		//width: "300px",
	};

	const checkBoxOptionsPopup = {
		validationMessageMode: "always",
		onContentReady: (e) => _tipLote.current = e.component.option("value")
	}

	const dateBoxHourOptions = {
		displayFormat: "HH:mm",
		useMaskBehavior: true,
		type: "time"
	};

	const lookupOptions = {
		onOpened: (e) => e.component.option("dataSource", _tipLote.current ? unitPeriod.filter(x => x.TipUnidadePeriodo === 7) : unitPeriod)
	};

	function updatedConfirmed() {
		_e.current.forEach((e, index) => {
			if(e.type === "insert"){
				let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _analysis, _analysisChange.current[index]);
				
				e.cancel = insertRow(e.data, strLogMessage);
			}
			if(e.type === "update"){
				let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _analysis, _analysisChange.current[index]);

				e.cancel = updateRow(_analysisChange.current[index], strLogMessage);
			}
			
			e.component.deselectAll();
		})
		clearUpdated();
	}

	function clearUpdated(){
		_groupChanges.current = [];
		_e.current = [];
		_analysisChange.current = [];
	}

	return (
		<React.Fragment>
			<div>
				<PopupConfirmModifyGroupAnalysis 
					visiblePopupConfirmModifyAnalysis={visiblePopupConfirmModifyAnalysis}
					setVisiblePopupConfirmModifyAnalysis={setVisiblePopupConfirmModifyAnalysis}
					updatedConfirmed={updatedConfirmed}
					clearUpdated={clearUpdated}
					groupChanges={_groupChanges.current}
					setLoad={setLoad}
				/>
				<DataGrid
					className={"dx-cards"}
					dataSource={analysis}
					rowAlternationEnabled={true}
					showColumnLines={true}
					allowColumnReordering={true}
					allowColumnResizing={true}
					showBorders={false}
					columnAutoWidth={true}
					columnHidingEnabled={true}
					repaintChangesOnly={false}
					onEditorPreparing={onEditorPreparing}
					onInitNewRow={(e) => {
						if(Object.keys(duplicate.current).length === 0){
							e.data.CodUnidadeEmpresa = unitCod;
							e.data.TipVerificacao = 4;
							e.data.ValPeriodo = 1;
							e.data.TipLote = false;
							e.data.ValIdeal = 0;
							e.data.TipUnidadePeriodo = 3;
							e.data.TipRegistroAtivo = true;
							e.data.DatIniciaAnalise = dayjs().startOf("day").format("YYYY-MM-DDTHH:mm");
							e.data.DatFinalizaAnalise = dayjs().endOf("day").format("YYYY-MM-DDTHH:mm");
						}
						else {
							e.data = duplicate.current;
							duplicate.current = {};
						}
					}}
					headerFilter={{ visible: true }}
					filterPanel={{ visible: true }}
					filterRow={{ visible: true }}
					onRowUpdating={async (e) => {
						const newObj = { ...e.oldData, ...e.newData };
						const result = messageLot(newObj);

						if (!result) {
              				notify("O valor do campo 'Por Lote' está configurado diferente do Grupo de Análise selecionado", "error", 3000)
							e.cancel = true;
						}
						else {
							if (e.newData.DatIniciaAnalise) newObj.DatIniciaAnalise = convertDate1900(e.newData.DatIniciaAnalise)
							if (e.newData.DatFinalizaAnalise) newObj.DatFinalizaAnalise = convertDate1900(e.newData.DatFinalizaAnalise)

							if(!checkDates(newObj)){
								notify(formatMessage("DateInvalid"), "error", 3000)
								e.cancel = true
							}
							else {
								e.type = "update"
								_e.current.push(e);
								_analysisChange.current.push(newObj);

								(_groupChanges.current.length > 0)
									? setVisiblePopupConfirmModifyAnalysis(true)
									: updatedConfirmed();
							}
						}
					}}
					onRowInserting={(e) => {
						const result = messageLot(e.data);

						if (e.data.DatIniciaAnalise) e.data.DatIniciaAnalise = convertDate1900(e.data.DatIniciaAnalise)
						if (e.data.DatFinalizaAnalise) e.data.DatFinalizaAnalise = convertDate1900(e.data.DatFinalizaAnalise)

						if (!result) {
              				notify("O valor do campo 'Por Lote' está configurado diferente do Grupo de Análise selecionado", "error", 3000)
							e.cancel = true;
						} 
						else {
							if(!checkDates(e.data)){
								notify(formatMessage("DateInvalid"), "error", 3000)
								e.cancel = true;
							}
							else{
								e.type = "insert"
								_e.current.push(e);
								_analysisChange.current.push(e.data);

								(_groupChanges.current.length > 0)
									? setVisiblePopupConfirmModifyAnalysis(true)
									: updatedConfirmed();

							}
						}
					}}
					onRowRemoving={(e) => {
						let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _analysis, e.data);
						const data = { unitCod: unitCod, data: e.data, logMessage: strLogMessage };

						e.cancel = deleteRow(data);
						e.component.deselectAll();
					}}
					onFocusedCellChanged={(e) => {
						if(e.column.dataField === "TipUnidadePeriodo") {
							e.row.data.TipLote 
								? e.column.lookup.dataSource = unitPeriod.filter(x => x.TipUnidadePeriodo === 7)
								: e.column.lookup.dataSource = unitPeriod;
						}
					}}
					ref={_analysis}>
					<Editing mode={isLarge ? "batch" : "popup"} allowUpdating={true} allowDeleting={true} allowAdding={false}>
						<Popup
							title="Cadastro de Análises"
							showTitle={true}
							width={700}
							height={850}
							fullScreen={isLarge ? false : true}
						>
							<Position my="center" at="center" of={window} />
						</Popup>
						<Form>
							<Item itemType="group" colCount={4} colSpan={2}>
								<Item colSpan={2} dataField="CodAnaliseGrupo"/>
								<Item colSpan={2} dataField="NomAnalise" />
								<Item colSpan={2} dataField="CodProduto" />
								<Item colSpan={2} dataField="CodTipo" />
								<Item colSpan={2} dataField="TipVerificacao" />
								<Item colSpan={2} dataField="DesUnidade" />
								<Item colSpan={1} dataField="ValMinimo" />
								<Item colSpan={1} dataField="ValMaximo" />
								<Item colSpan={1} dataField="ValIdeal" />
								<Item colSpan={1} dataField="ValPositivo" />
								<Item colSpan={4} dataField="TipLote" editorType={"dxCheckBox"} editorOptions={checkBoxOptionsPopup} />
								<Item colSpan={2} dataField="TipUnidadePeriodo" editorType={"dxLookup"} editorOptions={lookupOptions}/>
								<Item colSpan={2} dataField="ValPeriodo" />
								<Item
									colSpan={2}
									dataField="DatIniciaAnalise"
									editorType={"dxDateBox"}
                    				dataType={"datetime"}
                    				displayFormat={"HH:mm"}
                    				editorOptions={dateBoxHourOptions}
								/>
								<Item
									colSpan={2}
									dataField="DatFinalizaAnalise"
									editorType={"dxDateBox"}
                    				dataType={"datetime"}
                    				displayFormat={"HH:mm"}
                    				editorOptions={dateBoxHourOptions}
								/>
								<Item colSpan={2} dataField="NumOrdem" />
								<Item colSpan={2} dataField="TipRegistroAtivo" />
							</Item>
						</Form>
					</Editing>
					<Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false} />
					<GroupPanel visible={false} />
					<Export enabled={true} fileName="Cadastro_Analise" allowExportSelectedData={true} />
					<ColumnChooser enabled={true} mode="select" />
					<ColumnFixing enabled={false} />
					<Selection mode="multiple" />
					<Paging defaultPageSize={20} />
					<Pager showPageSizeSelector={true} showInfo={true} />
					<FilterRow visible={true} />
					<Toolbar >
						<Item cssClass={'add-container'}>
							<Buttons className={'add-button'} icon={"add"} onClick={() => _analysis.current.instance.addRow()}/>
						</Item>
						<Item name="saveButton"/>
						<Item name="revertButton"/>
						<Item name="exportButton"/>
						<Item name="columnChooserButton"/>
					</Toolbar>
					<Column
						dataField={"CodAnaliseGrupo"}
						caption={"Grupo de Análise"}
						allowSorting={true}
						width={"auto"}
						allowEditing={true}
					>
						<CustomRule message={"Este campo deve ser único"} reevaluate={true} type={"custom"} validationCallback={checkUniqueKey} />
						<Lookup dataSource={analysisGroup} displayExpr={"NomAnaliseGrupo"} valueExpr={"CodAnaliseGrupo"} />
						<RequiredRule />
					</Column>
					<Column dataField={"NomAnalise"} caption={"Nome da Análise"} allowSorting={true} width={"auto"}>
						<CustomRule message={"Este campo deve ser único"} reevaluate={true} type={"custom"} validationCallback={checkUniqueKey} />
						<RequiredRule />
					</Column>
					<Column dataField={"CodProduto"} caption={"Produto | Insumo"} allowSorting={true} width={"auto"} setCellValue={setProductValue}>
						<Lookup dataSource={product} displayExpr={"NomProduto"} valueExpr={"CodProduto"} />
						<CustomRule message={"Este campo deve ser único"} reevaluate={true} type={"custom"} validationCallback={checkUniqueKey} />
						<RequiredRule />
					</Column>
					<Column dataField={"CodTipo"} caption={"Situação"} allowSorting={true} width={"auto"}>
						<CustomRule message={"Este campo deve ser único"} reevaluate={true} type={"custom"} validationCallback={checkUniqueKey} />
						<Lookup dataSource={getFilteredSituations} displayExpr={"NomTipo"} valueExpr={"CodTipo"} />
					</Column>
					<Column
						dataField={"TipVerificacao"}
						caption={"Verificação"}
						allowSorting={true}
						width={"auto"}
						setCellValue={setVerificationValue}>
						<Lookup dataSource={verification} displayExpr={"NomVerificacao"} valueExpr={"TipVerificacao"} />
						<RequiredRule />
					</Column>
					<Column 
						dataField={"DesUnidade"} 
						caption={"Unidade"} 
						allowSorting={true} 
						width={"auto"}
						editorOptions={columnMaxLengthTen}
					>
						<RequiredRule />
					</Column>
					<Column dataField={"ValMinimo"} caption={"Valor Mínimo"} dataType={"number"} allowSorting={true} width={"auto"}>
						<CustomRule
							message={"Campo Obrigatório. Se for intervalo, valor máximo > valor mínimo"}
							reevaluate={true}
							validationCallback={checkValMinMax}
						/>
					</Column>
					<Column dataField={"ValMaximo"} caption={"Valor Máximo"} dataType={"number"} allowSorting={true} width={"auto"}>
						<CustomRule
							message={"Campo Obrigatório. Se for intervalo, valor máximo > valor mínimo"}
							reevaluate={true}
							validationCallback={checkValMinMax}
						/>
					</Column>
					<Column dataField={"ValIdeal"} caption={"Valor Ideal"} dataType={"number"} allowSorting={true} width={"auto"}>
						<RequiredRule />
					</Column>
					<Column
						dataField={"ValPositivo"}
						caption={"Positivo"}
						allowSorting={true}
						sortIndex={0}
						dataType={"boolean"}
						width={"auto"}></Column>
					<Column
						dataField={"TipLote"}
						caption={"Por Lote"}
						allowSorting={true}
						sortIndex={0}
						dataType={"boolean"}
						width={"auto"}
						setCellValue={setLoteValue}
						editorOptions={checkBoxOptions}>
					</Column>
					<Column
						dataField={"TipUnidadePeriodo"}
						caption={"Unidade Período"}
						allowSorting={true}
						sortIndex={0}
						dataType={"number"}
						width={"auto"}
						setCellValue={setPeriodUnit}
					>
						<CustomRule reevaluate={true} type={"custom"} validationCallback={checkGroupUnit} />
						<Lookup 
							dataSource={unitPeriod} 
							displayExpr={"NomUnidadePeriodo"} 
							valueExpr={"TipUnidadePeriodo"} 
							allowClearing={true}
						/>
					</Column>
					<Column
						dataField={"ValPeriodo"}
						caption={"Período"}
						allowSorting={true}
						sortIndex={0}
						dataType={"number"}
						width={"auto"}>
						<CustomRule message={"Este campo é obrigatório quando Por Lote estiver desmarcado"} reevaluate={true} type={"custom"} validationCallback={checkValuePerioid} />
					</Column>
					<Column
						dataField={"DatIniciaAnalise"}
						caption={"Início Análise"}
						allowSorting={true}
						sortIndex={0}
						dataType={"datetime"}
						width={"auto"}
						format={"shortTime"}>
					</Column>
					<Column
						dataField={"DatFinalizaAnalise"}
						caption={"Final Análise"}
						allowSorting={true}
						sortIndex={0}
						dataType={"datetime"}
						width={"auto"}
						format={"shortTime"}>
					</Column>
					<Column dataField={"NumOrdem"} caption={"Ordem"} allowSorting={true} sortIndex={0} width={"auto"}>
						<RequiredRule />
					</Column>
					<Column 
						dataField={"TipRegistroAtivo"} 
						caption={"Ativo"} 
						allowSorting={true} 
						sortIndex={0} 
						dataType={"boolean"} 
						width={"auto"}
					>
					</Column>
					<Column 
						type="buttons"
						allowSorting={true}
						width={90}
						format={"shortTime"}
					>
						<Button hint="Duplicar" icon="copy" onClick={cloneIconClick} />
						<Button name="edit" />
          				<Button name="delete" />
					</Column>
				</DataGrid>
			</div>
			<div>
				<ValidationSummary id="summary"></ValidationSummary>
			</div>
			<div className="load-indicator">
				<LoadIndicator visible={loading} height={40} width={40} />
			</div>
		</React.Fragment>
	);
}
