import React, {useEffect, useState, useRef} from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import {LoadIndicator} from "devextreme-react/load-indicator";
import "../style.scss";
import DataGrid, {
    Column,
    Pager,
    RequiredRule,
    Paging,
    FilterRow,
    Export,
    Selection,
    ColumnChooser,
    Grouping,
    GroupPanel,
    ColumnFixing,
    CustomRule,
    Toolbar,
    Item,
} from "devextreme-react/data-grid";
import {formatMessage} from "devextreme/localization";
import notify from "devextreme/ui/notify";
import {api} from "../../../services/api";
import {useAuth} from "../../../contexts/auth";
import {LogMessageGrid} from "../../../utils/logMessage";
import {useLoading} from "../../../contexts/loadingContext";
import Button from "devextreme-react/button";

export default function Permision(props) {
    const {loading} = useLoading();
    const {refreshToken, getRefreshTokenCode, getToken} = useAuth();
    const [load, setLoad] = useState(false);
    const [permission, setPermission] = useState([]);
    const [company, setCompany] = useState([]);
    const _permission = useRef();

    //Autenticação requests
    const {unitCod} = useAuth();

    useEffect(() => {
        firstLoad();
    }, []);

    useEffect(() => {
        if (load) {
            updateLoad()
        }
    }, [load]);

    async function firstLoad() {
        if (unitCod !== undefined) {
            loadData();
            loadDataUserCompanyUnit();
            refreshToken(getToken(), getRefreshTokenCode());
        }
    }

    async function updateLoad() {
        loadData();
        //loadDataUserCompanyUnit();
    }

    async function loadData() {
        await api
            .get("/permission", {params: {unitCod: unitCod}})
            .then((response) => {
                setPermission(response.data);
                setLoad(false);

                return false;
            })
            .catch((error) => {
                notify(formatMessage("UpdateError"), "error", 3000);
                setLoad(true);

                return true
            });
    }

    async function loadDataUserCompanyUnit() {
        await api
            .get("/companyUnit", {params: {unitCod: 0}})
            .then((response) => {
                setCompany(response.data);
                setLoad(false);
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function updateRow(data, parLogMessage) {
        return await api
            .put("/permission", {unitCod: unitCod, data: data, logMessage: parLogMessage})
            .then((response) => {
                setLoad(true);
                props.setUpdate(!props.update)

                return false;
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function insertRow(data, parLogMessage) {
        return await api
            .post("/permission", {unitCod: unitCod, data: data, logMessage: parLogMessage})
            .then((response) => {
                setLoad(true);
                props.setUpdate(!props.update)

                return false;
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    async function deleteRow(data) {
        return await api
            .delete("/permission", {data: data})
            .then((response) => {
                setLoad(true);
                props.setUpdate(!props.update)

                return false;
            })
            .catch((error) => {
                setLoad(true);

                return true;
            });
    }

    function checkUniqueKey(params) {
        let check = true;

        permission.forEach((unit) => {
            if (unit.CodPermissao !== params.data.CodPermissao &&
                unit.NomPermissao === params.data.NomPermissao
            ) check = false;
        })

        return check;
    }

    /*const onToolbarPreparing = (e) => {
        var dataGrid = e.component;

        e.toolbarOptions.items.unshift({
            location: "after",
            widget: "dxButton",
            options: {
                elementAttr: {id: 'ToolbarAddNewButton'},
                hint: "Adicionar um registro",
                icon: "add",
                onClick: () => { dataGrid.addRow() }
            },
        });
    }*/

    return (
        <React.Fragment>
            <DataGrid
                dataSource={permission}
                rowAlternationEnabled={true}
                showColumnLines={true}
                allowColumnReordering={true}
                showBorders={false}
                columnAutoWidth={false}
                columnHidingEnabled={true}
                repaintChangesOnly={true}
                headerFilter={{visible: true}}
                filterPanel={{visible: true}}
                filterRow={{visible: false}}
                // toolbar={{visible: true, items: ['addRowButton', 'exportButton']}}
                // onToolbarPreparing={(e) => AddButtonGrid(e)}
                onInitNewRow={(e) => {
                    /*e.data.TipRegistroAtivo = true;
                    e.data.ValIdiomaUsuario = 1;*/
                    e.data.CodUnidadeEmpresa = unitCod
                }}
                editing={{
                    refreshMode: "full",
                    mode: "batch",
                    allowUpdating: true,
                    allowDeleting: true,
                    allowAdding: true,
                }}
                onRowUpdating={async (e) => {
                    const newObj = {...e.oldData, ...e.newData};
                    let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _permission, newObj);

                    e.cancel = updateRow(newObj, strLogMessage);

                    e.component.deselectAll();
                }}
                onRowInserting={(e) => {
                    let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _permission, e.data);

                    e.cancel = insertRow(e.data, strLogMessage);

                    e.component.deselectAll();
                }}
                onRowRemoving={(e) => {
                    let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _permission, e.data)
                    const data = {unitCod: unitCod, data: e.data, logMessage: strLogMessage};

                    e.cancel = deleteRow(data);

                    e.component.deselectAll();
                }}
                ref={_permission}
            >
                <Grouping
                    contextMenuEnabled={false}
                    expandMode="rowClick"
                    autoExpandAll={false}
                />
                <GroupPanel visible={false}/>
                <Export
                    enabled={true}
                    fileName="Permissões"
                    allowExportSelectedData={true}
                />
                <ColumnChooser enabled={true} mode="select"/>
                <ColumnFixing enabled={false}/>
                <Selection mode="multiple"/>
                <Paging defaultPageSize={20}/>
                <Pager showPageSizeSelector={true} showInfo={true}/>
                <FilterRow visible={true}/>
                <Toolbar >
                    <Item cssClass={'add-container'}>
                        <Button className={'add-button'}  icon={"add"} onClick={() => (_permission.current.instance.addRow())}/>
                    </Item>
                    <Item name="saveButton"/>
                    <Item name="revertButton"/>
                    <Item name="exportButton"/>
                    <Item name="columnChooserButton"/>
                </Toolbar>
                <Column
                    dataField={"NomPermissao"}
                    caption={"Permissão"}
                    allowSorting={true}
                    allowEditing={true}
                    width={"60%"}
                >
                    <CustomRule
                        message={'Este campo deve ser único'}
                        reevaluate={true}
                        type={"custom"}
                        validationCallback={checkUniqueKey}
                    />
                    <RequiredRule/>
                </Column>
            </DataGrid>
            <div className="load-indicator">
                <LoadIndicator visible={loading} height={40} width={40}/>
            </div>
        </React.Fragment>
    )

};
