import tool from './icons/tool18dp.svg'
import transfer from './icons/transfer18dp.svg'
import lab from './icons/lab18dp.svg'
import Bell from './icons/bell.svg'
import register from './icons/register.svg'

export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home',
    items: [],
    id: [undefined],
  },
  {
    text: 'Administração',
    icon: 'user',
    items: [
      {
        text: 'Aplicação',
        path: '/aplication',
        id: [8230],
      },
      {
        text: 'Empresa e Unidade',
        path: '/companyandunit',
        id: [8210],
      },
      {
        text: 'Setor, Processo e Área',
        path: '/sectorprocessarea',
        id: [8220],
      },
      /*{
        text: 'Acesso de Dados',
        path: '/dataacess',
        id: [8250]
      },*/
      {
        text: 'Programa',
        path: '/users',
        id: [9210],
      },
      {
        text: 'Configuração OPC',
        path: '/configurationOPC',
        id: [9310],
      },
      {
        text: 'Bloco de Monitoração',
        path: '/monitorationBlock',
        id: [8260],
      },
    ],
  },
  {
    text: 'Cadastros',
    icon: register,
    items: [
      {
        text: 'Permissões',
        path: '/permission',
        id: [9130, 9140, 9150, 9160, 9170],
      },
      {
        text: 'Usuários',
        path: '/user',
        id: [9110],
      },
      /*{
        text: 'Recebimento',
        path: '/receipt',
        id: 4310
      },
      {
        text: 'Transporte',
        path: '/transport',
        id: 4220
      },*/
      {
        text: 'Produto',
        path: '/product',
        id: [8110],
      },
      /*{
        text: 'Estoque',
        path: '/productstock',
        id: 8118
      },*/
      {
        text: 'Unidade de Medida',
        path: '/measure',
        id: [8114],
      },
      {
        text: 'Localidade',
        path: '/locality',
        id: [8120],
      },
      {
        text: 'Dicas',
        path: '/tip',
        id: [8240],
      },
      {
        text: 'Email',
        path: '/email',
        id: [4330],
      },
      {
        text: 'Turnos',
        path: '/shifts',
        id: [9320],
      },
      {
        text: 'Indicadores',
        path: '/indicators',
        id: [9340],
      },
      {
        text: 'Bloco de Monitoração Meta',
        path: '/goals',
        id: [8420],
      },
      {
        text: 'Guias Rápidos',
        path: '/quickguides',
        id: [5110],
      },
      {
        text: 'Relatório Diário',
        path: '/dailyreport',
        id: [8121]
      },
      {
        text: 'Equipamento',
        path: '/equipment',
        id: [8510],
      },
      {
        text: 'Dashboard Configuração',
        path: '/dashboardConfiguration',
        id: [8610],
      },
    ],
  },
  {
    text: 'Aquisição de Dados',
    icon: transfer,
    items: [
      {
        text: 'Entrada Variáveis por Data',
        path: '/variableInputByDate',
        id: [1110],
      },
      {
        text: 'Entrada Variáveis por Variáveis',
        path: '/variableInputByVariable',
        id: [1120],
      },
      {
        text: 'Cadastro de Variáveis',
        path: '/variablesTab',
        id: [8310],
      },
      {
        text: 'Aprovar Pontos Críticos',
        path: '/approveCriticalPoint',
        id: [1140],
      },
    ],
  },
  {
    text: 'Laboratório',
    icon: lab,
    items: [
      {
        text: 'Amostras',
        path: '/sample',
        id: [2110],
      },
      {
        text: 'Permissão de Análises',
        path: '/permissionLaboratory',
        id: [2220],
      },
      {
        text: 'Cadastro de Análises',
        path: '/analysis',
        id: [2210],
      },
    ],
  },
  {
    text: 'Paradas',
    icon: tool,
    items: [
      {
        text: 'Cadastro Tipo/Motivos',
        path: '/reasonType',
        id: [1134],
      },
      {
        text: 'Área',
        path: '/areaProcessStop',
        id: [1132],
      },
      {
        text: 'Processo',
        path: '/processStop',
        id: [1130],
      },
    ],
  },
  {
    text: 'Tarefas',
    icon: Bell,
    items: [
      {
        text: 'Criar Tarefa',
        path: '/createTask',
        id: [3210],
      },
      {
        text: 'Executar Tarefa',
        path: '/executeTask',
        id: [3110],
      },
    ],
  },
  {
    text: '',
    items: [],
    visible: false
  },
  /*{
    text: 'Produções',
    icon: receipt,
    items: [
      {
        text: 'Grupo',
        path: '/productionGroup',
        id: 4230
      },
      {
        text: 'Entrada Direta',
        path: '/directInput',
        id: 4230
      }
    ]
  },*/
]
