/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "../administration/style.scss";
import "devextreme/data/odata/store";
import "../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import DataGrid, {
	Column,
	Pager,
	RequiredRule,
	Paging,
	FilterRow,
	Export,
	ColumnChooser,
	Grouping,
	GroupPanel,
	ColumnFixing,
	Selection,
	Editing,
	Form,
	Popup,
	Position,
	Item, Toolbar,
} from "devextreme-react/data-grid";
import { formatMessage } from "devextreme/localization";
import notify from "devextreme/ui/notify";
import { api } from "../../services/api";
import dayjs from "dayjs";
import { useAuth } from "../../contexts/auth";
import { LogMessageGrid } from "../../utils/logMessage";
import { useLoading } from "../../contexts/loadingContext";
import { useScreenSize } from "../../utils/media-query";
import { FavButton } from "../../components/fav-button/favButton";
import Button from "devextreme-react/button";

export default function Tips() {
	const { loading } = useLoading();
	const { refreshToken, getRefreshTokenCode, getToken } = useAuth();
	const [load, setLoad] = useState(false);
	const { isLarge } = useScreenSize();
	const [tips, setTips] = useState([]);
	const _tip = useRef();

	//Autenticação requests
	const { unitCod } = useAuth();

	useEffect(() => {
		async function firstLoad() {
			if (unitCod !== undefined) {
				refreshToken(getToken(), getRefreshTokenCode());
				setLoad(true);
			}
		}
		firstLoad();
	}, []);

	useEffect(() => {
		if (load) {
			loadData();
		}
	}, [load]);

	async function loadData() {
		await api
			.get(`/tip/?unitCod=${unitCod}`)
			.then((response) => {
				setTips(response.data);
				setLoad(false);
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function updateRow(data, parLogMessage) {
		return await api
			.put("/tip", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then((response) => {
				setLoad(true);

				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function insertRow(data, parLogMessage) {
		return await api
			.post("/tip", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then((response) => {
				setLoad(true);

				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	async function deleteRow(data) {
		return await api
			.delete("/tip", { data: data })
			.then((response) => {
				setLoad(true);

				return false;
			})
			.catch((error) => {
				setLoad(true);

				return true;
			});
	}

	/*const onToolbarPreparing = (e) => {
      var dataGrid = e.component;

      e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
              elementAttr: {id: 'ToolbarAddNewButton'},
              hint: "Adicionar uma dica",
              icon: "add",
              onClick: () => { dataGrid.addRow() }
          },
      });
  }*/

	function setCellValueDateInitial(newData, value, currentRowData) {
		newData.DatInicialDica = value;
		newData.DatFinalDica = currentRowData.DatFinalDica;
		newData.CodDica = currentRowData.CodDica;
		newData.CodUnidadeEmpresa = currentRowData.CodUnidadeEmpresa;

		checkDate(newData.DatInicialDica, newData.DatFinalDica);
	}

	function setCellValueDateFinal(newData, value, currentRowData) {
		newData.DatFinalDica = value;
		newData.DatInicialDica = currentRowData.DatInicialDica;
		newData.CodDica = currentRowData.CodDica;
		newData.CodUnidadeEmpresa = currentRowData.CodUnidadeEmpresa;

		checkDate(newData.DatInicialDica, newData.DatFinalDica);
	}

	const checkDate = (initialDate, endDate) => {
		const diff = dayjs(endDate).diff(dayjs(initialDate), "second");

		if (diff <= 0) {
			notify(formatMessage("DateInvalid"), "error", 2000);
		}
	};

	return (
		<React.Fragment className={"OnlyGrid"}>
			<div className={"ContainerButtons"}>
				<FavButton/>
			</div>

			{/* <h2 className="content-block">Dicas</h2> */}
			<div id="tipGrid">
				<DataGrid
					className={"dx-cards"}
					dataSource={tips}
					rowAlternationEnabled={true}
					showColumnLines={true}
					allowColumnReordering={true}
					showBorders={false}
					columnAutoWidth={false}
					columnHidingEnabled={true}
					repaintChangesOnly={true}
					onInitNewRow={(e) => {
						e.data.CodUnidadeEmpresa = unitCod;
						e.data.DatInicialDica = dayjs();
						e.data.DatFinalDica = dayjs().add(1, "day");
					}}
					headerFilter={{ visible: true }}
					filterPanel={{ visible: true }}
					filterRow={{ visible: false }}
					/* editing={{
            refreshMode: "full",
            mode:"batch",
            allowUpdating: true,
            allowDeleting: true,
            allowAdding: false,
          }} */
					onRowUpdating={async (e) => {
						const newObj = { ...e.oldData, ...e.newData };
						let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _tip, newObj);

						e.cancel = updateRow(newObj, strLogMessage);

						e.component.deselectAll();
					}}
					onRowInserting={(e) => {
						let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _tip, e.data);

						e.cancel = insertRow(e.data, strLogMessage);

						e.component.deselectAll();
					}}
					onRowRemoving={(e) => {
						let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _tip, e.data);
						const data = { unitCod: unitCod, data: e.data, logMessage: strLogMessage };

						e.cancel = deleteRow(data);

						e.component.deselectAll();
					}}
					onRowValidating={(e) => {
						if (e.newData.DatFinalDica && e.newData.DatInicialDica) {
							const diff = dayjs(e.newData.DatFinalDica).diff(e.newData.DatInicialDica, "second");

							if (diff <= 0) {
								e.isValid = false;
								notify(formatMessage("DateInvalid"), "error", 2000);
							}
							return false;
						}

						e.isValid = true;
					}}
					ref={_tip}>
					<Editing mode={isLarge ? "batch" : "popup"} allowUpdating={true} allowDeleting={true} allowAdding={false}>
						<Popup title="Cadastro de Dica" showTitle={true} width={700} height={750} fullScreen={true}>
							<Position my="center" at="center" of={window} />
						</Popup>
						<Form>
							<Item itemType="group" colCount={4} colSpan={2}>
								<Item colSpan={2} dataField="DesDica" />
								<Item
									colSpan={2}
									dataField="DatInicialDica"
									editorType={"dxDateBox"}
									dataType={"date"}
								/>
                <Item
									colSpan={2}
									dataField="DatFinalDica"
									editorType={"dxDateBox"}
									dataType={"date"}
								/>
							</Item>
						</Form>
					</Editing>
					<Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false} />
					<GroupPanel visible={false} />
					<Export enabled={true} fileName="Dicas" allowExportSelectedData={true} />
					<ColumnChooser enabled={true} mode="select" />
					<ColumnFixing enabled={false} />
					<Selection mode="multiple" />
					<Paging defaultPageSize={20} />
					<Pager showPageSizeSelector={true} showInfo={true} />
					<FilterRow visible={true} />
					<Toolbar >
						<Item cssClass={'add-container'}>
							<Button className={'add-button'}  icon={"add"} onClick={() => (_tip.current.instance.addRow())}/>
						</Item>
						<Item name="saveButton"/>
						<Item name="revertButton"/>
						<Item name="exportButton"/>
						<Item name="columnChooserButton"/>
					</Toolbar>
					<Column dataField={"DesDica"} caption={"Dica"} allowSorting={true} sortIndex={0} width="60%">
						<RequiredRule />
					</Column>
					<Column
						dataField={"DatInicialDica"}
						caption={"Início Apresentação"}
						dataType={"date"}
						allowSorting={true}
						width="20%"
						setCellValue={setCellValueDateInitial}>
						<RequiredRule />
					</Column>
					<Column
						dataField={"DatFinalDica"}
						caption={"Final Apresentação"}
						dataType={"date"}
						allowSorting={true}
						width="20%"
						setCellValue={setCellValueDateFinal}>
						<RequiredRule />
					</Column>
				</DataGrid>
			</div>
			<div className="load-indicator">
				<LoadIndicator visible={loading} height={40} width={40} />
			</div>
		</React.Fragment>
	);
}
