import React, { useState, createContext, useContext } from "react";

const LoadingContext = createContext({});
const useLoading = () => useContext(LoadingContext);

function LoadingProvider(props) {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading,
      }}
      {...props}
    />
  );
}

export { LoadingProvider, useLoading };
