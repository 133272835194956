import React, { useEffect, useRef, useState } from "react"
import "devextreme/data/odata/store"
import "../common/style.scss"
import { DateBox, Button as DateBoxButton } from "devextreme-react/date-box"
import { Button as TextBoxButton } from "devextreme-react/text-box";
import { TextBox } from 'devextreme-react';
import { SelectBox } from 'devextreme-react';
import { api } from "../../services/api"
import { Button } from "devextreme-react"
import dayjs from "dayjs"
import { useAuth } from "../../contexts/auth"
import { useDataLoader } from "../../contexts/dataLoaderContext"
import { formatMessage } from "devextreme/localization"
import { useScreenSize } from "../../utils/media-query"
import "./header-datebox.scss"
import notify from "devextreme/ui/notify";
import PopupCheckSample from "../../pages/laboratory/sampleInputs/popUpCheckSamples";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  const millisecondsInDay = 24 * 60 * 60 * 1000
  const { isXSmall, isLarge } = useScreenSize()
  const { unitCod } = useAuth()
  const _searchTextBox = useRef()
  const _valueDateChanged = useRef(0);
  const _buttonDateClicked = useRef("");
  const { _lstInputVariableSave, setSaveHeaderTextBox, saveHeaderTextBox, setChangedDateHeader, listToSaveSample, objectImgToDataBase, _lstTimeListAnalysis } = useDataLoader()
  const [visiblePopupCheckSample, setVisibleCheckSample] = useState(false);
  const [responsePopupCheckSample, setResponsePopupCheckSample] = useState(false);
  const objDictionaryLogSample = {
    Insert: formatMessage("InsertedSample"),
    Update: formatMessage("UpdatedSample"),
    Delete: formatMessage("DeletedSample")
  }

  const objDictionaryLogVariable = {
    Insert: formatMessage("InsertedVariable"),
    Update: formatMessage("UpdatedVariable"),
    Delete: formatMessage("DeletedVariable")
  }

  useEffect(() => {
    _searchTextBox.current.instance.option("value", null)
  }, [props.screen])

  useEffect(() => {
    if(responsePopupCheckSample) {
      if(_buttonDateClicked.current === "todayButton")
        goToToday()

      if(_buttonDateClicked.current === "prevDateButton")
        goToPrevDate()

      if(_buttonDateClicked.current === "nextDateButton")
        goToNextDate()

      if(_buttonDateClicked.current === "onDateChanged") 
        goToDateSelected(_valueDateChanged.current)

      if(_buttonDateClicked.current === "onButtonBackClicked")
        goToBack()

      listToSaveSample.current = [];
      setResponsePopupCheckSample(false);
    } 
  }, [responsePopupCheckSample])

  //#region Request
  async function PrcSaveVariable() {
    await api
      .post("/readingVariableManual/saveVariableInputsManual", {
        parUnitCod: unitCod,
        parLstSaveVariablesManual: _lstInputVariableSave.current,
        dictionaryLog: objDictionaryLogVariable
      })
      .then(response => {
        _lstInputVariableSave.current = []
        setSaveHeaderTextBox(true)
        return false
      })
      .catch(error => {
        return true
      })
  }

  async function PrcSaveSample() {
    await api
      .post("/analysisSample/insertupdate", {
        add: listToSaveSample.current,
        unitCod: unitCod,
        dictionaryLog: objDictionaryLogSample,
        images: objectImgToDataBase.current
      })
      .then(response => {
        props.setLoading(false)
        listToSaveSample.current.length = 0
        objectImgToDataBase.current.ImgBase64 = null
        objectImgToDataBase.current.DesObservacao = null
        setSaveHeaderTextBox(true)
        return false
        //boolReanalysis.current = false;
        //setListLots(response.data)
        //setCurrencyValueDate(dayjs().valueOf());
      })
      .catch(error => {
        console.log(error)
        props.setLoading(false)
        return true
      })
  }
  //#endregion

  //#region DateBox
  const todayButton = {
    text: "Hoje",
    visible: !isXSmall,
    onClick: () => {
      if(listToSaveSample.current.length === 0 || !window.location.href.includes("sample")) {
        goToToday();
      } else {
        setVisibleCheckSample(listToSaveSample.current.length > 0);
        _buttonDateClicked.current = "todayButton";
      }
    }
  }

  const prevDateButton = {
    icon: "spinprev",
    stylingMode: "text",
    onClick: e => {
      if(listToSaveSample.current.length === 0 || !window.location.href.includes("sample")) {
        goToPrevDate()
      } else {
        setVisibleCheckSample(listToSaveSample.current.length > 0);
        _buttonDateClicked.current = "prevDateButton";
      }
    }
  }

  const nextDateButton = {
    icon: "spinnext",
    stylingMode: "text",
    onClick: () => {
      if(listToSaveSample.current.length === 0 || !window.location.href.includes("sample")) {
        goToNextDate();
      } else {
        setVisibleCheckSample(listToSaveSample.current.length > 0);
        _buttonDateClicked.current = "nextDateButton";
      }
    }
  }

  function goToToday() {
    if (props.sampleScreen) 
      props.setLoading(true)
    setChangedDateHeader(true)
    props.setCurrencyValueDate(dayjs().valueOf())
  }

  function goToPrevDate() {
    if (props.sampleScreen) 
      props.setLoading(true)
    setChangedDateHeader(true)
    props.setCurrencyValueDate(props.currencyValueDate - millisecondsInDay)
  }

  function goToNextDate() {
    if (props.sampleScreen) 
      props.setLoading(true)
    setChangedDateHeader(true)
    props.setCurrencyValueDate(props.currencyValueDate + millisecondsInDay)
  }

  function goToDateSelected(value) {
    setChangedDateHeader(true)
    props.setCurrencyValueDate(value)
    _valueDateChanged.current = 0
  }

  function goToBack() {
    if (props.byDate) {
      props.setScreen('back')
    }
    else {
      props.setLoad(!props.load)
    }
  }
  //#endregion

  //#region Events
  const onValueChanged = e => {
    if(e.value !== null){
      if(props.screen === "process"){
        //Usado no programa de Amostras e de Entrada de variáveis
        props.setSearchProcessText(e.value)
      }
      else if (props.screen === "input" && props.byDate){
        props.setProcessCodeFilter(e.selectedItem.CodProcesso)
        props.setLoad(!props.load)
      }
      else{
        if(props.sampleScreen){
          //Usado no programa de Amostras
          props.setSearchAnalysisGroupText(e.value)
        } else{
          //Usado no programa de Entrada de Variáveis
          props.setSearchVariablesText(e.value)
        }
      }
    }
  }

  function onDateChanged(e){
    if(listToSaveSample.current.length === 0 || !window.location.href.includes("sample")) {
      goToDateSelected(e.value)
    } else {
      if(_valueDateChanged.current === 0) 
        _valueDateChanged.current = e.value
      _buttonDateClicked.current = "onDateChanged"

      setVisibleCheckSample(listToSaveSample.current.length > 0)
    }
  }
  //#endregion

  return (
    <div className="header-form fixed-bar">
      <div className="form-container">
        <div style={{ width: "100%" }} className="margin-element">
          <div
            className="dx-field-label"
            style={{
              marginTop: props.screen === "input" ? "-12px" : "-22px",
              float: "left",
              width: 50,
              display: props.screen === "process" || (props.byDate && props.screen === "process") ? "none" : "inline"
            }}
          >
            <Button
              style={{ float: "left" }}
              type={"back"}
              icon={"chevronleft"}
              className="chevronleft"
              onClick={() => {
                if(listToSaveSample.current.length === 0 || !window.location.href.includes("sample")) {
                  goToBack()
                } else {
                  setVisibleCheckSample(listToSaveSample.current.length > 0);
                  _buttonDateClicked.current = "onButtonBackClicked";
                }
              }}
            />
          </div>
          <div style={{ minWidth: "17%", float: "left", display: props.screen === "input" && !props.byDate ? "inline" : "none" }}>
            <div className="dx-field-label">Data</div>
            <DateBox
              value={props.currencyValueDate}
              stylingMode="outlined"
              width={isXSmall ? 200 : 260}
              height={45}
              onValueChanged={onDateChanged}
              max={dayjs()}
              ref={props.refHeaderDateBoxValue}
            >
              <DateBoxButton name="today" location="before" options={todayButton} />
              <DateBoxButton name="prevDate" location="before" options={prevDateButton} />
              <DateBoxButton name="nextDate" location="after" options={nextDateButton} />
              <DateBoxButton name="dropDown" />
            </DateBox>
          </div>
          <div
            style={{
              minWidth: "30%",
              maxWidth: isXSmall ? "70vw" : "100vw",
              marginLeft: 20,
              float: isXSmall ? "none" : "left",
              display: props.screen !== "input" || (props.screen === "input" && props.byDate) ? isXSmall ? "inherit" : "inline" : "none"
            }}
          >
            <div className={"search-bar-container"}>
              <div className={isLarge ? "search-bar marginLeft" : "search-bar-medium marginLeft"}>
                <TextBox
                    id="searchTextVariable"
                    label={'Pesquisar...'}
                    showClearButton={true}
                    ref={_searchTextBox}
                    visible={
                      (props.screen === "process" ||
                      (props.screen === "variable" && props.secondaryButtons) ||
                      (props.screen === "variable" && !props.sampleScreen)) &&
                      !(props.screen === "input" && props.byDate)//Quando for Entrada Variavel por Data e está na tela de inputs não aparece 
                    }
                    onValueChanged={(e) => onValueChanged(e)}
                    stylingMode={"outlined"}
                    valueChangeEvent={"keyup"}
                >
                    <TextBoxButton
                        name="clear"
                        location="after"
                    />
                </TextBox>
              </div> 
            </div>
            <SelectBox
              dataSource={props.lstProcess}
              stylingMode="outlined"
              style={{marginLeft: "-30px", marginTop: "15px"}}
              label={"Escolher outro Processo..."}
              searchEnabled={isLarge}
              showClearButton={false}
              onOptionChanged={(e) => {
                //Esvazia o selectbox quando clica para voltar 
                if (!e.component.instance().option("visible")) {
                  e.component.option("value", null)
                }
              }}
              visible={props.screen === "input" && props.byDate}
              ref={props.refHeaderSelectBox}
              onSelectionChanged={onValueChanged}
              displayExpr={"NomProcesso"}
              valueExpr={"CodProcesso"}
              labelMode={"floating"}
            /> 
          </div>
          <div
            style={{
              float: "left",
              marginTop: "6px",
              marginLeft: "20px",
              display: props.screen === "input" || (props.screen === "input" && props.byDate) ? "inline" : "none"
            }}
          >
            <Button
              className="save-button"
              id="searchFilterButton"
              text="Salvar"
              type="default"
              icon="save"
              useSubmitBehavior={false}
              stylingMode="contained"
              onClick={e => {
                if (_lstInputVariableSave.current.length > 0) {
                  //Programa entrada de variaveis
                  PrcSaveVariable()
                } else if (listToSaveSample.current.length > 0) {
                  let result = true;

                  for (let i = 0; i < listToSaveSample.current.length; i++)  {
                    for (let j = 0; j < _lstTimeListAnalysis.current.length; j++) {
                      for(let k = 0; k < _lstTimeListAnalysis.current[j][5].length; k++) {
                        const changed = listToSaveSample.current.find(sample => sample.index === `${j}:${k}` && sample.DatAnaliseAmostra !== sample.PreviousDate);
                        if(!changed) {
                          const search = listToSaveSample.current.find(sample => sample.CodAnalise === _lstTimeListAnalysis.current[j][8].CodAnalise 
                            && dayjs(sample.DatAnaliseAmostra).format('YYYY-MM-DDTHH:mm') === dayjs(_lstTimeListAnalysis.current[j][5][k]).format('YYYY-MM-DDTHH:mm')
                            && sample.index !== `${j}:${k}`)
  
                          if(search !== undefined) {
                            result = false;
                            break;
                          }
                        }
                      }

                      if(!result)
                        break;
                    }

                    const search = listToSaveSample.current.find(sample => sample.CodAnalise === listToSaveSample.current[i].CodAnalise 
                                                                && sample.DatAnaliseAmostra === listToSaveSample.current[i].DatAnaliseAmostra 
                                                                && sample.index !== listToSaveSample.current[i].index)
                    
                    if(search !== undefined) {
                      result = false;
                      break;
                    }
                  }

                  if(result) {
                    //Programa amostras
                    props.setLoading(true)
                    PrcSaveSample()
                  } else {
                    notify('Não é possível salvar pois existem registros selecionados com a mesma data.', 'error', 3000);
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
      <PopupCheckSample 
        visibleCheckSample={visiblePopupCheckSample}
        setVisibleCheckSample={setVisibleCheckSample}
        setResponsePopupCheckSample={setResponsePopupCheckSample}
      />
    </div>
  )
}
