/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect, useLayoutEffect } from "react"
import "devextreme/data/odata/store"
import "../../../components/common/style.scss"
import { Button } from "devextreme-react"
import NumericInputVariable from "./numericInputVariable"
import { api } from "../../../services/api"
import { useDataLoader } from "../../../contexts/dataLoaderContext"
import { useAuth } from "../../../contexts/auth"

export default function ButtonList(props) {
  const [secondaryButtons, setSecondaryButtons] = useState()
  const { unitCod, userCod } = useAuth()
  let _lstVariablesInputs = useRef([])
  const [inputHiHi, setInputHiHi] = useState(0)
  const { _lstInputVariableSave } = useDataLoader()

  //Usado para o selectbox de Processo
  useLayoutEffect(() => {
    _lstInputVariableSave.current = []
    async function loadData() {
      await setSecondaryBt(false)
      props.setScreen("input")
      await getVariableInputsByDate(props.processCodeFilter)
    }

    if (!props.load) loadData()
  }, [props.load])

  //Usado para botão de voltar que aparece no screen "input"
  useEffect(() => {
    async function loadDataBack() {
      if(props.screen === "back") {
        await setSecondaryBt(false)
        props.setScreen("process")
        props.setSearchProcessText("")
      }
    }

    loadDataBack()
  }, [props.screen])

  //#region Requests
  async function getVariableInputsByDate(processCode) {
    await api
      .get("/readingvariablemanual/getVariableInputsByDate", { params: { parUnitCod: unitCod, parUserId: userCod, parProcessCode: processCode } })
      .then(response => {
        try {
          _lstVariablesInputs.current = response.data
        } finally {
          setSecondaryButtons(true)
          props.setLoad(true)
        }
      })
      .catch(() => {
        return true
      })
  }
  //#endregion

  //#region functions
  async function loadVariableInputs(parProcessCode) {
    _lstInputVariableSave.current = []
    props.setScreen("input")
    await getVariableInputsByDate(parProcessCode)
  }

  async function setSecondaryBt(bool) {
    setSecondaryButtons(bool)
  }
  //#endregion

  return (
    <div className="content-block">
      {!secondaryButtons ? (
        <div>
          <h2>Selecionar processo</h2>
          {props.list.map((value, i) => (
            <Button
              key={i}
              className={"process-button"}
              text={props.screen === "process" ? value.NomProcesso : ""}
              stylingMode="outlined"
              searchEnabled={false}
              onClick={() => {
                props.setProcessCodeFilter(value.CodProcesso)
                props.setLoad(false)
              }}
            />
          ))}
        </div>
      ) : (
        <NumericInputVariable
          lstVariables={_lstVariablesInputs.current}
          lstProcess={props.lstProcess}
          setLoad={props.setLoad}
          load={props.load}
          setInputHiHi={setInputHiHi}
          inputHiHi={inputHiHi}
          processCodeFilter={props.processCodeFilter}
          loadVariableInputs={loadVariableInputs}
          setSecondaryBt={setSecondaryBt}
        />
      )}
    </div>
  )
}
