import React from 'react';
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { useAuth } from "../../contexts/auth";
import dayjs from "dayjs";
import { useScreenSize } from '../../utils/media-query';
import "./style.scss";

export default function PopupTip() {
    const { isXSmall, isSmall } = useScreenSize();
    const { setOpenPopupTip, openPopupTip, showTips } = useAuth();

    var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
    dayjs.extend(isSameOrBefore);
    return (
        <Popup
            width={isXSmall ? "80%" : isSmall ? "80%" : 800}
            height={"auto"}
            maxHeight={"80%"}
            visible={openPopupTip}
            showTitle={true}
            title={"DICAS"}
            closeOnOutsideClick={true}
            onHiding={(e) => setOpenPopupTip(false)}
            showCloseButton={true}
        >
            <ScrollView>
                <div> 
                    {showTips.map((tip) => {
                        return (
                            <div key={tip.CodDica}>
                                <div>
                                    <div className="ContainerDate">
                                        <b>{dayjs(tip.DatInicialDica).format('DD/MM/YYYY')} a {dayjs(tip.DatFinalDica).format('DD/MM/YYYY')}</b>
                                    </div>
                                    <div className="ContainerDesTip">{tip.DesDica}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </ScrollView>
        </Popup>
    );
}