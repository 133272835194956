/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "devextreme/data/odata/store";
import { Tabs } from "devextreme-react";
import VariableRegister from "./variableRegister";
import ConfigurationManual from "./configurationManual";
import ConfigurationAutomatic from "./configurationAutomatic";
import { FavButton } from "../../../components/fav-button/favButton";

export default function VariablesTab() {
	const [selectedTab, setSelectedTab] = useState(0);

	return (
		<React.Fragment>
			<FavButton/>
			<Tabs 
				className={"tabs-panel"}
				items={["Cadastro Variáveis","Config. Variáveis Manuais","Config. Variáveis Automáticas"]}
				onItemClick={e => setSelectedTab(e.itemIndex)}
				selectedIndex={selectedTab}
			/>
			{selectedTab === 0 ? <VariableRegister /> : ''}
			{selectedTab === 1 ? <ConfigurationManual/> : ''}
			{selectedTab === 2 ? <ConfigurationAutomatic/> : ''}
			
		</React.Fragment>
	);
}
