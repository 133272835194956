/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
//import { useAuth } from "../../../contexts/auth";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/form";
import TypeStop from "./typeStop";
import TypeReasonStop from "./typeReasonStop";
import { FavButton } from "../../../components/fav-button/favButton";

export default function ReasonTypeTab() {
  const [update, setUpdate] = useState(false)

  const renderTypeStopTab = () => {
    return <TypeStop update={update} setUpdate={setUpdate} />;
  };

  const renderTypeReasonStopTab = () => {
    return <TypeReasonStop update={update}  />;
  }

  return (
    <React.Fragment>
      <FavButton/>
      <TabPanel className={"tab-panel-margin"} animationEnabled={true} swipeEnabled={false}>
        <Item title="Tipos Parada" render={renderTypeStopTab}/>
        <Item title="Motivos Parada" render={renderTypeReasonStopTab}/>
      </TabPanel>
    </React.Fragment>
  );
}