import React from "react";
import "devextreme/data/odata/store";
import "../../components/common/style.scss";
import "./style.scss";
import Popup from 'devextreme-react/popup';
import { useScreenSize } from '../../utils/media-query';
import { Button } from "devextreme-react";
import { mountStrLogMessage } from "./popUpReopenTask";
import { useAuth } from "../../contexts/auth";
import { useDataLoader } from "../../contexts/dataLoaderContext";

export default function PopupCancelTask(props) {
  const { isXSmall} = useScreenSize();
  const { userCod } = useAuth();
  const { objectImgToDataBase } = useDataLoader();

  return (
    <Popup
      className={"pop-up"}
      width={isXSmall ? "80%" : 450}
      height={isXSmall ? 120 : 100}
      visible={props.visiblePopupCancelTask}
      showTitle={false}
      //closeOnOutsideClick={true}
      //onHiding={(e) => setVisiblePopUp(false)}
      //showCloseButton={true}
    >
    <div className="ContainerInfo">
      <p>Deseja cancelar a tarefa?</p>
      <div className="ContainerButtons">
        <Button
          text="SIM"
          type="default"
          stylingMode="text"
          onClick={(e) => {
            const result = mountStrLogMessage(props.appointment, userCod)
            const strLogMessage = result.strLogMessage;
            const appointment = result.appointment;

            props.setResponsePopupCancelTask(true);
            props.setVisiblePopupCancelTask(false);
            appointment.TipExecutarTarefa = false;
            appointment.TipAguardandoVerificacao = false;
            appointment.TipCancelarTarefa = true;
            appointment.TipPeriodica ? props.updateValuePeriodicTask(appointment, objectImgToDataBase.current, strLogMessage) : props.updateValueSimpleTask(appointment, objectImgToDataBase.current, strLogMessage);
          }}
        />
        <Button
          text="NÃO"
          type="default"
          stylingMode="text"
          onClick={(e) => {
            props.setResponsePopupCancelTask(false)
            props.setVisiblePopupCancelTask(false)
          }}
        />
      </div>
    </div>
    </Popup>
  )
}