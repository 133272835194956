export function ObjSaveManualVariables(
  parParamsVariable,
  parlstDateTime,
  parDateInputVariable,
  parValueVariable,
  parValueCheckNotWorking,
  parUserCode,
  parVariableCode,
  parPreviousValue,
  parTipVariable
) {
  const newObject = {
    CodVariavelLeitura: parVariableCode,
    ValMuitoBaixo: parParamsVariable.LoLo,
    ValBaixo: parParamsVariable.Lo,
    ValAlto: parParamsVariable.Hi,
    ValMuitoAlto: parParamsVariable.HiHi,
    DatVariavelLeitura: parDateInputVariable,
    TipFuncionando: parValueCheckNotWorking,
    CodUsuario: parUserCode,
    PreviousValue: parPreviousValue,
  }

  //Se a variavel for do tipo bit(1) escreve no campo ValVariavelLeitura
  if (parTipVariable === 1) {
    let value = parValueVariable === true ? 1 : 0
    newObject.ValVariavelLeitura = parValueVariable === "" ? null : value
  }

  //Se a variavel for do tipo numerico(2) escreve no campo ValVariavelLeitura
  if (parTipVariable === 2) newObject.ValVariavelLeitura = parValueVariable === "" ? null : parValueVariable

  //Se a variavel for do tipo Alfanumerico(3) escreve no campo ValVariavelLeituraString
  if (parTipVariable === 3) newObject.ValVariavelLeituraString = parValueVariable === "" ? null : parValueVariable

  return newObject
}
