/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/form";
import Permission from "./permission";
import ProgramsPermission from "./programsPermission";
import VariablesPermission from "./variablesPermission";
import AnalysisPermission from "./analysisPermission";
import ActionPermission from "./actionPermission";
import { useAuth } from "../../../contexts/auth";
import { FavButton } from "../../../components/fav-button/favButton";

export default function PermissionTab() {
  const [update, setUpdate] = useState(0);
  const { permissions } = useAuth();
  const [visiblePermission, setVisiblePermission] = useState(true);
  const [visibleProgramsPermission, setVisibleProgramsPermission] = useState(true);
  const [visibleVariablesPermission, setVisibleVariablesPermission] = useState(true);
  const [visibleAnalysisPermission, setVisibleAnalysisPermission] = useState(true);
  const [visibleActionPermission, setVisibleActionPermission] = useState(true);

  useEffect(() => {
    const foundProgramsPermission = permissions.find(element => element === 9130);
    const foundVariablesPermission = permissions.find(element => element === 9140);
    const foundActionPermission = permissions.find(element => element === 9150);
    const foundAnalysisPermission = permissions.find(element => element === 9160);
    const foundPermission = permissions.find(element => element === 9170);

    if(!foundProgramsPermission) setVisibleProgramsPermission(false);
    if(!foundVariablesPermission) setVisibleVariablesPermission(false);
    if(!foundActionPermission) setVisibleActionPermission(false);
    if(!foundAnalysisPermission) setVisibleAnalysisPermission(false);
    if(!foundPermission) setVisiblePermission(false);

  }, [])

  const renderPermisionTab = () => {
    return <Permission
      update={update}
      setUpdate={setUpdate}
    />;
  };

  const renderProgramsPermissionTab = () => {
    return <ProgramsPermission
      update={update}
    />;
  };

  const renderVariablesPermissionTab = () => {
    return <VariablesPermission
      update={update}
    />;
  };

  const renderAnalyzesPermissionTab = () => {
    return <AnalysisPermission
      update={update}
    />;
  };

  const renderActionPermissionTab = () => {
    return <ActionPermission
      update={update}
    />;
  };

  return (
    <React.Fragment>
      <FavButton/>
      <TabPanel
        className={"tab-panel-margin"}
        animationEnabled={true}
        swipeEnabled={false}
      >
        {visiblePermission && <Item title="Permissão" render={renderPermisionTab} />}
        {visibleProgramsPermission && <Item title="Programas" render={renderProgramsPermissionTab}/>}
        {visibleVariablesPermission && <Item title="Variáveis" render={renderVariablesPermissionTab}/>}
        {visibleAnalysisPermission && <Item title="Análises" render={renderAnalyzesPermissionTab}/>}
        {visibleActionPermission && <Item title="Ação" render={renderActionPermissionTab}/>}
      </TabPanel>
    </React.Fragment>
  );
}
