/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "../../../components/common/style.scss";
import "devextreme/data/odata/store";
import {Button, CheckBox, SelectBox} from "devextreme-react";
import {useLoading} from "../../../contexts/loadingContext";
import {useAuth} from "../../../contexts/auth";
import {api} from "../../../services/api";
import {LogMessageCheckboxList} from "../../../utils/logMessage";
import {formatMessage} from "devextreme/localization";
import {LoadIndicator} from "devextreme-react/load-indicator";

export default function Configuration(props) {
    const { loading } = useLoading();
    const [load, setLoad] = useState(false);
    const [MonitorationBlock, setMonitorationBlock] = useState([]);
    const [emailMonitorationBlock, setEmailMonitorationBlock] = useState([]);
    const [monitorationBlockGroup, setMonitorationBlockGroup] = useState([]);
    const [email, setEmail] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState(0);
    const [selectedGroup, setSelectedGroup] = useState(0);
    const [disabledButton, setDisabledButton] = useState(true);
    const { unitCod } = useAuth();
    const _emailMonitorationBlock = useRef([])

    useEffect(() => {
        loadEmail()
    }, [props.update]);

    useEffect(() => {
        if (load) loadEmail();
    }, [load]);

    useEffect(() => {
        if(selectedEmail == 0) return;
        loadMonitorationBlock()
        loadEmailMonitorationBlock()
        loadEmailMonitorationBlockGroup()
    }, [selectedEmail]);

    async function loadEmail() {
        await api
            .get("/Email", { params: { unitCod: unitCod } })
            .then(res => {
                setEmail(res.data);
                setLoad(false);
            })
            .catch(() => {
                setLoad(true);
            });
    }

    async function loadEmailMonitorationBlock() {
        await api
            .get("/EmailMonitorationBlock/get", { params: { unitCod: unitCod, EmailId: selectedEmail } })
            .then(res => {
                setEmailMonitorationBlock(res.data);
                _emailMonitorationBlock.current = [...res.data]
                setLoad(true);
            })
            .catch(() => {
                setLoad(false);
            });
    }

    async function loadEmailMonitorationBlockGroup() {
        await api
            .get("/MonitorationBlockGroup", { params: { unitCod: unitCod, EmailId: selectedEmail } })
            .then(res => {
                setMonitorationBlockGroup(res.data);
                setLoad(true);
            })
            .catch(() => {
                setLoad(false);
            });
    }

    async function loadMonitorationBlock() {
        await api
            .get("/monitorationBlock", { params: { unitCod: unitCod } })
            .then(res => {
                setMonitorationBlock(res.data);
                setLoad(false);
            })
            .catch(() => {
                setLoad(true);
            });
    }

    async function insertRowEmailMonitorationBlock(data, parLogMessage) {
        await api
            .post("/EmailMonitorationBlock", { unitCod: unitCod, data: data, logMessage: parLogMessage })
            .then(res => {

            })
            .catch(() => {
                return false;
            });
    }

    async function deleteRowEmailMonitorationBlock(data) {
        await api
            .delete("/EmailMonitorationBlock", {data: data})
            .then(res => {

            })
            .catch(() => {
                return false;
            });
    }

    function onValueChangedCheckBox(checkbox, CodBlocoMonitoracao) {
        setDisabledButton(false)
        let newList = [...emailMonitorationBlock];
        
        if(checkbox)
            newList.push({ CodEmail: selectedEmail, CodBlocoMonitoracao: CodBlocoMonitoracao });
        else
            newList = emailMonitorationBlock.filter(t => t.CodBlocoMonitoracao !== CodBlocoMonitoracao);

        setEmailMonitorationBlock(newList)
    }

    async function eventSaveClick() {
        const delRows = _emailMonitorationBlock.current.filter(emailBloco => {
            const found = emailMonitorationBlock.find(item => item.CodBlocoMonitoracao === emailBloco.CodBlocoMonitoracao)
            if(!found){
                emailBloco.NomLogFirst = email.find(e => e.CodEmail === emailBloco.CodEmail).NomEmail;
                emailBloco.NomLogSecond = MonitorationBlock.find(e => e.CodBlocoMonitoracao === emailBloco.CodBlocoMonitoracao).NomBlocoMonitoracao;
                return emailBloco;
            }
        })

        const addRows = []
        emailMonitorationBlock.forEach(bloco => {
            var found = _emailMonitorationBlock.current.find(e => e.CodBlocoMonitoracao === bloco.CodBlocoMonitoracao)
            if(!found){
                found = {...bloco}
                found.NomLogFirst = email.find(e => e.CodEmail === found.CodEmail).NomEmail;
                found.NomLogSecond = MonitorationBlock.find(e => e.CodBlocoMonitoracao === found.CodBlocoMonitoracao).NomBlocoMonitoracao;
                addRows.push(found)
            }   
        })

        for (const item of addRows) {
            let logMessage = LogMessageCheckboxList(formatMessage("InsertedLog"), item)
            await insertRowEmailMonitorationBlock(item, logMessage);
        }

        for (const item of delRows) {
            let logMessage = LogMessageCheckboxList(formatMessage("DeletedLog"), item)
            const data = { unitCod: unitCod, data: item, logMessage: logMessage }
            await deleteRowEmailMonitorationBlock(data);
        }
        
        await loadEmailMonitorationBlock()
        setLoad(true);
        setDisabledButton(true)
    }

    async function eventUndoClick() {
        await loadEmailMonitorationBlock()
        await setEmailMonitorationBlock(_emailMonitorationBlock.current)
        setDisabledButton(true)
    }

    return (
        <React.Fragment>
            <div className="header-form">
                <div className={"form-container"}>
                    <div className={"margin-element"}>
                        <SelectBox
                            dataSource={email}
                            label={"Email"}
                            searchEnabled={true}
                            onSelectionChanged={e => setSelectedEmail(e.selectedItem.CodEmail)}
                            displayExpr="NomEmail"
                            valueExpr="CodEmail"
                            width={250}
                            stylingMode={"outlined"}
                            labelMode={"floating"}
                        />
                    </div>
                    <div className={"margin-element"}>
                        <SelectBox
                            dataSource={monitorationBlockGroup}
                            label={"Grupo"}
                            searchEnabled={true}
                            onSelectionChanged={e => setSelectedGroup(e.selectedItem.CodBlocoMonitoracaoGrupo)}
                            displayExpr="NomBlocoMonitoracaoGrupo"
                            valueExpr="CodBlocoMonitoracaoGrupo"
                            width={250}
                            stylingMode={"outlined"}
                            labelMode={"floating"}
                        />
                    </div>
                    <div className={"margin-element"}>
                        <Button
                            text={"Desfazer"}
                            icon={"undo"}
                            type="normal"
                            className={"selectall-button"}
                            useSubmitBehavior={false}
                            stylingMode="contained"
                            onClick={eventUndoClick}
                            disabled={disabledButton}
                        />
                    </div>
                    <div className={"margin-element"}>
                        <Button
                            className={"save-button"}
                            id="searchFilterButton"
                            icon="save"
                            text="Salvar"
                            type="default"
                            useSubmitBehavior={false}
                            stylingMode="contained"
                            onClick={eventSaveClick}
                            disabled={disabledButton}
                        />
                    </div>
                </div>
            </div>
            <div>
                {}
            </div>
            <div>
                {MonitorationBlock.map(bloco => {
                    if(bloco.CodBlocoMonitoracaoGrupo !== selectedGroup) return;

                    let valueCheckBox = false;
                    emailMonitorationBlock.forEach(email => {
                        if(email.CodBlocoMonitoracao === bloco.CodBlocoMonitoracao){
                            valueCheckBox = true;
                        }
                    });
                    
                    return (
                        <div key={bloco.CodBlocoMonitoracao} style={{
                            display: "inline-block",
                            margin: "3% 1% 0% 2%",
                            width: "200px"
                        }}>
                            <CheckBox
                                className={"checkbox-medium"}
                                value={valueCheckBox}
                                text={`${bloco.NomBlocoMonitoracao}`}
                                onValueChanged={e => onValueChangedCheckBox(e.value, bloco.CodBlocoMonitoracao)}
                            />
                        </div>
                    )
                })}
            </div>
            <div className="load-indicator">
                <LoadIndicator visible={loading} height={40} width={40} />
            </div>
        </React.Fragment>
    );
}
