/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../../components/common/style.scss";
import "devextreme/data/odata/store";
import { LogMessageGrid } from "../../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Editing,
  FilterRow,
  Form,
  Grouping,
  GroupPanel,
  Item,
  Lookup,
  Pager,
  Paging,
  RequiredRule, Toolbar,
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useLoading } from "../../../contexts/loadingContext";
import { useAuth } from "../../../contexts/auth";
import { LoadIndicator } from "devextreme-react/load-indicator";
import Button from "devextreme-react/button";

export default function MonitorationBlockOpc(props) {
    const { loading } = useLoading();
    const [load, setLoad] = useState(false);
    const [monitorationBlockOpc, setMonitorationBlockOpc] = useState([]);
    const [opcGroup, setOpcGroup] = useState([]);
    const _monitorationBlockOpc = useRef();
    const { unitCod } = useAuth();

    useEffect(() => {
        async function loadData(){
            await loadOpcGroupData();
            loadDataOpc();
        }
        loadData();
    }, []);

    useEffect(() => {
        if (load) {
            loadDataOpc();
            props.setUpdate(!props.update)
        }
    }, [load]);

    async function loadDataOpc() {
        await api
            .get("/MonitorationBlockOpc", { params: { unitCod: unitCod } })
            .then((response) => {
                setMonitorationBlockOpc(response.data);
            })
            .catch(() => {
                return true;
            });
    }

    async function loadOpcGroupData() {
        await api
            .get("/OPCGroup", { params: { unitCod: unitCod } })
            .then((response) => {
                setOpcGroup(response.data);
            })
            .catch(() => {
                return true;
            });
      }

    async function updateRow(data, parLogMessage) {
        return await api
        .put("/monitorationBlockOpc", {
            unitCod: unitCod,
            data: data,
            logMessage: parLogMessage,
        })
        .then(() => {
            setLoad(!load);
            props.setUpdate(!props.update);
        })
        .catch(() => {
            return true;
        });
    }

    async function insertRow(data, parLogMessage) {
        return await api
        .post("/monitorationBlockOpc", {
            unitCod: unitCod,
            data: data,
            logMessage: parLogMessage,
        })
        .then(() => {
            setLoad(!load);
            props.setUpdate(!props.update);
            return false;
        })
        .catch(() => {
            return true;
        });
    }

    async function deleteRow(data) {
        return await api
        .delete("/monitorationBlockOpc", { data: data })
        .then(() => {
            setLoad(!load);
            props.setUpdate(!props.update);
            
            return false;
        })
        .catch(() => {
            return true;
        });
    }

    return (
        <React.Fragment>
        <DataGrid
            className={"dx-cards"}
            dataSource={monitorationBlockOpc}
            rowAlternationEnabled={true}
            showColumnLines={true}
            allowColumnReordering={true}
            showBorders={false}
            columnHidingEnabled={true}
            columnAutoWidth={true}
            repaintChangesOnly={true}
            onInitNewRow={(e) => {
                e.data.CodUnidadeEmpresa = unitCod;
            }}
            headerFilter={{ visible: true }}
            filterPanel={{ visible: true }}
            filterRow={{ visible: false }}
            onRowUpdating={async (e) => {
            const newObj = { ...e.oldData, ...e.newData };
            let strLogMessage = LogMessageGrid(
                formatMessage("UpdatedLog"),
                _monitorationBlockOpc,
                newObj
            );

            e.cancel = updateRow(newObj, strLogMessage);

            e.component.deselectAll();
            }}
            onRowInserting={async (e) => {
            let strLogMessage = LogMessageGrid(
                formatMessage("InsertedLog"),
                _monitorationBlockOpc,
                e.data
            );
            e.cancel = insertRow(e.data, strLogMessage);

            e.component.deselectAll();
            }}
            onRowRemoving={(e) => {
            let strLogMessage = LogMessageGrid(
                formatMessage("DeletedLog"),
                _monitorationBlockOpc,
                e.data
            );

            const data = {
                unitCod: unitCod,
                data: e.data,
                logMessage: strLogMessage,
            };
            e.cancel = deleteRow(data);

            e.component.deselectAll();
            }}
            ref={_monitorationBlockOpc}
        >
            <Editing
                mode={"batch"}
                allowUpdating={true}
                allowDeleting={true}
                allowAdding={false}
            >
                <Form>
                    <Item itemType="group" colCount={4} colSpan={2}>
                    <Item colSpan={2} dataField="NomBalancaFluxo" />
                    <Item colSpan={2} dataField="CodAreaProcesso" />
                    </Item>
                </Form>
            </Editing>
            <Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false}/>
            <GroupPanel visible={false} />
            <ColumnChooser enabled={true} mode="select" />
            <ColumnFixing enabled={false} />
            <Paging defaultPageSize={20} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />
            <Toolbar>
                <Item cssClass={'add-container'}>
                    <Button className={'add-button'} icon={"add"} onClick={() => (_monitorationBlockOpc.current.instance.addRow())}/>
                </Item>
                <Item name="saveButton"/>
                <Item name="revertButton"/>
                <Item name="exportButton"/>
                <Item name="columnChooserButton"/>
            </Toolbar>
            <Column
                dataField={"DesStringConexao"}
                caption={"String de Conexão"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
                <RequiredRule />
            </Column>
            <Column
                dataField={"ValTamanhoDb"}
                caption={"Tamanho no Banco de Dados"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
                <RequiredRule />
            </Column>
            <Column
                dataField={"CodOpcConexaoGrupo"}
                caption={"Grupo Conexão OPC"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
                <RequiredRule />
                <Lookup
                    dataSource={opcGroup}
                    displayExpr={opcGroup => `${opcGroup.NomOpcGrupo}`}
                    valueExpr="CodOpcGrupo"
                />
            </Column>
            <Column
                dataField={"ValSeparador"}
                caption={"Separador"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
                editorOptions={{ maxLength: 1 }}
            >
                <RequiredRule />
            </Column>
        </DataGrid>
        <div className="load-indicator">
            <LoadIndicator visible={loading} height={40} width={40} />
        </div>
        </React.Fragment>
    );
};
