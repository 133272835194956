import React, { useState, useEffect } from 'react';
import Popup from 'devextreme-react/popup';
import { useAuth } from "../../contexts/auth";
import { useScreenSize } from '../../utils/media-query';
import "./style.scss";

export default function PopupUpdadateDB() {
    const { isXSmall } = useScreenSize();
    const { visiblePopupUpdateDB, setVisiblePopupUpdateDB, initialConfig } = useAuth();
    const [versionDB, setVersionDB] = useState("0000");

    useEffect(() => {
        const versionString = initialConfig.versionDB !== undefined ? initialConfig.versionDB.toString() : "0000";
        
        setVersionDB(versionString);
    }, [initialConfig])

    return (
        <Popup
            width={isXSmall ? "100%" : 400}
            height={150}
            // visible={visiblePopupUpdateDB}
            visible={false}
            showTitle={true}
            showCloseButton={true}
            onHiding={(e) => setVisiblePopupUpdateDB(false)}
            closeOnOutsideClick={(e) => setVisiblePopupUpdateDB(false)}
            title={"Aviso"}
        >
            <div className={"ContainerContentPopupUpdateDB"}>
                <div>
                    Por favor, atualize o seu banco de dados para a última versão.
                </div>
                <div>
                    Versão atual do Banco: v{versionDB}
                </div>
            </div>
        </Popup>
    );
}