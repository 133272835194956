import React from "react";
import "devextreme/data/odata/store";
import "../../components/common/style.scss";
import "./style.scss";
import Popup from 'devextreme-react/popup';
import { useScreenSize } from '../../utils/media-query';
import { Button } from "devextreme-react";
import { useAuth } from "../../contexts/auth";
import { formatMessage } from "devextreme/localization";
import { useDataLoader } from "../../contexts/dataLoaderContext";

export function mountStrLogMessage(appointment, userCod) {
  let strLogMessage;

  if( (appointment.TipSituacao === 3 || appointment.TipSituacao === 6 || appointment.TipSituacao === 9 || appointment.TipSituacao === 10) && 
      (appointment.CodUsuarioMonitor === null || appointment.CodUsuarioMonitor !== userCod)
    ){
      appointment.CodUsuarioVerificador = userCod;
      strLogMessage = `${formatMessage("VerifiedTask")} ${appointment.NomTarefa}`;
    }

  if(appointment.TipSituacao === 2 || appointment.TipSituacao === 5) 
    strLogMessage = `${formatMessage("MonitoredTask")} ${appointment.NomTarefa}`;

  if(appointment.TipSituacao === 3 || appointment.TipSituacao === 6 || appointment.TipSituacao === 9 || appointment.TipSituacao === 10)
    strLogMessage = `${formatMessage("VerifiedTask")} ${appointment.NomTarefa}`;

  return { strLogMessage: strLogMessage, appointment: appointment }
}

export default function PopupReopenTask(props) {
  const { userCod } = useAuth();
  const { isXSmall} = useScreenSize();
  const { objectImgToDataBase } = useDataLoader();

  return (
    <Popup
      className={"pop-up"}
      width={isXSmall ? "80%" : 450}
      height={isXSmall ? 120 : 100}
      visible={props.visiblePopupReopenTask}
      showTitle={false}
      //closeOnOutsideClick={true}
      //onHiding={(e) => setVisiblePopUp(false)}
      //showCloseButton={true}
    >
    <div className="ContainerInfo">
      <p>Deseja reabrir a tarefa?</p>
      <div className="ContainerButtons">
        <Button
          text="SIM"
          type="default"
          stylingMode="text"
          onClick={(e) => {
            const result = mountStrLogMessage(props.appointment, userCod)
            const strLogMessage = result.strLogMessage;
            const appointment = result.appointment;

            props.setResponsePopupReopenTask(true);
            props.setVisiblePopupReopenTask(false);
            appointment.TipExecutarTarefa = true;
            appointment.TipAguardandoVerificacao = false;
            appointment.TipCancelarTarefa = false;
            appointment.TipPeriodica ? props.updateValuePeriodicTask(appointment, objectImgToDataBase.current, strLogMessage) : props.updateValueSimpleTask(appointment, objectImgToDataBase.current, strLogMessage);
          }}
        />
        <Button
          text="NÃO"
          type="default"
          stylingMode="text"
          onClick={(e) => {
            props.setResponsePopupReopenTask(false);
            props.setVisiblePopupReopenTask(false);
            if( props.appointment.CodUsuarioVerificador === userCod || 
                ((props.appointment.CodUsuarioVerificador === null && props.appointment.CodPermissaoVerificador === null) && props.appointment.CodUsuarioMonitor === userCod)
              ) 
            {
              props.setVisiblePopupCancelTask(true);
            } else {
              const result = mountStrLogMessage(props.appointment, userCod)
              const strLogMessage = result.strLogMessage;

              props.appointment.TipAguardandoVerificacao = true;
              props.appointment.TipExecutarTarefa = false;
              props.appointment.TipCancelarTarefa = false;
              props.appointment.TipPeriodica ? props.updateValuePeriodicTask(props.appointment, objectImgToDataBase.current, strLogMessage) : props.updateValueSimpleTask(props.appointment, objectImgToDataBase.current, strLogMessage);
            }            
          }}
        />
      </div>
    </div>
    </Popup>
  )
}