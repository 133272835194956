export const LogMessageGrid = (requestTypeMessage, refGrid, newObj) => {
    const password = "*****"
    let arrayData = []
    let strMessage = requestTypeMessage;
    let data = refGrid.current.instance.getVisibleColumns()

    data.forEach(element => {
    
      if(element.caption !== undefined)
        var obj = {
          caption: element.caption,
          dataField: element.dataField,
          lookup: element.lookup
        }

        if(element.editorOptions !== undefined) {
          if(element.editorOptions.mode === "password"){
            obj = {...obj, password}
          }
        }

        if(obj !== undefined)
          arrayData.push(obj)
    });

    arrayData.forEach(element => {
      for(const key in newObj) {
        if(key === element.dataField){
          
          let value = newObj[key]
          
          if(element.lookup !== undefined && element.dataField !== "DesCampoBlocoMonitoracaoValor") value = element.lookup.valueMap[value]
          if(element.lookup !== undefined && element.dataField === "DesCampoBlocoMonitoracaoValor") value = newObj.DesCampoBlocoMonitoracaoValor
          if(element.password !== undefined) value = element.password

          strMessage = `${strMessage.toString()} ${element.caption}: ${value}, `
        }
      }
    })

    strMessage = strMessage.trimStart()
    strMessage = strMessage.trimEnd()
    strMessage = strMessage.substring(0, strMessage.length - 1)

    return strMessage;
};

//No objeto item deve ter a propriedade NomLogFirst e a NomLogSecond
export const LogMessageCheckboxList = (requestTypeMessage, item) => {
  let strMessage = "";
  strMessage = `${requestTypeMessage} ${item.NomLogFirst} -> ${item.NomLogSecond}`

  return strMessage;
};