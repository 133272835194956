/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "../administration/style.scss";
import "devextreme/data/odata/store";
import "../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import DataGrid, {
	Column,
	Pager,
	RequiredRule,
	Paging,
	FilterRow,
	Export,
	ColumnChooser,
	Grouping,
	GroupPanel,
	ColumnFixing,
	Selection,
	Editing,
	Form,
	Popup,
	Position,
	Item, 
	Toolbar,
	Lookup,
	CustomRule,
} from "devextreme-react/data-grid";
import { formatMessage } from "devextreme/localization";
import { api } from "../../services/api";
import { useAuth } from "../../contexts/auth";
import { LogMessageGrid } from "../../utils/logMessage";
import { useLoading } from "../../contexts/loadingContext";
import { useScreenSize } from "../../utils/media-query";
import { FavButton } from "../../components/fav-button/favButton";
import Button from "devextreme-react/button";

export default function Equipment() {
	const { unitCod } = useAuth();
	const { loading } = useLoading();
	const { isLarge } = useScreenSize();
	const [load, setLoad] = useState(false);
	const [lstEquipment, setLstEquipment] = useState([]);
	const [lstAreaProcess, setLstAreaProcess] = useState([]);
	const _equipment = useRef();
	
	useEffect(() => {
		if (unitCod !== undefined)
			setLoad(true);
	}, []);

	useEffect(() => {
		if (load)
			loadDataAreaProcess();
			loadData();
	}, [load]);

	async function loadData() {
		await api
			.get("/equipment", { params: { unitCod: unitCod } })
			.then((response) => {
				setLstEquipment(response.data);
				setLoad(false);
			})
			.catch((error) => {
				setLoad(true);
			});
	}

	async function loadDataAreaProcess() {
		await api
		  .get("/areaprocess", { params: { unitCod: unitCod } })
		  .then((response) => {
			setLstAreaProcess(response.data);
		  })
		  .catch((error) => {});
	};

	async function updateRow(data, parLogMessage) {
		return await api
			.put("/equipment", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then((response) => {
				setLoad(true);
			})
			.catch((error) => {
				setLoad(true);
			});
	}

	async function insertRow(data, parLogMessage) {
		return await api
			.post("/equipment", { unitCod: unitCod, data: data, logMessage: parLogMessage })
			.then((response) => {
				setLoad(true);
			})
			.catch((error) => {
				setLoad(true);
			});
	}

	async function deleteRow(data) {
		return await api
			.delete("/equipment", { data: data })
			.then((response) => {
				setLoad(true);
			})
			.catch((error) => {
				setLoad(true);
			});
	}

	function checkUniqueKey(params) {
		let check = true;
	
		lstEquipment.forEach((equipment) => {
		  if (
			equipment.CodEquipamento !== params.data.CodEquipamento &&
			equipment.CodUnidadeEmpresa === params.data.CodUnidadeEmpresa &&
			equipment.TagEquipamento === params.data.TagEquipamento
		  )
			check = false;
		});
	
		return check;
	};

	return (
		<React.Fragment className={"OnlyGrid"}>
			<div className={"ContainerButtons"}>
				<FavButton/>
			</div>
			<div id="equipmentGrid">
				<DataGrid
					className={"dx-cards"}
					dataSource={lstEquipment}
					rowAlternationEnabled={true}
					showColumnLines={true}
					allowColumnReordering={true}
					showBorders={false}
					columnAutoWidth={false}
					columnHidingEnabled={true}
					repaintChangesOnly={true}
					headerFilter={{ visible: true }}
					filterPanel={{ visible: true }}
					filterRow={{ visible: false }}
					onInitNewRow={(e) => {
						e.data.CodUnidadeEmpresa = unitCod;
					}}
					onRowUpdating={async (e) => {
						const newObj = { ...e.oldData, ...e.newData };
						let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _equipment, newObj);

						e.cancel = updateRow(newObj, strLogMessage);

						e.component.deselectAll();
					}}
					onRowInserting={(e) => {
						let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _equipment, e.data);

						e.cancel = insertRow(e.data, strLogMessage);

						e.component.deselectAll();
					}}
					onRowRemoving={(e) => {
						let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _equipment, e.data);
						const data = { unitCod: unitCod, data: e.data, logMessage: strLogMessage };

						e.cancel = deleteRow(data);

						e.component.deselectAll();
					}}
					ref={_equipment}>
					<Editing mode={isLarge ? "batch" : "popup"} allowUpdating={true} allowDeleting={true} allowAdding={false}>
						<Popup title="Cadastro de Equipamento" showTitle={true} width={700} height={750} fullScreen={true}>
							<Position my="center" at="center" of={window} />
						</Popup>
						<Form>
							<Item 
								itemType="group" 
								colCount={4} 
								colSpan={2}
							>
								<Item 
									colSpan={2} 
									dataField="TagEquipamento" 
								/>
								<Item
									colSpan={2}
									dataField="DesEquipamento"
								/>
                				<Item
									colSpan={2}
									dataField="CodAreaProcesso"
								/>
							</Item>
						</Form>
					</Editing>
					<Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false} />
					<GroupPanel visible={false} />
					<Export enabled={true} fileName="Equipamentos" allowExportSelectedData={true} />
					<ColumnChooser enabled={true} mode="select" />
					<ColumnFixing enabled={false} />
					<Selection mode="multiple" />
					<Paging defaultPageSize={20} />
					<Pager showPageSizeSelector={true} showInfo={true} />
					<FilterRow visible={true} />
					<Toolbar >
						<Item cssClass={'add-container'}>
							<Button 
								className={'add-button'}
								icon={"add"} 
								onClick={() => (_equipment.current.instance.addRow())}
							/>
						</Item>
						<Item name="saveButton"/>
						<Item name="revertButton"/>
						<Item name="exportButton"/>
						<Item name="columnChooserButton"/>
					</Toolbar>
					<Column 
						dataField={"TagEquipamento"} 
						caption={"Tag"} 
						allowSorting={true} 
						sortIndex={0} 
					>
						<CustomRule
							message={"Este campo deve ser único"}
							reevaluate={true} 
							type={"custom"}
							validationCallback={checkUniqueKey}
          				/>
						<RequiredRule />
					</Column>
					<Column
						dataField={"DesEquipamento"}
						caption={"Descrição"}
						allowSorting={true}
						sortIndex={1} 
					/>
					<Column
						dataField={"CodAreaProcesso"}
						caption={"Area Processo"}
						allowSorting={true}
						sortIndex={2}
					>
						<Lookup
							dataSource={lstAreaProcess}
							displayExpr={"NomAreaProcesso"}
							valueExpr={"CodAreaProcesso"}
          				/>
						<RequiredRule />
					</Column>
				</DataGrid>
			</div>
			<div className="load-indicator">
				<LoadIndicator visible={loading} height={40} width={40} />
			</div>
		</React.Fragment>
	);
}