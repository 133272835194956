/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import Scheduler, { AppointmentDragging, Resource, View } from 'devextreme-react/scheduler'
import { useAuth } from '../../contexts/auth'
import { api } from '../../services/api'
import { CheckBox } from 'devextreme-react/check-box'
import PopupRepprovedTask from './popUpRepprovedTask'
import PopupReopenTask from './popUpReopenTask'
import PopupCancelTask from './popUpCancelTask'
import dayjs from 'dayjs'
import { formatMessage } from 'devextreme/localization'
import notify from 'devextreme/ui/notify'
import { FavButton } from '../../components/fav-button/favButton'
import { theme } from '../../utils/theme'
import { useDataLoader } from '../../contexts/dataLoaderContext'
import { ImageUploaderInline } from "../../components/image-uploader/imageUploaderInline";
import { Template } from 'devextreme-react/core/template'

const resourcesData = [
  {
    text: 'Execução',
    id: 1,
    color: theme.palette.chart.lightGreen,
  },
  {
    text: 'Monitoração',
    id: 2,
    color: theme.palette.chart.pink,
  },
  {
    text: 'Verificação',
    id: 3,
    color: theme.palette.chart.blue,
  },
]

// ver como pegar o id da tarefa a partir do popup

export default function ExecuteTask() {
  //#region variaveis
  const { refreshToken, getRefreshTokenCode, getToken, unitCod, userCod } = useAuth()
  const { objectImgToDataBase } = useDataLoader();
  const [user, setUser] = useState([])
  const [load, setLoad] = useState(false)
  const [executionTask, setExecutionTask] = useState(false)
  const [monitorationTask, setMonitorationTask] = useState(false)
  const [verificationTask, setVerificationTask] = useState(false)
  const [lstTasks, setLstTasks] = useState([])
  const [visiblePopupRepprovedTask, setVisiblePopupRepprovedTask] = useState(false)
  const [responsePopupRepprovedTask, setResponsePopupRepprovedTask] = useState(false)
  const [visiblePopupReopenTask, setVisiblePopupReopenTask] = useState(false)
  const [responsePopupReopenTask, setResponsePopupReopenTask] = useState(false)
  const [visiblePopupCancelTask, setVisiblePopupCancelTask] = useState(false)
  const [responsePopupCancelTask, setResponsePopupCancelTask] = useState(false)
  const _appointment = useRef()
  const _event = useRef()
  const _simpleTasks = useRef()
  const _periodicTasks = useRef()
  const _tasks = useRef()
  const _executorPermissions = useRef()
  const _monitorPermissions = useRef()
  const _verificadorPermissions = useRef()
  const _popUpExecuteTask = useRef()
  const _scheduler = useRef()
  const _currentDateEndView = useRef(new Date(new Date().setHours(23, 59, 59)));
  const _currentDateStartView = useRef(new Date(new Date().setHours(0, 0, 0)));
  let currentDate = new Date();
  const taskCode = useRef()
  const _flagExecutedTask = useRef(null)
  const imgs = useRef([])
  const views = ['day', 'week', 'month', 'agenda']
  const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
  dayjs.extend(isSameOrAfter)
  //#endregion

  //#region useEffect
  useEffect(() => {
    const currentWeekDay = currentDate.getDay();

    if(currentWeekDay > 1)
      currentDate = currentDate.setDate(currentDate.getDate() - (currentWeekDay - 1));

    firstLoad()
  }, [])
  
  useEffect(() => {
    if (load) updateLoad()
  }, [load])

  useEffect(() => {
    if(lstTasks.length > 0) tasksFiltredsByChecksBoxs()
  }, [executionTask, monitorationTask, verificationTask])

  async function firstLoad() {
    await loadDataUsers()
    await loadDataExecutorPermissions()
    await loadDataMonitorPermissions()
    await loadDataVerificadorPermissions()
    await refreshToken(getToken(), getRefreshTokenCode())
  }

  async function updateLoad() {
    await loadDataSimpleTasks()
    await loadDataPeriodicTasks()

    _tasks.current = []

    _tasks.current = _simpleTasks.current

    if (_periodicTasks.current !== undefined && _periodicTasks.current.length > 0) _periodicTasks.current.forEach((periodicTask) => _tasks.current.push(periodicTask))
    
    setLstTasks(_tasks.current)
  }

  //#endregion

  //#region request
  async function loadDataSimpleTasks() {
    await api
      .get('/tasks/getSimpleTasks', { params: { unitCod: unitCod, codExecutorUser: userCod, startDate: formatDate(_currentDateStartView.current), endDate: formatDate(_currentDateEndView.current) } })
      .then((response) => {
        _simpleTasks.current = response.data

        setLoad(false)
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function loadDataPeriodicTasks() {
    await api
      .get('/tasks/getPeriodicTasks', { params: { unitCod: unitCod, codExecutorUser: userCod, endDate: formatDate(_currentDateEndView.current) } })
      .then((response) => {
        _periodicTasks.current = response.data

        setLoad(false)
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function loadDataUsers() {
    await api
      .get('/user', { params: { unitCod: unitCod } })
      .then((response) => {
        userCod === 0 ? setUser(response.data) : setUser(response.data.filter((x) => x.CodUsuario !== 0))

        setLoad(false)
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function loadDataExecutorPermissions() {
    await api
      .get('/permission/getExecutorPermission', { params: { unitCod: unitCod } })
      .then((response) => {
        // eslint-disable-next-line no-const-assign
        _executorPermissions.current = response.data
        setLoad(false)

        return false
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function loadDataMonitorPermissions() {
    await api
      .get('/permission/getMonitorPermission', { params: { unitCod: unitCod } })
      .then((response) => {
        _monitorPermissions.current = response.data
        setLoad(false)

        return false
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function loadDataVerificadorPermissions() {
    await api
      .get('/permission/getVerificadorPermission', { params: { unitCod: unitCod } })
      .then((response) => {
        _verificadorPermissions.current = response.data
        setLoad(false)

        return false
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function insertValueSimpleTask(data, imgData, parLogMessage) {
    return await api
      .post('/valueSimpleTasks', { unitCod: unitCod, data: data, imgData: imgData, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true)

        return false
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function insertValuePeriodicTask(data, imgData, parLogMessage) {
    return await api
      .post('/valuePeriodicTasks', { unitCod: unitCod, data: data, imgData: imgData, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true)

        return false
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function updateValueSimpleTask(data, imgData, parLogMessage) {
    return await api
      .put('/valueSimpleTasks', { unitCod: unitCod, data: data, imgData: imgData, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true)

        return false
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function updateValuePeriodicTask(data, imgData, parLogMessage) {
    
    return await api
      .put('/valuePeriodicTasks', { unitCod: unitCod, data: data, imgData: imgData, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true)

        return false
      })
      .catch((error) => {
        setLoad(true)

        return true
      })
  }

  async function getImages(primaryCod, secondaryCod) {
		await api
			.get("/image/get", { params: { unitCod, programCod: 3110, primaryCod, secondaryCod } })
			.then((response) => {
				imgs.current = response.data
			})
			.catch(() => {
				return true;
			});
	}

  function onInicializedScheduler(e) {
    if(!dayjs(_currentDateEndView.current).isSame(dayjs(e.component.getEndViewDate()))) {
      if(e.component.currentView !== "month") {
        _currentDateEndView.current = new Date(e.component.getEndViewDate());
        _currentDateStartView.current = new Date(e.component.getStartViewDate());
        
        updateLoad();
      } else {
        const dateEndView = e.component.getEndViewDate();
        const dateStartView = e.component.getStartViewDate();
        const newDateEndView = new Date(dateEndView.getFullYear(), dateEndView.getMonth(), 0);
        const newDateStartView = new Date(dateStartView.getFullYear(), dateStartView.getMonth(), 1);

        if(!dayjs(_currentDateEndView.current).isSame(dayjs(newDateEndView))){
          _currentDateEndView.current = newDateEndView;
          _currentDateStartView.current = newDateStartView;
          
          updateLoad();
        }
      }
    }
    
    if (_event.current === undefined) _event.current = e.component

    if(e.component.option("currentView") === "agenda") {
      const result = document.getElementsByClassName("dx-scheduler-appointment-content-allday");
      
      if(result.length > 0) {
        for (let i = 0; i < result.length; i++) {
          result[i].innerText = formatMessage("dxScheduler-allDay");
        };
      };
    };
  }

  async function renderAppointmentFormOpening(e) {
    appointmentFormOpening(e);
    await getImages(e.appointmentData.CodTarefa, e.appointmentData.NumTarefa);
    e.popup.repaint();
    e.form.repaint();
  }

  //#region appointment
  async function appointmentFormOpening(e) {
    taskCode.current = e.appointmentData.CodTarefa
    e.popup.option('showTitle', true)
    e.popup.option("showCloseButton", true)
    e.popup.option('minWidth', '80%')
    e.popup.option('height', '100%')
    e.popup.option('onHiding', () => _scheduler.current.instance.repaint())
    objectImgToDataBase.current = {}
    _flagExecutedTask.current = null
    
    const form = e.form

    //Não deixar abrir popup quando clicar em linha do vazio
    if (!form.getEditor('NomTarefa').option('value')) {
      e.cancel = true
      return
    }

    let mainGroupItems = form.itemOption('mainGroup').items
    let recurrenceGroupItems = form.itemOption('recurrenceGroup').items
    const onClickBeforeExecuteTask = e.popup.option('toolbarItems[0]')
    let onClickAfterExecuteTask = onClickPopup(e)

    function onClickPopup(e) {
      const valided = disabledExecuteTask(e.appointmentData);

      if (valided.dayOk && valided.taskOk === true)
        return onClickBeforeExecuteTask;

      function messageNotify() {
        if(!valided.dayOk) 
          notify('Não é possível executar tarefas com datas posteriores a data atual.', 'error', 3000);

        if(valided.taskOk !== true && valided.taskOk)
          notify(`Está mesma tarefa tem que ser executada primeiramente no dia ${dayjs(valided.taskOk.DatIniciaMonitoracao).format('DD-MM-YYYY HH:mm:ss')}`, 'error', 3000);

        return onClickBeforeExecuteTask;
      }

      onClickBeforeExecuteTask.onClick = () => messageNotify();

      return onClickBeforeExecuteTask;
    }    

    e.popup.option('toolbarItems[0]', onClickAfterExecuteTask)
    form.itemOption('mainGroup').visible = true
    form.itemOption('recurrenceGroup').visible = true
    form.itemOption('recurrenceGroup').colSpan = 1
    form.itemOption('mainGroup').colSpan = 1

    function modifyMainGroupItems() {
      mainGroupItems[0].label = { text: 'Tarefa' }
      mainGroupItems[0].dataField = 'NomTarefa'
      mainGroupItems[0].disabled = true
      mainGroupItems[1].visible = false
      mainGroupItems[2].visible = false
      mainGroupItems[4].disabled = true
      mainGroupItems[4].editorOptions = {
        autoResizeEnabled: true
      }
    }

    if(mainGroupItems.length >= 7) {
      modifyMainGroupItems()
      mainGroupItems[6].visible = false
    } else {
      modifyMainGroupItems()
    }

    // itens padrão do form
    //if (mainGroupItems.length === 7)
      mainGroupItems.push(
        {
          colSpan: 2,
          dataField: 'DatFinalizaMonitoracao',
          label: { text: 'Data Final' },
          editorType: 'dxDateBox',
          editorOptions: {
            calendarOptions: { firstDayOfWeek: undefined },
            type: 'datetime',
            width: '100%',
          },
          disabled: true,
        },
        {
          colSpan: 2,
          label: { text: ' Executor' },
          editorType: 'dxSelectBox',
          dataField: e.appointmentData.CodPermissaoExecutor === null ? 'CodUsuarioExecutor' : 'CodPermissaoExecutor',
          editorOptions: {
            items: e.appointmentData.CodPermissaoExecutor === null ? user : _executorPermissions.current,
            displayExpr: e.appointmentData.CodPermissaoExecutor === null ? 'NomUsuario' : 'NomPermissao',
            valueExpr: e.appointmentData.CodPermissaoExecutor === null ? 'CodUsuario' : 'CodPermissao',
          },
          disabled: true,
        },
        {
          colSpan: 2,
          dataField: 'DatTarefaExecutor',
          label: { text: 'Data Execução' },
          editorType: 'dxDateBox',
          name: 'DataExecucao',
          editorOptions: {
            calendarOptions: { firstDayOfWeek: undefined },
            type: 'date',
            width: '100%',
          },
        }
      )

    // items da coluna da direita do form
    recurrenceGroupItems = []
    if (recurrenceGroupItems.length === 0)
      recurrenceGroupItems.push({
        label: { text: 'Histórico Observação | NC' },
        colSpan: 2,
        dataField: 'observations',
        editorType: 'dxTileView',
        editorOptions: {
          direction: 'vertical',
          height: 645,
          width: '100%',
          baseItemHeight: 120,
          baseItemWidth: 350,
          itemMargin: 10,
          items: e.appointmentData.observations,
          itemTemplate: function (itemData, _, itemElement) {
            const data = 'Data:'
            const usuario = 'Usuário:'
            const observacao = 'Observação:'
            const formatDate = dayjs(itemData.DatObservacao).format('DD/MM/YYYY HH:mm:ss')
            let userName = ''

            if (itemData.CodUsuarioExecutorNavigation !== null && itemData.CodUsuarioExecutorNavigation.NomUsuario)
              userName = `${itemData.CodUsuarioExecutorNavigation.NomUsuario}  -> Executou`

            if (itemData.CodUsuarioMonitorNavigation !== null && itemData.CodUsuarioMonitorNavigation.NomUsuario)
              userName = `${itemData.CodUsuarioMonitorNavigation.NomUsuario}  -> Monitorou`

            if (itemData.CodUsuarioVerificadorNavigation !== null && itemData.CodUsuarioVerificadorNavigation.NomUsuario)
              userName = `${itemData.CodUsuarioVerificadorNavigation.NomUsuario}  -> Verificou`

            let imageDiv = document.createElement('div')
            let imageDiv2 = document.createElement('div')
            let imageDiv3 = document.createElement('div')

            imageDiv.style.height = '100%'
            imageDiv.id = itemData.DatObservacao
            imageDiv.style.width = '100%'
            imageDiv.innerHTML = `${data.bold()} ${formatDate}`

            imageDiv2.style.height = '100%'
            imageDiv2.id = 'div2'
            imageDiv2.style.width = '100%'
            imageDiv2.innerHTML = `${usuario.bold()} ${userName}`

            imageDiv3.style.height = '100%'
            imageDiv3.id = 'div3'
            imageDiv3.style.width = '100%'
            imageDiv3.innerHTML = `${observacao.bold()} ${itemData.DesObservacao}`

            itemElement.append(imageDiv)
            itemElement.style.display = 'flex'
            itemElement.style.flexDirection = 'column'
            itemElement.style.justifyContent = 'center'
            document.getElementById(itemData.DatObservacao).appendChild(imageDiv2).appendChild(imageDiv3)
            const tile = document.getElementsByClassName('dx-item dx-tile')
            tile[0].className = 'dx-card'
          },
        },
      })

    // items do form caso seja execução de tarefa
    if (!e.appointmentData.TipTarefaExecutada && !e.appointmentData.TipTarefaMonitorada && !e.appointmentData.TipTarefaVerificada && e.appointmentData.TipSituacao === 1) {
      e.popup.option('title', 'Executar Tarefa')
      mainGroupItems.push(
        {
          colSpan: 2,
          label: { text: 'Tarefa Executada?', visible: true },
          editorType: 'dxCheckBox',
          dataField: 'TipTarefaExecutada',
          name: 'TarefaExecutada',
          editorOptions: {
            visible: false,
            iconSize: "36px",
          },
        },
        {
          itemType: 'button',
          horizontalAlignment: 'center',
          name: 'Sim',
          buttonOptions: {
            text: 'Sim',
            type: 'normal',
            width: '100%',
            height: '50px',
            onClick: () => {
              form.getEditor('TipTarefaExecutada').option('value', true)
              form.getEditor('TipNaoPossivel').option('value', false)
              form.getEditor('Sim').option('type', 'success')
              form.getEditor('Nao').option('type', 'normal')
              _flagExecutedTask.current = true
            },
          },
        },
        {
          itemType: 'button',
          horizontalAlignment: 'center',
          name: 'Nao',
          buttonOptions: {
            width: '100%',
            height: '50px',
            text: 'Não',
            type: 'normal',
            onClick: () => {
              form.getEditor('TipNaoPossivel').option('value', true)
              form.getEditor('TipTarefaExecutada').option('value', false)
              form.getEditor('Sim').option('type', 'normal')
              form.getEditor('Nao').option('type', 'danger')
              _flagExecutedTask.current = false
            },
          },
        },
        {
          colSpan: 2,
          label: { text: 'Não Possível', visible: false },
          editorType: 'dxCheckBox',
          dataField: 'TipNaoPossivel',
          name: 'NaoPossivel',
          editorOptions: {
            visible: false,
            iconSize: "36px",
          },
        }
      )
      form.repaint()
      form.itemOption('mainGroup.TarefaAprovadaExecutada', 'visible', true)
      form.itemOption('mainGroup.NaoPossivel', 'visible', true)
      form
        .getEditor('DatTarefaExecutor')
        .option('value', e.appointmentData.DatTarefaExecutor && e.appointmentData.TipSituacao > 1 ? e.appointmentData.DatTarefaExecutor : new Date())
      form.getEditor('DatTarefaExecutor').option('max', new Date())
    }

    // items do form caso seja monitoração de tarefa
    if ((e.appointmentData.TipSituacao === 2 || e.appointmentData.TipSituacao === 5)) {
      e.popup.option('title', 'Monitorar Tarefa')
      mainGroupItems.push(
        {
          colSpan: 2,
          label: { text: 'Monitor' },
          editorType: 'dxSelectBox',
          dataField: e.appointmentData.CodPermissaoMonitor === null ? 'CodUsuarioMonitor' : 'CodPermissaoMonitor',
          name: 'Monitor',
          editorOptions: {
            items: e.appointmentData.CodPermissaoMonitor === null ? user : _monitorPermissions.current,
            displayExpr: e.appointmentData.CodPermissaoMonitor === null ? 'NomUsuario' : 'NomPermissao',
            valueExpr: e.appointmentData.CodPermissaoMonitor === null ? 'CodUsuario' : 'CodPermissao',
          },
          disabled: true,
        },
        {
          colSpan: 2,
          dataField: 'DatTarefaMonitor',
          label: { text: 'Data Monitoração' },
          editorType: 'dxDateBox',
          name: 'DataMonitoracao',
          editorOptions: {
            calendarOptions: { firstDayOfWeek: undefined },
            type: 'date',
            width: '100%',
          },
        },
        {
          colSpan: 2,
          label: { text: 'Tarefa Aprovada Monitor?', visible: true },
          editorType: 'dxCheckBox',
          dataField: 'TipTarefaMonitorada',
          name: 'TarefaAprovadaMonitor',
          editorOptions: {
            visible: false,
            iconSize: "36px",
          },
        },
        {
          itemType: 'button',
          horizontalAlignment: 'center',
          name: 'Sim',
          buttonOptions: {
            text: 'Sim',
            type: 'normal',
            width: '100%',
            height: '50px',
            onClick: () => {
              form.getEditor('TipTarefaMonitorada').option('value', true)
              form.getEditor('Sim').option('type', 'success')
              form.getEditor('Nao').option('type', 'normal')
              _flagExecutedTask.current = true
            },
          },
        },
        {
          itemType: 'button',
          horizontalAlignment: 'center',
          name: 'Nao',
          buttonOptions: {
            width: '100%',
            height: '50px',
            text: 'Não',
            type: 'normal',
            onClick: () => {
              form.getEditor('TipTarefaMonitorada').option('value', false)
              form.getEditor('Sim').option('type', 'normal')
              form.getEditor('Nao').option('type', 'danger')
              _flagExecutedTask.current = true
            },
          },
        }
      )
      form.repaint()
      form.itemOption('mainGroup.TarefaAprovadaMonitor', 'visible', true)
      form.itemOption('mainGroup.DataMonitoracao', 'visible', true)
      form.getEditor('DatTarefaExecutor').option('disabled', true)
      form
        .getEditor('DatTarefaMonitor')
        .option(
          'value',
          e.appointmentData.DatTarefaMonitor && (e.appointmentData.TipSituacao === 3 || e.appointmentData.TipSituacao === 6) ? e.appointmentData.DatTarefaMonitor : new Date()
        )
      form.getEditor('DatTarefaMonitor').option('max', new Date())
    }

    // items do form caso seja verificação de tarefa
    if ((e.appointmentData.TipSituacao === 3 || e.appointmentData.TipSituacao === 6 || e.appointmentData.TipSituacao === 9 || e.appointmentData.TipSituacao === 10)) {
      e.popup.option('title', 'Verificar Tarefa')
      mainGroupItems.push(
        {
          colSpan: 2,
          label: { text: 'Monitor' },
          editorType: 'dxSelectBox',
          dataField: e.appointmentData.CodPermissaoMonitor === null ? 'CodUsuarioMonitor' : 'CodPermissaoMonitor',
          name: 'Monitor',
          editorOptions: {
            items: e.appointmentData.CodPermissaoMonitor === null ? user : _monitorPermissions.current,
            displayExpr: e.appointmentData.CodPermissaoMonitor === null ? 'NomUsuario' : 'NomPermissao',
            valueExpr: e.appointmentData.CodPermissaoMonitor === null ? 'CodUsuario' : 'CodPermissao',
          },
          disabled: true,
        },
        {
          colSpan: 2,
          dataField: 'DatTarefaMonitor',
          label: { text: 'Data Monitoração' },
          editorType: 'dxDateBox',
          name: 'DataMonitoracao',
          editorOptions: {
            calendarOptions: { firstDayOfWeek: undefined },
            type: 'date',
            width: '100%',
          },
        },
        {
          colSpan: 2,
          label: { text: 'Verificador' },
          editorType: 'dxSelectBox',
          dataField: e.appointmentData.CodPermissaoVerificador === null ? 'CodUsuarioVerificador' : 'CodPermissaoVerificador',
          name: 'Verificador',
          editorOptions: {
            items: e.appointmentData.CodPermissaoVerificador === null ? user : _verificadorPermissions.current,
            displayExpr: e.appointmentData.CodPermissaoVerificador === null ? 'NomUsuario' : 'NomPermissao',
            valueExpr: e.appointmentData.CodPermissaoVerificador === null ? 'CodUsuario' : 'CodPermissao',
          },
          disabled: true,
        },
        {
          colSpan: 2,
          dataField: 'DatTarefaVerificador',
          label: { text: 'Data Verificação' },
          editorType: 'dxDateBox',
          name: 'DataVerificacao',
          editorOptions: {
            calendarOptions: { firstDayOfWeek: undefined },
            type: 'date',
            width: '100%',
          },
        },
        {
          colSpan: 2,
          label: { text: 'Tarefa Aprovada Verificador?', visible: true },
          editorType: 'dxCheckBox',
          dataField: 'TipTarefaVerificada',
          name: 'TarefaAprovadaVerificador',
          editorOptions: {
            visible: false,
            iconSize: "36px",
          },
        },
        {
          itemType: 'button',
          horizontalAlignment: 'center',
          name: 'Sim',
          buttonOptions: {
            text: 'Sim',
            type: 'normal',
            width: '100%',
            height: '50px',
            onClick: () => {
              form.getEditor('TipTarefaVerificada').option('value', true)
              form.getEditor('Sim').option('type', 'success')
              form.getEditor('Nao').option('type', 'normal')
              _flagExecutedTask.current = true
            },
          },
        },
        {
          itemType: 'button',
          horizontalAlignment: 'center',
          name: 'Nao',
          buttonOptions: {
            width: '100%',
            height: '50px',
            text: 'Não',
            type: 'normal',
            onClick: () => {
              form.getEditor('TipTarefaVerificada').option('value', false)
              form.getEditor('Sim').option('type', 'normal')
              form.getEditor('Nao').option('type', 'danger')
              _flagExecutedTask.current = false
            },
          },
        }
      )
      form.repaint()
      form.itemOption('mainGroup.DataExecucao', 'visible', true)
      form.itemOption('mainGroup.DataMonitoracao', 'visible', true)
      form.itemOption('mainGroup.DataVerificacao', 'visible', true)
      form.itemOption('mainGroup.TarefaAprovadaMonitor', 'visible', true)
      form.itemOption('mainGroup.TarefaAprovadaVerificador', 'visible', true)
      form.getEditor('DatTarefaExecutor').option('disabled', true)
      form
        .getEditor('DatTarefaVerificador')
        .option(
          'value',
          e.appointmentData.DatTarefaVerificador && (e.appointmentData.TipSituacao === 4 || e.appointmentData.TipSituacao === 8)
            ? e.appointmentData.DatTarefaVerificador
            : new Date()
        )
      form.getEditor('DatTarefaVerificador').option('max', new Date())
    }

    // items do final do form
    mainGroupItems.push(
      {
        colSpan: 2,
        label: { text: 'Observação | NC' },
        editorType: 'dxTextArea',
        dataField: 'DesObservacao',
        validationRules: [{ type: 'required' }],
      },
      {
        colSpan: 2,
        template: 'my-template',
      }
    )

    // propriedades do form
    form.option('colCount', 2)
    form.option('colCountByScreen', { lg: 2, xs: 1 })
    // setando items da coluna da esquerda (mainGroup) e da direita (reccurenceGroup)
    form.itemOption('mainGroup', 'items', mainGroupItems)
    form.itemOption('recurrenceGroup', 'items', recurrenceGroupItems)

    _popUpExecuteTask.current = e
  }
  //#endregion

  function disabledExecuteTask(task) {
    const parDateStartInDayJs = dayjs(task.DatIniciaMonitoracao);
    const findTask = lstTasks.find(x => x.CodTarefa === task.CodTarefa && task.NumTarefa > x.NumTarefa && x.TipSituacao === 1);

    return {
      dayOk: dayjs().isSameOrAfter(parDateStartInDayJs), 
      taskOk: findTask === undefined ? true : findTask
    }
  }

  //#region executeTask
  //STATUS:
  // 1-Aguardando execução, 2-Executada Aguardando Monitoração, 3-Executada Aguardando Verificação, 4-Executada Aprovada,
  // 5-Não Possível Aguardando Monitoração, 6-Não Possível Aguardando Verificação, 7-Não Possível Aprovada, 8-Cancelada
  async function upTask(e) {
    if (_flagExecutedTask.current === null) {
      e.cancel = true
      return notify('Obrigado selecionar no Item "Tarefa Executada?"', 'error', 2000)
    }
    let strLogMessage
    //let imgData

    // Salvar a imagem no banco
    const logMessage = 'Modificou imagem'


    // -----------------------------------
    if (e.newData.DatTarefaExecutor) e.newData.DatTarefaExecutor = dayjs(e.newData.DatTarefaExecutor).format('YYYY-MM-DD[T]HH:mm:ss')
    if (e.newData.DatTarefaMonitor) e.newData.DatTarefaMonitor = dayjs(e.newData.DatTarefaMonitor).format('YYYY-MM-DD[T]HH:mm:ss')
    if (e.newData.DatTarefaVerificador) e.newData.DatTarefaVerificador = dayjs(e.newData.DatTarefaVerificador).format('YYYY-MM-DD[T]HH:mm:ss')

    if ((e.newData.TipSituacao === 2 && e.newData.TipTarefaMonitorada) ||
        (e.newData.TipSituacao === 3 && e.newData.TipTarefaVerificada) ||
        (e.newData.TipSituacao === 5 && e.newData.TipTarefaMonitorada) ||
        (e.newData.TipSituacao === 6 && e.newData.TipTarefaVerificada) ||
        (e.newData.TipSituacao === 9 && e.newData.TipTarefaVerificada) ||
        (e.newData.TipSituacao === 10 && e.newData.TipTarefaVerificada)) {
      if ((e.newData.TipSituacao === 2 || e.newData.TipSituacao === 5) && (e.newData.CodUsuarioMonitor === null || e.newData.CodUsuarioMonitor !== userCod)) {
        e.newData.CodUsuarioMonitor = userCod
      }

      if (
        (e.newData.TipSituacao === 3 || e.newData.TipSituacao === 6 || e.newData.TipSituacao === 9 || e.newData.TipSituacao === 10) &&
        (e.newData.CodUsuarioMonitor === null || e.newData.CodUsuarioMonitor !== userCod)
      ) {
        e.newData.CodUsuarioVerificador = userCod
        strLogMessage = `${formatMessage('VerifiedTask')} ${e.newData.NomTarefa}`
      }

      if (e.newData.TipSituacao === 2 || e.newData.TipSituacao === 5) strLogMessage = `${formatMessage('MonitoredTask')} ${e.newData.NomTarefa}`

      if (e.newData.TipSituacao === 3 || e.newData.TipSituacao === 6 || e.newData.TipSituacao === 9 || e.newData.TipSituacao === 10)
        strLogMessage = `${formatMessage('VerifiedTask')} ${e.newData.NomTarefa}`

      e.newData.TipExecutarTarefa = false
      e.newData.TipAguardandoVerificacao = false
      e.newData.TipCancelarTarefa = false
      e.newData.TipPeriodica ? (e.cancel = updateValuePeriodicTask(e.newData, objectImgToDataBase.current, strLogMessage)) : (e.cancel = updateValueSimpleTask(e.newData, objectImgToDataBase.current, strLogMessage))
    }

    if (e.newData.TipSituacao <= 1) {
      strLogMessage = `${formatMessage('ExecutedTask')} ${e.newData.NomTarefa}`

      if (e.newData.CodUsuarioExecutor === null) e.newData.CodUsuarioExecutor = userCod

      e.newData.TipPeriodica ? (e.cancel = insertValuePeriodicTask(e.newData, objectImgToDataBase.current, strLogMessage)) : (e.cancel = insertValueSimpleTask(e.newData, objectImgToDataBase.current, strLogMessage))
    }

    if ((e.newData.TipSituacao === 2 || e.newData.TipSituacao === 5) && !e.newData.TipTarefaMonitorada) {
      if (e.newData.CodUsuarioMonitor === null || e.newData.CodUsuarioMonitor !== userCod) e.newData.CodUsuarioMonitor = userCod
      setVisiblePopupRepprovedTask(true)
      _appointment.current = e.newData
    }

    if ((e.newData.TipSituacao === 3 || e.newData.TipSituacao === 6 || e.newData.TipSituacao === 9 || e.newData.TipSituacao === 10) && !e.newData.TipTarefaVerificada) {
      if (e.newData.CodUsuarioVerificador === null || e.newData.CodUsuarioVerificador !== userCod) e.newData.CodUsuarioVerificador = userCod
      setVisiblePopupRepprovedTask(true)
      _appointment.current = e.newData
    }
    _scheduler.current.instance.repaint()
  }
  //#endregion

  function onValueChangedExecutionTask(e) {
    setExecutionTask(e.value)
  }

  function onValueChangedMonitorationTask(e) {
    setMonitorationTask(e.value)
  }

  function onValueChangedVerificationTask(e) {
    setVerificationTask(e.value)
  }
  //#endregion

  //#region filters
  function tasksFiltredsByChecksBoxs() {
    let listTasksFiltreds = []

    if (executionTask && !monitorationTask && !verificationTask)
      listTasksFiltreds = lstTasks.filter((task) => task.IdTarefaSituacao === 1)

    if (!executionTask && monitorationTask && !verificationTask)
      listTasksFiltreds = lstTasks.filter((task) => task.IdTarefaSituacao === 2)

    if (!executionTask && !monitorationTask && verificationTask)
      listTasksFiltreds = lstTasks.filter((task) => task.IdTarefaSituacao === 3)

    if (executionTask && monitorationTask && !verificationTask)
      listTasksFiltreds = lstTasks.filter((task) => task.IdTarefaSituacao === 1 || task.IdTarefaSituacao === 2)

    if (executionTask && !monitorationTask && verificationTask)
      listTasksFiltreds = lstTasks.filter((task) => task.IdTarefaSituacao === 1 || task.IdTarefaSituacao === 3)

    if (executionTask && monitorationTask && verificationTask)
      listTasksFiltreds = lstTasks.filter((task) => task.IdTarefaSituacao === 1 || task.IdTarefaSituacao === 2 || task.IdTarefaSituacao === 3)

    if (!executionTask && monitorationTask && verificationTask)
      listTasksFiltreds = lstTasks.filter((task) => task.IdTarefaSituacao === 2 || task.IdTarefaSituacao === 3)

    if (!executionTask && !monitorationTask && !verificationTask && _event.current)
      listTasksFiltreds = lstTasks;

    if (_event.current !== undefined) {
      _event.current._dataSource._items = listTasksFiltreds;
      _event.current.repaint();
    }
  }
  //#endregion

  // #region ImageUplaoder
  // ------------------------------------------------------------------------------- //
  const formItemRender = () => {
    return (
      <ImageUploaderInline
        multiple={true}
        numImgs={10}
        objImgFromDataBase={imgs.current}
        objImgToDataBase={objectImgToDataBase}
        disabledButton={false}
        buttonType={true}
      />
    )
  }
  // #endregion

  function formatDate(date) {
    return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
  }

  return (
    <React.Fragment>
      <div className="Container">
        <div className="containerlegends">
          <div className="ContainerLegendCheckBox">
            <CheckBox defaultValue={false} text={'Tarefa Execução'} className={'ContainerCheckBox'} onValueChanged={onValueChangedExecutionTask} />
            <div className="color-boxExecuted" />
          </div>
          <div className="ContainerLegendCheckBox">
            <CheckBox defaultValue={false} text={'Tarefa Monitoração'} className={'ContainerCheckBox'} onValueChanged={onValueChangedMonitorationTask} />
            <div className="color-boxMonitored" />
          </div>
          <div className="ContainerLegendCheckBox">
            <CheckBox defaultValue={false} text={'Tarefa Verificação'} className={'ContainerCheckBox'} onValueChanged={onValueChangedVerificationTask} />
            <div className="color-boxVerificad" />
          </div>
        </div>
        <FavButton />
      </div>
      <Scheduler
        adaptivityEnabled={true}
        className={'dx-cards'}
        timeZone="America/Recife"
        dataSource={lstTasks}
        views={views}
        defaultCurrentView={'agenda'}
        defaultCurrentDate={currentDate}
        onContentReady={onInicializedScheduler}
        recurrenceEditMode={'series'}
        textExpr={'NomTarefa'}
        startDateExpr={'DatIniciaMonitoracao'}
        endDateExpr={'DatFinalizaMonitoracao'}
        descriptionExpr={'DesTarefa'}
        onAppointmentFormOpening={renderAppointmentFormOpening}
        onAppointmentUpdating={upTask}
        useDropDownViewSwitcher={false}
        editing={{ allowDeleting: false }}
        ref={_scheduler}
        cellDuration={30}
        maxAppointmentsPerCell={3}
        onCurrentViewChange={(e) => {
          if(e === "day") {
            currentDate = new Date();
            _scheduler.current.instance.option("currentDate", currentDate);
          }

          if(e === "agenda") {
            currentDate = new Date();
            const currentWeekDay = currentDate.getDay();

            if(currentWeekDay > 1) {
              currentDate = currentDate.setDate(currentDate.getDate() - (currentWeekDay - 1));

              _scheduler.current.instance.option("currentDate", currentDate);
            }
          }
        }}
      >
        <Resource dataSource={resourcesData} label="Tipo" fieldExpr={"IdTarefaSituacao"} useColorAsDefault={true} />
        <AppointmentDragging 
          onDragEnd={(e) => {
            e.cancel = true
            e.component.repaint()
          }}
        />
        <Template name={'my-template'} render={formItemRender} />
      </Scheduler>
      <PopupRepprovedTask
        visiblePopupRepprovedTask={visiblePopupRepprovedTask}
        setVisiblePopupRepprovedTask={setVisiblePopupRepprovedTask}
        responsePopupRepprovedTask={responsePopupRepprovedTask}
        setResponsePopupRepprovedTask={setResponsePopupRepprovedTask}
        setVisiblePopupReopenTask={setVisiblePopupReopenTask}
        popupExecuteTask={_popUpExecuteTask.current}
      />
      <PopupReopenTask
        visiblePopupReopenTask={visiblePopupReopenTask}
        setVisiblePopupReopenTask={setVisiblePopupReopenTask}
        responsePopupReopenTask={responsePopupReopenTask}
        setResponsePopupReopenTask={setResponsePopupReopenTask}
        setVisiblePopupCancelTask={setVisiblePopupCancelTask}
        responsePopupRepprovedTask={responsePopupRepprovedTask}
        updateValueSimpleTask={updateValueSimpleTask}
        updateValuePeriodicTask={updateValuePeriodicTask}
        appointment={_appointment.current}
      />
      <PopupCancelTask
        visiblePopupCancelTask={visiblePopupCancelTask}
        setVisiblePopupCancelTask={setVisiblePopupCancelTask}
        responsePopupCancelTask={responsePopupCancelTask}
        setResponsePopupCancelTask={setResponsePopupCancelTask}
        responsePopupRepprovedTask={responsePopupRepprovedTask}
        responsePopupReopenTask={responsePopupReopenTask}
        updateValueSimpleTask={updateValueSimpleTask}
        updateValuePeriodicTask={updateValuePeriodicTask}
        appointment={_appointment.current}
      />
    </React.Fragment>
  )
}