import React from "react";
import redo from "../../icons/redo.svg";
import { Button } from "devextreme-react/button";
import "./style.scss";
import {useScreenSize} from "../../utils/media-query";

export const ReportsButton = () => {
	const { isXSmall, isSmall, isXLSmall } = useScreenSize()

	const reportClick = () => {
		switch (window.location.hash) {
			case "#/createTask":
				window.open(`${localStorage.getItem("@es-mes/addressReports")}/finishedtasks#/tasksconfigurations`);
			break;
			case "#/sample":
			  window.open(`${localStorage.getItem("@es-mes/addressReports")}#/analysis`);
			break;
			case "#/variableInputByVariable":
			  window.open(`${localStorage.getItem("@es-mes/addressReports")}#/datamonitoring`);
			break;
			case "#/variableInputByDate":
			  window.open(`${localStorage.getItem("@es-mes/addressReports")}#/datamonitoring`);
			break;
			case "#/areaProcessStop":
				window.open(`${localStorage.getItem("@es-mes/addressReports")}#/stops`);
				break;
			case "#/processStop":
				window.open(`${localStorage.getItem("@es-mes/addressReports")}#/stops`);
				break;
			default:
			  break;
		}
	};
	
	return (
			<div>
				<Button
					className={"ReportsButton"} 
					text="Relatório"
					stylingMode={"outlined"}
					hint="Abrir relatório"
					icon={redo}
					visible={!isXSmall && !isXLSmall && !isSmall}
					onClick={reportClick}
				/>
			</div>
	);
};
