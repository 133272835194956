/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "./style.scss";
import DataGrid, {
  Column,
  Pager,
  RequiredRule,
  Paging,
  FilterRow,
  Export,
  Selection,
  ColumnChooser,
  Grouping,
  GroupPanel,
  ColumnFixing,
} from "devextreme-react/data-grid";
import {  formatMessage } from "devextreme/localization";
import { api } from "../../services/api";
import { useAuth } from "../../contexts/auth";
import { AddButtonGrid } from "../../components/add-new-button/addButtonGrid";
import { LogMessageGrid } from "../../utils/logMessage";
import { useLoading } from "../../contexts/loadingContext";

export default function Transport() {
  const { loading } = useLoading();
  const { refreshToken, getRefreshTokenCode, getToken } = useAuth();
  const [load, setLoad] = useState(false);
  const [transport, setTransport] = useState([]);
  const _transport = useRef();

  //Autenticação requests
  const { unitCod } = useAuth();

  useEffect(() => {
    async function firstLoad(){
      //if (await validateToken(token.current)){
        setLoad(true);
      //}
    }
    firstLoad();
    refreshToken(getToken(), getRefreshTokenCode());
  }, []);

  useEffect(() => {
    if (load){
      async function loadData() {
        await api 
        .get("/transport", { params: { unitCod: unitCod } })
          .then((response) => {
            setTransport(response.data);
            setLoad(false);
          })
          .catch((error) => {
            setLoad(true);
    
            return true;
          });
      }
      loadData();
    }
  }, [load]);

  async function updateRow(data, parLogMessage) {
    return await api
      .put("/transport", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);
        
        return true;
      });
  }

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/transport", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);
        

        return true;
      });
  }

  async function deleteRow(data) {
    return await api
      .delete("/transport", { data: data })
      .then((response) => {
        setLoad(true);

        return false;
      })
      .catch((error) => {
        setLoad(true);
        

        return true;
      });
  }

  const onEditorPreparing = (e) => {

      if (e.dataField === "NomTransporte" && e.parentType === "dataRow")  
        e.editorOptions.maxLength = 100

  }

  /*const onToolbarPreparing = (e) => {
      var dataGrid = e.component;

      e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
              elementAttr: {id: 'ToolbarAddNewButton'},
              hint: "Adicionar um registro",
              icon: "add",
              onClick: () => { dataGrid.addRow() }
          },
      });
  }*/

  return (
    <React.Fragment>
     {/*  <h2 className="content-block">Cadastro de Transportes</h2> */}
      <div>
        <DataGrid
          className={"dx-cards"}
          dataSource={transport}
          rowAlternationEnabled={true}
          allowColumnReordering={true}
          showColumnLines={true}
          //keyExpr={"CodTransporte"}
          showBorders={false}
          columnAutoWidth={false}
          columnHidingEnabled={true}
          repaintChangesOnly={true}
          onEditorPreparing={onEditorPreparing}
          headerFilter={{ visible: true }}
          filterPanel={{ visible: true }}
          filterRow={{ visible: false }}
          onToolbarPreparing={(e) => AddButtonGrid(e)}
          onInitNewRow={(e) => {
            e.data.CodUnidadeEmpresa = unitCod
          }}
          editing={{
            refreshMode: "full",
            mode:"batch",
            allowUpdating: true,
            allowDeleting: true,
            allowAdding: false,           
          }}
          onRowUpdating={async (e) => {
            const newObj = { ...e.oldData, ...e.newData };
            let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _transport, newObj);

            e.cancel = updateRow(newObj, strLogMessage);

            e.component.deselectAll();
          }}
          onRowInserting={(e) => {
            let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _transport, e.data);

            e.cancel = insertRow(e.data, strLogMessage);

            e.component.deselectAll();
          }}
          onRowRemoving={(e) => {
            let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _transport, e.data)
            const data = { unitCod: unitCod,  data: e.data, logMessage: strLogMessage }

            e.cancel = deleteRow(data);

            e.component.deselectAll();
          }}
          ref={_transport}
        >
          <Grouping
            contextMenuEnabled={false}
            expandMode="rowClick"
            autoExpandAll={false}
          />
          <GroupPanel visible={false} /> 
          <Export
            enabled={true}
            fileName="Cadastro_Transporte"
            allowExportSelectedData={true}
          />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={false} />
          <Selection mode="multiple" />
          <Paging defaultPageSize={20} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <FilterRow visible={true} />
          <Column
            dataField={"CodTransporteIntegracao"}
            caption={"Código Integração"}
            allowSorting={true}
            width={80}
          >
          </Column>  
          <Column
            dataField={"NomTransporte"}
            caption={"Nome"}
            allowSorting={true}
            width={"auto"}
          >
            <RequiredRule />
          </Column>  
        </DataGrid>
      </div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
