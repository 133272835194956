import React, { useState } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/form";
import { LoadIndicator } from "devextreme-react/load-indicator";
import User from "./user";
import UserPermissionTab from "./userPermission";
import AcessUnits from "./acessUnits";
import { useLoading } from "../../../contexts/loadingContext";
import { FavButton } from "../../../components/fav-button/favButton";

export default function UserTab() {
  const { loading } = useLoading();
  const [update, setUpdate] = useState(0);
  const [userRegistred, setUserRegistred] = useState();

  /*useEffect(() => {
    async function firstLoad() {
      if (typeof data !== "undefined") {
        setUserTab(data.getUserTab);
        setUserPermissionTab(data.getUserPermissionTab);
      }
    }
    firstLoad();
  }, [loading]);*/

  const renderUserTab = () => {
    return <User
              update={update}
              setUpdate={setUpdate}
              setUserRegistred={setUserRegistred}
            />;
  };

  const renderUserPermissionTab = () => {
    return <UserPermissionTab
              update={update}
            />;
  };

  const renderAcessUnits = () => {
    return <AcessUnits
              setUserRegistred={setUserRegistred}
              userRegistred={userRegistred}
              update={update}
            />;
  }

  return (
    <React.Fragment>
      <FavButton/>
      <TabPanel
        className={"tab-panel-margin"}
        style={{width: "100%"}}
        animationEnabled={true}
        swipeEnabled={false}
      >
        <Item title="Cadastro de Usuários" render={renderUserTab} />
        <Item title="Permissão dos Usuários" render={renderUserPermissionTab} />
        <Item title="Acesso Unidades" render ={renderAcessUnits}/>
      </TabPanel>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
