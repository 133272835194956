/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useRef} from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import {LoadIndicator} from "devextreme-react/load-indicator";
import "../style.scss";
import DataGrid, {
    Column,
    Pager,
    RequiredRule,
    Paging,
    Export,
    Selection,
    ColumnChooser,
    Grouping,
    Lookup,
    GroupPanel,
    ColumnFixing,
    CustomRule,
    Editing,
    Form,
    Popup,
    Position,
    Item, Toolbar,
} from "devextreme-react/data-grid";
import {api} from "../../../services/api";
import {useAuth} from "../../../contexts/auth";
import {LogMessageGrid} from "../../../utils/logMessage";
import {formatMessage} from "devextreme/localization";
import {useLoading} from "../../../contexts/loadingContext";
import {loadDataSectors} from "../../../utils/functionRequests";
import {useScreenSize} from "../../../utils/media-query";
import Button from "devextreme-react/button";

export default function AreaProcess(props) {
    const {loading} = useLoading();
    const [load, setLoad] = useState(false);
    const {isLarge} = useScreenSize();
    const [areaprocess, setAreaProcess] = useState([]);
    const [dataSourceProcess, setDataSourceProcess] = useState([]);
    const [dataSourceReadVariable, setDataSourceReadVariable] = useState([]);
    const [lstSectors, setLstSectors] = useState([]);
    const _areaProcess = useRef();
    const { unitCod } = useAuth();

    useEffect(() => {
        async function firstLoad() {
            loadDataSectors(unitCod, setLstSectors, setLoad);
            loadDataSourceProcess();
            await loadDataSourceReadVariable();
            loadData();
        }
        firstLoad();
    }, [props.update]);

    useEffect(() => {
        if(load)
            loadData();
    }, [load]);

    async function loadDataSourceProcess() {
        await api
            .get("/process", {params: {unitCod: unitCod}})
            .then((response) => {
                setDataSourceProcess(response.data);
                setLoad(false);
            })
            .catch(() => {
                setLoad(true);

                return true;
            });
    }

    async function loadDataSourceReadVariable() {
        await api
            .get("/readingvariableOPC/getVariableOPC", {params: {unitCod: unitCod, tipVariableOPC: 1}})
            .then((response) => {
                setDataSourceReadVariable(response.data);
                setLoad(false);
            })
            .catch(() => {
                setLoad(true);

                return true;
            });
    }

    async function loadData() {
        await api
            .get("/areaprocess", {params: {unitCod: unitCod}})
            .then((response) => {
                setAreaProcess(response.data);
            })
            .catch(() => {
                setLoad(true);

                return true;
            });
    }

    async function updateRow(data, parLogMessage) {
        return await api
            .put("/areaprocess", {unitCod: unitCod, data: data, logMessage: parLogMessage})
            .then(() => {
                setLoad(!load);
                return false;
            })
            .catch(() => {
                return true;
            });
    }

    async function insertRow(data, parLogMessage) {
        return await api
            .post("/areaprocess", {unitCod: unitCod, data: data, logMessage: parLogMessage})
            .then(() => {
                setLoad(!load);
                return false;
            })
            .catch(() => {
                return true;
            });
    }

    async function deleteRow(data) {
        return await api
            .delete("/areaprocess", {data: data})
            .then(() => {
                setLoad(!load);
                return false;
            })
            .catch(() => {
                return true;
            });
    }

    const onEditorPreparing = (e) => {
        if (e.dataField === "CodVariavelLeitura" && e.parentType === "dataRow") {
            e.editorOptions.disabled = !e.row.data.TipFuncionandoAutomatico;
            e.editorOptions.value = null;
        }

        if (e.dataField === "TipFuncionandoAutomatico" && e.parentType === "dataRow") e.editorOptions.disabled = !e.row.data.TipPermiteParada;
    };

    function setCellValue(newData, value) {
        newData.TipPermiteParada = value;
        newData.TipFuncionandoAutomatico = false;
        this.defaultSetCellValue(newData, value);
    }

    function setCellValueTipFuncionandoAutomatico(newData, value) {
        newData.TipFuncionandoAutomatico = value;
        this.defaultSetCellValue(newData, value);
    }

    function checkUniqueKeyIntegration(params) {
        let check = true;
        areaprocess.forEach((unit) => {
            if (
                unit.CodAreaProcesso !== params.data.CodAreaProcesso &&
                unit.CodAreaProcessoIntegracao === params.data.CodAreaProcessoIntegracao &&
                params.data.CodAreaProcessoIntegracao !== null
            )
                check = false;
        });

        return check;
    }

    function checkUniqueKey(params) {
        let check = true;

        areaprocess.forEach((unit) => {
            if (
                unit.CodAreaProcesso !== params.data.CodAreaProcesso &&
                unit.NomAreaProcesso === params.data.NomAreaProcesso &&
                unit.CodProcesso === params.data.CodProcesso
            )
                check = false;
        });

        return check;
    }

    function sectorAndProcess(process) {
        let strSectorAndProcess;

        lstSectors.forEach((sector) => {
            if (process.CodSetor === sector.CodSetor) strSectorAndProcess = `${sector.NomSetor} - ${process.NomProcesso}`;
        });
        return strSectorAndProcess;
    }

    return (
        <React.Fragment >
            <div id="receiptsGrid">
                <DataGrid
                    className={"dx-cards"}
                    dataSource={areaprocess}
                    rowAlternationEnabled={true}
                    showColumnLines={true}
                    allowColumnReordering={true}
                    showBorders={false}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    repaintChangesOnly={false}
                    onEditorPreparing={onEditorPreparing}
                    onInitNewRow={(e) => {
                        e.data.CodUnidadeEmpresa = unitCod;
                        e.data.TipPermiteParada = true;
                        e.data.TipFuncionando = true;
                        e.data.TipFuncionandoAutomatico = false;
                    }}
                    headerFilter={{visible: true}}
                    filterPanel={{visible: true}}
                    filterRow={{visible: false}}
                    onRowUpdating={async (e) => {
                        const newObj = {...e.oldData, ...e.newData};
                        let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _areaProcess, newObj);

                        e.cancel = updateRow(newObj, strLogMessage);
                        e.component.deselectAll();
                    }}
                    onRowInserting={(e) => {
                        let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _areaProcess, e.data);

                        e.cancel = insertRow(e.data, strLogMessage);
                        e.component.deselectAll();
                    }}
                    onRowRemoving={(e) => {
                        let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _areaProcess, e.data);
                        const data = {unitCod: unitCod, data: e.data, logMessage: strLogMessage};

                        e.cancel = deleteRow(data);
                        e.component.deselectAll();
                    }}
                    ref={_areaProcess}>
                    <Editing mode={isLarge ? "batch" : "popup"} allowUpdating={true} allowDeleting={true} allowAdding={false}>
                        <Popup
                            title="Cadastro de Área"
                            showTitle={true}
                            width={700}
                            height={750}
                            fullScreen={true}>
                            <Position my="center" at="center" of={window}/>
                        </Popup>
                        <Form>
                            <Item itemType="group" colCount={4} colSpan={2}>
                                <Item colSpan={2} dataField="NomAreaProcesso"/>
                                <Item colSpan={2} dataField="TipPermiteParada"/>
                                <Item colSpan={2} dataField="TipFuncionandoAutomatico"/>
                                <Item colSpan={2} dataField="CodProcesso"/>
                                <Item colSpan={2} dataField="CodVariavelLeitura"/>
                                <Item colSpan={2} dataField="CodAreaProcessoIntegracao"/>
                                <Item colSpan={1} dataField="TipFuncionando"/>
                            </Item>
                        </Form>
                    </Editing>
                    <Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false}/>
                    <GroupPanel visible={false}/>
                    <Export enabled={true} fileName="Cadastro_Area" allowExportSelectedData={true}/>
                    <ColumnChooser enabled={true} mode="select"/>
                    <ColumnFixing enabled={false}/>
                    <Selection mode="multiple"/>
                    <Paging defaultPageSize={20}/>
                    <Pager showPageSizeSelector={true} showInfo={true}/>
                    <Toolbar>
                        <Item cssClass={'add-container'}>
                            <Button className={'add-button'} icon={"add"} onClick={() => _areaProcess.current.instance.addRow()}/>
                        </Item>
                        <Item name="saveButton"/>
                        <Item name="revertButton"/>
                        <Item name="exportButton"/>
                        <Item name="columnChooserButton"/>
                    </Toolbar>
                    <Column dataField={"NomAreaProcesso"} caption={"Nome Área"} hidingPriority={6} allowSorting={true} minWidth={100}>
                        <CustomRule
                            message={"A combinação entre o Nome da Área e o Setor - Processo deve ser única"}
                            reevaluate={true}
                            type={"custom"}
                            validationCallback={checkUniqueKey}
                        />
                        <RequiredRule/>
                    </Column>
                    <Column
                        dataField={"TipPermiteParada"}
                        caption={"Permite Parada?"}
                        dataType={"boolean"}
                        setCellValue={setCellValue}
                        allowSorting={true}
                        hidingPriority={5}
                        width={"auto"}
                    />
                    <Column
                        dataField={"TipFuncionandoAutomatico"}
                        caption={"Parada Automática"}
                        dataType={"boolean"}
                        cssClass={"word-wrap"}
                        setCellValue={setCellValueTipFuncionandoAutomatico}
                        allowSorting={true}
                        hidingPriority={4}
                        width={"auto"}
                    />
                    <Column
                        dataField={"CodProcesso"}
                        caption={"Setor - Processo"}
                        minWidth={100}
                        hidingPriority={3}
                        allowSorting={true}
                        sortIndex={0}
                    >
                        <Lookup dataSource={dataSourceProcess} displayExpr={e => sectorAndProcess(e)} valueExpr="CodProcesso"/>
                        <RequiredRule/>
                    </Column>
                    <Column
                        dataField={"CodVariavelLeitura"}
                        caption={"Variável Leitura"}
                        minWidth={100}
                        hidingPriority={2}
                        allowSorting={true}
                        sortIndex={0}
                    >
                        <Lookup
                            dataSource={dataSourceReadVariable}
                            allowClearing={true}
                            displayExpr="NomVariavelLeitura"
                            valueExpr="CodVariavelLeitura"
                        />
                    </Column>
                    <Column 
                        dataField={"CodAreaProcessoIntegracao"} 
                        caption={"Código Integração"}
                        dataType={"number"}
                        hidingPriority={7}
                        width={150}
                        allowSorting={true}
                    >
                        <CustomRule
                            message={"Este campo deve ser único"}
                            reevaluate={true}
                            type={"custom"}
                            validationCallback={checkUniqueKeyIntegration}
                        />
                    </Column>
                    <Column
                        dataField={"TipFuncionando"}
                        caption={"Ativo"}
                        dataType={"boolean"}
                        width={"10%"}
                        setCellValue={setCellValue}
                        allowSorting={true}
                        hidingPriority={1}
                    />
                </DataGrid>
            </div>
            <div className="load-indicator">
                <LoadIndicator visible={loading} height={40} width={40}/>
            </div>
        </React.Fragment>
    );
}
