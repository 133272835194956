/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../../components/common/style.scss";
import "devextreme/data/odata/store";
import { LogMessageGrid } from "../../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  CustomRule,
  Editing,
  FilterRow,
  Grouping,
  GroupPanel,
  Item,
  Lookup,
  Pager,
  Paging,
  RequiredRule, 
  Toolbar,
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useLoading } from "../../../contexts/loadingContext";
import { useAuth } from "../../../contexts/auth";
import { LoadIndicator } from "devextreme-react/load-indicator";
import Button from "devextreme-react/button";

export default function MonitorationBlockVariable(props) {
    const { loading } = useLoading();
    const [load, setLoad] = useState(false);
    const [monitorationBlock, setMonitorationBlock] = useState([]);
    const [monitorationBlockVariable, setMonitorationBlockVariable] = useState([]);
    const [readingVariable, setReadingVariable] = useState([]);
    const [monitorationBlockValueColumnsName, setMonitorationBlockValueColumnsName] = useState([]);
    const _monitorationBlock = useRef();
    const _codMonitorationBlock = useRef(0);
    const { unitCod } = useAuth();

    //#region useEffect
    useEffect(() => {
        loadMonitorationBlockData();
    }, [props.update]);

    useEffect(() => {
        async function requestLoad() {
            await loadReadingVariableData()
            await loadMonitorationBlockColumnsNames()
            loadData()
        }
        requestLoad()
    }, [])

    useEffect(() => {
        if (load) {
            loadData()
            setLoad(false)
          }
    }, [load]);
    //#endregion

    useEffect(() => {
        loadMonitorationBlockData();
    }, [props.update])

    //#region LoadData
    async function loadData() {
        await api
        .get("/monitorationBlockVariable", { params: { unitCod: unitCod } })
        .then((response) => {
            setMonitorationBlockVariable(response.data);
        })
        .catch(() => {
            return true;
        });
    }

    async function loadMonitorationBlockData() {
        await api
            .get("/MonitorationBlock", { params: { unitCod: unitCod } })
            .then((response) => {
                setMonitorationBlock(response.data);
            })
            .catch(() => {
                return true;
            });
    }

    async function loadReadingVariableData() {
        await api
            .get("/ReadingVariable", { params: { unitCod: unitCod } })
            .then((response) => {
                setReadingVariable(response.data);
            })
            .catch(() => {
                return true;
            });
    }

    async function loadMonitorationBlockColumnsNames() {
        await api
            .get("/monitorationBlockValue/getColumnsNames")
            .then((response) => {
                setMonitorationBlockValueColumnsName(response.data);
            })
            .catch(() => {
                return true;
            });
    }

    async function updateRow(data, parLogMessage) {
        return await api
        .put("/monitorationBlockVariable", {
            unitCod: unitCod,
            data: data,
            logMessage: parLogMessage
        })
        .then(() => {
            setLoad(!load);
        })
        .catch(() => {
            return true;
        });
    }

    async function insertRow(data, parLogMessage) {
        return await api
        .post("/monitorationBlockVariable", {
            unitCod: unitCod,
            data: data,
            logMessage: parLogMessage
        })
        .then(() => {
            setLoad(!load);
            return false;
        })
        .catch(() => {
            return true;
        });
    }

    async function deleteRow(data) {
        return await api
        .delete("/monitorationBlockVariable", { data: data })
        .then(() => {
            setLoad(!load);
            return false;
        })
        .catch(() => {
            return true;
        });
    }
    //#endregion

    function setCellValue(newData, monitorationBlock){
        newData.CodBlocoMonitoracao = monitorationBlock;
        newData.CodVariavelLeitura = "";
        _codMonitorationBlock.current = monitorationBlock;

        this.defaultSetCellValue(newData, monitorationBlock);
    }

    function filterReadingVariable(){
        if(!_codMonitorationBlock.current) return readingVariable

        const usedMonitorationBlock = monitorationBlockVariable.filter(val => val.CodBlocoMonitoracao === _codMonitorationBlock.current)
        _codMonitorationBlock.current = 0;

        return readingVariable.filter(readVar => 
            !usedMonitorationBlock.find(val => val.CodVariavelLeitura === readVar.CodVariavelLeitura)
        )
    }

    function checkDescription(params) {
        const searched = monitorationBlockVariable.find(x => x.CodBlocoMonitoracao === params.data.CodBlocoMonitoracao &&
                                                                  x.CodBlocoMonitoracaoVariavel != params.data.CodBlocoMonitoracaoVariavel &&          
                                                                  x.DesCampoBlocoMonitoracaoValor == params.data.DesCampoBlocoMonitoracaoValor);

        if(searched === undefined)
            return true;

        return false;
    }

    return (
        <React.Fragment>
        <DataGrid
            className={"dx-cards"}
            dataSource={monitorationBlockVariable}
            rowAlternationEnabled={true}
            showColumnLines={true}
            allowColumnReordering={true}
            showBorders={false}
            columnHidingEnabled={true}
            columnAutoWidth={true}
            repaintChangesOnly={false}
            headerFilter={{ visible: true }}
            filterPanel={{ visible: true }}
            filterRow={{ visible: false }}
            onRowUpdating={async (e) => {
                const newObj = { ...e.oldData, ...e.newData };
                let strLogMessage = LogMessageGrid(
                    formatMessage("UpdatedLog"),
                    _monitorationBlock,
                    newObj
                );
                e.cancel = updateRow(newObj, strLogMessage);
                e.component.deselectAll();
            }}

            onRowInserting={async (e) => {
                let strLogMessage = LogMessageGrid(
                    formatMessage("InsertedLog"),
                    _monitorationBlock,
                    e.data
                );
                e.cancel = insertRow(e.data, strLogMessage);
                e.component.deselectAll();
            }}

            onRowRemoving={(e) => {
                let strLogMessage = LogMessageGrid(
                    formatMessage("DeletedLog"),
                    _monitorationBlock,
                    e.data
                );

                const data = {
                    unitCod: unitCod,
                    data: e.data,
                    logMessage: strLogMessage,
                };
                e.cancel = deleteRow(data);
                e.component.deselectAll();
            }}
            ref={_monitorationBlock}
        >
            <Editing
                mode={"row"}
                allowUpdating={true}
                allowDeleting={true}
                allowAdding={false}
            />
            <Grouping contextMenuEnabled={false} expandMode="rowClick" autoExpandAll={false}/>
            <GroupPanel visible={false} />
            <ColumnChooser enabled={true} mode="select" />
            <ColumnFixing enabled={false} />
            <Paging defaultPageSize={20} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />
            <Toolbar>
                <Item cssClass={'add-container'}>
                    <Button className={'add-button'} icon={"add"} onClick={() => _monitorationBlock.current.instance.addRow()}/>
                </Item>
                <Item name="saveButton"/>
                <Item name="revertButton"/>
                <Item name="exportButton"/>
                <Item name="columnChooserButton"/>
            </Toolbar>
            <Column
                dataField={"CodBlocoMonitoracao"}
                caption={"Bloco de Monitoração"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
                setCellValue={setCellValue}
            >
                <RequiredRule />
                <Lookup
                    dataSource={monitorationBlock}
                    displayExpr={"NomBlocoMonitoracao"}
                    valueExpr="CodBlocoMonitoracao"
                />
            </Column>
            <Column
                dataField={"CodVariavelLeitura"}
                caption={"Variavel Leitura"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
                <RequiredRule />
                <Lookup
                    dataSource={filterReadingVariable}
                    displayExpr={"NomVariavelLeitura"}
                    valueExpr="CodVariavelLeitura"
                />
            </Column>
            <Column
                dataField={"DesCampoBlocoMonitoracaoValor"}
                caption={"Descrição"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
            >
                <Lookup
                    dataSource={monitorationBlockValueColumnsName}
                />
                <CustomRule 
                    message={'Este Bloco de Monitoração já possui este campo de Descrição.'}
                    reevaluate={true}
                    type={"custom"}
                    validationCallback={checkDescription}
                />
                <RequiredRule />
            </Column>
            <Column
                dataField={"TipAgrupamentoPrioridade"}
                caption={"Prioridade"}
                allowSorting={true}
                allowFiltering={true}
                allowSearch={true}
                allowGrouping={true}
                allowEditing={true}
                dataType={'number'}
            >
            </Column>
        </DataGrid>
        <div className="load-indicator">
            <LoadIndicator visible={loading} height={40} width={40} />
        </div>
        </React.Fragment>
    );
};
