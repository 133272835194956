import React from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import "./style.scss";
import Popup from 'devextreme-react/popup';
import { useScreenSize } from '../../../utils/media-query';
import { Button } from "devextreme-react";

export default function PopupConfirmModifyAnalysis(props) {
    const { isXSmall} = useScreenSize();

    return (
      <Popup
        className={"pop-up"}
        width={isXSmall ? "80%" : 450}
        height={145+(props.groupChanges.length*10)}
        visible={props.visiblePopupConfirmModifyAnalysis}
        showTitle={false}
      >
      <div className="ContainerInfo">
        <p>Tipo DIÁRIO ou ESPECIFICAR NA AMOSTRA tem que ser configurado em todas as análises do grupo, deseja salvar mesmo assim?</p>
        {props.groupChanges.map(group => 
          <div key={group}>Verificar grupo {group}</div>
        )}
        <div className="ContainerButtons">
          <Button
            text="SIM"
            type="default"
            stylingMode="text"
            onClick={() => {
                props.updatedConfirmed();
                props.setVisiblePopupConfirmModifyAnalysis(false);
            }}
          />
          <Button
            text="NÃO"
            type="default"
            stylingMode="text"
            onClick={() => {
                props.setVisiblePopupConfirmModifyAnalysis(false);
                props.clearUpdated();
                props.setLoad(true);
            }}
          />
        </div>
      </div>
      </Popup>
    )
}