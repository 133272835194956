import React, { useState } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/form";
import Company from "./company";
import Unit from "./unit";
import { FavButton } from "../../../components/fav-button/favButton";

export default function CompanyAndUnitTab() {
	const [update, setUpdate] = useState(0);

	const renderCompanyTab = () => {
		return <Company update={update} setUpdate={setUpdate} />;
	};

	const renderUnitTab = () => {
		return <Unit update={update} setUpdate={setUpdate} />;
	};

	return (
		<React.Fragment>
			<FavButton />
				<TabPanel className={"tab-panel-margin"} animationEnabled={true} swipeEnabled={false}>
					<Item title="Empresa" render={renderCompanyTab} />
					<Item title="Unidade Industrial" render={renderUnitTab} />
				</TabPanel>
		</React.Fragment>
	);
}
