/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "devextreme/data/odata/store";
import "../../../components/common/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "../style.scss";
import DataGrid, {
  Column,
  Pager,
  RequiredRule,
  Paging,
  FilterRow,
  Export,
  Selection,
  ColumnChooser,
  Grouping,
  GroupPanel,
  ColumnFixing, Item, Toolbar,
} from "devextreme-react/data-grid";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { LogMessageGrid } from "../../../utils/logMessage";
import { formatMessage } from "devextreme/localization";
import { useLoading } from "../../../contexts/loadingContext";
import Button from "devextreme-react/button";

export default function ProductMeasure(props) {
  const { loading } = useLoading();
  const { refreshToken, getRefreshTokenCode, getToken } = useAuth();
  const [load, setLoad] = useState(false);
  const [dataSourceMeasureValue, setDataSourceMeasureValue] = useState([]);
  const _measure = useRef();
  //Autenticação requests
  const { unitCod } = useAuth();

  useEffect(() => {
    async function firstLoad(){
      //if (await validateToken(token.current)){
        if(unitCod !== undefined) {
          loadData();
          refreshToken(getToken(), getRefreshTokenCode());
        }
      //}
    }
    firstLoad();
  }, []);

  useEffect(() => {
    if(load){
      loadData();
    }
  }, [load]);

  async function loadData() {
    await api
      .get("/measurevalue", { params: { unitCod: unitCod } })
      .then((response) => {
        setDataSourceMeasureValue(response.data);
        setLoad(false);
      })
      .catch(() => {
        setLoad(true);

        return true;
      });
  }

  async function updateRow(data, parLogMessage) {
    return await api
      .put("/measurevalue", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then(() => {
        setLoad(true);
        props.setUpdate(!props.update)
        return false;
      })
      .catch(() => {
        setLoad(true);

        return true;
      });
  }

  async function insertRow(data, parLogMessage) {
    return await api
      .post("/measurevalue", { unitCod: unitCod, data: data, logMessage: parLogMessage })
      .then(() => {
        setLoad(true);
        props.setUpdate(!props.update)
        return false;
      })
      .catch(() => {
        setLoad(true);

        return true;
      });
  }

  async function deleteRow(data) {
    return await api
      .delete("/measurevalue", { data: data })
      .then(() => {
        setLoad(true);
        props.setUpdate(!props.update)
        return false;
      })
      .catch(() => {
        setLoad(true);

        return true;
      });
  }

  const onEditorPreparing = (e) => {
      if (e.dataField === "NomUnidadeValor" && e.parentType === "dataRow")
        e.editorOptions.maxLength = 100;

        if (e.dataField === "NomSiglaUnidadeValor" && e.parentType === "dataRow")
        e.editorOptions.maxLength = 20;
  }

  /*const onToolbarPreparing = (e) => {
      var dataGrid = e.component;

      e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
              elementAttr: {id: 'ToolbarAddNewButton'},
              hint: "Adicionar um registro",
              icon: "add",
              onClick: () => { dataGrid.addRow() }
          },
      });
  }*/

  return (
    <React.Fragment>
      <div id="processGrid">
        <DataGrid
          className={"dx-cards"}
          dataSource={dataSourceMeasureValue}
          rowAlternationEnabled={true}
          showColumnLines={true}
          allowColumnReordering={true}
          showBorders={false}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          repaintChangesOnly={false}
          onEditorPreparing={onEditorPreparing}
          onInitNewRow={(e) => {
            e.data.CodUnidadeEmpresa = unitCod;
          }}
          headerFilter={{ visible: true }}
          filterPanel={{ visible: true }}
          filterRow={{ visible: false }}
          editing={{
            refreshMode: "full",
            mode:"batch",
            allowUpdating: true,
            allowDeleting: true,
            allowAdding: false,
          }}
          onRowUpdating={async (e) => {
            const newObj = { ...e.oldData, ...e.newData };
            let strLogMessage = LogMessageGrid(formatMessage("UpdatedLog"), _measure, newObj);

            e.cancel = updateRow(newObj, strLogMessage);

            e.component.deselectAll();
          }}
          onRowInserting={(e) => {
            let strLogMessage = LogMessageGrid(formatMessage("InsertedLog"), _measure, e.data);

            e.cancel = insertRow(e.data, strLogMessage);

            e.component.deselectAll();
          }}
          onRowRemoving={(e) => {
            let strLogMessage = LogMessageGrid(formatMessage("DeletedLog"), _measure, e.data)
            const data = { unitCod: unitCod,  data: e.data, logMessage: strLogMessage };

            e.cancel = deleteRow(data);

            e.component.deselectAll();
          }}
          ref={_measure}
        >
          <Grouping
            contextMenuEnabled={false}
            expandMode="rowClick"
            autoExpandAll={false}
          />
          <GroupPanel visible={false} />
          <Export
            enabled={true}
            fileName="produto_grupo"
            allowExportSelectedData={true}
          />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={false} />
          <Selection mode="multiple" />
          <Paging defaultPageSize={20} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <FilterRow visible={true} />
          <Toolbar >
            <Item cssClass={'add-container'}>
              <Button className={'add-button'}  icon={"add"} onClick={() => (_measure.current.instance.addRow())}/>
            </Item>
            <Item name="saveButton"/>
            <Item name="revertButton"/>
            <Item name="exportButton"/>
            <Item name="columnChooserButton"/>
          </Toolbar>
          <Column
            dataField={"NomUnidadeValor"}
            caption={"Unidade de Medida"}
            hidingPriority={1}
            allowSorting={true}
          >
            <RequiredRule />
          </Column>
          <Column
            dataField={"NomSiglaUnidadeValor"}
            caption={"Sigla"}
            hidingPriority={0}
            allowSorting={true}
            width={100}
          >
            <RequiredRule />
          </Column>
        </DataGrid>
      </div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
